import axios from 'axios';

// import { GlobalState } from 'types/Store';
import { authService } from './';
import Browser, { Window } from './browser';
import { serializeFilter } from 'utils/general';

// const manualErrorHandlingActions: ErrorHandlingAction<Partial<ErrorResponse>> = {
//   [LOGIN]: {
//     statusCode: 401,
//     error: 'Unauthorized'
//   },
//   [UPDATE_USER]: {
//     statusCode: 401,
//     error: 'Unauthorized',
//     message: 'The old password is invalid.'
//   },
//   [CREATE_BOOK]: {
//     statusCode: 400,
//     error: 'Bad request',
//     message: "You've exceeded the book allowance for your current plan"
//   }
// };

const browser: Window = new Browser();

export let instance: any;

export const cacheLocal = (action: any) => {
  // if (
  //   action.type === LOCATION_CHANGE ||
  //   action.type === LOGIN ||
  //   action.type === LOGGED_IN ||
  //   action.type === LOGGED_OUT ||
  //   action.type === RESET_USER
  // ) {
  //   return;
  // }
  // console.log('--caching action', action);
  // const { data } = store().getState();
  // if (action.meta && action.meta.persist) {
  //   switch (action.type) {
  //     case UPDATE_SIDE_BY_ID:
  //       const sideIndex = Number(action.payload.id.split('-').pop());
  //       data.book.data = {
  //         ...data.book.data,
  //         sides: [
  //           ...data.book.data.sides.slice(0, sideIndex),
  //           {
  //             ...data.book.data.sides[sideIndex],
  //             ...action.payload.toUpdate
  //           },
  //           ...data.book.data.sides.slice(sideIndex + 1)
  //         ]
  //       };
  //       break;
  //     case UPDATE_BOOK_BY_ID:
  //       data.book.data = {
  //         ...data.book.data,
  //         ...action.payload.toUpdate
  //       };
  //       break;
  //   }
  // }
  // const { auth, ...rest } = data;
  // browser.window.setItem(
  //   'cache',
  //   JSON.stringify({
  //     data: {
  //       ...rest
  //     },
  //     synced: false
  //   })
  // );
};

export const markCacheAsSynced = async () => {
  console.log('--marking as synced');
  try {
    const storedData = browser.window.getItem('cache');

    if (storedData) {
      const { data } = JSON.parse(storedData);

      browser.window.setItem(
        'cache',
        JSON.stringify({
          data: {
            ...data
          },
          synced: true
        })
      );
    }
  } catch (e) {
    console.error(e);
  }
};

export const clearCache = (action: any) => {
  browser.window.removeItem('cache');
};

export const getClients = async (opts?: any) => {
  let query: string = '';

  if (opts?.sort && Object.keys(opts.sort).length) {
    query = `?sortKey=${opts.sort.key}&sortDirection=${opts.sort.direction}`;
  }

  if (opts?.filters) {
    let q: string = ``;

    q += `filter=${serializeFilter(opts.filters.options)}`;

    if (query.length > 1) {
      query += `&${q}`;
    } else {
      query = `?${q}`;
    }
  }

  if (opts?.offset) {
    const q: string = `skip=${opts.offset}`;

    if (query.length > 1) {
      query += `&${q}`;
    } else {
      query = `?${q}`;
    }
  }

  return await instance
    .get(`clients${query}`, {
      headers: {
        authorization: authService.default.token()
      }
    });
};

export const getClient = async (clientId: string, isCustomer?: boolean) => {
  return await instance
    .get(`clients/${clientId}`, {
      headers: {
        ...(!isCustomer && {
          authorization: authService.default.token()
        })
      }
    });
};

export const updateClient = async (clientId: string, payload: any) => {
  return await instance
    .put(`clients/${clientId}`,
      { ...payload },
      {
        headers: {
          authorization: authService.default.token()
        },
      }
    );
};

export const getOnboardStatus = async (clientId: string) => {
  return await instance
    .get(`clients/${clientId}/onboard-status`, {
      headers: {
        authorization: authService.default.token()
      }
    });
};

export const getInsights = async (
  clientId: string,
  date: string,
  period: string
) => {
  const q = `?date=${date}&period=${period}`;

  return await instance
    .get(`clients/${clientId}/insights${q}`, {
      headers: {
        authorization: authService.default.token()
      }
    });
};

export const getPlatformInsights = async (
  date: string,
  period: string
) => {
  const q = `?date=${date}&period=${period}`;

  return await instance
    .get(`insights${q}`, {
      headers: {
        authorization: authService.default.token()
      }
    });
};

export const getFulfillerInsights = async (
  clientId: string,
  fulfillerId: string,
  date: string,
  period: string
) => {
  const q = `?date=${date}&period=${period}`;

  return await instance
    .get(`clients/${clientId}/fulfillers/${fulfillerId}/insights${q}`, {
      headers: {
        authorization: authService.default.token()
      }
    });
};

export const getOverview = async (clientId: string) => {
  return await instance
    .get(`clients/${clientId}/overview`, {
      headers: {
        authorization: authService.default.token()
      }
    });
};

export const getPlatformOverview = async () => {
  return await instance
    .get(`overview`, {
      headers: {
        authorization: authService.default.token()
      }
    });
};

export const getFulfillerOverview = async (
  clientId: string,
  fulfillerId: string
) => {
  return await instance
    .get(`clients/${clientId}/fulfillers/${fulfillerId}/overview`, {
      headers: {
        authorization: authService.default.token()
      }
    });
};

export const getUserByToken = async (clientId: string) => {
  return await instance
    .get(`clients/${clientId}/user`, {
      headers: {
        authorization: authService.default.token()
      }
    });
};

export const getAccountingAuthUrl = async (clientId: string) => {
  return await instance
    .get(`clients/${clientId}/accounting/auth-uri`, {
      headers: {
        authorization: authService.default.token()
      }
    });
};

export const postAccountingOaCompletion = async (clientId: string, payload: any) => {
  return await instance
    .put(`clients/${clientId}/accounting/oa-completion`,
      { ...payload },
      {
        headers: {
          authorization: authService.default.token()
        }
      }
    );
};

export const createEvent = async (clientId: string, payload: any) => {
  return await instance
    .post(`clients/${clientId}/events`,
      { ...payload },
      {
        headers: {
          authorization: authService.default.token()
        }
      }
    );
};

export const getEvents = async (
  clientId: string,
  date: string,
  period?: string
) => {
  let q = `?date=${date}`;

  if (period) {
    q += `&period=${period}`;
  }

  return await instance
    .get(`clients/${clientId}/events${q}`, {
      headers: {
        authorization: authService.default.token()
      }
    });
};

export const updateEvent = async (
  clientId: string,
  id: string,
  payload: any
) => {
  return await instance
    .put(`clients/${clientId}/events/${id}`,
      { ...payload },
      {
        headers: {
          authorization: authService.default.token()
        },
      }
    );
};

export const deleteEvent = async (
  clientId: string,
  id: string
) => {
  return await instance
    .delete(`clients/${clientId}/events/${id}`,
      {
        headers: {
          authorization: authService.default.token()
        },
      }
    );
};

export const getUnavailableDates = async (
  clientId: string,
  date: string,
  serviceId: string | null,
  isCustomer?: boolean,
  maxDuration?: string
) => {
  let q = `?date=${date}&serviceId=${serviceId}`;

  if (maxDuration) {
    q += `&maxDuration=${maxDuration}`;
  }

  return await instance
    .get(`clients/${clientId}/events/unavailable-dates${q}`, {
      headers: {
        ...(!isCustomer && {
          authorization: authService.default.token()
        })
      }
    });
};

export const getTimeSlots = async (
  clientId: string,
  date: string,
  serviceId: string | null,
  isCustomer?: boolean,
  maxDuration?: string
) => {
  let q = `?date=${date}&serviceId=${serviceId}`;

  if (maxDuration) {
    q += `&maxDuration=${maxDuration}`;
  }

  return await instance
    .get(`clients/${clientId}/events/time-slots${q}`, {
      headers: {
        ...(!isCustomer && {
          authorization: authService.default.token()
        })
      }
    });
};

export const getAvailability = async (
  clientId: string,
  dates: string[],
) => {
  const q = `?startDate=${dates[0]}&endDate=${dates[1]}`;

  return await instance
    .get(`clients/${clientId}/events/availability${q}`, {
      headers: {
        authorization: authService.default.token()
      }
    });
};

export const login = async (payload: any) => {
  return await instance
    .post(`users/login`,
      { ...payload }
    );
};

export const logout = async () => {
  return await instance
    .post(`users/logout`,
      {},
      {
        headers: {
          authorization: authService.default.token()
        }
      }
    );
};

export const createClient = async (payload: any) => {
  return await instance
    .post(`clients`,
      { ...payload }
    );
};

export const createUser = async (clientId: string, payload: any, isCustomer?: boolean) => {
  return await instance
    .post(`clients/${clientId}/users`,
      { ...payload },
      {
        headers: {
          ...(!isCustomer && {
            authorization: authService.default.token()
          })
        }
      }
    );
};

export const getUsers = async (clientId: string, opts?: any) => {
  let query: string = '';

  if (opts?.sort && Object.keys(opts.sort).length) {
    query = `?sortKey=${opts.sort.key}&sortDirection=${opts.sort.direction}`;
  }

  if (opts?.filters) {
    let q: string = ``;

    q += `filter=${serializeFilter(opts.filters.options)}`;

    if (query.length > 1) {
      query += `&${q}`;
    } else {
      query = `?${q}`;
    }
  }

  if (opts?.offset) {
    const q: string = `skip=${opts.offset}`;

    if (query.length > 1) {
      query += `&${q}`;
    } else {
      query = `?${q}`;
    }
  }

  return await instance
    .get(`clients/${clientId}/users${query}`, {
      headers: {
        authorization: authService.default.token()
      }
    });
};

export const getUser = async (clientId: string, id: string) => {
  return await instance
    .get(`clients/${clientId}/users/${id}`, {
      headers: {
        authorization: authService.default.token()
      }
    });
};

export const updateUser = async (clientId: string, id: string, payload: any) => {
  return await instance
    .put(`clients/${clientId}/users/${id}`,
      { ...payload },
      {
        headers: {
          authorization: authService.default.token()
        },
      }
    );
};

export const deleteUser = async (clientId: string, id: string) => {
  return await instance
    .delete(`clients/${clientId}/users/${id}`,
      {
        headers: {
          authorization: authService.default.token()
        },
      }
    );
};

export const createFulfillerGroup = async (clientId: string, payload: any) => {
  return await instance
    .post(`clients/${clientId}/fulfiller-groups`,
      { ...payload },
      {
        headers: {
          authorization: authService.default.token()
        }
      }
    );
};

export const updateFulfillerGroup = async (clientId: string, id: string, payload: any) => {
  return await instance
    .put(`clients/${clientId}/fulfiller-groups/${id}`,
      { ...payload },
      {
        headers: {
          authorization: authService.default.token()
        }
      }
    );
};

export const getFulfillerGroups = async (clientId: string, ids?: string[]) => {
  let q = '';

  if (ids) {
    q = '?ids=';

    q += ids.join('&ids=');
  }

  return await instance
    .get(`clients/${clientId}/fulfiller-groups${q}`, {
      headers: {
        authorization: authService.default.token()
      }
    });
};

export const deleteFulfillerGroup = async (clientId: string, id: string) => {
  return await instance
    .delete(`clients/${clientId}/fulfiller-groups/${id}`,
      {
        headers: {
          authorization: authService.default.token()
        },
      }
    );
};

export const verify = async (userId: string, token: string) => {
  return await instance
    .post(`users/${userId}/verify?token=${token}`)
};

export const requestPasswordReset = async (payload: any) => {
  return await instance
    .post(`users/request-password-reset`,
      { ...payload }
    );
};

export const resetPassword = async (id: string, payload: any) => {
  return await instance
    .post(`users/${id}/reset-password`,
      { ...payload }
    );
};

export const createService = async (clientId: string, payload: any) => {
  return await instance
    .post(`clients/${clientId}/services`,
      { ...payload },
      {
        headers: {
          authorization: authService.default.token()
        }
      }
    );
};

export const getServices = async (clientId: string, isCustomer?: boolean) => {
  return await instance
    .get(`clients/${clientId}/services`, {
      headers: {
        ...(!isCustomer && {
          authorization: authService.default.token()
        })
      }
    });
};

export const getService = async (clientId: string, id: string, isCustomer?: boolean) => {
  return await instance
    .get(`clients/${clientId}/services/${id}`, {
      headers: {
        ...(!isCustomer && {
          authorization: authService.default.token()
        })
      }
    });
};

export const getDefaultServiceSteps = async (clientId: string, isCustomer?: boolean) => {
  return await instance
    .get(`clients/${clientId}/services/default-steps`, {
      headers: {
        ...(!isCustomer && {
          authorization: authService.default.token()
        })
      }
    });
};

export const getServiceFields = async (clientId: string) => {
  return await instance
    .get(`clients/${clientId}/services/fields`, {
      headers: {
        authorization: authService.default.token()
      }
    });
};

export const updateService = async (clientId: string, id: string, payload: any) => {
  return await instance
    .put(`clients/${clientId}/services/${id}`,
      { ...payload },
      {
        headers: {
          authorization: authService.default.token()
        },
      }
    );
};

export const deleteService = async (clientId: string, id: string) => {
  return await instance
    .delete(`clients/${clientId}/services/${id}`,
      {
        headers: {
          authorization: authService.default.token()
        },
      }
    );
};

export const cloneService = async (clientId: string, id: string) => {
  return await instance
    .post(`clients/${clientId}/services/${id}/clone`,
      {},
      {
        headers: {
          authorization: authService.default.token()
        },
      }
    );
};

export const getBillPreview = async (clientId: string) => {
  return await instance
    .get(`clients/${clientId}/bills/preview`, {
      headers: {
        authorization: authService.default.token()
      }
    });
};

export const getPayments = async (
  clientId: string,
  jobId: string | null,
  opts?: any
) => {
  let query: string = '';

  if (opts?.sort && Object.keys(opts.sort).length) {
    query = `?sortKey=${opts.sort.key}&sortDirection=${opts.sort.direction}`;
  }

  if (opts && opts.filters) {
    let q: string = '';

    q += `filter=${serializeFilter(opts.filters.options)}`;

    if (query.length > 1) {
      query += `&${q}`;
    } else {
      query = `?${q}`;
    }
  }

  if (opts?.offset) {
    const q: string = `skip=${opts.offset}`;

    if (query.length > 1) {
      query += `&${q}`;
    } else {
      query = `?${q}`;
    }
  }

  if (jobId) {
    const q: string = `jobId=${jobId}`;

    if (query.length > 1) {
      query += `&${q}`;
    } else {
      query = `?${q}`;
    }
  }

  const url = `clients/${clientId}/payments${query}`;

  return await instance
    .get(url, {
      headers: {
        authorization: authService.default.token()
      }
    });
};

export const refund = async (clientId: string, paymentIds: string[]) => {
  return await instance
    .post(`clients/${clientId}/payments/refund`,
      { paymentIds },
      {
        headers: {
          authorization: authService.default.token()
        }
      }
    );
};

export const createFulfiller = async (clientId: string, payload: any) => {
  return await instance
    .post(`clients/${clientId}/fulfillers`,
      { ...payload },
      {
        headers: {
          authorization: authService.default.token()
        }
      }
    );
};

export const getFulfiller = async (clientId: string, id: string) => {
  return await instance
    .get(`clients/${clientId}/fulfillers/${id}`, {
      headers: {
        authorization: authService.default.token()
      }
    });
};

// TODO: abstract
export const getFulfillers = async (
  clientId: string,
  opts?: any
) => {
  let query: string = '';

  if (opts?.sort && Object.keys(opts.sort).length) {
    query = `?sortKey=${opts.sort.key}&sortDirection=${opts.sort.direction}`;
  }

  if (opts?.filters) {
    let q: string = ``;

    q += `filter=${serializeFilter(opts.filters.options)}`;

    if (query.length > 1) {
      query += `&${q}`;
    } else {
      query = `?${q}`;
    }
  }

  if (opts?.offset) {
    const q: string = `skip=${opts.offset}`;

    if (query.length > 1) {
      query += `&${q}`;
    } else {
      query = `?${q}`;
    }
  }

  return await instance
    .get(`clients/${clientId}/fulfillers${query}`, {
      headers: {
        authorization: authService.default.token()
      }
    });
};

export const deleteFulfiller = async (clientId: string, id: string) => {
  return await instance
    .delete(`clients/${clientId}/fulfillers/${id}`,
      {
        headers: {
          authorization: authService.default.token()
        },
      }
    );
};

// TODO: abstract
export const getBills = async (clientId: string, opts?: any) => {
  let query: string = '';

  if (opts?.sort && Object.keys(opts.sort).length) {
    query = `?sortKey=${opts.sort.key}&sortDirection=${opts.sort.direction}`;
  }

  if (opts?.filters) {
    let q: string = ``;

    q += `filter=${serializeFilter(opts.filters.options)}`;

    if (query.length > 1) {
      query += `&${q}`;
    } else {
      query = `?${q}`;
    }
  }

  if (opts?.offset) {
    const q: string = `skip=${opts.offset}`;

    if (query.length > 1) {
      query += `&${q}`;
    } else {
      query = `?${q}`;
    }
  }

  return await instance
    .get(`clients/${clientId}/bills${query}`, {
      headers: {
        authorization: authService.default.token()
      }
    });
};

export const getCustomerById = async (clientId: string, idOrEmail: string) => {
  return await instance
    .get(`clients/${clientId}/customers/${idOrEmail}`, {
      headers: {
        authorization: authService.default.token()
      }
    });
};

export const getCustomers = async (clientId: string, query?: string) => {
  let q: string = '';

  if (query?.length) {
    q = `?filter=${query}`;
  }

  return await instance
    .get(`clients/${clientId}/customers${q}`, {
      headers: {
        authorization: authService.default.token()
      }
    });
};

export const createCustomer = async (
  clientId: string,
  payload: any
) => {
  return await instance
    .post(`clients/${clientId}/customers`,
      { ...payload },
      {
        headers: {
          authorization: authService.default.token()
        }
      }
    );
};

export const updateCustomer = async (clientId: string, id: string, payload: any) => {
  return await instance
    .put(`clients/${clientId}/customers/${id}`,
      { ...payload },
      {
        headers: {
          authorization: authService.default.token()
        }
      }
    );
};

// TODO: abstract
export const getJobs = async (
  clientId: string,
  opts?: any
) => {
  let query: string = '';

  if (opts?.sort && Object.keys(opts.sort).length) {
    query = `?sortKey=${opts.sort.key}&sortDirection=${opts.sort.direction}`;
  }

  if (opts?.filters) {
    let q: string = ``;

    q += `filter=${serializeFilter(opts.filters.options)}`;

    if (query.length > 1) {
      query += `&${q}`;
    } else {
      query = `?${q}`;
    }
  }

  if (opts?.offset) {
    const q: string = `skip=${opts.offset}`;

    if (query.length > 1) {
      query += `&${q}`;
    } else {
      query = `?${q}`;
    }
  }

  return await instance
    .get(`clients/${clientId}/jobs${query}`, {
      headers: {
        authorization: authService.default.token()
      }
    });
};

export const getJobFields = async (clientId: string) => {
  return await instance
    .get(`clients/${clientId}/jobs/fields`, {
      headers: {
        authorization: authService.default.token()
      }
    });
};

export const getJob = async (clientId: string, id: string, isCustomer?: boolean) => {
  return await instance
    .get(`clients/${clientId}/jobs/${id}`, {
      headers: {
        ...(!isCustomer && {
          authorization: authService.default.token()
        })
      }
    });
};

export const updateJob = async (clientId: string, id: string, payload: any, isCustomer?: boolean) => {
  return await instance
    .put(`clients/${clientId}/jobs/${id}`,
      { ...payload },
      {
        ...(!isCustomer && {
          headers: {
            authorization: authService.default.token()
          }
        })
      }
    );
};

export const syncJob = async (clientId: string, id: string) => {
  return await instance
    .put(`clients/${clientId}/jobs/${id}/sync`,
      {},
      {
        headers: {
          authorization: authService.default.token()
        }
      }
    );
};

export const updateFulfiller = async (clientId: string, id: string, payload: any) => {
  return await instance
    .put(`clients/${clientId}/fulfillers/${id}`,
      { ...payload },
      {
        headers: {
          authorization: authService.default.token()
        }
      }
    );
};

export const createJob = async (
  clientId: string,
  date: string,
  payload: any,
  isCustomer?: boolean
) => {
  const q = `?date=${date}`;

  return await instance
    .post(`clients/${clientId}/jobs${q}`,
      { ...payload },
      {
        headers: {
          ...(!isCustomer && {
            authorization: authService.default.token()
          })
        }
      }
    );
};

export const deleteJob = async (clientId: string, id: string, isCustomer?: boolean) => {
  return await instance
    .delete(`clients/${clientId}/jobs/${id}`,
      {
        headers: {
          ...(!isCustomer && {
            authorization: authService.default.token()
          })
        }
      }
    );
};

export const getPaymentIntent = async (
  clientId: string,
  jobId: string,
  isCustomer?: boolean
) => {
  return await instance
    .post(`clients/${clientId}/payments/intent`,
      { jobId },
      {
        headers: {
          ...(!isCustomer && {
            authorization: authService.default.token()
          })
        }
      }
    );
};

export const postPaymentOutcome = async (clientId: string, payload: any, isCustomer?: boolean) => {
  return await instance
    .post(`clients/${clientId}/payments/outcome`,
      { ...payload },
      {
        headers: {
          ...(!isCustomer && {
            authorization: authService.default.token()
          })
        }
      }
    );
};

export const createPricing = async (clientId: string, payload: any) => {
  return await instance
    .post(`clients/${clientId}/pricing`,
      { ...payload },
      {
        headers: {
          authorization: authService.default.token()
        },
      }
    );
};

// TODO: abstract
export const getPricing = async (clientId: string, serviceId: string, opts?: any) => {
  let query: string = '';

  const serviceQ: string = `serviceId=${serviceId}`;

  if (query.length > 1) {
    query += `&${serviceQ}`;
  } else {
    query = `?${serviceQ}`;
  }

  if (opts?.sort) {
    query = `&sortKey=${opts.sort.key}&sortDirection=${opts.sort.direction}`;
  }

  if (opts?.filters) {
    let q: string = ``;

    q += `filter=${JSON.stringify(opts.filters.options)}`;

    if (query.length > 1) {
      query += `&${q}`;
    } else {
      query = `?${q}`;
    }
  }

  if (opts?.offset) {
    const q: string = `skip=${opts.offset}`;

    if (query.length > 1) {
      query += `&${q}`;
    } else {
      query = `?${q}`;
    }
  }

  return await instance
    .get(`clients/${clientId}/pricing${query}`, {
      headers: {
        authorization: authService.default.token()
      }
    });
};

export const getPrice = async (clientId: string, id: string) => {
  return await instance
    .get(`clients/${clientId}/pricing/${id}`, {
      headers: {
        authorization: authService.default.token()
      }
    });
};

export const updatePricing = async (clientId: string, id: string, payload: any) => {
  return await instance
    .put(`clients/${clientId}/pricing/${id}`,
      { ...payload },
      {
        headers: {
          authorization: authService.default.token()
        },
      }
    );
};

export const deletePricing = async (clientId: string, id: string) => {
  return await instance
    .delete(`clients/${clientId}/pricing/${id}`,
      {
        headers: {
          authorization: authService.default.token()
        },
      }
    );
};

export const calculate = async (clientId: string, payload: any, isCustomer?: boolean) => {
  return await instance
    .post(`clients/${clientId}/pricing/calculate`,
      { ...payload },
      {
        headers: {
          ...(!isCustomer && {
            authorization: authService.default.token()
          })
        }
      }
    );
};

export const createDirectDebit = async (clientId: string) => {
  return await instance
    .put(`clients/${clientId}/create-direct-debit`,
      {},
      {
        headers: {
          authorization: authService.default.token()
        }
      }
    );
};

export const completeDirectDebit = async (clientId: string, redirectFlowId: string) => {
  return await instance
    .post(`clients/${clientId}/complete-direct-debit`,
      { redirectFlowId },
      {
        headers: {
          authorization: authService.default.token()
        }
      }
    );
};

export const checkEmail = async (email: string) => {
  return await instance
    .post(`users/email-check`,
      { email },
    );
};

export const checkForDuplicateCustomer = async (clientId: string, email: string) => {
  return await instance
    .post(`clients/${clientId}/customers/check`,
      { email },
      {
        headers: {
          authorization: authService.default.token()
        }
      }
    );
};

export const getAppBanners = async (clientId: string) => {
  return await instance
    .get(`clients/${clientId}/banners`, {
      headers: {
        authorization: authService.default.token()
      }
    });
};

export const verifyRecaptcha = async (
  token: string,
  action: string
) => {
  return await instance
    .post(`rc`,
      {
        token,
        action
      }
    );
};

export const postcodeLookup = async (postcode: string) => {
  const API_KEY: string | undefined = process.env.REACT_APP_GET_ADDRESS_API_KEY;

  return await instance
    .get(`https://api.getAddress.io/find/${postcode}?expand=true&api-key=${API_KEY}`);
};

export const setup = (url: string) => {
  instance = axios.create({
    baseURL: process.env.NODE_ENV !== 'production' ? '/' : url,
    timeout: 20000,
    // headers: { 'X-Custom-Header': 'foobar' }
  });

  // TODO: catch errors and dispatch generic/specific action for a modal
  instance.interceptors.response.use(
    (response: any) => {
      return Promise.resolve(response.data);
    },
    (error: any) => {
      if (error.response) {
        // console.log('-------------error', error.response);
        if (error.response.config.url.includes('login')) {
          return Promise.reject(error);
        }

        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);

        switch (error.response.status) {
          case 401:
            authService.default.invalidate();

            if (authService.default.shouldRefreshOnInvalid) {
              // window.location.reload();
            }

            authService.default.redirectToLogin();
            break;
          case 423:
            switch (error.response.data.reason) {
              case 'trial-over':
                // TODO: change/remove
                window.location.href =
                  window.origin + '/account?locked=true&reason=trial-over#billing';
                break;
            }
            break;
        }
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js

        // likely a timeout
        // console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }

      // console.log('----here');
      return Promise.reject(error);
    }
  );

  return {
    cacheLocal,
    markCacheAsSynced
  };
};
