import styled from 'styled-components';
// import { device } from 'theme/media';
import { PrimaryButton } from 'components/atoms';
import { Dock } from './Carousel';

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  overflow: hidden;
`;

export const SectionsWrapper = styled.div<{ sectionIndex: number }>`
  display: flex;
  height: 100%;
  flex-direction: row;
  transition: transform ${({ theme }) => theme.animSpeed} ease-in-out;
  transform: translateX(0);
  margin-bottom: 2rem;

  ${({ sectionIndex }) =>
    sectionIndex &&
    `
      transform: translateX(-${sectionIndex * 100}%);
  `}
`;

export const SectionWrapper = styled.section`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  flex-shrink: 0;
`;

export const CtaWrapper = styled.div<{ dock?: Dock }>`
  display: flex;
  justify-content: center;

  ${({ dock }) =>
    dock === Dock.Top &&
    `
    margin-bottom: auto;
  `}

  ${({ dock }) =>
    dock === Dock.Right &&
    `
    margin-left: auto;
  `}

  ${({ dock }) =>
    dock === Dock.Bottom &&
    `
    margin-top: auto !important;
  `}

  ${({ dock }) =>
    dock === Dock.Left &&
    `
    margin-right: auto;
  `}
`;

// TODO: move to atoms
export const Indicator = styled.div`
  display: flex;
  justify-content: center;
`;

export const Indicators = styled.div<{ index: number }>`
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: row;
  margin-left: 0.5rem;

  :after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    transform: ${({ index }) => `translateX(${index * 0.75 + index * 0.5}rem);`}
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 2.5rem;
    background-color: ${({ theme }) => theme.colors.corePrimary};
    transition: transform ${({ theme }) => theme.animSpeed} ease-in-out;
  }
`;

export const IndicatorPoint = styled.div`
  display: flex;
  width: 0.75rem;
  height: 0.75rem;
  margin-right: 0.5rem;
  border-radius: 2.5rem;
  background-color: black;
  justify-content: center;
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
