import { ITableHeader } from 'types/Header';

export const stripTempRowProps = (
  rows: any | any[],
  namespaceKey: string
): any => {
  if (Array.isArray(rows)) {
    return rows.map((r: any) => {
      const {
        key,
        subIndex,
        _id,
        isNew,
        isNewSub,
        isParent,
        isSub,
        isSubLast,
        checked,
        ...rest
      } = r;

      return {
        ...rest,
        // ...(!_id.includes(namespaceKey) && {
        ...(!!_id && {
          _id
        })
      };
    });
  } else {
    const {
      key,
      subIndex,
      _id,
      isNew,
      isNewSub,
      isParent,
      isSub,
      isSubLast,
      checked,
      ...rest
    } = rows;

    return {
      ...rest,
      // ...(!_id.includes(namespaceKey) && {
      ...(!!_id && {
        _id
      })
    };
  }
};

export const filterColumns = (header: ITableHeader, isEditMode?: boolean) => {
  if (!header.hide) {
    return true;
  }

  if (typeof isEditMode === 'undefined') {
    return false;
  }

  return !header.hide(isEditMode);
};

