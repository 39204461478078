import styled from 'styled-components';

import BaseInput from './BaseInput';
import { elemMixin } from '../form/mixins.styles';

export const StyledNumericInput = styled(BaseInput)<{ errored?: boolean; textRight?: boolean; }>`
  ${({ width }) =>
    width &&
    `
    width: ${width};
  `}

  ${({ errored, textRight }) => elemMixin(errored, textRight)}
`;

