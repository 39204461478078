import { LocalError } from 'types/Error';

export interface Item {
  txt: string;
  deleteTo?: number;
  img?: string;
}

export const items: Item[] = [
  {
    txt: 'Automate your business bookings.',
    img: '/images/landscape-1.jpg'
  },
  {
    txt: 'From service selection and payment, all the way through to fulfilment.',
    img: '/images/landscape-2.jpg'
  },
  {
    txt: 'Jobs are automatically assigned to a fulfiller.',
    deleteTo: 37,
    img: '/images/landscape-8.jpg'
  },
  {
    txt: 'Jobs are automatically assigned to a gas safety engineer.',
    deleteTo: 36,
    img: '/images/landscape-8.jpg'
  },
  {
    txt: 'Jobs are automatically assigned to an electrician.',
    deleteTo: 36,
    img: '/images/landscape-8.jpg'
  },
  {
    txt: 'Jobs are automatically assigned to a barber.',
    deleteTo: 37,
    img: '/images/landscape-8.jpg'
  },
  {
    txt: 'Jobs are automatically assigned to a mechanic.',
    deleteTo: 37,
    img: '/images/landscape-8.jpg'
  },
  {
    txt: 'Jobs are automatically assigned to a driver.',
    deleteTo: 37,
    img: '/images/landscape-8.jpg'
  },
  {
    txt: 'Jobs are automatically assigned to a writer.',
    deleteTo: 36,
    img: '/images/landscape-8.jpg'
  },
  {
    txt: 'Jobs are automatically assigned to anyone that directly engages in booking fulfilment.',
    img: '/images/landscape-8.jpg'
  },
  {
    txt: 'Stay on top of your team, payments, stats and more.',
    img: '/images/landscape-8.jpg'
  }
];

export default class TxtType {
  el: HTMLElement | null;
  imgEl: HTMLElement | null;
  items: Item[];

  // in milliseconds
  durationFactor: number = 100;
  waitDurationAfterSentenceFinish: number = 3000;
  waitDurationAfterDeletion: number = 500;
  cursorFlashDuration: number = 500;

  index: number = 0;
  txt: string = '';
  isDeleting: boolean = false;
  endReached: boolean = false;

  timeout:  ReturnType<typeof setTimeout> | null = null;

  constructor(
    el: HTMLElement | null,
    incomingItems: Item[],
    imgEl: HTMLElement | null
  ) {
    if (el) {
      this.el = el;
      this.imgEl = imgEl;
      this.items = incomingItems;

      this.tick();
    } else {
      throw new LocalError('Text type elem is null');
    }
  }

  tick() {
    const i = this.index % this.items.length;
    const currentItem: Item = this.items[i];
    const fullTxt: string = currentItem.txt;
    // const img: string | undefined = items[i + 1] ? items[i + 1].img : items[0].img;

    if (this.isDeleting) {
      this.txt = fullTxt.substring(0, this.txt.length - 1);
    } else {
      this.txt = fullTxt.substring(0, this.txt.length + 1);
    }

    this.el!.innerHTML = `
      <span>${this.txt}</span>
      <i/>
    `;

    let delta = this.durationFactor - Math.random() * 100;

    if (this.isDeleting) {
      delta /= 2;
    }

    if (!this.isDeleting && this.txt === fullTxt) {
      delta = this.waitDurationAfterSentenceFinish;
      this.isDeleting = true;
      this.endReached = true;
      this.el!.querySelector('i')!.style.animation =
        `cursor ${this.cursorFlashDuration / 1000}s steps(2, jump-none) infinite alternate`;
      this.el!.querySelector('span')!.style.display = 'inline';
    } else if ((this.isDeleting && this.txt) === '' || (currentItem.deleteTo && this.endReached && this.txt.length === currentItem.deleteTo)) {
      delta = this.waitDurationAfterDeletion;
      this.isDeleting = false;
      this.endReached = false;
      this.index++;

      // if (img) {
      //   this.imgEl!.style.backgroundImage = `url(${img})`;
      // }
      this.el!.querySelector('span')!.style.display = 'inline-block';
    } else if (this.isDeleting) {
      this.el!.querySelector('i')!.style.animation = 'none';
      this.el!.querySelector('span')!.style.display = 'inline';
    }

    this.timeout = setTimeout(() => this.tick(), delta);
  }
}

