import React from 'react'

import { ControlWrapper, IconContainer } from './IconWrapper.styles'

interface IconWrapperProps {
  children: JSX.Element
  disabled?: boolean
  errored?: boolean
  icon: JSX.Element
  readOnly?: boolean
  shaded?: boolean
}

const IconWrapper: React.FC<IconWrapperProps> = props => {
  const { children, disabled, errored, icon, readOnly, shaded } = props

  return (
    <ControlWrapper disabled={disabled} errored={errored} readOnly={readOnly} shaded={shaded}>
      {children}
      <IconContainer>{icon}</IconContainer>
    </ControlWrapper>
  )
}

export default IconWrapper
