import React from 'react'

import { Branding } from 'gobookem-shared';

const Logo = (props: any) => {
  return (
    <div
      style={{ width: '100%', height: '100%' }}
      dangerouslySetInnerHTML={{ __html: Branding.logo(props) }}
    />
  );
};

export default Logo;

