import React, { FC, memo, useState, useCallback } from 'react';

import { Checkbox } from 'components/atoms';
import { ITableHeader, CellType, RowAction } from 'types/Header';
import {
  TableProps,
  SortState,
  SortDirection
} from './Table';
import { filterColumns } from './utils';

import {
  Wrapper,
  Row,
  TableHeader as StyledTableHeader,
  ColumnLabel
} from './TableHeader.styles';

export interface TableHeaderProps extends TableProps {
  isEditMode: boolean;
  onCheckAllRows: (checked: boolean) => void;
  sortState: SortState | null | undefined;
}

const TableHeader: FC<TableHeaderProps> = (props) => {
  const {
    namespaceKey,
    headerConfig,
    checkable,
    isEditMode,
    onSort,
    onCheckAllRows,
    sortState
  } = props;

  const [checkAll, setCheckAll] = useState<boolean>(false);

  const getHeader = useCallback((header: ITableHeader, parentHeader?: ITableHeader, subIndex?: number) => {
    const isSortable: boolean = !!(header.sortable && onSort);

    return (
      <StyledTableHeader
        key={header.key}
        style={{ width: header.width }}
        borderLeft={parentHeader && subIndex === 0}
      >
        {header.type === CellType.Action ? (
          header.renderIcon ? header.renderIcon() : null
        ) : (
          <ColumnLabel
            isSortable={isSortable}
            onClick={(e: any) => {
              if (isSortable) {
                const newState = {
                  key: header.key,
                  label: header.label || header.key,
                  direction: sortState ? sortState.direction * -1 : 1
                };

                onSort!(newState, header, namespaceKey);
              }
            }}
          >{header.label}</ColumnLabel>
        )}
        {sortState && sortState.key === header.key && (
          <span>{sortState.direction === SortDirection.Ascending ? ' ↑' : SortDirection.Descending ? ' ↓' : ''}</span>
        )}
      </StyledTableHeader>
    );
  }, [
    namespaceKey,
    sortState,
    onSort
  ]);

  const render = useCallback(() => {
    const headerList: any[] = [];
    const headerReferenceList: any[] = [];

    headerConfig
      .filter((header: ITableHeader) => filterColumns(header, isEditMode))
      .forEach((header: ITableHeader) => {
        if (header.type === CellType.ArrayMap) {
          header.subColumns!.forEach((subHeader: ITableHeader, subIndex) => {
            headerList.push(getHeader(subHeader, header, subIndex));
            headerReferenceList.push(subHeader);
          });
        } else {
          headerList.push(getHeader(header));
          headerReferenceList.push(header);
        }
      });

    const mergeCellIndex: number = headerReferenceList.findIndex(hr => hr.mergeWithParent);

    if (mergeCellIndex !== -1) {
      headerList.splice(mergeCellIndex, 1);
    }

    if (checkable) {
      headerList.unshift(getHeader({
        key: RowAction.Key,
        type: CellType.Action,
        isInternal: true,
        renderIcon: () => (
          <Checkbox
            checked={checkAll}
            onChange={(e) => {
              setCheckAll(e.target.checked);
              onCheckAllRows(e.target.checked);
            }}
          />
        )
      }));
    }

    const returnValue = (
      <>
        <Row>
          {headerList}
        </Row>
      </>
    );

    return returnValue;
  }, [
    headerConfig,
    checkable,
    checkAll,
    isEditMode,
    getHeader,
    onCheckAllRows
  ]);

  return (
    <Wrapper>
      {render()}
    </Wrapper>
  );
};

export default memo(TableHeader);
