interface Dimensions {
  width: number;
  height: number;
}

export interface Window {
  window: {
    redirect: (url: string, soft?: boolean) => void;
    setItem: (key: string, value: string) => void;
    getItem: (key: string) => string | null;
    removeItem: (key: string) => void;
    clearItem: () => void;
    dimensions: () => Dimensions;
  };
}

export default class Browser implements Window {
  readonly name = 'browser';
  readonly type = 'browser';

  window = {
    redirect: (url: string, soft?: boolean) => {
      if (soft) {
        window.location.replace(url);
      } else {
        window.location.href = url;
      }
    },
    setItem: (key: string, value: string) => {
      window.localStorage.setItem(key, value);
    },
    getItem: (key: string) => window.localStorage.getItem(key),
    removeItem: (key: string) => window.localStorage.removeItem(key),
    clearItem: () => {
      window.localStorage.clear();
    },

    dimensions: () => {
      const dElem = window.document.documentElement;

      return {
        width: dElem.offsetWidth,
        height: dElem.offsetHeight
      };
    }
  };
}
