import styled from 'styled-components';
import { device } from 'theme/media';

import {
  Dropdown,
  // FormSection,
  Settings,
  Cross,
  Tick,
  Chevron,
  UnfoldLess,
  UnfoldMore,
  Edit
} from 'components/atoms';
import {
  Table
} from '../../components';

import {
  AdminFormWrapper,
  AdminFormLine,
  Card
} from 'theme/mixins';
import { StatusIndicator } from './Services.styles';

export * from 'theme/mixins';

export const Wrapper = styled.div`
  width: inherit;
  height: 100%;
  flex-direction: column;

  .mobile-page-header, .mobile-settings-seperator, .mobile-actions-seperator, .mobile-page-actions {
    @media ${device.mobile} {
      display: none;
    }

    @media ${device.tablet} {
      display: flex;
    }
  }

  .mobile-settings-chevron-button {
    @media ${device.onlyMobile} {
      display: none;
    }

    @media ${device.laptop} {
      display: flex;
    }
  }

  @media ${device.mobile} {
    overflow: hidden;
  }

  @media ${device.tablet} {
    overflow: unset;
  }
`;

export const MobileSettingsWrapper = styled.div<{ show: boolean; }>`
  position: relative;

  @media ${device.mobile} {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: auto;
    // max-height: calc(100vh + 6rem);
    padding-top: 7rem;
    z-index: ${({ theme }) => theme.layers.highest};

    ${({ show }) => show && `
      display: block;
    `}
  }

  @media ${device.tablet} {
    display: block;
    position: relative;
    width: unset;
    height: unset;
    // overflow: unset;
    // max-height: unset;
    padding-top: unset;
    z-index: unset;
  }
`;

export const MobileSettingIconWrapper = styled.div<{
  mobileSettingsOn: boolean;
  noRight?: boolean;
}>`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  margin-top: -100px;
  right: 2rem;
  z-index: ${({ theme }) => theme.layers.high};

  @media ${device.tablet} {
    display: none;
  }

  > div {
    margin-bottom: 1rem;
  }

  ${({ mobileSettingsOn }) => mobileSettingsOn && `
    z-index: 0;
  `}

  ${({ noRight }) => noRight && `
    right: 0;
  `}
`;

export const IFrameSectionWrapper = styled.div<{ blur: boolean; }>`
  height: inherit;
  filter: blur(0);
  transition: filter ${({ theme }) => theme.animSpeed} ease-in-out;

  ${({ blur }) => blur && `
    filter: blur(.4rem);
  `}
`;

export const CreateServiceFormWrapper = styled(AdminFormWrapper)`
  height: inherit;
  text-align: left;

  @media ${device.mobile} {
    height: inherit;
  }

  @media ${device.tablet} {
    height: unset;
  }
`;

export const StyledStatusIndicator = styled(StatusIndicator)`
  margin-right: 1rem;
`;

export const StyledDropdown = styled(Dropdown)`
  margin-left: 1rem;
`;

export const StyledCross = styled(Cross)`
  width: 1.3rem;
  height: auto;

  @media ${device.tablet} {
    display: none;
  }
`;

export const Grid = styled.div`
  display: grid;
  position: relative;
  width: inherit;
  height: inherit;
  grid-column-gap: revert;

  @media ${device.mobile} {
    grid-template-columns: 1fr;
  }

  @media ${device.tablet} {
    grid-template-columns: 1fr 0 1fr;
  }
`;

export const ServiceFormWrapper = styled.div`
  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
  }
`;

export const FormFieldControlWrapper = styled.div`
  @media ${device.tablet} {
    display: flex;
    margin-right: 2rem;
  }
`;

export const StyledSettingsIcon = styled(Settings)`
  width: 2.1rem;
  cursor: pointer;
`;

export const StyledMobileSettingsIcon = styled(Settings)`
  width: 2.1rem;
  height: auto;
`;

export const StyledMobileEditIcon = styled(Edit)`
  width: 2.1rem;
  height: auto;
`;

export const StyledMobileTickIcon = styled(Tick)<{ disabled: boolean; }>`
  width: 2rem;
  height: auto;

  ${({ theme, disabled }) => disabled && `
    fill: ${theme.baseInput.disabled.color};
  `}
`;

export const StyledMobileChevronIcon = styled(Chevron)`
  width: 4rem;
  height: auto;
  transform: rotateZ(90deg);
`;

export const StyledUnfoldLess = styled(UnfoldLess)`
  width: 2rem;
  height: auto;
`;

export const StyledUnfoldMore = styled(UnfoldMore)`
  width: 2rem;
  height: auto;
`;

export const ModalTable = styled(Table)`
  @media ${device.tablet} {
    width: 75rem;
  }
`;

export const Top = styled.div`
  @media ${device.mobile} {
    display: none;
  }

  @media ${device.tablet} {
    display: block;
  }
`;






export const ConfigDetails = styled.div<{ isExpanded: boolean; }>`
  display: flex;
  max-height: 0;
  flex-direction: column;
  text-align: left;
  transition: max-height ${({ theme }) => theme.animSpeed} ease-in-out;
  cursor: default;
  overflow: hidden;

  ${({ isExpanded }) => isExpanded && `
    max-height: 200rem;
  `};

  @media ${device.onlyMobile} {
    max-height: 200rem;
  }
`;

export const ConfigDetailsOuter = styled.div`
  display: flex;
  width: 100%;
  max-height: inherit;
  flex-direction: column;
  overflow-x: auto;
  margin-top: 3rem;
  padding-top: 2rem;
  border-top: .1rem solid ${({ theme }) => theme.colors.neutralPrimaryDark};
`;

export const ConfigWrapper = styled.div`
  @media ${device.mobile} {
    margin-right: 0;
  }

  @media ${device.tablet} {
    margin-right: 2rem;
  }
`;

export const ConfigCard = styled(Card)`
  margin-bottom: 2rem;
  cursor: pointer;

  @media ${device.mobile} {
    padding: 2rem;
  }

  @media ${device.tablet} {
    padding: 2rem 3rem;
  }
`;

// eslint-disable-next-line
export const ConfigCardColumn = styled.div<{
  grow?: boolean;
  row?: boolean;
  maxWidth?: boolean;
  spaceAround?: boolean;
  alignStart?: boolean;
  alignCenter?: boolean;
  stack?: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  word-break: break-word;
  flex-shrink: 0;

  ${({ grow }) => grow && `
    flex: 1;
  `}

  ${({ row }) => row && `
    flex-direction: row;
  `}

  ${({ maxWidth }) => maxWidth && `
    max-width: 15rem;
  `}

  ${({ spaceAround }) => spaceAround && `
    justify-content: space-around;
  `}

  ${({ alignStart }) => alignStart && `
    @media ${device.mobileXL} {
      align-items: start;
    }
  `}

  ${({ alignCenter }) => alignCenter && `
    @media ${device.mobileXL} {
      align-items: center;
    }
  `}

  ${({ stack, alignCenter }) => stack && `
    @media ${device.mobile} {
      flex-direction: column;
      align-items: start;

      > div {
        margin-bottom: .75rem;
      }
    }

    @media ${device.mobileXL} {
      flex-direction: unset;
      align-items: unset;

      > div {
        width: 13rem;
      }
    }

    @media ${device.tablet} {
      > div {
        width: 17rem;
      }
    }
  `}

  :not(:last-child) {
    @media ${device.mobile} {
      margin-right: 2rem;
    }

    @media ${device.mobileXL} {
      margin-right: 1rem;
    }

    @media ${device.tablet} {
      margin-right: 1.5rem;
    }
  }

  @media ${device.onlyMobile} {
    > span {
      font-weight: 700;
    }
  }
`;

export const OperatingHoursWrapper = styled(AdminFormLine)`
  @media ${device.onlyMobile} {
    flex-direction: column;
    align-items: start;

    > div:first-child {
      margin-bottom: 1rem;
    }

    > span {
      display: none;
    }
  }
`;

