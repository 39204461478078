import React, {
  FC,
  useCallback,
  useState,
  useEffect,
  useMemo,
  useRef,
  memo
} from 'react';
import { useParams, useNavigate } from "react-router";
import {
  format,
  formatRelative
} from 'date-fns';

import { Job, StatusType } from 'types/Job';
import { Service, Step, Field, FieldType } from 'types/Service';
import { Fulfiller } from 'types/Fulfiller';
import { Client } from 'types/Client';
import { NetworkError } from 'types/Error';
import { Period } from 'types/Generic';
import { gatewayService } from 'services';
import {
  Button,
  Spinner,
  PrimaryButton,
  UnfoldLess,
  UnfoldMore,
  Clock,
  Payments,
  Worker,
  QuickReference
} from 'components/atoms';
import { Popup } from 'components/molecules';
import { theme } from 'theme';
import {
  formatCurrency,
  formatJobStatus,
  getStatusState,
  copyText,
  getPaymentLink,
  createDropdownOption,
  getNetworkErrors,
  isListEmpty,
  isJobOverdue,
  stripZoneFromISOString,
  getJobToCopy,
  getJobDuration
} from 'utils/general';
import {
  useSortFilter,
  useMediaSizes,
} from '../../hooks';
import { FilterOptions } from '../../hooks/useSortFilter';
import { PageHeader } from '../../components';
import { Actions } from 'state';
import {
  DATE_DROPDOWN_OPTIONS,
  CURRENCY_DROPDOWN_OPTIONS,
  ISO_FORMAT
} from '../../../../../constants';
import { AdminPageProps } from 'components/AppRouter';
import {
  isFulfiller,
  concealJobPrice,
  canCreateJob,
  canUpdateJob,
  canDeleteJob,
  canHandlePayments
} from 'config/privileges';

import {
  MenuWrapper,
  MenuItem,
  IconButton,
  StyledPlus,
  NoDataIcon,
  NoDataCaption
} from 'theme/mixins';
import {
  Wrapper,
  JobCard,
  StyledMenuDots,
  JobStatus,
  StyledChevron,
  JobCardColumn,
  JobDetails,
  JobDetailsOuter,
  JobDetailsInner,
  GroupDetailsHeader,
  GroupDetails,
  GroupFields,
  FieldLabel,
  FieldValue,
  StyledLink,
  JobDetailsFooter,
  AdminFormLine
} from './Jobs.styles';

interface State {
  jobsFilterLoaded: boolean;
  jobs: {
    loading: boolean;
    data: Job[] | null;
    total: number | null;
    fields: any;
    offset: number;
    error: NetworkError | null;
    expanded: {
      [id: string]: boolean;
    };
  };
  services: {
    loading: boolean;
    data: Service[] | null;
    total: number | null;
    fields: any;
    offset: number;
    error: NetworkError | null;
  };
  jobDelete: {
    loading: boolean;
    error: NetworkError | null;
  };
  fulfillers: {
    loading: boolean;
    data: Fulfiller[] | null;
    total: number | null;
    fields: any;
    offset: number;
    error: NetworkError | null;
  };
  defaultServiceSteps: {
    loading: boolean;
    data: Field[] | null;
    error: NetworkError | null;
  };
  client: {
    loading: boolean;
    data: Client | null;
    error: NetworkError | null;
  };
}

const initialState: State = {
  jobsFilterLoaded: false,
  jobs: {
    loading: false,
    total: null,
    data: null,
    fields: {},
    offset: 0,
    error: null,
    expanded: {}
  },
  services: {
    loading: false,
    total: null,
    data: null,
    fields: {},
    offset: 0,
    error: null
  },
  jobDelete: {
    loading: false,
    error: null
  },
  fulfillers: {
    loading: false,
    total: null,
    data: null,
    fields: {},
    offset: 0,
    error: null
  },
  defaultServiceSteps: {
    loading: false,
    data: null,
    error: null
  },
  client: {
    loading: false,
    data: null,
    error: null
  }
};

const Jobs: FC<AdminPageProps> = props => {
  const {
    sideNavOpen,
    userData,
    addToast,
    dispatch
  } = props;

  const [state, setState] = useState<State>(initialState);
  const [activeMenuId, setActiveMenuId] = useState<string>();
  const allJobsCollapsed = useMemo(() => {
    return Object.keys(state.jobs.expanded).every((key: string) => {
      return state.jobs.expanded[key] === false;
    });
  }, [state.jobs.expanded]);
  const allJobsExpanded = useMemo(() => {
    const entries = Object.keys(state.jobs.expanded);

    if (!entries.length) {
      return false;
    }

    return entries
      .every((key: string) => {
        return state.jobs.expanded[key] === true;
      });
  }, [state.jobs.expanded]);
  // Proxy access to state within useEffect closure
  const filterStateCache = useRef<any | null>(null);
  const prevFilterStateCache = useRef<any | null>(null);

  const filterOptions: FilterOptions['fields'] = [
    {
      label: 'Service',
      name: 'service.name',
      type: FieldType.Dropdown,
      items: (state.services.data && state.services.data.map((service: Service) => {
        return createDropdownOption(service.name);
      })) || []
    },
    {
      label: 'Status',
      name: 'queryStatus.type',
      type: FieldType.Dropdown,
      items: Object.keys(StatusType).map((key: string) => ({
        ...createDropdownOption(
          formatJobStatus(key),
          key
        )
      }))
    },
    {
      label: 'Submitted',
      name: 'created.at',
      type: FieldType.DateTime,
      options: [
        createDropdownOption('Next year', Period.NextYear),
        createDropdownOption('Next quarter', Period.NextQuarter),
        createDropdownOption('Next month', Period.NextMonth),
        createDropdownOption('Next week', Period.NextWeek),
        createDropdownOption('Tomorrow', Period.Tomorrow),
        createDropdownOption('Today', Period.Today),
        createDropdownOption('Yesterday', Period.Yesterday),
        createDropdownOption('This week', Period.ThisWeek),
        createDropdownOption('Last week', Period.LastWeek),
        createDropdownOption('This month', Period.ThisMonth),
        createDropdownOption('Last month', Period.LastMonth),
        createDropdownOption('This quarter', Period.ThisQuarter),
        createDropdownOption('Last quarter', Period.LastQuarter),
        createDropdownOption('This year', Period.ThisYear),
        createDropdownOption('Last year', Period.LastYear),
        ...DATE_DROPDOWN_OPTIONS
      ],
      default: {
        'created.at#datetime': `today=${format(new Date(), ISO_FORMAT)}`
      }
    },
    {
      label: 'Due',
      name: 'fields.date.iso.0',
      type: FieldType.DateTime,
      options: [
        createDropdownOption('Next year', Period.NextYear),
        createDropdownOption('Next quarter', Period.NextQuarter),
        createDropdownOption('Next month', Period.NextMonth),
        createDropdownOption('Next week', Period.NextWeek),
        createDropdownOption('Tomorrow', Period.Tomorrow),
        createDropdownOption('Today', Period.Today),
        createDropdownOption('Yesterday', Period.Yesterday),
        createDropdownOption('This week', Period.ThisWeek),
        createDropdownOption('Last week', Period.LastWeek),
        createDropdownOption('This month', Period.ThisMonth),
        createDropdownOption('Last month', Period.LastMonth),
        createDropdownOption('This quarter', Period.ThisQuarter),
        createDropdownOption('Last quarter', Period.LastQuarter),
        createDropdownOption('This year', Period.ThisYear),
        createDropdownOption('Last year', Period.LastYear),
        ...DATE_DROPDOWN_OPTIONS
      ],
      default: {
        'fields.date.iso.0#datetime': `today=${format(new Date(), ISO_FORMAT)}`
      }
    },
    {
      label: 'Price',
      name: 'price',
      type: FieldType.Currency,
      options: [ ...CURRENCY_DROPDOWN_OPTIONS ],
      default: {
        'price#currency': 'less-than=0'
      }
    },
    {
      label: 'Fulfiller',
      name: 'fulfiller._id',
      type: FieldType.Dropdown,
      queryType: 'or',
      items: [
        {
          ...createDropdownOption('Unmatched', 'null'),
          changeKeyTo: 'fulfillerId'
        },
        ...((state.fulfillers.data && state.fulfillers.data.map((teamMember: Fulfiller) => {
          return createDropdownOption(`${teamMember.firstName} ${teamMember.lastName}`, teamMember._id);
        })) || [])
      ]
    }
  ].filter((option): boolean => {
    if (concealJobPrice(userData.user!)) {
      if (option.name === 'price') {
        return false;
      }
    }

    return true;
  }) as FilterOptions['fields'];

  const {
    renderPaginationAndSort: paginationMarkup,
    renderFilterControl: filterControlMarkup,
    renderAppliedFilters,
    filterApplied,
    filterState,
    setInitialFilterState
  } = useSortFilter({
    listItemId: 'Job',
    total: state.jobs.total,
    offset: state.jobs.offset,
    onPrev: (opts) => {
      fetchJobs(opts);
    },
    onNext: (opts) => {
      fetchJobs(opts);
    },
    onReset: (opts) => {
      fetchJobs(opts);
    },
    onFilter: (opts) => {
      fetchJobs(opts);
    },
    onSave: (opts: any) => {
      saveJobsFilter(opts);
    },
    filterOptions: {
      fields: filterOptions
    },
    sortOptions: {
      options: [
        createDropdownOption('Due', 'fields.date.iso.0'),
        createDropdownOption('Submitted', 'created.at'),
        createDropdownOption(
          isFulfiller(userData.user!) ? 'Pay' : 'Price',
          isFulfiller(userData.user!) ? 'fulfillerPay' : 'price'
        )
      ],
      defaultSorting: {
        key: 'fields.date.iso.0',
        label: 'Due',
        direction: -1
      }
    }
  });

  filterStateCache.current = filterState;

  const { isMobile } = useMediaSizes();
  const navigate = useNavigate();
  const { clientId } = useParams();

  const isClientOnboarded: boolean = !!(state.client.data && state.client.data.isOnboarded);

  const onNew = useCallback(() => {
    navigate({ pathname: `/${clientId}/back-office/jobs/create` });
  }, [clientId, navigate]);

  // For all job state updates
  const updateExpandedForAllJobs = useCallback((incomingState: boolean, jobs: Job[]): State['jobs']['expanded'] => {
    return jobs.reduce((acc: any, curr: Job) => {
      if (!acc[curr._id]) {
        acc[curr._id] = incomingState;
      }

      return acc;
    }, {});
  }, []);

  const toggleExpandAll = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      jobs: {
        ...prevState.jobs,
        expanded: {
          ...updateExpandedForAllJobs(allJobsCollapsed, prevState.jobs.data!)
        }
      }
    }));
  }, [
    allJobsCollapsed,
    updateExpandedForAllJobs
  ]);

  const onCopyToNew = useCallback((job: Job) => {
    dispatch({
      type: Actions.JOB_TO_COPY,
      payload: getJobToCopy(job)
    });

    navigate({
      pathname: `/${clientId}/back-office/jobs/create`
    });
  }, [
    clientId,
    dispatch,
    navigate
  ]);

  const fetchJobs = useCallback((opts?: any) => {
    if (state.jobs.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      jobs: {
        ...prevState.jobs,
        loading: true
      }
    }));

    gatewayService.getJobs(clientId!, opts)
      .then((jobs: any) => {
        setState(prevState => ({
          ...prevState,
          jobs: {
            ...prevState.jobs,
            loading: false,
            total: jobs.total,
            data: [ ...jobs.data ],
            fields: jobs.fields,
            offset: jobs.offset,
            error: null,
            ...(allJobsExpanded && {
              expanded: {
                ...updateExpandedForAllJobs(true, jobs.data)
              }
            })
          }
        }));
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          jobs: {
            ...prevState.jobs,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.jobs,
    clientId,
    allJobsExpanded,
    updateExpandedForAllJobs,
    addToast
  ]);

  const fetchServices = useCallback(() => {
    if (state.services.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      services: {
        ...prevState.services,
        loading: true
      }
    }));

    gatewayService.getServices(clientId!)
      .then((servicesResponse: any) => {
        setState(prevState => ({
          ...prevState,
          services: {
            ...prevState.services,
            loading: false,
            total: servicesResponse.total,
            data: [
              ...JSON.parse(JSON.stringify(servicesResponse.data))
            ],
            fields: servicesResponse.fields,
            offset: servicesResponse.offset,
            error: null
          }
        }));
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          services: {
            ...prevState.services,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.services,
    clientId,
    addToast
  ]);

  const fetchTeamMembers = useCallback(() => {
    if (state.fulfillers.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      fulfillers: {
        ...prevState.fulfillers,
        loading: true
      }
    }));

    gatewayService.getFulfillers(clientId!)
      .then((teamMembersResponse: any) => {
        setState(prevState => ({
          ...prevState,
          fulfillers: {
            ...prevState.fulfillers,
            loading: false,
            total: teamMembersResponse.total,
            data: [
              ...JSON.parse(JSON.stringify(teamMembersResponse.data))
            ],
            fields: teamMembersResponse.fields,
            offset: teamMembersResponse.offset,
            error: null
          }
        }));
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          fulfillers: {
            ...prevState.fulfillers,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.fulfillers,
    clientId,
    addToast
  ]);

  const removeJob = useCallback((id: string, callback: (e?: NetworkError) => void) => {
    if (state.jobDelete.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      jobDelete: {
        ...prevState.jobDelete,
        loading: true
      }
    }));

    gatewayService.deleteJob(clientId!, id)
      .then(() => {
        setState(prevState => ({
          ...prevState,
          jobDelete: {
            ...prevState.jobDelete,
            loading: false,
            error: null
          }
        }));

        callback();

        addToast({
          type: 'success',
          content: 'Job deleted'
        });
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          jobDelete: {
            ...prevState.jobDelete,
            loading: false,
            error
          }
        }));

        callback(err);

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.jobDelete,
    clientId,
    addToast
  ]);

  const loadJobsFilter = useCallback(() => {
    const apiMethod = isFulfiller(userData.user!) ? gatewayService.getFulfiller : gatewayService.getUser;

    apiMethod(clientId!, userData.user!._id)
      .then((getUserResponse) => {
        // console.log('---jobs filter loaded');

        if (getUserResponse.data.jobsFilter) {
          setInitialFilterState(getUserResponse.data.jobsFilter);
        }

        setState(prevState => ({
          ...prevState,
          jobsFilterLoaded: true
        }));
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        console.log('---jobs filter load error', error.message);
      });
  }, [
    clientId,
    userData.user,
    setInitialFilterState
  ]);

  const saveJobsFilter = useCallback((opts: any) => {
    if (JSON.stringify(opts) === JSON.stringify(prevFilterStateCache.current)) {
      return;
    }

    const apiMethod = isFulfiller(userData.user!) ? gatewayService.updateFulfiller : gatewayService.updateUser;

    apiMethod(
      clientId!,
      userData.user!._id,
      {
        jobsFilter: opts
      }
    )
      .then((updateUserResponse) => {
        // console.log('---jobs filter saved');
        prevFilterStateCache.current = updateUserResponse.data.jobsFilter;
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        console.log('---jobs filter save error', error.message);
      });

    prevFilterStateCache.current = opts;
  }, [
    clientId,
    userData.user
  ]);

  // const updateJob = useCallback((id: string, payload: Partial<Job>) => {
  //   if (state.jobUpdate.loading) {
  //     return;
  //   }

  //   setState(prevState => ({
  //     ...prevState,
  //     serviceUpdate: {
  //       ...prevState.jobUpdate,
  //       id,
  //       loading: true
  //     }
  //   }));

  //   gatewayService.updateJob(match.params.clientId, id, payload)
  //     .then((job: any) => {
  //       const index: number = state.jobs.data!.findIndex((s: Job) => s._id === id);

  //       setState(prevState => ({
  //         ...prevState,
  //         jobs: {
  //           ...prevState.jobs,
  //           data: [
  //             ...prevState.jobs.data!.slice(0, index),
  //             {
  //               ...job.data
  //             },
  //             ...prevState.jobs.data!.slice(index + 1, prevState.jobs.total!),
  //           ]
  //         },
  //         jobUpdate: {
  //           ...prevState.jobUpdate,
  //           loading: false,
  //           data: job.data,
  //           error: null
  //         }
  //       }));
  //     })
  //     .catch((e) => {
  //       console.log('------in error', e);
  //       setState(prevState => ({
  //         ...prevState,
  //         jobUpdate: {
  //           ...prevState.jobUpdate,
  //           loading: false,
  //           error: new Error(e)
  //         }
  //       }));

  //       // TODO: show error
  //     });
  // }, [state.jobs.data, state.jobUpdate, match.params.clientId]);

  const fetchDefaultServiceFields = useCallback(() => {
    if (state.defaultServiceSteps.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      defaultServiceSteps: {
        ...prevState.defaultServiceSteps,
        loading: true
      }
    }));

    gatewayService.getDefaultServiceSteps(clientId!)
      .then((defaultServiceStepsResponse: any) => {
        setState(prevState => ({
          ...prevState,
          defaultServiceSteps: {
            loading: false,
            data: defaultServiceStepsResponse.data,
            error: null
          }
        }));
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          defaultServiceSteps: {
            ...prevState.defaultServiceSteps,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.defaultServiceSteps,
    clientId,
    addToast
  ]);

  const fetchClient = useCallback(() => {
    if (state.client.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      client: {
        ...prevState.client,
        loading: true
      }
    }));

    gatewayService.getClient(clientId!)
      .then((clientResponse: any) => {
        setState(prevState => ({
          ...prevState,
          client: {
            loading: false,
            data: clientResponse.data,
            error: null
          }
        }));
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          client: {
            ...prevState.client,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.client,
    clientId,
    addToast
  ]);

  const deleteJob = useCallback((id: string) => {
    removeJob(id, (e?: NetworkError) => {
      if (!e) {
        fetchJobs(filterState);
      }
    });
  }, [
    filterState,
    removeJob,
    fetchJobs
  ]);

  const onChevronClick = useCallback((e: any, id: string) => {
    e.stopPropagation();

    setState(prevState => ({
      ...prevState,
      jobs: {
        ...prevState.jobs,
        expanded: {
          ...prevState.jobs.expanded,
          [id]: !prevState.jobs.expanded[id]
        }
      }
    }));
  }, []);

  const onMenuClick = useCallback((e: any, id: string) => {
    e.stopPropagation();

    setActiveMenuId(id);
  }, []);

  const closePopupMenu = useCallback(() => {
    // TODO: component still mounted
    setActiveMenuId('');
  }, []);

  const onJobClick = useCallback((e: any, jobClientId: string, id: string) => {
    navigate({ pathname: `/${jobClientId}/back-office/jobs/${id}` });
  }, [navigate]);

  const onJobEdit = useCallback((jobId: string) => {
    navigate({ pathname: `/${clientId}/back-office/jobs/${jobId}/edit` });
  }, [
    navigate,
    clientId
  ]);

  const getGenericFormattedGroupName = useCallback((key: string, job: Job) => {
    const step: Step | undefined = (job.steps || []).find((s: Step) => s.name === key);

    return step ? step.label : key;
  }, []);

  const getFormattedGroupName = useCallback((key: string, job: any) => {
    const step: Step | undefined = job.service.steps.find((s: Step) => s.name === key);

    return step ? step.label : key;
  }, []);

  const getGenericFormattedFieldName = useCallback((key: string, job: Job) => {
    const fields = (job.steps || []).reduce((acc: Field[], curr: Step) => {
      return [ ...acc, ...curr.fields ];
    }, []);

    const field: Field | undefined = fields.find((f: Field) => f.name === key);

    return field ? field.label : key;
  }, []);

  const getFormattedFieldName = useCallback((key: string, job: Job) => {
    const fields = job.service!.steps.reduce((acc: Field[], curr: Step) => {
      return [ ...acc, ...curr.fields ];
    }, []);

    const field: Field | undefined = fields.find((f: Field) => f.name === key);

    return field ? field.label : key;
  }, []);

  const renderJobDetails = useCallback((job: Job) => {
    const fieldKeys: string[] = Object
      .keys(job.fields)
      .filter((key: string) => key !== 'date');

    return (
      <>
        <JobDetailsOuter>
          <JobDetailsInner groupCount={fieldKeys.length}>
            {fieldKeys.map((key: string) => (
              <GroupDetails key={key}>
                <GroupDetailsHeader>{getFormattedGroupName(key, job)}</GroupDetailsHeader>
                <div>
                  {Object.keys(job.fields[key]).map((fieldKey: string) => {
                    const fieldValue = job.fields[key][fieldKey];

                    return (
                      <GroupFields key={fieldKey}>
                        <FieldLabel>{getFormattedFieldName(fieldKey, job)}:</FieldLabel>
                        <FieldValue>{fieldValue}</FieldValue>
                      </GroupFields>
                    );
                  })}
                </div>
              </GroupDetails>
            ))}
          </JobDetailsInner>
        </JobDetailsOuter>
        <br/>
        <br/>
        <JobDetailsFooter>
          <AdminFormLine
            row
            spaceBetween
          >
            <div>
              <i>Submitted by {job.byAdmin ? 'admin' : 'customer'}</i>
            </div>
            <div>
              <FieldLabel>ID: </FieldLabel>
              <span>{job._id}</span>
            </div>
          </AdminFormLine>
        </JobDetailsFooter>
      </>
    );
  }, [
    getFormattedGroupName,
    getFormattedFieldName
  ]);

  const renderGenericJobDetails = useCallback((job: Job) => {
    if (!state.defaultServiceSteps.data) {
      return null;
    }

    const fieldKeys: string[] = Object
      .keys(job.fields)
      .filter((key: string) => key !== 'date');

    return (
      <>
        <JobDetailsOuter>
          <JobDetailsInner groupCount={fieldKeys.length}>
            {fieldKeys.map((key: string) => (
              <GroupDetails key={key}>
                <GroupDetailsHeader>{getGenericFormattedGroupName(key, job)}</GroupDetailsHeader>
                <div>
                  {Object.keys(job.fields[key]).map((fieldKey: string) => {
                    const fieldValue = job.fields[key][fieldKey];

                    return (
                      <GroupFields key={fieldKey}>
                        <FieldLabel>{getGenericFormattedFieldName(fieldKey, job)}:</FieldLabel>
                        <FieldValue>{fieldValue}</FieldValue>
                      </GroupFields>
                    );
                  })}
                </div>
              </GroupDetails>
            ))}
          </JobDetailsInner>
        </JobDetailsOuter>
        <br/>
        <br/>
        <JobDetailsFooter>
          <AdminFormLine
            row
            spaceBetween
          >
            <div>
              <i>Submitted by {job.byAdmin ? 'admin' : 'customer'}</i>
            </div>
            <div>
              <FieldLabel>ID: </FieldLabel>
              <span>{job._id}</span>
            </div>
          </AdminFormLine>
        </JobDetailsFooter>
      </>
    );
  }, [
    state.defaultServiceSteps.data,
    getGenericFormattedGroupName,
    getGenericFormattedFieldName
  ]);

  const renderJobs = useCallback(() => {
    if (state.jobs.loading || state.client.loading) {
      return (
        <Spinner
          color={theme.textColor}
          size={'M'}
        />
      );
    }

    if (state.jobs.error || state.services.error) {
      return null;
    }

    if (!state.jobs.data || !state.client.data) {
      return null;
    }

    if (isListEmpty(state.jobs.data)) {
      if (filterApplied) {
        return (
          <>
            <AdminFormLine marginBottom>
              {renderAppliedFilters()}
            </AdminFormLine>
            <AdminFormLine
              column
              centerH
            >
              <AdminFormLine marginBottom>
                <NoDataIcon />
              </AdminFormLine>
              <AdminFormLine marginBottom>
                <NoDataCaption>No jobs found. Try a different filter.</NoDataCaption>
              </AdminFormLine>
            </AdminFormLine>
          </>
        );
      } else {
        return (
          <AdminFormLine
            column
            centerH
          >
            <AdminFormLine marginBottom>
              <NoDataIcon />
            </AdminFormLine>
            <AdminFormLine marginBottom>
              <NoDataCaption>No jobs just yet</NoDataCaption>
            </AdminFormLine>
            {!isFulfiller(userData.user!) && (
              <PrimaryButton
                disabled={!isClientOnboarded}
                onClick={onNew}
              >Create your first job!</PrimaryButton>
            )}
          </AdminFormLine>
        );
      }
    }

    return (
      <>
        <AdminFormLine marginBottom>
          {renderAppliedFilters()}
        </AdminFormLine>
        <AdminFormLine marginBottom>
          {paginationMarkup()}
        </AdminFormLine>
        {state.jobs.data.map((job: Job) => {
          const isMatched: boolean = !!job.fulfillerId?.length;
          const fulfillerExists: boolean = !!job.fulfiller;
          const fulfillerFormatted = fulfillerExists ? `${job.fulfiller?.firstName} ${job.fulfiller?.lastName}` : isMatched && !fulfillerExists ? 'User not found' : 'Unmatched';
          const isGenericJob: boolean = job.serviceId === 'none';
          const isServiceDeleted: boolean = !!job.service?.isDeleted;

          return (
            <JobCard
              key={job._id}
              column
              boxShadow
              centerV
              onClick={(e: any) => onJobClick(e, job.clientId, job._id)}
            >
              <JobCardColumn
                row
                noMarginRight
              >
                <JobCardColumn>
                  <IconButton
                    hoverEffect
                    onClick={(e: any) => onChevronClick(e, job._id)}
                  >
                    <StyledChevron $down={state.jobs.expanded[job._id]}/>
                  </IconButton>
                </JobCardColumn>
                <JobCardColumn grow row spaceAround stack>
                  <JobCardColumn alignStart>
                    <div>
                      <AdminFormLine style={{ marginBottom: '.5rem' }}>
                        {isGenericJob ? (
                          <b>Generic Job</b>
                        ) : isServiceDeleted ? (
                          <s>{job.service?.name ?? ''}</s>
                        ) : (
                          <StyledLink
                            to={{ pathname: `/${job.clientId}/back-office/services/${job.serviceId}` }}
                            onClick={(e: any) => e.stopPropagation() }
                          >
                            <b>{job.service?.name ?? ''}</b>
                          </StyledLink>
                        )}
                      </AdminFormLine>
                      <AdminFormLine style={{ marginBottom: '.5rem' }}>
                        <Clock style={{
                          width: '1.75rem',
                          height: 'auto',
                          marginRight: '.5rem',
                          flexShrink: 0
                        }} />
                        <span
                          style={{
                            ...(isJobOverdue(job) && {
                              textDecoration: `.2rem underline ${theme.colors.accentTertiary}`
                            })
                          }}
                        >
                          <span>{formatRelative(
                            stripZoneFromISOString(job.fields.date.iso[0]),
                            new Date()
                          )}</span>
                          <span>&nbsp;|&nbsp;</span>
                          <span>{getJobDuration(job)}</span>
                        </span>
                      </AdminFormLine>
                      <AdminFormLine style={{ marginBottom: '.5rem' }}>
                        <QuickReference style={{
                          width: '1.75rem',
                          height: 'auto',
                          marginRight: '.5rem',
                          flexShrink: 0
                        }} />
                        <span>{job.reference}</span>
                      </AdminFormLine>
                    </div>
                  </JobCardColumn>
                  <JobCardColumn alignCenter>
                    <div>
                      {!concealJobPrice(userData.user!) && (
                        <AdminFormLine style={{ marginBottom: '.5rem' }}>
                          <Payments style={{
                            width: '1.75rem',
                            height: 'auto',
                            marginRight: '.5rem',
                            flexShrink: 0,
                            transform: 'rotateZ(45deg)'
                          }} />
                          <span>{formatCurrency(job.price)}</span>
                        </AdminFormLine>
                      )}
                      {isFulfiller(userData.user!) && (
                        <AdminFormLine style={{ marginBottom: '.5rem' }}>
                          <Payments style={{
                            width: '1.75rem',
                            height: 'auto',
                            marginRight: '.5rem',
                            flexShrink: 0,
                            transform: 'rotateZ(45deg)'
                          }} />
                          <span>{formatCurrency(job.fulfillerPay)}</span>
                        </AdminFormLine>
                      )}
                      <AdminFormLine style={{ marginBottom: '.5rem' }}>
                        <Worker style={{
                          width: '1.75rem',
                          height: 'auto',
                          marginRight: '.5rem',
                          flexShrink: 0
                        }} />
                        <span>
                          {fulfillerFormatted}
                          {!isFulfiller(userData.user!) && (
                            <span>&nbsp;|&nbsp;{job.fulfillerPayPercentage}%</span>
                          )}
                        </span>
                      </AdminFormLine>
                    </div>
                  </JobCardColumn>
                  <JobCardColumn alignCenter>
                    <div>
                      <JobStatus
                        state={getStatusState(job.status.slice(-1)[0].type)}
                      >{formatJobStatus(job.status.slice(-1)[0].type)}</JobStatus>
                    </div>
                  </JobCardColumn>
                </JobCardColumn>
                <JobCardColumn noMarginRight>
                  <IconButton
                    hoverEffect
                    onClick={(e: any) => onMenuClick(e, job._id)}
                  >
                    <StyledMenuDots />
                    {activeMenuId === job._id && (
                      <Popup
                        id={job._id}
                        left
                        bottom
                        convertable
                        onClose={closePopupMenu}
                      >
                        {({ closePopup }) => (
                          <MenuWrapper>
                            {canUpdateJob(clientId!, userData.user!, job) && (
                              <MenuItem onClick={() => {
                                closePopup();
                                onJobEdit(job._id);
                              }}>Edit</MenuItem>
                            )}
                            {canHandlePayments(
                              state.client.data,
                              userData.user
                            ) && (
                              <MenuItem
                                onClick={() => {
                                  if (!state.client.data!.isOnboarded) {
                                    return addToast({
                                      type: 'error',
                                      content: 'Please complete all the steps on the homepage to fully get setup'
                                    });
                                  }

                                  closePopup();

                                  const paymentPageIndex: number = job.steps?.length ? 4 : job.service!.steps.length;

                                  copyText(
                                    getPaymentLink(
                                      job.clientId,
                                      job.serviceId,
                                      paymentPageIndex,
                                      job._id,
                                    )
                                  )
                                    .then(() => {
                                      addToast({
                                        type: 'success',
                                        content: 'Payment link copied'
                                      });
                                    })
                                    .catch(() => {
                                      addToast({
                                        type: 'error',
                                        content: 'Error copying payment link. Please copy manually.'
                                      });
                                    });
                                }}
                              >Copy payment link</MenuItem>
                            )}
                            {canCreateJob(clientId!, userData.user!) && (
                              <MenuItem onClick={() => {
                                closePopup();
                                onCopyToNew(job);
                              }}>Copy to new</MenuItem>
                            )}
                            {canDeleteJob(clientId!, userData.user!, job) && (
                              <MenuItem onClick={() => {
                                closePopup();
                                deleteJob(job._id);
                              }}>Delete</MenuItem>
                            )}
                          </MenuWrapper>
                        )}
                      </Popup>
                    )}
                  </IconButton>
                </JobCardColumn>
              </JobCardColumn>
              <JobDetails
                isExpanded={state.jobs.expanded[job._id]}
                onClick={(e: any) => e.stopPropagation()}
              >
                {isGenericJob ? renderGenericJobDetails(job) : renderJobDetails(job)}
              </JobDetails>
            </JobCard>
          );
        })}
        {paginationMarkup(true)}
      </>
    );
  }, [
    isClientOnboarded,
    userData.user,
    clientId,
    state.jobs,
    state.client,
    state.services,
    activeMenuId,
    filterApplied,
    closePopupMenu,
    onChevronClick,
    onMenuClick,
    onJobClick,
    onJobEdit,
    renderJobDetails,
    renderGenericJobDetails,
    paginationMarkup,
    renderAppliedFilters,
    deleteJob,
    addToast,
    onNew,
    onCopyToNew
  ]);

  useEffect(() => {
    if (!state.jobs.data && !state.jobs.error) {
      if (state.jobsFilterLoaded) {
        fetchJobs(filterState);
      }
    }

    if (!state.services.data && !state.services.error) {
      fetchServices();
    }

    if (!state.fulfillers.data && !state.fulfillers.error) {
      fetchTeamMembers();
    }

    if (!state.defaultServiceSteps.data && !state.defaultServiceSteps.error) {
      fetchDefaultServiceFields();
    }

    if (!state.client.data && !state.client.error) {
      fetchClient();
    }
  }, [
    filterState,
    state.jobsFilterLoaded,
    state.jobs.data,
    state.jobs.error,
    state.services.data,
    state.services.error,
    state.fulfillers.data,
    state.fulfillers.error,
    state.defaultServiceSteps.data,
    state.defaultServiceSteps.error,
    state.client.data,
    state.client.error,
    fetchJobs,
    fetchServices,
    fetchTeamMembers,
    fetchDefaultServiceFields,
    fetchClient
  ]);

  useEffect(() => {
    if (!isClientOnboarded && state.client.data && !isFulfiller(userData.user!)) {
      addToast({
        type: 'info',
        content: 'Please complete all the steps on the homepage to fully get setup'
      });
    }
  }, [
    isClientOnboarded,
    state.client.data,
    userData.user,
    addToast
  ]);

  useEffect(() => loadJobsFilter(), [loadJobsFilter]);

  return (
    <Wrapper>
      <PageHeader
        title={'Jobs'}
        rightContent={
          <>
            {(
              (!isListEmpty(state.jobs.data) && !state.jobs.error && !state.services.error)
                || (filterApplied && isListEmpty(state.jobs.data))
            ) && (
              <>
                <Button
                  style={{marginBottom: 0}}
                  icon={
                    !isMobile ? undefined : allJobsCollapsed ? (
                      <UnfoldMore style={{ width: '1.75rem', height: 'auto' }} />
                    ) : (
                      <UnfoldLess style={{ width: '1.75rem', height: 'auto' }} />
                    )
                  }
                  onClick={toggleExpandAll}
                  disabled={!state.jobs.data || (state.jobs.data && state.jobs.data.length === 0)}
                >{isMobile ? '' : ((allJobsCollapsed ? 'Expand' : 'Collapse').concat(' all'))}</Button>
                {filterControlMarkup(sideNavOpen, true)}
              </>
            )}
            {canCreateJob(clientId!, userData.user!) && (
              <Button
                style={{marginBottom: 0}}
                icon={<StyledPlus />}
                disabled={!isClientOnboarded}
                onClick={onNew}
              >{isMobile ? '' : 'New'}</Button>
            )}
          </>
        }
      />
      {renderJobs()}
    </Wrapper>
  );
};

export default memo(Jobs);

