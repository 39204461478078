import styled from 'styled-components';

import { device } from 'theme/media';
import {
  Arrow,
  Percentage,
  Options,
  Tick,
  Professor,
  Security,
  Lock,
  Shield,
  Billing
} from 'components/atoms';

import { FormLine } from 'theme/mixins';
export * from 'theme/mixins';

const SVG_ANIMATION_DURATION: number = .75;

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;

export const Text = styled.p`
  font-size: 1.6rem;
`;

export const TopContainer = styled.section`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 50%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  z-index: 0;
  transition: background-image 1s ease-in-out;
`;

export const ContentContainer = styled.section`
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;

  h2 {
    margin-bottom: 2rem;
    font-size: 2.8rem;

    @media ${device.tablet} {
      position: sticky;
      top: 1.95rem;

      &.pinned {
        color: ${({ theme }) => theme.colors.neutralSecondary};
      }
    }
  }

  @media ${device.mobile} {
    width: 100%;
  }

  @media ${device.mobileL} {
    width: unset;
  }
`;

export const TypeWriter = styled.div`
  display: inline;
  position: relative;
  color: ${({ theme }) => theme.colors.coreSecondary};
  z-index: 1;
  text-align: center;
  padding: 2rem;

  i {
    display: inline-block;
    position: absolute;
    width: 0.08em;
    background-color: ${({ theme }) => theme.colors.coreSecondary};
  }

  @media ${device.mobile} {
    font-size: 2.5rem;
    line-height: 2.9rem;
    max-width: 50rem;

    i {
      height: 2.9rem;
    }
  }

  @media ${device.tablet} {
    font-size: 3rem;
    line-height: 3.1rem;
    max-width: 60rem;

    i {
      height: 3.1rem;
    }
  }

  @media ${device.laptop} {
    font-size: 5rem;
    line-height: 5.8rem;
    max-width: 80rem;

    i {
      height: 5.8rem;
    }
  }

  @media ${device.laptopL} {
    max-width: 100rem;
  }
`;

export const Block = styled.div<{ altTheme?: boolean }>`
  display: flex;
  position: relative;
  padding: 10rem 4rem;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutralSecondary};

  ${({ theme, altTheme }) => altTheme && `
    background-color: ${theme.colors.neutralPrimaryDark1};

    p, h2 {
      color: ${theme.colors.coreSecondary};
    }
  `}
`;

export const ScrollBtn = styled.div<{ bottom?: boolean }>`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  left: 5rem;
  bottom: 5rem;
  width: 4.4rem;
  height: 4.4rem;
  border: 1.5px solid ${({ theme }) => theme.colors.coreSecondary};
  border-radius: 100%;
  color: ${({ theme }) => theme.colors.coreSecondary};
  opacity: 0.5;
  cursor: pointer;
  transition: opacity ${({ theme }) => theme.animSpeed} ease-in-out;
  
  svg {
    transform: rotate(90deg);
  }

  ${({ bottom }) => bottom && `
    top: 5rem;

    svg {
      transform: rotate(-90deg);
    }

    @media ${device.mobile} {
      top: 2rem;
      left: 2rem;
    }

    @media ${device.tablet} {
      top: 5rem;
      left: 5rem;
    }
  `}

  :hover {
    opacity: 1;
  }
`;

export const StyledArrow = styled(Arrow)``;

export const StyledOptions = styled(Options)`
  height: auto;
`;

export const WhatTextBlock = styled(FormLine)`
  @media ${device.mobile} {
    margin-top: 5rem;
  }

  @media ${device.tablet} {
    margin-left: 10rem;
    margin-top: 0rem;
    justify-content: center;
  }

  @media ${device.laptop} {
    margin-left: 6.25rem;
  }

  @media ${device.laptopM} {
    margin-left: 12.5rem;
  }

  @media ${device.laptopL} {
    margin-left: 25rem;
  }
`;

export const StyledTick = styled(Tick)`
  height: auto;
  margin-right: 1rem;
  fill: #09a109;
  flex-shrink: 0;
`;

export const WhatFeatures = styled(FormLine)`
  width: 100%;
  margin-top: 2rem;
`;

export const WhatOuter = styled(FormLine)`
  @media ${device.mobile} {
    margin-top: 5rem;
    align-items: center;

    ${StyledOptions} {
      width: 26rem;
    }
  }

  @media ${device.tablet} {
    flex-direction: row;
    margin-top: 10rem;

    ${StyledOptions} {
      width: 40rem;
    }
  }

  ${WhatFeatures} {
    ${FormLine} {
      opacity: 0;
      transform: translateY(-3rem);
    }
  }

  ${StyledOptions} {
    circle:nth-of-type(7) {
      animation: ${SVG_ANIMATION_DURATION * 3}s linear 0s infinite alternate feature-knob-1;
    }
    circle:nth-of-type(8) {
      animation: ${SVG_ANIMATION_DURATION * 3}s linear 0s infinite alternate feature-knob-2;
    }
    circle:nth-of-type(9) {
      animation: ${SVG_ANIMATION_DURATION * 3}s linear 0s infinite alternate feature-knob-3;
    }
  }
`;

export const StyledProfessor = styled(Professor)`
  height: auto;
`;

export const HowList = styled.ol`
  @media ${device.mobile} {
    margin-top: 5rem;
    max-width: 35rem;
  }

  @media ${device.tablet} {
    max-width: 36rem;
    padding-right: 5rem;
    margin-top: 0rem;
    margin-left: 5rem;
  }

  @media ${device.laptop} {
    max-width: 45rem;
    padding-right: 0rem;
    margin-right: 0rem;
    justify-content: center;
  }

  @media ${device.laptopM} {
    margin-right: 10rem;
  }

  @media ${device.laptopL} {
    margin-right: 20rem;
  }
`;

export const HowListItem = styled.li`
  margin-bottom: 1.75rem;

  &::marker {
    font-size: 1.6rem;
    font-weight: bold;
  }
`;

export const HowOuter = styled(FormLine)`
  @media ${device.mobile} {
    align-items: center;
    flex-direction: column-reverse;

    ${StyledProfessor} {
      width: 26rem;
      margin-top: 5rem;
    }
  }

  @media ${device.tablet} {
    flex-direction: row;
    margin-top: 10rem;

    ${StyledProfessor} {
      width: 30rem;
      margin-top: 0rem;
    }
  }

  @media ${device.laptop} {
    ${StyledProfessor} {
      width: 50rem;
    }
  }

  ${StyledProfessor} {
    line {
      opacity: 0;
      transform: translateY(-3rem);
    }
  }

  ${HowList} {
    ${HowListItem} {
      opacity: 0;
      transform: translateY(-3rem);
    }
  }
`;

export const StyledPercentage = styled(Percentage)`
  height: auto;

  @media ${device.mobile} {
    margin-top: 5rem;
  }

  @media ${device.tablet} {
    margin-top: 0rem;
  }
`;

export const PricingOuter = styled(FormLine)`
  @media ${device.mobile} {
    margin-top: 5rem;

    ${StyledPercentage} {
      width: 26rem;
    }
  }

  @media ${device.tablet} {
    flex-direction: row;
    margin-top: 10rem;

    ${StyledPercentage} {
      width: 30rem;
    }
  }

  @media ${device.laptop} {
    ${StyledPercentage} {
      width: 40rem;
    }
  }
`;

export const PricingTextBlock = styled(FormLine)`
  @media ${device.mobile} {
    align-items: center;
    text-align: center;
  }

  @media ${device.tablet} {
    margin-right: 10rem;
    justify-content: center;
  }

  @media ${device.laptop} {
    margin-right: 25rem;
  }
`;

export const StyledSecurity = styled(Security)`
  height: auto;

  @media ${device.onlyMobile} {
    margin-bottom: 5rem;
  }
`;

export const SecurityList = styled(FormLine)`
  width: 100%;
  margin-top: 2rem;
  text-align: left;

  svg {
    margin-right: 1rem;
    flex-shrink: 0;
  }

  @media ${device.mobile} {
    max-width: 35rem;
  }

  @media ${device.tablet} {
    max-width: 36rem;
  }

  @media ${device.laptop} {
    max-width: 45rem;
  }
`;

export const SecurityOuter = styled(FormLine)`
  @media ${device.mobile} {
    margin-top: 5rem;
    align-items: center;

    ${StyledSecurity} {
      width: 26rem;
    }
  }

  @media ${device.tablet} {
    flex-direction: row;
    margin-top: 10rem;

    ${StyledSecurity} {
      width: 30rem;
    }
  }

  @media ${device.laptop} {
    ${StyledSecurity} {
      width: 40rem;
    }
  }

  ${StyledSecurity} {
    g.line {
      opacity: 0;
      transform: translateY(-3rem);
    }

    g.lock {
      transform-box: fill-box;
      transform-origin: center;
      transform: rotateZ(0deg);
    }
  }

  ${SecurityList} {
    ${FormLine} {
      opacity: 0;
      transform: translateY(-3rem);
    }
  }

  g.lock {
    animation: ${SVG_ANIMATION_DURATION / 4}s ease-in-out 0s infinite normal forwards lock;
  }
`;

export const SecurityTextBlock = styled(FormLine)`
  @media ${device.mobile} {
    align-items: center;
    text-align: center;

    h3 {
      width: 100%;
    }
  }

  @media ${device.tablet} {
    margin-left: 10rem;
    justify-content: center;

    h3 {
      text-align: left;
    }
  }

  @media ${device.laptop} {
    margin-left: 25rem;
  }
`;

export const StyledShield = styled(Shield)`
`;

export const StyledBilling = styled(Billing)`
`;

export const StyledLock = styled(Lock)`
`;

