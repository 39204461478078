import React, { FC } from 'react'

import { Container, SectionHeader, SectionBody } from './FormSection.styles'

export interface FormSectionProps {
  children: React.ReactNode;
  className?: string;
  headerText?: string;
  cols?: number;
  noBorder?: boolean;
  noPadding?: boolean;
}

const FormSection: FC<FormSectionProps> = props => {
  const {
    headerText,
    cols,
    noBorder,
    noPadding,
    children,
    ...rest
  } = props

  return (
    <Container
      className={rest.className}
      noBorder={noBorder}
      noPadding={noPadding}
    >
      {headerText && <SectionHeader>{headerText}</SectionHeader>}
      <SectionBody cols={cols}>{children}</SectionBody>
    </Container>
  )
}

export default FormSection
