import styled from 'styled-components';
import { device } from 'theme/media';

import { Plus, MenuDots, Payments } from 'components/atoms';
import { Card } from 'theme/mixins';

export const Wrapper = styled.div`
  display: flex;
  width: inherit;
  height: auto;
  flex-direction: column;
`;

export const StyledPlus = styled(Plus)`
  fill: ${({ theme }) => theme.textColor};
`;

export const StatusIndicator = styled.i<{ isEnabled: boolean; }>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  font-style: normal;
  margin-right: 1rem;

  ${({ isEnabled }) => `
    :after {
      content: '';
      display: block;
      top: 0;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background-color: ${isEnabled ? 'green' : 'red'}
    }
  `}
`;

export const ClientCard = styled(Card)`
  margin-bottom: 2rem;
  cursor: pointer;

  @media ${device.mobile} {
    padding: 2rem;
  }

  @media ${device.tablet} {
    padding: 3rem;
  }
`;

export const ContentWrapper = styled.div<{ column?: boolean; centerV?: boolean; }>`
  display: flex;
  justify-content: flex-start;

  @media ${device.mobile} {
    :not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  @media ${device.tablet} {
    :not(:last-child) {
      margin-bottom: unset;
      margin-right: 1rem;
    }
  }

  ${({ column }) => column && `
    flex-direction: column;
  `}

  ${({ centerV }) => centerV && `
    align-items: center;
  `}
`;

export const NameAndStatus = styled(ContentWrapper)`
  display: flex;

  @media ${device.tablet} {
    ${ContentWrapper} {
      max-width: 35rem;
    }
  }
`;

export const StyledMenuDots = styled(MenuDots)`
  fill: ${({ theme }) => theme.colors.corePrimaryDarker};
  transform: rotateZ(90deg);
`;

export const StyledPayments = styled(Payments)<{ state: boolean; }>`
  transform: rotateZ(45deg);

  ${({ theme, state }) => !state && `
    fill: ${theme.colors.neutralPrimaryDark1};
  `}
`;

export const ClientCardOuter = styled.div`
  display: flex;
`;

export const ClientCardLeftColumnInner = styled(ContentWrapper)`
  @media ${device.tablet} {
    ${ContentWrapper} {
      align-items: center;
    }
  }
`;

export const ClientCardColumn = styled.div<{ grow?: boolean; }>`
  display: flex;
  flex-direction: row;

  ${({ grow }) => grow && `
    flex: 1;
  `}

  @media ${device.tablet} {
    ${ContentWrapper} {
      flex-direction: row;
    }
  }
`;

export const FieldLabel = styled.span`
  flex: 1;
  text-align: left;
  color: ${({ theme }) => theme.colors.neutralPrimaryDarker};
  word-break: break-word;
`;

