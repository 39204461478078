import React, { FC, memo, useMemo } from 'react';

import { PageHeader } from '../../components';
import { AdminPageProps } from 'components/AppRouter';
import {
  isPlatformAdmin,
  isPlatformReviewer,
  isClientAdmin,
  isClientReviewer,
  isFulfiller
} from 'config/privileges';

import { PlatformDashboard } from './platform';
import { ClientDashboard } from './client';
import { FulfillerDashboard } from './fulfiller';
import {
  Wrapper,
} from './RootDashboard.styles';

interface Props extends AdminPageProps {
  noClientSelected: boolean;
}

const Dashboard: FC<Props> = props => {
  const {
    userData,
    noClientSelected
  } = props;

  const shouldRenderPlatformDashboard = useMemo(() => {
    return noClientSelected
      && userData.user
      && (
        isPlatformAdmin(userData.user)
        || isPlatformReviewer(userData.user)
      );
  }, [
    noClientSelected,
    userData.user
  ]);

  const shouldRenderClientDashboard = useMemo(() => {
    return !noClientSelected
      && userData.user
      && (
        isClientAdmin(userData.user)
        || isClientReviewer(userData.user)
        || isPlatformAdmin(userData.user)
        || isPlatformReviewer(userData.user)
      );
  }, [
    noClientSelected,
    userData.user
  ]);

  const shouldRenderFulfillerDashboard = useMemo(() => {
    return userData.user && isFulfiller(userData.user);
  }, [userData.user]);

  return (
    <Wrapper>
      <PageHeader
        title={`${shouldRenderPlatformDashboard ? 'Platform dashboard' : 'Dashboard'}`}
      />
      {shouldRenderPlatformDashboard && (
        <PlatformDashboard {...props} />
      )}
      {shouldRenderClientDashboard && (
        <ClientDashboard {...props} />
      )}
      {shouldRenderFulfillerDashboard && (
        <FulfillerDashboard {...props} />
      )}
    </Wrapper>
  );
};

export default memo(Dashboard);
