import styled from 'styled-components';
import { Link } from 'react-router-dom';

import {
  Tick,
  Cross
} from 'components/atoms';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  background: ${({ theme }) => theme.colors.neutralSecondary};
  z-index: ${({ theme }) => theme.layers.highest};
`;

export const StyledIconWrapper = styled.div<{
  left?: boolean;
  right?: boolean;
}>`
  display: flex;
  position: absolute;
  top: -1.5rem;
  width: 3rem;
  height: 3rem;
  border-radius: 1.5rem;
  background-color: ${({ theme }) => theme.colors.corePrimary};
  opacity: 0;
  transition: opacity ${({ theme }) => theme.animSpeed};
  cursor: pointer;
  align-items: center;
  justify-content: center;

  ${({ left }) => left && `
    left: -1.5rem;
  `};

  ${({ right }) => right && `
    right: -1.5rem;
  `};
`;

export const EditWrapper = styled.div`
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: calc(100% + 2rem);
  height: calc(100% + 2rem);
  margin-left: -1rem;
  margin-top: -1rem;
  border: .2rem dashed ${({ theme }) => theme.colors.corePrimary};
  border-radius: .4rem;
  transition: opacity ${({ theme }) => theme.animSpeed};
`;

export const Step = styled.div<{
  stacked?: boolean;
  $active?: boolean;
  nextUp?: boolean;
  isLast?: boolean;
  completed?: boolean;
  stackedLineWidth: number;
}>`
  display: flex;
  position: relative;
  flex-direction: column;
  user-select: none;

  ${({ stacked }) => !stacked && `
    flex: 1;
  `};

  ${({ stacked }) => stacked && `
    width: 3rem;
  `};

  ${({ theme, isLast }) => !isLast && `
    :before {
      content: '';
      position: relative;
      left: 50%;
      top: 1.5rem;
      height: .2rem;
      margin-left: 2rem;
      margin-right: 2rem;

      transition: all ${theme.animSpeed} ease-in-out;
      background: linear-gradient(to right, ${theme.progress.accentColor} 50%, ${theme.progress.inCompleteColor} 50%);
      background-size: 200% 100%;
      background-position: right bottom;
    }
  `};

  ${({ isLast }) => isLast && `
    :before {
      content: '';
      position: relative;
      left: 50%;
      top: 1.5rem;
      width: 0;
      height: .2rem;
      margin-left: 2rem;
      margin-right: 2rem;
    }
  `};

  ${({ stacked, $active, stackedLineWidth }) => stacked && $active && `
    position: relative;
    // width: auto;
    width: 6rem;

    :before {
      position: absolute;
      width: ${stackedLineWidth}px;
    }
  `};

  ${({ completed }) => completed && `
    :before {
      background-position: left bottom;
    }
  `};

  ${({ stacked, nextUp }) => stacked && nextUp && `
    margin-left: auto;
  `};

  &:hover {
    ${EditWrapper} {
      opacity: 1;

      ${StyledIconWrapper} {
        opacity: 1;
      }
    }
  }
`;

export const Steps = styled.div<{ stacked?: boolean; }>`
  display: flex;
  width: 100%;
  margin: 2rem auto 2rem auto;
  flex-direction: row;
  justify-content: center;

  ${({ stacked }) => stacked && `
    justify-content: left;

    // ${Step} {
    //   flex: none;
    //   width: 1rem;
    //   // border: .1rem solid;
    // }
  `};
`;

export const StepOuter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StepInner = styled(Link)<{
  $active?: boolean;
  $completed?: boolean;
  to?: any;
  $isTruncated: boolean;
  $hasError: boolean;
}>`
  display: flex;
  position: relative;
  width: 3rem;
  height: 3rem;
  border-radius: 1.5rem;
  align-items: center;
  justify-content: center;
  cursor: default;
  text-decoration: none;
  color: inherit;

  ${({ to }) => to && to.pathname && `
    cursor: pointer;
  `};

  transition: all ${({ theme }) => theme.animSpeed} ease-in-out;
  transition-delay: ${({ theme }) => theme.animDelay};
  background: linear-gradient(to right, ${({ theme }) => theme.progress.activeColor} 50%, ${({ theme }) => theme.progress.inCompleteColor} 50%);
  background-size: 200% 100%;
  background-position: right bottom;

  ${({ theme, $active }) => $active && `
    color: ${theme.colors.neutralSecondary};
    background-position: left bottom;
    font-weight: 700;
  `};

  ${({ theme, $completed }) => $completed && `
    transition-delay: unset;
    background: ${theme.progress.completeColor};
  `};

  ${({ $isTruncated }) => $isTruncated && `
    &:before {
      content: '';
      position: absolute;
      left: 0.2rem;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: black;
      z-index: -1;
    }
  `};

  ${({ theme, $hasError }) => $hasError && `
    color: ${theme.colors.neutralSecondary};
    background: ${theme.colors.accentTertiary};
    transition-delay: unset;
  `};
`;

export const StepInfo = styled(Link)<{
  $active?: boolean;
  $completed?: boolean;
  to?: any;
  $hasError: boolean;
}>`
  display: flex;
  position: relative;
  flex-direction: column;
  text-align: center;
  margin-top: .75rem;
  color: ${({ theme }) => theme.progress.inCompleteTextColor};
  text-decoration: none;
  cursor: default;

  overflow: hidden;

  ${({ to }) => to && to.pathname && `
    cursor: pointer;
  `};

  span {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    transform: translateX(-100%);
    transition: transform ${({ theme }) => theme.animSpeed} ease-in-out;
    transition-delay: ${({ theme }) => theme.animDelay};

    &:before {
      display: inline-block;
      content: attr(data-content);
      color: ${({ theme }) => theme.progress.activeColor};
      transform: translateX(100%);
      transition: transform ${({ theme }) => theme.animSpeed} ease-in-out;
      transition-delay: ${({ theme }) => theme.animDelay};
    }
  }

  ${({ $completed, $active }) => ($completed || $active) && `
    span {
      transform: translateX(0);
      &:before {
        transform: translateX(0);
      }
    }
  `};

  ${({ theme, $hasError }) => $hasError && `
    span {
      &:before {
        color: ${theme.colors.accentTertiary};
      }
    }
  `};
`;

export const StyledTickWrapper = styled.div`
  display: flex;
  position: absolute;
  top: -.9rem;
  right: -.9rem;
  background-color: ${({ theme: thm }) => thm.colors.neutralSecondary};
  border-radius: 50%;
  padding: .2rem;
`;

export const StyledTick = styled(Tick)`
  width: 1.5rem;
  height: 1.5rem;

  fill: ${({ theme }) => theme.progress.accentColor};
`;

export const FloatingLabel = styled.div<{ left: number }>`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: .75rem;
  text-align: center;
  transform: translate(0, 0);
  transition: transform ${({ theme }) => theme.animSpeed} ease-in-out;
  transition-delay: ${({ theme }) => theme.animDelay};

  ${({ left }) => `
    transform: translate(${left}px, 0);
  `};
`;

export const StyledCross = styled(Cross)`
  width: 1.5rem;
  height: 1.5rem;
`;

