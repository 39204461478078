import React, { FC, memo, useCallback, useState, useRef, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';
import {
  format,
  formatDuration,
  intervalToDuration,
  isSameDay
} from 'date-fns';

import { AdminPageProps } from 'components/AppRouter';
import {
  Spinner,
  Dropdown
} from 'components/atoms';
import { CellType } from 'types/Header';
import { Event, EventType } from 'types/Event';
import {
  FulfillerInsights,
  FulfillerOverview
} from 'types/Fulfiller';
import { Period } from 'types/Generic';
import { NetworkError } from 'types/Error';
import { StatusType } from 'types/Job';

import { Table } from '../../../components';
import { theme } from 'theme';
import { gatewayService } from 'services';
import {
  animateValue,
  getRawCurrencyValue,
  formatCurrency,
  createDropdownOption,
  formatLabel,
  getNetworkErrors,
  isListEmpty,
  stripZoneFromISOString
} from 'utils/general';
import { ISO_FORMAT } from '../../../../../../constants';

import {
  Wrapper,
  AdminFormLine,
} from './FulfillerDashboard.styles';
import {
  StatSection
} from '../RootDashboard.styles';
import {
  Card,
  NoDataIcon,
  NoDataCaption,
  StatNumber,
  EventCancelledWrapper
} from 'theme/mixins';

interface State {
  insights: {
    loading: boolean;
    data: FulfillerInsights | null;
    error: NetworkError | null;
  };
  events: {
    loading: boolean;
    data: Event[] | null;
    error: NetworkError | null;
  };
  overview: {
    loading: boolean;
    data: FulfillerOverview | null;
    error: NetworkError | null;
  };
  insightPeriod: string;
}

const initialState: State = {
  insights: {
    loading: false,
    data: null,
    error: null
  },
  events: {
    loading: false,
    data: null,
    error: null
  },
  overview: {
    loading: false,
    data: null,
    error: null
  },
  insightPeriod: 'today'
};

const FulfillerDashboard: FC<AdminPageProps> = props => {
  const {
    userData,
    addToast
  } = props;

  const navigate = useNavigate();
  const params: any = useParams();

  const [state, setState] = useState<State>(initialState);
  const totalJobsRef = useRef<HTMLDivElement>(null);
  const totalCompletedJobsRef = useRef<HTMLDivElement>(null);
  const totalEarningsRef = useRef<HTMLDivElement>(null);
  const [prevInsightPeriod, setPrevInsightPeriod] = useState<string>(state.insightPeriod);

  const fetchInsights = useCallback(() => {
    if (state.insights.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      insights: {
        ...prevState.insights,
        loading: true
      }
    }));

    gatewayService.getFulfillerInsights(
      params.clientId,
      userData.user!._id,
      format(new Date(), ISO_FORMAT),
      state.insightPeriod
    )
      .then((insightResponse: any) => {
        setState(prevState => ({
          ...prevState,
          insights: {
            loading: false,
            data: insightResponse.data,
            error: null
          }
        }));
      })
      .catch((err) => {
        setState(prevState => ({
          ...prevState,
          insights: {
            ...prevState.insights,
            loading: false,
            error: getNetworkErrors([err])[0]
          }
        }));

        addToast({
          type: 'error',
          content: 'Something went wrong loading Insights'
        });
      });
  }, [
    state.insights,
    state.insightPeriod,
    params.clientId,
    userData.user,
    addToast
  ]);

  const fetchEvents = useCallback(() => {
    if (state.events.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      events: {
        ...prevState.events,
        loading: true
      }
    }));

    gatewayService.getEvents(
      params.clientId,
      format(new Date(), ISO_FORMAT),
      Period.Today
    )
      .then((eventsResponse: any) => {
        setState(prevState => ({
          ...prevState,
          events: {
            loading: false,
            data: eventsResponse.data,
            error: null
          }
        }));
      })
      .catch((err) => {
        setState(prevState => ({
          ...prevState,
          events: {
            ...prevState.events,
            loading: false,
            error: getNetworkErrors([err])[0]
          }
        }));

        addToast({
          type: 'error',
          content: 'Something went wrong loading Events'
        });
      });
  }, [
    state.events,
    params.clientId,
    addToast
  ]);

  const fetchOverview = useCallback(() => {
    if (state.overview.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      overview: {
        ...prevState.overview,
        loading: true
      }
    }));

    gatewayService.getFulfillerOverview(
      params.clientId,
      userData.user!._id
    )
      .then((overviewResponse: any) => {
        setState(prevState => ({
          ...prevState,
          overview: {
            loading: false,
            data: overviewResponse.data,
            error: null
          }
        }));
      })
      .catch((err) => {
        setState(prevState => ({
          ...prevState,
          overview: {
            ...prevState.overview,
            loading: false,
            error: getNetworkErrors([err])[0]
          }
        }));

        addToast({
          type: 'error',
          content: 'Something went wrong loading Overview'
        });
      });
  }, [
    state.overview,
    params.clientId,
    userData.user,
    addToast
  ]);

  const renderFulfillerInsights = useCallback(() => {
    if (state.insights.loading) {
      return (
        <Spinner
          color={theme.textColor}
          size={'M'}
        />
      );
    }

    if (!state.insights.data) {
      return null;
    }

    const totalJobsPCDirection: number = state.insights.data.totalJobsPC.value.startsWith('+') ? 1 : state.insights.data.totalJobsPC.value.startsWith('-') ? -1 : 0;
    const totalCompletedJobsPCDirection: number = state.insights.data.totalCompletedJobsPC.value.startsWith('+') ? 1 : state.insights.data.totalCompletedJobsPC.value.startsWith('-') ? -1 : 0;
    const totalGrossEarningsPCDirection: number = state.insights.data.totalGrossEarningsPC.value.startsWith('+') ? 1 : state.insights.data.totalGrossEarningsPC.value.startsWith('-') ? -1 : 0;

    return (
      <AdminFormLine marginBottom>
        <Card>
          <AdminFormLine
            row
            centerV
            spaceBetween
            marginBottom
            style={{paddingBottom: '.5rem'}}
          >
            <h3 style={{ marginBottom: 0 }}>Insights</h3>
            <Dropdown
              width={'14rem'}
              value={state.insightPeriod}
              options={[
                ...Object
                  .values(Period)
                  .filter(val => !val.includes('last')
                    && !val.includes('yesterday')
                    && !val.includes('next')
                    && !val.includes('tomorrow')
                  )
                  .map(val => createDropdownOption(formatLabel(val), val))
              ]}
              onChange={(option: any) => {
                setState(prevState => ({
                  ...prevState,
                  insightPeriod: (option && option.value) || ''
                }));
              }}
            />
          </AdminFormLine>

          <AdminFormLine
            row
            marginBottom
            inheritWidth
          >
            <StatSection
              mCols={1}
              tCols={2}
              lCols={3}
            >
              <AdminFormLine column>
                <AdminFormLine>Total jobs</AdminFormLine>
                <StatNumber
                  ref={totalJobsRef}
                  pdDirection={totalJobsPCDirection}
                >
                  <span>{0}</span>
                  <span>{state.insights.data.totalJobsPC.value}</span>
                </StatNumber>
              </AdminFormLine>
              <AdminFormLine column>
                <AdminFormLine>Total completed jobs</AdminFormLine>
                <StatNumber
                  ref={totalCompletedJobsRef}
                  pdDirection={totalCompletedJobsPCDirection}
                >
                  <span>{0}</span>
                  <span>{state.insights.data.totalCompletedJobsPC.value}</span>
                </StatNumber>
              </AdminFormLine>
              <AdminFormLine column>
                <AdminFormLine>Total earnings</AdminFormLine>
                <StatNumber
                  ref={totalEarningsRef}
                  pdDirection={totalGrossEarningsPCDirection}
                >
                  <span>{formatCurrency(0)}</span>
                  <span>{state.insights.data.totalGrossEarningsPC.value}</span>
                </StatNumber>
              </AdminFormLine>
            </StatSection>
          </AdminFormLine>
        </Card>
      </AdminFormLine>
    );
  }, [
    state.insightPeriod,
    state.insights.loading,
    state.insights.data
  ]);

  const renderTodayAtAGlanceHeader = useCallback(() => {
    return (
      <AdminFormLine
        row
        centerV
        spaceBetween
        marginBottom
        style={{paddingBottom: '.5rem'}}
      >
        <h3 style={{ marginBottom: 0 }}>Today at a glance</h3>
      </AdminFormLine>
    );
  }, []);

  const renderTodayAtAGlanceContent = useCallback(() => {
    if (isListEmpty(state.events.data)) {
      return (
        <AdminFormLine
          column
          centerH
        >
          <NoDataIcon />
          <NoDataCaption>No events today</NoDataCaption>
        </AdminFormLine>
      );
    }

    return (
      <AdminFormLine
        row
        centerV
      >
        <Table
          namespaceKey={'whats-on'}
          headerConfig={[
            {
              key: 'start',
              label: 'Time',
              width: '15%',
              type: CellType.Text,
              format: (startDateISO: string, row: Event) => {
                if (row.isAllDay) {
                  if (row.jobStatus === StatusType.CANCELLED) {
                    return (
                      <EventCancelledWrapper>All day</EventCancelledWrapper>
                    )
                  }

                  return 'All day';
                }

                // Spanned entry
                if (!isSameDay(new Date(), new Date(stripZoneFromISOString(startDateISO)))) {
                  return '';
                }

                if (row.jobStatus === StatusType.CANCELLED) {
                  return (
                    <EventCancelledWrapper>{format(stripZoneFromISOString(startDateISO), 'HH:mm')}</EventCancelledWrapper>
                  )
                }

                return format(stripZoneFromISOString(startDateISO), 'HH:mm');
              }
            },
            {
              key: '',
              label: 'Duration',
              width: '15%',
              type: CellType.Text,
              format: (_, row: Event) => {
                if (row.isAllDay) {
                  if (row.jobStatus === StatusType.CANCELLED) {
                    return (
                      <EventCancelledWrapper>All day</EventCancelledWrapper>
                    )
                  }

                  return 'All day';
                }

                if (row.jobStatus === StatusType.CANCELLED) {
                  return (
                    <EventCancelledWrapper>
                      {formatDuration(intervalToDuration({
                        start: new Date(stripZoneFromISOString(row.start)),
                        end: new Date(stripZoneFromISOString(row.end))
                      }))}
                    </EventCancelledWrapper>
                  )
                }

                return formatDuration(intervalToDuration({
                  start: new Date(stripZoneFromISOString(row.start)),
                  end: new Date(stripZoneFromISOString(row.end))
                }));
              }
            },
            {
              key: 'type',
              label: 'Type',
              width: '15%',
              type: CellType.Text,
              format: (type: string, row: Event) => {
                if (row.jobStatus === StatusType.CANCELLED) {
                  return (
                    <EventCancelledWrapper>{type}</EventCancelledWrapper>
                  )
                }

                return type;
              }
            },
            {
              key: 'summary',
              label: `Details`,
              width: '70%',
              type: CellType.Text,
              format: (summary: string, row: Event) => {
                if (row.jobStatus === StatusType.CANCELLED) {
                  return (
                    <EventCancelledWrapper>{summary}</EventCancelledWrapper>
                  )
                }

                return summary;
              }
            }
          ]}
          loading={state.events.loading}
          rows={state.events.data || []}
          total={state.events.data ? state.events.data.length : 0}
          onRowClick={(event: Event) => {
            switch (event.type) {
              case EventType.Job:
                navigate({
                  pathname: `/${params.clientId}/back-office/jobs/${event.jobId}`
                });
                break;
              case EventType.General:
                navigate({
                  pathname: `/${params.clientId}/back-office/calendar`
                });
                break;
            };
          }}
        />
      </AdminFormLine>
    );
  }, [
    params.clientId,
    state.events.loading,
    state.events.data,
    navigate
  ]);

  const renderTodayAtAGlance = useCallback(() => {
    if (state.events.error) {
      return null;
    }

    return (
      <AdminFormLine
        id="whats-on-today"
        marginBottom
      >
        <Card>
          {renderTodayAtAGlanceHeader()}
          {renderTodayAtAGlanceContent()}
        </Card>
      </AdminFormLine>
    );
  }, [
    state.events.error,
    renderTodayAtAGlanceHeader,
    renderTodayAtAGlanceContent
  ]);

  const renderFulfillerOverview = useCallback(() => {
    if (state.overview.loading) {
      return (
        <Spinner
          color={theme.textColor}
          size={'M'}
        />
      );
    }

    if (!state.overview.data) {
      return null;
    }

    return (
      <AdminFormLine marginBottom>
        <Card>
          <AdminFormLine
            row
            centerV
            spaceBetween
            marginBottom
            style={{paddingBottom: '.5rem'}}
          >
            <h3 style={{ marginBottom: 0 }}>Overview</h3>
          </AdminFormLine>

          <AdminFormLine
            row
            marginBottom
            inheritWidth
          >
            <StatSection mCols={1}>
              <AdminFormLine column>
                <AdminFormLine>Total jobs</AdminFormLine>
                <StatNumber>{state.overview.data.totalJobs}</StatNumber>
              </AdminFormLine>
            </StatSection>
          </AdminFormLine>
        </Card>
      </AdminFormLine>
    );
  }, [
    state.overview.loading,
    state.overview.data
  ]);

  useEffect(() => {
    if (!state.insights.data) {
      return;
    }

    const currentTotalJobs = Number((totalJobsRef.current && (totalJobsRef.current.childNodes[0] as HTMLElement).innerHTML) || '');

    animateValue(
      currentTotalJobs,
      state.insights.data.totalJobsPC.current,
      1250,
      (value: number) => {
        if (totalJobsRef.current) {
          (totalJobsRef.current.childNodes[0] as HTMLElement).innerHTML = `${Math.round(value)}`;
        }
      }
    );
  }, [
    state.insights.data
  ]);

  useEffect(() => {
    if (!state.insights.data) {
      return;
    }

    const currentTotalCompletedJobs = Number((totalCompletedJobsRef.current && (totalCompletedJobsRef.current.childNodes[0] as HTMLElement).innerHTML) || '');

    animateValue(
      currentTotalCompletedJobs,
      state.insights.data.totalCompletedJobsPC.current,
      1250,
      (value: number) => {
        if (totalCompletedJobsRef.current) {
          (totalCompletedJobsRef.current.childNodes[0] as HTMLElement).innerHTML = `${Math.round(value)}`;
        }
      }
    );
  }, [
    state.insights.data
  ]);

  useEffect(() => {
    if (!state.insights.data) {
      return;
    }

    const currentTotalEarnings = Number(getRawCurrencyValue((totalEarningsRef.current && (totalEarningsRef.current.childNodes[0] as HTMLElement).innerHTML) || ''));

    animateValue(
      currentTotalEarnings,
      state.insights.data.totalGrossEarningsPC.current,
      1250,
      (value: number) => {
        if (totalEarningsRef.current) {
          (totalEarningsRef.current.childNodes[0] as HTMLElement).innerHTML = formatCurrency(value);
        }
      }
    );
  }, [
    state.insights.data
  ]);

  useEffect(() => {
    if ((!state.insights.data && !state.insights.error) || (state.insightPeriod && state.insightPeriod !== prevInsightPeriod)) {
      fetchInsights();
    }
  }, [
    state.insights.data,
    state.insights.error,
    state.insightPeriod,
    prevInsightPeriod,
    fetchInsights
  ]);

  useEffect(() => {
    if (prevInsightPeriod !== state.insightPeriod) {
      setPrevInsightPeriod(state.insightPeriod);
    }
  }, [
    state.insightPeriod,
    prevInsightPeriod
  ]);

  useEffect(() => {
    if (!state.events.data && !state.events.error) {
      fetchEvents();
    }
  }, [
    state.events.data,
    state.events.error,
    fetchEvents
  ]);

  useEffect(() => {
    if (!state.overview.data && !state.overview.error) {
      fetchOverview();
    }
  }, [
    state.overview.data,
    state.overview.error,
    fetchOverview
  ]);

  return (
    <Wrapper>
      {renderFulfillerInsights()}
      {renderTodayAtAGlance()}
      {renderFulfillerOverview()}
    </Wrapper>
  );
};

export default memo(FulfillerDashboard);
