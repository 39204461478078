import React, { FC, memo, useCallback, useState } from 'react';
import { object, string } from 'yup';

import { theme } from 'theme';
import { gatewayService } from 'services';
import {
  TextInput,
  PrimaryButton,
  Text
} from 'components/atoms';
import {
  Background,
  RecaptchaNotice,
  Footer
} from 'components/molecules';
import { StyledLink } from 'theme/mixins';
import {
  getNetworkErrors
} from 'utils/general';
import {
  NetworkError
} from 'types/Error';
import { Form } from 'types/UI';
import { useValidation } from 'components/hooks';

import {
  Wrapper,
  StyledCard,
  StyledFormWrapper,
  FormLine,
  StyledHeader,
  HR
} from './RequestPasswordReset.styles';

interface State {
  request: {
    loading: boolean;
    success: boolean;
    error: NetworkError | null;
  };
  form: Form;
}

const initialState: State = {
  request: {
    loading: false,
    success: false,
    error: null
  },
  form: {}
};

const requestPasswordResetSchema = object({
  email: string()
    .email()
    .required()
});

const RequestPasswordReset: FC<any> = props => {
  const [state, setState] = useState<State>(initialState);
  const { errors, validate } = useValidation(requestPasswordResetSchema);

  const requestPasswordReset = useCallback((payload: any) => {
    if (state.request.loading) {
      return;
    }

    gatewayService.requestPasswordReset(payload)
      .then(() => {
        setState(prevState => ({
          ...prevState,
          request: {
            loading: false,
            success: true,
            error: null
          },
        }));
      })
      .catch((err) => {
        setState(prevState => ({
          ...prevState,
          request: {
            ...prevState.request,
            loading: false,
            success: false,
            error: getNetworkErrors([err])[0]
          }
        }));
      });
  }, [
    state.request.loading
  ]);

  const handleOnChange = useCallback((e: any, key: string) => {
    const value = e.target.value;

    const newState = {
      ...state,
      form: {
        ...state.form,
        [key]: value
      }
    };

    setState(newState);
  }, [state]);

  const handleSubmit = useCallback((e: any) => {
    e.preventDefault();

    validate({
      form: state.form,
      all: true
    })
      .then(() => {
        setState(prevState => ({
          ...prevState,
          request: {
            ...prevState.request,
            loading: true
          }
        }));

        if (process.env.NODE_ENV === 'production') {
          // @ts-ignore
          window['grecaptcha'].enterprise.ready(async () => {
            const action = 'request_password_reset';
            // @ts-ignore
            const token = await window['grecaptcha'].enterprise.execute(process.env.REACT_APP_RC_KEY_ID, { action });
            gatewayService.verifyRecaptcha(
              token,
              action
            )
              .then(() => {
                requestPasswordReset(state.form);
              })
              .catch((err) => {
                const error: NetworkError = getNetworkErrors([err])[0];

                setState(prevState => ({
                  ...prevState,
                  request: {
                    ...prevState.request,
                    loading: false,
                    error
                  }
                }));
              });
          });

          return;
        }

        requestPasswordReset(state.form);
      });
  }, [
    state,
    requestPasswordReset,
    validate
  ]);

  let submitError = null;

  if (state.request.error) {
    let errorMessage: string = state.request.error.message;

    if (state.request.error.status === 400) {
      errorMessage = 'Please check your input and try again';
    }
    else if (state.request.error.status === 500) {
      errorMessage = 'An unexpected error occurred. Please try again.';
    }

    submitError = (
      <Text
        isError
        value={errorMessage}
      ></Text>
    );
  }

  return (
    <>
      <Background />
      <Wrapper>
        <StyledCard boxShadow>
          <StyledFormWrapper onSubmit={handleSubmit}>
            <FormLine
              center
              marginBottom
            >
              <StyledHeader>Request password reset</StyledHeader>
            </FormLine>

            <FormLine marginBottom>
              <HR />
            </FormLine>
            <FormLine marginBottom />

            {!state.request.success && (
              <>
                <FormLine marginBottom>
                  <TextInput
                    name="email"
                    width={'100%'}
                    label="Email"
                    type="email"
                    value={state.form.email as string}
                    error={errors.email}
                    onChange={(e) => handleOnChange(e, 'email')}
                    onBlur={() => validate({
                      form: state.form,
                      field: 'email'
                    })}
                  />
                </FormLine>

                <FormLine marginBottom />

                <FormLine right>
                  <PrimaryButton
                    width={'100%'}
                    loading={state.request.loading}
                    disabled={state.request.loading || state.request.success}
                    spinnerColor={theme.colors.coreSecondary}
                    type="submit"
                  >
                    Send reset link
                  </PrimaryButton>
                </FormLine>
              </>
            )}
            {submitError && (
              <FormLine
                center
                marginBottom
              >
                {submitError}
              </FormLine>
            )}
            {state.request.success && (
              <>
                <FormLine marginBottom />
                <FormLine
                  center
                  marginBottom
                >
                  <p>Check your email for a reset link</p>
                </FormLine>
              </>
            )}

            <FormLine
              center
              marginBottom
            >
              <StyledLink to={'/login'}>Login</StyledLink>
            </FormLine>

            {!state.request.success && (
              <RecaptchaNotice />
            )}
          </StyledFormWrapper>
        </StyledCard>
        <FormLine
          center
          marginBottom
        >
          <Footer />
        </FormLine>
      </Wrapper>
    </>
  );
};

export default memo(RequestPasswordReset);
