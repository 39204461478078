import React, { FC, memo } from 'react';

import { theme } from 'theme';

import {
  Wrapper,
  RectWrapper
} from './Background.styles';

export interface BackgroundProps { }

const backgroundRect1 = () => {
  return (
    <svg
      viewBox="0 0 10 10"
      fill="none"
      style={{
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '25rem',
        height: '30rem'
      }}
    >
      <rect
        x="0"
        y="0"
        width="100%"
        height="100%"
        fill="url(#rect1)"
        style={{ transform: 'rotate(45deg)' }}
      />
      <defs>
        <linearGradient id="rect1" x1="861.815" y1="354.57" x2="1187.43" y2="-455.956" gradientUnits="userSpaceOnUse">
          <stop stopColor={theme.colors.corePrimary}></stop>
          <stop offset="1" stopColor={theme.colors.corePrimaryDarker2}></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

const backgroundRect2 = () => {
  return (
    <svg viewBox="0 0 756 1034" fill="none">
      <rect
        x="0"
        y="0"
        width="1048.17"
        height="879.874"
        fill="url(#rect2)"
        style={{ transform: 'rotate(-62deg)' }}
      />
      <defs>
        <linearGradient id="rect2" x1="861.815" y1="354.57" x2="1187.43" y2="-455.956" gradientUnits="userSpaceOnUse">
          <stop stopColor={theme.colors.corePrimary}></stop>
          <stop offset="1" stopColor={theme.colors.corePrimaryDarker2}></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

const Background: FC<BackgroundProps> = props => {
  return (
    <Wrapper>
      <RectWrapper left>
        {backgroundRect1()}
      </RectWrapper>
      <RectWrapper right>
        {backgroundRect2()}
      </RectWrapper>
    </Wrapper>
  );
};

export default memo(Background);

