import { Fulfiller } from './Fulfiller';
import { Service } from './Service';
import { BaseEntity } from './Generic';

export enum OnboardPreference {
  Service = 'service',
  Generic = 'generic'
}

export enum PaymentPlan {
  Monthly,
  PerBooking
}

export enum CustomerPaymentServiceType {
  Stripe
}

export interface CustomerPaymentService {
  type: CustomerPaymentServiceType;
  secret: string;
  paymentsDashboardLink: string;
  key?: string;
}

export interface OnboardStep {
  name: string;
  id?: number;
  flow?: OnboardPreference[];
  status?: boolean;
  disabled?: boolean;
  optional?: boolean;
  link?: string;
  comment?: string;
  icon?: string;
  steps?: OnboardStep[];
}

export interface ClientInsights {
  totalJobsPC: {      // percent change;
    previous: number;
    current: number;
    value: string;
  }
  totalGrossEarningsPC: {
    previous: number;
    current: number;
    value: string;
  };
  refundedJobsPC: {
    previous: number;
    current: number;
    value: string;
  };
  genericServiceShare: {
    generic: number;
    genericFormatted: string;
    service: number;
    serviceFormatted: string;
  };
  serviceShare: Array<{
    _id: string;
    share: number;
    service: Service;
  }>;
  jobThroughPut: Array<{
    fulfiller: Fulfiller;
    jobs: number;
  }>;
}

export interface ClientOverview {
  totalServices: number;
  totalEnabledServices: number;
  totalUsers: number;
  totalRevokedUsers: number;
  totalFulfillers: number;
  totalRevokedFulfillers: number;
}

export enum AccountingPlatform {
  None = 'none',
  QuickBooks = 'QuickBooks',
  Xero = 'Xero'
}

export interface Settings {
  accounting: {
    platform: AccountingPlatform;
    businessAccountNumber?: string;
    businessSortCode?: string;
    defaultVat: boolean;
    externalInvoiceGeneration: boolean;
  };
  jobs: {
    fulfillerHandlePayments: boolean;
    defaultFulfillerLabel: string;
  };
  notifications: {
    jobsPaid: boolean;
    additionalJobChargesPaid: boolean;
    fulfillerAutoAssigned: boolean;
    pendingJobReminder: boolean;
    jobHourReminder: boolean;
    customerInvoices: boolean;
  };
}

export enum LockedReasonCode {
  PaymentIssue = 'payment-issue'
}

export interface Locked {
  status: boolean;
  reasonCode: LockedReasonCode;
  reason: string | null;
}

export interface Trial {
  on: Date | null;
  days: number;
}

export interface Client extends BaseEntity {
  _id: string;
  businessName: string;
  businessEmail: string;
  businessAddressLine1: string;
  businessAddressLine2: string;
  businessAddressCity: string;
  businessAddressPostCode: string;
  customerPaymentService: CustomerPaymentService | null;
  defaultPassword: string;
  primaryAdministrator: string;
  trial: Trial;
  settings: Settings;
  paymentPlan: PaymentPlan;
  isDeleted: boolean;
  onboardPreference: OnboardPreference;
  goCardlessCustomerId?: string;
  goCardlessMandateId?: string;
  qboCompanyId?: string;
  locked?: Locked;

  // Temp, deleted once connected
  qboStateToken?: string;
  qboConnectionError?: boolean;

  // decorated
  isOnboarded?: boolean;
}
