import styled from 'styled-components';

import {
  Copy,
  Payments,
  FormSection,
  NoData,
  Warning
} from 'components/atoms';
import {
  Tabs
} from 'components/molecules';
import { device } from 'theme/media';

export * from 'theme/mixins';

export const Wrapper = styled.div`
  display: flex;
  width: inherit;
  height: 100%;
  flex-direction: column;
`;

export const StatusIndicator = styled.i<{ isEnabled: boolean; }>`
  display: flex;
  position: relative;
  justify-content: center;
  padding-top: 2rem;
  font-style: normal;

  ${({ isEnabled }) => `
    :after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background-color: ${isEnabled ? 'green' : 'red'}
    }
  `}
`;

export const StyledPayments = styled(Payments)<{ state: boolean; }>`
  transform: rotateZ(45deg);

  ${({ theme, state }) => !state && `
    fill: ${theme.colors.neutralPrimaryDark1};
  `}
`;

export const StyledWarning = styled(Warning)`
  margin-right: .5rem;
  fill: ${({ theme }) => theme.colors.corePrimaryDarker1};
`;

export const StyledCopy = styled(Copy)`
  width: 1.75rem;
  height: 1.75rem;
`;

export const StyledTabs = styled(Tabs)`
  margin-top: 1.75rem;
`;

export const StyledFormSection = styled(FormSection)`
  padding: 0;

  > div:last-child {
    grid-template-columns: 1fr 0 1fr;
    grid-column-gap: revert;

    > div:first-child {
      margin-right: 1rem;
    }

    > div:last-child {
      margin-left: 1rem;
    }
  }
`;

export const CardInner = styled.div`
  display: flex;
  align-items: center;
`;

export const Details = styled.div<{ isExpanded: boolean; }>`
  display: flex;
  max-height: 0;
  flex-direction: column;
  text-align: left;
  transition: max-height ${({ theme }) => theme.animSpeed} ease-in-out;
  cursor: default;
  overflow: hidden;

  ${({ isExpanded }) => isExpanded && `
    max-height: 25rem;
  `};

  p {
    margin-left: 6rem;
  }
`;

export const DetailsOuter = styled.div`
  display: flex;
  width: 100%;
  max-height: inherit;
  flex-direction: column;
  overflow-x: auto;
  margin-top: 1rem;
  padding-top: 2rem;
  border-top: .1rem solid ${({ theme }) => theme.colors.neutralPrimaryDark};
`;

export const DetailsInner = styled.div<{ groupCount: number }>`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  // margin-top: 3rem;
`;

export const ContentWrapper = styled.div<{
  column?: boolean;
  center?: boolean;
  wordBreak?: boolean;
}>`
  display: flex;
  justify-content: flex-start;

  ${({ column }) => column && `
    flex-direction: column;
    align-items: center;
  `}

  ${({ center }) => center && `
    justify-content: center;
  `}

  ${({ wordBreak }) => wordBreak && `
    word-break: break-word;
  `}

  :first-child {
    margin-right: 1rem;
  }

  :last-child {
    margin-left: auto;
    justify-content: flex-end;
  }

  @media ${device.onlyMobile} {
    &.mobile-group-expand-wrapper {
      position: absolute;
      bottom: -1.5rem;
      left: calc(50% - 3.5rem);
      margin: 0;

      button {
        background-color: ${({ theme }) => theme.backgroundColor};
        border-color: ${({ theme }) => theme.textColor};
      }
    }

    &.mobile-group-menu-wrapper {
      position: absolute;
      bottom: -1.5rem;
      left: calc(50% + .5rem);
      margin: 0;

      button {
        background-color: ${({ theme }) => theme.backgroundColor};
        border-color: ${({ theme }) => theme.textColor};
      }
    }
  }
`;

export const GroupListItem = styled.div`
  display: flex;
  height: 5rem;
  align-items: center;
  border-bottom: .1rem solid ${({ theme }) => theme.table.borderColor};
  
`;

export const StyledNoDataIcon = styled(NoData)`
  width: 8rem;
  height: 100%;
  margin-bottom: 2rem;

  @media ${device.tablet} {
    width: 12rem;
  }
`;

