import React from 'react'

import { IconProps } from './types';

type Props = React.DetailedHTMLProps<React.DOMAttributes<SVGSVGElement>, SVGSVGElement> &
  IconProps;

const TransferMoney = React.forwardRef<SVGSVGElement, Props>((props, ref) => {
  const { className, ...rest } = props;

  return (
    <svg
      {...rest}
      ref={ref}
      className={props.className}
      width="545.56323"
      height="523.50056"
      viewBox="0 0 545.56323 523.50056"
    >
      <path d="M159.753,522.31056c0,.66003,.53003,1.19,1.19006,1.19h383.28998c.65997,0,1.19-.52997,1.19-1.19,0-.65997-.53003-1.19-1.19-1.19H160.94307c-.66003,0-1.19006,.53003-1.19006,1.19Z" fill="#ccc"/>
      <g>
        <path d="M217.91365,522.99361H41.48886c-21.51191,0-39.01333-17.50142-39.01333-39.01374V104.83075c0-21.51191,17.50142-39.01333,39.01333-39.01333H217.91365c21.51151,0,39.01293,17.50142,39.01293,39.01333V483.97987c0,21.51232-17.50142,39.01374-39.01293,39.01374Z" fill="#3f3d56"/>
        <path d="M256.92674,205.28092c-.91025,0-1.65045,.74021-1.65045,1.65045v52.81453c0,.91025,.74021,1.65045,1.65045,1.65045s1.65045-.74021,1.65045-1.65045v-52.81453c0-.91025-.74021-1.65045-1.65045-1.65045Z" fill="#3f3d56"/>
        <path d="M253.21342,104.83104V483.97336c0,18.7244-14.57349,34.04062-33.00082,35.22894h-.00826c-.27234,.01652-.54463,.03304-.82523,.0413-.48691,.02473-.97376,.03299-1.46888,.03299,0,0-1.81551-.13206-5.13294-.37962-3.35042-.24756-8.22749-.61892-14.30946-1.10578-1.82372-.14032-3.74651-.29712-5.77659-.46213-4.04358-.33011-8.48331-.69321-13.24488-1.10583-1.89801-.1568-3.86206-.33006-5.86735-.50338-10.24931-.883-21.72824-1.91453-33.83431-3.08634-2.04655-.18984-4.10961-.38788-6.1892-.59419-.54463-.05777-1.09757-.10728-1.65045-.16506-8.63189-.84996-125.71564-23.47767-125.71564-27.90092V104.83104c0-19.50007,15.80312-35.30319,35.30324-35.30319h21.07628c3.36694,0,6.07366,2.62422,6.36252,5.9829,.02473,.22283,.04951,.4456,.09076,.66843,.61066,3.3752,3.70526,5.72708,7.13818,5.72708h107.08205c3.43292,0,6.52752-2.35188,7.13818-5.72708,.04125-.22283,.06603-.4456,.09081-.66843,.28881-3.35868,2.99553-5.9829,6.36247-5.9829h21.07633c19.50007,0,35.30319,15.80312,35.30319,35.30319Z" fill="#fff"/>
        <path d="M1.65045,165.67002c-.91025,0-1.65045,.74021-1.65045,1.65045v13.20363c0,.91025,.74021,1.65045,1.65045,1.65045s1.65045-.74021,1.65045-1.65045v-13.20363c0-.91025-.74021-1.65045-1.65045-1.65045Z" fill="#3f3d56"/>
        <path d="M1.65045,205.28092c-.91025,0-1.65045,.74021-1.65045,1.65045v25.58204c0,.91025,.74021,1.65045,1.65045,1.65045s1.65045-.74021,1.65045-1.65045v-25.58204c0-.91025-.74021-1.65045-1.65045-1.65045Z" fill="#3f3d56"/>
        <path d="M1.65045,241.59091c-.91025,0-1.65045,.74021-1.65045,1.65045v25.58204c0,.91025,.74021,1.65045,1.65045,1.65045s1.65045-.74021,1.65045-1.65045v-25.58204c0-.91025-.74021-1.65045-1.65045-1.65045Z" fill="#3f3d56"/>
        <rect x="30.12079" y="75.30766" width="32.18385" height="4.12614" rx=".31021" ry=".31021" fill="#e6e6e6"/>
        <circle cx="202.04914" cy="76.13289" r="3.30091" fill="#e6e6e6"/>
        <circle cx="211.12664" cy="76.13289" r="3.30091" fill="#e6e6e6"/>
        <circle cx="220.20414" cy="76.13289" r="3.30091" fill="#e6e6e6"/>
      </g>
      <path d="M199.2886,298.51575H59.2886c-2.20557,0-4-1.79443-4-4s1.79443-4,4-4H199.2886c2.20557,0,4,1.79443,4,4s-1.79443,4-4,4Z" fill="#e6e6e6"/>
      <circle cx="129.79071" cy="395.51575" r="58" fill="#fab51d"/>
      <path d="M139.00859,376.57804c-4.89484,.20103-10.42855,.53711-14.04647,4.293-3.14318,3.26305-3.83195,8.10532-.64457,11.58907,3.19575,3.49289,8.16452,3.96549,11.84835,6.69033,4.32554,3.1995,3.69197,9.52925-.49392,12.56163-4.93603,3.57581-11.30911,1.90862-15.82167-1.49356-1.15636-.87182-2.27886,1.08088-1.13562,1.94281,4.04299,3.04815,9.31864,4.74505,14.31748,3.2865,4.51149-1.31636,8.00859-4.995,8.3643-9.77982,.16977-2.28366-.47955-4.60146-1.89955-6.40441-1.499-1.90326-3.65809-3.0743-5.84068-4.02726-3.75733-1.64052-10.22682-3.76303-9.1344-9.10397,1.32412-6.47372,9.20386-7.08735,14.48676-7.30431,1.44277-.05925,1.45029-2.30956,0-2.25h0Z" fill="#fff"/>
      <path d="M129.928,373.51575l-.21574,49.39335c-.00632,1.44801,2.24367,1.44986,2.25,0l.21574-49.39335c.00632-1.44801-2.24367-1.44986-2.25,0h0Z" fill="#fff"/>
      <path d="M437.08565,0H151.12569c-10.48999,0-19.02002,8.52997-19.02002,19.01996V214.97998c0,10.48999,8.53003,19.02002,19.02002,19.02002H437.08565c10.48999,0,19.02002-8.53003,19.02002-19.02002V19.01996c0-10.48999-8.53003-19.01996-19.02002-19.01996Z" fill="#fff"/>
      <circle cx="292.79071" cy="84.51575" r="32" fill="#fab51d"/>
      <path d="M298.86106,70.23584c-3.96096,.16324-8.42022,.41435-11.41661,3.36215-2.48204,2.4418-3.34997,6.24304-1.06925,9.11803,2.40131,3.02702,6.39485,3.6014,9.52957,5.53579,1.94826,1.20224,3.23934,3.0236,3.28121,5.36426,.03919,2.19141-.97209,4.23876-2.6628,5.61221-4.1481,3.36971-9.80485,1.97481-13.72914-.97221-.77211-.57983-1.52056,.72186-.75708,1.29521,3.25012,2.44074,7.43474,3.8583,11.47885,2.80815,3.72256-.96665,6.72131-3.89507,7.12458-7.80812,.42504-4.12426-2.28291-6.93249-5.79432-8.54355-3.2096-1.47258-8.83914-3.04159-8.30843-7.61645,.67475-5.81661,7.79544-6.46889,12.32342-6.6555,.96183-.03964,.96686-1.53985,0-1.5h0Z" fill="#fff"/>
      <path d="M291.97743,67.51575l-.17986,41.15818c-.00422,.96534,1.49578,.96658,1.5,0l.17986-41.15818c.00422-.96534-1.49578-.96658-1.5,0h0Z" fill="#fff"/>
      <path d="M437.08565,0H151.12569c-10.48999,0-19.02002,8.52997-19.02002,19.01996V214.97998c0,10.48999,8.53003,19.02002,19.02002,19.02002H437.08565c10.48999,0,19.02002-8.53003,19.02002-19.02002V19.01996c0-10.48999-8.53003-19.01996-19.02002-19.01996Zm17.02002,214.97998c0,9.39001-7.63,17.02002-17.02002,17.02002H151.12569c-9.39001,0-17.02002-7.63-17.02002-17.02002V19.01996c0-9.38995,7.63-17.01996,17.02002-17.01996H437.08565c9.39001,0,17.02002,7.63,17.02002,17.01996V214.97998Z" fill="#3f3d56"/>
      <g>
        <path d="M261.1357,19.34998h-28.06006c-1.40997,0-2.56,1.15002-2.56,2.56,0,1.41998,1.15002,2.57001,2.56,2.57001h28.06006c1.40997,0,2.56-1.15002,2.56-2.57001,0-1.40997-1.15002-2.56-2.56-2.56Z" fill="#e6e6e6"/>
        <path d="M308.1357,19.34998h-28.06006c-1.40997,0-2.56,1.15002-2.56,2.56,0,1.41998,1.15002,2.57001,2.56,2.57001h28.06006c1.40997,0,2.56-1.15002,2.56-2.57001,0-1.40997-1.15002-2.56-2.56-2.56Z" fill="#e6e6e6"/>
        <path d="M355.1357,19.34998h-28.06006c-1.40997,0-2.56,1.15002-2.56,2.56,0,1.41998,1.15002,2.57001,2.56,2.57001h28.06006c1.40997,0,2.56-1.15002,2.56-2.57001,0-1.40997-1.15002-2.56-2.56-2.56Z" fill="#e6e6e6"/>
      </g>
      <path d="M401.41567,134.51575H186.79567c-8.92999,0-16.19,7.26001-16.19,16.19v14.62c0,8.92999,7.26001,16.19,16.19,16.19h214.62c8.92999,0,16.19-7.26001,16.19-16.19v-14.62c0-8.92999-7.26001-16.19-16.19-16.19Z" fill="#fab51d"/>
      <path d="M73.17926,211.49182l-8.22992-3.89856c3.37555,4.71082,6.28418,12.06805,7.84137,17.92249,2.63654-5.45343,6.88672-12.1261,11.09375-16.11194l-8.69812,2.23767c5.35974-26.26794,25.5191-45.12573,48.60437-45.12573v-2c-24.11316,0-45.13898,19.64758-50.61145,46.97607Z" fill="#3f3d56"/>
      <path d="M111.05843,251.51575H59.05843c-2.20557,0-4-1.79443-4-4s1.79443-4,4-4h52c2.20557,0,4,1.79443,4,4s-1.79443,4-4,4Z" fill="#e6e6e6"/>
    </svg>
  );
});

export default TransferMoney;
