import React, { FC, memo } from 'react';

import {
  Background,
  Footer
} from 'components/molecules';
import { PageProps } from 'components/AppRouter';

import {
  Wrapper,
  StyledCard,
  FormLine,
  StyledHeader,
  HR,
  StyledFormWrapper
} from './NoAccess.styles';

const NoAccess: FC<PageProps> = props => {
  return (
    <>
      <Background />
      <Wrapper>
        <StyledCard boxShadow>
          <StyledFormWrapper>
            <FormLine
              center
              marginBottom
            >
              <StyledHeader>No access</StyledHeader>
            </FormLine>

            <FormLine marginBottom>
              <HR />
            </FormLine>
            <FormLine marginBottom />

            <FormLine center>
              <p>You do not have access to any clients. Please check with your system admin or ensure registration has been completed properly.</p>
            </FormLine>
          </StyledFormWrapper>
        </StyledCard>
        <FormLine
          center
          marginBottom
        >
          <Footer />
        </FormLine>
      </Wrapper>
    </>
  );
};

export default memo(NoAccess);
