import React, { FC, useState, useCallback, useEffect, useRef, memo } from 'react';
import { useParams, useNavigate } from 'react-router';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie'
import {
  format,
  formatDuration,
  intervalToDuration,
  isSameDay
} from 'date-fns';

import { theme } from 'theme';
import { gatewayService } from 'services';
import {
  Spinner,
  Dropdown,
} from 'components/atoms';
import { OnboardChecklist } from 'components/molecules';
import { AdminPageProps } from 'components/AppRouter';
import {
  Client,
  OnboardStep,
  ClientInsights,
  ClientOverview,
  OnboardPreference
} from 'types/Client';
import {
  Event
} from 'types/Event';
import { CellType } from 'types/Header';
import { Period } from 'types/Generic';
import { EventType } from 'types/Event';
import { NetworkError } from 'types/Error';
import { StatusType } from 'types/Job';
import { RadialChart } from 'components/pages/adminDashboard/components';
import {
  animateValue,
  getRawCurrencyValue,
  formatCurrency,
  createDropdownOption,
  formatLabel,
  getNetworkErrors,
  isListEmpty,
  stripZoneFromISOString
} from 'utils/general';
import { Table } from '../../../components';
import { ISO_FORMAT } from '../../../../../../constants';

import {
  Wrapper,
  AdminFormLine,
} from './ClientDashboard.styles';
import {
  ChartWrapper,
  RadialChartWrapper,
  StatSection
} from '../RootDashboard.styles';
import {
  Card,
  NoDataIcon,
  NoDataCaption,
  StatNumber,
  EventCancelledWrapper
} from 'theme/mixins';


interface State {
  client: {
    loading: boolean;
    data: Client | null;
    error: NetworkError | null;
  };
  onboardStatus: {
    loading: boolean;
    data: OnboardStep[] | null;
    complete: boolean;
    hide: boolean;
    error: NetworkError | null;
  };
  insights: {
    loading: boolean;
    data: ClientInsights | null;
    error: NetworkError | null;
  };
  events: {
    loading: boolean;
    data: Event[] | null;
    error: NetworkError | null;
  };
  overview: {
    loading: boolean;
    data: ClientOverview | null;
    error: NetworkError | null;
  };
  insightPeriod: string;
}

const initialState: State = {
  client: {
    loading: false,
    data: null,
    error: null
  },
  onboardStatus: {
    loading: false,
    data: null,
    complete: false,
    hide: false,
    error: null
  },
  insights: {
    loading: false,
    data: null,
    error: null
  },
  events: {
    loading: false,
    data: null,
    error: null
  },
  overview: {
    loading: false,
    data: null,
    error: null
  },
  insightPeriod: 'today'
};

const ClientDashboard: FC<AdminPageProps> = props => {
  const {
    addToast
  } = props;

  const navigate = useNavigate();
  const params: any = useParams();

  const [state, setState] = useState<State>(initialState);
  const totalJobsRef = useRef<HTMLDivElement>(null);
  const totalEarningsRef = useRef<HTMLDivElement>(null);
  const refundedJobsRef = useRef<HTMLDivElement>(null);
  const [prevInsightPeriod, setPrevInsightPeriod] = useState<string>(state.insightPeriod);

  const fetchClient = useCallback(() => {
    if (state.client.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      client: {
        ...prevState.client,
        loading: true
      }
    }));

    gatewayService.getClient(params.clientId)
      .then((clientResponse: any) => {
        setState(prevState => ({
          ...prevState,
          client: {
            loading: false,
            data: clientResponse.data,
            error: null
          }
        }));
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          client: {
            ...prevState.client,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.client,
    params.clientId,
    addToast
  ]);

  const fetchOnboardStatus = useCallback(() => {
    if (state.onboardStatus.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      onboardStatus: {
        ...prevState.onboardStatus,
        loading: true
      }
    }));

    gatewayService.getOnboardStatus(params.clientId)
      .then((statusResponse: any) => {
        setState(prevState => ({
          ...prevState,
          onboardStatus: {
            loading: false,
            data: statusResponse.data,
            complete: statusResponse.complete,
            hide: statusResponse.hide,
            error: null
          }
        }));
      })
      .catch((err: any) => {
        setState(prevState => ({
          ...prevState,
          onboardStatus: {
            loading: false,
            data: null,
            complete: false,
            hide: false,
            error: getNetworkErrors([err])[0]
          }
        }));

        addToast({
          type: 'error',
          content: 'Something went wrong loading Onboard Status'
        });
      });
  }, [
    state.onboardStatus.loading,
    params.clientId,
    addToast
  ]);

  const fetchInsights = useCallback(() => {
    if (state.insights.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      insights: {
        ...prevState.insights,
        loading: true
      }
    }));

    gatewayService.getInsights(
      params.clientId,
      format(new Date(), ISO_FORMAT),
      state.insightPeriod
    )
      .then((insightResponse: any) => {
        setState(prevState => ({
          ...prevState,
          insights: {
            loading: false,
            data: insightResponse.data,
            error: null
          }
        }));
      })
      .catch((err) => {
        setState(prevState => ({
          ...prevState,
          insights: {
            ...prevState.insights,
            loading: false,
            error: getNetworkErrors([err])[0]
          }
        }));

        addToast({
          type: 'error',
          content: 'Something went wrong loading Insights'
        });
      });
  }, [
    state.insights,
    state.insightPeriod,
    params.clientId,
    addToast
  ]);

  const fetchEvents = useCallback(() => {
    if (state.events.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      events: {
        ...prevState.events,
        loading: true
      }
    }));

    gatewayService.getEvents(
      params.clientId,
      format(new Date(), ISO_FORMAT),
      Period.Today
    )
      .then((eventsResponse: any) => {
        setState(prevState => ({
          ...prevState,
          events: {
            loading: false,
            data: eventsResponse.data,
            error: null
          }
        }));
      })
      .catch((err) => {
        setState(prevState => ({
          ...prevState,
          events: {
            ...prevState.events,
            loading: false,
            error: getNetworkErrors([err])[0]
          }
        }));

        addToast({
          type: 'error',
          content: 'Something went wrong loading Events'
        });
      });
  }, [
    state.events,
    params.clientId,
    addToast
  ]);

  const fetchOverview = useCallback(() => {
    if (state.overview.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      overview: {
        ...prevState.overview,
        loading: true
      }
    }));

    gatewayService.getOverview(params.clientId)
      .then((overviewResponse: any) => {
        setState(prevState => ({
          ...prevState,
          overview: {
            loading: false,
            data: overviewResponse.data,
            error: null
          }
        }));
      })
      .catch((err) => {
        setState(prevState => ({
          ...prevState,
          overview: {
            ...prevState.overview,
            loading: false,
            error: getNetworkErrors([err])[0]
          }
        }));

        addToast({
          type: 'error',
          content: 'Something went wrong loading Overview'
        });
      });
  }, [
    state.overview,
    params.clientId,
    addToast
  ]);

  const updateClient = useCallback((payload: any) => {
    if (state.client.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      client: {
        ...prevState.client,
        loading: true
      }
    }));

    gatewayService.updateClient(params.clientId, payload)
      .then((updateClientResponse: any) => {
        setState(prevState => ({
          ...prevState,
          client: {
            loading: false,
            data: updateClientResponse.data,
            error: null
          }
        }));
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          client: {
            ...prevState.client,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: 'Unabled to update client. ' + error.message
        });
      });
  }, [
    addToast,
    state.client,
    params.clientId
  ]);

  const onFlowChange = useCallback((flow: OnboardPreference) => {
    updateClient({ onboardPreference: flow });
  }, [updateClient]);

  const renderInsights = useCallback(() => {
    if (state.insights.loading) {
      return (
        <Spinner
          color={theme.textColor}
          size={'M'}
        />
      );
    }

    if (!state.insights.data) {
      return null;
    }

    const totalJobsPCDirection: number = state.insights.data.totalJobsPC.value.startsWith('+') ? 1 : state.insights.data.totalJobsPC.value.startsWith('-') ? -1 : 0;
    const totalGrossEarningsPCDirection: number = state.insights.data.totalGrossEarningsPC.value.startsWith('+') ? 1 : state.insights.data.totalGrossEarningsPC.value.startsWith('-') ? -1 : 0;
    const refundedJobsPCDirection: number = state.insights.data.refundedJobsPC.value.startsWith('+') ? 1 : state.insights.data.refundedJobsPC.value.startsWith('-') ? -1 : 0;
    const serviceShareData = state.insights.data.serviceShare.map(shareItem => (
      {
        id: shareItem.service.name,
        label: shareItem.service.name,
        value: shareItem.share
      }
    ));

    return (
      <AdminFormLine marginBottom>
        <Card>
          <AdminFormLine
            row
            centerV
            spaceBetween
            marginBottom
            style={{paddingBottom: '.5rem'}}
          >
            <h3 style={{ marginBottom: 0, marginRight: '2rem' }}>Insights</h3>
            <Dropdown
              width={'14rem'}
              value={state.insightPeriod}
              options={[
                ...Object
                  .values(Period)
                  .filter(val => !val.includes('last')
                    && !val.includes('yesterday')
                    && !val.includes('next')
                    && !val.includes('tomorrow')
                  )
                  .map(val => createDropdownOption(formatLabel(val), val))
              ]}
              onChange={(option: any) => {
                setState(prevState => ({
                  ...prevState,
                  insightPeriod: (option && option.value) || ''
                }));
              }}
            />
          </AdminFormLine>

          <AdminFormLine
            row
            marginBottom
            inheritWidth
          >
            <StatSection
              mCols={1}
              tCols={2}
              lCols={3}
            >
              <AdminFormLine column>
                <AdminFormLine>Total jobs</AdminFormLine>
                <StatNumber
                  ref={totalJobsRef}
                  pdDirection={totalJobsPCDirection}
                >
                  <span>{0}</span>
                  <span>{state.insights.data.totalJobsPC.value}</span>
                </StatNumber>
              </AdminFormLine>
              <AdminFormLine column>
                <AdminFormLine>Total earnings</AdminFormLine>
                <StatNumber
                  ref={totalEarningsRef}
                  pdDirection={totalGrossEarningsPCDirection}
                >
                  <span>{formatCurrency(0)}</span>
                  <span>{state.insights.data.totalGrossEarningsPC.value}</span>
                </StatNumber>
              </AdminFormLine>
              <AdminFormLine column>
                <AdminFormLine>Refunded jobs</AdminFormLine>
                <StatNumber
                  ref={refundedJobsRef}
                  pdDirection={refundedJobsPCDirection}
                >
                  <span>{0}</span>
                  <span>{state.insights.data.refundedJobsPC.value}</span>
                </StatNumber>
              </AdminFormLine>
            </StatSection>
          </AdminFormLine>

          {state.insights.data.totalJobsPC.current !== 0 && (
            <>
              <AdminFormLine marginBottom />
              <AdminFormLine marginBottom />

              <AdminFormLine
                row
                marginBottom
                inheritWidth
              >
                <StatSection mCols={1} lCols={2}>
                  <AdminFormLine column>
                    <AdminFormLine>Percentage of generic and service jobs</AdminFormLine>
                    <ChartWrapper>
                      <RadialChartWrapper>
                        <RadialChart
                          autoFit
                          data={[
                            {
                              label: `Generic ${state.insights.data.genericServiceShare.genericFormatted}`,
                              value: state.insights.data.genericServiceShare.generic,
                              trackWidth: 10,
                              colour: theme.colors.corePrimary
                            },
                            {
                              label: `Service ${state.insights.data.genericServiceShare.serviceFormatted}`,
                              value: state.insights.data.genericServiceShare.service,
                              trackWidth: 10,
                              colour: theme.colors.accentSecondary
                            }
                          ]}
                          gap={5}
                        />
                      </RadialChartWrapper>
                    </ChartWrapper>
                  </AdminFormLine>
                  {serviceShareData.length !== 0 && (
                    <AdminFormLine column>
                      <AdminFormLine>Service share (percentage of services booked)</AdminFormLine>
                      <ChartWrapper>
                        <ResponsivePie
                          data={serviceShareData}
                          margin={{ top: 10, right: 80, bottom: 10, left: 80 }}
                          innerRadius={0.5}
                          padAngle={0.7}
                          cornerRadius={3}
                          activeOuterRadiusOffset={8}
                          borderWidth={1}
                          borderColor={{
                              from: 'color',
                              modifiers: [
                                  [
                                      'darker',
                                      0.2
                                  ]
                              ]
                          }}
                          arcLinkLabelsSkipAngle={10}
                          arcLinkLabelsTextColor={theme.textColor}
                          arcLinkLabelsThickness={2}
                          arcLinkLabelsColor={{ from: 'color' }}
                          arcLabelsSkipAngle={10}
                          arcLinkLabelsStraightLength={12}
                          arcLabelsTextColor={{
                              from: 'color',
                              modifiers: [
                                  [
                                      'darker',
                                      2
                                  ]
                              ]
                          }}
                        />
                      </ChartWrapper>
                    </AdminFormLine>
                  )}
                </StatSection>
              </AdminFormLine>

              {state.insights.data.jobThroughPut.length !== 0 && (
                <>
                  <AdminFormLine marginBottom />
                  <AdminFormLine marginBottom />

                  <AdminFormLine
                    row
                    marginBottom
                  >
                    <StatSection mCols={1}>
                      <AdminFormLine column>
                        <AdminFormLine>Job throughput (top 5 fulfillers)</AdminFormLine>
                        <ChartWrapper>
                          <ResponsiveBar
                            data={state.insights.data.jobThroughPut.map(item => {
                              return {
                                fulfiller: item.fulfiller ? `${item.fulfiller?.firstName} ${item.fulfiller?.lastName}` : 'Unassigned',
                                jobs: item.jobs
                              }
                            })}
                            keys={[
                              'jobs'
                            ]}
                            indexBy="fulfiller"
                            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                            padding={0.4}
                            valueScale={{ type: 'linear' }}
                            indexScale={{ type: 'band', round: true }}
                            defs={[
                              {
                                  id: 'gradientA',
                                  type: 'linearGradient',
                                  colors: [
                                      { offset: 0, color: theme.colors.corePrimary },
                                      { offset: 100, color: theme.colors.accentSecondary },
                                  ],
                              }
                            ]}
                            fill={[
                                { match: { id: 'jobs' }, id: 'gradientA' },
                            ]}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                              tickSize: 5,
                              tickPadding: 5,
                              tickRotation: 0,
                              legend: 'Fulfillers',
                              legendPosition: 'middle',
                              legendOffset: 32
                            }}
                            axisLeft={{
                              tickSize: 5,
                              tickPadding: 5,
                              tickRotation: 0,
                              legend: 'Jobs',
                              legendPosition: 'middle',
                              legendOffset: -40
                            }}
                            labelSkipWidth={12}
                            labelSkipHeight={12}
                            role="application"
                            ariaLabel="Job throughput (top 5 fulfillers)"
                          />
                        </ChartWrapper>
                      </AdminFormLine>
                    </StatSection>
                  </AdminFormLine>
                </>
              )}
            </>
          )}
        </Card>
      </AdminFormLine>
    );
  }, [
    state.insightPeriod,
    state.insights.loading,
    state.insights.data
  ]);

  const renderTodayAtAGlanceHeader = useCallback(() => {
    return (
      <AdminFormLine
        row
        centerV
        spaceBetween
        marginBottom
        style={{paddingBottom: '.5rem'}}
      >
        <h3 style={{ marginBottom: 0, marginRight: '2rem' }}>Today at a glance</h3>
      </AdminFormLine>
    );
  }, []);

  const renderTodayAtAGlanceContent = useCallback(() => {
    if (isListEmpty(state.events.data)) {
      return (
        <AdminFormLine
          column
          centerH
        >
          <NoDataIcon />
          <NoDataCaption>No events today</NoDataCaption>
        </AdminFormLine>
      );
    }

    return (
      <AdminFormLine
        row
        centerV
      >
        <Table
          namespaceKey={'whats-on'}
          headerConfig={[
            {
              key: 'start',
              label: 'Time',
              width: '15%',
              type: CellType.Text,
              format: (startDateISO: string, row: Event) => {
                if (row.isAllDay) {
                  if (row.jobStatus === StatusType.CANCELLED) {
                    return (
                      <EventCancelledWrapper>All day</EventCancelledWrapper>
                    )
                  }

                  return 'All day';
                }

                // Spanned entry
                if (!isSameDay(new Date(), new Date(stripZoneFromISOString(startDateISO)))) {
                  return '';
                }

                if (row.jobStatus === StatusType.CANCELLED) {
                  return (
                    <EventCancelledWrapper>{format(stripZoneFromISOString(startDateISO), 'HH:mm')}</EventCancelledWrapper>
                  )
                }

                return format(stripZoneFromISOString(startDateISO), 'HH:mm');
              }
            },
            {
              key: '',
              label: 'Duration',
              width: '15%',
              type: CellType.Text,
              format: (_, row: Event) => {
                if (row.isAllDay) {
                  if (row.jobStatus === StatusType.CANCELLED) {
                    return (
                      <EventCancelledWrapper>All day</EventCancelledWrapper>
                    )
                  }

                  return 'All day';
                }

                if (row.jobStatus === StatusType.CANCELLED) {
                  return (
                    <EventCancelledWrapper>
                      {formatDuration(intervalToDuration({
                        start: new Date(stripZoneFromISOString(row.start)),
                        end: new Date(stripZoneFromISOString(row.end))
                      }))}
                    </EventCancelledWrapper>
                  )
                }

                return formatDuration(intervalToDuration({
                  start: new Date(stripZoneFromISOString(row.start)),
                  end: new Date(stripZoneFromISOString(row.end))
                }));
              }
            },
            {
              key: 'type',
              label: 'Type',
              width: '15%',
              type: CellType.Text,
              format: (type: string, row: Event) => {
                if (row.jobStatus === StatusType.CANCELLED) {
                  return (
                    <EventCancelledWrapper>{type}</EventCancelledWrapper>
                  )
                }

                return type;
              }
            },
            {
              key: 'summary',
              label: `Details`,
              width: '70%',
              type: CellType.Text,
              format: (summary: string, row: Event) => {
                if (row.jobStatus === StatusType.CANCELLED) {
                  return (
                    <EventCancelledWrapper>{summary}</EventCancelledWrapper>
                  )
                }

                return summary;
              }
            }
          ]}
          loading={state.events.loading}
          rows={state.events.data || []}
          total={state.events.data ? state.events.data.length : 0}
          onRowClick={(event: Event) => {
            switch (event.type) {
              case EventType.Job:
                navigate({
                  pathname: `/${params.clientId}/back-office/jobs/${event.jobId}`
                });
                break;
              case EventType.General:
                navigate({
                  pathname: `/${params.clientId}/back-office/calendar`
                });
                break;
            };
          }}
        />
      </AdminFormLine>
    );
  }, [
    params.clientId,
    state.events.loading,
    state.events.data,
    navigate
  ]);

  const renderTodayAtAGlance = useCallback(() => {
    if (state.events.error) {
      return null;
    }

    return (
      <AdminFormLine
        id="whats-on-today"
        marginBottom
      >
        <Card>
          {renderTodayAtAGlanceHeader()}
          {renderTodayAtAGlanceContent()}
        </Card>
      </AdminFormLine>
    );
  }, [
    state.events.error,
    renderTodayAtAGlanceHeader,
    renderTodayAtAGlanceContent
  ]);

  const renderOverview = useCallback(() => {
    if (state.overview.loading) {
      return (
        <Spinner
          color={theme.textColor}
          size={'M'}
        />
      );
    }

    if (!state.overview.data) {
      return null;
    }

    return (
      <AdminFormLine marginBottom>
        <Card>
          <AdminFormLine
            row
            centerV
            spaceBetween
            marginBottom
            style={{paddingBottom: '.5rem'}}
          >
            <h3 style={{ marginBottom: 0, marginRight: '2rem' }}>Overview</h3>
          </AdminFormLine>

          <AdminFormLine
            row
            marginBottom
            inheritWidth
          >
            <StatSection
              mCols={2}
            >
              <AdminFormLine column>
                <AdminFormLine>Total services</AdminFormLine>
                <StatNumber>{state.overview.data.totalServices}</StatNumber>
              </AdminFormLine>
              <AdminFormLine column>
                <AdminFormLine>Total enabled services</AdminFormLine>
                <StatNumber>{state.overview.data.totalEnabledServices}</StatNumber>
              </AdminFormLine>
            </StatSection>
          </AdminFormLine>
          <AdminFormLine
            row
            marginBottom
            inheritWidth
          >
            <StatSection
              mCols={2}
              lCols={4}
            >
              <AdminFormLine column>
                <AdminFormLine>Total users</AdminFormLine>
                <StatNumber>{state.overview.data.totalUsers}</StatNumber>
              </AdminFormLine>
              <AdminFormLine column>
                <AdminFormLine>Total revoked users</AdminFormLine>
                <StatNumber>{state.overview.data.totalRevokedUsers}</StatNumber>
              </AdminFormLine>
              <AdminFormLine column>
                <AdminFormLine>Total fulfillers</AdminFormLine>
                <StatNumber>{state.overview.data.totalFulfillers}</StatNumber>
              </AdminFormLine>
              <AdminFormLine column>
                <AdminFormLine>Total revoked fulfillers</AdminFormLine>
                <StatNumber>{state.overview.data.totalRevokedFulfillers}</StatNumber>
              </AdminFormLine>
            </StatSection>
          </AdminFormLine>
        </Card>
      </AdminFormLine>
    );
  }, [
    state.overview.loading,
    state.overview.data
  ]);

  useEffect(() => {
    if (!state.onboardStatus.data && !state.onboardStatus.error) {
      fetchOnboardStatus();
    }
  }, [
    state.onboardStatus.data,
    state.onboardStatus.error,
    fetchOnboardStatus
  ]);

  useEffect(() => {
    if (!state.insights.data) {
      return;
    }

    const currentTotalJobs = Number((totalJobsRef.current && (totalJobsRef.current.childNodes[0] as HTMLElement).innerHTML) || '');

    animateValue(
      currentTotalJobs,
      state.insights.data.totalJobsPC.current,
      1250,
      (value: number) => {
        if (totalJobsRef.current) {
          (totalJobsRef.current.childNodes[0] as HTMLElement).innerHTML = `${Math.round(value)}`;
        }
      }
    );
  }, [
    state.insights.data
  ]);

  useEffect(() => {
    if (!state.insights.data) {
      return;
    }

    const currentTotalEarnings = Number(getRawCurrencyValue((totalEarningsRef.current && (totalEarningsRef.current.childNodes[0] as HTMLElement).innerHTML) || ''));

    animateValue(
      currentTotalEarnings,
      state.insights.data.totalGrossEarningsPC.current,
      1250,
      (value: number) => {
        if (totalEarningsRef.current) {
          (totalEarningsRef.current.childNodes[0] as HTMLElement).innerHTML = formatCurrency(value);
        }
      }
    );
  }, [
    state.insights.data
  ]);

  useEffect(() => {
    if (!state.insights.data) {
      return;
    }

    const currentRefundedJobs = Number((refundedJobsRef.current && (refundedJobsRef.current.childNodes[0] as HTMLElement).innerHTML) || '');

    animateValue(
      currentRefundedJobs,
      state.insights.data.refundedJobsPC.current,
      1250,
      (value: number) => {
        if (refundedJobsRef.current) {
          (refundedJobsRef.current.childNodes[0] as HTMLElement).innerHTML = `${Math.round(value)}`;
        }
      }
    );
  }, [
    state.insights.data
  ]);

  useEffect(() => {
    if ((!state.insights.data && !state.insights.error) || (state.insightPeriod && state.insightPeriod !== prevInsightPeriod)) {
      fetchInsights();
    }
  }, [
    state.insights.data,
    state.insights.error,
    state.insightPeriod,
    prevInsightPeriod,
    fetchInsights
  ]);

  useEffect(() => {
    if (prevInsightPeriod !== state.insightPeriod) {
      setPrevInsightPeriod(state.insightPeriod);
    }
  }, [
    state.insightPeriod,
    prevInsightPeriod
  ]);

  useEffect(() => {
    if (!state.events.data && !state.events.error) {
      fetchEvents();
    }
  }, [
    state.events.data,
    state.events.error,
    fetchEvents
  ]);

  useEffect(() => {
    if (!state.overview.data && !state.overview.error) {
      fetchOverview();
    }
  }, [
    state.overview.data,
    state.overview.error,
    fetchOverview
  ]);

  useEffect(() => {
    if (!state.client.data && !state.client.error) {
      fetchClient();
    }
  }, [
    state.client.data,
    state.client.error,
    fetchClient
  ]);

  return (
    <Wrapper>
      {!state.onboardStatus.error && state.client.data && (
        <OnboardChecklist
          flow={state.client.data.onboardPreference}
          loading={state.onboardStatus.loading}
          steps={state.onboardStatus.data}
          complete={state.onboardStatus.complete}
          hide={state.onboardStatus.hide}
          addToast={addToast}
          onFlowChange={onFlowChange}
        />
      )}
      {renderInsights()}
      {renderTodayAtAGlance()}
      {renderOverview()}
    </Wrapper>
  );
};

export default memo(ClientDashboard);

