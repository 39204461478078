import React, { useCallback } from 'react';

import { Wrapper } from './Tab.styles';

interface TabProps {
  activeTab: string;
  label: string;
  onTabSelect: (label: string) => void;
}

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLLIElement>, HTMLLIElement> &
  TabProps;

const Tab = React.forwardRef<HTMLLIElement, Props>((props, ref) => {
  const {
    label,
    activeTab,
    onTabSelect,
    ...rest
  } = props;

  const onTabClick = useCallback(() => {
    onTabSelect(label);
  }, [label, onTabSelect]);

  return (
    <Wrapper
      {...rest}
      ref={ref}
      isActive={activeTab === label}
      onClick={onTabClick}
    >{label}</Wrapper>
  );
});

export default Tab;
