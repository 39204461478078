import styled from 'styled-components';

export * from 'theme/mixins';

export const Wrapper = styled.div`
  display: flex;

  div:nth-of-type(2) {
    margin-left: 1rem;
  }
`;

