import { StatusType as JobStatus } from './Job';
import { BaseEntity } from './Generic';
import { User } from './User';
import { Fulfiller } from './Fulfiller';

export enum EventType {
  Job = 'Job',
  General = 'General'
}

export enum Status {
  Submitted = 'Submitted',
  Sent = 'Sent'
}

export interface Event extends BaseEntity {
  _id: string;
  type: EventType;
  status: Status;
  summary: string;
  creatorId: string;
  start: string;
  end: string;
  usersAndGroups: string[];
  colour: string;
  clientId: string;
  isAllDay?: boolean;
  description?: string;
  creator?: User | Fulfiller;

  // decorated
  jobId?: string;
  jobStatus?: JobStatus;
  jobFulfillerName?: string;
  jobFulfillerEmail?: string;
}

