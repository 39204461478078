import styled from 'styled-components';

export const ColourPickerWrapper = styled.div`
  margin-top: 1rem;
`;

export const Colour = styled.div<{
  value: string;
  readOnly?: boolean;
}>`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: ${({ value }) => value};
  border: .1rem solid ${({ theme }) => theme.textColor};

  ${({ readOnly }) => !readOnly && `
    cursor: pointer;
  `}
`;

