import styled from 'styled-components';
import { device } from 'theme/media';

export * from 'theme/mixins';

const dividerHeight: number = 3.5;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Outer = styled.div<{ isFirst?: boolean; }>`
  display: flex;
  position: relative;
  flex-direction: row;

  ${({ theme, isFirst }) => !isFirst && `
    margin-top: ${dividerHeight}rem; 

    :before {
      content: '';
      display: block;
      position: absolute;
      left: 25rem;
      top: -${dividerHeight}rem;
      width: .2rem;
      height: ${dividerHeight}rem;
      background: ${theme.colors.neutralPrimaryDark1};

      @media ${device.onlyMobile} {
        left: 50%;
      }
    }
  `};

  @media ${device.onlyMobile} {
    width: 100%;
  }
`;

export const Inner = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: column;
`;

export const Info = styled.div<{ isComment?: boolean; }>`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin-left: ${dividerHeight}rem; 
  word-break: break-word;

  ${({ theme, isComment }) => !isComment && `
    :before {
      content: '';
      display: block;
      position: absolute;
      left: -${dividerHeight * .75}rem;
      top: 50%;
      width: ${dividerHeight * .5}rem;
      height: .2rem;
      background: ${theme.colors.neutralPrimaryDark1};
    }
  `}

  ${({ isComment }) => isComment && `
    line-height: 2rem;
  `}
`;

export const ChangesWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: column;
`;

