import React from 'react'

import {
  InputControl,
  RowWrapper,
  StartAdornmentWrapper
} from './BaseInput.styles'
import IconWrapper from './IconWrapper'

export interface BaseInputProps {
  errored?: boolean;
  icon?: JSX.Element;
  shadedIcon?: boolean;
  inputStartAdornment?: JSX.Element;
}

export type StyledBaseInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> &
  BaseInputProps

// Add use forwardRef to allow attaching ref to underlying input element
const BaseInput = React.forwardRef<HTMLInputElement, StyledBaseInputProps>((props, ref) => {
  const { icon, value, ...rest } = props

  let control = (
    <InputControl
      {...rest}
      data-lpignore
      ref={ref}
      value={value !== undefined || !rest.onChange ? value : ''}
    />
  );

  if (rest.inputStartAdornment) {
    control = (
      <RowWrapper>
        <StartAdornmentWrapper>
          {rest.inputStartAdornment}
        </StartAdornmentWrapper>
        {control}
      </RowWrapper>
    );
  }

  if (icon) {
    return (
      <IconWrapper
        disabled={props.disabled}
        errored={props.errored}
        icon={icon}
        readOnly={props.readOnly}
        shaded={props.shadedIcon}
      >
        {control}
      </IconWrapper>
    )
  }

  return control
})

export default BaseInput
