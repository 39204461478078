import styled from 'styled-components';

const IconContainer = styled.span`
  position: absolute;
  right: 0.2rem;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  pointer-events: none;

  & svg path {
    fill: ${({ theme }) => theme.baseInput && theme.baseInput.icon.color};
  }
`

interface ControlWrapperProps {
  disabled?: boolean
  errored?: boolean
  readOnly?: boolean
  shaded?: boolean
}
const ControlWrapper = styled.div<ControlWrapperProps>`
  position: relative;
  border-radius: 0.6rem;

  ${({ disabled, errored, readOnly, shaded, theme }) =>
    shaded &&
    `
      background-color: ${
        errored
          ? theme.colors.accentTertiary
          : disabled
          ? theme.baseInput.disabled.borderColor
          : readOnly
          ? theme.baseInput.backgroundColor
          : theme.baseInput.borderColor
      };
      padding-right: 2.6rem;

      & ${IconContainer} svg path {
        fill: ${disabled || readOnly ? theme.baseInput.disabled.color : theme.baseInput.icon.color};
      }

      ${!disabled &&
        !readOnly &&
        !errored &&
        `
          &:hover {
            background-color: ${theme.baseInput.hover.borderColor};
            
            &:not(:focus-within) > :first-child {
              border-color: ${theme.baseInput.hover.borderColor};
            }
          }

          &:focus-within {
            background-color: ${theme.baseInput.focus.borderColor};
          }
        `}
    `}

  ${({ disabled, readOnly, shaded, theme }) =>
    !shaded &&
    `
        & > :first-child {
          padding-right: 2.6rem;
        }

        ${(disabled || readOnly) &&
          `
          & ${IconContainer} svg path {
            fill: ${theme.baseInput.disabled.color};
          }
        `}
      `}
`

export { ControlWrapper, IconContainer }
