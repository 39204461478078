import React, { useState, useCallback, useRef, useMemo } from 'react';

import ToastComponent from './ToastComponent';

import {
  Wrapper,
  RemoveAllWrapper,
  RemoveAllCross
} from './ToastManager.styles';

export interface ToastInternal {
  // TODO: set 'id' as optional and set to messsage content if not passed
  id: number;
  type: 'success' | 'info' | 'warning' | 'error';
  content: string | React.ReactNode;
  autoRemove?: boolean;
  tag?: number;
  closeFunction?: () => void;
}

export type Toast = Omit<ToastInternal, 'id'>;

const isDuplicate = (toast: Toast, list: Toast[]): boolean => {
  return !!list.find((listItem: Toast) => {
    const typeEqual: boolean = listItem.type === toast.type;
    let contentEqual: boolean = false;

    if ((listItem.content as React.ReactElement).props && (toast.content as React.ReactElement).props) {
      contentEqual = JSON.stringify((listItem.content as React.ReactElement).props.children) === JSON.stringify((toast.content as React.ReactElement).props.children);
    } else {
      contentEqual = listItem.content === toast.content;
    }

    return typeEqual && contentEqual;
  });
};

const useToast = (): {
  render: () => React.ReactNode;
  add: (toast: Toast) => void;
  remove: (id: number) => void;
} => {
  const [list, setList] = useState<ToastInternal[]>([]);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const showRemoveAll = useMemo(() => {
    return list.length > 1;
  }, [list]);

  const add = (toast: Toast) => {
    setList(prevList => {
      if (isDuplicate(toast, prevList)) {
        return prevList;
      }

      return [
        ...prevList,
        {
          id: performance.now(),
          ...toast
        }
      ];
    });

    setTimeout(() => {
      if (wrapperRef.current) {
        wrapperRef.current.scrollTop = wrapperRef.current.scrollHeight;
      }
    }, 0);
  };

  const remove = useCallback((id: number, tag?: number) => {
    setList(prevList => {
      const index: number = prevList.findIndex(t => t.id === id);

      if (index >= 0) {
        const total: number = prevList.length;

        return [
          ...prevList.slice(0, index),
          ...prevList.slice(index + 1, total)
        ];
      }

      return prevList;
    });

    // remove all toasts with similar tags
    if (!(tag! > 0)) {
      return;
    }

    list
      .filter((toast: Toast) => toast.tag === tag)
      .forEach((toast: Toast) => toast.closeFunction!());
  }, [list]);

  const update = (index: number, data: any) => {
    setList(prevList => {
      return [
        ...prevList.slice(0, index),
        {
          ...prevList[index],
          ...data
        },
        ...prevList.slice(index + 1, prevList.length)
      ];
    });
  };

  const removeAll = useCallback(() => {
    list.forEach((toast: Toast) => toast.closeFunction!());
  }, [list]);

  const render = useCallback(() => {
    return (
      <Wrapper ref={wrapperRef}>
        {showRemoveAll && (
          <RemoveAllWrapper onClick={() => removeAll()}>
            <RemoveAllCross />
          </RemoveAllWrapper>
        )}
        <div>
          {list.map((toast, index: number) => (
            <ToastComponent
              key={toast.id}
              {...toast}
              onLoad={(closeFunction) => {
                update(index, { closeFunction });
              }}
              onClose={remove}
            />
          ))}
        </div>
      </Wrapper>
    );
  }, [
    list,
    showRemoveAll,
    remove,
    removeAll
  ]);

  return {
    render,
    add,
    remove
  };
};

export default useToast;
