import styled from 'styled-components';

import { AdminFormWrapper  } from 'theme/mixins';
export * from 'theme/mixins';

export const Wrapper = styled.div`
  display: flex;
  width: inherit;
  height: 100%;
  flex-direction: column;
`;

export const FormWrapper = styled(AdminFormWrapper)`
  padding: 0;
  text-align: left;
`;

