import styled from 'styled-components';

export * from 'theme/mixins';

export const Wrapper = styled.div`
  display: flex;
  width: inherit;
  height: 100%;
  flex-direction: column;
`;

export const CardInner = styled.div`
  display: flex;
  align-items: center;
`;

export const ContentWrapper = styled.div<{ column?: boolean; center?: boolean; }>`
  display: flex;
  justify-content: flex-start;

  ${({ column }) => column && `
    flex-direction: column;
    align-items: center;
  `}

  ${({ center }) => center && `
    justify-content: center;
  `}

  :first-child {
    width: 3.5rem;
    height: 3.5rem;
    justify-content: flex-start;
  }

  :last-child {
    margin-left: 2.5rem;
  }
`;

export const Details = styled.div<{ isExpanded: boolean; }>`
  display: flex;
  max-height: 0;
  flex-direction: column;
  text-align: left;
  transition: max-height ${({ theme }) => theme.animSpeed} ease-in-out;
  cursor: default;
  overflow: hidden;

  ${({ isExpanded }) => isExpanded && `
    max-height: 25rem;
  `};

  p {
    margin-left: 6rem;
  }
`;

export const DetailsOuter = styled.div`
  display: flex;
  width: 100%;
  max-height: inherit;
  flex-direction: column;
  overflow-x: auto;
  margin-top: 3rem;
  padding-top: 2rem;
  border-top: .1rem solid ${({ theme }) => theme.colors.neutralPrimaryDark};
`;

export const DetailsInner = styled.div<{ groupCount: number }>`
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  // margin-top: 3rem;
`;
