import React, { FC, memo, useCallback, useMemo, useRef, createRef } from 'react';
import {
  format
} from 'date-fns';

import { StatusType } from 'types/Job';
import { EventWithSpan } from './Calendar';
import { UI_INTERNAL_EVENT } from '../../../../../constants';
import { stripZoneFromISOString } from 'utils/general';
import { EventCancelledWrapper } from 'theme/mixins';

import {
  Wrapper,
  DayRow,
  EntriesContainer,
  Entries,
  EntryRow,
  EntryItems,
  Time,
  Summary,
  ModalSummary,
  ModalTimeSplit
} from './Day.styles';

interface Props {
  day: number;
  date: Date;
  entries: any[];
  onViewEvent: (event: EventWithSpan, date: Date, targetElementBoundingRect: { x: number; y: number; width: number; height: number; } | null) => void;
  onSelectDay: (date: Date, events: EventWithSpan[], targetElementBoundingRect: { x: number; y: number; width: number; height: number; } | null) => void;
}

export const getEventSummaryLine = (
  event: EventWithSpan,
  inModal?: boolean
) => {
  const isSpan: boolean = !!event.span;
  const entryStartTimeStr: string = inModal ? isSpan ? format(new Date(stripZoneFromISOString(event.start)), 'dd/MM HH:mm') : format(new Date(stripZoneFromISOString(event.start)), 'HH:mm') : format(new Date(stripZoneFromISOString(event.start)), 'HH:mm');
  const entryEndTimeStr: string = inModal ? isSpan ? format(new Date(stripZoneFromISOString(event.end)), 'dd/MM HH:mm') : format(new Date(stripZoneFromISOString(event.end)), 'HH:mm') : '';

  let elem = (
    <>
      {!event.isAllDay && (
        <Time>{`${entryStartTimeStr} - ${entryEndTimeStr}`}</Time>
      )}
      {inModal && (
        <ModalTimeSplit colour={event.colour} />
      )}
      {inModal ? (
        <ModalSummary>{event.summary}</ModalSummary>
      ) : (
        <Summary>{event.summary}</Summary>
      )}
    </>
  );

  if (event.jobStatus === StatusType.CANCELLED) {
    elem = (
      <EventCancelledWrapper>{elem}</EventCancelledWrapper>
    );
  }

  return elem;
};

const getBoundingRectInfo = (element: HTMLElement | null) => {
  const boundingRect = element ? element!.getBoundingClientRect() : null;
  let boundingInfo: { x: number; y: number; width: number; height: number; } | null = null;

  if (boundingRect) {
    boundingInfo = {
      x: boundingRect.x,
      y: boundingRect.y,
      width: boundingRect.width,
      height: boundingRect.height
    };
  }

  return boundingInfo;
};

const Day: FC<Props> = props => {
  const {
    day,
    date,
    entries,
    onViewEvent,
    onSelectDay,
  } = props;

  const wrapperRef = useRef<HTMLDivElement>(null);
  const entryRowRefs = useRef<Array<React.RefObject<HTMLDivElement>>>([]);

  if (entryRowRefs.current.length === 0 && entries.length > 0) {
    entryRowRefs.current = [...Array(entries.length)].map(() => createRef<HTMLDivElement>());
  }

  const realEntryCount = useMemo(() => entries.filter(e => e && e.summary !== UI_INTERNAL_EVENT && e.creatorId.length).length , [entries]);

  const onSelectDayLocal = useCallback(() => {
    const boundingInfo: { x: number; y: number; width: number; height: number; } | null = getBoundingRectInfo(wrapperRef.current);

    if (!boundingInfo) {
      return console.error('Unable to get calendar day bounding rect');
    }

    if (wrapperRef.current!.closest('.react-datepicker__day--outside-month')) {
      return;
    }

    return onSelectDay(date, entries, boundingInfo);

  }, [
    date,
    entries,
    onSelectDay
  ]);

  const renderEntries = useCallback(() => {
    return entries.map((entry: EventWithSpan, index: number) => {
      if (!entry) {
        return null;
      }

      if (entry.summary === UI_INTERNAL_EVENT && entry.creatorId.length === 0) {
        // console.log('============empty', day);
        return (
          <EntryRow
            isEmpty
            key={entry._id}
            span={entry.span}
          >
            <Time></Time>
            <Summary>Empty</Summary>
          </EntryRow>
        );
      }

      return (
        <EntryRow
          key={entry._id}
          ref={entryRowRefs.current[index]}
          span={entry.span}
          colour={entry.colour}
          onClick={(e: any) => {
            e.stopPropagation();

            const boundingInfo: { x: number; y: number; width: number; height: number; } | null = getBoundingRectInfo(entryRowRefs.current[index].current);

            if (!boundingInfo) {
              return console.error('Unable to get calendar entry bounding rect');
            }

            return onViewEvent(entry, date, boundingInfo);
          }}
        >
          {getEventSummaryLine(entry)}
        </EntryRow>
      );
    });
  }, [
    // day,
    date,
    entries,
    onViewEvent
  ]);

  return (
    <Wrapper
      ref={wrapperRef}
      onClick={onSelectDayLocal}
    >
      <DayRow>
        <span> {day}</span>
        {realEntryCount > 1 && (
          <EntryItems>{realEntryCount}</EntryItems>
        )}
      </DayRow>
      {entries.length > 0 && (
        <EntriesContainer>
          <Entries>
            {renderEntries()}
          </Entries>
        </EntriesContainer>
      )}
    </Wrapper>
  );
};

export default memo(Day);

