import React, { useRef, useCallback, useMemo, useState, useEffect } from 'react';
import { theme } from 'theme';
import computedStyle from 'computed-style';

import {
  Spinner
} from 'components/atoms';

import {
  OuterSVG,
  InnerSVG,
  StyledText,
  OuterG,
  StyledG,
  Circle,
  CircleBackground
} from './RadialChart.styles';

const RADIUS = 90;
const GAP = 2.5;
const TRACK_WIDTH = 7;
const TRACK_BACKGROUND_COLOUR = theme.colors.neutralPrimary1;
const OUTER_ID = 'outer';
const INNER_ID = 'inner';

interface DualRadialChartProps {
  data: Array<{
    label: string;
    value: number;
    colour: string;
    trackBackgroundColour?: string;
    trackWidth?: number;
    id?: string;
  }>;
  isLoading?: boolean;
  radius?: number;
  gap?: number;
  autoFit?: boolean;
}

const DualRadialChart = ({
  data: [
    {
      label: outerLabel,
      value: outerValue,
      colour: outerColour,
      trackBackgroundColour: outerTrackBackgroundColour = TRACK_BACKGROUND_COLOUR,
      trackWidth: outerTrackWidth = TRACK_WIDTH,
      id: outerId = OUTER_ID
    },
    {
      label: innerLabel,
      value: innerValue,
      colour: innerColour,
      trackBackgroundColour: innerTrackBackgroundColour = TRACK_BACKGROUND_COLOUR,
      trackWidth: innerTrackWidth = TRACK_WIDTH,
      id: innerId = INNER_ID
    }
  ],
  isLoading,
  radius = RADIUS,
  gap = GAP,
  autoFit
}: DualRadialChartProps) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const outerGRef = useRef<SVGGElement>(null);
  const outerCircleRef = useRef<SVGCircleElement>(null);
  const innerCircleRef = useRef<SVGCircleElement>(null);
  const outerTextRef = useRef<SVGTextElement>(null);
  const innerTextRef = useRef<SVGTextElement>(null);

  const [outerWidth, setOuterWidth] = useState<number>(radius * 2);
  const [prevOuterWidth, setPrevOuterWidth] = useState<number>(outerWidth);
  const [autoFitSet, setAutoFitSet] = useState<boolean>(false);

  const data = useMemo(() => {
    const oValue = outerValue * 100;
    const iValue = innerValue * 100;

    const outerNormalizedRadius = outerWidth * .5 - outerTrackWidth * 2;
    const outerCircumference = outerNormalizedRadius * 2 * Math.PI;
    const outerStrokeOffset: number = outerCircumference - oValue / 100 * outerCircumference;

    const innerTrackWidthDiff = innerTrackWidth - outerTrackWidth;
    const innerRadius = outerNormalizedRadius;
    const innerNormalizedRadius = innerRadius - innerTrackWidth + innerTrackWidthDiff * .5 - gap;
    const innerCircumference = innerNormalizedRadius * 2 * Math.PI;
    const innerStrokeOffset: number = innerCircumference - iValue / 100 * innerCircumference;
    const innerDimension = innerRadius * 2;
    const innerPosOffset = (outerWidth - innerDimension) / 2;

    const returnData = {
      outerWidth,

      outerNormalizedRadius,
      outerCircumference,
      outerStrokeOffset,

      innerPosOffset,
      innerRadius,

      innerNormalizedRadius,
      innerCircumference,
      innerStrokeOffset
    };

    // console.log('--------returnData', returnData);

    return returnData;
  }, [
    outerWidth,
    gap,
    innerTrackWidth,
    innerValue,
    outerTrackWidth,
    outerValue,
  ]);

  const onMouseEnter = useCallback((e: any, circleRef: any, textRef: any) => {
    circleRef.current.style.opacity = 0.5;
    textRef.current.style.fontWeight = '700';
  }, []);

  const onMouseLeave = useCallback((e: any, circleRef: any, textRef: any) => {
    circleRef.current.style.opacity = 1;
    textRef.current.style.fontWeight = 'normal';
  }, []);

  useEffect(() => {
    if (autoFit && svgRef.current) {
      const parentElem: HTMLElement | null = svgRef.current.parentNode as HTMLElement;
      const padding = Number(computedStyle(parentElem, 'padding').replace(/px|rem/g, '')) || 0;

      const parentWidth = parentElem.offsetWidth;
      const parentHeight = parentElem.offsetHeight;

      const width: number = (parentWidth <= parentHeight ? parentWidth : parentHeight) - padding * 2;
      // console.log('--------setting outer width', width);

      setOuterWidth(width);
      setAutoFitSet(true);
    }
  }, [
    autoFit
  ]);

  useEffect(() => {
    if (autoFit) {
      if (autoFitSet && outerWidth === prevOuterWidth) {
        // console.log('-----settting stroke');

        // set the stroke to the full circumference of the circle
        if (outerCircleRef.current) {
          outerCircleRef.current.style.cssText = `stroke-dashoffset: ${data.outerCircumference};`;
        }

        if (innerCircleRef.current) {
          innerCircleRef.current.style.cssText = `stroke-dashoffset: ${data.innerCircumference};`;
        }

        // activate transition
        setTimeout(() => {
          if (outerCircleRef.current) {
            outerCircleRef.current.style.cssText += ` transition: stroke-dashoffset .5s;`;
          }

          if (innerCircleRef.current) {
            innerCircleRef.current.style.cssText += ` transition: stroke-dashoffset .5s;`;
          }

          // make visible and set the actual value
          setTimeout(() => {
            if (outerGRef.current) {
              outerGRef.current.style.cssText = `visibility: visible;`;
            }

            if (outerCircleRef.current) {
              outerCircleRef.current.style.cssText += ` stroke-dashoffset: ${data.outerStrokeOffset};`;
            }

            if (innerCircleRef.current) {
              innerCircleRef.current.style.cssText += ` stroke-dashoffset: ${data.innerStrokeOffset};`;
            }
          }, 250);
        }, 250);
      }
    } else {
      // set the stroke to the full circumference of the circle
      if (outerCircleRef.current) {
        outerCircleRef.current.style.cssText = `stroke-dashoffset: ${data.outerCircumference};`;
      }

      if (innerCircleRef.current) {
        innerCircleRef.current.style.cssText = `stroke-dashoffset: ${data.innerCircumference};`;
      }

      // activate transition
      setTimeout(() => {
        if (outerCircleRef.current) {
          outerCircleRef.current.style.cssText += ` transition: stroke-dashoffset .5s;`;
        }

        if (innerCircleRef.current) {
          innerCircleRef.current.style.cssText += ` transition: stroke-dashoffset .5s;`;
        }

        // make visible and set the actual value
        setTimeout(() => {
          if (outerGRef.current) {
            outerGRef.current.style.cssText = `visibility: visible;`;
          }

          if (outerCircleRef.current) {
            outerCircleRef.current.style.cssText += ` stroke-dashoffset: ${data.outerStrokeOffset};`;
          }

          if (innerCircleRef.current) {
            innerCircleRef.current.style.cssText += ` stroke-dashoffset: ${data.innerStrokeOffset};`;
          }
        }, 250);
      }, 250);
    }
  }, [
    autoFit,
    autoFitSet,
    outerWidth,
    prevOuterWidth,
    data.outerCircumference,
    data.innerCircumference,
    data.outerStrokeOffset,
    data.innerStrokeOffset
  ]);

  useEffect(() => {
    if (outerWidth !== prevOuterWidth) {
      setPrevOuterWidth(outerWidth);
    }
  }, [
    outerWidth,
    prevOuterWidth
  ]);

  if (isLoading) {
    return (
      <Spinner
        color={theme.textColor}
        size={'M'}
      />
    );
  }

  return (
    <OuterSVG
      id={outerId}
      ref={svgRef}
      width={data.outerWidth}
      height={data.outerWidth}
    >
      <OuterG ref={outerGRef}>
        <CircleBackground
          strokeWidth={outerTrackWidth}
          strokeDasharray={`${data.outerCircumference} ${data.outerCircumference}`}
          trackBackgroundColour={outerTrackBackgroundColour}
          r={data.outerNormalizedRadius}
          cx={data.outerWidth * .5}
          cy={data.outerWidth * .5}
        />
        <Circle
          id={outerId}
          ref={outerCircleRef}
          stroke={outerColour}
          strokeWidth={outerTrackWidth}
          strokeDasharray={`${data.outerCircumference} ${data.outerCircumference}`}
          r={data.outerNormalizedRadius}
          cx={data.outerWidth * .5}
          cy={data.outerWidth * .5}
          onMouseEnter={(e: any) => onMouseEnter(e, outerCircleRef, outerTextRef)}
          onMouseLeave={(e: any) => onMouseLeave(e, outerCircleRef, outerTextRef)}
        />
        <InnerSVG
          id={innerId}
          x={data.innerPosOffset}
          y={data.innerPosOffset}
          width={data.innerRadius * 2}
          height={data.innerRadius * 2}
        >
          <StyledG pos={data.innerRadius * 2}>
            <StyledText dy="-7.5" ref={outerTextRef}>{outerLabel}</StyledText>
            <StyledText dy="15" ref={innerTextRef}>{innerLabel}</StyledText>
          </StyledG>
          <CircleBackground
            strokeWidth={innerTrackWidth}
            strokeDasharray={`${data.innerCircumference} ${data.innerCircumference}`}
            trackBackgroundColour={innerTrackBackgroundColour}
            r={data.innerNormalizedRadius}
            cx={data.innerRadius}
            cy={data.innerRadius}
          />
          <Circle
            id={innerId}
            ref={innerCircleRef}
            stroke={innerColour}
            strokeWidth={innerTrackWidth}
            strokeDasharray={`${data.innerCircumference} ${data.innerCircumference}`}
            r={data.innerNormalizedRadius}
            cx={data.innerRadius}
            cy={data.innerRadius}
            onMouseEnter={(e: any) => onMouseEnter(e, innerCircleRef, innerTextRef)}
            onMouseLeave={(e: any) => onMouseLeave(e, innerCircleRef, innerTextRef)}
          />
        </InnerSVG>
      </OuterG>
    </OuterSVG>
  );
}

export default DualRadialChart;
