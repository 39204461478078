import React, { FC, memo, useCallback, useState, useRef } from 'react';
import { useNavigate } from 'react-router';
import { object, string } from 'yup';

import { theme } from 'theme';
import { gatewayService, authService } from 'services';
import { getQueryParam } from 'utils/url';
import {
  TextInput,
  PrimaryButton,
  Text,
  OTPInput
} from 'components/atoms';
import {
  Background,
  RecaptchaNotice,
  Footer
} from 'components/molecules';
import { PageProps } from 'components/AppRouter';
import { Actions } from 'state';
import {
  getNetworkErrors
} from 'utils/general';
import {
  NetworkError
} from 'types/Error';
import { Form } from 'types/UI';
import { useValidation } from 'components/hooks';

import {
  Wrapper,
  StyledCard,
  StyledFormWrapper,
  FormLine,
  StyledHeader,
  HR,
  StyledLink
} from './Login.styles';

interface State {
  login: {
    loading: boolean;
    error: NetworkError | null;
  };
  MFA: {
    show: boolean;
  },
  form: Form;
}

const initialState: State = {
  login: {
    loading: false,
    error: null
  },
  MFA: {
    show: false
  },
  form: {}
};

const loginSchema = object({
  email: string()
    .email()
    .required(),
  password: string()
    .required()
});

const Login: FC<PageProps> = props => {
  const {
    dispatch
  } = props;

  const [state, setState] = useState<State>(initialState);
  const formRef = useRef<HTMLFormElement | null>(null);

  const redirectUrl: string | undefined = getQueryParam('redirect', decodeURIComponent(window.location.search.split('?')[1]));
  const navigate = useNavigate();
  const { errors, validate } = useValidation(loginSchema);

  // handle redirect
  const postLogin = useCallback((loginResponse: any) => {
    if (redirectUrl) {
      return authService.default.redirectAfterLogin(redirectUrl);
    }

    const urls: any = loginResponse.urls;

    if (urls) {
      navigate({ pathname: urls.postLogin });
    }
  }, [
    navigate,
    redirectUrl
  ]);

  const login = useCallback((payload: any) => {
    if (state.login.loading) {
      return;
    }

    gatewayService.login(payload)
      .then((loginResponse: any) => {
        setState(prevState => ({
          ...prevState,
          login: {
            loading: false,
            error: null
          }
        }));

        const {
          token,
          requestCode,
          ...rest
        } = loginResponse.data;

        if (requestCode) {
          setState(prevState => ({
            ...prevState,
            MFA: {
              show: true
            }
          }));

          return;
        }

        if (token) {
          const data = {
            fields: loginResponse.fields,
            user: rest
          };

          dispatch({
            type: Actions.UPDATE_USER_CACHE,
            payload: {
              ...data,
              loading: false,
              errors: null
            }
          });

          authService.default.setToken(token);
        }

        postLogin(rest);
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          login: {
            ...prevState.login,
            loading: false,
            error
          }
        }));
      });
  }, [
    state.login.loading,
    dispatch,
    postLogin
  ]);

  const handleOnChange = useCallback((e: any, key: string) => {
    setState(prevState => {
      const newState = {
        ...prevState,
        form: {
          ...prevState.form,
          [key]: e.target.value
        }
      };

      return newState;
    });
  }, []);
  
  const handleSubmit = useCallback((e: any) => {
    e.preventDefault();

    validate({
      form: state.form,
      all: true
    })
      .then(() => {
        setState(prevState => ({
          ...prevState,
          login: {
            ...prevState.login,
            loading: true
          }
        }));

        if (process.env.NODE_ENV === 'production') {
          // @ts-ignore
          window['grecaptcha'].enterprise.ready(async () => {
            const action = 'login';
            // @ts-ignore
            const token = await window['grecaptcha'].enterprise.execute(process.env.REACT_APP_RC_KEY_ID, { action });
            gatewayService.verifyRecaptcha(token, action)
              .then(() => {
                login(state.form);
              })
              .catch((err) => {
                const error: NetworkError = getNetworkErrors([err])[0];

                setState(prevState => ({
                  ...prevState,
                  login: {
                    ...prevState.login,
                    loading: false,
                    error
                  }
                }));
              });
          });

          return;
        }

        login(state.form);
      });
  }, [
    state,
    login,
    validate
  ]);

  let submitError = null;

  if (state.login.error) {
    let errorMessage: string = state.login.error.message;

    if (state.login.error.status === 400) {
      errorMessage = 'Please check your input and try again';
    }
    else if (state.login.error.status === 500) {
      errorMessage = 'An unexpected error occurred. Please try again.';
    }

    submitError = (
      <Text
        isError
        value={errorMessage}
      ></Text>
    );
  }

  return (
    <>
      <Background />
      <Wrapper>
        {state.MFA.show ? (
          <StyledCard boxShadow>
            <StyledFormWrapper
              ref={formRef}
              onSubmit={handleSubmit}
            >
              <FormLine
                center
                marginBottom
              >
                <StyledHeader>Login</StyledHeader>
              </FormLine>

              <FormLine marginBottom>
                <HR />
              </FormLine>
              <FormLine marginBottom />

              <FormLine marginBottom>
                <h3>Enter the 6-digit code sent to your email</h3>
              </FormLine>

              <FormLine marginBottom>
                <OTPInput
                  digits={6}
                  label="6-digit code"
                  value={state.form.code as string || ''}
                  onChange={(e) => handleOnChange(e, 'code')}
                />
              </FormLine>

              <FormLine marginBottom />

              <FormLine marginBottom right>
                <PrimaryButton
                  width={'100%'}
                  loading={state.login.loading}
                  spinnerColor={theme.colors.coreSecondary}
                  disabled={state.login.loading || (state.form.code as string)?.length !== 6}
                  type="submit"
                >
                  Verify
                </PrimaryButton>
              </FormLine>
              {submitError && (
                <FormLine
                  center
                  marginBottom
                >
                  {submitError}
                </FormLine>
              )}
            </StyledFormWrapper>
          </StyledCard>
        ) : (
          <StyledCard boxShadow>
            <StyledFormWrapper onSubmit={handleSubmit}>
              <FormLine
                center
                marginBottom
              >
                <StyledHeader>Login</StyledHeader>
              </FormLine>

              <FormLine marginBottom>
                <HR />
              </FormLine>
              <FormLine marginBottom />

              <FormLine marginBottom>
                <TextInput
                  width={'100%'}
                  name="email"
                  label="Email"
                  type="email"
                  value={state.form.email as string}
                  error={errors.email}
                  onChange={(e) => handleOnChange(e, 'email')}
                  onBlur={() => validate({
                    form: state.form,
                    field: 'email'
                  })}
                />
              </FormLine>
              <FormLine marginBottom>
                <TextInput
                  width={'100%'}
                  name="password"
                  label="Password"
                  type="password"
                  value={state.form.password as string}
                  error={errors.password}
                  onChange={(e) => handleOnChange(e, 'password')}
                  onBlur={() => validate({
                    form: state.form,
                    field: 'password'
                  })}
                />
              </FormLine>

              <FormLine marginBottom />

              <FormLine marginBottom right>
                <PrimaryButton
                  width={'100%'}
                  loading={state.login.loading}
                  spinnerColor={theme.colors.coreSecondary}
                  disabled={state.login.loading}
                  type="submit"
                >
                  Login
                </PrimaryButton>
              </FormLine>
              {submitError && (
                <FormLine
                  center
                  marginBottom
                >
                  {submitError}
                </FormLine>
              )}

              <FormLine
                center
                marginBottom
              >
                <StyledLink to={"/request-password-reset"}>Reset password</StyledLink>
              </FormLine>
              <FormLine
                center
                marginBottom
              >
                <span>
                  <span>Don't have an account?&nbsp;</span>
                  <StyledLink to={"/signup"}>Sign up</StyledLink>
                </span>
              </FormLine>

              <RecaptchaNotice />
            </StyledFormWrapper>
          </StyledCard>
        )}
        <FormLine
          center
          marginBottom
        >
          <Footer />
        </FormLine>
      </Wrapper>
    </>
  );
};

export default memo(Login);

