import React, { FC, memo, useCallback, useState, useMemo } from 'react';
import { useParams, useNavigate } from "react-router";

import { gatewayService, authService } from 'services';
import { Avatar } from 'components/atoms';
import { Popup } from 'components/molecules';
import { UserData } from 'types/User';
import { NetworkError } from 'types/Error';
import { getNetworkErrors } from 'utils/general';

import {
  MenuWrapper,
  MenuItem
} from 'theme/mixins';
import {
  Wrapper,
  ProfileName,
  ProfileRole
} from './User.styles';

interface UserProps {
  clientId: string;
  userData: UserData | null;
  sideNavOpen: boolean;
}

interface State {
  menuOpen: boolean;
  logout: {
    loading: boolean;
    error: NetworkError | null;
  };
}

const initialState: State = {
  menuOpen: false,
  logout: {
    loading: false,
    error: null
  }
};

const User: FC<UserProps> = props => {
  const {
    userData
  } = props;

  const [state, setState] = useState<State>({
    ...initialState,
  });
  const navigate = useNavigate();
  const params: any = useParams();

  const name = useMemo(() => {
    if (!userData?.user) {
      return '';
    }

    return `${userData.user.firstName} ${userData.user.lastName}` || '';
  }, [userData]);

  const onToggleMenu = useCallback((menuOpen: boolean) => {
    setState(prevState => ({
      ...prevState,
      menuOpen
    }));
  }, []);

  const postLogout = useCallback(() => {
    authService.default.invalidate()
    navigate({ pathname: '/login' });
  }, [navigate]);

  const onLogout = useCallback(() => {
    if (state.logout.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      serviceCreate: {
        ...prevState.logout,
        loading: true
      }
    }));

    gatewayService.logout()
      .then(() => {
        setState(prevState => ({
          ...prevState,
          logout: {
            loading: false,
            error: null
          },
        }));

        postLogout();
      })
      .catch((e) => {
        setState(prevState => ({
          ...prevState,
          service: {
            ...prevState.logout,
            loading: false,
            error: getNetworkErrors([e])[0]
          }
        }));
      });
  }, [
    state.logout.loading,
    postLogout
  ]);

  const navigateToProfile = useCallback(() => {
    navigate({
      pathname: `/${params.clientId}/back-office/profile`
    });
  }, [
    navigate,
    params.clientId
  ]);

  return (
    <Wrapper type="button">
      <Avatar
        user={userData?.user}
        onClick={() => onToggleMenu(true)}
      />
      {state.menuOpen && userData && userData.user && (
        <Popup
          id={'user'}
          left
          bottom
          onClose={() => {
            onToggleMenu(false);
          }}
        >
          {({ closePopup }) => (
            <MenuWrapper>
              <MenuItem style={{marginBottom: '1rem'}}>
                <ProfileName>{name}</ProfileName>
                <ProfileRole>{userData.user!.role}</ProfileRole>
              </MenuItem>
              <MenuItem onClick={() => {
                closePopup();
                navigateToProfile();
              }}>Profile</MenuItem>
              <MenuItem onClick={() => {
                closePopup();
                onLogout();
              }}>Sign out</MenuItem>
            </MenuWrapper>
          )}
        </Popup>
      )}
    </Wrapper>
  );
};

export default memo(User);

