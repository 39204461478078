import React, { FC, useCallback, useState, useEffect, memo } from 'react';
import { useNavigate } from "react-router";
import { format } from 'date-fns';

import { Client } from 'types/Client';
import { FieldType } from 'types/Service';
import { gatewayService } from 'services';
import { Spinner } from 'components/atoms';
import { Popup } from 'components/molecules';
import { theme } from 'theme';
import {
  createDropdownOption,
  getNetworkErrors
} from 'utils/general';
import { useSortFilter } from '../../../hooks';
import { PageHeader } from '../../../components';
import {
  DATE_DROPDOWN_OPTIONS,
  ISO_FORMAT
} from '../../../../../../constants';
import { AdminPageProps } from 'components/AppRouter';
import {
  NetworkError
} from 'types/Error';

import {
  MenuWrapper,
  MenuItem,
  IconButton,
  StyledLink
} from 'theme/mixins';
import {
  Wrapper,
  ClientCard,
  ContentWrapper,
  ClientCardLeftColumnInner,
  NameAndStatus,
  StyledMenuDots,
  ClientCardOuter,
  ClientCardColumn,
  // FieldLabel,
  StatusIndicator
} from './Clients.styles';

interface State {
  clients: {
    loading: boolean;
    data: Client[] | null;
    total: number | null;
    fields: any;
    offset: number;
    error: NetworkError | null;
  };
}

const initialState: State = {
  clients: {
    loading: false,
    total: null,
    data: null,
    fields: {},
    offset: 0,
    error: null
  },
};

const Clients: FC<AdminPageProps> = props => {
  const {
    sideNavOpen,
    addToast
  } = props;

  const [state, setState] = useState<State>(initialState);
  const [activeMenuId, setActiveMenuId] = useState<string>();

  const { renderPaginationAndSort: paginationMarkup, renderFilterControl: filterControlMarkup } = useSortFilter({
    listItemId: 'client',
    total: state.clients.total,
    offset: state.clients.offset,
    onPrev: (opts) => fetchClients(opts),
    onNext: (opts) => fetchClients(opts),
    onFilter: (opts) => fetchClients(opts),
    filterOptions: {
      fields: [
        {
          label: 'Onboarded',
          name: 'isOnboarded',
          type: FieldType.Boolean
        },
        {
          label: 'Created',
          name: 'created',
          type: FieldType.DateTime,
          options: [
            createDropdownOption('Today', 'today'),
            createDropdownOption('Yesterday', 'yesterday'),
            ...DATE_DROPDOWN_OPTIONS
          ],
          default: {
            'created#datetime': `today=${format(new Date(), ISO_FORMAT)}`
          }
        },
        {
          label: 'Locked',
          name: 'locked.status',
          type: FieldType.Boolean
        }
      ]
    }
  });

  const navigate = useNavigate();

  const fetchClients = useCallback((opts?: any) => {
    if (state.clients.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      clients: {
        ...prevState.clients,
        loading: true
      }
    }));

    gatewayService.getClients(opts)
      .then((clientsResponse: any) => {
        setState(prevState => ({
          ...prevState,
          clients: {
            ...prevState.clients,
            loading: false,
            total: clientsResponse.total,
            data: [
              ...JSON.parse(JSON.stringify(clientsResponse.data))
            ],
            fields: clientsResponse.fields,
            offset: clientsResponse.offset,
            error: null
          }
        }));
      })
      .catch((err) => {
        setState(prevState => ({
          ...prevState,
          clients: {
            ...prevState.clients,
            loading: false,
            data: null,
            error: getNetworkErrors([err])[0]
          }
        }));

        addToast({
          type: 'error',
          content: 'Something went wrong loading Clients'
        });
      });
  }, [
    state.clients,
    addToast
  ]);

  const onMenuClick = useCallback((e: any, id: string) => {
    e.stopPropagation();

    setActiveMenuId(id);
  }, []);

  const closePopupMenu = useCallback(() => {
    // TODO: component still mounted
    setActiveMenuId('');
  }, []);

  const onClientClick = useCallback((e: any, clientId: string) => {
    navigate({ pathname: `/${clientId}/back-office/dashboard` });
  }, [navigate]);

  const renderClients = useCallback(() => {
    if (state.clients.loading) {
      return (
        <Spinner
          color={theme.textColor}
          size={'M'}
        />
      );
    }

    if (!state.clients.data) {
      return null;
    }

    return (
      <>
        {state.clients.data.map((client: Client) => {
          return (
            <ClientCard
              key={client._id}
              column
              boxShadow
              centerV
              onClick={(e: any) => onClientClick(e, client._id)}
            >
              <ClientCardOuter>
                <ClientCardColumn grow>
                  <ClientCardLeftColumnInner column>
                    <NameAndStatus>
                      <StatusIndicator
                        isEnabled={!!client.isOnboarded}
                      />
                      <StyledLink
                        to={{ pathname: `/${client._id}/back-office/dashboard` }}
                        onClick={(e: any) => e.stopPropagation() }
                        style={{ fontSize: '16px' }}
                      >
                        <b>{client.businessName}</b>
                      </StyledLink>
                    </NameAndStatus>
                    <ContentWrapper>
                      <p>{format(new Date(client.created.at), 'dd/MM/yy')}</p>
                    </ContentWrapper>
                  </ClientCardLeftColumnInner>
                </ClientCardColumn>
                <ClientCardColumn>
                  <ContentWrapper centerV>
                    <IconButton
                      hoverEffect
                      onClick={(e: any) => onMenuClick(e, client._id)}
                    >
                      <StyledMenuDots />
                      {activeMenuId === client._id && (
                        <Popup
                          id={client._id}
                          left
                          bottom
                          convertable
                          onClose={closePopupMenu}
                        >
                          {({ closePopup }) => (
                            <MenuWrapper>
                              <MenuItem onClick={() => {
                                closePopup();
                              }}>Option 1</MenuItem>
                            </MenuWrapper>
                          )}
                        </Popup>
                      )}
                    </IconButton>
                  </ContentWrapper>
                </ClientCardColumn>
              </ClientCardOuter>
            </ClientCard>
          );
        })}
        {paginationMarkup()}
      </>
    );
  }, [
    state.clients,
    activeMenuId,
    closePopupMenu,
    onMenuClick,
    onClientClick,
    paginationMarkup
  ]);

  useEffect(() => {
    if (!state.clients.data && !state.clients.error) {
      fetchClients();
    }
  }, [
    state.clients.data,
    state.clients.error,
    fetchClients,
  ]);

  return (
    <Wrapper>
      <PageHeader
        title={'Clients'}
        rightContent={
          <>
            {filterControlMarkup(sideNavOpen, true, !(!!state.clients.total))}
          </>
        }
      />
      {renderClients()}
    </Wrapper>
  );
};

export default memo(Clients);

