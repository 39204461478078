import React, { useState } from 'react';

import { FormField } from '../form';
import { StyledTextarea } from './TextArea.styles';
import { BaseProps } from '../form/types';
import { FormFieldProps } from '../form/FormField';

export interface TextAreaProps extends React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>{
  test?: any;
}

export type Props = TextAreaProps & BaseProps & FormFieldProps;

const TextArea = React.forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
  const {
    className,
    label,
    error,
    hasChanged,
    width,
    widthM,
    widthT,
    widthL,
    noWidth,
    noMinWidth,
    onFocus,
    onBlur,
    ...rest
  } = props;

  const [isFocused, setIsFocused] = useState<boolean>(false);

  return (
    <FormField
      id={rest.id}
      error={error}
      label={label}
      className={className}
      hasChanged={hasChanged}
      value={rest.value}
      width={width}
      widthM={widthM}
      widthT={widthT}
      widthL={widthL}
      noWidth={noWidth}
      noMinWidth={noMinWidth}
      isFocused={isFocused}
      isFull={String(rest.value as string).length > 0}
      info={rest.info}
      tooltip={rest.tooltip}
      builder={rest.builder}
      startAdornment={rest.startAdornment}
      endAdornment={rest.endAdornment}
      formFieldStyles={rest.formFieldStyles}
    >
      <StyledTextarea
        {...rest}
        ref={ref}
        value={!!rest.value ? rest.value : ''}
        autoComplete={rest.disableAutoComplete ? '!off' : undefined}
        errored={!!error}
        onFocus={e => {
          if (onFocus) {
            onFocus(e);
          }

          setIsFocused(true);
        }}
        onBlur={e => {
          if (onBlur) {
            onBlur(e);
          }

          setIsFocused(false);
        }}
      />
    </FormField>
  );
});

export default TextArea;
