import 'ress';
import { createGlobalStyle } from 'styled-components';

import { device } from 'theme/media';

export default createGlobalStyle`
  :root {
    --checkbox-width: 1;
    --checkbox-height: 1;
  }

  html {
    height: 100%;
    overflow-y: initial;
    overflow-x: hidden;
    margin: 0;
    font-size: 62.5%;
  }

  body, #root {
    height: 100%;
    max-width: 100vw;
    margin: 0;
    padding: 0;
    font-size: 1.4rem;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
    overflow-y: auto;

    @media ${device.mobile} {
      font-size: 1.4rem;
    }

    @media ${device.tablet} {
      font-size: 1.4rem;
    }
  }

  body {
    background-color: ${({ theme }) => theme.colors.neutralSecondary};
  }

  #root {
    background-position: 50%;
    background-size: cover;
  }

  h3, h4 {
    margin-bottom: 1rem;
  }

  button {
    -webkit-appearance: none;
    border-width: 0;
    border-color: transparent;
  }
  
  button:focus {
    outline: none;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  .tether-element {
    z-index: 800;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::selection {
    background-color: ${(props: any) => props.theme.selectionColor}62;
  }

  .highlight-element {
    background-color: ${({ theme }) => theme.colors.corePrimary}77 !important;
    animation: .25s linear 0s infinite alternate glow-2;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }

  // Tooltip
  // May need to use 'display: inline-block;' on parent or self if width is unnecessarily large to counteract the effect of 'right: -8rem;'
  @media ${device.laptop} {
    [data-title]:hover:after {
      opacity: 1;
      transition: all 0.25s ease-in-out 1s;
      visibility: visible;
    }
    [data-title]:after {
      content: attr(data-title);
      display: inline-block;
      position: absolute;
      bottom: 100.5%;
      left: 0;
      right: -8rem;     // if parent width is too small
      // padding: 4px 4px 4px 8px;
      padding: 1rem;
      -moz-border-radius: 5px;
      -webkit-border-radius: 5px;
      border-radius: 5px;
      -moz-box-shadow: 0px 0px 4px #222;
      -webkit-box-shadow: 0px 0px 4px #222;
      box-shadow: 0px 0px 4px #222;
      color: ${({ theme }) => theme.colors.neutralSecondary};
      background-color: black;
      opacity: 0;
      z-index: 99999;
      visibility: hidden;
      max-width: 20rem;
      max-height: 20rem;
      overflow-y: auto;
      text-align: left;
      font-size: 1.4rem;
      font-weight: normal;
    }
    [data-title] {
      position: relative;
    }
  }

  @keyframes spinner-animation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes checkbox-shrink-bounce {
    0%{
      transform: scale(1);
    }
    33%{
      transform: scale(.85);
    }
    100%{
      transform: scale(1);
    }
  }
  @keyframes checkbox-check {
    0%{
      width: 0;
      height: 0;
      border-color: ${({ theme }) => theme.textColor};
      transform: translate3d(100%,0,0) rotate(45deg);
    }
    33%{
      width: var(--checkbox-width);
      height: 0;
      transform: translate3d(100%,0,0) rotate(45deg);
    }
    100%{
      width: var(--checkbox-width);
      height: var(--checkbox-height);
      border-color: ${({ theme }) => theme.textColor};
      transform: translate3d(100%,0,0) rotate(45deg);
    }
  }

  @keyframes cursor {
    0% {
      background-color: ${({ theme }) => theme.colors.coreSecondary};
    }

    100% {
      background-color: transparent;
    }
  }

  @keyframes feature-knob-1 {
    0% {
      transform: translateX(0rem);
    }

    50% {
      transform: translateX(15rem);
    }

    100% {
      transform: translateX(-15rem);
    }
  }

  @keyframes feature-knob-2 {
    0% {
      transform: translateX(0rem);
    }

    50% {
      transform: translateX(20rem);
    }

    75% {
      transform: translateX(10rem);
    }

    100% {
      transform: translateX(25rem);
    }
  }

  @keyframes feature-knob-3 {
    0% {
      transform: translateX(0rem);
    }

    50% {
      transform: translateX(-15rem);
    }

    75% {
      transform: translateX(-4rem);
    }

    100% {
      transform: translateX(-27rem);
    }
  }

  @keyframes lock {
    0% {
      transform: rotateZ(0deg);
    }

    25% {
      transform: rotateZ(5.62deg);
    }

    75% {
      transform: rotateZ(-5.62deg);
    }

    100% {
      transform: rotateZ(0deg);
    }
  }

  @keyframes glow {
    0% {
      opacity: 0.2;
    }

    25% {
      opacity: 0.5;
    }

    75% {
      opacity: 0.8;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes toast-in {
    0% {
      transform: translateX(340px);
    }

    100% {
      transform: translateX(0px);
    }
  }

  @keyframes toast-out {
    0% {
      transform: translateX(0px);
    }

    100% {
      transform: translateX(340px);
    }
  }

  @keyframes glow-2 {
    0% {
      opacity: 1;
    }

    25% {
      opacity: 0.8;
    }

    75% {
      opacity: 0.5;
    }

    100% {
      opacity: 0.2;
    }
  }

  @keyframes stat-pd {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;
