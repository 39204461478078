import styled from 'styled-components';

export * from 'theme/mixins';

export const Wrapper = styled.div`
  display: flex;
  width: inherit;
  height: 100%;
  flex-direction: column;
`;

