import React from 'react'

export interface HomeProps {
  className?: string
}

const Home = (props: HomeProps) => (
  <svg className={props.className} width="2.4rem" height="2.4rem" viewBox="0 0 24 24" fill="#FFFFFF">
    <path d="M0 0h24v24H0V0z" fill="none"/>
    <path d="M12 5.69l5 4.5V18h-2v-6H9v6H7v-7.81l5-4.5M12 3L2 12h3v8h6v-6h2v6h6v-8h3L12 3z"/>
  </svg>
)

export default Home
