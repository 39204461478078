import { theme as gTheme } from 'theme';
import styled from 'styled-components';
import { CardElement } from "@stripe/react-stripe-js";

// Styles taken from /atoms/form/mixins
export const options = {
  style: {
    base: {
      color: gTheme.textColor,
      fontFamily: '"Roboto", sans-serif',
      // might not be needed in newer versions
      // fontSmoothing: 'none',
      fontSize: '14px',
      backgroundColor: gTheme.colors.neutralSecondary,
      '::selection': {
        backgroundColor: `#FDD67D62`
      }
    },
    empty: {
      color: gTheme.baseInput.placeholder.color,
      fontSize: '14px',
      '::placeholder': {
        color: gTheme.baseInput.placeholder.color
      },
      fontWeight: 'normal'
    },
    invalid: {
      color: gTheme.textColor,
      iconColor: gTheme.colors.accentTertiary,
      '::selection': {
        backgroundColor: `#FDD67D62`
      }
    }
  }
};

export const CardNumberWrapper = styled.div<{ isFocused: boolean, isFull: boolean }>`
  width: 100%;
  height: 3.7rem;
  border: 1px solid ${({ theme }) => theme.option.borderColor};
  border-radius: .4rem;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.neutralSecondary};
  transition: border ${({ theme }) => theme.animSpeed};

  ${({ theme, isFocused }) =>
    isFocused &&
    `
      border-color: ${theme.option.active.color} !important;
  `}

  :hover {
    border-color: ${gTheme.baseInput.hover.borderColor};
  }
`;

export const StyledCardNumberInput = styled(CardElement)`
  width: 100%;

  &--webkit-autofill {
    background: transparent !important;
  }
`;

export const CardError = styled.div`
  text-align: left;
  margin-top: 1rem;
  color: ${({ theme }) => theme.colors.accentTertiary};
`;

export const TrustImage = styled.img`
  width: 100%;
  height: auto;
  border: .1rem solid ${({ theme }) => theme.colors.neutralPrimaryDark3};
`;
