import styled from 'styled-components';
import { device } from 'theme/media';
// import { Link } from 'react-router-dom';

import {
  Tick
} from 'components/atoms';

export const Wrapper = styled.div`
  display: flex;
  width: inherit;
  flex-direction: column;
  margin-bottom: 1.75rem;
`;

export const ItemIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.corePrimary};

  @media ${device.mobile} {
    width: 3rem;
    height: 3rem;
  }

  @media ${device.tablet} {
    width: 5rem;
    height: 5rem;
  }
`;

export const Steps = styled.div`
  display: block;
  width: inherit;
  height: 100%;
  overflow-x: auto;
  white-space: nowrap;
  padding: 1rem 0;
  max-width: ${({ theme }) => `calc(100vw - ${theme.appPadding.value * 2}${theme.appPadding.unit})`};
`;

export const Summary = styled.div`
  display: flex;
  width: 100%;
  height: 50%;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  white-space: normal;
  font-weight: bold;
  opacity: .7;
  transition: opacity ${({ theme }) => theme.animSpeed} ease-in-out;

  @media ${device.mobile} {
    // font-size: 1.2rem;
  }

  @media ${device.tablet} {
    // font-size: 1.4rem;
  }
`;

export const Status = styled.div`
  display: flex;
  width: 100%;
  height: 50%;
  justify-content: center;
  opacity: .7;
  transition: opacity ${({ theme }) => theme.animSpeed} ease-in-out;

  svg {
    fill: ${({ theme }) => theme.textColor};

    @media ${device.mobile} {
      width: 1.5rem;
    }

    @media ${device.tablet} {
      width: 2rem;
    }
  }
`;

export const Step = styled.div<{
  completed: boolean;
  isLast: boolean;
  $disabled?: boolean;
}>`
  display: inline-block;
  position: relative;
  flex-direction: column;
  margin-right: 2rem;
  border: .1rem solid ${({ theme }) => theme.colors.corePrimary}77;
  background-color: ${({ theme }) => theme.colors.neutralSecondary};
  box-shadow: rgb(60 66 87 / 8%) 0px 3px 9px 0px;
  border-radius: 1.25rem;
  vertical-align: top;
  transition: border ${({ theme }) => theme.animSpeed} ease-in-out;
  // cursor: pointer;

  ${({ theme, completed }) => completed && `
    border: .1rem solid ${theme.colors.accentSecondary};

    ${Summary}, ${Status} {
      opacity: 1;
    }

    ${Status} {
      svg {
        fill: ${theme.colors.neutralSecondary};
      }
    }

    ${ItemIconWrapper} {
      background-color: ${theme.colors.accentSecondary};
    }
  `}

  ${({ theme, completed, isLast }) => !isLast && `
    :after {
      content: '';
      position: absolute;
      top: calc(50% - .2rem / 2);
      right: -2rem;
      width: 2rem;
      height: .2rem;

      ${!completed ? `
        background-color: ${theme.colors.corePrimary};
      ` : ''}

      ${completed ? `
        background-color: ${theme.colors.accentSecondary};
      ` : ''}
    }
  `}

  ${({ $disabled }) => $disabled && `
    filter: grayscale(1);
  `}

  :last-child {
    margin-right: 0;
  }

  ${({ theme, completed, $disabled }) => !$disabled && `
    :hover {
      ${!completed ? `
        border: .1rem solid ${theme.colors.corePrimary};
      ` : ''}

      ${Summary}, ${Status} {
        opacity: 1;
      }
    }
  `}

  @media ${device.mobile} {
    width: 14rem;
    height: 12rem;
  }

  @media ${device.tablet} {
    width: 18rem;
    height: 16rem;
  }
`;

export const StyledTick = styled(Tick)`
  display: flex;
  fill: ${({ theme }) => theme.colors.neutralSecondary};
`;
