import styled from 'styled-components';
import { device } from 'theme/media';

import {
  PrimaryButton,
  Button,
  Cross
} from 'components/atoms';

export const Wrapper = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 11;
`;

export const InnerWrapper = styled.div`
  display: flex;
  position: relative;
  padding: 3rem;
  min-height: 20rem;
  max-height: 50rem;
  // border-radius: 0.7rem;
  box-shadow: black 0 0 .5rem 0;
  background-color: ${({ theme }) => theme.modal.backgroundColor};
  flex-direction: column;
  z-index: 5;

  @media ${device.mobile} {
    width: 100%;
  }

  @media ${device.mobileL} {
    width: unset;
    min-width: 40rem;
    max-width: 60rem;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  position: relative;
  width: inherit;
  height: inherit;
  overflow-y: auto;
  flex-direction: column;
`;

export const OverlayMask = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 4;
`;

export const ActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto 0 0 auto;
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
  display: flex;
  font-size: 1.4rem;
  margin-left: 1rem;
`;

export const StyledNegativeButton = styled(Button)`
  display: flex;
  font-size: 1.4rem;
`;

export const Title = styled.h2`
  margin-bottom: 2rem;
`;

export const StyledCross = styled(Cross)`
  position: absolute;
  top: -4rem;
  right: -4rem;
  width: 5rem;
  height: auto;
  cursor: pointer;

  path {
    fill: white;
  }
`;
