export const getQueryParam = (key: string, url: string): string | undefined => {
  if (!url) {
    return undefined;
  }

  if (!url.includes(key)) {
    return undefined;
  }

  const start = url.indexOf(`${key}=`);
  const end = url.indexOf('&', start);
  const param = url.substring(start + key.length + 1, end === -1 ? url.length : end);

  // console.log(start, end, param);

  return param.length > 0 ? param : undefined;
};

