import { css } from 'styled-components';

export const elemMixin = (errored?: boolean, textRight?: boolean) => {
  return css`
    position: relative;
    width: 100%;
    height: 3.7rem;
    padding: 1rem;
    background-color: ${({ theme }) => theme.colors.neutralSecondary};
    border: 1px solid ${({ theme }) => theme.option.borderColor};
    border-radius: .4rem;
    outline: none;
    transition: border ${({ theme }) => theme.animSpeed};

    ${textRight && `
      text-align: right;
    `}

    ${({ theme }) =>
      errored
        ? `
      border-color: ${theme.colors.accentTertiary};
      caret-color: ${theme.colors.accentTertiary};

      ::selection { 
        background-color: ${theme.colors.accentTertiary}40;
      }
    `
        : `
      :hover {
        border-color: ${theme.baseInput.hover.borderColor};
      }

      :active {
        border-color: ${theme.baseInput.active.borderColor};
      }

      :focus {
        border-color: ${theme.baseInput.focus.borderColor};
      }
    `}

    &:focus,
    &:active {
      border-color: ${({ theme }) => theme.option.active.color};
    }

    :read-only {
      border-color: transparent;
      color: ${({ theme }) => theme.baseInput.readonly.color};
      // padding-left: 0.2rem;
      // padding-right: 0.2rem;
    }

    :disabled {
      border-color: ${({ theme }) => theme.baseInput.disabled.borderColor};
      color: ${({ theme }) => theme.baseInput.disabled.color};

      border: 1px solid #C8C9CA;
      background-color: hsl(0, 0%, 95%);
    }

    ::placeholder {
      color: ${({ theme }) => theme.baseInput.placeholder.color};
      // font-style: italic;
      font-weight: 300;
    }
  `;
};

