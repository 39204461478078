import React, { FC, useCallback, useState, useEffect, memo, useMemo } from 'react';
import {
  useParams,
} from "react-router";
// import { useTranslation } from 'react-i18next';
import {
  format,
  isSameMonth,
  addDays,
  getDate,
  getMonth,
  getYear,
  startOfWeek
} from 'date-fns';

import {
  Event,
  EventType,
  Status
} from 'types/Event';
import { StatusType } from 'types/Job';
import { FulfillerGroup } from 'types/FulfillerGroup';
import { User } from 'types/User';
import { Fulfiller } from 'types/Fulfiller';
import { gatewayService } from 'services';
import {
  Button,
  PrimaryButton,
  Spinner,
  Worker
} from 'components/atoms';
import {
  Popup
} from 'components/molecules';
import { theme } from 'theme';
import {
  formatJobStatus,
  getStatusState,
  getNetworkErrors,
  stripZoneFromISOString,
  doubleSingleDigit
} from 'utils/general';
import { Calendar as CalendarComponent } from '../../components';
import { getEventSummaryLine } from '../../components/Calendar/Day';
import { EventWithSpan } from '../../components/Calendar/Calendar';
import { PageHeader } from '../../components';
import { AdminPageProps } from 'components/AppRouter';
import { EventModal } from './modals';
import { useMediaSizes } from '../../hooks';
import {
  canCreateEvent,
  canUpdateEvent,
  canDeleteEvent,
  isFulfiller
} from 'config/privileges';
import {
  NetworkError
} from 'types/Error';
import {
  ModalWrapper,
  ModalItem,
  IconButton,
  EventCancelledWrapper
} from 'theme/mixins';
import {
  ISO_FORMAT,
  DAY_IN_MILLISECONDS,
  UI_INTERNAL_EVENT
} from '../../../../../constants';

import {
  Wrapper,
  StyledPlus,
  ModalBorder,
  AdminFormLine,
  StyledEditIcon,
  StyledCrossIcon,
  StyledBinIcon,
  StyledCalendar,
  StyledProfile,
  EventColour,
  ViewEventRow,
  ViewEventIconWrapper,
  JobStatus,
  StyledLink,
  EventsModalWrapper,
  StyledGroups
} from './Calendar.styles';

interface State {
  admins: {
    loading: boolean;
    data: User[] | null;
    total: number | null;
    error: NetworkError | null;
  };
  events: {
    loading: boolean;
    data: Event[] | null;
    periodDates: string[];
    total: number | null;
    fields: any;
    offset: number;
    error: NetworkError | null;
  };
  eventCreateModal: {
    show: boolean;
    loading: boolean;
    data: Event | null;
    error: NetworkError | null;
    date?: Date;
  };
  eventUpdateModal: {
    show: boolean;
    loading: boolean;
    error: NetworkError | null;
    event: EventWithSpan | null;
  };
  eventView: {
    event: EventWithSpan | null;
    action: string;
    targetElementBoundingRect: { x: number; y: number; width: number; height: number; } | null
  };
  eventsView: {
    date: Date | null;
    events: EventWithSpan[] | null;
    action: string;
    targetElementBoundingRect: { x: number; y: number; width: number; height: number; } | null
  };
  eventDelete: {
    loading: boolean;
    error: NetworkError | null;
  };
  fulfillers: {
    loading: boolean;
    data: Fulfiller[] | null;
    total: number | null;
    error: NetworkError | null;
  };
  fulfillerGroups: {
    loading: boolean;
    data: FulfillerGroup[] | null;
    total: number | null;
    error: NetworkError | null;
  };
  modal: {
    show: boolean;
    header: string;
    content: string;
    buttons: any[];
  };
  currentDate: Date | null;
  calendarKey: number;
}

const initialState: State = {
  admins: {
    loading: false,
    total: null,
    data: null,
    error: null
  },
  events: {
    loading: false,
    data: null,
    periodDates: [],
    total: null,
    fields: {},
    offset: 0,
    error: null
  },
  eventCreateModal: {
    show: false,
    loading: false,
    data: null,
    error: null,
    date: undefined
  },
  eventUpdateModal: {
    show: false,
    loading: false,
    error: null,
    event: null
  },
  eventView: {
    event: null,
    action: '',
    targetElementBoundingRect: null
  },
  eventsView: {
    date: null,
    events: null,
    action: '',
    targetElementBoundingRect: null
  },
  eventDelete: {
    loading: false,
    error: null
  },
  fulfillers: {
    loading: false,
    total: null,
    data: null,
    error: null
  },
  fulfillerGroups: {
    loading: false,
    data: null,
    total: null,
    error: null
  },
  modal: {
    show: false,
    header: '',
    content: '',
    buttons: []
  },
  currentDate: null,
  calendarKey: new Date().getTime()
};

const getEmptyEvent = (id: string): EventWithSpan => {
  return {
    _id: id,
    type: EventType.General,
    status: Status.Submitted,
    created: {
      by: 'system',
      at: format(new Date(), ISO_FORMAT),
    },
    updated: {
      by: 'system',
      at: format(new Date(), ISO_FORMAT),
    },
    summary: UI_INTERNAL_EVENT,
    creatorId: '',
    start: format(new Date(), ISO_FORMAT),
    end: format(new Date(), ISO_FORMAT),
    usersAndGroups: [],
    colour: '#08f',
    clientId: '',
    span: {
      isStart: false,
      isMiddle: false,
      isEnd: false,
      duration: {
        ms: 0,
        days: 0
      }
    }
  }
};

const filterInternalEvents = (event: Event | EventWithSpan) => event.summary !== UI_INTERNAL_EVENT;

const allSpanDayEntriesAligned = (
  spanEntries: EventWithSpan[],
  orderMap: { [key: string]: number },
  cursorIsStartOfWeek: boolean
): boolean => {
  if (cursorIsStartOfWeek) {
    return true;
  }

  return spanEntries.every((entry: EventWithSpan, index: number) => {
    // if (cursorIsStartOfWeek || entry.span.isStart) {

    const correctIndex: number = orderMap[`${entry.summary}-${entry._id}`];

    // check for correct positioning. also check for identical and inverse identical swaps e.g. [0, 1] is the same as [1, 0]
    return index === correctIndex;
  });
};

const Calendar: FC<AdminPageProps> = props => {
  const {
    userData,
    addToast
  } = props;

  const [state, setState] = useState<State>(initialState);

  const { clientId } = useParams();
  const { isMobile } = useMediaSizes();

  const calendarEntries = useMemo(() => {
    let entriesMap: { [key: string]: Array<Event | EventWithSpan> } = {};

    if (!state.events.data) {
      return entriesMap;
    }

    const orderMap: { [key: string]: number } = {};
    // for debug
    // let prevOrderMap: any = {};

    const entries = state.events.data;
    const periodDates: string[] = state.events.periodDates;

    let dateCursor: Date = new Date(stripZoneFromISOString(periodDates[0]));
    const endPeriodDate: Date = new Date(stripZoneFromISOString(periodDates[1]));

    while (dateCursor.getTime() !== endPeriodDate.getTime()) {
      const cursorStartOfWeek: Date = startOfWeek(dateCursor, { weekStartsOn: 0 });
      const cursorIsStartOfWeek: boolean = cursorStartOfWeek.getTime() === dateCursor.getTime();

      // Date is source of truth, so extract date from there
      const dateStr = `${dateCursor.getFullYear()}-${doubleSingleDigit(dateCursor.getMonth() + 1)}-${doubleSingleDigit(dateCursor.getDate())}`;
      const mDate: Date = new Date(`${dateStr}T00:00:00.000`);

      // console.log('-----mDate', date, day, mDate.toISOString());

      const dayEntries: Event[] = entries
        .map((entry: Event, index: number) => {
          const mStart = new Date(stripZoneFromISOString(entry.start));
          const start = mStart;
          const mEnd = new Date(stripZoneFromISOString(entry.end));
          const end = mEnd;
          const diff: number = (end as any) - (start as any);
          const startDate: string = format(start, ISO_FORMAT).split('T')[0];
          const endDate: string = format(end, ISO_FORMAT).split('T')[0];
          const span: boolean = diff > DAY_IN_MILLISECONDS || startDate !== endDate;
          const spanStart: boolean = span && getDate(mDate) === getDate(mStart) && getYear(mDate) === getYear(mStart) && getMonth(mDate) === getMonth(mStart);
          const spanEnd: boolean = span && !spanStart && dateStr === endDate;
          const spanMiddle: boolean = span && !spanEnd && mDate > start && mDate < end;

          if (span && (spanStart || spanMiddle || spanEnd)) {
            return {
              ...entry,
              span: {
                isStart: spanStart,
                isMiddle: spanMiddle,
                isEnd: spanEnd,
                duration: {
                  ms: (end as any) - (start as any),
                  days: Math.ceil(((end as any) - (start as any)) / DAY_IN_MILLISECONDS)
                }
              }
            };
          }

          return entry;
        })
        .filter((entry: Event | EventWithSpan) => {
          const entryStartDateTimeSplit: string[] = entry.start.split('T');
          const entryStartDateStr: string = entryStartDateTimeSplit[0];

          if ((entry as EventWithSpan).span) {
            return true;
          }

          return entryStartDateStr === dateStr;
        })
        // Order entries in asc order according to event start
        // If same start time, order according to event creation date
        .sort((a: Event, b: Event) => {
          if (a.start === b.start) {
            return a.created.at.localeCompare(b.created.at);
          }

          return a.start.localeCompare(b.start);
        });

      const nonSpanEntries = dayEntries
        .filter((entry: Event | EventWithSpan) => {
          return !(entry as EventWithSpan).span;
        })

      let spanEntries: EventWithSpan[] = dayEntries
        .filter((entry: Event | EventWithSpan): entry is EventWithSpan => {
          return !!(entry as EventWithSpan).span;
        });

      // Move ending span events to the end of the list to not create a gap for the rest of the week amongst spanned entries
      if (cursorIsStartOfWeek) {
        const endingSpanEvents = spanEntries.filter(event => event.span?.isEnd);
        const otherSpanEvents = spanEntries.filter(event => !event.span?.isEnd);

        spanEntries = [
          ...otherSpanEvents,
          ...endingSpanEvents
        ];

      }

      // Capture original order after sort
      spanEntries.forEach((entry: EventWithSpan, index: number) => {
        if (cursorIsStartOfWeek || entry.span.isStart) {
          orderMap[`${entry.summary}-${entry._id}`] = index;
          // console.log('------entry added to orderMap', dateStr, entry, orderMap);
        }
      });

      // Check if items are aligned
      const amendedSpanEntries = [ ...spanEntries ];
      let allSpanEntriesAligned: boolean = allSpanDayEntriesAligned(amendedSpanEntries, orderMap, cursorIsStartOfWeek);

      // console.log('----------allSpanEntriesAligned', allSpanEntriesAligned, dateStr);

      let iterations = 0;
      while (!allSpanEntriesAligned) {
        // Get swap items
        let swap: number[] = [];
        let newElems: number = -1;

        for (let index = 0; index < amendedSpanEntries.length; index++) {
          const entry: EventWithSpan = amendedSpanEntries[index];
          const correctIndex: number = orderMap[`${entry.summary}-${entry._id}`];

          // Check for correct positioning
          if (index !== correctIndex && !isNaN(correctIndex)) {
            swap = [index, correctIndex || 0];

            // console.log(
            //   '-------------------------------------------------------in',
            //   index,
            //   correctIndex,
            //   amendedSpanEntries.length,
            //   dateStr,
            //   swap,
            //   entry,
            //   orderMap
            // );

            if (correctIndex >= amendedSpanEntries.length) {
              newElems = (correctIndex - amendedSpanEntries.length) + 1;
              // console.log('-------------------------------------------------------in newElems', newElems);

              for (let i = 0; i < newElems; i++) {
                amendedSpanEntries.push(getEmptyEvent(`${dateStr}-${amendedSpanEntries.length + newElems}`));
              }
            }

            break;
          }
        }

        // Do the swap
        if (swap.length) {
          const entryA = amendedSpanEntries[swap[0]];
          const entryB = amendedSpanEntries[swap[1]];

          amendedSpanEntries[swap[0]] = entryB;
          amendedSpanEntries[swap[1]] = entryA;

          // If the initial order of span entry A has changed, update it
          const entryAOrderMapIndex: number = orderMap[`${entryA.summary}-${entryA._id}`];
          if (entryA
            && entryA.span
            && entryA.span.isStart
            && entryAOrderMapIndex !== swap[1]
          ) {
            orderMap[`${entryA.summary}-${entryA._id}`] = swap[1];
          }
          // If the initial order of span entry B has changed, update it
          const entryBOrderMapIndex: number = orderMap[`${entryB.summary}-${entryB._id}`];
          if (entryB
            && entryB.span
            && entryB.span.isStart
            && entryBOrderMapIndex !== swap[0]
          ) {
            orderMap[`${entryB.summary}-${entryB._id}`] = swap[0];
          }

          // Retest
          allSpanEntriesAligned = allSpanDayEntriesAligned(amendedSpanEntries, orderMap, cursorIsStartOfWeek);
        } else {
          allSpanEntriesAligned = true;
        }

        iterations++;
        // debugging purposes
        if (iterations === 50) {
          console.log('-----breaking infiinite loop');

          allSpanEntriesAligned = true;
        }
      }

      const sortedDayEntries: Array<Event | EventWithSpan> = [
        ...amendedSpanEntries,
        ...nonSpanEntries
      ];

      // Replace empty slots with real events
      if (nonSpanEntries.length > 0) {
        let emptyIndex: number = sortedDayEntries.findIndex((e: Event | EventWithSpan, index: number) => {
          return e.summary === UI_INTERNAL_EVENT;
        });
        let nonSpanEntryIndex: number = sortedDayEntries.findIndex((e: Event | EventWithSpan, index: number) => {
          return !(e as EventWithSpan).span;
        });
        const processedMap: any = new Map();

        while (emptyIndex >= 0 && nonSpanEntryIndex >= 0) {
          // console.log('==================here', emptyIndex, nonSpanEntryIndex);
          const removedItems: Array<Event | EventWithSpan> = sortedDayEntries.splice(nonSpanEntryIndex, 1);
          // console.log('==================removedItems', removedItems);

          if (removedItems.length > 0) {
            sortedDayEntries.splice(emptyIndex, 1, removedItems[0]);
            processedMap.set(emptyIndex, true);
          }

          emptyIndex = sortedDayEntries.findIndex((e: Event | EventWithSpan, index: number) => {
            return e.summary === UI_INTERNAL_EVENT;
          });
          nonSpanEntryIndex = sortedDayEntries.findIndex((e: Event | EventWithSpan, index: number) => {
            return !(e as EventWithSpan).span && !processedMap.get(index);
          });
        }
      }

      entriesMap = {
        ...entriesMap,
        [dateStr]: sortedDayEntries
      };

      // console.log('--------dayEntries', dateStr, day, dayEntries);

      // debug
      // if (JSON.stringify(orderMap) !== JSON.stringify(prevOrderMap)) {
      //   console.log('--------order map', orderMap);
      // }
      // prevOrderMap = { ...orderMap };

      dateCursor = addDays(dateCursor, 1);
    }

    // console.log('--------entriesMap', entriesMap);

    return entriesMap;
  }, [
    state.events.data,
    state.events.periodDates
  ]);

  const getFormattedUsersAndGroups = (userIds: string[], data: Array<FulfillerGroup | User | Fulfiller>): string => {
    return userIds
      .map((id: string) => {
        const found = data.find(d => d._id === id);

        if (found) {
          if ((found as FulfillerGroup).name) {
            return (found as FulfillerGroup).name;
          } else {
            return `${(found as Fulfiller | User).firstName} ${(found as Fulfiller | User).lastName}`;
          }
        }

        return 'Unknown group/user/fulfiller';
      })
      .join(', ');
  };

  const fetchAdmins = useCallback((opts?: any) => {
    if (state.admins.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      admins: {
        ...prevState.admins,
        loading: true
      }
    }));

    gatewayService.getUsers(clientId!, opts)
      .then((usersResponse: any) => {
        setState(prevState => ({
          ...prevState,
          admins: {
            loading: false,
            total: usersResponse.total,
            data: [
              ...JSON.parse(JSON.stringify(usersResponse.data))
            ],
            fields: usersResponse.fields,
            offset: usersResponse.offset,
            error: null
          }
        }));
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          admins: {
            ...prevState.admins,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.admins,
    clientId,
    addToast
  ]);

  const fetchFulfillers = useCallback((opts?: any) => {
    if (state.fulfillers.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      fulfillers: {
        ...prevState.fulfillers,
        loading: true
      }
    }));

    gatewayService.getFulfillers(clientId!, opts)
      .then((fulfillersResponse: any) => {
        setState(prevState => ({
          ...prevState,
          fulfillers: {
            loading: false,
            total: fulfillersResponse.total,
            data: [
              ...JSON.parse(JSON.stringify(fulfillersResponse.data))
            ],
            error: null
          }
        }));
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          fulfillers: {
            ...prevState.fulfillers,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.fulfillers,
    clientId,
    addToast
  ]);

  const fetchFulfillerGroups = useCallback(() => {
    if (state.fulfillerGroups.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      fulfillerGroups: {
        ...prevState.fulfillerGroups,
        loading: true
      }
    }));

    gatewayService.getFulfillerGroups(clientId!)
      .then((fulfillerGroupsResponse: any) => {
        setState(prevState => ({
          ...prevState,
          fulfillerGroups: {
            ...prevState.fulfillerGroups,
            loading: false,
            total: fulfillerGroupsResponse.total,
            data: fulfillerGroupsResponse.data,
            error: null
          }
        }));
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          fulfillerGroups: {
            ...prevState.fulfillerGroups,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.fulfillerGroups,
    clientId,
    addToast
  ]);

  const onNew = useCallback((date?: Date) => {
    setState(prevState => ({
      ...prevState,
      eventCreateModal: {
        ...prevState.eventCreateModal,
        show: true,
        date
      }
    }));

    if (!isFulfiller(userData.user!)) {
      fetchFulfillers();
      fetchAdmins();
      fetchFulfillerGroups();
    }
  }, [
    userData.user,
    fetchAdmins,
    fetchFulfillers,
    fetchFulfillerGroups
  ]);

  const editEvent = useCallback((event: EventWithSpan) => {
    setState(prevState => ({
      ...prevState,
      eventUpdateModal: {
        ...prevState.eventUpdateModal,
        show: true,
        event
      }
    }));
  }, []);

  const fetchEvents = useCallback((isoDate?: string) => {
    if (state.events.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      events: {
        ...prevState.events,
        loading: true
      }
    }));

    gatewayService.getEvents(
      clientId!,
      isoDate || format(new Date(), ISO_FORMAT)
    )
      .then((eventsResponse: any) => {
        setState(prevState => ({
          ...prevState,
          events: {
            ...prevState.events,
            loading: false,
            total: eventsResponse.total,
            data: [
              ...eventsResponse.data
            ],
            periodDates: eventsResponse.periodDates,
            fields: eventsResponse.fields,
            offset: eventsResponse.offset,
            error: null
          }
        }));
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          events: {
            ...prevState.events,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.events,
    clientId,
    addToast
  ]);

  const removeEvent = useCallback((id: string, callback: (e?: NetworkError) => void) => {
    if (state.eventDelete.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      eventDelete: {
        ...prevState.eventDelete,
        loading: true
      }
    }));

    gatewayService.deleteEvent(clientId!, id)
      .then(() => {
        setState(prevState => ({
          ...prevState,
          eventDelete: {
            ...prevState.eventDelete,
            loading: false,
            error: null
          }
        }));

        addToast({
          type: 'success',
          content: 'Event deleted'
        });

        callback();
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          eventDelete: {
            ...prevState.eventDelete,
            loading: false,
            error: getNetworkErrors([err])[0]
          }
        }));

        callback(err);

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.eventDelete,
    clientId,
    addToast
  ]);

  const createEvent = useCallback((payload: Partial<Event>, cb: () => void) => {
    setState(prevState => ({
      ...prevState,
      eventCreateModal: {
        ...prevState.eventCreateModal,
        loading: true
      }
    }));

    const newPayload = {
      ...payload,
      type: EventType.General,
      creatorId: userData.user!._id,
      start: format(payload.start as any, ISO_FORMAT),
      end: format(payload.end as any, ISO_FORMAT)
    };

    gatewayService
      .createEvent(clientId!, newPayload)
      .then((createEventResponse: any) => {
        setState(prevState => ({
          ...prevState,
          eventCreateModal: {
            ...initialState.eventCreateModal
          }
        }));

        addToast({
          type: 'success',
          content: 'Event created'
        });

        cb();
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          eventCreateModal: {
            ...prevState.eventCreateModal,
            loading: false,
            error: getNetworkErrors([err])[0]
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    userData,
    clientId,
    addToast
  ]);

  const updateEvent = useCallback((payload: Partial<Event>, cb?: () => void) => {
    setState(prevState => ({
      ...prevState,
      eventUpdateModal: {
        ...prevState.eventUpdateModal,
        loading: true
      }
    }));

    const newPayload = {
      start: format(payload.start as any, ISO_FORMAT),
      end: format(payload.end as any, ISO_FORMAT),
      summary: payload.summary,
      colour: payload.colour,
      description: payload.description,
      isAllDay: payload.isAllDay,
      usersAndGroups: payload.usersAndGroups
    };

    gatewayService
      .updateEvent(clientId!, payload._id!, newPayload)
      .then((eventUpdateResponse: any) => {
        setState(prevState => ({
          ...prevState,
          eventUpdateModal: {
            ...initialState.eventUpdateModal
          }
        }));

        addToast({
          type: 'success',
          content: 'Event updated'
        });

        if (cb) {
          cb();
        }
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          eventUpdateModal: {
            ...prevState.eventUpdateModal,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    clientId,
    addToast
  ]);

  const closeModals = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      modal: {
        ...initialState.modal
      },
      eventCreateModal: {
        ...initialState.eventCreateModal
      },
      eventUpdateModal: {
        ...initialState.eventUpdateModal
      },
      eventView: {
        ...initialState.eventView
      },
      eventsView: {
        ...initialState.eventsView
      }
    }));
  }, []);

  const closeModal = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      modal: {
        ...initialState.modal
      }
    }));
  }, []);

  const closeViewEventModal = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      eventView: {
        ...initialState.eventView
      },
    }));
  }, []);

  const closeViewEventsModal = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      eventsView: {
        ...initialState.eventsView
      },
    }));
  }, []);

  const closeEventModal = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      eventCreateModal: {
        ...initialState.eventCreateModal
      },
      eventUpdateModal: {
        ...initialState.eventUpdateModal
      }
    }));
  }, []);

  const openViewEventModal = useCallback((
    event: EventWithSpan,
    targetElementBoundingRect: {
      x: number;
      y: number;
      width: number;
      height: number;
    } | null
  ) => {
    setState(prevState => ({
      ...prevState,
      eventView: {
        ...prevState.eventView,
        action: 'view-event',
        event,
        targetElementBoundingRect
      },
      eventsView: {
        ...initialState.eventsView
      }
    }));
  }, []);

  const onSelectDay = useCallback((date: Date, events: EventWithSpan[], targetElementBoundingRect: { x: number; y: number; width: number; height: number; } | null) => {
    if (!events.length) {
      closeViewEventModal();
      closeViewEventsModal();
      onNew(date);

      return;
    }

    setState(prevState => ({
      ...prevState,
      eventsView: {
        ...prevState.eventsView,
        date,
        action: 'view-events',
        events,
        targetElementBoundingRect
      },
      eventView: {
        ...initialState.eventView
      }
    }));
  }, [
    onNew,
    closeViewEventModal,
    closeViewEventsModal
  ]);

  const getEventDuration = useCallback((event: EventWithSpan): string => {
    if (event.isAllDay) {
      return 'All day';
    }

    if (event.span) {
      return `${format(stripZoneFromISOString(event.start), 'dd/MM/yyyy')} - ${format(stripZoneFromISOString(event.end), 'dd/MM/yyyy')}`;
    } else {
      const startTime: string = event.start.split('T')[1].split(':').slice(0, 2).join(':');
      const endTime: string = event.end.split('T')[1].split(':').slice(0, 2).join(':');

      return `${startTime} - ${endTime}`;
    }
  }, []);

  const onEventSubmit = useCallback((form: any, isEdit: boolean, cb: () => void) => {
    if (isEdit) {
      updateEvent(form, () => {
        cb();
        fetchEvents(state.currentDate ? format(state.currentDate, ISO_FORMAT) : undefined);
      });
    } else {
      createEvent(form, () => {
        cb();
        fetchEvents(state.currentDate ? format(state.currentDate, ISO_FORMAT) : undefined);
      });
    }
  }, [
    state.currentDate,
    createEvent,
    updateEvent,
    fetchEvents
  ]);

  const getOtherViewEventRows = useCallback((event: EventWithSpan): JSX.Element | null => {
    let rows: JSX.Element | null = null;

    if (event.jobId) {
      const statusString: string = event.jobStatus!;
      const status: StatusType = StatusType[statusString as keyof typeof StatusType];

      rows = (
        <>
          {event.jobFulfillerName && (
            <ViewEventRow row centerV>
              <ViewEventIconWrapper column>
                <Worker />
              </ViewEventIconWrapper>
              <AdminFormLine column>
                {event.jobFulfillerName}
              </AdminFormLine>
            </ViewEventRow>
          )}
          <ViewEventRow row centerV>
            <JobStatus
              state={getStatusState(status)}
            >{formatJobStatus(statusString)}</JobStatus>
            <AdminFormLine column>
              <StyledLink
                to={{ pathname: `/${clientId}/back-office/jobs/${event.jobId}` }}
                style={{ marginLeft: '2rem' }}
                onClick={(e: any) => e.stopPropagation() }
              >
                <b>{event.jobId}</b>
              </StyledLink>
            </AdminFormLine>
          </ViewEventRow>
        </>
      );
    } else {
      rows = (
        <>
          <ViewEventRow row centerV>
            <ViewEventIconWrapper column>
              <StyledProfile />
            </ViewEventIconWrapper>
            <AdminFormLine column>
              {`${event.creator?.firstName} ${event.creator?.lastName}`}
            </AdminFormLine>
          </ViewEventRow>
          {event.usersAndGroups?.length ? (
            <ViewEventRow row centerV>
              <ViewEventIconWrapper column>
                <StyledGroups />
              </ViewEventIconWrapper>
              <AdminFormLine column>
                {!isFulfiller(userData.user!) ? (
                  getFormattedUsersAndGroups(event.usersAndGroups, [
                    ...(state.fulfillerGroups.data || []),
                    ...(state.admins.data || []),
                    ...(state.fulfillers.data || [])
                  ])
                ) : event.usersAndGroups.length}
              </AdminFormLine>
            </ViewEventRow>
          ) : null}
        </>
      );
    }

    return rows;
  }, [
    userData.user,
    clientId,
    state.fulfillerGroups.data,
    state.admins.data,
    state.fulfillers.data
  ]);

  // TODO: find way of chaining modals. Maybe have a modal manager where these configs are passed in as an array and when one has finished being shown, it loads the next.
  const deleteEvent = useCallback((event: EventWithSpan) => {
    return setState(prevState => ({
      ...prevState,
      modal: {
        show: true,
        header: `Delete '${event.summary}'`,
        content: 'Are you sure you\'d like to delete this event?',
        buttons: [
          {
            type: 'standard',
            text: 'No'
          },
          {
            type: 'primary',
            text: 'Yes, delete',
            loading: 'state.eventDelete.loading',
            onClick: (cb: () => void) => {
              removeEvent(event._id, (e?: NetworkError) => {
                if (!e) {
                  fetchEvents(state.currentDate ? format(state.currentDate, ISO_FORMAT) : undefined);
                  cb();
                }
              });
            }
          }
        ]
      }
    }));
  }, [
    state.currentDate,
    removeEvent,
    fetchEvents
  ]);

  // TODO: abstract
  const renderModal = useCallback(() => {
    if (!state.modal.show) {
      return null;
    }

    return (
      <Popup
        id={'event-modal'}
        layered
        convertable
        noPadding
        onClose={closeModal}
      >
        {({ closePopup }) => {
          return (
            <ModalWrapper>
              <ModalItem>
                <AdminFormLine marginTop>
                  <h3 style={{marginBottom: 0}}>{state.modal.header}</h3>
                </AdminFormLine>
              </ModalItem>

              <AdminFormLine marginBottom />
              <ModalBorder />
              <AdminFormLine marginBottom />

              <ModalItem>
                <AdminFormLine marginBottom>
                  {state.modal.content}
                </AdminFormLine>
              </ModalItem>

              <AdminFormLine marginBottom />
              <ModalBorder />
              <AdminFormLine marginBottom />

              <ModalItem>
                <AdminFormLine right>
                  {state.modal.buttons.map((button: any, index: number) => {
                    switch(button.type) {
                      case 'standard':
                        return (
                          <Button
                            key={index}
                            type={'button'}
                            onClick={() => {
                              closePopup();

                              if (button.onClick) {
                                button.onClick();
                              }
                            }}
                            style={{ marginRight: '1rem' }}
                          >{button.text}</Button>
                        );
                      case 'primary':
                        return (
                          <PrimaryButton
                            key={index}
                            type={'submit'}
                            loading={
                              // eslint-disable-next-line
                              eval(button.loading)
                            }
                            spinnerColor={theme.colors.coreSecondary}
                            onClick={() => {
                              if (button.onClick) {
                                button.onClick(closePopup);
                              }
                            }}
                          >{button.text}</PrimaryButton>
                        );
                    };

                    return null;
                  })}
                </AdminFormLine>
              </ModalItem>
            </ModalWrapper>
          );
        }}
      </Popup>
    );
  }, [
    state,
    closeModal
  ]);

  const renderEventModal = useCallback(() => {
    if (state.eventView.action !== 'view-event'
      && state.eventsView.action !== 'view-events'
    ) {
      return null;
    }

    if (state.events.loading) {
      return (
        <Spinner
          color={theme.textColor}
          size={'M'}
        />
      );
    }

    if (!state.events.fields) {
      return null;
    }

    if (state.eventView.action === 'view-event' && state.eventView.event) {
      const event = state.eventView.event;

      let eventMarkup = (
        <>
          <ViewEventRow row centerV>
            <ViewEventIconWrapper column>
              <EventColour colour={event.colour} />
            </ViewEventIconWrapper>
            <AdminFormLine column>
              <h2>{event.jobId ? event.summary.split(' - ').slice(1).join(' - ') : event.summary}</h2>
            </AdminFormLine>
          </ViewEventRow>
          {event.description && (
            <ViewEventRow row centerV>
              <ViewEventIconWrapper column/>
              <AdminFormLine column>
                <p>{event.description}</p>
              </AdminFormLine>
            </ViewEventRow>
          )}
          <ViewEventRow row centerV>
            <ViewEventIconWrapper column>
              <StyledCalendar />
            </ViewEventIconWrapper>
            <AdminFormLine column>
              {getEventDuration(event)}
            </AdminFormLine>
          </ViewEventRow>
        </>
      );

      if (event.jobStatus === StatusType.CANCELLED) {
        eventMarkup = (
          <EventCancelledWrapper column>
            {eventMarkup}
          </EventCancelledWrapper>
        );
      }

      return (
        <Popup
          id={`event-popup`}
          left
          bottom
          convertable
          parentElementBoundingRect={state.eventView.targetElementBoundingRect || undefined}
          style={{ padding: '0' }}
          onClose={closeViewEventModal}
        >
          {({ closePopup }) => (
            <EventsModalWrapper>
              <ModalItem
                style={{
                  position: 'sticky',
                  top: 0,
                  background: 'white',
                  zIndex: 0
                }}
              >
                <AdminFormLine
                  right
                  marginTop
                  marginBottom
                >
                  <AdminFormLine row centerV>
                    {!event.jobId && (
                      <>
                        {canUpdateEvent(clientId!, userData.user!, event) && (
                          <IconButton hoverEffect>
                            <StyledEditIcon onClick={() => {
                              closePopup();
                              editEvent(event);
                            }}/>
                          </IconButton>
                        )}
                        {canDeleteEvent(clientId!, userData.user!, event) && (
                          <IconButton hoverEffect>
                            <StyledBinIcon onClick={() => {
                              closePopup();
                              deleteEvent(event);
                            }}/>
                          </IconButton>
                        )}
                      </>
                    )}
                    <IconButton
                      hoverEffect
                      type="button"
                      onClick={() => {
                        closePopup();
                      }}
                    >
                      <StyledCrossIcon />
                    </IconButton>
                  </AdminFormLine>
                </AdminFormLine>
              </ModalItem>
              <ModalItem applyBottomMarginToLastChild>
                {eventMarkup}
                {getOtherViewEventRows(event)}
              </ModalItem>
            </EventsModalWrapper>
          )}
        </Popup>
      );
    }

    if (state.eventsView.action === 'view-events' && state.eventsView.events) {
      const events = state.eventsView.events;
      const date = format(state.eventsView.date!, 'do MMM yyyy');

      return (
        <Popup
          id={`events-popup`}
          left
          bottom
          convertable
          parentElementBoundingRect={state.eventsView.targetElementBoundingRect || undefined}
          style={{ padding: '0' }}
          onClose={closeViewEventsModal}
        >
          {({ closePopup }) => (
            <EventsModalWrapper>
              <ModalItem
                style={{
                  position: 'sticky',
                  top: 0,
                  background: 'white',
                  zIndex: 0
                }}
              >
                <AdminFormLine
                  right
                  marginTop
                  marginBottom
                >
                  {canCreateEvent(clientId!, userData.user!) && (
                    <IconButton hoverEffect>
                      <StyledPlus onClick={() => {
                        closePopup();
                        onNew(state.eventsView.date!);
                      }}/>
                    </IconButton>
                  )}
                  <IconButton
                    hoverEffect
                    type="button"
                    onClick={() => {
                      closePopup();
                    }}
                  >
                    <StyledCrossIcon />
                  </IconButton>
                </AdminFormLine>
              </ModalItem>
              <ModalItem>
                <AdminFormLine marginBottom center>
                  <h2>{date}</h2>
                </AdminFormLine>
              </ModalItem>
              <ModalItem>
                {events
                  .filter(filterInternalEvents)
                  .map((event: EventWithSpan) => {
                    return (
                      <AdminFormLine
                        key={event._id}
                        row
                        marginBottom
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          closePopup();
                          openViewEventModal(event, state.eventsView.targetElementBoundingRect);
                        }}
                      >
                        {getEventSummaryLine(event, true)}
                      </AdminFormLine>
                    );
                  })
                }
              </ModalItem>
            </EventsModalWrapper>
          )}
        </Popup>
      );
    }
  }, [
    state,
    clientId,
    userData.user,
    getEventDuration,
    getOtherViewEventRows,
    deleteEvent,
    editEvent,
    onNew,
    openViewEventModal,
    closeViewEventModal,
    closeViewEventsModal
  ]);

  const renderCalendar = useCallback(() => {
    if (state.events.error || state.admins.error) {
      return null;
    }

    return (
      <CalendarComponent
        key={state.calendarKey}
        entries={calendarEntries}
        loading={state.events.loading}
        onViewEvent={(event, targetElementBoundingRect) => {
          openViewEventModal(event, targetElementBoundingRect);
        }}
        closeViewEvent={() => {
          closeViewEventModal();
        }}
        onSelectDay={onSelectDay}
        onMonthChange={(date: Date) => {
          setState(prevState => ({
            ...prevState,
            currentDate: date
          }));

          closeModals();
          fetchEvents(format(date, ISO_FORMAT));
        }}
      />
    );
  }, [
    state.events.loading,
    state.events.error,
    state.admins.error,
    state.calendarKey,
    calendarEntries,
    openViewEventModal,
    closeViewEventModal,
    closeModals,
    onSelectDay,
    fetchEvents
  ]);

  useEffect(() => {
    if (!state.events.data && !state.events.error) {
      fetchEvents();
    }
  }, [
    state.events.data,
    state.events.error,
    fetchEvents
  ]);

  useEffect(() => {
    if (!isFulfiller(userData.user!)) {
      if (!state.fulfillers.data && !state.fulfillers.error) {
        fetchFulfillers();
      }

      if (!state.admins.data && !state.admins.error) {
        fetchAdmins();
      }

      if (!state.fulfillerGroups.data && !state.fulfillerGroups.error) {
        fetchFulfillerGroups();
      }
    }
  }, [
    userData.user,
    state.fulfillers.data,
    state.fulfillers.error,
    state.admins.data,
    state.admins.error,
    state.fulfillerGroups.data,
    state.fulfillerGroups.error,
    fetchFulfillers,
    fetchAdmins,
    fetchFulfillerGroups
  ]);

  const showEventModal: boolean = state.eventCreateModal.show || state.eventUpdateModal.show;

  return (
    <Wrapper>
      <PageHeader
        title={'Calendar'}
        rightContent={
          <>
            <Button
              style={{marginBottom: 0}}
              disabled={!state.currentDate || isSameMonth(new Date(), state.currentDate)}
              onClick={() => {
                setState(prevState => ({
                  ...prevState,
                  currentDate: null,
                  calendarKey: new Date().getTime()
                }));

                fetchEvents();
              }}
            >Today</Button>
            {canCreateEvent(clientId!, userData.user!) && (
              <Button
                style={{marginBottom: 0}}
                icon={
                  <StyledPlus />
                }
                disabled={false}
                onClick={() => onNew()}
              >{isMobile ? '' : 'New event'}</Button>
            )}
          </>
        }
      />
      {renderCalendar()}
      {renderEventModal()}
      {renderModal()}
      <EventModal
        key={showEventModal.toString()}
        show={showEventModal}
        fields={state.events.fields}
        fulfillerGroups={state.fulfillerGroups.data}
        admins={state.admins.data}
        fulfillers={state.fulfillers.data}
        user={userData.user}
        date={state.eventCreateModal.date}
        loading={state.eventCreateModal.loading || state.eventUpdateModal.loading}
        event={state.eventUpdateModal.event}
        onClose={closeEventModal}
        onSubmit={onEventSubmit}
      />
    </Wrapper>
  );
};

export default memo(Calendar);

