import React, { FC, useCallback, useState, useMemo, useEffect } from 'react';
import {
  format,
  setYear,
  setMonth,
  setDate,
  getYear,
  getMonth,
  getDate,
  setHours,
  setMinutes,
  startOfDay
} from 'date-fns';

import {
  createDropdownOption,
  doubleSingleDigit,
  stripZoneFromISOString
} from 'utils/general';
import { DropdownOption } from 'types/UI';
import {
  FormFieldProps
} from 'components/atoms/form/FormField';
import {
  HOUR_DROPDOWN_OPTIONS,
  MINUTE_DROPDOWN_OPTIONS,
  ISO_FORMAT
} from '../../../constants';

import {
  Wrapper,
  InnerWrapper,
  FormLine,
  CalendarDay,
  StyledDatePicker,
  StyledDropdown
} from './DateTimeFields.styles';

interface Meta {
  timeSlotIndex?: number
}

interface Props {
  unavailableDates?: string[];
  value?: Date | string[];
  width?: string;
  widthM?: string;
  widthT?: string;
  widthL?: string;
  isDisabled?: boolean;
  timeDisabled?: boolean;
  slots?: {
    options?: string[];
    onFetchTimeSlots: (date: string) => void;
  };
  withMinMaxDate?: {
    minInDays?: number;
    maxInMonths?: number;
  };
  dateOnly?: boolean;
  timeOnly?: boolean;
  timeDuration?: string;
  timePlaceholder?: string;
  hasDateChanged?: boolean;
  hasTimeChanged?: boolean;
  info?: string;
  error?: string;
  dateTooltip?: FormFieldProps['tooltip'];
  timeTooltip?: FormFieldProps['tooltip'];
  loading?: boolean;
  noMarginBottom?: boolean;
  onCalendarOpen?: () => void;
  onChange: (
    datetime: Date | string[],
    meta?: Meta
  ) => void;
  onMonthChange?: (date: Date) => void;
  onDateFocus?: () => void;
  onBlur?: () => void;
}

interface State {
  date: Date;
  slotOption: DropdownOption | unknown | null;
  meta?: Meta;
}

const initialState: State = {
  date: new Date(),
  slotOption: null,
  meta: undefined
};

const DateTimeFields: FC<Props> = props => {
  const {
    unavailableDates,
    value,
    width,
    widthM,
    widthT,
    widthL,
    isDisabled,
    timeDisabled,
    slots,
    withMinMaxDate,
    dateOnly,
    timeOnly,
    timeDuration,
    timePlaceholder,
    hasDateChanged,
    hasTimeChanged,
    info,
    error,
    dateTooltip,
    timeTooltip,
    loading,
    noMarginBottom,
    onCalendarOpen,
    onChange,
    onMonthChange,
    onDateFocus,
    onBlur,
    ...rest
  } = props;

  const [state, setState] = useState<State>({ ...initialState });

  const timeOptions = useMemo(() => {
    if (slots) {
      return null;
    }

    const timeSplit: string[] | Array<null> = format(state.date, 'HH:mm').split(':');
    const hour: string | null = timeSplit[0];
    const minute: string | null = timeSplit[1];

    const hourOptions = HOUR_DROPDOWN_OPTIONS;
    const minuteOptions = MINUTE_DROPDOWN_OPTIONS;

    return {
      hour,
      minute,
      hourOptions,
      minuteOptions
    }
  }, [
    slots,
    state
  ]);

  const slotTimes = useMemo((): DropdownOption[] => {
    if (!state.date || !(slots && slots.options)) {
      return [];
    }

    return slots.options.map((option: string) => {
      const slotSplit: string[] = option.split(' - ');
      const slotStart: string = slotSplit[0];
      const slotStartDate: number = Number(slotStart.split('T')[0].split('-')[2]);
      const slotEnd: string = slotSplit[1];
      const slotEndDate: number = Number(slotEnd.split('T')[0].split('-')[2]);

      let formattedOption: string = option;

      if (slotStartDate !== slotEndDate) {
        const slotStartDateTime: string = format(stripZoneFromISOString(slotStart), 'h:mmaaa do MMM');
        const slotEndDateTime: string = format(stripZoneFromISOString(slotEnd), 'h:mmaaa do MMM');

        formattedOption = `${slotStartDateTime} - ${slotEndDateTime}`;
      } else {
        const slotStartTime: string = format(stripZoneFromISOString(slotStart), 'h:mmaaa');
        const slotEndTime: string = format(stripZoneFromISOString(slotEnd), 'h:mmaaa');

        formattedOption = `${slotStartTime} - ${slotEndTime}`;
      }

      return createDropdownOption(
        formattedOption,
        [slotStart, slotEnd]
      );
    });
  }, [
    slots,
    state.date
  ]);

  const onChangeLocal = useCallback((newState: any) => {
    onChange(
      !!slots ? newState.slotOption ? newState.slotOption.value : [format(startOfDay(newState.date), ISO_FORMAT), format(startOfDay(newState.date), ISO_FORMAT)] : newState.date,
      !!slots ? newState.meta : undefined
    );
  }, [
    slots,
    onChange
  ]);

  const onDateChange = useCallback((selectedDateTime: Date) => {
    // console.log('--DateTimeFields selectedDateTime', format(selectedDateTime, 'yyyy-MM-dd HH:mm:ss'));

    setState(prevState => {
      const newState = {
        ...prevState,
        date: selectedDateTime,
        slotOption: null
      };

      onChangeLocal(newState);

      return newState;
    });

    if (slots) {
      const now = new Date();
      let newDate;
      let newDateString;

      newDate = setYear(now, getYear(selectedDateTime));
      newDate = setMonth(newDate, getMonth(selectedDateTime));
      newDate = setDate(newDate, getDate(selectedDateTime));

      newDateString = format(newDate, ISO_FORMAT);

      // Maintain current time
      slots.onFetchTimeSlots(newDateString); 
    }
  }, [
    slots,
    onChangeLocal
  ]);

  const onTimeChange = useCallback((option: DropdownOption | unknown, type?: 'hour' | 'minute') => {
    setState((prevState: any) => {
      let date: Date = prevState.date;
      let meta: Meta | null = null;

      if (!slots && type && option) {
        switch (type) {
          case 'hour':
            date = setHours(date, Number((option as DropdownOption).value));
            break;
          case 'minute':
            date = setMinutes(date, Number((option as DropdownOption).value));
            break;
        };
      }
      else if (slots && slots.options && option) {
        meta = {
          timeSlotIndex: slots.options.findIndex(slot => slot === (option as DropdownOption).value.join(' - '))
        };
      }

      const newState = {
        ...prevState,
        date,
        ...(slots && {
          slotOption: option
        }),
        ...(meta && {
          meta
        })
      };

      onChangeLocal(newState);

      return newState;
    });
  }, [
    slots,
    onChangeLocal
  ]);

  const updateDateTime = useCallback(() => {
    setState(prevState => {
      if (value) {
        const newDate: Date = !slots ? value as Date : new Date(stripZoneFromISOString((value as string[])[0]));
        const hasNewDate: boolean = state.date.getTime() !== newDate.getTime();

        if (hasNewDate) {
          const newState = {
            ...prevState,
            ...(hasNewDate && {
              date: newDate
            })
          };

          return newState;
        }
      }

      return prevState;
    });
  }, [
    value,
    slots,
    state.date
  ]);

  const renderDateControl = useCallback(() => {
    // wait until incoming value is synched with local value (for tracking changes with highlightDateChanged)
    if (!!slots && value && new Date(stripZoneFromISOString((value as string[])[0])).getTime() !== state.date.getTime()) {
      return null;
    }

    const excludedDates = (unavailableDates || []).map((isoDate: string) => new Date(stripZoneFromISOString(isoDate)));

    return (
      <StyledDatePicker
        fullWidth
        loading={loading}
        hasChanged={hasDateChanged}
        label={"Date"}
        placeholderText="Select date"
        dateFormat={'dd/MM/yyyy'}
        error={error}
        value={state.date}
        excludeDates={excludedDates}
        withFooter={!!unavailableDates}
        isDisabled={isDisabled}
        withMinMaxDate={withMinMaxDate}
        tooltip={dateTooltip}
        onCalendarOpen={onCalendarOpen}
        onChange={onDateChange}
        onMonthChange={onMonthChange}
        onFocus={onDateFocus}
        onBlur={onBlur}
        renderDayContents={(day: number, date: Date) => {
          if (!unavailableDates) {
            return (
              <CalendarDay>{day}</CalendarDay>
            );
          }

          // date is source of truth, so extract date from there
          const datetimeISO = `${date.getFullYear()}-${doubleSingleDigit(date.getMonth() + 1)}-${doubleSingleDigit(date.getDate())}T00:00:00.000Z`;
          const isUnavailable = unavailableDates.includes(datetimeISO);

          // console.log('-----datetimeISO', datetimeISO, day, isUnavailable);

          return (
            <CalendarDay
              unavailable={isUnavailable}
            >
              {isUnavailable ? (
                <del>{day}</del>
              ) : (
                day
              )}
            </CalendarDay>
          ); 
        }}
      />
    );
  }, [
    slots,
    error,
    value,
    loading,
    hasDateChanged,
    unavailableDates,
    isDisabled,
    state.date,
    withMinMaxDate,
    dateTooltip,
    onCalendarOpen,
    onDateChange,
    onMonthChange,
    onDateFocus,
    onBlur
  ]);

  const renderTimeControl = useCallback(() => {
    if (slots) {
      let timeValue: DropdownOption | undefined;

      if (value) {
        const foundOption = slotTimes.find(st => st.value[0] === (value as string[])[0] && st.value[1] === (value as string[])[1]);

        if (foundOption) {
          timeValue = foundOption.value;
        }
      }

      // No longer needed since dropdown should always be shown
      // // wait until update
      // // if (state.time && value && !initial) {
      // if (value && !timeValue && slotTimes.length === 0) {
      //   return null;
      // }

      return (
        <StyledDropdown
          noMinWidth
          key={`${!!state.date}`}
          hasChanged={hasTimeChanged}
          width={'100%'}
          label={'Time slot'}
          value={timeValue}
          error={error ? ' ' : undefined}
          isDisabled={isDisabled || !!timeDisabled}
          options={slotTimes}
          tooltip={timeTooltip}
          onChange={(newValue: DropdownOption | unknown) => onTimeChange(newValue)}
          onBlur={onBlur}
          style={{
            ...(!timeOnly && {
              marginLeft: '1rem'
            })
          }}
        />
      );
    }

    if (!timeOptions) {
      return null;
    }

    return (
      <FormLine>
        <StyledDropdown
          timeOnly={timeOnly}
          key={`hours`}
          label={'Hour'}
          width={'8rem'}
          placeholder={(timePlaceholder && timePlaceholder.split(':')[0]) || '00'}
          value={timeOptions.hour}
          isDisabled={!!timeDisabled}
          options={timeOptions.hourOptions}
          onChange={(newValue: DropdownOption | unknown) => onTimeChange(newValue, 'hour')}
          style={{
            ...(!timeOnly && {
              marginLeft: '1rem'
            })
          }}
          onBlur={onBlur}
        />
        <StyledDropdown
          key={`minutes`}
          label={'Minute'}
          width={'8rem'}
          placeholder={(timePlaceholder && timePlaceholder.split(':')[1]) || '00'}
          value={timeOptions.minute}
          isDisabled={!!timeDisabled}
          options={timeOptions.minuteOptions}
          onChange={(newValue: DropdownOption | unknown) => onTimeChange(newValue, 'minute')}
          style={{
            marginLeft: '1rem'
          }}
          onBlur={onBlur}
        />
      </FormLine>
    );
  }, [
    value,
    error,
    state,
    isDisabled,
    timeDisabled,
    slots,
    timeOnly,
    timeOptions,
    timePlaceholder,
    slotTimes,
    hasTimeChanged,
    timeTooltip,
    onTimeChange,
    onBlur
  ]);

  useEffect(() => {
    updateDateTime();
  }, [
    updateDateTime
  ]);

  useEffect(() => {
    if (value && slots && !slots.options) {
      slots.onFetchTimeSlots(format(new Date(), ISO_FORMAT));
    }
  }, [
    value,
    slots
  ]);

  // TODO: have dynamic sizing of Slot time dropdown based on options that come back from server
  return (
    <Wrapper {...rest} >
      <InnerWrapper
        width={width}
        widthM={widthM}
        widthT={widthT}
        widthL={widthL}
        marginBottom={!noMarginBottom}
      >
        {!timeOnly && renderDateControl()}
        {!dateOnly && renderTimeControl()}
      </InnerWrapper>

      {/*info && (
        <FormLine marginBottom>
          {info}
        </FormLine>
      )*/}
    </Wrapper>
  );
};

export default DateTimeFields;

