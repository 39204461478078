import React, { FC, memo } from 'react';

import {
  Background,
  Footer
} from 'components/molecules';

import {
  Wrapper,
  StyledCard,
  FormLine,
  StyledFormWrapper,
  StyledHeader,
  HR
} from './Unverified.styles';

const Unverified: FC<any> = props => {
  return (
    <>
      <Background />
      <Wrapper>
        <StyledCard boxShadow>
          <StyledFormWrapper>
            <FormLine
              center
              marginBottom
            >
              <StyledHeader>Your account is unverified</StyledHeader>
            </FormLine>

            <FormLine marginBottom>
              <HR />
            </FormLine>
            <FormLine marginBottom />

            <FormLine center>
              <p>Please verify your account by clicking on the link we sent to your email address.</p>
            </FormLine>
          </StyledFormWrapper>
        </StyledCard>
        <FormLine
          center
          marginBottom
        >
          <Footer />
        </FormLine>
      </Wrapper>
    </>
  );
};

export default memo(Unverified);
