import React, { FC, memo, useCallback, useState, useRef } from 'react';

import { useDrag } from 'react-dnd';

import {
  TextInput,
  NumericInput,
  Dropdown,
  Text,
  PrimaryButton,
  Button,
  Tick,
  Cross,
  Checkbox
} from 'components/atoms';
import { ITableHeader, CellType, RowAction } from 'types/Header';
import {
  DragItem
} from 'types/UI';
import { getRawCurrencyValue, } from 'utils/general';
import {
  Row,
  Cell,
  ActionWrapper,
  EditActionWrapper
} from './TableRow.styles';
import { TableProps, EditRowState } from './Table';
import { Table } from './Table.styles';
import { stripTempRowProps, filterColumns } from './utils';
import { theme } from 'theme';

export interface TableRowProps extends TableProps {
  index: number;
  row: any;
  editRow: EditRowState;
  isTableFixed: boolean;
  isEditMode: boolean;
  isEditModeGlobal: boolean;
  highlight?: string;
  activeMenu?: any | null;
  onUpdateRow?: (id: string, payload: any, isNew: boolean, index?: number, header?: ITableHeader) => void;
  onRowAction?: (row: any, action: RowAction, header: ITableHeader, state: any) => void;
  onCheckRow?: (checked: boolean, row: any, index: number) => void;
  onShowMenu: (id: any | null) => void;
}

type State = Partial<any> & {};

export interface DraggableRowProps {
  children?: React.ReactNode;
  isPreview?: boolean;
  item?: any;
  loading?: TableProps['loading'];
  row?: TableRowProps['row'];
  highlight?: TableRowProps['highlight'];
  headerConfig?: TableProps['headerConfig'];
  isTableFixed?: TableRowProps['isTableFixed'];
  clickable?: boolean;
  isEditMode?: boolean;
  isEditModeGlobal?: boolean;
  onRowClick?: (e: any) => void;
}

export const DraggableRow: FC<DraggableRowProps> = (props) => {
  const {
    isPreview,
    item,
    loading,
    row,
    highlight,
    headerConfig,
    isTableFixed,
    clickable,
    isEditModeGlobal,
    onRowClick
  } = props;

  const ref = useRef<HTMLTableRowElement>(null);

  const [{ isDragging }, drag] = useDrag({
    type: DragItem.TableRowItem,
    canDrag: true,
    item: () => ({
      row,
      children: props.children, 
      headerConfig,
      width: ref.current!.getBoundingClientRect().width
    }),
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    })
  });

  drag(ref);

  let nativeProps: DraggableRowProps & { $loading?: boolean; onClick?: any; } = {
    $loading: loading,
    clickable,
    highlight,
    onClick: onRowClick
  };

  if (isPreview) {
    nativeProps = {};
  }

  let elem = (
    <Row
      ref={ref}
      style={{ opacity: isDragging ? 0.5 : 1 }}
      {...nativeProps}
    >
      {props.children}
    </Row>
  );

  // add table head to maintain column widths
  if (isPreview) {
    elem = (
      <Table
        isFixed={isTableFixed}
        style={{ background: theme.colors.neutralSecondary }}
      >
        {item.headerConfig && (
          <thead>
            <tr>
              {item.headerConfig
                .filter((h: ITableHeader) => filterColumns(h, isEditModeGlobal!))
                .map((header: any) => (
                  <th
                    key={header.key}
                    style={{
                      width: !!header.width ? header.width : 'auto',
                      padding: 0
                    }}
                  />
                ))
              }
            </tr>
          </thead>
        )}
        <tbody>
          <tr style={{ boxShadow: 'rgb(60 66 87 / 8%) 0px 3px 9px 0px' }}>
            {item.children}
          </tr>
        </tbody>
      </Table>
    );
  }

  return elem;
};

const TableRow: FC<TableRowProps> = props => {
  const {
    namespaceKey,
    loading,
    index,
    row,
    editRow,
    isTableFixed,
    isEditMode,
    isEditModeGlobal,
    highlight,
    headerConfig,
    isDraggable,
    checkable,
    inactiveRows,
    activeMenu,
    onAdd,
    onUpdateRow,
    onRowClick,
    onRowAction,
    onEditCell,
    onCheckRow,
    onShowMenu
  } = props;

  // console.log('-------editRow', editRow);

  const [state, setState] = useState<State>(row);

  const getUpdatedState = useCallback(() => {
    const stateToSend = Object.assign({}, row, state);

    Object
      .keys(state)
      .forEach((k: string) => {
        const hConfig: ITableHeader | undefined = headerConfig.find((hc => hc.key === k));

        if (hConfig && hConfig.type === CellType.Dropdown) {
          if (state[k]) {
            stateToSend[k] = state[k];
          }
        }

        if (!stateToSend[k]) {
          delete stateToSend[k];
        }
      });

    // Check required fields are filled before persisting new entity
    const allParentFieldsFilled: boolean[] = [];
    headerConfig
      .forEach((header: ITableHeader) => {
        if (header.key === RowAction.Key || header.type === CellType.ArrayMap) {
          return;
        }

        if (!header.editable || header.isOptional) {
          return allParentFieldsFilled.push(true);
        }

        switch (header.type) {
          case CellType.Text:
          case CellType.Dropdown:
            if (stateToSend[header.key] && stateToSend[header.key].length > 0) {
              return allParentFieldsFilled.push(true);
            }
            break;
          case CellType.Number:
          case CellType.Currency:
            if (stateToSend[header.key]) {
              return allParentFieldsFilled.push(true);
            }
            break;
          case CellType.Array:
            if (stateToSend[header.key].length > 0) {
              return allParentFieldsFilled.push(true);
            }
            break;
        };

        allParentFieldsFilled.push(false);
        console.log('----valid state...', stateToSend[header.key]);
      });

    console.log('----allParents...', allParentFieldsFilled);

    const rawRow = stripTempRowProps(stateToSend, namespaceKey);

    return {
      state: rawRow,
      isNew: rawRow,
      valid: !allParentFieldsFilled.includes(false)
    };
  }, [
    row,
    state,
    headerConfig,
    namespaceKey
  ]);

  const onUpdate = useCallback((e: any, header: ITableHeader, parentHeader?: ITableHeader) => {
    if (JSON.stringify(state) !== JSON.stringify(row)) {
      if (!onUpdateRow) {
        return;
      }

      const { state: stateToSend, isNew, valid } = getUpdatedState();

      if (parentHeader) {
        // console.log('=======', stateToSend, row, index, row.subIndex, parentHeader);

        onUpdateRow(row._id, stateToSend, isNew, index, parentHeader);
      } else {
        // console.log('=======add', index, valid, row, stateToSend);
        if (row.isNew && onAdd) {
          // console.log('=======in 111111', stateToSend);
          if (!valid) {
            return;
          }
          // console.log('=======in 1', stateToSend);

          onAdd!(stateToSend, index)
        }
        else if (editRow.id === row._id) {
          // console.log('=======in 2', row, stateToSend, parentHeader);
          onUpdateRow(row._id, stateToSend, row.isNew, index);
        }
      }
    }
  }, [
    row,
    state,
    index,
    editRow,
    getUpdatedState,
    onAdd,
    onUpdateRow
  ]);

  const onRowClickLocal = useCallback((e: any) => {
    // ignore top level (portal) element
    const lastBodyElem = document.body.childNodes[document.body.childNodes.length - 1];
    if (lastBodyElem && lastBodyElem.contains(e.target)) {
      return;
    }

    if (onRowClick) {
      onRowClick(row);
    }
  }, [row, onRowClick]);

  const updateInputState = useCallback((newValue: any, header: ITableHeader, parentHeader?: ITableHeader) => {
    if (parentHeader) {
      setState({
        ...state,
        [header.key]: newValue,
        [parentHeader.key]: [
          ...state[parentHeader.key].slice(0, row.subIndex),
          {
            ...state[parentHeader.key][row.subIndex],
            [header.key]: newValue
          },
          ...state[parentHeader.key].slice(row.subIndex + 1)
        ]
      });
    } else {
      setState({
        ...state,
        [header.key]: newValue
      });
    }
  }, [row.subIndex, state]);

  const getTextElem = useCallback((
    value: any,
    header: ITableHeader,
    parentHeader?: ITableHeader,
    newType?: CellType,
    isInactive?: boolean
  ) => {
    const inputProps: any = {
      type: newType || header.type,
      value
    };

    if ((isEditMode && header.editable) || row.isNew) {
      inputProps['onChange'] = (e: any) => {
        updateInputState(e.target.value, header, parentHeader);
      };

      inputProps['onClick'] = (e: any) => {
        e.stopPropagation();
      };

      inputProps['onKeyUp'] = (e: any) => {
        if (e.key === 'Enter') {
          onUpdate(e, header, parentHeader);
        }
      };

      return (
        <TextInput {...inputProps} />
      );
    } else {
      inputProps['readOnly'] = 'readonly';
    }

    return (
      <Text
        wordBreak
        {...inputProps}
        disabled={isInactive}
      />
    );
  }, [
    row.isNew,
    isEditMode,
    updateInputState,
    onUpdate
  ]);

  const getNumberElem = useCallback((
    value: any,
    header: ITableHeader,
    parentHeader?: ITableHeader,
    newType?: CellType,
    isInactive?: boolean
  ) => {
    const inputProps: any = {
      type: newType || header.type,
      value,
      ...(isEditMode && {
        min: 1,
        max: 500
      })
    };

    if ((isEditMode && header.editable) || row.isNew) {
      inputProps['onUpdate'] = (e: any, val: number) => {
        updateInputState(val, header, parentHeader);
      }

      inputProps['onClick'] = (e: any) => {
        e.stopPropagation();
      }

      inputProps['onKeyUp'] = (e: any) => {
        if (e.key === 'Enter') {
          onUpdate(e, header, parentHeader);
        }
      };

      return (
        <NumericInput {...inputProps} />
      );
    } else {
      inputProps['readOnly'] = 'readonly';
    }

    return (
      <Text
        {...inputProps}
        disabled={isInactive}
      />
    );
  }, [
    row.isNew,
    isEditMode,
    updateInputState,
    onUpdate
  ]);

  const getCurrencyElem = useCallback((
    value: any,
    header: ITableHeader,
    parentHeader?: ITableHeader,
    newType?: CellType,
    isInactive?: boolean
  ) => {
    const inputProps: any = {
      type: newType || header.type,
      value
    };

    if ((isEditMode && header.editable) || row.isNew) {
      inputProps['onChange'] = (e: any) => {
        updateInputState(
          Number(getRawCurrencyValue(e.target.value)),
          header,
          parentHeader
        );
      }

      inputProps['onClick'] = (e: any) => {
        e.stopPropagation();
      }

      inputProps['onKeyUp'] = (e: any) => {
        if (e.key === 'Enter') {
          onUpdate(e, header, parentHeader);
        }
      };

      return (
        <TextInput {...inputProps} />
      );
    } else {
      inputProps['readOnly'] = 'readonly';
    }

    return (
      <Text
        {...inputProps}
        disabled={isInactive}
      />
    );
  }, [
    row.isNew,
    isEditMode,
    updateInputState,
    onUpdate
  ]);

  const getDropdownElem = useCallback((
    value: any,
    header: ITableHeader,
    parentHeader?: ITableHeader,
    isInactive?: boolean
  ) => {
    let dropdownProps: any = {
      value
    };

    if ((isEditMode && header.editable) || row.isNew) {
      let options = typeof header.dropdownOptions === 'function' ? header.dropdownOptions() : header.dropdownOptions;

      if (header.dependant && row[header.dependant.on] && header.dependant.getDropdownOptions) {
        options = header.dependant.getDropdownOptions(row[header.dependant.on], header.dropdownOptions);
      }

      dropdownProps = {
        ...dropdownProps,
        options
      };

      dropdownProps['onChange'] = (option: any) => {
        if (parentHeader) {
          setState({
            ...state,
            [header.key]: option.value,
            [parentHeader.key]: [
              ...state[parentHeader.key].slice(0, row.subIndex),
              {
                ...state[parentHeader.key][row.subIndex],
                [header.key]: option.value
              },
              ...state[parentHeader.key].slice(row.subIndex + 1)
            ]
          });
        } else {
          setState({
            ...state,
            [header.key]: option.value
          });
        }
      };

      return (
        <Dropdown { ...dropdownProps } />
      );
    }

    return (
      <Text
        {...dropdownProps}
        disabled={isInactive}
      />
    );
  }, [
    state,
    row,
    isEditMode,
  ]);

  const getActionMenuElem = useCallback((
    value: any,
    header: ITableHeader,
    parentHeader?: ITableHeader
  ) => {
    if (isEditMode || row.isNew) {
      return (
        <EditActionWrapper>
          <Button
            style={{
              backgroundColor: 'transparent',
              borderColor: 'transparent',
              marginBottom: 0,
              padding: 0,
              width: '3rem',
              height: '3rem'
            }}
            icon={
              <Cross style={{ width: '1.25rem', height: '1.25rem' }} />
            }
            onClick={(e) => {
              e.stopPropagation();

              if (onRowAction) {
                setState({ ...row });
                onRowAction(row, RowAction.Cancel, header, getUpdatedState().state);
              }
            }}
          />
          <PrimaryButton
            style={{
              backgroundColor: 'transparent',
              borderColor: 'transparent',
              marginBottom: 0,
              padding: 0,
              width: '3rem',
              height: '3rem'
            }}
            loading={editRow.loading}
            disabled={JSON.stringify(state) === JSON.stringify(row)}
            spinnerColor={theme.colors.coreSecondary}
            icon={<Tick />}
            onClick={(e) => {
              e.stopPropagation();

              onUpdate(e, header, parentHeader);
            }}
          />
        </EditActionWrapper>
      );
    }

    return (
      <ActionWrapper onClick={(e) => {
        e.stopPropagation();

        onShowMenu(row._id);
      }}>
        {header.renderIcon!(row)}
        {header.renderMenu!(
          { row, activeMenu },
          (itemRow: any, action: RowAction) => {
            if (onRowAction) {
              onRowAction(row, action, header, getUpdatedState().state);
            }

            onShowMenu(null);
          },
          () => onShowMenu(null)
        )}
      </ActionWrapper>
    );
  }, [
    row,
    state,
    isEditMode,
    editRow,
    activeMenu,
    getUpdatedState,
    onRowAction,
    onUpdate,
    onShowMenu
  ]);

  const getIconElem = useCallback((
    value: any,
    header: ITableHeader,
    parentHeader?: ITableHeader
  ) => {
    if (isEditMode || row.isNew) {
      return (
        <PrimaryButton
          style={{ marginBottom: 0 }}
          loading={editRow.loading}
          disabled={JSON.stringify(state) === JSON.stringify(row)}
          spinnerColor={theme.colors.coreSecondary}
          icon={<Tick />}
          onClick={(e) => {
            e.stopPropagation();

            onUpdate(e, header, parentHeader);
          }}
        />
      );
    }

    return (
      <div onClick={(e) => {
        e.stopPropagation();

        if (header.onIconClick) {
          header.onIconClick(row, (r: any, action: RowAction) => {
            if (onRowAction) {
              onRowAction(r, action, header, getUpdatedState().state);
            }
          });
        }
      }}>
        {header.renderIcon!(row)}
      </div>
    );
  }, [
    row,
    state,
    editRow.loading,
    isEditMode,
    getUpdatedState,
    onRowAction,
    onUpdate
  ]);

  const getCell = useCallback((header: ITableHeader, parentHeader?: ITableHeader, subHeaderIndex?: number) => {
    if (row.isSub && !parentHeader) {
      return (
        <Cell
          key={header.key}
          borderBottom={row.isSubLast}
        />
      )
    }

    const keySegments: string[] = header.key.split('.');

    if (header.type !== CellType.ActionMenu && header.type !== CellType.Icon) {
      // if (keySegments.length === 1) {
      //   if (!row[header.key] && !header.editable) {
      //     return <Cell key={header.key}></Cell>;
      //   }
      // } else {
      //   if (!row[keySegments[0]][keySegments[1]]) {
      //     return <Cell key={header.key}></Cell>;
      //   }
      // }
    }

    // let value = header.editable ? state[header.key] : keySegments.length > 1 ? row[keySegments[0]][keySegments[1]] : row[header.key];
    let value = keySegments.length > 1 ? row[keySegments[0]] ? row[keySegments[0]][keySegments[1]] : null : state[header.key];
    let elem;
    let isActionMenuElem = false;

    const isInactive: boolean = !!(inactiveRows && inactiveRows.includes(row._id));

    if (header.format) {
      value = header.format(value, row, index, editRow);
    }

    switch (header.type) {
      case CellType.Text:
      case CellType.Password:
        elem = getTextElem(value, header, parentHeader, undefined, isInactive);

        // change type
        if (header.dependant && row[header.dependant.on] && header.dependant.onChangeType) {
          const newType: CellType = header.dependant.onChangeType(row[header.dependant.on]);

          switch (newType) {
            case CellType.Number:
              elem = getNumberElem(value, header, parentHeader, undefined, isInactive);
              break;
            case CellType.Currency:
              elem = getCurrencyElem(value, header, parentHeader, undefined, isInactive);
              break;
            case CellType.Dropdown:
              elem = getDropdownElem(value, header, parentHeader, isInactive);
              break;
            case CellType.ActionMenu:
              elem = getActionMenuElem(value, header, parentHeader);
              break;
            case CellType.Icon:
              elem = getIconElem(value, header, parentHeader);
              break;
          }
        }
        break;
      case CellType.Number:
        elem = getNumberElem(value, header, parentHeader, undefined, isInactive);

        // change type
        if (header.dependant && row[header.dependant.on] && header.dependant.onChangeType) {
          const newType: CellType = header.dependant.onChangeType(row[header.dependant.on]);

          switch (newType) {
            case CellType.Text:
            case CellType.Password:
              elem = getTextElem(value, header, parentHeader, undefined, isInactive);
              break;
            case CellType.Currency:
              elem = getCurrencyElem(value, header, parentHeader, undefined, isInactive);
              break;
            case CellType.Dropdown:
              elem = getDropdownElem(value, header, parentHeader, isInactive);
              break;
            case CellType.ActionMenu:
              elem = getActionMenuElem(value, header, parentHeader);
              break;
            case CellType.Icon:
              elem = getIconElem(value, header, parentHeader);
              break;
          }
        }
        break;
      case CellType.Currency:
        elem = getCurrencyElem(value, header, parentHeader, undefined, isInactive);

        // change type
        if (header.dependant && row[header.dependant.on] && header.dependant.onChangeType) {
          const newType: CellType = header.dependant.onChangeType(row[header.dependant.on]);

          switch (newType) {
            case CellType.Text:
            case CellType.Password:
              elem = getTextElem(value, header, parentHeader, undefined, isInactive);
              break;
            case CellType.Number:
              elem = getNumberElem(value, header, parentHeader, undefined, isInactive);
              break;
            case CellType.Dropdown:
              elem = getDropdownElem(value, header, parentHeader, isInactive);
              break;
            case CellType.ActionMenu:
              elem = getActionMenuElem(value, header, parentHeader);
              break;
            case CellType.Icon:
              elem = getIconElem(value, header, parentHeader);
              break;
          }
        }
        break;
      case CellType.Dropdown:
        elem = getDropdownElem(value, header, parentHeader, isInactive);

        // change type
        if (header.dependant && row[header.dependant.on] && header.dependant.onChangeType) {
          const newType: CellType = header.dependant.onChangeType(row[header.dependant.on]);

          switch (newType) {
            case CellType.Text:
            case CellType.Password:
              elem = getTextElem(value, header, parentHeader, undefined, isInactive);
              break;
            case CellType.Number:
              elem = getNumberElem(value, header, parentHeader, undefined, isInactive);
              break;
            case CellType.Currency:
              elem = getCurrencyElem(value, header, parentHeader, newType, isInactive);
              break;
            case CellType.ActionMenu:
              elem = getActionMenuElem(value, header, parentHeader);
              break;
            case CellType.Icon:
              elem = getIconElem(value, header, parentHeader);
              break;
          }
        }
        break;
      case CellType.ActionMenu:
        elem = getActionMenuElem(value, header, parentHeader);
        isActionMenuElem = true;

        // change type
        if (header.dependant && row[header.dependant.on] && header.dependant.onChangeType) {
          const newType: CellType = header.dependant.onChangeType(row[header.dependant.on]);

          switch (newType) {
            case CellType.Text:
            case CellType.Password:
              elem = getTextElem(value, header, parentHeader, undefined, isInactive);
              break;
            case CellType.Number:
              elem = getNumberElem(value, header, parentHeader, undefined, isInactive);
              break;
            case CellType.Currency:
              elem = getCurrencyElem(value, header, parentHeader, newType, isInactive);
              break;
            case CellType.Dropdown:
              elem = getDropdownElem(value, header, parentHeader);
              break;
            case CellType.Icon:
              elem = getIconElem(value, header, parentHeader);
              break;
          }
        }
        break;
      case CellType.Icon:
        elem = getIconElem(value, header, parentHeader);

        // change type
        if (header.dependant && row[header.dependant.on] && header.dependant.onChangeType) {
          const newType: CellType = header.dependant.onChangeType(row[header.dependant.on]);

          switch (newType) {
            case CellType.Text:
            case CellType.Password:
              elem = getTextElem(value, header, parentHeader, undefined, isInactive);
              break;
            case CellType.Number:
              elem = getNumberElem(value, header, parentHeader, undefined, isInactive);
              break;
            case CellType.Currency:
              elem = getCurrencyElem(value, header, parentHeader, newType, isInactive);
              break;
            case CellType.Dropdown:
              elem = getDropdownElem(value, header, parentHeader, isInactive);
              break;
            case CellType.ActionMenu:
              elem = getActionMenuElem(value, header, parentHeader);
              break;
          }
        }
        break;
      case CellType.Action:
        elem = header.renderIcon ? header.renderIcon(row) : null;
        break;
    }

    if (header.customWrapper) {
      return (
        <Cell
          key={header.key}
          borderLeft={parentHeader && subHeaderIndex === 0}
          borderBottom={row.isSubLast}
          isActionMenu={isActionMenuElem}
          data-label={header.label}
          isInactive={isInactive}
        >
          {header.customWrapper(
            elem
          )}
        </Cell>
      );
    }

    const editedCellContent = onEditCell && onEditCell(row, header, isInactive);

    if (editedCellContent) {
      return (
        <Cell
          key={header.key}
          borderLeft={parentHeader && subHeaderIndex === 0}
          borderBottom={row.isSubLast}
          isActionMenu={isActionMenuElem}
          data-label={header.label}
          isInactive={isInactive}
        >
          {editedCellContent}
        </Cell>
      );
    }

    return (
      <Cell
        key={header.key}
        borderLeft={parentHeader && subHeaderIndex === 0}
        borderBottom={row.isSubLast}
        isActionMenu={isActionMenuElem}
        data-label={header.label}
        isInactive={isInactive}
      >
        {elem}
      </Cell>
    );
  }, [
    row,
    index,
    state,
    editRow,
    inactiveRows,
    getTextElem,
    getNumberElem,
    getCurrencyElem,
    getDropdownElem,
    getActionMenuElem,
    getIconElem,
    onEditCell
  ]);

  const render = useCallback(() => {
    const cellList: any[] = [];
    const headerReferenceList: any[] = [];

    headerConfig
      .filter((header: ITableHeader) => filterColumns(header, isEditModeGlobal!))
      .forEach((header: ITableHeader) => {
        if (header.type === CellType.ArrayMap) {
          header.subColumns!.forEach((subHeader: ITableHeader, subHeaderIndex: number) => {
            cellList.push(getCell(subHeader, header, subHeaderIndex));
            headerReferenceList.push(subHeader);
          });
        } else {
          cellList.push(getCell(header));
          headerReferenceList.push(header);
        }
      });

    const mergeCellIndex: number = headerReferenceList.findIndex(hr => hr.mergeWithParent);

    if (mergeCellIndex !== -1) {
      const mergeParentIndex: number = headerReferenceList.findIndex((hr: ITableHeader, i: number) => hr.key === headerReferenceList[mergeCellIndex].key && i !== mergeCellIndex);

      if (row.isParent) {
        cellList.splice(mergeCellIndex, 1);
      } else {
        cellList.splice(mergeParentIndex, 1);
      }
    }

    if (checkable && onCheckRow) {
      cellList.unshift(getCell({
        key: RowAction.Key,
        type: CellType.Action,
        isInternal: true,
        renderIcon: () => (
          <Checkbox
            checked={!!row.checked}
            onChange={(e) => {
              onCheckRow(e.target.checked, row, index)
            }}
          />
        )
      }));
    }

    const returnValue = (
      isDraggable ? (
        <DraggableRow
          loading={!!loading}
          clickable={!!onRowClick}
          highlight={highlight}
          row={row}
          headerConfig={headerConfig}
          isTableFixed={isTableFixed}
          isEditModeGlobal={isEditModeGlobal}
          onRowClick={onRowClickLocal}
        >
          {cellList}
        </DraggableRow>
      ) : (
        <Row
          $loading={loading}
          clickable={!!onRowClick}
          highlight={highlight}
          onClick={onRowClickLocal}
        >
          {cellList}
        </Row>
      )
    );

    return returnValue;
  }, [
    loading,
    row,
    index,
    headerConfig,
    isTableFixed,
    highlight,
    isDraggable,
    checkable,
    isEditModeGlobal,
    getCell,
    onRowClick,
    onRowClickLocal,
    onCheckRow
  ]);

  return (
    <>
      {render()}
    </>
  );
};

export default memo(TableRow);

