import React, { useState } from 'react';

import { isNumeric } from 'utils/general';
import { FormField } from '../form';
import BaseInput, { StyledBaseInputProps } from './BaseInput';
import { BaseProps } from '../form/types';
import { FormFieldProps } from '../form/FormField';
import { FieldType } from 'types/Service';
import { MAX_PRICE } from '../../../constants';

export interface TextInputProps extends Omit<StyledBaseInputProps, 'errored'> {
  format?: (value: any) => any;
}

export type Props = TextInputProps & BaseProps & FormFieldProps;

const TextInput = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    type,
    className,
    label,
    warning,
    error,
    hasChanged,
    width,
    widthM,
    widthML,
    widthT,
    widthL,
    noWidth,
    noMinWidth,
    onFocus,
    onBlur,
    ...rest
  } = props;

  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [innerError, setInnerError] = useState<string | undefined>(error);

  const maxLengthCheck = (e: any) => {
    const val: string = e.target.value.toString();
    const min: string = e.target.min.toString();
    const max: string = e.target.max.toString();

    // Min check
    if (!!min && Number(val) < Number(min)) {
      setInnerError(`Minimum number is ${min}`);
    } else {
      setInnerError(undefined);
    }

    // Only run below if max is present. Otherwise input becomes unusable
    if (!max) {
      return;
    }

    // Max check
    if (Number(val.split('.')[0]) > Number(max)) {
      e.target.value = val.slice(0, max.length - 1)
    }

    // Char length check
    const str = val.split('.')[0]
      .replaceAll('.', '')
      .replaceAll(',', '');
    if (str.length > max.length) {
      e.target.value = val.slice(0, max.length)
    }

    // Cull preceeding 0s
    if (val.split('.')[0].length > 1 && val.charAt(0) === '0') {
      e.target.value = Number(val);
    }
  };

  const additionalInputProps = {
    ...(type === FieldType.Currency && {
      max: MAX_PRICE
    })
  };

  return (
    <FormField
      id={rest.id}
      warning={warning}
      error={error || innerError}
      label={label}
      className={className}
      hasChanged={hasChanged}
      value={rest.value}
      width={width}
      widthM={widthM}
      widthML={widthML}
      widthT={widthT}
      widthL={widthL}
      noWidth={noWidth}
      noMinWidth={noMinWidth}
      isFocused={isFocused}
      isFull={String(rest.value as string).length > 0}
      info={rest.info}
      tooltip={rest.tooltip}
      builder={rest.builder}
      startAdornment={rest.startAdornment}
      endAdornment={rest.endAdornment}
      formFieldStyles={rest.formFieldStyles}
    >
      <BaseInput
        {...rest}
        ref={ref}
        type={type || 'text'}
        errored={!!error}
        disabled={rest.disabled}
        autoComplete={rest.disableAutoComplete ? '!off' : undefined}
        { ...additionalInputProps }
        onInput={e => {
          if (rest.onInput) {
            rest.onInput(e);
          }

          maxLengthCheck(e);
        }}
        onKeyPress={(e) => {
          if (type === FieldType.Currency) {
            return isNumeric(e);
          }

          if (rest.onKeyPress) {
            rest.onKeyPress(e);
          }
        }}
        onFocus={e => {
          if (onFocus) {
            onFocus(e);
          }

          setIsFocused(true);
        }}
        onBlur={e => {
          if (onBlur) {
            onBlur(e);
          }

          setIsFocused(false);
        }}
      />
    </FormField>
  );
});

export default TextInput;
