import React, { FC, useCallback, useState, useEffect, memo, useRef, useMemo, createRef } from 'react';
import { useLocation, useNavigate, useParams } from "react-router";
import { createPortal } from 'react-dom';

import { Service, Step, Field, FieldType } from 'types/Service';
import {
  Pricing,
  Condition as PricingCondition,
  ConditionType
} from 'types/Pricing';
import { BrowserWindow } from 'types/UI';
import { gatewayService } from 'services';
import { theme } from 'theme';
import {
  IconButton,
  NoDataCaption,
  StyledBinIcon
} from 'theme/mixins';
import { sizes } from 'theme/media';
import { getQueryParam } from 'utils/url';
import {
  Actions,
  FrameActions
} from 'state';
import {
  formatCurrency,
  getRawCurrencyValue,
  isFieldCalculable,
  getNetworkErrors,
  isMinWidth,
  isListEmpty
} from 'utils/general';
import {
  Button,
  Spinner,
  FormSection,
  PrimaryButton
} from 'components/atoms';
import { PageHeader } from '../../components';
import { useMediaSizes, UseMediaSizesState } from '../../hooks';
import { useDebouncedCallback } from 'components/hooks';
import {
  DEFAULT_PRICING_RULE_NAME_PREFIX,
  // BASE_PRICE_NAME
} from '../../../../../constants';
import { AdminPageProps } from 'components/AppRouter';
import {
  canCreatePricing,
  canUpdatePricing,
  canDeletePricing
} from 'config/privileges';
import {
  NetworkError
} from 'types/Error';

import {
  Wrapper,
  Card,
  AdminFormLine,
  StatusIndicator,
  ContentWrapper,
  StyledPayments,
  StyledPlus,
  PreviewPane,
  Device,
  StyledFrame,
  CreateServiceFormWrapper,
  FormFieldControlWrapper,
  StyledHR,
  HR,
  ClearButton,
  Prices,
  PriceOuter,
  PriceInput,
  Price,
  RuleName,
  Conditions,
  Condition,
  ConditionButton,
  BinButton,
  ConditionBinButton,
  StyledEditButton,
  StyledEditIcon,
  NameEditButton,
  NameEditIcon,
  NameCrossButton,
  NameCrossIcon,
  NameTickButton,
  NameTickIcon,
  NameWrapper,
  NameInput,
  ConditionInner,
  PriceRule,
  CompareField,
  CompareValue,
  StyledSwitch,
  FrameSpinner,
  FieldRuleIcon,
  MobileSettingsWrapper,
  Grid,
  IFrameSectionWrapper,
  MobileSettingIconWrapper,
  StyledMobileSettingsIcon,
  StyledMobileChevronIcon,
  StyledMobileTickIcon,
  StyledCross,
  StyledAircraft,
  StyledChevron,
  RuleHighlight
} from './PricingRules.styles';

interface State {
  pricing: {
    loading: boolean;
    data: Pricing[] | null;
    total: number | null;
    fields: any;
    offset: number;
    error: NetworkError | null;
    expanded: {
      [id: string]: boolean;
    };
  };
  services: {
    loading: boolean;
    data: Service[] | null;
    total: number | null;
    fields: any;
    offset: number;
    error: NetworkError | null;
  };
  service: {
    loading: boolean;
    data: Service | null;
    fields: any;
    error: NetworkError | null;
  };
  pricingCreate: {
    loading: boolean;
    data: Pricing | null;
    error: NetworkError | null;
  };
  pricingUpdate: {
    loading: boolean;
    error: NetworkError | null;
  };
  form: any;
  errors: {
    [key: string]: any;
  };
  builder: {
    frameLoaded: boolean;
    selectedStepIndex: number;
    selectedFields: Field[];
    selectedPrice: Pricing & {
      editMode: boolean;
    } | null;
    comparator: string | null;
    selectedCondition: {
      data: PricingCondition | ConditionType | null;
      parentId: string;
      all?: boolean;
    };
    testMode: boolean;
    highlightRows: any;
    priceResults: any;
  },
  showMobileSettings: boolean;
  minimizeMobileSettings: boolean;
}

const initialState: State = {
  pricing: {
    loading: false,
    total: null,
    data: null,
    fields: {},
    offset: 0,
    error: null,
    expanded: {}
  },
  services: {
    loading: false,
    total: null,
    data: null,
    fields: {},
    offset: 0,
    error: null
  },
  service: {
    loading: false,
    data: null,
    fields: {},
    error: null
  },
  pricingCreate: {
    loading: false,
    data: null,
    error: null
  },
  pricingUpdate: {
    loading: false,
    error: null
  },
  form: {},
  errors: {},
  builder: {
    frameLoaded: false,
    selectedStepIndex: -1,
    selectedFields: [],
    selectedPrice: null,
    comparator: null,
    selectedCondition: {
      data: null,
      parentId: ''
    },
    testMode: false,
    highlightRows: {},
    priceResults: {}
  },
  showMobileSettings: false,
  minimizeMobileSettings: false
};

const iFrameHeight = 640;

const dropdownComparators: string[] = [
  'is equal to',
  'is not equal to'
];

const numberComparators: string[] = [
  'is less than',
  'is less than or equal to',
  'is greater than',
  'is greater than or equal to',
  'is equal to',
  'is not equal to'
];

const priceLengthDimensionMap: Record<number, number> = {
  1: 8,
  2: 8,
  3: 8,
  4: 8,
  5: 9,
  6: 10,
  7: 11,
  8: 12
};

const priceLengthConditionLeftPaddingMap: Record<number, number> = {
  4: 11,
  5: 12,
  6: 13,
  7: 14,
  8: 15
};

const isMultipleCondition = (
  condition: PricingCondition | ConditionType | null,
  pricing: Pricing
): boolean => condition === ConditionType.FieldMultiple && !!pricing.appliesTo;

const isConditionSelectedAtPriceLevel = (
  builderState: State['builder'],
  pricing: Pricing
): boolean => {
  if (builderState.selectedCondition.parentId === pricing._id && (!!builderState.selectedCondition.data || !!builderState.selectedCondition.all)) {
    return true;
  }

  return false;
};

const isConditionSelected = (
  builderState: State['builder'],
  pricing: Pricing,
  condition: PricingCondition | ConditionType
): boolean => {
  if (builderState.selectedCondition.parentId === pricing._id) {
    if (!!builderState.selectedCondition.all) {
      return true;
    }

    if (isMultipleCondition(builderState.selectedCondition.data, pricing) && isMultipleCondition(condition, pricing)) {
      return true;
    }

    if ((builderState.selectedCondition.data as PricingCondition | null)?.compareField === (condition as PricingCondition).compareField) {
      return true;
    }
  }

  return false;
};

const findField = (
  steps: Step[],
  condition: PricingCondition | ConditionType | null,
  pricing: Pricing
): [Field | null, number] => {
  // Look for field
  for (let s = 0; s < steps.length; s++) {
    for (const f of steps[s].fields) {
      if (!isMultipleCondition(condition, pricing) && f.name === (condition as PricingCondition | null)?.compareField) {

        return [{ ...f }, s];
      }
    }
  }

  // If not found, look for appliesTo field
  for (let s = 0; s < steps.length; s++) {
    for (const f of steps[s].fields) {
      if (f.name === pricing.appliesTo) {

        return [{ ...f }, s];
      }
    }
  }

  return [null, -1];
};

const findFieldByPricingAppliesTo = (steps: Step[], pricing: Pricing): Field | null => {
  let field: Field | null = null;

  for (const step of steps) {
    for (const f of step.fields) {
      if (f.type === FieldType.Number && f.name === pricing.appliesTo) {
        field = { ...f };

        break;
      }
    }
  }

  return field;
};

const getStandardConditions = (conditions: Array<PricingCondition | ConditionType>): PricingCondition[] => {
  return (conditions.filter((condition: PricingCondition | ConditionType) => {
    return condition !== ConditionType.FieldMultiple;
  }) as PricingCondition[]);
};

// update conditions array with CondtionType.FieldMultiple
const inflatePricingRule = (pricing: Pricing): Pricing => {
  if (!pricing.appliesTo) {
    return pricing;
  }

  const conditions = [
    ...pricing.conditions
  ];

  if (conditions[0] !== ConditionType.FieldMultiple) {
    conditions.unshift(ConditionType.FieldMultiple);
  }

  return {
    ...pricing,
    conditions
  }
};

const PricingRules: FC<AdminPageProps> = props => {
  const {
    userData,
    addToast
  } = props;

  const [state, setState] = useState<State>(initialState);
  const pricingOpts = useRef<any>();
  const location = useLocation();
  const navigate = useNavigate();
  const { clientId } = useParams();
  const serviceId: string | undefined = getQueryParam('service', location.search);
  const ruleElemRefs = useRef<Array<React.RefObject<HTMLDivElement>>>([]);
  const conditionElemRefs = useRef<Array<React.RefObject<HTMLDivElement>>>([]);
  const [prevRuleCount, setPrevRuleCount] = useState<number>(0);
  const [conditionHeights, setConditionHeights] = useState<number[]>([]);
  const [ruleAdded, setRuleAdded] = useState<boolean>(false);

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const newRuleName = useMemo(() => {
    return `${DEFAULT_PRICING_RULE_NAME_PREFIX} ${(state.pricing.total || 0) + 1}`
  }, [state.pricing.total]);

  const isTestMode = useMemo(() => {
    return state.builder.testMode;
  }, [state.builder.testMode]);

  const isPriceSet = useMemo(() => {
    if (!state.pricing.data) {
      return true;
    }

    return state.pricing.data.every(pricing => pricing.unitPrice > 0);
  }, [state.pricing.data]);

  const inflatedPricingRules = useMemo(() => {
    if (!state.pricing.data) {
      return [];
    }

    return state.pricing.data.map(inflatePricingRule);
  }, [state.pricing.data]);

  const onMediaResize = useCallback(({ isMobile: isM }: UseMediaSizesState): void => {
    const frameData = {
      action: FrameActions.HIDE_BACKGROUND_AND_FOOTER,
      to: 'child',
      payload: {
        service: {
          builder: {
            hideBackground: isM,
            hideFooter: isM
          }
        }
      }
    };

    throttleIframeMessage(frameData);

    // hack as memoization not working when accessing state
    setState((prevState) => {
      processConditionHeights(prevState, true);

      return prevState;
    });
  // eslint-disable-next-line
  }, []);

  const { isMobile } = useMediaSizes({ onMediaResize });

  const selectPrice = useCallback((pricing: Pricing) => {
    setState(prevState => {
      if (prevState.builder.testMode) {
        return prevState;
      }

      return {
        ...prevState,
        builder: {
          ...prevState.builder,
          selectedPrice: {
            ...pricing,
            editMode: false
          },
          comparator: null,
          selectedCondition: {
            data: null,
            all: true,
            parentId: pricing._id
          },
          selectedFields: pricing.conditions.map((condition: PricingCondition | ConditionType) => {
            const [field] = findField(prevState.service.data!.steps, condition, pricing);

            return {
              ...field!,
              ...(!isMultipleCondition(condition, pricing) && {
                value: (condition as PricingCondition).compareValue
              })
            };
          })
        }
      };
    });
  }, []);

  const fetchPricing = useCallback((opts?: any) => {
    if (state.pricing.loading) {
      return;
    }

    pricingOpts.current = Object.assign({}, pricingOpts.current, opts);

    setState(prevState => ({
      ...prevState,
      pricing: {
        ...prevState.pricing,
        loading: true
      }
    }));

    gatewayService.getPricing(clientId!, serviceId!, pricingOpts.current)
      .then((pricing: any) => {
        setState(prevState => ({
          ...prevState,
          pricing: {
            ...prevState.pricing,
            loading: false,
            total: pricing.total,
            data: [
              ...JSON.parse(JSON.stringify(pricing.data))
            ],
            fields: pricing.fields,
            offset: pricing.offset,
            error: null
          }
        }));
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          pricing: {
            ...prevState.pricing,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    clientId,
    serviceId,
    state.pricing,
    addToast
  ]);

  const fetchService = useCallback((callback?: (e?: any) => void) => {
    if (state.service.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      service: {
        ...prevState.service,
        loading: true
      }
    }));

    gatewayService.getService(clientId!, serviceId!)
      .then((serviceResponse: any) => {
        setState(prevState => ({
          ...prevState,
          service: {
            loading: false,
            fields: serviceResponse.fields,
            data: serviceResponse.data,
            error: null
          }
        }));

        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          service: {
            ...prevState.service,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.service,
    serviceId,
    clientId,
    addToast
  ]);

  const fetchServices = useCallback(() => {
    if (state.services.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      services: {
        ...prevState.services,
        loading: true
      }
    }));

    gatewayService.getServices(clientId!)
      .then((services: any) => {
        setState(prevState => ({
          ...prevState,
          services: {
            loading: false,
            total: services.total,
            data: [
              ...JSON.parse(JSON.stringify(services.data))
            ],
            fields: services.fields,
            offset: services.offset,
            error: null
          }
        }));
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          services: {
            ...prevState.services,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.services,
    clientId,
    addToast
  ]);

  const onCreatePricing = useCallback((payload: Partial<Pricing>, cb: () => void) => {
    setState(prevState => ({
      ...prevState,
      pricingCreate: {
        ...prevState.pricingCreate,
        loading: true
      }
    }));

    const newPayload = {
      ...payload,
      serviceId
    };

    gatewayService
      .createPricing(clientId!, newPayload)
      .then((createPricingResponse: any) => {
        setState(prevState => ({
          ...prevState,
          pricingCreate: {
            ...initialState.pricingCreate
          }
        }));

        addToast({
          type: 'success',
          content: 'Pricing rule created'
        });

        cb();
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          pricingCreate: {
            ...prevState.pricingCreate,
            loading: false,
            error
          }
        }));

        cb();

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    serviceId,
    clientId,
    addToast
  ]);

  const onUpdatePricing = useCallback((
    id: string,
    incomingPricing: Partial<Pricing>,
    cb?: (pricing: Pricing) => void,
    index?: number
  ) => {
    setState(prevState => ({
      ...prevState,
      pricingUpdate: {
        ...prevState.pricingUpdate,
        loading: true
      }
    }));

    const newPayload = { ...incomingPricing };

    if (index !== undefined && index >= 0) {
      newPayload.conditions = [
        ...incomingPricing.conditions!.slice(0, index),
        ...incomingPricing.conditions!.slice(index! + 1, incomingPricing.conditions!.length)
      ];
    }

    if (newPayload.conditions) {
      newPayload.conditions = getStandardConditions(newPayload.conditions);
    }

    const {
      _id,
      clientId: payloadClientId,
      created,
      updated,
      serviceId: payloadServiceId,
      ...rest
    } = newPayload;

    gatewayService
      .updatePricing(clientId!, id, rest)
      .then((pricingUpdateResponse: any) => {
        setState(prevState => {
          const priceCurrentlySelected: boolean = prevState.builder.selectedPrice?._id === id;

          return {
            ...prevState,
            pricingUpdate: {
              ...initialState.pricingUpdate
            },
            ...(priceCurrentlySelected && {
              builder: {
                ...prevState.builder,
                selectedPrice: {
                  ...prevState.builder.selectedPrice,
                  ...pricingUpdateResponse.data
                },
                selectedFields: pricingUpdateResponse.data.conditions.map((condition: PricingCondition | ConditionType) => {
                  const [field] = findField(prevState.service.data!.steps, condition, pricingUpdateResponse.data);

                  return {
                    ...field!,
                    ...(!isMultipleCondition(condition, pricingUpdateResponse.data) && {
                      value: (condition as PricingCondition).compareValue
                    })
                  };
                })
              }
            })
          };
        });

        if (cb) {
          cb(pricingUpdateResponse.data);
        }

        addToast({
          type: 'success',
          content: 'Pricing rule updated',
          tag: 4
        });
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          pricingUpdate: {
            ...prevState.pricingUpdate,
            loading: false,
            error 
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    clientId,
    addToast
  ]);

  const deletePricing = useCallback((id: string) => {
    setState(prevState => ({
      ...prevState,
      pricing: {
        ...prevState.pricing,
        loading: true
      }
    }));

    gatewayService
      .deletePricing(clientId!, id)
      .then((pricing: Pricing) => {
        setState(prevState => ({
          ...prevState,
          pricing: {
            ...prevState.pricing,
            loading: false
          }
        }));

        addToast({
          type: 'success',
          content: 'Pricing rule deleted'
        });

        fetchPricing();
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          pricing: {
            ...prevState.pricing,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    clientId,
    fetchPricing,
    addToast
  ]);

  const onBack = useCallback(() => {
    setState({ ...initialState });

    navigate({
      pathname: `/${clientId}/back-office/pricing-rules`
    });
  }, [
    clientId,
    navigate
  ]);

  const onEditService = useCallback(() => {
    navigate({
      pathname: `/${clientId}/back-office/services/${state.service.data!._id}/edit`
    });
  }, [
    state.service.data,
    clientId,
    navigate
  ]);

  const onServiceClick = useCallback((e:any, id: string) => {
    setState(prevState => ({
      ...prevState,
      service: {
        ...initialState.service
      },
      pricing: {
        ...initialState.pricing
      }
    }));

    navigate({
      pathname: `/${clientId}/back-office/pricing-rules`,
      search: `?service=${id}`
    });
  }, [
    clientId,
    navigate
  ]);

  const createPricingRule = useCallback(() => {
    onCreatePricing({
      // name: basePriceExists ? newRuleName : BASE_PRICE_NAME,
      name: newRuleName,
      unitPrice: state.form.basePrice,
      serviceId
    }, () => {
      fetchPricing();
    });
  }, [
    newRuleName,
    state.form.basePrice,
    serviceId,
    onCreatePricing,
    fetchPricing
  ]);

  const selectCondition = useCallback((
    condition: PricingCondition | ConditionType,
    pricing: Pricing
  ) => {
    setState(prevState => {
      if (prevState.builder.testMode) {
        return prevState;
      }

      const isMCondition: boolean = isMultipleCondition(condition, pricing);
      const [field, stepIndex] = findField(
        prevState.service.data!.steps,
        condition,
        pricing
      );

      return {
        ...prevState,
        builder: {
          ...prevState.builder,
          selectedPrice: {
            ...pricing,
            editMode: false
          },
          selectedCondition: {
            data: condition,
            parentId: pricing._id,
            all: false
          },
          ...(field && {
            selectedFields: [{
              ...field,
              ...(!isMCondition && {
                value: (condition as PricingCondition).compareValue
              })
            }],
            selectedStepIndex: stepIndex
          }),
          ...(!isMCondition && {
            comparator: (condition as PricingCondition).comparator
          })
        }
      };
    });

  }, []);

  // const clearSelectedCondition = useCallback(() => {
  //   setState(prevState => ({
  //     ...prevState,
  //     builder: {
  //       ...prevState.builder,
  //       comparator: null,
  //       ...(prevState.builder.selectedPrice && !!prevState.builder.selectedCondition.all && {
  //         selectedCondition: {
  //           ...prevState.builder.selectedCondition,
  //           data: null
  //         },
  //       }),
  //       ...(prevState.builder.selectedPrice && prevState.builder.selectedCondition.data && {
  //         selectedPrice: null,
  //         selectedCondition: {
  //           ...prevState.builder.selectedCondition,
  //           data: null
  //         },
  //       })
  //     }
  //   }));
  // }, []);

  const selectStep = useCallback((index: number) => {
    setState(prevState => ({
      ...prevState,
      builder: {
        ...prevState.builder,
        selectedStepIndex: index
      }
    }));
  }, []);

  const selectField = useCallback((field: Field) => {
    setState(prevState => {
      if (prevState.builder.testMode) {
        return prevState;
      }

      const selectedFieldState = {
        ...prevState,
        builder: {
          ...prevState.builder,
          selectedFields: [{
            ...field 
          }]
        }
      };

      if (field.label && field.value) {
        if (!(isFieldCalculable(field) && prevState.builder.selectedPrice && prevState.pricing.data)) {
          return selectedFieldState;
        }

        const pricing: Pricing | undefined = prevState.pricing.data.find(p => p._id === prevState.builder.selectedPrice!._id);
        const comparator: string | null = prevState.builder.comparator;

        if (!pricing) {
          return selectedFieldState;
        }

        const standardConditions: PricingCondition[] = getStandardConditions(pricing.conditions);

        // exact condition match
        if (standardConditions.find((condition: PricingCondition) => {
            return condition.compareField === field.name && condition.compareValue === field.value;
          })
        ) {
          // console.log('------exact con');
          return selectedFieldState;
        }

        // update condition
        const foundCondition: PricingCondition | undefined = standardConditions.find((condition: PricingCondition) => condition.compareField === field.name);

        if (foundCondition && (foundCondition.compareValue !== field.value || foundCondition.comparator !== comparator)) {
          // console.log('------update con');
          const index: number = standardConditions.findIndex((condition: PricingCondition) => condition.compareField === field.name);
          const length: number = pricing.conditions.length;

          if (index === -1) {
            return selectedFieldState;
          }

          const updatedCondition: PricingCondition = {
            compareField: field.name,
            comparator: foundCondition.comparator,
            compareValue: field.value
          };

          onUpdatePricing(
            pricing._id,
            {
              ...pricing,
              conditions: [
                ...pricing.conditions.slice(0, index),
                { ...updatedCondition },
                ...pricing.conditions.slice(index + 1, length),
              ]
            },
            (updatedPricing?: Pricing) => {
              fetchPricing();
              fetchService();
              selectCondition(updatedCondition, updatedPricing!);
            }
          );
        }

        // add condition
        else if (!prevState.builder.selectedCondition.data || !foundCondition) {
          const newCondition: PricingCondition = {
            compareField: field.name,
            comparator: dropdownComparators[0],
            compareValue: field.value
          };

          onUpdatePricing(
            pricing._id,
            {
              ...pricing,
              conditions: [
                ...pricing.conditions,
                { ...newCondition }
              ]
            },
            (updatedPricing?: Pricing) => {
              fetchPricing();
              fetchService();
              selectCondition(newCondition, updatedPricing!);
            }
          );
        }
      }

      return selectedFieldState;
    });

    // console.log('-------------selectField', field);

  }, [
    onUpdatePricing,
    fetchPricing,
    fetchService,
    selectCondition
  ]);

  const showPriceResults = useCallback((priceResults: any) => {
    setState(prevState => {
      if (!prevState.builder.testMode || !priceResults.rules) {
        return prevState;
      }

      return {
        ...prevState,
        builder: {
          ...prevState.builder,
          highlightRows: priceResults.rules.reduce((acc: any, curr: any, idx: number) => {
            if (!acc[curr.id]) {
              acc[curr.id] = !!curr.highlight;

              // Scroll to the highest price
              if (idx === 0) {
                const ruleIndex: number = prevState.pricing.data!.findIndex((rule: Pricing) => rule._id === curr.id);

                if (ruleIndex >= 0) {
                  scrollToRule(ruleIndex);
                }
              }
            }

            return acc;
          }, {}),
          priceResults
        }
      };
    });
  }, []);

  const toggleIsMultiple = useCallback((field?: Field) => {
    setState(prevState => {
      if (prevState.builder.testMode) {
        return prevState;
      }

      if (!prevState.builder.selectedPrice) {
        return prevState;
      }

      const { editMode, ...pricing } = prevState.builder.selectedPrice;

      const isEnabled: boolean = !!pricing.appliesTo;

      onUpdatePricing(
        prevState.builder.selectedPrice._id,
        {
          ...pricing,
          appliesTo: isEnabled ? null : field?.name
        },
        (updatedPricing) => {
          fetchService();
          fetchPricing();
          selectPrice(inflatePricingRule(updatedPricing));
        } 
      );

      return prevState;
    });
  }, [
    onUpdatePricing,
    fetchPricing,
    fetchService,
    selectPrice
  ]);

  const onIframeMessage = useCallback((event: any) => {
    if (event.origin === window.location.origin) {
      let data = null;

      try {
        data = JSON.parse(event.data);
      } catch (e) {};

      if (!data) {
        return;
      }

      if (data.to === 'parent') {
        // console.log('--------Parent', data);

        switch (data.action) {
          case FrameActions.SELECT_STEP: {
            const stepIndex = data.payload.stepIndex;

            selectStep(stepIndex);
            break;
          }
          case FrameActions.SELECT_FIELD: {
            const fieldItem = data.payload.field;

            selectField(fieldItem);
            break;
          }
          case FrameActions.PRICE_RESULTS: {
            const priceResults = data.payload;   

            showPriceResults(priceResults);
            break;
          }
          case FrameActions.ENABLE_FIELD_PRICING: {
            const fieldItem = data.payload.field;

            toggleIsMultiple(fieldItem);
            break;
          }
        }
      }
    }
  }, [
    selectStep,
    selectField,
    showPriceResults,
    toggleIsMultiple
  ]);

  const addMessageListener = useCallback(() => {
    if (iframeRef.current) {
      iframeRef.current.contentWindow!.addEventListener('message', onIframeMessage);
    }
  }, [onIframeMessage]);

  const removeMessageListener = useCallback(() => {
    if (iframeRef.current) {
      iframeRef.current!.contentWindow!.removeEventListener('message', onIframeMessage);
    }
  }, [onIframeMessage]);

  const [throttleIframeMessage] = useDebouncedCallback((frameData: any) => {
    if (iframeRef.current) {
      iframeRef.current!.contentWindow!.postMessage(JSON.stringify(frameData), window.location.origin);
    }
  }, 50);

  const processConditionHeights = useCallback((currentState: State, isFromObserver?: boolean) => {
    let xInflatedPricingRules = inflatedPricingRules;

    // hack as memoization (useMemo - inflatePricingRule) not working when accessing state
    if (isFromObserver && !inflatedPricingRules.length) {
      if (currentState.pricing.data?.length) {
        xInflatedPricingRules = currentState.pricing.data.map(inflatePricingRule);
      }
    }

    if (!xInflatedPricingRules.length || (isMobile && !currentState.showMobileSettings)) {
      return;
    }

    let cIdx: number = -1;
    const heights: number[] = [];
    xInflatedPricingRules.forEach((pricing: Pricing) => {
      pricing.conditions.forEach((condition) => {
        cIdx++;

        if (conditionElemRefs.current[cIdx] && conditionElemRefs.current[cIdx].current) {
          heights.push(conditionElemRefs.current[cIdx].current!.offsetHeight);
          // console.log('-----------------------exists');
        }
      });
    });

    if (cIdx !== -1) {
      setConditionHeights([...heights]);
    }

    if (conditionElemRefs.current.length === 0 || conditionElemRefs.current.length !== cIdx + 1) {
      // console.log('-----------------------initializing');
      const refs: Array<React.RefObject<HTMLDivElement>> = [];
      xInflatedPricingRules.forEach((pricing: Pricing) => {
        pricing.conditions.forEach((condition) => {
          refs.push(createRef<HTMLDivElement>());
        });
      });

      conditionElemRefs.current = [...refs];
    }
  }, [
    inflatedPricingRules,
    isMobile
  ]);

  const sendInitialDataToFrame = useCallback(() => {
    const frameData = {
      action: FrameActions.UPDATE_CHILD,
      to: 'child',
      payload: {
        service: {
          data: {
            ...state.service.data
          },
          builder: {
            context: 'pricing',
            parentReady: false,
            selectedFields: state.builder.selectedFields,
            selectedStepIndex: state.builder.selectedStepIndex,
            selectedFieldIndex: -1,
            selectedPrice: null,
            isSelectable: true,
            isEditable: false,
            isSortable: false,
            isDeletable: false,
            testMode: state.builder.testMode,
            hideBackground: !isMinWidth(sizes.tablet),
            hideFooter: !isMinWidth(sizes.tablet)
          }
        }
      }
    };

    if (iframeRef.current) {
      (iframeRef.current!.contentWindow! as BrowserWindow).isChild = true;
      (iframeRef.current!.contentWindow! as BrowserWindow).isModifiable = !state.builder.testMode;
      iframeRef.current!.contentWindow!.postMessage(JSON.stringify(frameData), window.location.origin);
    }
  }, [
    state.service.data,
    state.builder.testMode,
    state.builder.selectedFields,
    state.builder.selectedStepIndex
  ]);

  const onClearForm = useCallback(() => {
    const frameData = {
      action: Actions.RESET_FORM,
      to: 'child'
    };

    if (iframeRef.current) {
      iframeRef.current!.contentWindow!.postMessage(JSON.stringify(frameData), window.location.origin);
      iframeRef.current!.contentWindow!.location.reload();
    }

    setState(prevState => ({
      ...prevState,
      builder: {
        ...prevState.builder,
        highlightRows: {}
      }
    }));
  }, []);

  const toggleTestMode = useCallback((isTMode: boolean) => {
    if (isTMode) {
      const frameData = {
        action: Actions.RESET_FORM,
        to: 'child'
      };

      if (iframeRef.current) {
        iframeRef.current!.contentWindow!.postMessage(JSON.stringify(frameData), window.location.origin);
      }
    }

    setState(prevState => ({
      ...prevState,
      builder: {
        ...prevState.builder,
        frameLoaded: false,
        testMode: isTMode,
        comparator: null,
        selectedFields: [],
        selectedPrice: null,
        selectedCondition: {
          ...initialState.builder.selectedCondition 
        },
        highlightRows: {}
      }
    }));

    iframeRef.current!.contentWindow!.location.reload();
  }, []);

  const onFrameLoad = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      builder: {
        ...prevState.builder,
        frameLoaded: true
      }
    }));

    addMessageListener();
    sendInitialDataToFrame();
  }, [
    addMessageListener,
    sendInitialDataToFrame
  ]);

  const setPriceEditMode = useCallback((pricing: Pricing) => {
    setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        basePrice: pricing.unitPrice
      },
      builder: {
        ...prevState.builder,
        selectedPrice: {
          ...prevState.builder.selectedPrice!,
          editMode: true
        }
      }
    }));
  }, []);

  const changeComparator = useCallback((
    comparator: string | null,
    index: number,
    pricing: Pricing
  ) => {
    if (state.builder.testMode || !state.builder.selectedCondition.data) {
      return;
    }

    // const field = state.service.data!.fields.find((f: Field) => f.name === state.builder.selectedCondition.data!.compareField);
    const [field] = findField(
      state.service.data!.steps,
      state.builder.selectedCondition.data!,
      pricing
    );

    if (!field) {
      return;
    }

    let list: string[] = [];

    if (field.type === FieldType.Dropdown) {
      list = [
        ...dropdownComparators
      ];
    }
    else if (field.type === FieldType.Number) {
      list = [
        ...numberComparators
      ];
    }

    const idx: number = list.indexOf(comparator!);
    const newComparator: string = list[idx + 1] ? list[idx + 1] : list[0];

    setState(prevState => ({
      ...prevState,
      builder: {
        ...prevState.builder,
        comparator: newComparator
      }
    }));

    const length: number = state.builder.selectedPrice!.conditions.length;
    const currCondition: PricingCondition = state.builder.selectedPrice!.conditions[index] as PricingCondition;
    const { editMode, ...currentPricing } = state.builder.selectedPrice!;
    const updatedCondition: PricingCondition = {
      compareField: field.name,
      comparator: newComparator,
      compareValue: currCondition.compareValue
    };

    onUpdatePricing(
      state.builder.selectedPrice!._id,
      {
        ...currentPricing,
        conditions: [
          ...state.builder.selectedPrice!.conditions.slice(0, index),
          updatedCondition,
          ...state.builder.selectedPrice!.conditions.slice(index + 1, length),
        ]
      },
      () => {
        fetchPricing();
        selectCondition(updatedCondition, currentPricing!);
      }
    );
  }, [
    state.builder.testMode,
    state.builder.selectedPrice,
    state.builder.selectedCondition,
    state.service.data,
    onUpdatePricing,
    fetchPricing,
    selectCondition
  ]);

  const updatePricingRule = useCallback((pricing: Pricing) => {
    if (pricing.unitPrice === state.form.basePrice) {
      setState(prevState => ({
        ...prevState,
        form: {
          ...initialState.form
        },
        builder: {
          ...prevState.builder,
          selectedPrice: {
            ...prevState.builder.selectedPrice!,
            editMode: false
          }
        }
      }));

      return;
    }

    onUpdatePricing(pricing._id, {
      ...pricing,
      unitPrice: state.form.basePrice
    }, () => {
      fetchPricing();

      setState(prevState => ({
        ...prevState,
        form: {
          ...initialState.form
        },
        builder: {
          ...prevState.builder,
          selectedPrice: {
            ...prevState.builder.selectedPrice!,
            editMode: false
          }
        }
      }));
    });
  }, [
    state.form.basePrice,
    fetchPricing,
    onUpdatePricing
  ]);

  const onPriceInputKeyPress = useCallback((e: any, pricing: Pricing) => {
    if (e.nativeEvent.keyCode === 13) {
      updatePricingRule(pricing);
    }
  }, [updatePricingRule]);

  const renderIFrameSection = useCallback(() => {
    return (
      <PreviewPane>
        <Device>
          <StyledFrame
            ref={iframeRef}
            title={'Booking preview'}
            onLoad={onFrameLoad}
            src={`${window.location.origin}/${clientId}/booking/${serviceId}/1`}
          />
          {!state.builder.frameLoaded && (
            <FrameSpinner>
              <Spinner
                color={theme.textColor}
                size={'L'}
              />
            </FrameSpinner>
          )}
        </Device>
      </PreviewPane>
    );
  }, [
    serviceId,
    clientId,
    state.builder.frameLoaded,
    onFrameLoad
  ]);

  const onFormSubmit = useCallback((e: any) => {
    e.preventDefault();
  }, []);

  const onNewService = useCallback(() => {
    navigate({ pathname: `/${clientId}/back-office/services/create` });
  }, [clientId, navigate]);

  const renderPricingRules = useCallback(() => {
    let conditionIndex: number = -1;

    return (
      <Prices>
        {inflatedPricingRules.map((inflatedPricing: Pricing, index: number) => {
          const conditionSelectedAtPriceLevel: boolean = isConditionSelectedAtPriceLevel(
            state.builder,
            inflatedPricing
          );
          const isPriceSelected: boolean = !!state.builder.selectedPrice && state.builder.selectedPrice._id === inflatedPricing._id;
          const isPriceEditMode: boolean = !!state.builder.selectedPrice && state.builder.selectedPrice._id === inflatedPricing._id && state.builder.selectedPrice.editMode;
          const highlightColor: string | undefined = state.builder.highlightRows[inflatedPricing._id];
          const serviceFieldBasedOnAppliesTo: Field | null = findFieldByPricingAppliesTo(state.service.data!.steps, inflatedPricing);
          const widthBasedOnPriceLength: number = isPriceEditMode ? priceLengthDimensionMap[String(state.form.basePrice).length] : priceLengthDimensionMap[String(inflatedPricing.unitPrice).length];
          const ringRadius: number = widthBasedOnPriceLength * .5 * 10;
          let paddingLeftBasedOnPriceLength: number = isPriceEditMode ? priceLengthConditionLeftPaddingMap[String(state.form.basePrice).length] : priceLengthConditionLeftPaddingMap[String(inflatedPricing.unitPrice).length];
          const editName: boolean = state.form[inflatedPricing._id]?.name.length >= 0;

          if (!paddingLeftBasedOnPriceLength) {
            paddingLeftBasedOnPriceLength = priceLengthConditionLeftPaddingMap[Number(Object.keys(priceLengthConditionLeftPaddingMap)[0])];
          }

          return (
            <PriceRule
              key={inflatedPricing._id}
              ref={ruleElemRefs.current[index]}
              testMode={state.builder.testMode}
              isAppliedToField={!!inflatedPricing.appliesTo}
            >
              <NameWrapper>
                {!editName && (
                  <NameEditButton
                    hoverEffect
                    type="button"
                    onClick={() => {
                      setState(prevState => ({
                        ...prevState,
                        form: {
                          ...prevState.form,
                          [inflatedPricing._id]: {
                            name: inflatedPricing.name
                          }
                        }
                      }));
                    }}
                  >
                    <NameEditIcon />
                  </NameEditButton>
                )}
                {editName ? (
                  <>
                    <NameCrossButton
                      hoverEffect
                      type="button"
                      onClick={() => {
                        setState(prevState => {
                          const newState = { ...prevState };

                          delete newState.form[inflatedPricing._id];
                          delete newState.errors[inflatedPricing._id];

                          return newState;
                        });
                      }}
                    >
                      <NameCrossIcon />
                    </NameCrossButton>
                    <NameInput
                      value={state.form[inflatedPricing._id]?.name}
                      error={state.errors[inflatedPricing._id]?.name}
                      onKeyPress={(e: any) => {
                        if (!!state.errors[inflatedPricing._id]?.name) {
                          return;
                        }

                        if (e.nativeEvent.keyCode === 13) {
                          onUpdatePricing(
                            inflatedPricing._id, {
                              ...inflatedPricing,
                              name: state.form[inflatedPricing._id]?.name
                            }, (updatedPricing) => {
                              fetchPricing();
                              setState(prevState => {
                                const newState = { ...prevState };

                                delete newState.form[inflatedPricing._id];
                                delete newState.errors[inflatedPricing._id];

                                return newState;
                              });
                            }
                          );
                        }
                      }}
                      onChange={(e) => {
                        const name = e.target.value;
                        const inflatedPricingRuleNames = inflatedPricingRules
                          .filter(rule => rule._id !== inflatedPricing._id)
                          .map(rule => rule.name);

                        setState(prevState => {
                          const newState = {
                            ...prevState,
                            form: {
                              ...prevState.form,
                              [inflatedPricing._id]: {
                                name
                              }
                            }
                          };

                          if (name.length === 0) {
                            newState.errors[inflatedPricing._id] = {
                              name: 'Rule name cannot be empty'
                            };
                          }
                          else if (inflatedPricingRuleNames.includes(name)) {
                            newState.errors[inflatedPricing._id] = {
                              name: 'This rule name is already taken'
                            };
                          } else {
                            delete newState.errors[inflatedPricing._id];
                          }

                          return newState;
                        });
                      }}
                    />
                    <NameTickButton
                      hoverEffect
                      type="button"
                      disabled={!!state.errors[inflatedPricing._id]?.name}
                      onClick={() => {
                        onUpdatePricing(
                          inflatedPricing._id, {
                            ...inflatedPricing,
                            name: state.form[inflatedPricing._id]?.name
                          }, (updatedPricing) => {
                            fetchPricing();
                            setState(prevState => {
                              const newState = { ...prevState };

                              delete newState.form[inflatedPricing._id];
                              delete newState.errors[inflatedPricing._id];

                              return newState;
                            });
                          }
                        );
                      }}
                    >
                      <NameTickIcon />
                    </NameTickButton>
                  </>
                ) : (
                  <RuleName>{inflatedPricing.name}</RuleName>
                )}
              </NameWrapper>
              <PriceOuter
                selected={isPriceSelected}
              >
                {(isPriceEditMode && (
                  <>
                    <PriceInput
                      autoFocus
                      value={formatCurrency(state.form.basePrice)}
                      type={FieldType.Currency}
                      selected={isPriceSelected}
                      conditionSelected={conditionSelectedAtPriceLevel}
                      hasConditions={inflatedPricing.conditions.length > 0}
                      widthBasedOnPriceLength={widthBasedOnPriceLength}
                      onChange={(e: any) => {
                        setState(prevState => ({
                          ...prevState,
                          form: {
                            ...prevState.form,
                            basePrice: Number(getRawCurrencyValue(e.target.value)) || 0
                          }
                        }));
                      }}
                      onKeyPress={(e: any) => onPriceInputKeyPress(e, inflatedPricing)}
                      onBlur={() => updatePricingRule(inflatedPricing)}
                      onInput={(e: any) => {
                        const val = getRawCurrencyValue(e.target.value);
                        const max = '99999999';   // 8 characters £999,999.99

                        if (!max) {
                          return;
                        }

                        if (Number(val) > Number(max)) {
                          e.target.value = val.slice(0, max.length - 1)
                        }

                        if (val.length > max.length) {
                          e.target.value = val.slice(0, max.length)
                        }
                      }}
                    />
                    <i />
                  </>
                )) || (
                  <Price
                    selected={isPriceSelected}
                    conditionSelected={conditionSelectedAtPriceLevel}
                    hasConditions={inflatedPricing.conditions.length > 0}
                    highlightColor={highlightColor}
                    widthBasedOnPriceLength={widthBasedOnPriceLength}
                    onClick={() => selectPrice(inflatedPricing)}
                  >
                    {formatCurrency(inflatedPricing.unitPrice)}
                  </Price>
                )}
                {canUpdatePricing(clientId!, userData.user!) && (
                  <StyledEditButton
                    hoverEffect
                    type="button"
                    onClick={(e: any) => {
                      e.stopPropagation();

                      selectPrice(inflatedPricing);
                      setPriceEditMode(inflatedPricing);
                    }}
                  >
                    <StyledEditIcon />
                  </StyledEditButton>
                )}
                {canDeletePricing(clientId!, userData.user!) && (
                  <BinButton
                    hoverEffect
                    type="button"
                    onClick={(e: any) => {
                      e.stopPropagation();

                      selectPrice(inflatedPricing);
                      deletePricing(inflatedPricing._id);
                    }}
                  >
                    <StyledBinIcon />
                  </BinButton>
                )}
                {inflatedPricing.appliesTo && (
                  <FieldRuleIcon
                    noMinWidth
                    isEnabled={!!inflatedPricing.appliesTo}
                    tooltip={`The total price of this field (${serviceFieldBasedOnAppliesTo?.label}) will be added onto the price of the service.`}
                    value={'F'}
                  />
                )}
              </PriceOuter>
              <Conditions>
                {inflatedPricing.conditions.map((condition: PricingCondition | ConditionType, idx: number) => {
                  conditionIndex++;

                  const isMCondition: boolean = isMultipleCondition(condition, inflatedPricing);
                  const comparator: string | null = isMCondition ? null : (condition as PricingCondition).comparator;
                  const conditionSelected: boolean = isConditionSelected(
                    state.builder,
                    inflatedPricing,
                    condition
                  );
                  const selectedIndex: number = inflatedPricing.conditions.findIndex((con: PricingCondition | ConditionType) => {
                    if (!state.builder.selectedCondition.data) {
                      return false;
                    }

                    const { parentId, ...selectedCondition } = state.builder.selectedCondition;

                    return JSON.stringify(con) === JSON.stringify(selectedCondition.data)
                  });
                  const shouldHighlightStem: boolean = idx <= selectedIndex;
                  const [serviceField] = findField(
                    state.service.data!.steps,
                    condition,
                    inflatedPricing
                  );
                  const key: string = isMCondition ? 'is-multiple' : `${(condition as PricingCondition).compareField}-${(condition as PricingCondition).comparator}-${(condition as PricingCondition).compareValue}-${ringRadius}`;

                  return (
                    <Condition
                      key={key}
                      ref={conditionElemRefs.current[conditionIndex]}
                      ownHeight={conditionHeights[conditionIndex]}
                      zindex={inflatedPricing.conditions.length - idx}
                      highlightStem={isPriceSelected && shouldHighlightStem}
                      selected={conditionSelected}
                      testMode={state.builder.testMode}
                      paddingLeftBasedOnPriceLength={paddingLeftBasedOnPriceLength}
                      ringRadius={ringRadius}
                      onClick={() => selectCondition(condition, inflatedPricing)}
                    >
                      <ConditionInner>
                        <span>{!!inflatedPricing.appliesTo && isMCondition ? 'Is' : 'When'}</span>
                        {idx === 0 && inflatedPricing.appliesTo ? (
                          <>
                            <span>&nbsp;the cost of a single unit (<CompareField>{serviceFieldBasedOnAppliesTo?.label ?? '?'}</CompareField>)</span>
                            {!state.builder.testMode && (
                              <ConditionBinButton
                                hoverEffect
                                type="button"
                                onClick={(e: any) => {
                                  e.stopPropagation();

                                  selectPrice(inflatedPricing);
                                  toggleIsMultiple();
                                }}
                              >
                                <StyledBinIcon />
                              </ConditionBinButton>
                            )}
                          </>
                        ) : (
                          <>
                            <CompareField>&nbsp;&nbsp;{serviceField ? serviceField.label : '?'}&nbsp;&nbsp;</CompareField>
                            <ConditionButton
                              onClick={(e: any) => {
                                if (selectedIndex > -1 && selectedIndex !== idx) {
                                  return;
                                }

                                changeComparator(
                                  comparator,
                                  idx,
                                  inflatedPricing
                                );
                              }}
                            >{conditionSelected && state.builder.comparator ? state.builder.comparator : comparator}</ConditionButton>
                            <span>&nbsp;</span>
                            <CompareValue>{(condition as PricingCondition).compareValue}</CompareValue>
                            <ConditionBinButton
                              hoverEffect
                              type="button"
                              onClick={(e: any) => {
                                e.stopPropagation();

                                selectPrice(inflatedPricing);
                                onUpdatePricing(inflatedPricing._id, inflatedPricing, (updatedPricing) => {
                                  fetchPricing();
                                  fetchService();
                                  selectPrice(inflatePricingRule(updatedPricing));
                                }, idx);
                              }}
                            >
                              <StyledBinIcon />
                            </ConditionBinButton>
                          </>
                        )}
                      </ConditionInner>
                    </Condition>
                  );
                })}
              </Conditions>
            </PriceRule>
          );
        })}
      </Prices>
    );
  }, [
    clientId,
    inflatedPricingRules,
    state.service.data,
    state.form,
    state.builder,
    state.errors,
    userData.user,
    conditionHeights,
    selectPrice,
    changeComparator,
    selectCondition,
    fetchPricing,
    fetchService,
    onUpdatePricing,
    onPriceInputKeyPress,
    updatePricingRule,
    deletePricing,
    setPriceEditMode,
    toggleIsMultiple
  ]);

  const renderFormFieldControl = useCallback(() => {
    return (
      <>
        <FormFieldControlWrapper>
          <AdminFormLine
            column
            marginBottom
            fullWidth
          >
            <AdminFormLine
              row
              centerV
              marginBottom
            >
              <h3 style={{marginBottom: 0}}>Create your pricing rules</h3>
              <StyledSwitch
                trueText={isMobile ? 'Test' : 'Test mode'}
                falseText={isMobile ? 'Test' : 'Test mode'}
                textPosition={'left'}
                disabled={!state.builder.frameLoaded}
                tooltip={'Once you\'re happy with your rules try them out as if you were your user.'}
                value={state.builder.testMode}
                onChange={toggleTestMode}
              />
              <div className="mobile-page-new-action">
                {canCreatePricing(clientId!, userData.user!) && serviceId && (
                  <Button
                    style={{marginBottom: 0}}
                    type="button"
                    icon={<StyledPlus />}
                    disabled={isTestMode || !isPriceSet}
                    loading={state.pricingCreate.loading}
                    onClick={createPricingRule}
                  >{isMobile ? '' : 'New rule'}</Button>
                )}
              </div>
            </AdminFormLine>
            <AdminFormLine marginBottom />
            <AdminFormLine
              marginBottom
              style={{
                overflow: 'auto',
                height: isMobile ? '100%' : `${iFrameHeight}px`
              }}
            >
              {renderPricingRules()}
            </AdminFormLine>
          </AdminFormLine>
        </FormFieldControlWrapper>
      </>
    );
  }, [
    isMobile,
    isPriceSet,
    isTestMode,
    serviceId,
    clientId,
    userData.user,
    state.builder.testMode,
    state.builder.frameLoaded,
    state.pricingCreate.loading,
    renderPricingRules,
    toggleTestMode,
    createPricingRule
  ]);

  const renderForm = useCallback(() => {
    if (!serviceId) {
      if (state.services.loading) {
        return (
          <Spinner
            color={theme.textColor}
            size={'M'}
          />
        );
      }

      if (state.services.error) {
        return null;
      }

      if (isListEmpty(state.services.data)) {
        return (
          <AdminFormLine
            column
            centerH
            style={{
              paddingTop: '5rem'
            }}
          >
            <AdminFormLine marginBottom>
              <StyledAircraft />
            </AdminFormLine>
            <AdminFormLine marginBottom>
              <NoDataCaption>Looks like you're off course. Come back when you've landed your first service. Or click below to create one now!</NoDataCaption>
            </AdminFormLine>
            <PrimaryButton
              onClick={onNewService}
            >Create your first service!</PrimaryButton>
          </AdminFormLine>
        );
      }

      return (
        <FormSection
          cols={2}
          noBorder
        >
          {state.services.data!.map((service: Service) => {
            const pricingRuleCount: number = (service.pricingRules && service.pricingRules.length) || 0;

            return (
              <Card
                key={service._id}
                row
                noPadding
                boxShadow
                hSpaceBetween
                centerV
                style={{
                  padding: '3rem',
                  marginBottom: '2rem',
                  cursor: 'pointer'
                }}
                onClick={(e: any) => onServiceClick(e, service._id)}
              >
                <ContentWrapper>
                  <StatusIndicator
                    isEnabled={service.isEnabled}
                  >{service.isEnabled ? 'Enabled' : 'Disabled'}</StatusIndicator>
                </ContentWrapper>
                <ContentWrapper>
                  <p style={{ marginLeft: '2rem', marginRight: '2rem' }}><b>{service.name}</b></p>
                </ContentWrapper>
                <ContentWrapper column>
                  <IconButton style={{ marginBottom: '.75rem' }}>
                    <StyledPayments $state={pricingRuleCount !== 0} />
                  </IconButton>
                  <p>{`${pricingRuleCount} pricing rule${pricingRuleCount > 1 || pricingRuleCount === 0 ? 's' : ''}`}</p>
                </ContentWrapper>
              </Card>
            );
          })}
        </FormSection>
      );
    } else {
      if (state.pricing.error) {
        return null;
      }

      const mobileRuleHighlight: boolean = state.minimizeMobileSettings
        && isMobile
        && state.builder.testMode
        && !!state.builder.priceResults?.rules?.length;

      return (
        <CreateServiceFormWrapper
          noOverflow
          onSubmit={onFormSubmit}
        >
          <Grid>
            <MobileSettingsWrapper
              show={state.showMobileSettings}
              minimize={state.minimizeMobileSettings}
            >
              {renderFormFieldControl()}
              {mobileRuleHighlight && (
                <RuleHighlight
                  onClick={() => {
                    setState(prevState => ({
                      ...prevState,
                      minimizeMobileSettings: !prevState.minimizeMobileSettings
                    }));
                  }}
                >
                  <span>{state.builder.priceResults?.rules[0].name}</span>
                </RuleHighlight>
              )}
              {isMobile && state.showMobileSettings && (
                <>
                  <IconButton
                    hoverEffectAlwaysOn
                    medium
                    type="button"
                    style={{
                      position: 'absolute',
                      top: '0',
                      right: 0,
                      backgroundColor: 'white'
                    }}
                    onClick={() => {
                      setState(prevState => ({
                        ...prevState,
                        showMobileSettings: false,
                        minimizeMobileSettings: false
                      }));
                    }}
                  >
                    <StyledCross />
                  </IconButton>
                  <IconButton
                    medium
                    hoverEffectAlwaysOn
                    type="button"
                    style={{
                      position: 'absolute',
                      top: '0',
                      right: '6rem',
                      backgroundColor: 'white'
                    }}
                    onClick={() => {
                      setState(prevState => ({
                        ...prevState,
                        minimizeMobileSettings: !prevState.minimizeMobileSettings
                      }));
                    }}
                  >
                    <StyledChevron
                      $noFill
                      $up={state.minimizeMobileSettings}
                      $down={!state.minimizeMobileSettings}
                    />
                  </IconButton>
                </>
              )}
            </MobileSettingsWrapper>
            <AdminFormLine className={'mobile-settings-seperator'}>
              <StyledHR $alt />
            </AdminFormLine>
            <IFrameSectionWrapper blur={state.showMobileSettings && !state.minimizeMobileSettings}>
              {renderIFrameSection()}
              {createPortal(
                <MobileSettingIconWrapper mobileSettingsOn={state.showMobileSettings}>
                  <IconButton
                    medium
                    hoverEffectAlwaysOn
                    type="button"
                    onClick={() => {
                      onBack();
                    }}
                  >
                    <StyledMobileChevronIcon />
                  </IconButton>
                  <IconButton
                    medium
                    hoverEffectAlwaysOn
                    type="button"
                    onClick={() => {
                      setState(prevState => ({
                        ...prevState,
                        showMobileSettings: true
                      }));
                    }}
                  >
                    <StyledMobileSettingsIcon />
                  </IconButton>
                  <IconButton
                    hoverEffectAlwaysOn
                    medium
                    type="button"
                    onClick={() => {
                      onBack();
                    }}
                  >
                    {false ? (
                      <Spinner
                        color={theme.textColor}
                        size={'S'}
                      />
                    ) : (
                      <StyledMobileTickIcon disabled={false} />
                    )}
                  </IconButton>
                </MobileSettingIconWrapper>,
                document.body
              )}
            </IFrameSectionWrapper>
          </Grid>

          <AdminFormLine className={'mobile-actions-seperator'}>
            <HR $alt />
          </AdminFormLine>

          <AdminFormLine
            right
            topPadding
            spaceBetween
            className={'mobile-page-actions'}
          >
            {serviceId && (
              <Button
                type={'button'}
                onClick={onBack}
              >Back</Button>
            )}
            <AdminFormLine>
              <ClearButton
                type={'button'}
                disabled={!state.builder.testMode}
                onClick={onClearForm}
              >Clear form</ClearButton>
              <Button
                type={'button'}
                style={{ marginLeft: '1rem' }}
                onClick={onEditService}
              >Edit service</Button>
            </AdminFormLine>
          </AdminFormLine>
        </CreateServiceFormWrapper>
      );
    }
  }, [
    state.builder.testMode,
    state.pricing.error,
    state.showMobileSettings,
    state.minimizeMobileSettings,
    state.builder.priceResults,
    serviceId,
    state.services,
    isMobile,
    onBack,
    onFormSubmit,
    onServiceClick,
    renderFormFieldControl,
    renderIFrameSection,
    onClearForm,
    onEditService,
    onNewService
  ]);

  useEffect(() => {
    if (!serviceId && !state.services.data && !state.services.error) {
      fetchServices()
    }
  }, [
    serviceId,
    state.services,
    fetchServices
  ]);

  useEffect(() => {
    if (
      serviceId
      && !state.service.data && !state.service.error
      && !state.pricing.data && !state.pricing.error
    ) {
      fetchService((e?: any) => {
        if (!e) {
          fetchPricing();
        }
      });
    }
  }, [
    serviceId,
    state.service,
    state.pricing,
    fetchService,
    fetchPricing
  ]);

  useEffect(() => {
    if (state.service.data) {
      const frameData = {
        action: FrameActions.UPDATE_CHILD,
        to: 'child',
        payload: {
          service: {
            data: {
              ...state.service.data
            },
            builder: {
              context: 'pricing',
              parentReady: true,
              selectedFields: state.builder.selectedFields,
              selectedStepIndex: state.builder.selectedStepIndex,
              selectedFieldIndex: -1,
              selectedPrice: state.builder.selectedPrice,
              isSelectable: true,
              isEditable: false,
              isSortable: false,
              isDeletable: false,
              testMode: state.builder.testMode,
              hideBackground: isMobile,
              hideFooter: isMobile
            }
          }
        }
      };

      if (iframeRef.current) {
        iframeRef.current!.contentWindow!.postMessage(JSON.stringify(frameData), window.location.origin);
      }
    }
  }, [
    state,
    isMobile,
    clientId
  ]);

  useEffect(() => {
    return () => {
      removeMessageListener();
    };
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!state.pricing.data) {
      return;
    }

    if (prevRuleCount !== state.pricing.data.length) {
      ruleElemRefs.current = [...Array(state.pricing.data.length)].map(() => createRef<HTMLDivElement>());

      setPrevRuleCount(state.pricing.data.length);
      // console.log('-----------------setting elems');
    }

    if (state.pricing.data.length - prevRuleCount === 1) {
      // console.log('-----------------');
      setRuleAdded(true);
    }

    if (ruleAdded && state.pricing.data.length === prevRuleCount) {
      // console.log('---------highlighting');
      scrollToRule(0);
      setRuleAdded(false);

      setState(prevState => ({
        ...prevState,
        builder: {
          ...initialState.builder,
          frameLoaded: prevState.builder.frameLoaded,
          selectedPrice: {
            ...state.pricing.data![0],
            editMode: false
          }
        }
      }));
    }

  // eslint-disable-next-line
  }, [
    state.pricing.data,
    prevRuleCount,
    ruleElemRefs.current,
    ruleAdded
  ]);
  
  useEffect(() => {
    processConditionHeights(state);
  // eslint-disable-next-line
  }, [
    state,
    state.pricing.data,
    state.showMobileSettings,
    conditionElemRefs.current,
    processConditionHeights
  ]);

  const scrollToRule = (elemIndex: number) => {
    if (ruleElemRefs.current[elemIndex] && ruleElemRefs.current[elemIndex].current) {
      ruleElemRefs.current[elemIndex].current!.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // console.log('--------state', state);

  return (
    <Wrapper>
      <PageHeader
        title={`Pricing rules ${serviceId && state.service.data ? 'for \'' + state.service.data.name + '\'' : ''}`}
        rightContent={
          <>
            {canCreatePricing(clientId!, userData.user!) && serviceId && (
              <Button
                style={{marginBottom: 0}}
                icon={<StyledPlus style={{width: '1.3rem', height: '1.3rem' }}/>}
                disabled={isTestMode || !isPriceSet}
                loading={state.pricingCreate.loading}
                onClick={createPricingRule}
              >New rule</Button>
            )}
          </>
        }
        tag={serviceId ? 'mobile-page-header' : ''}
      />
      {!!serviceId && !state.pricing.error && (
        <div className="mobile-instruction">
          <AdminFormLine marginBottom>Use this page to set pricing rules for your service by selecting your price rule on the left and choosing which form field/question and value/answer (conditions) on the right activates it. Only dropdown and numerical fields can be used as conditions for a rule.</AdminFormLine>
          <AdminFormLine marginBottom />
        </div>
      )}
      {renderForm()}
    </Wrapper>
  );
};

export default memo(PricingRules);

