import React, { FC, memo } from 'react';
import { format } from 'date-fns';

import {
  Wrapper,
  StyledLink
} from './Footer.styles';

const Footer: FC = props => {
  return (
    <Wrapper>
      <div>
        <span>©{format(new Date(), 'yyyy')} GoBook'em</span>
      </div>
      <div>
        <StyledLink to={'/terms'}>Terms</StyledLink>
        <span>&nbsp;|&nbsp;</span>
        <StyledLink to={'/privacy'}>Privacy</StyledLink>
      </div>
    </Wrapper>
  );
};

export default memo(Footer);

