import React, { useMemo } from 'react';

import { theme } from 'theme';
import { User } from 'types/User';
import { Fulfiller } from 'types/Fulfiller';

import { getContrastingTextColor } from 'utils/general';

import {
  Wrapper,
  CircleWrapper,
  NameWrapper
} from './Avatar.styles';

export interface AvatarProps {
  user?: User | Fulfiller | null;
  showName?: boolean;
}

export interface CircleWrapperProps {
  colour: {
    font: string;
    background: string;
  }
}

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLElement> &
  AvatarProps;

const Avatar = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    className,
    user,
    showName,
    ...rest
  } = props;

  const name = useMemo(() => {
    if (!user) {
      return '';
    }

    return `${user.firstName} ${user.lastName}` || '';
  }, [user]);

  const fontColour = useMemo(() => {
    if (!user) {
      return theme.textColor;
    }

    return getContrastingTextColor(user.colour, theme.textColor);
  }, [user]);

  if (!user) {
    return null;
  }

  const displayText = name
    .split(' ')
    .slice(0, 2)
    .map(part => part[0])
    .join('');

  return (
    <Wrapper
      {...rest}
      ref={ref}
      className={className}
    >
      <CircleWrapper
        colour={{
          font: fontColour,
          background: user.colour
        }}
      >
        <span>{displayText}</span>
      </CircleWrapper>
      {showName && (
        <NameWrapper>{name}</NameWrapper>
      )}
    </Wrapper>
  );
});

export default Avatar;

