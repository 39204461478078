import React, { useCallback, useState, useEffect } from 'react';
import { default as ReactDatePicker, ReactDatePickerProps } from 'react-datepicker';
import {
  setHours,
  addMonths,
  addYears,
  format
} from 'date-fns';

import { theme } from 'theme';

import {
  TextInput,
  Calendar,
  Spinner
} from 'components/atoms';
import { FormFieldProps } from '../form/FormField';
import {
  IconButton,
  StyledChevron
} from 'theme/mixins';

import {
  StyledDatePickerWrapper,
  DatePickerFooter,
  DatePickerFooterList,
  DatePickerFooterListItem,
  LoadingLayer,
  StyledHeader
} from './DatePicker.styles';

// tslint:disable-next-line: no-submodule-imports
import 'react-datepicker/dist/react-datepicker.css';

export interface DatePickerProps extends Omit<ReactDatePickerProps, 'value' | 'onChange'> {
  width?: string;
  label?: string;
  noLabel?: boolean;
  error?: string;
  value?: Date;
  withFooter?: boolean;
  withMinMaxDate?: {
    minInDays?: number;
    maxInMonths?: number;
  };
  fullWidth?: boolean;
  isDisabled?: boolean;
  hasChanged?: boolean;
  tooltip?: FormFieldProps['tooltip'];
  loading?: boolean;
  onChange?: (value: Date) => void;
}

const DatePickerWrapper = (props: DatePickerProps) => {
  const {
    className,
    children,
    withFooter,
    loading
  } = props;

  return (
    <div className={className}>
      <div>{children}</div>
      {withFooter && (
        <DatePickerFooter>
          <DatePickerFooterList>
            <DatePickerFooterListItem colour={theme.textColor}>Available</DatePickerFooterListItem>
            <DatePickerFooterListItem colour={theme.datepicker.unavailable}><del>Unavailable</del></DatePickerFooterListItem>
          </DatePickerFooterList>
        </DatePickerFooter>
      )}
      {loading && (
        <LoadingLayer>
          <Spinner
            color={theme.textColor}
            size={'M'}
          />
        </LoadingLayer>
      )}
    </div>
  );
}

const DatePicker = React.forwardRef<HTMLInputElement, DatePickerProps>((props, ref) => {
  const {
    width,
    value,
    label,
    noLabel,
    error,
    onChange,
    withFooter,
    withMinMaxDate,
    fullWidth,
    isDisabled,
    hasChanged,
    tooltip,
    loading,
    ...rest
  } = props;

  const [innerValue, setInnerValue] = useState(value);

  const handleChange = useCallback((update: Date) => {
    setInnerValue(update);

    if (onChange) {
      onChange(update);
    }
  }, [onChange]);

  useEffect(() => {
    // NOTE: set to midday to prevent react-datepicker from changing the day due to browser offset
    if (value) {
      setInnerValue(setHours(value, 12));
    }
  }, [value]);

  const extra: any = {};

  if (withMinMaxDate) {
    const now: Date = new Date();
    extra.minDate = now;

    if (withMinMaxDate.maxInMonths && withMinMaxDate.maxInMonths >= 1) {
      extra.maxDate = addMonths(now, withMinMaxDate.maxInMonths);
    } else {
      extra.maxDate = addYears(now, 2);
    }
  }

  return (
    <StyledDatePickerWrapper
      width={width}
      fullWidth={fullWidth}
    >
      {/* placed 'renderCustomHeader' above of '{...rest}' so that other components can override this custom header */}
      <ReactDatePicker
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <StyledHeader>
            <IconButton
              hoverEffect
              disabled={prevMonthButtonDisabled}
              type="button"
              onClick={decreaseMonth}
            >
              <StyledChevron $left/>
            </IconButton>

            <h3>{format(date, 'MMMM yyyy')}</h3>

            <IconButton
              hoverEffect
              disabled={nextMonthButtonDisabled}
              type="button"
              onClick={increaseMonth}
            >
              <StyledChevron />
            </IconButton>
          </StyledHeader>
        )}
        {...rest}
        {...extra}
        dateFormat={rest.dateFormat || 'yyyy-MM-dd'}
        onChange={handleChange}
        disabled={isDisabled}
        popperPlacement={rest.popperPlacement || 'bottom'}
        customInput={
          <TextInput
            hasChanged={hasChanged}
            icon={<Calendar />}
            label={noLabel ? undefined : label || 'Select date'}
            error={error}
            tooltip={tooltip}
          />
        }
        selected={innerValue}
        calendarContainer={(containerProps) => (
          <DatePickerWrapper
            {...containerProps}
            loading={loading}
            withFooter={withFooter}
          />
        )}
      />
    </StyledDatePickerWrapper>
  );
});

export default DatePicker;

