import styled from 'styled-components';
import { device } from 'theme/media';

export const Row = styled.tr<{ noBackground?: boolean; $loading?: boolean; highlight?: string; clickable?: boolean; }>`
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${({ theme }) => theme.table.borderColor};

  input {
    width: 100%;
  }

  :hover {
    background-color: ${({ theme }) => theme.table.hover.rowBackgroundColor};
  }

  ${({ clickable }) => clickable && `
    cursor: pointer;
  `};

  ${({ $loading }) => $loading && `
    // background-color: rgba(0,0,0,0.5);
  `};

  ${({ highlight }) => highlight && `
    background-color: ${highlight};
  `};

  @media ${device.onlyMobile} {
    display: block;
    position: relative;
    margin: 0 0 1rem 0;
  }
`;

export const Cell = styled.td<{ centre?: boolean; empty?: boolean; borderLeft?: boolean; borderBottom?: boolean; isActionMenu?: boolean; isInactive?: boolean; }>`
  padding: ${({ theme }) => theme.table.padding};

  ${({ centre }) => centre && `
    text-align: center;
  `};

  ${({ theme, borderLeft }) => borderLeft && `
    // border-left: 1px solid;
    border-left-style: solid;
    border-left-color: ${theme.table.borderColor};
    border-left-width: 3px;
  `};

  ${({ theme, borderBottom }) => borderBottom && `
    // border-bottom: 1px solid;
    border-bottom-style: solid;
    border-bottom-color: ${theme.table.borderColor};
    border-bottom-width: 3px;
  `};

  @media ${device.onlyMobile} {
    display: block;
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;

		&:before {
      content: attr(data-label);
			/* Now like a table header */
			position: absolute;
			/* Top/left values mimic padding */
			display: flex;
			top: 0;
			left: 6px;
			width: 45%;
			height: 100%;
			padding-right: 10px;
			white-space: nowrap;
			align-items: center;

      ${({ theme, isInactive }) => isInactive && `
        color: ${theme.baseInput.disabled.color};
      `}
		}

		&:first-child {
		  font-weight: 700;
		}

    ${({ theme, isActionMenu }) => isActionMenu && `
      @media ${device.onlyMobile} {
        padding: 0;
        position: unset;

        &:before {
          content: unset;
        }
      }
    `};
  }
`;

export const ActionWrapper = styled.div`
  @media ${device.onlyMobile} {
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    > button {
      display: none;
    }
  }
`;

export const EditActionWrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media ${device.onlyMobile} {
    justify-content: flex-end;
  }
`;
