import { BaseEntity } from './Generic';

// TODO: temp. Extend from shared type
export interface Condition {
  compareField: string;
  comparator: string | null;
  compareValue: string;
}

export enum ConditionType {
  FieldMultiple = 1
}

export interface Pricing extends BaseEntity {
  _id: string;
  name: string;
  appliesTo: string | null;
  unitPrice: number;
  conditions: Array<Condition | ConditionType>;
  serviceId: string;
  clientId: string;
}

