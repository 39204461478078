import React, { memo, useState, useCallback, useEffect } from 'react';

import { isNumeric } from 'utils/general';
import { StyledNumericInput } from './NumericInput.styles';
import { FormField } from '../form';
import { StyledBaseInputProps } from './BaseInput';
import { FormFieldProps } from '../form/FormField';
import { MAX_PRICE } from '../../../constants';

export interface NumericInputProps extends Omit<StyledBaseInputProps, 'value'> {
  onUpdate?: (e: any, value: number) => void;
  label?: string;
  error?: string;
  maxDecimalPlaces?: number;
  fixedDecimalPlaces?: number;
  value?: number;
  width?: string;
}

export type Props = NumericInputProps & FormFieldProps;

const NumericInput = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    className,
    style,
    error,
    hasChanged,
    label,
    value,
    width,
    widthM,
    widthT,
    widthL,
    noMinWidth,
    maxDecimalPlaces,
    fixedDecimalPlaces,
    onBlur,
    onFocus,
    onChange,
    onUpdate,
    ...rest
  } = props;

  const [innerValue, setInnerValue] = useState<number | undefined>(value);
  const [innerError, setInnerError] = useState<string | undefined>(error);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const onChangeInternal = useCallback((e: any) => {
    const val = e.target.value;

    if (onChange) {
      onChange(e);
    }
    if (onUpdate) {
      let propagatedVal = Number(val);

      if (val === '') {
        propagatedVal = 0;
      }

      setInnerValue(propagatedVal);
      onUpdate(e, propagatedVal);
    }
  }, [onChange, onUpdate]);

  const maxLengthCheck = (e: any) => {
    const val: string = e.target.value.toString();
    const min: string = e.target.min.toString();
    const max: string = e.target.max.toString();

    // Min check
    if (!!min && Number(val) < Number(min)) {
      setInnerError(`Minimum number is ${min}`);
    } else {
      setInnerError(undefined);
    }

    // Only run below if max is present. Otherwise input becomes unusable
    if (!max) {
      return;
    }

    // Max check
    if (Number(val.split('.')[0]) > Number(max)) {
      e.target.value = val.slice(0, max.length - 1)
    }

    // Char length check
    if (val.split('.')[0].length > max.length) {
      e.target.value = val.slice(0, max.length)
    }

    // Cull preceeding 0s
    if (val.split('.')[0].length > 1 && val.charAt(0) === '0') {
      e.target.value = Number(val);
    }
  };

  useEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(innerValue)) {
      setInnerValue(value);
    }
  }, [
    innerValue,
    value
  ]);

  return (
    <FormField
      id={rest.id}
      error={error || innerError}
      label={label}
      className={className}
      style={style}
      hasChanged={hasChanged}
      value={innerValue}
      width={width}
      widthM={widthM}
      widthT={widthT}
      widthL={widthL}
      noMinWidth={noMinWidth}
      isFocused={isFocused}
      isFull={(innerValue && String(innerValue).length > 0) || false}
      info={rest.info}
      tooltip={rest.tooltip}
      builder={rest.builder}
      startAdornment={rest.startAdornment}
      endAdornment={rest.endAdornment}
      formFieldStyles={rest.formFieldStyles}
    >
      <StyledNumericInput
        {...rest}
        ref={ref}
        value={innerValue}
        type="number"
        autoComplete={rest.disableAutoComplete ? '!off' : undefined}
        disabled={rest.disabled}
        max={rest.max || MAX_PRICE}
        onFocus={e => {
          if (onFocus) {
            onFocus(e);
          }

          setIsFocused(true);
        }}
        onBlur={e => {
          if (onBlur) {
            onBlur(e);
          }

          setIsFocused(false);
        }}
        onInput={maxLengthCheck}
        onKeyPress={e => {
          isNumeric(e);

          if (rest.onKeyPress) {
            rest.onKeyPress(e);
          }
        }}
        onChange={onChangeInternal}
        errored={!!error || !!innerError}
      />
    </FormField>
  );
});

export default memo(NumericInput);
