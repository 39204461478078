import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import * as WebFont from 'webfontloader';

import { Branding } from 'gobookem-shared';

import 'theme/font.css';
import './polyfills';
import './i18n';

import App from './components/App';
import { GlobalStyle } from './theme';
import { gatewayService } from 'services';
import { scrollToContent } from 'utils/general';

declare global {
  interface Window {
    AUTH0_URL: string;
    FRONTEND_URL: string;
    BACKEND_URL: string;
    GoCardlessClient: any;
  }
}

WebFont.load({
  custom: {
    families: ['Roboto'],
  }
});

if (process.env.NODE_ENV !== 'production') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React);
}

const connectionString = `${process.env.REACT_APP_BACKEND_URL}`;
gatewayService.setup(connectionString);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// TODO: add stripe key to config
root.render(
  <BrowserRouter>
    <ThemeProvider theme={Branding.theme}>
      <>
        <GlobalStyle />
        <App />
      </>
    </ThemeProvider>
  </BrowserRouter>
);

setTimeout(scrollToContent, 500);

