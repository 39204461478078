import styled from 'styled-components';

export const Wrapper = styled.div<{
  isError?: boolean;
  disabled?: boolean;
}>`
  ${({ theme, isError }) => isError && `
    min-width: 0;
    color: ${theme.colors.accentTertiary}
  `}

  ${({ theme, disabled }) => disabled && `
    color: ${theme.colors.neutralPrimaryDark2}
  `}
`;

