import React, { useState, useRef } from 'react';
import Select, { Props as SelectProps } from 'react-select';

import { FormField } from '../form';
import { BaseProps } from '../form/types';
import { DropdownOption } from 'types/UI';
import { sizes } from 'theme/media';

import { selectStyles, getTheme } from './Dropdown.styles';
import { FormFieldProps } from '../form/FormField';
import {
  isMinWidth
} from 'utils/general';

export interface DropdownProps extends BaseProps, SelectProps {
  options: DropdownOption[] | any;
};

export type Props = DropdownProps & FormFieldProps;

const getOption = (value: string, options: DropdownOption[] | any | undefined): DropdownOption | null => {
  if (!options) {
    return null;
  }

  for (const option of options) {
    if (option.options?.length) {
      for (const groupedOption of option.options) {
        if (JSON.stringify(groupedOption.value) === JSON.stringify(value)) {
          return groupedOption;
        }
      }
    } else {
      if (JSON.stringify(option.value) === JSON.stringify(value)) {
        return option;
      }
    }
  }

  return null;
};

const Dropdown = (props: Props) => {
  const {
    className,
    label,
    value,
    options,
    error,
    hasChanged,
    width,
    widthM,
    widthT,
    widthL,
    isClearable,
    isDisabled,
    noWidth,
    noMinWidth,
    onFocus,
    onBlur,
    onChange,
    onMenuOpen,
    ...rest
  } = props;

  const [isFocused, setIsFocused] = useState<boolean>(false);
  const onMenuCloseTs = useRef<number>(0);
  // const onBlurTs = useRef<number>(0);

  const isMobile: boolean = !isMinWidth(sizes.tablet);

  return (
    <FormField
      id={rest.id}
      error={error}
      label={label}
      className={className}
      style={rest.style}
      hasChanged={hasChanged}
      value={value}
      width={width}
      widthM={widthM}
      widthT={widthT}
      widthL={widthL}
      noWidth={noWidth}
      noMinWidth={noMinWidth}
      isFocused={isFocused}
      info={rest.info}
      tooltip={rest.tooltip}
      builder={rest.builder}
      warning={rest.warning}
      endAdornment={rest.endAdornment}
      formFieldStyles={rest.formFieldStyles}
    >
      <Select
        {...props}
        className={'select'}
        classNamePrefix={'select'}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isSearchable={!isMobile}
        value={rest.isMulti ? value : getOption(value as string, options)}
        options={options}
        menuPortalTarget={document.body as HTMLElement}
        menuPosition={'fixed'}
        menuShouldScrollIntoView={false}
        onMenuOpen={() => {
          setTimeout(()=>{
            const selectedEl = document.getElementsByClassName("select__option--is-selected")[0];

            if(selectedEl){
              selectedEl.scrollIntoView({ inline: 'start' });
            }
          }, 0);

          if (onMenuOpen) {
            onMenuOpen();
          }
        }}
        onMenuClose={() => {
          onMenuCloseTs.current = Date.now();
        }}
        // NOTE: causes scroll bug in prod
        // closeMenuOnScroll={e => {
        //   if (e.target && (e.target as HTMLElement).className?.includes('MenuList')) {
        //     return false;
        //   }

        //   return true;
        // }}
        styles={selectStyles(isFocused, !!error)}
        theme={(theme) => getTheme(theme)}
        onChange={(newValue, actionMeta) => {
          if (onChange) {
            onChange(newValue, actionMeta);
          }
        }}
        onFocus={e => {
          setIsFocused(true);

          if (onFocus) {
            onFocus(e as any);
          }
        }}
        onBlur={e => {
          // Workaround to fix DummyInput from firing blur event after option selection on smaller screens
          // onBlurTs.current = Date.now();
          // const diff = onBlurTs.current - onMenuCloseTs.current;
          // if (diff <= 1000) {
          //   e.target.focus();
          //   return;
          // }

          setIsFocused(false);

          if (onBlur) {
            onBlur(e as any);
          }
        }}
      />
    </FormField>
  );
};

export default Dropdown;

