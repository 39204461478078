import React, { FC } from 'react';

import { AppBanner as AppBannerType} from 'types/Generic';

import {
  Wrapper,
} from './AppBanner.styles';

export interface AppBannerProps {
  banner: AppBannerType;
}

const AppBanner: FC<AppBannerProps> = props => {
  const { banner } = props;

  return (
    <Wrapper type={banner.type}>
      {banner.content}
    </Wrapper>
  );
};

export default AppBanner;

