import styled from 'styled-components';
import { device } from 'theme/media';

import { CheckboxProps } from './Checkbox';

// TODO: add disabled and readonly state

export const Wrapper = styled.div<{
  width?: string;
  widthM?: string;
  widthML?: string;
  widthT?: string;
  widthL?: string;
}>`
  position: relative;

  @media ${device.mobile} {
    ${({ widthM }) => widthM && `
      width: ${widthM};
    `}
  }

  @media ${device.mobileL} {
    ${({ widthML }) => widthML && `
      width: ${widthML};
    `}
  }

  @media ${device.tablet} {
    ${({ widthT }) => widthT && `
      width: ${widthT};
    `}
  }

  @media ${device.laptop} {
    ${({ widthL }) => widthL && `
      width: ${widthL};
    `}
  }

  @media ${device.laptop} {
    ${({ width, widthM, widthT, widthL }) => !width && !widthM && !widthT && !widthL && `
      max-width: 35rem;
    `}
  }
`;

export const StyledSpan = styled.span<{ width: number; }>`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  background: ${({ theme }) => theme.colors.neutralPrimary};
  border: .1rem solid ${({ theme }) => theme.option.borderColor};
  border-radius: .4rem;
  transition: all ${({ theme }) => theme.animSpeed} cubic-bezier(.4,.0,.23,1);
  flex-shrink: 0;

  ${({ width }) => `
    width: ${width}rem;
    height: ${width}rem;
  `};

  &:hover {
    border-color: ${({ theme }) => theme.baseInput.hover.borderColor};
  }

  &:focus,
  &:active {
    border-color: ${({ theme }) => theme.option.active.color};
  }
`;

export const StyledLabel = styled.label<{
  labelPos: CheckboxProps['labelPos'];
  smallText?: boolean;
}>`
  position: relative;
  display: flex;
  margin: .6rem 0;
  align-items: center;
  color: inherit;
  transition: color ${({ theme }) => theme.animSpeed} cubic-bezier(.4,.0,.23,1);
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.accentPrimary};
  }

  ${({ labelPos }) => labelPos === 'top' && `
    flex-direction: column-reverse;

    ${StyledSpan} {
      margin-top: 1rem;
      margin-right: 0;
    }
  `}

  ${({ labelPos }) => labelPos === 'right' && `
    flex-direction: row;
  `}

  ${({ labelPos }) => labelPos === 'bottom' && `
    flex-direction: column;

    ${StyledSpan} {
      margin-bottom: 1rem;
      margin-right: 0;
    }
  `}

  ${({ labelPos }) => labelPos === 'left' && `
    flex-direction: row-reverse;

    ${StyledSpan} {
      margin-left: 1rem;
      margin-right: 0;
    }
  `}

  ${({ smallText }) => smallText && `
    font-size: 1.2rem;
  `}
`;

export const StyledCheckbox = styled.input<{
  width: number;
  tickWidthRatio: number;
  errored?: boolean;
}>`
  position: absolute;
  width: 0;
  height: 0;
  visibility: hidden;

  ${({ theme, width, tickWidthRatio }) => `
    &:checked + label > span {
      border: ${width / 2}rem solid ${theme.colors.corePrimary};
      animation: checkbox-shrink-bounce ${theme.animSpeed} cubic-bezier(.4,.0,.23,1);
    }

    &:checked + label {
      color: ${theme.colors.accentPrimary};

      > span:before {
        content: '';
        position: absolute;
        right: ${width * tickWidthRatio}rem;
        bottom: 50%;
        border-right: ${width / 10}rem solid transparent;
        border-bottom: ${width / 10}rem solid transparent;
        transform: rotate(45deg);
        transform-origin: 0% 100%;
        animation: checkbox-check ${theme.animSpeed} ${theme.animDelay} cubic-bezier(.4,.0,.23,1) forwards;
      }
    }

    &:focus + label,
    &:active + label {
      color: ${theme.colors.accentPrimary};

      > span {
        border-color: ${theme.option.active.color};
      }
    }
  `}

  ${({ theme, errored }) => errored && `
    & + label > span {
      border-color: ${theme.colors.accentTertiary};
    }
  `}
`;

