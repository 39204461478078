import { useEffect, useRef, useState } from 'react';

import {
  isMinWidth,
} from 'utils/general';
import { sizes } from 'theme/media';

export interface UseMediaSizesProps {
  onMediaResize?: (state: UseMediaSizesState) => void
}

export interface UseMediaSizesState {
  isMobile: boolean;
  isMobileS: boolean;
  isMobileM: boolean;
  isMobileL: boolean;
  isMobileXL: boolean;
  isTablet: boolean;
  isLaptop: boolean;
  isLaptopM: boolean;
  isLaptopL: boolean;
  isDesktop: boolean;
}

const initialState: UseMediaSizesState = {
  isMobile: !isMinWidth(sizes.tablet),
  isMobileS: isMinWidth(sizes.mobileS),
  isMobileM: isMinWidth(sizes.mobileM),
  isMobileL: isMinWidth(sizes.mobileL),
  isMobileXL: isMinWidth(sizes.mobileXL),
  isTablet: isMinWidth(sizes.tablet),
  isLaptop: isMinWidth(sizes.laptop),
  isLaptopM: isMinWidth(sizes.laptopM),
  isLaptopL: isMinWidth(sizes.laptopL),
  isDesktop: isMinWidth(sizes.desktop)
};

const useMediaSizes = (props?: UseMediaSizesProps): UseMediaSizesState => {
  const [state, setState] = useState<UseMediaSizesState>(initialState);

  const docElementObserver = useRef<ResizeObserver | null>(null);

  // Observe docElement dimension change
  useEffect(() => {
    if (!docElementObserver.current) {
      docElementObserver.current = new ResizeObserver(() => {
        setState(prevState => {
          const newState = {
            isMobile: !isMinWidth(sizes.tablet),
            isMobileS: isMinWidth(sizes.mobileS),
            isMobileM: isMinWidth(sizes.mobileM),
            isMobileL: isMinWidth(sizes.mobileL),
            isMobileXL: isMinWidth(sizes.mobileXL),
            isTablet: isMinWidth(sizes.tablet),
            isLaptop: isMinWidth(sizes.laptop),
            isLaptopM: isMinWidth(sizes.laptopM),
            isLaptopL: isMinWidth(sizes.laptopL),
            isDesktop: isMinWidth(sizes.desktop)
          };

          if (props?.onMediaResize) {
            props.onMediaResize(newState);
          }

          return newState;
        });
      });

      docElementObserver.current.observe(document.documentElement);
    }

    return () => {
      if (docElementObserver.current) {
        docElementObserver.current.disconnect();
      }
    };
  // eslint-disable-next-line
  }, []);

  return state;
};

export default useMediaSizes;

