import styled from 'styled-components';
import { device } from 'theme/media';

import { DatePicker, Dropdown } from 'components/atoms';
import {
  FormLine
} from 'theme/mixins';
export * from 'theme/mixins';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .react-datepicker {
    &__header {
      background-color: ${({ theme }) => theme.datepicker.header};
    }
  }

  .react-datepicker-popper {
    z-index: ${({ theme }) => theme.layers.highest};
  }
`;

export const InnerWrapper = styled(FormLine)<{
  width?: string;
  widthM?: string;
  widthT?: string;
  widthL?: string;
}>`
  ${({ width }) => width && `
    width: ${width};
  `}

  @media ${device.mobile} {
    ${({ widthM }) => widthM && `
      width: ${widthM};
    `}
  }

  @media ${device.tablet} {
    ${({ widthT }) => widthT && `
      width: ${widthT};
    `}
  }

  @media ${device.laptop} {
    ${({ widthL }) => widthL && `
      width: ${widthL};
    `}
  }

  @media ${device.laptop} {
    ${({ width, widthM, widthT, widthL }) => !width && !widthM && !widthT && !widthL && `
      max-width: 35rem;
    `}
  }
`;

export const CalendarDay = styled.div<{ unavailable?: boolean; }>`
  ${({ theme, unavailable }) => unavailable && `
    color: ${theme.datepicker.unavailable};
  `};
`;

export const StyledDatePicker = styled(DatePicker)``;

export const StyledDropdown = styled(Dropdown)<{ timeOnly?: boolean; }>`
  .select__control {
    height: 3.5rem;
  }

  ${({ timeOnly }) => !timeOnly && `
    margin-left: 1rem;
  `};
`;

