import React, { Fragment, FC, memo, useCallback, useState, useEffect } from 'react';
import { useParams } from "react-router";
import { useTranslation } from 'react-i18next';

import { PageHeader } from '../../components';
import { AdminPageProps } from 'components/AppRouter';
import {
  Client,
  AccountingPlatform,
  Settings as ClientSettings
} from 'types/Client';
import { NetworkError } from 'types/Error';
import {
  Form,
  DropdownOption
} from 'types/UI';
import { gatewayService } from 'services';
import {
  Spinner,
  Switch,
  TextInput,
  Text,
  CreatableDropdown,
  Dropdown
} from 'components/atoms';
import { Tabs } from 'components/molecules';
import { theme } from 'theme';
import {
  IconButton,
  StyledLinkExternal
} from 'theme/mixins';
import {
  useDebouncedCallback
} from 'components/hooks';

import {
  getNetworkErrors,
  extractNumericInput,
  createDropdownOption
} from 'utils/general';
import { FULFILLER_TYPE_DROPDOWN_OPTIONS } from '../../../../../constants';

import {
  Wrapper,
  Card,
  AdminFormLine,
  SettingOuter,
  SettingInner,
  SettingDescription,
  PlatformOptions,
  PlatformOption,
  PlatformLogo,
  ComingSoonRibbon,
  StyledLinkIcon,
  SettingHeader
} from './Settings.styles';

interface State {
  client: {
    loading: boolean;
    data: Client | null;
    fields: any;
    error: NetworkError | null;
  };
  updateClient: {
    loading: boolean;
    error: NetworkError | null;
  };
  accountingPlatform: {
    loading: boolean;
    error: NetworkError | null;
  };
  form: Form;
  ui: {
    addedFulfillerTypes: DropdownOption[]
  },
  errors: {
    [key: string]: any;
  };
  activeSettingsTab: string;
}

const initialState: State = {
  client: {
    loading: false,
    data: null,
    fields: {},
    error: null
  },
  updateClient: {
    loading: false,
    error: null
  },
  accountingPlatform: {
    loading: false,
    error: null
  },
  form: {},
  ui: {
    addedFulfillerTypes: []
  },
  errors: {},
  activeSettingsTab: ''
};

const Settings: FC<AdminPageProps> = props => {
  const {
    addToast
  } = props;

  const [state, setState] = useState<State>(initialState);
  const { clientId } = useParams();
  const { t } = useTranslation();

  const fetchClient = useCallback(() => {
    if (state.client.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      client: {
        ...prevState.client,
        loading: true
      }
    }));

    gatewayService.getClient(clientId!)
      .then((clientResponse: any) => {
        setState(prevState => ({
          ...prevState,
          client: {
            loading: false,
            data: clientResponse.data,
            fields: clientResponse.fields,
            error: null
          },
          form: {
            settings: {
              ...initialState.form.settings as any,
              ...clientResponse.data.settings
            }
          }
        }));
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          client: {
            ...prevState.client,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: 'Unable to load settings. ' + error.message
        });
      });
  }, [
    addToast,
    state.client,
    clientId
  ]);

  const updateClient = useCallback((payload: any) => {
    if (state.updateClient.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      updateClient: {
        ...prevState.updateClient,
        loading: true
      }
    }));

    gatewayService.updateClient(clientId!, payload)
      .then((updateClientResponse: any) => {
        setState(prevState => ({
          ...prevState,
          updateClient: {
            loading: false,
            error: null
          },
          form: {
            settings: updateClientResponse.data.settings
          }
        }));
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          updateClient: {
            loading: false,
            error
          },
          form: {
            ...prevState.form,
            loading: false,
            settings: prevState.client.data!.settings as any
          }
        }));

        addToast({
          type: 'error',
          content: 'Unabled to update settings. ' + error.message
        });
      });
  }, [
    addToast,
    state.updateClient,
    clientId
  ]);

  const fetchAccountingAuthUrl = useCallback((platform: AccountingPlatform, cb: (authUrl: string) => void) => {
    if (state.accountingPlatform.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      accountingPlatform: {
        ...prevState.accountingPlatform,
        loading: true
      }
    }));

    gatewayService.getAccountingAuthUrl(clientId!)
      .then((accountingAuthUrlResponse: any) => {
        setState(prevState => ({
          ...prevState,
          accountingPlatform: {
            ...prevState.accountingPlatform,
            loading: false,
          }
        }));

        cb(accountingAuthUrlResponse.data);
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          accountingPlatform: {
            ...prevState.accountingPlatform,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: 'Error connecting accounting platform. ' + error.message
        });
      });
  }, [
    addToast,
    state.accountingPlatform,
    clientId
  ]);

  const [validateInvoicingAccountNumber] = useDebouncedCallback((newBusinessAccountNumber: string, newState: any) => {
    if (newBusinessAccountNumber.length !== 8) {
      newState.errors.businessAccountNumber = 'Account number must be 8 digits';
    } else {
      delete newState.errors.businessAccountNumber;
    }
  }, 1000);

  const [validateInvoicingSortCode] = useDebouncedCallback((newBusinessSortCode: string, newState: any) => {
    if (newBusinessSortCode.length !== 6) {
      newState.errors.businessSortCode = 'Sort code must be 6 digits';
    } else {
      delete newState.errors.businessSortCode;
    }
  }, 1000);

  const [validateVatNumber] = useDebouncedCallback((newVatNumber: string, newState: any) => {
    if (newVatNumber.length !== 9) {
      newState.errors.vatNumber = 'VAT number should be 9 digits';
    } else {
      delete newState.errors.vatNumber;
    }
  }, 1000);

  const [updateInvoicingAccountDetails] = useDebouncedCallback((settings: { settings: ClientSettings }) => {
    updateClient(settings);
  }, 1000);

  const onConnectToPlatform = useCallback((platform: AccountingPlatform) => {
    fetchAccountingAuthUrl(platform, (authUrl: string) => {
      window.location.href = authUrl;
    });
  }, [fetchAccountingAuthUrl]);

  const renderPlatformOption = useCallback((platformOption: AccountingPlatform, currentPlatform: AccountingPlatform) => {
    const isSelected: boolean = platformOption === currentPlatform;
    const isDisabled: boolean = platformOption === AccountingPlatform.Xero;
    const isConnected: boolean = !!state.client.data?.qboCompanyId;
    const comingSoon: boolean = platformOption === AccountingPlatform.Xero;

    let content = null;

    switch (platformOption) {
      case AccountingPlatform.None:
        content = <span>None</span>;
        break; 
      case AccountingPlatform.QuickBooks:
        const isUnfinished: boolean = !isConnected && !!state.client.data?.qboStateToken;
        const connectionError: boolean = !!state.client.data?.qboConnectionError;

        const tooltip = connectionError ?
          'Error connecting QuickBooks. Please try again' : isUnfinished ?
          'Connection process incomplete' : isConnected ?
          'Connected' : 'Connect QuickBooks';

        content = (
          <>
            <PlatformLogo
              width="50%"
              alt="QuickBooks logo"
              src="/images/quickbooks-logo.jpg"
            />
            {isSelected && (
              <IconButton
                data-title={tooltip}
                onClick={(e) => {
                  e.stopPropagation();

                  if (isUnfinished || connectionError || !isConnected) {
                    onConnectToPlatform(platformOption)
                  }
                }}
              >
                {state.accountingPlatform.loading ? (
                  <Spinner /> 
                ) : (
                  <StyledLinkIcon
                    $connected={isConnected}
                    $error={isUnfinished || connectionError}
                  />
                )}
              </IconButton>
            )}
          </>
        );
        break; 
      case AccountingPlatform.Xero:
        content = (
          <>
            <PlatformLogo
              width="50%"
              alt="Xero logo"
              src="/images/xero-logo.png"
            />
            {comingSoon && (
              <ComingSoonRibbon>Coming soon</ComingSoonRibbon>
            )}
          </>
        );
        break; 
    };

    return (
      <PlatformOption
        key={platformOption}
        $disabled={isDisabled || comingSoon}
        selected={isSelected}
        onClick={() => {
          if (isDisabled) {
            return;
          }

          setState(prevState => {
            const newState = {
              ...prevState,
              form: {
                ...prevState.form,
                settings: {
                  ...prevState.form.settings as any,
                  accounting: {
                    ...(prevState.form.settings as any).accounting,
                    platform: platformOption
                  }
                }
              }
            };

            updateClient({
              settings: {
                ...newState.form.settings
              }
            });

            return newState;
          });
        }}
      >{content}</PlatformOption>
    );
  }, [
    state.client.data,
    state.accountingPlatform.loading,
    updateClient,
    onConnectToPlatform
  ]);

  const renderSwitchSetting = useCallback((
    settingCategory: string,
    setting: string,
    settingLabelElem?: JSX.Element
  ) => {
    return (
      <SettingInner>
        {settingLabelElem || <div>{t(`settings.${setting}`)}</div>}
        <Switch
          value={(state.form.settings as any)[settingCategory][setting]}
          onChange={(value: boolean) => {
            setState((prevState: any) => ({
              ...prevState,
              form: {
                ...prevState.form,
                settings: {
                  ...prevState.form.settings,
                  [settingCategory]: {
                    ...prevState.form.settings[settingCategory],
                    [setting]: value
                  }
                }
              }
            }));

            updateClient({
              settings: {
                ...(state.form.settings as any),
                [settingCategory]: {
                  ...(state.form.settings as any)[settingCategory],
                  [setting]: value
                }
              }
            });
          }}
        />
      </SettingInner>
    );
  }, [
    t,
    state.form.settings,
    updateClient
  ]);

  const renderSetting = useCallback((
    settingCategory: string,
    setting: string
  ) => {
    switch(settingCategory) {
      case 'accounting':
        switch(setting) {
          case 'platform':
            const platformOptions: AccountingPlatform[] = state.client.fields.settings.platform.enum;
            const currentPlatform = (state.form.settings as any).accounting.platform;

            return (
              <Fragment key={setting}>
                <SettingOuter>
                  <SettingInner>
                    <SettingHeader>Accounting platform</SettingHeader>
                  </SettingInner>
                  <SettingDescription>Connect your accounting platform to have the invoices generated from new bookings and automatically imported to your accounting software.</SettingDescription>
                  <AdminFormLine marginTopSmall marginBottom>
                    <PlatformOptions>
                      {platformOptions.map((platformOption) => renderPlatformOption(platformOption, currentPlatform))}
                    </PlatformOptions>
                  </AdminFormLine>
                </SettingOuter>
                <SettingOuter key={`settings.defaultVat`}>
                  {renderSwitchSetting(settingCategory, 'defaultVat')}
                  <SettingDescription>Should VAT be applied to new bookings and Services. Turn on if your business is VAT registered.</SettingDescription>
                  {(state.form.settings as any).accounting.defaultVat && (
                    <AdminFormLine marginTopSmall marginBottom>
                      <TextInput
                        label={t('vatNumber')}
                        inputMode="numeric"
                        maxLength={9}
                        onInput={e => extractNumericInput(e)}
                        value={(state.form.settings as any).accounting.vatNumber || ''}
                        error={state.errors.vatNumber}
                        onChange={(e) => {
                          const newVatNumber = e.target.value;

                          setState(prevState => {
                            const newState = {
                              ...prevState,
                              form: {
                                ...prevState.form,
                                settings: {
                                  ...(prevState.form.settings as any),
                                  accounting: {
                                    ...(prevState.form.settings as any).accounting,
                                    vatNumber: newVatNumber
                                  }
                                }
                              }
                            };

                            validateVatNumber(newVatNumber, newState);

                            if (!newVatNumber.length || newVatNumber.length === 9) {
                              updateInvoicingAccountDetails({
                                settings: {
                                  ...newState.form.settings
                                }
                              });
                            }

                            return newState;
                          });

                        }}
                      />
                    </AdminFormLine>
                  )}
                </SettingOuter>
                <SettingOuter>
                  <SettingInner column>
                    <SettingHeader>{t(`invoicing`)}</SettingHeader>
                  </SettingInner>
                  {currentPlatform !== AccountingPlatform.None && (
                    renderSwitchSetting(
                      settingCategory,
                      'externalInvoiceGeneration',
                      <div>{t(`settings.externalInvoiceGeneration`) + ' ' + currentPlatform}</div>
                    )
                  )}
                  <SettingInner column>
                    <AdminFormLine>
                      <Dropdown
                        label={t('dueDate')}
                        options={[
                          createDropdownOption('ASAP', 3),
                          createDropdownOption('7 Days', 7),
                          createDropdownOption('14 Days', 14),
                          createDropdownOption('30 Days', 30),
                          createDropdownOption('60 Days', 60),
                          createDropdownOption('90 Days', 90)
                        ]}
                        value={(state.form.settings as any).accounting.dueDate || 3}
                        onChange={(option: any) => {
                          setState(prevState => {
                            const newState = {
                              ...prevState,
                              form: {
                                ...prevState.form,
                                settings: {
                                  ...(prevState.form.settings as any),
                                  accounting: {
                                    ...(prevState.form.settings as any).accounting,
                                    dueDate: (option && option.value) || 0
                                  }
                                }
                              }
                            };

                            updateInvoicingAccountDetails({
                              settings: {
                                ...newState.form.settings
                              }
                            });

                            return newState;
                          });
                        }}
                      />
                    </AdminFormLine>
                  </SettingInner>
                  {(currentPlatform === AccountingPlatform.None || !(state.form.settings as any)[settingCategory].externalInvoiceGeneration) && (
                    <SettingInner column>
                      <SettingDescription>GoBook'em invoices will be generated and sent to your customers each time a booking is created. Below you can optionally enter the bank details you want to appear on these invoices.</SettingDescription>
                      <AdminFormLine marginTopSmall marginBottom>
                        <TextInput
                          label={t('businessAccountNumber')}
                          inputMode="numeric"
                          maxLength={8}
                          onInput={e => extractNumericInput(e)}
                          value={(state.form.settings as any).accounting.businessAccountNumber || ''}
                          error={state.errors.businessAccountNumber}
                          onChange={(e) => {
                            const newBusinessAccountNumber = e.target.value;

                            setState(prevState => {
                              const newState = {
                                ...prevState,
                                form: {
                                  ...prevState.form,
                                  settings: {
                                    ...(prevState.form.settings as any),
                                    accounting: {
                                      ...(prevState.form.settings as any).accounting,
                                      businessAccountNumber: newBusinessAccountNumber
                                    }
                                  }
                                }
                              };

                              validateInvoicingAccountNumber(newBusinessAccountNumber, newState);

                              if (!newBusinessAccountNumber.length || newBusinessAccountNumber.length === 8) {
                                updateInvoicingAccountDetails({
                                  settings: {
                                    ...newState.form.settings
                                  }
                                });
                              }

                              return newState;
                            });

                          }}
                        />
                      </AdminFormLine>
                      <AdminFormLine marginBottom>
                        <TextInput
                          label={t('businessSortCode')}
                          inputMode="numeric"
                          maxLength={6}
                          onInput={e => extractNumericInput(e)}
                          value={(state.form.settings as any).accounting.businessSortCode || ''}
                          error={state.errors.businessSortCode}
                          onChange={(e) => {
                            const newBusinessSortCode = e.target.value;

                            setState(prevState => {
                              const newState = {
                                ...prevState,
                                form: {
                                  ...prevState.form,
                                  settings: {
                                    ...(prevState.form.settings as any),
                                    accounting: {
                                      ...(prevState.form.settings as any).accounting,
                                      businessSortCode: newBusinessSortCode
                                    }
                                  }
                                }
                              };

                              validateInvoicingSortCode(newBusinessSortCode, newState);

                              if (!newBusinessSortCode.length || newBusinessSortCode.length === 6) {
                                updateInvoicingAccountDetails({
                                  settings: {
                                    ...newState.form.settings
                                  }
                                });
                              }

                              return newState;
                            });
                          }}
                        />
                      </AdminFormLine>
                    </SettingInner>
                  )}
                </SettingOuter>
              </Fragment>
            );
        };
        break;
      case 'jobs':
        switch(setting) {
          case 'fulfillerHandlePayments':
            return (
              <SettingOuter key={setting}>
                {renderSwitchSetting(settingCategory, setting)}
              </SettingOuter>
            );
          case 'defaultFulfillerLabel':
            const options = [
              ...FULFILLER_TYPE_DROPDOWN_OPTIONS,
              ...state.ui.addedFulfillerTypes
            ];

            if (state.client.data) {
              const globalSelectedOption: string = state.client.data?.settings.jobs.defaultFulfillerLabel;
              const optionIndex: number = options.findIndex(o => o.label === globalSelectedOption);

              if (optionIndex === -1) {
                options.push(createDropdownOption(globalSelectedOption));
              }
            }

            options.sort((a, b) => a.label.localeCompare(b.label));

            return (
              <SettingOuter key={`settings.defaultFulfillerLabel`}>
                <SettingInner column>
                  <SettingDescription>This is the default way in which your fulfillers are referred to by your customers. This option can be overriden when creating a service or job.</SettingDescription>
                  {(state.form.settings as any).accounting.defaultVat && (
                    <AdminFormLine marginTopSmall marginBottom>
                      <CreatableDropdown
                        label={t('defaultFulfillerLabel')}
                        width={'22.5rem'}
                        value={(state.form.settings as any).jobs.defaultFulfillerLabel || 'fulfiller'}
                        options={options}
                        onChange={(option: any, actionMeta) => {
                          const value = (option && option.value) || '';

                          if (!value.length) {
                            return;
                          }

                          setState(prevState => {
                            const newState = {
                              ...prevState,
                              form: {
                                ...prevState.form,
                                settings: {
                                  ...(prevState.form.settings as any),
                                  jobs: {
                                    ...(prevState.form.settings as any).jobs,
                                    defaultFulfillerLabel: value
                                  }
                                }
                              }
                            };

                            if (actionMeta.action === 'create-option') {
                              const newOptionExists: boolean = newState.ui.addedFulfillerTypes.findIndex(ft => ft.label === value) !== -1;

                              if (!newOptionExists) {
                                newState.ui.addedFulfillerTypes.push({ ...actionMeta.option as DropdownOption });
                              }
                            }

                            updateInvoicingAccountDetails({
                              settings: {
                                ...newState.form.settings
                              }
                            });

                            return newState;
                          });
                        }}
                      />
                    </AdminFormLine>
                  )}
                </SettingInner>
              </SettingOuter>
            );
        };
        break;
      default:
        return (
          <SettingOuter key={setting}>
            {renderSwitchSetting(settingCategory, setting)}
          </SettingOuter>
        );
    };
  }, [
    t,
    state.form.settings,
    state.client.fields,
    state.client.data,
    state.ui.addedFulfillerTypes,
    state.errors,
    renderPlatformOption,
    renderSwitchSetting,
    validateInvoicingAccountNumber,
    validateInvoicingSortCode,
    validateVatNumber,
    updateInvoicingAccountDetails
  ]);

  const renderTabs = useCallback(() => {
    if (state.client.loading) {
      return (
        <Spinner
          color={theme.textColor}
          size={'M'}
        />
      );
    }

    if (!state.client.data) {
      return null;
    }

    const settingKeys: string[] = Object
      .keys(state.form.settings as any)
      .sort((a, b) => a.localeCompare(b));

    return (
      <Card>
        <Tabs
          activeTab={state.activeSettingsTab}
          tabHasChanged={(activeSettingsTab: string, index: number) => {
            setState(prevState => ({
              ...prevState,
              activeSettingsTab
            }));
          }}
        >
          {
            [
              ...settingKeys.map((settingCategory: string, index: number) => {
                const categorySettings: any = (state.form.settings as any)[settingCategory];

                return (
                  <div
                    key={`${settingCategory}-${index}`}
                    title={t(`settings.${settingCategory}`)}
                  >
                    <AdminFormLine
                      column
                      marginTop
                    >
                      {Object
                        .keys(categorySettings)
                        .map((setting: string) => renderSetting(settingCategory, setting))
                      }
                    </AdminFormLine>
                  </div>
                );
              }),
              <div
                key="settings.about"
                title={t(`settings.about`)}
              >
                <AdminFormLine
                  column
                  marginTop
                >
                  <SettingOuter key="version">
                    <SettingInner>
                      <Text
                        label="Version"
                        value={process.env.REACT_APP_VERSION}
                      />
                    </SettingInner>
                  </SettingOuter>
                  <SettingOuter key="terms">
                    <SettingInner>
                      <Text
                        label="Terms and conditions"
                        value={(
                          <StyledLinkExternal
                            inline
                            href="/terms"
                            target="_blank"
                          >All the legal stuff</StyledLinkExternal>
                        )}
                      />
                    </SettingInner>
                  </SettingOuter>
                  <SettingOuter key="privay">
                    <SettingInner>
                      <Text
                        label="Privacy policy"
                        value={(
                          <StyledLinkExternal
                            inline
                            href="/privacy"
                            target="_blank"
                          >Legal stuff about our data handling</StyledLinkExternal>
                        )}
                      />
                    </SettingInner>
                  </SettingOuter>
                </AdminFormLine>
              </div>
            ]
          }
        </Tabs>
      </Card>
    );
  }, [
    t,
    state.form,
    state.client.loading,
    state.client.data,
    state.activeSettingsTab,
    renderSetting
  ]);

  useEffect(() => {
    if (!state.client.data && !state.client.error) {
      fetchClient();
    }
  }, [
    state.client.data,
    state.client.error,
    fetchClient
  ]);

  return (
    <Wrapper>
      <PageHeader title={'Settings'} />
      {renderTabs()}
    </Wrapper>
  );
};

export default memo(Settings);

