import React, { FC, useCallback, useState, memo } from 'react';

import { PageHeader } from '../../components';

import { AdminPageProps } from 'components/AppRouter';

import { IconButton } from 'theme/mixins';
import {
  Wrapper,
  Card,
  ContentWrapper,
  StyledChevron,
  CardInner,
  Details,
  DetailsOuter,
  DetailsInner
} from './Help.styles';

interface Question {
  _id: number;
  q: string;
  a: string;
};

interface State {
  questions: {
    data: Question[];
    total: number | null;
    expanded: {
      [id: string]: boolean;
    };
  };
}

const initialState: State = {
  questions: {
    data: [
      {
        _id: 0,
        q: 'Do I need to have a website to start using this platform?',
        a: 'No. Once you\'re signed up you can create bookings on behalf of a customer and send them the payment link.'
      },
      {
        _id: 1,
        q: 'Are there anymore charges to be aware of?',
        a: 'Absolutely not. GoBook\'em will only charge the monthly fee of £24.99. There may be asscoiated costs from 3rd party payment vendors that we use such as Stripe, but this falls out of our scope.'
      },
      {
        _id: 2,
        q: 'Am I notified when a booking is submitted or has been paid for?',
        a: 'Yes. As the business owner you will be notified as well as your customer and the person ensuring the booking is fulfilled (the fulfiller).'
      },
      {
        _id: 3,
        q: 'Can I track the progress of a booking?',
        a: 'Yes. All updates made to a booking, including the changes and who made them, are in the Status section at the top of the booking admin page.'
      },
      {
        _id: 4,
        q: 'Can I have multiple Services?',
        a: 'Yes. You can have as many as 10.'
      },
      {
        _id: 5,
        q: 'What is Pricing rules and how do they work?',
        a: 'These are rules that specify the price of a booking based on fields entered. This allows you to set multiple prices for your Services.'
      },
      {
        _id: 6,
        q: 'What is a Service?',
        a: 'A service is the thing that you are offering to your customers. Essentialy it\'s a template of the fields that you require your users to fill in when submitting a booking.'
      },
    ],
    total: null,
    expanded: {}
  },
};

const Help: FC<AdminPageProps> = props => {
  const [state, setState] = useState<State>(initialState);

  const onChevronClick = useCallback((e: any, id: number) => {
    e.stopPropagation();

    setState(prevState => ({
      ...prevState,
      questions: {
        ...prevState.questions,
        expanded: {
          ...prevState.questions.expanded,
          [id]: !prevState.questions.expanded[id]
        }
      }
    }));
  }, []);

  const renderDetails = useCallback((question: Question) => {
    return (
      <>
        <DetailsOuter>
          <DetailsInner groupCount={1}>
            <p>{question.a}</p>
          </DetailsInner>
        </DetailsOuter>
      </>
    );
  }, []);

  const renderQuestions = useCallback(() => {
    return (
      <>
        {state.questions.data.map((question: Question) => {
          return (
            <Card
              key={question._id}
              column
              boxShadow
              centerV
              style={{
                marginBottom: '2rem',
                cursor: 'pointer'
              }}
            >
              <CardInner>
                <ContentWrapper>
                  <IconButton
                    hoverEffect
                    onClick={(e: React.MouseEvent) => onChevronClick(e, question._id)}
                  >
                    <StyledChevron $down={state.questions.expanded[question._id]}/>
                  </IconButton>
                </ContentWrapper>
                <ContentWrapper>
                  <p>{question.q}</p>
                </ContentWrapper>
              </CardInner>
              <Details
                isExpanded={state.questions.expanded[question._id]}
                onClick={(e) => e.stopPropagation()}
              >
                {renderDetails(question)}
              </Details>
            </Card>
          );
        })}
      </>
    );
  }, [
    state.questions,
    onChevronClick,
    renderDetails
  ]);

  return (
    <Wrapper>
      <PageHeader title={'FAQs'}/>
      {renderQuestions()}
    </Wrapper>
  );
};

export default memo(Help);
