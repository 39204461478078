import React, { FC, memo, useState, useCallback } from 'react';
import {
  format,
  getMonth
} from 'date-fns';

import { Event } from 'types/Event';
import { DatePicker } from 'components/atoms';
import { Day } from './';
import { doubleSingleDigit } from 'utils/general';
import { IconButton, StyledChevron } from 'theme/mixins';

import {
  Wrapper,
  StyledHeader
} from './Calendar.styles';

export type EventWithSpan = Event & {
  span: {
    isStart: boolean;
    isMiddle: boolean;
    isEnd: boolean;
    duration: {
      ms: number;
      days: number;
    }
  };
};

interface Props {
  entries: { [key: string]: Array<Event | EventWithSpan> };
  loading: boolean;
  onViewEvent: (event: EventWithSpan, targetElementBoundingRect: { x: number; y: number; width: number; height: number; } | null) => void;
  onSelectDay: (date: Date, events: EventWithSpan[], targetElementBoundingRect: { x: number; y: number; width: number; height: number; } | null) => void;
  onMonthChange: (date: Date) => void;
  closeViewEvent: () => void;
}

interface State {
  selectedDate: Date;
  calendarWeeks: number;
  popupOpen: boolean;
  popupEventId: string | null;
  currentMonth: number;
};

const initialState: State = {
  selectedDate: new Date(),
  calendarWeeks: 0,
  popupOpen: false,
  popupEventId: null,
  currentMonth: getMonth(new Date()) + 1
};

const Calendar: FC<Props> = props => {
  const {
    entries,
    loading,
    onViewEvent,
    closeViewEvent,
    onSelectDay,
    onMonthChange
  } = props;

  const [state, setState] = useState<State>(initialState);

  const onDateChange = useCallback((date: Date) => {
    const selectedMonth: number = getMonth(date) + 1;

    setState(prevState => ({
      ...prevState,
      selectedDate: date,
      ...(prevState.currentMonth !== selectedMonth && {
        currentMonth: selectedMonth
      })
    }));
  }, []);

  const onMonthChangeLocal = useCallback((date: Date) => {
    setState(prevState => ({
      ...prevState,
      currentMonth: getMonth(date) + 1
    }));

    closeViewEvent();
    onMonthChange(date);
  }, [
    closeViewEvent,
    onMonthChange
  ]);

  const onViewEventLocal = useCallback((
    event: EventWithSpan,
    eventDate: Date,
    targetElementBoundingRect: { x: number; y: number; width: number; height: number; } | null
  ) => {
    onViewEvent(event, targetElementBoundingRect);
  }, [onViewEvent]);

  const renderDayContents = useCallback((day: number, date: Date) => {
    // date is source of truth, so extract date from there
    const dateStr = `${date.getFullYear()}-${doubleSingleDigit(date.getMonth() + 1)}-${doubleSingleDigit(date.getDate())}`;
    const mDate: Date = new Date(`${dateStr}T00:00:00.000`);

    // console.log('-----mDate', date, day, mDate.toISOString());

    return (
      <Day
        day={day}
        date={mDate}
        entries={entries[dateStr] || []}
        onViewEvent={(event, eventDate, targetElementBoudingRect) => {
          onViewEventLocal(event, eventDate, targetElementBoudingRect);
        }}
        onSelectDay={onSelectDay}
      />
    );
  }, [
    entries,
    onViewEventLocal,
    onSelectDay
  ]);

  return (
    <Wrapper dayWidth={960 / 7}>
      <DatePicker
        inline
        fullWidth
        focusSelectedMonth
        value={state.selectedDate}
        loading={loading}
        onChange={onDateChange}
        onMonthChange={onMonthChangeLocal}
        renderDayContents={renderDayContents}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <StyledHeader>
            <IconButton
              medium
              hoverEffect
              disabled={prevMonthButtonDisabled}
              onClick={decreaseMonth}
            >
              <StyledChevron $left/>
            </IconButton>

            <h3>{format(date, 'MMMM yyyy')}</h3>

            <IconButton
              medium
              hoverEffect
              disabled={nextMonthButtonDisabled}
              onClick={increaseMonth}
            >
              <StyledChevron />
            </IconButton>
          </StyledHeader>
        )}
      />
    </Wrapper>
  );
};

export default memo(Calendar);

