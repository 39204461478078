import React, { useState, useCallback, useRef, useEffect } from 'react';
import { SketchPicker } from 'react-color';

import { FormField } from '../form';
import {
  ColourPickerWrapper,
  Colour
} from './ColourPicker.styles';
import { BaseProps } from '../form/types';
import { FormFieldProps } from '../form/FormField';

export interface ColourPickerProps extends React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLDivElement>, HTMLDivElement>{
  disableAutoScroll?: boolean;
}

export type Props = ColourPickerProps & BaseProps & FormFieldProps;

const ColourPicker = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    className,
    label,
    error,
    hasChanged,
    disableAutoScroll,
    width,
    widthM,
    widthT,
    widthL,
    noWidth,
    noMinWidth,
    onFocus,
    onBlur,
    ...rest
  } = props;

  const wrapperRef = useRef<HTMLDivElement>(null);
  const [innerValue, setInnerValue] = useState<any>(rest.value || 'FFFFFF');
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [openPicker, setOpenPicker] = useState<boolean>(false);

  const handleColourChange = useCallback((colour: any) => {
    setInnerValue(colour);

    if (rest.onChange) {
      rest.onChange(colour);
    }
  }, [rest]);

  useEffect(() => {
    if (openPicker && !disableAutoScroll) {
      if (wrapperRef.current) {
        wrapperRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [
    openPicker,
    disableAutoScroll
  ]);

  return (
    <FormField
      id={rest.id}
      label={label}
      className={className}
      hasChanged={hasChanged}
      value={rest.value}
      width={width}
      widthM={widthM}
      widthT={widthT}
      widthL={widthL}
      noWidth={noWidth}
      noMinWidth={noMinWidth}
      isFocused={isFocused}
      isFull={String(rest.value as string).length > 0}
      info={rest.info}
      tooltip={rest.tooltip}
      builder={rest.builder}
      endAdornment={rest.endAdornment}
      formFieldStyles={rest.formFieldStyles}
    >
      <>
        <Colour
          {...rest}
          ref={ref}
          value={!!rest.value ? rest.value as string: ''}
          onClick={() => {
            if (!rest.readOnly) {
              setOpenPicker(prevState => !prevState);
            }
          }}
          onFocus={e => {
            if (onFocus) {
              onFocus(e);
            }

            setIsFocused(true);
          }}
          onBlur={e => {
            if (onBlur) {
              onBlur(e);
            }

            setIsFocused(false);
          }}
        />

        {openPicker && (
          <ColourPickerWrapper ref={wrapperRef}>
            <SketchPicker
              color={innerValue}
              disableAlpha={true}
              presetColors={[
                '#1F2227',
                '#D0021B',
                '#F5A623',
                '#F8E71C',
                '#8B572A',
                '#7ED321',
                '#417505',
                '#BD10E0',
                '#9013FE',
                '#4A90E2',
                '#50E3C2',
                '#B8E986',
                '#000000',
                '#4A4A4A',
                '#9B9B9B'
              ]}
              onChange={handleColourChange}
            />
          </ColourPickerWrapper>
        )}
      </>
    </FormField>
  );
});

export default ColourPicker;

