import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { device } from 'theme/media';

import { Menu, PrimaryButton } from 'components/atoms';

export * from 'theme/mixins';

export const StyledPrimaryButton = styled(PrimaryButton)`
  transition: color ${({ theme }) => theme.animDelay} ease-in-out, background ${({ theme }) => theme.animDelay} ease-in-out;
`;

export const HeaderWrapper = styled.header<{
  shouldFloat?: boolean;
  hideHeader?: boolean;
}>`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  align-items: center;
  color: ${({ theme }) => theme.colors.coreSecondary};
  z-index: 1;
  transition: color ${({ theme }) => theme.animDelay} ease-in-out, box-shadow ${({ theme }) => theme.animDelay} ease-in-out, background ${({ theme }) => theme.animDelay} ease-in-out, transform ${({ theme }) => theme.animDelay} ease-in-out;

  svg g path.alt {
    transition: fill ${({ theme }) => theme.animDelay} ease-in-out;
  }

  ${({ hideHeader }) => hideHeader && `
    transform: translateY(-6.9rem);
  `}

  @media ${device.mobile} {
    height: 6.9rem;
    z-index: 2;
    background-position: top;
    background-size: 100% 100vh;
    padding: 2rem 3rem;

    ${({ theme, shouldFloat }) => !shouldFloat && `
      ${StyledPrimaryButton} {
        color: ${theme.colors.neutralSecondary};
        background: ${theme.colors.coreSecondary};
      }
    `}

    ${({ theme, shouldFloat }) => shouldFloat && `
      box-shadow: -.1rem .4rem 2rem -.8rem rgba(0,0,0,.62);
      background: rgba(0, 0, 0, 0.5);
      color: ${theme.colors.neutralSecondary};

      svg g path.alt {
        fill: ${theme.colors.neutralSecondary};
      }
    `}

    :after {
      content: '';
      display: flex;
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      // background-color: hsla(0,0%,0%,0.2);
      z-index: 0;
    }
  }

  @media ${device.tablet} {
    // box-shadow: unset;
    padding: 2rem 4rem;

    :after {
      content: unset; 
    }
  }
`;

export const HeaderItem = styled(Link)`
  position: relative;
  font-weight: 700;
  text-decoration: none;
  z-index: 1;
  color: inherit;

  @media ${device.onlyMobile} {
    button, ${StyledPrimaryButton} {
      border: none;
      background: transparent;
      box-shadow: none;
      color: ${({ theme }) => theme.colors.corePrimary};
    }
  }

  @media ${device.mobile} {
    display: flex;
    font-size: 3rem;
    margin: 2rem 0;
  }

  @media ${device.tablet} {
    display: flex;
    font-size: unset;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    align-items: center;
  }
`;

export const Logo = styled(Link)`
  display: block;
  font-size: 2.5rem;
  font-weight: 700;
  margin-right: auto;
  text-decoration: none;
  color: inherit;
  z-index: inherit;
`;

export const MenuLayer = styled.div<{ isActive: boolean; }>`
  transition: color ${({ theme }) => theme.animDelay} ease-in-out;
  color: inherit;

  @media ${device.onlyMobile} {
    display: flex;
    position: absolute;
    margin-top: 6.9rem;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100vh - 6.9rem);
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: transform 0.25s ease-in-out;
    visibility: hidden;

    :before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      background-color: rgba(255, 255, 255, 0.9);
      flex-direction: column;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: opacity 0.25s ease-in-out;
      z-index: 1;
    }

    ${({ isActive, theme }) =>
      isActive &&
      `
        display: flex;
        visibility: visible;
        color: ${theme.colors.corePrimary};

        :before {
          opacity: 1;
          width: 100%;
          height: 100%;
        }
    `}
  }

  @media ${device.tablet} {
    display: flex;
    position: relative;
    flex-direction: row;
    visibility: visible;
    margin-top: 0;
    width: auto;
    height: auto;
    margin-left: auto;
    justify-content: unset;

    :before {
      display: none;
    }
  }
`;

export const MenuIcon = styled(Menu)`
  transition: color ${({ theme }) => theme.animDelay} ease-in-out;

  @media ${device.mobile} {
    margin-left: auto;
  }

  @media ${device.tablet} {
    display: none;
  }
`;
