import styled from 'styled-components';
import Select from 'react-select'

import { device } from 'theme/media';
import {
  Button,
  Logo
} from 'components/atoms';

import { FormWrapper } from 'theme/mixins';
export * from 'theme/mixins';

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  margin: 0 auto;

  @media ${device.mobile} {
    width: 100%;

    h2 {
      text-align: center;
    }
  }

  @media ${device.mobileL} {
    width: 40rem;
    justify-content: center;

    h2 {
      text-align: left;
    }
  }

  @media ${device.tablet} {
    width: 50rem;
  }
`;

export const InnerWrapper = styled.div`
  display: flex;
  top: 0;
  width: inherit;
  flex-direction: column;

  @media ${device.mobile} {
    text-align: center;

    h2 {
      text-align: left;
    }
  }
`;

export const BackgroundImage = styled.div`
  position: fixed;
  top: 11.9rem;
  left: 0;
  width: 100%;
  height: 45rem;
  background-image: url('/images/test-team-bg-1.png');
  background-size: contain;
  background-position: center;
`;

export const StyledButton = styled(Button)`
  display: flex;
  margin-bottom: 1rem;
  text-transform: uppercase;
`;

export const OptionList = styled.ul`
  display: flex;
`;

export const Option = styled.li<{ active: boolean }>`
  display: flex;
  padding: 1.2rem;
  background-color: ${({ theme }) => theme.option.backgroundColor};
  border: 1px solid ${({ theme }) => theme.option.borderColor};
  margin-right: 1rem;

  &:active {
    background-color: ${({ theme }) => theme.option.active.color};
  }

  ${({ theme, active }) =>
    active &&
    `
      background-color: ${theme.option.active.color};
  `}
`;

export const TextBox = styled.textarea`
  display: flex;
  padding: 1.2rem;
  background-color: ${({ theme }) => theme.option.backgroundColor};
  border: 1px solid ${({ theme }) => theme.option.borderColor};
`;

export const BookingFormWrapper = styled(FormWrapper)`
  text-align: left;

  @media ${device.mobile} {
    padding: .5rem;
  }
`;

export const StyledSelect = styled(Select)<{ fullWidth?: boolean, marginRight?: boolean }>`
  text-align: left;

  @media ${device.mobile} {
    width: 100%;
    margin-left: unset;
    margin-top: 1rem;
  }

  @media ${device.mobileL} {
    // margin-left: 2rem;
    // margin-top: unset;

    ${({ fullWidth }) =>
      !fullWidth &&
      `
        width: 17rem !important;
    `}

    ${({ marginRight }) =>
      marginRight &&
      `
        margin-right: 1rem;
    `}
  }
`;

export const StyledLogo = styled(Logo)`
  width: 2.5rem;
  height: auto;
  margin-right: .5rem;

  g#logo-2 {
    display: none;
  }
`;

export const AccountDetails = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    flex-direction: row;

    div:first-child {
      width: 7rem;
      margin-right: .5rem;
    }
  }
`;

