import styled from 'styled-components';
import { device } from 'theme/media';
import { Link as LinkIcon } from 'components/atoms';

import { Card, FormWrapper  } from 'theme/mixins';
export * from 'theme/mixins';

export const Wrapper = styled.div`
  display: flex;
  width: inherit;
  height: 100%;
  flex-direction: column;
`;

export const StyledCard = styled(Card)`
  width: 50rem;
  margin: 0 auto;
`;

export const LoginFormWrapper = styled(FormWrapper)`
  margin-top: 2rem;
  text-align: left;
`;

export const SettingOuter = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutralPrimaryDark};
`;

export const SettingInner = styled.div<{ column?: boolean; }>`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 1rem;
  padding-top: 1rem;
  align-items: center;

  ${({ column }) => column && `
    flex-direction: column;
    align-items: unset;
  `}
`;

export const SettingDescription = styled.div`
  margin-bottom: 1rem;
  font-size: 1.2rem;
  max-width: 35rem;
`;

export const PlatformLogo = styled.img`
  height: auto;
`;

export const PlatformOption = styled.div<{
  selected?: boolean;
  $disabled?: boolean;
}>`
  display: flex;
  position: relative;
  width: 15rem;
  height: 15rem;
  margin-right: 2rem;
  border: .1rem solid ${({ theme }) => theme.textColor};
  border-radius: 2rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${({ theme, selected }) => selected && `
    border-width: .2rem;
    border-color: ${theme.colors.corePrimary};
  `}

  ${({ theme, $disabled }) => $disabled && `
    filter: grayscale(1);
    border-color: ${theme.colors.neutralPrimaryDarker};
    cursor: unset;
    overflow: hidden;
  `}

  > button {
    position: absolute;

    @media ${device.mobile} {
      right: .5rem;
      bottom: .5rem;
    }

    @media ${device.mobileXL} {
      right: 1rem;
      bottom: 1rem;
    }
  }
`;

export const ComingSoonRibbon = styled.div`
  position: fixed;
  background: #777;
  box-shadow: 0 0 0 999px #777;
  clip-path: inset(0 -100%);
  inset: 0 0 auto auto; /* top and right equal to 0 */
  transform-origin: 0 0; /* OR top left */
  transform: translate(29.3%) rotate(45deg);
  filter: invert(1);
`;

export const PlatformOptions = styled.div`
  display: flex;
  flex-direction: row;

  @media ${device.mobile} {
    flex-direction: column;

    > ${PlatformOption} {
      width: 10rem;
      height: 10rem;
      margin-bottom: 2rem;

      ${ComingSoonRibbon} {
        font-size: .85rem;
      }
    }
  }

  @media ${device.mobileL} {
    flex-direction: row;

    > ${PlatformOption} {
      width: 9rem;
      height: 9rem;
      margin-bottom: unset;

      ${ComingSoonRibbon} {
        font-size: .85rem;
      }
    }
  }

  @media ${device.mobileXL} {
    flex-direction: row;

    > ${PlatformOption} {
      width: 15rem;
      height: 15rem;

      ${ComingSoonRibbon} {
        font-size: unset;
      }
    }
  }
`;

export const StyledLinkIcon = styled(LinkIcon)<{
  $connected?: boolean;
  $error?: boolean;
}>`
  ${({ theme, $connected }) => $connected && `
    fill: ${theme.colors.corePrimary};
  `}

  ${({ theme, $error }) => $error && `
    fill: ${theme.colors.accentTertiary};
  `}
`;

export const SettingHeader = styled.div`
  font-weight: 700;
`;

