import styled from 'styled-components';

export const Wrapper = styled.li<{ isActive: boolean }>`
  display: flex;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 1.75rem 1rem 1.75rem;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  font-weight: 700;
`;
