import { useCallback } from "react";

const useAutoFocus = () => {
  const inputRef = useCallback((inputElement: HTMLElement | null) => {
    setTimeout(() => {
      if (inputElement) {
        inputElement.focus();
      }
    }, 10);
  }, []);

  return inputRef;
};

export default useAutoFocus;
