import React, { FC, memo, useCallback, useState } from 'react';
import { theme } from 'theme';
import {
  Logo,
  Button
} from 'components/atoms';

import {
  HeaderWrapper,
  HeaderItem,
  // Logo,
  MenuLayer,
  MenuIcon,
  StyledLink,
  StyledPrimaryButton,
  LogoOuterWrapper,
  LogoWrapper
} from './HeaderLanding.styles';

const URL_ROOT = '/';
const URL_PRICING = '/#pricing';
const URL_LOGIN = '/login';
const URL_SIGNUP = '/signup';

export interface HeaderLandingProps {
  float: boolean;
  hideHeader?: boolean;
}

const Header: FC<HeaderLandingProps> = props => {
  const {
    float,
    hideHeader
  } = props;

  const [menuActive, setMenuActive] = useState<boolean>(false);

  const onMenuTap = useCallback((isActive: boolean) => {
    setMenuActive(isActive);
  }, []);

  const onMenuItemClick = useCallback(() => {
    setMenuActive(false);
  }, []);

  return (
    <HeaderWrapper
      shouldFloat={float}
      hideHeader={hideHeader}
    >
      <LogoOuterWrapper navOpen={true}>
        <StyledLink to={{ pathname: URL_ROOT }}>
          <LogoWrapper noPadding>
            <Logo />
          </LogoWrapper>
        </StyledLink>
      </LogoOuterWrapper>
      <MenuLayer isActive={menuActive}>
        <HeaderItem to={URL_PRICING} onClick={onMenuItemClick}>
          <span>Pricing</span>
        </HeaderItem>
        <HeaderItem to={URL_LOGIN} onClick={onMenuItemClick}>
          <Button noPadding style={{ marginBottom: 0 }}>Login</Button>
        </HeaderItem>
        <HeaderItem to={URL_SIGNUP} onClick={onMenuItemClick}>
          <StyledPrimaryButton noPadding style={{ marginBottom: 0 }}>Sign up</StyledPrimaryButton>
        </HeaderItem>
      </MenuLayer>
      <MenuIcon
        active={menuActive}
        color={theme.colors.coreSecondary}
        useAltColor={float}
        altColor={theme.colors.corePrimaryLight}
        onTap={onMenuTap}
      />
    </HeaderWrapper>
  );
};

export default memo(Header);
