import styled from 'styled-components';

// import { device } from 'theme/media';
import { Button, Dropdown } from 'components/atoms';

export * from 'theme/mixins';

export const Wrapper = styled.div<{ width: string; hasResults: boolean; }>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};

  > div {
    display: flex;
    width: 100%;
    align-items: flex-end;

    ${({ hasResults }) => hasResults && `
      :first-child {
        margin-bottom: 1.75rem;
      }
    `}
  }
`;

export const StyledButton = styled(Button)`
  margin-left: 2rem;
  margin-bottom: 0;
`;

export const StyledDropdown = styled(Dropdown)``;

export const Message = styled.div<{ error?: boolean; }>`
  margin-top: 1rem;
  font-size: 1.2rem;

  ${({ theme, error }) => error && `
    color: ${theme.colors.accentTertiary};
  `}
`;

