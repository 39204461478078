import React, { FC, useCallback, useState, memo } from 'react';

import {
  OnboardStep,
  OnboardPreference
} from 'types/Client';
import {
  Worker,
  Service as ServiceIcon,
  Billing,
  Dropdown,
  Spinner
} from 'components/atoms';
import { theme } from 'theme';
import { StyledPayments } from 'config/nav';
import {
  StyledLink,
  AdminFormLine
} from 'theme/mixins';
import { AdminPageProps } from 'components/AppRouter';

import {
  Wrapper,
  Steps,
  Step,
  Summary,
  Status,
  ItemIconWrapper,
  StyledTick
} from './OnboardChecklist.styles';

interface OnboardChecklistProps {
  flow: OnboardPreference;
  loading: boolean;
  steps: OnboardStep[] | null;
  complete: boolean;
  hide: boolean;
  addToast: AdminPageProps['addToast'];
  onFlowChange: (flow: OnboardPreference) => void;
}

const OnboardChecklist: FC<OnboardChecklistProps> = props => {
  const {
    flow,
    loading,
    steps,
    complete,
    hide,
    addToast,
    onFlowChange
  } = props;

  const [chosenFlow, setChosenFlow] = useState<OnboardPreference>(flow);

  const getIcon = (step: OnboardStep) => {
    switch (step.icon) {
      case 'workforce-icon':
        return ( <Worker /> ); 
      case 'service-icon':
        return ( <ServiceIcon /> );
      case 'pricing-icon':
        return ( <StyledPayments /> );
      case 'billing-icon':
        return ( <Billing /> );
    };
  };

  const renderIcon = (completed: boolean, step: OnboardStep) => {
    return (
      <ItemIconWrapper>
        {completed ? (
          <StyledTick />
        ) : (
          getIcon(step)
        )}
      </ItemIconWrapper>
    );
  };

  const showStepComment = useCallback((step: OnboardStep) => {
    if (!step.comment || step.disabled) {
      return;
    }

    addToast({
      type: 'info',
      content: step.comment,
      autoRemove: false,
      tag: step.id
    });
  }, [addToast]);

  if (loading) {
    return (
      <Wrapper>
        <Spinner
          color={theme.textColor}
          size={'M'}
        />
      </Wrapper>
    );
  }

  if (!steps || hide) {
    return null;
  }

  const filteredSteps = steps.filter((step: OnboardStep) => step.flow!.includes(chosenFlow));

  return (
    <Wrapper>
      <AdminFormLine
        row
        centerV
        spaceBetween
      >
        {!complete && !hide && (
          <h3 style={{
            marginBottom: 0
          }}>Almost there! Complete the below steps to fully get up and running.</h3>
        )}
        {complete && !hide && (
          <h3 style={{
            marginBottom: 0
          }}>You're all set up! Why not save time when creating jobs by creating a template (Service).</h3>
        )}
        <Dropdown
          options={[
            {
              label: 'Service (Full setup)',
              value: OnboardPreference.Service
            },
            {
              label: 'Generic (Quick start)',
              value: OnboardPreference.Generic
            }
          ]}
          value={chosenFlow}
          onChange={(option: any) => {
            setChosenFlow(option.value || '');

            onFlowChange(option.value);
          }}
          style={{
            marginLeft: '1rem'
          }}
        />
      </AdminFormLine>
      <Steps>
        {filteredSteps.map((step: OnboardStep, index: number) => {
          const completed: boolean = !step.disabled && (step.steps ? step.steps.every(s => !!s.status) : step.status!);

          return (
            <Step
              key={step.name}
              completed={completed}
              isLast={filteredSteps.length - 1 === index}
              $disabled={step.disabled}
              onClick={() => showStepComment(step)}
            >
              {step.link && !step.disabled ? (
                <StyledLink $bare to={step.link}>
                  <Summary>
                    <span>{step.name}</span>
                  </Summary>
                  <Status>
                    {renderIcon(completed, step)}
                  </Status>
                </StyledLink>
              ) : (
                <>
                  <Summary>
                    <span>{step.name}</span>
                  </Summary>
                  <Status>
                    {renderIcon(completed, step)}
                  </Status>
                </>
              )}
            </Step>
          );
        })}
      </Steps>
    </Wrapper>
  );
};

export default memo(OnboardChecklist);
