import styled from 'styled-components';

import { device } from 'theme/media';

import {
  Card,
  FormWrapper,
  Header2
} from 'theme/mixins';
export * from 'theme/mixins';

export const Wrapper = styled.div`
  display: flex;
  width: inherit;
  flex-direction: column;
  align-items: center;
`;

export const StyledCard = styled(Card)`
  margin-top: 5rem;
  margin-bottom: 5rem;

  @media ${device.mobile} {
    width: 100%;
  }

  @media ${device.mobileL} {
    width: 40rem;
  }

  @media ${device.tablet} {
    width: 50rem;
  }
`;

export const StyledFormWrapper = styled(FormWrapper)`
  margin-top: 2rem;
  text-align: left;
`;

export const StyledHeader = styled(Header2)`
  margin-top: 0;
`;

