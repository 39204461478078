import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationEng from './locales/en/translation.json';
import translationEsp from './locales/es/translation.json';
import translationFre from './locales/fr/translation.json';

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    // lng: 'en',
    fallbackLng: 'en', // use en if detected lng is not available

    keySeparator: '.',

    interpolation: {
      escapeValue: false // react already safes from xss
    },

    react: {
      useSuspense: false
    },

    resources: {
      en: {
        translations: translationEng
      },
      es: {
        translations: translationEsp
      },
      fr: {
        translations: translationFre
      }
    },
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations'
  });

export default i18n;
