import React, { FC, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  object,
  string
} from 'yup';
import {
  setHours,
  getHours,
  setMinutes,
  setSeconds,
  setMilliseconds,
  addHours,
  startOfDay,
  endOfDay,
  isBefore,
  format
} from 'date-fns';

import {
  Button,
  PrimaryButton,
  Switch,
  TextInput,
  TextArea,
  Dropdown,
  ModifiedCircle,
  ColourPicker
} from 'components/atoms';
import {
  Popup,
  DateTimeFields
} from 'components/molecules';
import { theme } from 'theme';
import {
  ModalWrapper,
  ModalItem,
  AdminFormLine,
  ModalBorder
} from 'theme/mixins';
import { FulfillerGroup } from 'types/FulfillerGroup';
import { User } from 'types/User';
import { Fulfiller } from 'types/Fulfiller';
import { DropdownOption } from 'types/UI';
import { UserData } from 'types/User';
import {
  createDropdownOption,
  stripZoneFromISOString
} from 'utils/general';
import {
  isFulfiller
} from 'config/privileges';
import { useValidation } from 'components/hooks';

import { EventWithSpan } from '../../../components/Calendar/Calendar';
import { ISO_FORMAT } from '../../../../../../constants';

import {
  EventModalContentWrapper
} from './EventModal.styles';

interface State {
  form: {
    usersAndGroups: Array<string | DropdownOption>;
    [key: string]: any;
  };
  errors: {
    [key: string]: any;
  };
}

const initialState: State = {
  form: {
    usersAndGroups: []
  },
  errors: {}
};

export interface EventModalProps {
  show: boolean;
  event: EventWithSpan | null;
  fields: any;
  fulfillerGroups: FulfillerGroup[] | null;
  admins: User[] | null;
  fulfillers: Fulfiller[] | null;
  user: UserData['user'];
  date?: Date;
  loading?: boolean;
  onClose: () => void;
  onSubmit: (form: State['form'], isEditEvent: boolean, cb: () => void) => void;
}

const eventSchema = object({
  summary: string()
    .required()
    .max(50),
  description: string()
    .nullable()
    .optional()
    .max(500)
});

const EventModal: FC<EventModalProps> = props => {
  const {
    show,
    event,
    fields,
    fulfillerGroups,
    admins,
    fulfillers,
    user,
    date,
    loading,
    onClose,
    onSubmit
  } = props;

  let defaultDateStart;
  let defaultDateEnd;
  let dateStart;
  let dateEnd;

  if (!event) {
    defaultDateStart = setMinutes(new Date(), 0);
    defaultDateStart = setSeconds(defaultDateStart, 0);
    defaultDateStart = setMilliseconds(defaultDateStart, 0);

    defaultDateEnd = addHours(defaultDateStart, 1);
  }

  if (date) {
    dateStart = setHours(date, getHours(new Date()));
    dateStart = setMinutes(dateStart, 0);
    dateStart = setSeconds(dateStart, 0);
    dateStart = setMilliseconds(dateStart, 0);

    dateEnd = addHours(dateStart, 1);
  }

  const [state, setState] = useState<State>({
    ...JSON.parse(JSON.stringify(initialState)),
    form: {
      ...JSON.parse(JSON.stringify(initialState.form)),
      ...(!event && {
        start: defaultDateStart,
        end: defaultDateEnd
      }),
      ...(date && {
        start: dateStart,
        end: dateEnd
      }),
      ...(event && {
        ...event,
        start: new Date(stripZoneFromISOString(event.start)),
        end: new Date(stripZoneFromISOString(event.end))
      })
    }
  });
  const { t } = useTranslation();
  const { errors, validate } = useValidation(eventSchema);

  const allUsersAndGroups = useMemo((): DropdownOption[] => {
    if (!fulfillerGroups || !admins || !fulfillers) {
      return [];
    }

    const stdList: Array<{
      _id: string;
      name: string;
    }> = [];

    [
      ...fulfillerGroups,
      ...admins,
      ...fulfillers
    ].forEach((item: FulfillerGroup | User | Fulfiller) => {
      if ((item as FulfillerGroup).name) {
        stdList.push({
          _id: item._id,
          name: (item as FulfillerGroup).name
        });
      } else {
        stdList.push({
          _id: item._id,
          name: `${(item as Fulfiller | User).firstName} ${(item as Fulfiller | User).lastName}`
        });
      }
    });

    return stdList
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(i => createDropdownOption(i.name, i._id));
  }, [
    fulfillerGroups,
    admins,
    fulfillers
  ]);

  const valueUserItems = useMemo((): DropdownOption[] => {
    if (!state.form.usersAndGroups || !fulfillerGroups || !admins || !fulfillers) {
      return [];
    }

    const convertedItems = state.form.usersAndGroups.map((item: string | DropdownOption) => {
      if (typeof item === 'string') {
        const group: FulfillerGroup | undefined = fulfillerGroups.find(g => g._id === item);

        if (group) {
          return createDropdownOption(group.name, group._id);
        }

        const admin: User | undefined = admins.find(a => a._id === item);

        if (admin) {
          return createDropdownOption(`${admin.firstName} ${admin.lastName}`, admin._id);
        }

        const fulfiller: Fulfiller | undefined = fulfillers.find(f => f._id === item);

        if (fulfiller) {
          return createDropdownOption(`${fulfiller.firstName} ${fulfiller.lastName}`, fulfiller._id);
        }

        return createDropdownOption('Unknown group', '');
      }

      return item;
    });

    let isAllUsersAndGroupsSelected: boolean = !!convertedItems.find(i => i.value === 'all-users-and-groups');

    if (!isAllUsersAndGroupsSelected) {
      isAllUsersAndGroupsSelected = allUsersAndGroups.length === convertedItems.length;
    }

    if (isAllUsersAndGroupsSelected) {
      return [{
        label: 'All users/fulfiller groups',
        value: 'all-users-and-groups'
      }];
    }

    return convertedItems;
  }, [
    fulfillerGroups,
    admins,
    fulfillers,
    state.form.usersAndGroups,
    allUsersAndGroups
  ]);

  const userDropdownItems = useMemo(() => {
    const isAllUsersAndGroupsSelected: boolean = !!valueUserItems.find(i => i.value === 'all-users-and-groups');
    let userItems: DropdownOption[] = [];

    if (isAllUsersAndGroupsSelected) {
      userItems = [
        { ...createDropdownOption('All users/fulfiller groups', 'all-users-and-groups') },
      ];
    } else {
      userItems = [
        { ...createDropdownOption('All users/fulfiller groups', 'all-users-and-groups') },
        ...allUsersAndGroups
      ];
    }

    return userItems;
  }, [
    valueUserItems,
    allUsersAndGroups
  ]);

  const onSubmitLocal = useCallback((cb: () => void) => {
    if (isBefore(state.form.end, state.form.start)) {
      setState(prevState => {
        const newState = { ...prevState };

        newState.errors.end = 'End date cannot be before start date';

        return newState;
      });

      return;
    }
    else if (state.form.end === state.form.start) {
      setState(prevState => {
        const newState = { ...prevState };

        newState.errors.end = 'End date cannot be the same as start date';

        return newState;
      });

      return;
    } else {
      setState(prevState => {
        const newState = { ...prevState };

        delete newState.errors.end;

        return newState;
      });
    }

    validate({
      form: state.form,
      all: true
    })
      .then(() => {
        const isAllUsersAndGroupsSelected: boolean = !!(state.form.usersAndGroups as DropdownOption[]).find(i => i.value === 'all-users-and-groups');
        const usersAndGroups: Array<string | DropdownOption> = isAllUsersAndGroupsSelected ? [ ...allUsersAndGroups ] : [ ...state.form.usersAndGroups ];
        const isMap: boolean = usersAndGroups.length && (usersAndGroups as DropdownOption[])[0].value;
        const usersAndGroupsAsStrings: string[] = isMap ? (usersAndGroups as DropdownOption[]).map(u => u.value) : usersAndGroups;

        const form: State['form'] = {
          ...state.form,
          usersAndGroups: usersAndGroupsAsStrings,
          description: state.form.description || null
        };

        if (!form.colour && !event) {
          form.colour = user?.colour || '#FFFFFF';
        }

        onSubmit(form, !!event, cb);
      });
  }, [
    event,
    state.form,
    allUsersAndGroups,
    user,
    onSubmit,
    validate
  ]);

  if (!show) {
    return null;
  }

  const formKeys = Object
    .keys(fields)
    .filter(k => ![
      'creatorId',
      'status',
      'jobId',
      'jobStatus',
      'jobFulfillerEmail'
    ].includes(k));
  let formGroups: string[] = (valueUserItems as DropdownOption[]).map(g => g.value);
  const allUsersAndGroupsSelected: boolean = formGroups.length === 1 && formGroups[0] === 'all-users-and-groups';
  if (allUsersAndGroupsSelected) {
    formGroups = allUsersAndGroups.map(i => i.value);
  }

  const allUsersDatabaseValue = (event && event.usersAndGroups) || '';
  const usersHasChanged = !!event && (!formGroups.every((id: string) => allUsersDatabaseValue.includes(id)) || formGroups.length !== allUsersDatabaseValue.length);
  const usersAndGroupsHint: string = fields['usersAndGroups'].hint;

  return (
    <Popup
      id={'new-event'}
      layered
      convertable
      noPadding
      maxWidth
      onClose={() => onClose()}
    >
      {({ closePopup }) => {
        return (
          <ModalWrapper>
            <ModalItem>
              <AdminFormLine marginTop>
                <h3 style={{marginBottom: 0}}>
                  {event ? `Edit '${state.form.summary}'` : 'New event'}
                </h3>
              </AdminFormLine>
            </ModalItem>

            <AdminFormLine marginBottom />
            <ModalBorder />
            <AdminFormLine marginBottom />

            <EventModalContentWrapper>
              {!isFulfiller(user!) && (
                <AdminFormLine marginBottom>
                  <Dropdown
                    isMulti
                    width={'100%'}
                    label={t('users')}
                    hasChanged={usersHasChanged}
                    value={valueUserItems}
                    options={userDropdownItems}
                    placeholder={usersAndGroupsHint}
                    onChange={(items: any) => {
                      setState(prevState => ({
                        ...prevState,
                        form: {
                          ...prevState.form,
                          usersAndGroups: items
                        }
                      }));
                    }}
                  />
                </AdminFormLine>
              )}

              {formKeys.map((key: string, index: number) => {
                const fieldItem = fields[key];
                const value = state.form[key] || '';
                const databaseValue = (event && (event as any)[key]) || '';
                let hasChanged: boolean = !!event && value !== databaseValue;

                let elem: React.ReactNode;

                switch (fieldItem.type) {
                  case 'boolean':
                    if (key === 'isAllDay') {
                      elem = (
                        <Switch
                          value={value}
                          label={t(key)}
                          hasChanged={hasChanged}
                          onChange={(newState: boolean) => {
                            const isAllDay: boolean = newState;
                            const currentStart: Date = state.form.start;
                            const currentEnd: Date = state.form.end;

                            let newCurrentStart: Date = currentStart;
                            let newCurrentEnd: Date = currentEnd;

                            if (!isAllDay && !event?.start && !event?.end) {
                              newCurrentStart = setHours(currentStart, getHours(new Date()));
                              newCurrentStart = setMinutes(newCurrentStart, 0);
                              newCurrentStart = setSeconds(newCurrentStart, 0);
                              newCurrentStart = setMilliseconds(newCurrentStart, 0);

                              newCurrentEnd = addHours(newCurrentStart, 1);
                            }

                            const newStart: Date = isAllDay ? startOfDay(currentStart) : event?.start ? new Date(stripZoneFromISOString(event.start)) : newCurrentStart;
                            const newEnd: Date = isAllDay ? endOfDay(currentEnd) : event?.end ? new Date(stripZoneFromISOString(event.end)) : newCurrentEnd;

                            setState(prevState => ({
                              ...prevState,
                              form: {
                                ...prevState.form,
                                [key]: isAllDay,
                                start: newStart,
                                end: newEnd
                              },
                              errors: {}
                            }));
                          }}
                        />
                      );
                    } else {
                      elem = (
                        <Switch
                          value={value}
                          label={t(key)}
                          hasChanged={hasChanged}
                          onChange={(newState: boolean) => {
                            setState(prevState => ({
                              ...prevState,
                              form: {
                                ...prevState.form,
                                [key]: newState
                              }
                            }));
                          }}
                        />
                      );
                    }
                    break;
                  case 'string':
                    if (key === 'colour') {
                      elem = (
                        <ColourPicker
                          hasChanged={hasChanged}
                          value={value || (!event && user?.colour)}
                          label={t(key)}
                          onChange={(colour: any) => {
                            setState(prevState => ({
                              ...prevState,
                              form: {
                                ...prevState.form,
                                [key]: colour.hex
                              }
                            }));
                          }}
                        />
                      );
                    } else {
                      elem = (
                        <TextInput
                          width={'100%'}
                          value={value}
                          label={t(key)}
                          error={errors[key]}
                          hasChanged={hasChanged}
                          onChange={(e) => {
                            const localValue = e.target.value;

                            setState(prevState => ({
                              ...prevState,
                              form: {
                                ...prevState.form,
                                [key]: localValue
                              }
                            }));
                          }}
                          onKeyUp={(e) => {
                            validate({
                              form: state.form,
                              field: key
                            });

                            if (e.key === 'Enter') {
                              onSubmitLocal(closePopup);
                            }
                          }}
                          onBlur={() => validate({
                            form: state.form,
                            field: key
                          })}
                        />
                      );
                    }
                    break;
                  case 'datetime':
                    const val = value ? format(value, ISO_FORMAT) : '';
                    hasChanged = !!event && val !== databaseValue;

                    elem = (
                      <AdminFormLine
                        column
                        fullWidth
                      >
                        {key === 'start' && (
                          <h4>
                            <span>Start</span>
                            {hasChanged && (
                              <ModifiedCircle leftMargin />
                            )}
                          </h4>
                        )}
                        {key === 'end' && (
                          <h4>
                            <span>End</span>
                            {hasChanged && (
                              <ModifiedCircle leftMargin />
                            )}
                          </h4>
                        )}
                        <DateTimeFields
                          width={'100%'}
                          value={value || undefined}
                          error={(key === 'end' && state.errors.end) || ''}
                          dateOnly={state.form.isAllDay}
                          timeDisabled={state.form.timeDisabled}
                          onChange={(datetime: Date | string[]) => {
                            setState(prevState => {
                              const start = datetime as Date;
                              const end = datetime as Date;

                              const newState = {
                                ...prevState,
                                form: {
                                  ...prevState.form,
                                  ...(key === 'start' && {
                                    start,
                                    end: addHours(end, 1),
                                  }),
                                  ...(key === 'end' && {
                                    end
                                  })
                                }
                              };

                              return newState;
                            });
                          }}
                        />
                      </AdminFormLine>
                    );
                    break;
                  case 'long-string':
                    elem = (
                      <TextArea
                        width={'100%'}
                        value={value}
                        label={t(key)}
                        error={errors[key]}
                        hasChanged={hasChanged}
                        onChange={(e) => {
                          const localValue = e.target.value;

                          setState(prevState => ({
                            ...prevState,
                            form: {
                              ...prevState.form,
                              [key]: localValue
                            }
                          }));
                        }}
                        onKeyUp={() => validate({
                          form: state.form,
                          field: key
                        })}
                        onBlur={() => validate({
                          form: state.form,
                          field: key
                        })}
                      />
                    );
                    break;
                }

                if (!elem) {
                  return null;
                }

                return (
                  <AdminFormLine key={index} marginBottom>
                    {elem}
                  </AdminFormLine>
                );
              })}
            </EventModalContentWrapper>

            <AdminFormLine marginBottom />
            <ModalBorder />
            <AdminFormLine marginBottom />

            <ModalItem>
              <AdminFormLine right>
                <Button
                  type={'button'}
                  onClick={() => {
                    closePopup();
                  }}
                  style={{ marginRight: '1rem' }}
                >Cancel</Button>
                <PrimaryButton
                  type={'submit'}
                  loading={loading}
                  spinnerColor={theme.colors.coreSecondary}
                  onClick={() => onSubmitLocal(closePopup)}
                >{event ? 'Update' : 'Create'} event</PrimaryButton>
              </AdminFormLine>
            </ModalItem>
          </ModalWrapper>
        );
      }}
    </Popup>
  );
};

export default EventModal;

