import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { device } from 'theme/media';
import {
  Plus,
  MenuDots,
  Chevron,
  Payments,
  Text,
  NoData,
  Refund,
  Clock,
  Bin2
} from 'components/atoms';

export const StyledPlus = styled(Plus)`
  width: 1.3rem;
  height: 1.3rem;
  fill: ${({ theme }) => theme.textColor};
`;

export const StyledRefund = styled(Refund)`
  width: 2rem;
  height: 2rem;
  fill: ${({ theme }) => theme.textColor};
`;

export const StyledClock = styled(Clock)`
  width: 2rem;
  height: 2rem;
  fill: ${({ theme }) => theme.textColor};
`;

export const StyledBinIcon = styled(Bin2)`
  width: 1.5rem;
  height: 1.5rem;
`;

// eslint-disable-next-line
export const Card = styled.div<{
  transparent?: boolean;
  fixedHeight?: boolean;
  column?: boolean;
  row?: boolean;
  noPadding?: boolean;
  left?: boolean;
  right?: boolean;
  fullWidth?: boolean;
  noMediaQ?: boolean;
  sticky?: boolean;
  boxShadow?: boolean;
  borderRadius?: boolean;
  hSpaceBetween?: boolean;
  centerH?: boolean;
  centerV?: boolean;
  isOver?: boolean;
  pointer?: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 3rem;
  border: 0.1rem solid ${({ theme }) => theme.card.borderColor};
  transition: background-color ${({ theme }) => theme.animSpeed} ease-in-out, border ${({ theme }) => theme.animSpeed} ease-in-out;
  background-color: ${({ theme }) => theme.colors.neutralSecondary};

  @media ${device.onlyMobile} {
    padding: 2rem;
  }

  ${({ sticky }) => sticky && `
    position: sticky;
    top: 4rem;
  `};

  ${({ noPadding }) => noPadding && `
    padding: 0;

    @media ${device.onlyMobile} {
      padding: 0;
    }
  `}

  ${({ column }) =>
    column &&
    `
    flex-direction: column;
  `}

  ${({ row }) =>
    row &&
    `
    flex-direction: row;
  `}

  ${({ left }) =>
    left &&
    `
    justify-content: flex-start;
    align-items: flex-start;
  `}

  ${({ right }) =>
    right &&
    `
    justify-content: flex-end;
    align-items: flex-end;
  `}

  ${({ fixedHeight }) =>
    fixedHeight &&
    `
      // height: 44.5rem;
  `}

  ${({ transparent, theme }) =>
    transparent &&
    `
      background-color: ${theme.form.backgroundTransparent};
      color: ${theme.colors.corePrimary};
  `}

  ${({ boxShadow, theme }) => boxShadow && `
    box-shadow: rgb(60 66 87 / 8%) 0px 3px 9px 0px;

    :hover {
      box-shadow: rgb(60 66 87 / 8%) 0px 6px 19px 0px;
    }
  `}

  ${({ borderRadius }) => borderRadius && `
    border-radius: 1.5rem;
  `};

  ${({ hSpaceBetween }) => hSpaceBetween && `
    justify-content: space-between;
  `};

  ${({ centerV, row }) => centerV && row && `
    align-items: center;
  `}

  ${({ theme, isOver }) => isOver && `
    background: ${theme.colors.corePrimary}44;
  `}

  ${({ pointer }) => pointer && `
    cursor: pointer;
  `}
`;

export const FormWrapper = styled.form<{ fullHeight?: boolean, noOverflow?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;

  ${({ noOverflow }) =>
    noOverflow &&
    `
      overflow: visible;
  `}

  ${({ fullHeight }) =>
    fullHeight &&
    `
      height: 100%;
  `}

  @media ${device.mobile} {
    padding: 1.25rem;
  }

  @media ${device.mobileL} {
    padding: 1rem;
  }
`;

export const AdminFormWrapper = styled.form<{ fullHeight?: boolean, noOverflow?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;

  ${({ noOverflow }) =>
    noOverflow &&
    `
      overflow: visible;
  `}

  ${({ fullHeight }) =>
    fullHeight &&
    `
      height: 100%;
  `}
`;

// eslint-disable-next-line
export const FormLine = styled.div<{
  header?: boolean;
  width?: string;
  left?: boolean;
  right?: boolean;
  center?: boolean;
  centerH?: boolean;
  centerV?: boolean;
  marginTop?: boolean;
  marginBottom?: boolean;
  column?: boolean;
  row?: boolean;
  fullHeight?: boolean;
  topPadding?: boolean;
  spaceBetween?: boolean;
}>`
  display: flex;

  ${({ width }) =>
    width &&
    `
      width: ${width};
  `}

  ${({ header, theme }) =>
    header &&
    `
      padding-bottom: 2rem;
      border-bottom: .1rem solid ${theme.colors.corePrimary};
  `}

  ${({ left }) =>
    left &&
    `
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
  `}

  ${({ right }) =>
    right &&
    `
    justify-content: flex-end;
  `}

  ${({ center }) =>
    center &&
    `
    justify-content: center;
  `}

  ${({ centerV, row }) => centerV && row && `
    align-items: center;
  `}

  ${({ marginTop }) =>
    marginTop &&
    `
      margin-top: 1rem;
  `}

  ${({ marginBottom }) =>
    marginBottom &&
    `
      margin-bottom: 1.75rem;
  `}

  ${({ column }) =>
    column &&
    `
    flex-direction: column;
  `}

  ${({ row }) =>
    row &&
    `
    flex-direction: row;
  `}

  ${({ fullHeight }) =>
    fullHeight &&
    `
      height: 100%;
  `}

  ${({ topPadding }) =>
    topPadding &&
    `
    padding-top: 2rem;
  `}

  ${({ spaceBetween }) =>
    spaceBetween &&
    `
    justify-content: space-between;
  `}
`;

// eslint-disable-next-line
export const AdminFormLine = styled.div<{
  header?: boolean;
  width?: string;
  left?: boolean;
  right?: boolean;
  center?: boolean;
  centerH?: boolean;
  centerV?: boolean;
  marginTop?: boolean;
  marginBottom?: boolean;
  marginTopSmall?: boolean;
  marginBottomSmall?: boolean;
  column?: boolean;
  row?: boolean;
  fullWidth?: boolean;
  fullHeight?: boolean;
  topPadding?: boolean;
  spaceBetween?: boolean;
  inheritWidth?: boolean;
  widthM?: string;
  widthT?: string;
  widthL?: string;
}>`
  display: flex;

  ${({ width }) => width && `
      width: ${width};
  `}

  ${({ inheritWidth }) => inheritWidth && `
      width: inherit;
  `}

  ${({ header, theme }) => header && `
      padding-bottom: 2rem;
      border-bottom: .1rem solid ${theme.colors.corePrimary};
  `}

  ${({ left }) => left && `
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
  `}

  ${({ right }) => right && `
    justify-content: flex-end;
  `}

  ${({ center }) => center && `
    justify-content: center;
  `}

  ${({ centerV, row }) => centerV && row && `
    align-items: center;
  `}

  ${({ centerH, column }) => centerH && column && `
    align-items: center;
  `}

  ${({ marginTop }) => marginTop && `
    margin-top: 1.75rem;
  `}

  ${({ marginBottom }) => marginBottom && `
    margin-bottom: 1.75rem;
  `}

  ${({ marginTopSmall }) => marginTopSmall && `
    margin-top: 1rem;
  `}

  ${({ marginBottomSmall }) => marginBottomSmall && `
    margin-bottom: 1rem;
  `}

  ${({ column }) => column && `
    flex-direction: column;
  `}

  ${({ row }) => row && `
    flex-direction: row;
  `}

  ${({ fullWidth }) => fullWidth && `
    width: 100%;
  `}

  ${({ fullHeight }) => fullHeight && `
    height: 100%;
  `}

  ${({ topPadding }) => topPadding && `
    padding-top: 2rem;
  `}

  ${({ spaceBetween }) => spaceBetween && `
    justify-content: space-between;
  `}

  @media ${device.mobile} {
    ${({ widthM }) => widthM && `
      width: ${widthM};
    `}
  }

  @media ${device.tablet} {
    ${({ widthT }) => widthT && `
      width: ${widthT};
    `}
  }

  @media ${device.laptop} {
    ${({ widthL }) => widthL && `
      width: ${widthL};
    `}
  }
`;

export const ErrorMsgWrapper = styled.p`
  display: flex;
  width: 100%;
  color: ${({ theme }) => theme.colors.accentTertiary};
`;

export const Header = styled.h2`
  margin-bottom: 2rem;
`;

export const Header2 = styled.h2`
  font-size: 2.8rem;
  margin-top: 3rem;
  margin-bottom: 2rem;
  font-weight: normal;
`;

export const AdminHeader = styled.h2`
  margin-bottom: .5rem;
`;

export const IconButton = styled.button<{
  hoverEffect?: boolean;
  hoverEffectAlwaysOn?: boolean;
  medium?: boolean;
  inheritWidth?: boolean;
  inheritHeight?: boolean;
  marginLeft?: boolean;
}>`
  display: flex;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  transition: box-shadow ${({ theme }) => theme.animSpeed}, border ${({ theme }) => theme.animSpeed} ease-in-out;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;

  // monitor
  flex-shrink: 0;

  ${({ medium }) => medium && `
    width: 4rem;
    height: 4rem;
  `}

  ${({ inheritWidth }) => inheritWidth && `
    width: inherit;
  `}

  ${({ inheritHeight }) => inheritHeight && `
    height: inherit;
  `}

  ${({ marginLeft }) => marginLeft && `
    margin-left: 1rem;
  `}

  ${({ theme, hoverEffect }) => hoverEffect && `
    border: 0.1rem solid transparent;

    :hover {
      box-shadow: rgb(60 66 87 / 8%) 0px 3px 9px 0px;
      border: 0.1rem solid ${theme.card.borderColor};
    }
  `}

  ${({ theme, hoverEffectAlwaysOn }) => hoverEffectAlwaysOn && `
    box-shadow: rgb(60 66 87 / 8%) 0px 3px 9px 0px;
    border: 0.1rem solid ${theme.card.borderColor};
  `}

  :disabled {
    cursor: unset;

    :hover {
      border-color: ${({ theme }) => theme.colors.neutralPrimaryDark3};
      box-shadow: none;
    }

    svg {
      fill: ${({ theme }) => theme.button.disabled.color};
    }
  }
`;

export const IconWrapper = styled.div<{
  hoverEffect?: boolean;
  hoverEffectAlwaysOn?: boolean;
  medium?: boolean;
  inheritWidth?: boolean;
  inheritHeight?: boolean;
  marginLeft?: boolean;
}>`
  display: flex;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  transition: box-shadow ${({ theme }) => theme.animSpeed}, border ${({ theme }) => theme.animSpeed} ease-in-out;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;

  // monitor
  flex-shrink: 0;

  ${({ medium }) => medium && `
    width: 4rem;
    height: 4rem;
  `}

  ${({ inheritWidth }) => inheritWidth && `
    width: inherit;
  `}

  ${({ inheritHeight }) => inheritHeight && `
    height: inherit;
  `}

  ${({ marginLeft }) => marginLeft && `
    margin-left: 1rem;
  `}

  ${({ theme, hoverEffect }) => hoverEffect && `
    border: 0.1rem solid transparent;

    :hover {
      box-shadow: rgb(60 66 87 / 8%) 0px 3px 9px 0px;
      border: 0.1rem solid ${theme.card.borderColor};
    }
  `}

  ${({ theme, hoverEffectAlwaysOn }) => hoverEffectAlwaysOn && `
    box-shadow: rgb(60 66 87 / 8%) 0px 3px 9px 0px;
    border: 0.1rem solid ${theme.card.borderColor};
  `}
`;

export const StyledMenuDots = styled(MenuDots)`
  fill: ${({ theme }) => theme.colors.corePrimaryDarker};
  transform: rotateZ(90deg);
`;

export const StyledChevron = styled(Chevron)<{
  $left?: boolean;
  $right?: boolean;
  $down?: boolean;
  $up?: boolean;
  $noFill?: boolean;
}>`
  fill: ${({ theme }) => theme.colors.corePrimaryDarker};
  width: 100%;
  height: auto;
  transition: transform ${({ theme }) => theme.animSpeed} ease-in-out;
  transform: rotateZ(-90deg);

  ${({ $noFill }) => $noFill && `
    fill: unset;
  `};

  ${({ $left }) => $left && `
    transform: rotateZ(90deg);
  `};

  ${({ $right }) => $right && `
    transform: rotateZ(-90deg);
  `};

  ${({ $down }) => $down && `
    transform: rotateZ(0deg);
  `};

  ${({ $up }) => $up && `
    transform: rotateZ(180deg);
  `};
`;

export const StyledLink = styled(Link)<{
  $bare?: boolean;
  $bold?: boolean;
}>`
  color: ${({ theme }) => theme.textColor};

  ${({ $bare }) => $bare && `
    text-decoration: none;
  `};

  ${({ $bare, theme }) => !$bare && `
    text-decoration: underline;
    text-decoration-color: ${theme.colors.corePrimaryDarker};
    text-decoration-thickness: .25rem;
  `};

  ${({ $bold }) => $bold && `
    font-weight: bold;
  `};
`;

export const StyledLinkExternal = styled.a<{
  bold?: boolean;
  noIcon?: boolean;
  inline?: boolean;
}>`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.textColor};
  text-decoration: underline;
  text-decoration-color: ${({ theme }) => theme.colors.corePrimaryDarker};
  text-decoration-thickness: .25rem;

  ${({ bold }) => bold && `
    font-weight: bold;
  `};

  ${({ noIcon }) => !noIcon && `
    &:after {
      content: '';
      display: inline-block;
      width: 1.5rem;
      height: 1.5rem;
      margin-left: 0.5rem;
      background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjRweCIgdmlld0JveD0iMCAwIDI0IDI0IiB3aWR0aD0iMjRweCIgZmlsbD0iIzAwMDAwMCI+PHBhdGggZD0iTTAgMGgyNHYyNEgwVjB6IiBmaWxsPSJub25lIi8+PHBhdGggZD0iTTE5IDE5SDVWNWg3VjNINWMtMS4xMSAwLTIgLjktMiAydjE0YzAgMS4xLjg5IDIgMiAyaDE0YzEuMSAwIDItLjkgMi0ydi03aC0ydjd6TTE0IDN2MmgzLjU5bC05LjgzIDkuODMgMS40MSAxLjQxTDE5IDYuNDFWMTBoMlYzaC03eiIvPjwvc3ZnPg==);
      background-repeat: no-repeat;
      background-size: cover;
    }
  `};

  ${({ inline }) => inline && `
    display: inline;

    &:after {
      position: relative;
      top: .3rem;
    }
  `};
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  max-height: 100vh;
`;

export const ModalItem = styled.div<{ applyBottomMarginToLastChild?: boolean; }>`
  padding: 0 2rem;

  ${({ applyBottomMarginToLastChild }) => applyBottomMarginToLastChild && `
    &:last-child {
      margin-bottom: 1.75rem;
    }
  `}
`;

export const ModalBorder = styled.div`
  display: flex;
  border-bottom: .1rem solid ${({ theme }) => theme.colors.neutralPrimaryDark};
`;

export const JobStatus = styled.div<{ state: number; }>`
  display: flex;
  border: .1rem solid transparent;
  padding: 1rem;
  border-radius: 1.25rem;
  font-weight: 700;

  ${({ theme, state }) => state === 0 && `
    background-color: ${theme.colors.neutralPrimary};
    border: .2rem solid ${theme.colors.neutralPrimaryDark1};
  `}

  ${({ theme, state }) => state === 1 && `
    background-color: ${theme.colors.neutralPrimary};
    border: .2rem solid ${theme.colors.accentSecondaryLight};
  `}

  ${({ theme, state }) => state === 2 && `
    background-color: ${theme.colors.accentSecondaryLight};
  `}

  ${({ theme, state }) => state === 3 && `
    background-color: ${theme.colors.neutralPrimary};
    border: .2rem solid ${theme.colors.accentTertiary};
  `}

  ${({ theme, state }) => state === -1 && `
    background-color: ${theme.colors.neutralPrimary};
    border: .2rem solid ${theme.colors.neutralPrimaryDark1};
  `}
`;

export const StyledPayments = styled(Payments)<{ $state: boolean; }>`
  transform: rotateZ(45deg);

  ${({ theme, $state }) => !$state && `
    fill: ${theme.colors.neutralPrimaryDark1};
  `}
`;

export const ImportantPriceText = styled(Text)`
  flex: 1;
  font-size: 3rem;
`;

export const LogoOuterWrapper = styled.div<{ navOpen: boolean }>`
  display: flex;
  position: sticky;
  top: 0;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  z-index: ${({ theme }) => theme.layers.lowest};

  span {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.corePrimary};
  }

  svg {
    g[id='logo-2'] {
      transition: all ${({ theme }) => theme.animSpeed} ease-in-out;

      ${({ navOpen }) => !navOpen && `
        transform: translate(-50rem, 0px);
      `};
    }
  }
`;

export const LogoWrapper = styled.div<{ noPadding?: boolean; background?: boolean; }>`
  display: flex;
  width: ${({ theme }) => theme.logo.width / 10}rem;
  justify-content: center;
  flex-shrink: 0;

  ${({ noPadding }) => !noPadding && `
    padding: 2rem 1.6rem;
  `}

  ${({ background, theme }) => background && `
    background-color: ${theme.colors.neutralSecondary};
  `}
`;

export const HR = styled.hr<{ $alt?: boolean; }>`
  width: 100%;
  border: none;
  border-top: .1rem solid ${({ $alt, theme }) => !$alt ? theme.colors.corePrimary : theme.colors.neutralPrimaryDark1};
  border-bottom: .1rem solid transparent;
`;

export const PreviewPane = styled.div`
  position: sticky;
  top: .4rem;
  height: inherit;
  // margin-bottom: 5rem;
  margin-bottom: 2rem;

  @media ${device.onlyMobile} {
    width: 100%;
    height: 100%;
  }
`;

export const Device = styled.div`
  @media ${device.onlyMobile} {
    width: 100%;
    height: 100%;
  }

  @media ${device.tablet} {
    position: relative;
    height: 98vh;
    max-height: 64rem;
    margin-left: 2rem;
    background: #000000;
    overflow: hidden;

    &:before {
      content: "";
      position: absolute;
      left: 50%;
      top: 3rem;
      width: 6rem;
      height: 1rem;
      border-radius: 1rem;
      margin-left: -3rem;
      background: #333333;
    }

    border-radius: 3.5rem;
    box-shadow: 0 0 0 .2rem #AAAAAA;
    box-sizing: content-box;

    &:after {
      content: "";
      position: absolute;
      left: 50%;
      width: 7rem;
      height: 3rem;
      margin-left: -3.5rem;
      bottom: 1.8rem;
      border-radius: 2rem;
      box-sizing: border-box;
      border: .3rem solid #333333;
    }
  }
`;

export const StyledFrame = styled.iframe<{ inline?: boolean; }>`
  display: flex;
  border: none;

  @media ${device.onlyMobile} {
    width: 100%;
    height: 100%;

    ${({ inline }) => inline && `
      height: 100vh;
    `}
  }

  @media ${device.tablet} {
    width: calc(100% - 4rem);
    height: calc(100% - 13rem);
    padding: 6.5rem 2rem;
  }
`;

export const FrameSpinner = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: ${({ theme }) => theme.layers.highest};
`;

export const StyledHR = styled(HR)`
  border-right: 0.1rem solid ${({ theme }) => theme.colors.neutralPrimaryDark1};
  width: 0.1rem;
  border-bottom: none;
`;

export const ModalContentWrapper = styled(ModalItem)<{ maxWidth?: boolean; }>`
  overflow-y: auto;

  @media ${device.mobile} {
    max-height: 35rem;
  }

  @media ${device.tablet} {
    min-width: 40.4rem;

    ${({ maxWidth }) => maxWidth && `
      max-width: 75rem;
    `}
  }
`;

export const MenuWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.neutralSecondary};
`;

export const MenuItem = styled.li<{ disabled?: boolean; forMobile?: boolean; }>`
  display: flex;
  flex-direction: column;
  cursor: pointer;

  ${({ theme, disabled }) => disabled && `
    color: ${theme.baseInput.disabled.color};
    cursor: unset;
  `};

  ${({ forMobile }) => forMobile && `
    display: none;

    @media ${device.onlyMobile} {
      display: flex;
    }
  `};

  @media ${device.mobile} {
    padding: 1rem 0;
  }

  @media ${device.tablet} {
    padding: .5rem 0;
  }
`;

export const NoDataIcon = styled(NoData)`
  width: 12rem;
  height: 100%;
  margin-bottom: 2rem;
`;

export const NoDataCaption = styled.span`
  font-weight: 700;

  @media ${device.mobile} {
    max-width: 26rem;
  }
`;

export const StatNumber = styled.div<{
  pdDirection?: number;
  caption?: boolean;
  small?: boolean;
}>`
  display: flex;
  flex-direction: row;

  ${({ theme, pdDirection, caption, small }) => ((pdDirection! >= -1 && pdDirection! <= 1) || caption) && `
    span:last-child:not(:only-child) {
      color: ${theme.textColor};
      margin-left: 1rem;
      padding-top: .5rem;
      font-size: 1.4rem;
      font-weight: bold;
      opacity: 0;
      flex-shrink: 0;
      flex-grow: 0;
      animation: stat-pd .75s 1.25s 1 normal forwards;

      ${pdDirection === 1 ? `
        color: ${theme.colors.accentSecondary};
      ` : ''}

      ${pdDirection === -1 ? `
        color: ${theme.colors.accentTertiary};
      ` : ''}

      ${small ? `
        padding-top: .2rem;
        font-size: 1.2rem;
      ` : ''}
    }
  `}

  @media ${device.mobile} {
    font-size: 3rem;

    ${({ small }) => small && `
      font-size: 2.25rem;
    `}
  }

  @media ${device.laptop} {
    font-size: 4rem;

    ${({ small }) => small && `
      font-size: 2.25rem;
    `}
  }
`;

export const EventCancelledWrapper = styled.s<{ column?: boolean; }>`
  display: flex;

  ${({ column }) => column && `
    flex-direction: column;
  `}
`;

