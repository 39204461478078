import { theme as gTheme } from 'theme';

export const selectStyles = (isFocused?: boolean, error?: boolean) => ({
  container: (provided: any) => ({
    ...provided,
    width: '100%'
  }),
  control: (provided: any, styleState: any) => {
    return {
      ...provided,
      height: '100%',
      boxShadow: 'none',
      border: `1px solid ${styleState.menuIsOpen || isFocused ? gTheme.option.active.color : gTheme.option.borderColor}`,
      borderRadius: '.4rem',
      transition: `border ${gTheme.animSpeed}`,
      '&:focus, &:active, &:hover': {
        borderColor: styleState.isFocused ? gTheme.option.active.color : ''
      },
      ...(error && {
        border: `1px solid ${gTheme.colors.accentTertiary}`,
        '&:focus, &:active, &:hover': {
          borderColor: gTheme.colors.accentTertiary
        },
      })
    };
  },
  menu: (provided: any, styleState: any) => ({
    ...provided,
    zIndex: gTheme.layers.highest
  }),
  menuPortal: (provided: any, styleState: any) => ({
    ...provided,
    zIndex: gTheme.layers.highest
  }),
  singleValue: (provided: any, styleState: any) => ({
    ...provided,
  })
});

