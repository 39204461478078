import React from 'react'

import { IconProps } from './types';

type Props = React.DetailedHTMLProps<React.DOMAttributes<SVGSVGElement>, SVGSVGElement> &
  IconProps;

const Building = React.forwardRef<SVGSVGElement, Props>((props, ref) => {
  const { className, ...rest } = props;

  return (
    <svg
      {...rest}
      ref={ref}
      className={props.className}
      width="993.73022"
      height="422.32217"
      viewBox="0 0 993.73022 422.32217"
    >
      <polygon points="380.33884 279.58173 383.23392 356.30087 376.18716 356.30087 376.18716 364.98608 395.88272 364.98608 395.88272 356.30087 390.13818 356.30087 390.54849 279.58173 380.33884 279.58173" fill="#3f3d56"/>
      <path d="M372.73932,343.99686h-.00003c2.99792,0,5.42825,2.43033,5.42825,5.42825v22.43671c0,2.99792-2.43033,5.42825-5.42825,5.42825h.00003c-2.99792,0-5.42825-2.43033-5.42825-5.42825v-22.43671c0-2.99792,2.43033-5.42825,5.42825-5.42825Z" fill="#3f3d56"/>
      <path d="M400.2424,343.99686h-.00003c2.99792,0,5.42825,2.43033,5.42825,5.42825v22.43671c0,2.99792-2.43033,5.42825-5.42825,5.42825h.00003c-2.99792,0-5.42825-2.43033-5.42825-5.42825v-22.43671c0-2.99792,2.43033-5.42825,5.42825-5.42825Z" fill="#3f3d56"/>
      <polygon points="444.39209 231.78143 332.93219 226.71507 350.30258 218.75365 448.01093 220.20119 444.39209 231.78143" fill="#010101"/>
      <polygon points="0 243.03156 13.4609 237.96519 431.36432 248.82169 448.73471 240.86026 441.49704 290.07632 109.53795 254.70364 0 243.03156" fill="#010101"/>
      <polygon points="107.4792 271.6203 109.53795 254.70364 441.49704 290.07632 439.68762 315.04623 107.4792 271.6203" fill="#3f3d56"/>
      <ellipse cx="330.76089" cy="285.00998" rx="34.37888" ry="36.55017" fill="#3f3d56"/>
      <circle cx="330.76089" cy="278.49609" r="25.33179" fill="#010101"/>
      <polygon points="613.39136 279.58173 610.49634 356.30087 617.54303 356.30087 617.54303 364.98608 597.84753 364.98608 597.84753 356.30087 603.59204 356.30087 603.1817 279.58173 613.39136 279.58173" fill="#3f3d56"/>
      <rect x="615.56262" y="343.99686" width="10.85651" height="33.29321" rx="5.42822" ry="5.42822" fill="#3f3d56"/>
      <rect x="588.05963" y="343.99686" width="10.85651" height="33.29321" rx="5.42822" ry="5.42822" fill="#3f3d56"/>
      <polygon points="549.33813 231.78143 660.79803 226.71507 643.42767 218.75365 545.7193 220.20119 549.33813 231.78143" fill="#010101"/>
      <polygon points="509.44403 216.18808 504.37772 104.72817 496.41629 122.09854 497.8638 219.8069 509.44403 216.18808" fill="#010101"/>
      <polygon points="993.73022 243.03156 980.26935 237.96519 562.36591 248.82169 544.99554 240.86026 552.23315 290.07632 884.19226 254.70364 993.73022 243.03156" fill="#010101"/>
      <polygon points="886.25104 271.6203 884.19226 254.70364 552.23315 290.07632 554.0426 315.04623 886.25104 271.6203" fill="#3f3d56"/>
      <ellipse cx="662.96936" cy="285.00998" rx="34.37885" ry="36.55017" fill="#3f3d56"/>
      <circle cx="662.96936" cy="278.49609" r="25.33179" fill="#010101"/>
      <path d="M560.01367,217.89745l-1.64294,12.10632-.854,65.16789c-.06519,4.84918-.65143,9.64053-1.75153,14.28714-3.1701,13.53439-10.57422,25.84564-21.51038,34.76971-9.92279,8.10614-23.45718,14.24365-41.37039,13.15802-47.76852-2.89505-51.9881-43.54901-51.9881-43.54901l1.80219-73.72276,.24609-10.11098,2.1713-18.24385s5.79013-51.38736,59.34879-49.93983c53.55869,1.44753,55.54898,56.07735,55.54898,56.07735Z" fill="#010101"/>
      <polygon points="456.69611 200.90344 467.55261 183.53307 481.30414 179.19048 528.34894 179.19048 539.9292 183.53307 550.43805 200.90344 531.24396 192.21826 477.40039 192.21826 456.69611 200.90344" fill="#e6e6e6"/>
      <g>
        <polygon points="496.50888 325.78528 498.36609 375.00134 493.84552 375.00134 493.84552 380.57297 506.48041 380.57297 506.48041 375.00134 502.79523 375.00134 503.05847 325.78528 496.50888 325.78528" fill="#3f3d56"/>
        <path d="M491.6337,367.10818h0c1.92322,0,3.48227,1.55905,3.48227,3.48227v14.39337c0,1.92322-1.55905,3.48227-3.48227,3.48227h0c-1.92322,0-3.48227-1.55905-3.48227-3.48227v-14.39337c0-1.92322,1.55905-3.48227,3.48227-3.48227Z" fill="#3f3d56"/>
        <path d="M509.27719,367.10818h0c1.92322,0,3.48227,1.55905,3.48227,3.48227v14.39337c0,1.92322-1.55905,3.48227-3.48227,3.48227h0c-1.92322,0-3.48227-1.55905-3.48227-3.48227v-14.39337c0-1.92322,1.55905-3.48227,3.48227-3.48227Z" fill="#3f3d56"/>
      </g>
      <rect x="460.31845" y="185.7903" width="37.36307" height="1.99969" transform="translate(170.83368 598.33002) rotate(-74.47739)" fill="#010101"/>
      <rect x="529.99975" y="174.10844" width="2.00043" height="37.3632" transform="translate(-27.20847 106.00117) rotate(-11.11698)" fill="#010101"/>
    </svg>
  );
});

export default Building;
