import React, { useCallback, useState, useEffect } from 'react';

import {
  StyledButton,
  StyledBox,
  StyledInner
} from './Menu.styles';

interface MenuProps {
  color: string;
  onTap: (isActive: boolean) => void;
  active?: boolean;
  useAltColor?: boolean;
  altColor?: string;
}

type StyledMenuProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> &
  MenuProps;

const Menu = React.forwardRef<HTMLButtonElement, StyledMenuProps>((props, ref) => {
  const {
    color,
    onTap,
    active,
    useAltColor,
    altColor,
    ...rest
  } = props;

  const [isActive, setIsActive] = useState<boolean>(active || false);

  const onClick = useCallback(() => {
    const newState = !isActive;

    if (onTap) {
      onTap(newState);
    }

    setIsActive(newState);
  }, [isActive, onTap]);

  useEffect(() => {
    if (active !== isActive) {
      setIsActive(active!);
    }
  }, [
    active,
    isActive
  ]);

  return (
    <StyledButton {...rest} ref={ref} type="button" onClick={onClick}>
      <StyledBox>
        <StyledInner color={color} isActive={isActive} useAltColor={useAltColor} altColor={altColor} />
      </StyledBox>
    </StyledButton>
  );
});

export default Menu;
