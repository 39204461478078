import { useCallback, useState } from "react";
import { AnyObject, ValidationError } from 'yup';

import { Form } from 'types/UI';

interface ErrorForm {
  [key: string]: any;
}

interface HasBeenFocused {
  [key: string]: boolean;
}

interface ValidateOptions {
  form: Form,
  field?: string,
  all?: boolean,
  propagateRejection?: boolean,
  silent?: boolean,
}

interface ValidateSchemaOptions {
  incomingSchema: AnyObject,
  form: Form,
  propagateRejection?: boolean,
}

interface State {
  schema: AnyObject;
  errors: ErrorForm;
  hasBeenFocused: HasBeenFocused;
}

const initialState: State = {
  schema: {},
  errors: {},
  hasBeenFocused: {}
};

const useValidation = (schema: AnyObject = {}) => {
  const [state, setState] = useState<State>({
    ...initialState,
    schema
  });

  const validate = useCallback((options: ValidateOptions): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      setState(prevState => {
        let errors: ErrorForm = {};
        let hasBeenFocused: HasBeenFocused = {};

        try {
          prevState.schema.validateSync(options.form, { abortEarly: false });

          resolve(true);
        } catch (e: unknown) {
          hasBeenFocused = {
            ...prevState.hasBeenFocused,
            ...(options.field && {
              [options.field]: true
            })
          };

          errors = (e as ValidationError).inner.reduce((acc: ErrorForm, curr) => {
            if (!curr.path) {
              return acc;
            }

            if (!options.all && !hasBeenFocused[curr.path]) {
              return acc;
            }

            return {
              ...acc,
              [curr.path]: curr.message
            };
          }, errors);

          if (options.propagateRejection) {
            reject(errors);
          }
        }

        const newState = {
          ...prevState,
          ...(!options.silent && {
            errors,
          }),
          hasBeenFocused
        };

        return newState;
      });
    });
  }, []);

  const validateSchema = useCallback((options: ValidateSchemaOptions): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      try {
        options.incomingSchema.validateSync(options.form, { abortEarly: false });

        resolve(true);
      } catch (e: unknown) {
        if (options.propagateRejection) {
          reject();
        }
      }
    });
  }, []);

  const setSchema = useCallback((incomingSchema: AnyObject) => {
    setState(prevState => ({
      ...prevState,
      schema: incomingSchema
    }));
  }, []);

  const reset = useCallback(() => {
    setState(prevState => ({
      ...initialState,
      schema: prevState.schema
    }));
  }, []);

  return {
    errors: state.errors,
    validate,
    validateSchema,
    setSchema,
    reset
  };
};

export default useValidation;
