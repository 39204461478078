import React, { Fragment, useCallback, useState, useRef, createRef } from 'react';
import { format } from 'date-fns';

import {
  Button,
  PrimaryButton,
  Checkbox,
  TextInput,
  DatePicker,
  Filter as FilterIcon,
  Reset
} from 'components/atoms';
import { Popup } from 'components/molecules';
import { FieldType } from 'types/Service';
import { Period } from 'types/Generic';
import {
  formatCurrency,
  getRawCurrencyValue,
  stripZoneFromISOString
} from 'utils/general';
import {
  SortState,
  SortDirection
} from '../../components/Table/Table';
import { ISO_FORMAT } from '../../../../../constants';
import { useMediaSizes } from '../';
import { IconButton } from 'theme/mixins';
import { theme } from 'theme';

import {
  ListFooter,
  ListHeaderFooterSection,
  FilterHeader,
  MenuWrapper,
  MenuItem,
  FilterOptionsElem,
  FilterOptionsInner,
  AdminFormLine,
  StyledDropdown,
  FilterButton,
  FilterCountBadge,
  StyledChevron,
  AppliedFilter,
  StyledCross,
  AppliedFilterHeader,
  SortWrapper,
  SortArrowUp,
  SortArrowDown
} from './useSortFilter.styles';

export interface Filters {
  options: Array<{
    [key: string]: string;
  }> | [];
  sectionOpenState?: boolean[];
}

export interface SortFilter {
  isOpen: boolean;
  filters?: Filters;
  offset?: number;
  sort?: SortState;
}

export interface FilterOptionItem {
  label: string;
  value: string;
  changeKeyTo?: string;
}

export interface FilterOption {
  label: string;
  name: string;
  type: FieldType;
  queryType?: 'or' | 'and';
  items?: FilterOptionItem[];
  options?: Array<{
    label: string;
    value: string;
  }>;
  default?: {
    [key: string]: string;
  }
}

export interface FilterOptions {
  fields: FilterOption[];
}

export interface SortOptions {
  options: Array<{
    label: string;
    value: string;
  }>;
  defaultSorting: SortState;
}

export interface Props {
  listItemId: string;
  total: number | null;
  offset: number | null;
  onPrev?: (opts: SortFilter) => void;
  onNext?: (opts: SortFilter) => void;
  onReset?: (opts: SortFilter) => void;
  onFilter?: (opts: SortFilter) => void;
  onSave?: (opts: any) => void;
  onSort?: (opts: SortFilter) => void;
  filterOptions?: FilterOptions;
  sortOptions?: SortOptions;
}

interface State {
  sortFilter: SortFilter;
}

const PER_PAGE = 10;

const useSortFilter = (props: Props): {
  renderPaginationAndSort: (isBottom?: boolean) => JSX.Element,
  renderFilterControl: (sideNavOpen: boolean, noMargin?: boolean, disabled?: boolean) => JSX.Element | null,
  renderAppliedFilters: () => JSX.Element | null,
  sortState: SortState | null,
  setSortState: (sortState: SortState) => void,
  filterApplied: boolean,
  filterState: any,
  setInitialFilterState: (initialState: SortFilter) => void;
} => {
  const {
    listItemId,
    total,
    offset,
    onPrev,
    onNext,
    onReset,
    onFilter,
    onSave,
    onSort,
    filterOptions,
    sortOptions
  } = props;

  const { isMobile } = useMediaSizes();

  const [state, setState] = useState<State>({
    sortFilter: {
      filters: {
        options: [],
        ...(filterOptions && {
          sectionOpenState: new Array(filterOptions.fields.length).fill(false)
        })
      },
      ...(sortOptions && {
        sort: {
          ...sortOptions.defaultSorting
        }
      }),
      isOpen: false
    },
  });
  const [sortState, setSortStateLocal] = useState<SortState | null>(null);
  const sortFilterApplied = useRef<any>({
    status: false,
    data: {}
  });
  const filterOptionInnerRefs = useRef<Array<React.RefObject<HTMLDivElement>>>([]);

  if (filterOptions && filterOptions.fields.length > 0) {
    filterOptionInnerRefs.current = [...Array(filterOptions.fields.length)].map(() => createRef<HTMLDivElement>());
  }

  const isPresetPeriod = (period: Period | string): boolean => {
    return Object
      .values(Period)
      .map(p => p.toString())
      .includes(period);
  };

  const setInitialFilterState = useCallback((options: SortFilter) => {
    setState(prevState => {
      const newState = {
        ...prevState,
        sortFilter: {
          ...prevState.sortFilter,
          ...options
        }
      };

      sortFilterApplied.current.status = true;
      sortFilterApplied.current.data = newState.sortFilter;

      return newState;
    });
  }, []);

  const updatePaginationState = useCallback((incomingOffset: number) => {
    const newState = {
      ...state,
      sortFilter: {
        ...state.sortFilter,
        offset: incomingOffset
      }
    };

    setState(newState);

    return newState.sortFilter;
  }, [state]);

  const onPaginate = useCallback((direction: string) => {
    let offsetLocal: number = offset || 0;

    switch (direction) {
      case 'prev': {
        if (offsetLocal > 0) {
          offsetLocal -= PER_PAGE;

          if (onPrev) {
            const newState = updatePaginationState(offsetLocal);
            onPrev(newState);

            if (onSave) {
              onSave({
                ...newState,
                isOpen: false
              });
            }
          }
        }
        break;
      }
      case 'next': {
        if (offsetLocal < total! - PER_PAGE) {
          offsetLocal += PER_PAGE;

          if (onNext) {
            const newState = updatePaginationState(offsetLocal);
            onNext(newState);

            if (onSave) {
              onSave({
                ...newState,
                isOpen: false
              });
            }
          }
        }
        break;
      }
      case 'reset': {
        if (onReset) {
          const newState = updatePaginationState(0);
          onReset(newState);

          if (onSave) {
            onSave({
              ...newState,
              isOpen: false
            });
          }
        }
        break;
      }
    }
  }, [
    total,
    offset,
    updatePaginationState,
    onPrev,
    onNext,
    onReset,
    onSave
  ]);

  const toggleFilter = useCallback((isOpen: boolean) => {
    setState(prevState => ({
      ...prevState,
      sortFilter: {
        ...prevState.sortFilter,
        isOpen
      }
    }));
  }, []);

  const addFilter = useCallback((key: string, filterOptionItem: any) => {
    setState(prevState => ({
      ...prevState,
      sortFilter: {
        ...prevState.sortFilter,
        filters: {
          ...prevState.sortFilter.filters!,
          options: [
            ...prevState.sortFilter.filters!.options,
            {
              [key]: filterOptionItem.value
            }
          ]
        }
      }
    }));
  }, []);

  const editFilter = useCallback((key: string, index: number, filterOptionItem: any) => {
    const newState = {
      ...state,
      sortFilter: {
        ...state.sortFilter,
        filters: {
          ...state.sortFilter.filters,
          options: [
            ...state.sortFilter.filters!.options.slice(0, index),
            filterOptionItem,
            ...state.sortFilter.filters!.options.slice(index + 1, state.sortFilter.filters!.options.length)
          ]
        }
      }
    };

    setState(newState as any);
  }, [state]);

  const removeFilter = useCallback((
    key: string,
    filterOptionItem: FilterOptionItem | undefined
  ): any => {
    const index: number = state.sortFilter.filters!.options.findIndex((opt: any) => opt[key] === filterOptionItem?.value);

    const newState = {
      ...state,
      sortFilter: {
        ...state.sortFilter,
        filters: {
          ...state.sortFilter.filters,
          options: [
            ...state.sortFilter.filters!.options.slice(0, index),
            ...state.sortFilter.filters!.options.slice(index + 1, state.sortFilter.filters!.options.length),
          ]
        }
      }
    };

    setState(newState as any);

    return newState;
  }, [state]);

  const removeFilters = useCallback((
    key: string,
    filterOptionItem: any,
    shallow?: boolean
  ) => {
    const otherFilters: any = state.sortFilter.filters!.options.filter((opt: any) => Object.keys(opt)[0] !== key);

    if (shallow) {
      return otherFilters;
    }

    const newState = {
      ...state,
      sortFilter: {
        ...state.sortFilter,
        filters: {
          ...state.sortFilter.filters,
          options: [
            ...otherFilters
          ]
        }
      }
    };

    setState(newState as any);
  }, [state]);

  const updateFilters = useCallback((filters: Filters['options']): State => {
    const newState = {
      ...state,
      sortFilter: {
        ...state.sortFilter,
        filters: {
          ...state.sortFilter.filters!,
          options: [ ...filters ]
        }
      }
    };

    setState(newState as any);

    return newState;
  }, [state]);

  const toggleFilterSection = useCallback((
    e: any,
    filterOption: FilterOption,
    index: number
  ) => {
    const key: string = filterOption.name + '#' + filterOption.type;

    setState(prevState => {
      const currValue: boolean = prevState.sortFilter.filters!.sectionOpenState![index];

      const foundFilter: any = prevState.sortFilter.filters!.options.find((option: any) => Object.keys(option)[0] === key);
      const filterFound: boolean = !!foundFilter;
      const defaultOptions: any = [];

      if (filterOption.type !== FieldType.Dropdown) {
        if (!currValue) {
          setTimeout(() => {
            if (filterOptionInnerRefs.current[index].current) {
              filterOptionInnerRefs.current[index].current!.style.overflow = 'visible';
            }
          }, 250);
        } else {
          filterOptionInnerRefs.current[index].current!.style.overflow = '';
        }
      }

      if (!filterFound && filterOption.default) {
        defaultOptions.push(filterOption.default);
      }

      let filters: Filters['options'] = [];

      if (!currValue === false && filterFound) {
        filters = removeFilters(key, foundFilter, true);
      } else {
        filters = [
          ...prevState.sortFilter.filters!.options,
        ];
      }

      return {
        ...prevState,
        sortFilter: {
          ...prevState.sortFilter,
          filters: {
            ...prevState.sortFilter.filters!,
            options: [
              ...filters,
              ...defaultOptions
            ],
            sectionOpenState: [
              ...prevState.sortFilter.filters!.sectionOpenState!.slice(0, index),
              !currValue,
              ...prevState.sortFilter.filters!.sectionOpenState!.slice(index + 1, prevState.sortFilter.filters!.sectionOpenState!.length)
            ]
          }
        }
      };
    });
  }, [removeFilters]);

  const onChangeCheckbox = useCallback((e: any, filterOption: FilterOption, filterOptionItem: FilterOptionItem) => {
    const changeKeyTo: string | undefined = filterOptionItem.changeKeyTo;
    const queryType: string | undefined = filterOption.queryType;
    const key: string = (changeKeyTo ? changeKeyTo : filterOption.name) + '#' + (queryType ? filterOption.type + '-' + queryType : filterOption.type);

    if (e.target.checked) {
      addFilter(key, filterOptionItem);
    } else {
      removeFilter(key, filterOptionItem);
    }
  }, [
    addFilter,
    removeFilter
  ]);

  const onChangeCurrency = useCallback((filterOption: FilterOption, option: any | null, e?: any, elemPos?: number) => {
    const key: string = filterOption.name + '#' + filterOption.type;
    const index: number = state.sortFilter.filters!.options.findIndex((opt: any) => Object.keys(opt)[0] === key);

    if (index >= 0) {
      const currFilterOption = state.sortFilter.filters!.options[index];
      const equalSplit: string[] = currFilterOption[key].split('=');
      const betweenSplit: string[] = currFilterOption[key].split('=')[1].split('"');
      const type: string = option ? option.value : equalSplit[0];

      // is input update
      if (e && (elemPos === 0 || elemPos === 1)) {
        if (elemPos === 0) {
          currFilterOption[key] = `${type}=${getRawCurrencyValue(e.target.value)}"${(betweenSplit && betweenSplit[1]) || 0}`;
        }
        else if (elemPos === 1) {
          currFilterOption[key] = `${type}=${betweenSplit[0]}"${getRawCurrencyValue(e.target.value)}`;
        }
      } else {
        currFilterOption[key] = `${type}=${equalSplit[1]}`;
      }

      // console.log('---------currency update 1', currFilterOption);

      // is select update
      editFilter(key, index, currFilterOption);
    } else {
      const defaultFilterOption = {
        [key]: `${option.value}=0`
      };

      // console.log('---------currency update 2', defaultFilterOption);

      // is select update
      addFilter(key, defaultFilterOption);
    }
  }, [
    state,
    addFilter,
    editFilter
  ]);

  const onChangeDate = useCallback((
    filterOption: FilterOption,
    option: any | null,
    selectedDate?: Date,
    elemPos?: number
  ) => {
    const key: string = filterOption.name + '#' + filterOption.type;
    const index: number = state.sortFilter.filters!.options.findIndex((opt: any) => Object.keys(opt)[0] === key);

    if (index >= 0) {
      const currFilterOption = state.sortFilter.filters!.options[index];
      const equalSplit: string[] = currFilterOption[key].split('=');
      if (equalSplit.length === 1) {
        equalSplit.push(format(new Date(), ISO_FORMAT));
      }

      const betweenSplit: string[] = currFilterOption[key].split('=')[1]?.split('"') || [];
      if (!betweenSplit.length) {
        betweenSplit.push(format(new Date(), ISO_FORMAT));
      }

      const type: string = option ? option.value : equalSplit[0];
      let newDate: Date | string | undefined = selectedDate;

      if (selectedDate) {
        newDate = format(selectedDate, ISO_FORMAT);
      }

      // is input update
      if (newDate && (elemPos === 0 || elemPos === 1)) {
        if (elemPos === 0) {
          currFilterOption[key] = `${type}=${newDate}"${(betweenSplit && betweenSplit[1]) || format(new Date(), ISO_FORMAT)}`;
        }
        else if (elemPos === 1) {
          currFilterOption[key] = `${type}=${betweenSplit[0]}"${newDate}`;
        }
      } else {
        currFilterOption[key] = `${type}=${equalSplit[1]}`;
      }

      // Dropdown change to type between without setting any dates
      if (type === 'between' && !(elemPos === 0 || elemPos === 1)) {
        currFilterOption[key] = `${type}=${betweenSplit[0]}"${format(new Date(), ISO_FORMAT)}`;
      }

      if (isPresetPeriod(type)) {
        currFilterOption[key] = type;
      }

      // is select update
      editFilter(key, index, currFilterOption);
    } else {
      const defaultFilterOption = {
        [key]: `${option.value}=0`
      };

      // console.log('---------date update 2', defaultFilterOption);

      addFilter(key, defaultFilterOption);
    }
  }, [
    state,
    addFilter,
    editFilter
  ]);

  const toggleSortDirection = useCallback(() => {
    const newState = {
      ...state,
      sortFilter: {
        ...state.sortFilter,
        sort: {
          ...state.sortFilter.sort!,
          direction: state.sortFilter.sort!.direction * -1
        }
      }
    };

    setState(newState);

    return newState;
  }, [state]);

  const onApplyFilter = useCallback((newState?: State) => {
    const s = newState || state;

    sortFilterApplied.current.status = true;
    sortFilterApplied.current.data = s.sortFilter;

    if (onFilter) {
      onFilter({
        ...s.sortFilter,
        offset: 0
      });
    }

    if (onSave) {
      onSave({
        ...s.sortFilter,
        isOpen: false,
        offset: 0
      });
    }
  }, [
    state,
    onFilter,
    onSave
  ]);

  const renderPaginationAndSort = useCallback((isBottom?: boolean) => {
    const page: number = offset! / PER_PAGE + 1;
    const totalPages: number = Math.ceil(total! / PER_PAGE);
    const pageMarker = ` -- page ${page} of ${totalPages}`;
    const sortInfo = sortOptions ? (
      <SortWrapper
        onClick={() => {
          const newState = toggleSortDirection();

          onApplyFilter(newState);
        }}
      >
        {state.sortFilter.sort?.direction === SortDirection.Ascending ? (
          <SortArrowUp />
        ) : SortDirection.Descending ? (
          <SortArrowDown />
        ) : null}
        <span style={{ marginLeft: '.5rem' }}>{state.sortFilter.sort!.label}</span>
      </SortWrapper>
    ) : null;

    const sortInfoAbovePaginationInfo = sortOptions && isBottom && sortInfo;
    const sortInfoBelowPaginationInfo = sortOptions && !isBottom && sortInfo;
    const showResetButton: boolean = totalPages > 2;

    return (
      <ListFooter>
        <ListHeaderFooterSection column>
          {sortInfoAbovePaginationInfo}
          <div>
            <span>{` ${total} ${total === 1 ? listItemId : listItemId + 's'}`}</span>
            {totalPages >= 2 && (
              <span>{pageMarker}</span>
            )}
          </div>
          {sortInfoBelowPaginationInfo}
        </ListHeaderFooterSection>
        <ListHeaderFooterSection
          vCenter
          showResetButton={showResetButton}
        >
          <Button
            disabled={offset === 0 || total! <= PER_PAGE}
            icon={isMobile ? (
              <StyledChevron
                $left
                $noFill
                style={{
                  ...(isMobile && {
                    width: '2.4rem',
                    height: 'auto'
                  })
                }}
              /> ) : undefined
            }
            onClick={(e) => onPaginate('prev')}
          >{isMobile ? '' : 'Previous'}</Button>
          {showResetButton && (
            <IconButton
              hoverEffectAlwaysOn
              disabled={page < 2}
              style={{
                marginLeft: '1rem',
                marginRight: '1rem',
                background: theme.button.backgroundColor,
                border: `.1rem solid ${theme.button.borderColor}`
              }}
              onClick={() => onPaginate('reset')}
            >
              <Reset
                style={{
                  ...(isMobile && {
                    width: '2.4rem',
                    height: 'auto'
                  }),
                  width: '1.75rem'
                }}
              />
            </IconButton>
          )}
          <Button
            disabled={!(offset! + PER_PAGE < total!)}
            icon={isMobile ? (
              <StyledChevron
                $right
                $noFill
                style={{
                  ...(isMobile && {
                    width: '2.4rem',
                    height: 'auto'
                  })
                }}
              /> ) : undefined
            }
            onClick={(e) => onPaginate('next')}
          >{isMobile ? '' : 'Next'}</Button>
        </ListHeaderFooterSection>
      </ListFooter>
    );
  }, [
    listItemId,
    total,
    offset,
    isMobile,
    sortOptions,
    state.sortFilter.sort,
    onPaginate,
    toggleSortDirection,
    onApplyFilter
  ]);

  const discardUnAppliedFilters = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      sortFilter: {
        ...prevState.sortFilter,
        filters: {
          ...sortFilterApplied.current.data.filters
        },
        sort: {
          ...sortFilterApplied.current.data.sort
        }
      }
    }));
  }, []);

  const setFilterOptionVisible = useCallback((isChecked: boolean, filterOption: FilterOption, index: number) => {
    if (isChecked && (filterOption.type === FieldType.Currency || filterOption.type === FieldType.DateTime)) {
      if (filterOptionInnerRefs.current[index].current!.style.overflow !== 'visible') {
        filterOptionInnerRefs.current[index].current!.style.overflow = 'visible';
      }
    }
  }, []);

  const setFilterOptionInVisible = useCallback(() => {
    let i;

    for (i = 0; i < filterOptionInnerRefs.current.length; i++) {
      filterOptionInnerRefs.current[i].current!.style.overflow = '';
    }
  }, []);

  const onResetFilter = useCallback(() => {
    const newState = {
      ...state,
      sortFilter: {
        ...state.sortFilter,
        offset: 0,
        filters: {
          ...state.sortFilter.filters!,
          options: [],
          sectionOpenState: new Array(filterOptions!.fields.length).fill(false)
        },
        ...(sortOptions && {
          sort: {
            ...sortOptions.defaultSorting
          }
        })
      }
    };

    if (
      onFilter
      && state.sortFilter.filters!.options.length > 0
      && sortFilterApplied.current.status
    ) {
      onFilter(newState.sortFilter);

      if (onSave) {
        onSave({
          ...newState.sortFilter,
          isOpen: false
        });
      }
    }

    setState(newState);

    sortFilterApplied.current.status = false;
    sortFilterApplied.current.data = {};
  }, [
    state,
    filterOptions,
    sortOptions,
    onFilter,
    onSave
  ]);

  const setSortState = useCallback((sState: SortState) => {
    setSortStateLocal(sState);  

    if (onSort) {
      onSort({
        ...state.sortFilter,
        sort: {
          ...sState
        }
      });
    }
  }, [
    state,
    onSort
  ]);

  const renderFilterOption = useCallback((filterOption: FilterOption, index: number) => {
    let content;

    switch (filterOption.type) {
      case FieldType.Dropdown: {
        const hasListItems: boolean = !!filterOption.items;
        const key: string = filterOption.name + '#' + filterOption.type;

        content = hasListItems && filterOption.items!.map((filterOptionItem: FilterOptionItem, i: number) => {
          const isFilterChecked: boolean = !!(
            state.sortFilter.filters
            && state.sortFilter.filters!.options.find((opt) => {
              return opt[key] === filterOptionItem.value
                || (filterOptionItem.changeKeyTo && Object.keys(opt)[0].split('#')[0] === filterOptionItem.changeKeyTo && opt[filterOptionItem.changeKeyTo + '#' + filterOption.type] === filterOptionItem.value)
                || (filterOption.queryType && opt[Object.keys(opt)[0].split('#')[0] + '#' + filterOption.type + '-' + filterOption.queryType] === filterOptionItem.value);
            })
          );

          return (
            <MenuItem key={i}>
              <Checkbox
                checked={isFilterChecked}
                label={filterOptionItem.label}
                onChange={(e) => onChangeCheckbox(e, filterOption, filterOptionItem) }
              />
            </MenuItem>
          );
        })
        break;
      }
      case FieldType.Currency: {
        const key: string = filterOption.name + '#' + filterOption.type;
        const selected = state.sortFilter.filters!.options.find((opt) => Object.keys(opt)[0] === key);

        const type: string = selected ? selected[key].split('=')[0] : filterOption.options![0].value;
        const selectedValue: any = filterOption.options!.find((opt: any) => opt.value === type);
        const firstInputValue: number = Number(getRawCurrencyValue((selected && selected[key].split('=')[1].split('"')[0]) || '0'));
        const secondInputValue: number = Number(getRawCurrencyValue(((selected && selected[key].split('=')[1])! || '').split('"').pop() || '0'));

        // console.log(
        //   '-------selected',
        //   selected,
        //   filterOption.options,
        //   selectedValue,
        //   CURRENCY_DROPDOWN_OPTIONS,
        //   firstInputValue,
        //   type
        // );

        content = (
          <MenuItem column>
            <StyledDropdown
              width={'22.5rem'}
              value={selectedValue.value}
              options={filterOption.options!}
              onChange={(option: any) => onChangeCurrency(filterOption, option)}
            />
            <AdminFormLine row centerV>
              <TextInput
                width={'9rem'}
                type={filterOption.type}
                value={formatCurrency(firstInputValue)}
                onChange={(e) => onChangeCurrency(filterOption, null, e, 0)}
              />
              {type === 'between' && (
                <>
                  <span style={{ padding: '0 1rem' }}>And</span>
                  <TextInput
                    width={'9rem'}
                    type={filterOption.type}
                    value={formatCurrency(secondInputValue)}
                    onChange={(e) => onChangeCurrency(filterOption, null, e, 1)}
                  />
                </>
              )}
            </AdminFormLine>
          </MenuItem>
        );
        break;
      }
      case FieldType.DateTime: {
        const key: string = filterOption.name + '#' + filterOption.type;
        const selected = state.sortFilter.filters!.options.find((opt) => Object.keys(opt)[0] === key);

        const type: string = selected ? selected[key].split('=')[0] : filterOption.options![0].value;
        const selectedValue: any = filterOption.options!.find((opt: any) => opt.value === type);
        const showDatePickers: boolean = !isPresetPeriod(selectedValue.value);
        const firstInputValue: Date = new Date((selected && stripZoneFromISOString(selected[key].split('=')[1]?.split('"')[0])) || new Date());
        const secondInputValue: Date = new Date(stripZoneFromISOString(((selected && selected[key].split('=')[1])! || '').split('"').pop()) || new Date());

        // console.log(
        //   '-------selected',
        //   type,
        //   selected,
        //   firstInputValue,
        //   secondInputValue
        // );

        content = (
          <MenuItem column>
            <StyledDropdown
              width={'22.5rem'}
              value={selectedValue.value}
              options={filterOption.options!}
              onChange={(option: any) => onChangeDate(filterOption, option)}
            />
            {showDatePickers && (
              <AdminFormLine row centerV>
                <DatePicker
                  noLabel
                  width={'11rem'}
                  dateFormat={'dd/MM/yy'}
                  placeholderText="Select date"
                  value={firstInputValue}
                  onChange={(selectedDate: Date) => onChangeDate(filterOption, null, selectedDate, 0)}
                />
                {type === 'between' && (
                  <>
                    <span>-</span>
                    <DatePicker
                      noLabel
                      width={'11rem'}
                      dateFormat={'dd/MM/yy'}
                      placeholderText="Select date"
                      value={secondInputValue}
                      onChange={(selectedDate: Date) => onChangeDate(filterOption, null, selectedDate, 1)}
                    />
                  </>
                )}
              </AdminFormLine>
            )}
          </MenuItem>
        );
        break;
      }
      case FieldType.Boolean: {
        // TODO:
        break;
      }
    };

    return content;
  }, [
    state,
    onChangeCheckbox,
    onChangeCurrency,
    onChangeDate
  ]);

  const renderFilterControl = useCallback((
    sideNavOpen: boolean,
    noMargin?: boolean,
    disabled?: boolean
  ) => {
    disabled = disabled || false;

    if (!filterOptions || !state.sortFilter.filters!.sectionOpenState) {
      return null;
    }

    const buttonProps: any = { disabled };

    if (noMargin) {
      buttonProps['style'] = {
        marginBottom: 0
      }
    }

    const filterCount: number = sortFilterApplied.current.data?.filters?.options?.length || 0;

    return (
      <div>
        <FilterButton
          {...buttonProps}
          icon={<FilterIcon style={{ width: '1.75rem', height: 'auto' }} />}
          badge={
            filterCount > 0 && (
              <FilterCountBadge>{filterCount}</FilterCountBadge>
            )
          }
          onClick={(e) => toggleFilter(true)}
        >
          {isMobile ? '' : 'Filter'}
        </FilterButton>
        {state.sortFilter.isOpen && (
          <Popup
            id={listItemId}
            left
            bottom
            convertable
            noPadding
            onClose={() => {
              if (sortFilterApplied.current.status) {
                discardUnAppliedFilters();
              } else {
                onResetFilter();
              }

              toggleFilter(false);
            }}
          >
            {({ closePopup }) => (
              <MenuWrapper>
                <MenuItem
                  vCenter
                  hCenter
                  paddingBottom
                  borderBottom
                  style={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                    backgroundColor: 'white'
                  }}
                >
                  <Button
                    style={{ marginBottom: 0 }}
                    onClick={() => {
                      setFilterOptionInVisible();
                      onResetFilter();
                    }}
                  >Reset</Button>
                  <FilterHeader>Filters</FilterHeader>
                  <PrimaryButton
                    style={{ marginBottom: 0 }}
                    onClick={() => {
                      onApplyFilter();
                      closePopup(); 
                    }}
                  >Apply</PrimaryButton>
                </MenuItem>
                {sortOptions && (
                  <MenuItem borderBottom>
                    <StyledDropdown
                      width={'22.5rem'}
                      label="Sort by"
                      value={state.sortFilter.sort?.key}
                      options={sortOptions.options}
                      style={{ marginTop: '.5rem' }}
                      endAdornment={(
                        <IconButton
                          hoverEffect
                          type="button"
                          marginLeft
                          onClick={() => toggleSortDirection()}
                        >
                          {state.sortFilter.sort?.direction === SortDirection.Ascending ? (
                            <SortArrowUp />
                          ) : SortDirection.Descending ? (
                            <SortArrowDown />
                          ) : null}
                        </IconButton>
                      )}
                      onChange={(option: any) => {
                        setState(prevState => ({
                          ...prevState,
                          sortFilter: {
                            ...prevState.sortFilter,
                            sort: {
                              ...prevState.sortFilter.sort!,
                              key: option?.value || '',
                              label: option?.label || ''
                            }
                          }
                        }));
                      }}
                    />
                  </MenuItem>
                )}
                {filterOptions.fields.map((filterOption: FilterOption, index: number) => {
                  const isChecked: boolean = state.sortFilter.filters!.sectionOpenState![index];

                  let elem = null;

                  elem = (
                    <Fragment key={index}>
                      <MenuItem
                        borderBottom
                      >
                        <Checkbox
                          checked={isChecked}
                          label={filterOption.label}
                          onChange={(e) => toggleFilterSection(e, filterOption, index) }
                        />
                      </MenuItem>
                      <FilterOptionsElem isExpanded={isChecked}>
                        <FilterOptionsInner
                          ref={filterOptionInnerRefs.current[index]}
                          onClick={() => setFilterOptionVisible(isChecked, filterOption, index) }
                        >
                          {renderFilterOption(filterOption, index)}
                        </FilterOptionsInner>
                      </FilterOptionsElem>
                    </Fragment>
                  );

                  return elem;
                })}
              </MenuWrapper>
            )}
          </Popup>
        )}
      </div>
    );
  }, [
    state,
    isMobile,
    listItemId,
    filterOptions,
    sortOptions,
    toggleFilter,
    toggleFilterSection,
    toggleSortDirection,
    onResetFilter,
    onApplyFilter,
    discardUnAppliedFilters,
    renderFilterOption,
    setFilterOptionVisible,
    setFilterOptionInVisible
  ]);

  const renderAppliedFilters = useCallback(() => {
    if (!filterOptions) {
      return null;
    }

    const applied = state.sortFilter.filters?.options || [];

    return (
      <div>
        {!!applied.length && (
          <AppliedFilterHeader>Applied filters</AppliedFilterHeader>
        )}
        {applied.map((option) => {
          const key: string = Object.keys(option)[0];
          const split: string[] = key.split('#');
          const name: string = split[0];
          const type: string = split[1].split('-')[0];
          const filterOptionIndex: number = filterOptions.fields.findIndex((o) => {
            const foundChangeTo: boolean = !!o.items?.find(i => i.changeKeyTo === name);

            if (o.name === name) {
              return true;
            }

            return foundChangeTo;
          });

          if (filterOptionIndex === -1) {
            console.log('--useSortFilter: filter option not found', key);

            return null;
          }

          const filterOption = filterOptions.fields[filterOptionIndex];

          if (!filterOption) {
            return 'Invalid filter';
          }

          const filterLabel: string = filterOption.label;
          const filterValue: string = option[key];

          if (!filterValue) {
            return null;
          }

          switch (type) {
            case FieldType.Dropdown: {
              const filterOptionItem: FilterOptionItem | undefined = filterOption.items?.find(i => i.value === filterValue);

              const formattedFilterValue: string = filterOptionItem?.label || 'Invalid value';

              return (
                <AppliedFilter key={JSON.stringify(option)}>
                  <span>{`${filterLabel}: ${formattedFilterValue}`}</span>
                  <IconButton onClick={() => {
                    const newState = removeFilter(key, filterOptionItem);

                    onApplyFilter(newState);
                  }}>
                    <StyledCross />
                  </IconButton>
                </AppliedFilter>
              );
            }
            case FieldType.DateTime: {
              const periodSplit: string[] = filterValue.split('=');
              const period: string = periodSplit[0];
              const dates: string[] = periodSplit[1]?.split('"');
              let formattedPeriod = filterOption!.options!.find(o => o.value === period)?.label || 'Invalid value';

              if (!isPresetPeriod(period) && formattedPeriod !== 'Invalid value') {
                formattedPeriod = formattedPeriod.replace('Is ', '');
                formattedPeriod += ' ' + format(stripZoneFromISOString(dates[0]), 'dd/MM/yyyy');

                if (dates.length === 2 && period === 'between') {
                  formattedPeriod += ' and ' + format(stripZoneFromISOString(dates[1]), 'dd/MM/yyyy');
                }
              }

              return (
                <AppliedFilter key={JSON.stringify(option)}>
                  <span>{`${filterLabel}: ${formattedPeriod}`}</span>
                  <IconButton onClick={() => {
                    const filters = removeFilters(key, filterOption, true);
                    const newState = updateFilters(filters);

                    // TODO: refactor updateFilters to contain the below line to prevent another setState
                    newState.sortFilter.filters!.sectionOpenState![filterOptionIndex] = false;
                    setState(newState);
                    onApplyFilter(newState);
                  }}>
                    <StyledCross />
                  </IconButton>
                </AppliedFilter>
              );
            }
            case FieldType.Currency:
              const comparatorSplit: string[] = filterValue.split('=');
              const comparator: string = comparatorSplit[0];
              const values: string[] = comparatorSplit[1].split('"');
              let formattedComparator = filterOption!.options!.find(o => o.value === comparator)?.label || 'Invalid value';

              if (formattedComparator !== 'Invalid value') {
                formattedComparator = formattedComparator.replace('Is ', '');
                formattedComparator += ' ' + formatCurrency(Number(values[0]));

                if (values.length === 2 && comparator === 'between') {
                  formattedComparator += ' and ' + formatCurrency(Number(values[1]));
                }
              }

              return (
                <AppliedFilter key={JSON.stringify(option)}>
                  <span>{`${filterLabel}: ${formattedComparator}`}</span>
                  <IconButton onClick={() => {
                    const filters = removeFilters(key, filterOption, true);
                    const newState = updateFilters(filters);

                    // TODO: refactor updateFilters to contain the below line to prevent another setState
                    newState.sortFilter.filters!.sectionOpenState![filterOptionIndex] = false;
                    setState(newState);
                    onApplyFilter(newState);
                  }}>
                    <StyledCross />
                  </IconButton>
                </AppliedFilter>
              );
          };

          return null;
        })}
      </div>
    );
  }, [
    filterOptions,
    state,
    removeFilter,
    removeFilters,
    updateFilters,
    onApplyFilter
  ]);

  // console.log('-------state', state);

  return {
    renderPaginationAndSort,
    renderFilterControl,
    renderAppliedFilters,
    sortState,
    setSortState,
    filterApplied: sortFilterApplied.current.status,
    filterState: state.sortFilter,
    setInitialFilterState
  };
};

export default useSortFilter;

