import styled from 'styled-components';
import { device } from 'theme/media';

import {
  AdminFormWrapper,
  AdminFormLine,
  Card
} from 'theme/mixins';
import { StatusIndicator } from './Jobs.styles';
import {
  TextInput,
  Copy,
  Dropdown
} from 'components/atoms';

export * from 'theme/mixins';

export const Wrapper = styled.div`
  display: flex;
  width: inherit;
  height: 100%;
  flex-direction: column;
`;

export const CreateFormWrapper = styled(AdminFormWrapper)`
  padding: 1rem 0;
  text-align: left;
`;

export const StyledStatusIndicator = styled(StatusIndicator)`
  margin-left: 2rem;

  :after {
    top: .2rem;
  }
`;

export const PaymentLink = styled.div`
  font-weight: 700;
  cursor: pointer;
  padding-right: .5rem;
  word-break: break-all;
  text-decoration: underline;
  text-decoration-color: ${({ theme }) => theme.colors.corePrimaryDarker};
  text-decoration-thickness: .25rem;

  svg {
    position: relative;
    top: .4rem;
    left: .5rem;
  }
`;

export const StatusWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 1.75rem;
`;

export const StyledTextInput = styled(TextInput)`
  margin-right: 1rem;
`;

export const GeneratedPrice = styled.div`
  font-size: 1.8rem;
  font-weight: 700;
  cursor: pointer;
`;

export const StyledCopyIcon = styled(Copy)`
  width: 1.75rem;
  height: auto;
`;

export const StyledPageHeader = styled.span`
  width: 1.75rem;
  height: auto;
`;

export const BuilderWrapper = styled(AdminFormLine)`
  height: inherit;
  text-align: left;
  flex-direction: column;

  @media ${device.mobile} {
    height: inherit;
  }

  @media ${device.tablet} {
    height: unset;
  }
`;

export const FulfillerInfo = styled(AdminFormLine)`
  > div {
    flex: 1;
  }

  @media ${device.mobile} {
    flex-direction: column;
  }

  @media ${device.onlyMobile} {
    > div:last-child {
      margin-top: 1.75rem;
    }
  }

  @media ${device.tablet} {
    flex-direction: row;
  }
`;

export const FulfillerDropdown = styled(Dropdown)`
  flex: 1;

  @media ${device.mobile} {
    margin-right: 0;
  }

  @media ${device.tablet} {
    margin-right: 1rem;
  }
`;

export const FulfillerPay = styled(TextInput)`
  flex: 1;
`;

export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WarningCard = styled(Card)`
  width: 35rem;

  svg {
    fill: ${({ theme }) => theme.toast.warningColor};
    margin-right: 1rem;
  }

  @media ${device.onlyMobile} {
    width: 100%;
    max-width: 50rem;
  }
`;

export const WarningText = styled.div`
  display: flex;
  flex-direction: row;
`;

export const WarningAction = styled.div`
  display: flex;
  flex-direction: column;

  button {
    width: fit-content;
    margin: 0;
    margin-top: 2rem;
    margin-left: auto;
  }
`;

export const VatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
`;

export const SettingDateAndTime = styled.div<{ marginBottom?: boolean; }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ marginBottom }) => marginBottom && `
    margin-bottom: .5rem;
  `}

  :after {
    content: '';
    width: .75rem;
    height: .1rem;
    background-color: ${({ theme }) => theme.textColor};
    margin-left: auto;
  }
`;

export const SettingDuration = styled.div`
  display: flex;
  position: relative;
  align-items: center;

  :before {
    content: '';
    width: .1rem;
    height: 138%;
    background-color: ${({ theme }) => theme.textColor};
    margin-right: 1.25rem;
  }

  :after {
    content: '';
    position: absolute;
    left: 0;
    width: .75rem;
    height: .1rem;
    background-color: ${({ theme }) => theme.textColor};
  }
`;

export const DateKey = styled.div`
  display: inline-flex;
  width: 6rem;
  flex-shrink: 0;
`;

export const DateSplitter = styled.div`
  display: inline-flex;

  :before {
    content: '';
    margin-left: .5rem;
    margin-right: .5rem;
  }

  :after {
    content: '';
    margin-left: .5rem;
    margin-right: .5rem;
  }
`;

export const TimeSplitter = styled.div`
  display: inline-flex;

  :before {
    content: '';
    margin-left: .25rem;
    margin-right: .25rem;
  }

  :after {
    content: '';
    margin-left: .25rem;
    margin-right: .25rem;
  }
`;

