import React from 'react'

import { IconProps } from './types'

type Props = React.DetailedHTMLProps<React.DOMAttributes<SVGSVGElement>, SVGSVGElement> &
  IconProps;

const ArrowRightAlt = React.forwardRef<SVGSVGElement, Props>((props, ref) => {
  const { className, ...rest } = props;

  return (
    <svg
      {...rest}
      ref={ref}
      className={className}
      width="2.4rem"
      height="2.4rem"
      viewBox="0 -960 960 960"
      fill="#000"
    >
      <path d="m560-240-56-58 142-142H160v-80h486L504-662l56-58 240 240-240 240Z"/>
    </svg>
  );
});

export default ArrowRightAlt;

