export interface AppError {
  errorCode: string;
}

class BaseError extends Error {
  public isOperational: boolean;
  constructor(
    public message: string,
    public status: number,
    public data: AppError,
    public type: string,
    public isPublic: boolean,
  ) {
    super(message);

    this.isOperational = true;

    Error.captureStackTrace(this, this.constructor);
  }
}

export class NetworkError extends BaseError {
  /**
   * Creates a NetworkError.
   * @param {string} message - Error message.
   * @param {number} status - HTTP status code of error.
   * @param {boolean} isPublic - Whether the message should be visible to user or not.
   */
  constructor(
    message: string = 'An unexpected server error occurred.',
    status: number = 500,
    data: AppError = {
      errorCode: '1300' 
    },
    type: string = NetworkError.name,
    isPublic: boolean = false
  ) {
    super(message, status, data, type, isPublic);
  }
}

export class LocalError extends BaseError {
  /**
   * Creates a LocalError.
   * @param {string} message - Error message.
   * @param {number} status - HTTP status code of error.
   * @param {boolean} isPublic - Whether the message should be visible to user or not.
   */
  constructor(
    message: string = 'An unexpected error occurred.',
    status: number = 500,
    data: AppError = {
      errorCode: '1200' 
    },
    type: string = LocalError.name,
    isPublic: boolean = false
  ) {
    super(message, status, data, type, isPublic);
  }
}

export type ApplicationError =
  | LocalError
  | NetworkError;
