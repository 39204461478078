import { css } from 'styled-components';

export const BaseButtonStyles = (
  width?: string,
  loading?: boolean,
  iconOnly?: boolean,
  noPadding?: boolean
) => {
  return css`
    display: flex;
    min-width: 8rem;
    height: 3.7rem;
    margin-bottom: 2rem;
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
    padding: 0 1.5rem;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    border-style: solid;
    border-width: 1px;
    border-color: ${({ theme }) => theme.button.borderColor};
    flex-grow: 0;
    transition: box-shadow ${({ theme }) => theme.animSpeed};

    svg {
      margin-right: 0.5rem;
    }

    ${width && `
      width: ${width};
    `}

    color: ${({ theme }) => theme.button.color};
    background-color: ${({ theme }) => theme.button.backgroundColor};

    :not(:disabled):hover {
      border-color: ${({ theme }) => theme.button.hover.borderColor};
      box-shadow: rgb(60 66 87 / 8%) 0px 3px 9px 0px;
    }

    :not(:disabled):active {
      color: ${({ theme }) => theme.backgroundColor};
      background-color: ${({ theme }) => theme.textColor};

      svg {
        fill: ${({ theme }) => theme.backgroundColor};
      }
    }

    :disabled {
      border-color: ${({ theme }) => theme.button.disabled.borderColor};
      color: ${({ theme }) => theme.button.disabled.color};

      svg {
        fill: ${({ theme }) => theme.button.disabled.color};
      }
    }

    ${noPadding && `
      padding: unset;
    `}

    ${loading && `
      cursor: wait;
    `}

    ${iconOnly && `
      min-width: 0;
      width: 3.7rem;
      height: 3.7rem;
      padding: 1rem;

      & svg {
        width: 1.3rem;
        height: 1.3rem;
        margin-right: 0;
      }
    `}
  `;
};

export const BaseContentWrapperStyles = () => {
  return css`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    & span {
      padding-bottom: 0.1rem;
    }
  `;
};
