import styled from 'styled-components';

import {
  CreditCards,
  TransferMoney
} from 'components/atoms';

export * from 'theme/mixins';

export const Wrapper = styled.div`
  display: flex;
  width: inherit;
  height: 100%;
  flex-direction: column;
`;

export const StyledCreditCards = styled(CreditCards)`
  width: 12rem;
  height: auto;
`;

export const StyledTransferMoney = styled(TransferMoney)`
  width: 12rem;
  height: auto;
`;

