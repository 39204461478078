import styled from 'styled-components';
import { device } from 'theme/media';

export * from 'theme/mixins';

export const Wrapper = styled.div`
  display: flex;
  width: inherit;
  height: max-content;
  flex-direction: column;
`;

export const ChartWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 40rem;
  justify-content: center;
`;

export const RadialChartWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 3.5rem;
  justify-content: center;
`;

export const StatSection = styled.div<{
  mCols?: number;
  tCols?: number;
  lCols?: number;
}>`
  display: grid;
  width: 100%;
  grid-template-rows: auto;
  grid-column-gap: .8rem;
  grid-row-gap: 4rem;

  @media ${device.mobile} {
    ${({ mCols }) => mCols && mCols > 0 && `
      grid-template-columns: ${'1fr '.repeat(mCols).trim()};
    `};
  }

  @media ${device.tablet} {
    ${({ tCols }) => tCols && tCols > 0 && `
      grid-template-columns: ${'1fr '.repeat(tCols).trim()};
    `};
  }

  @media ${device.laptop} {
    ${({ lCols }) => lCols && lCols > 0 && `
      grid-template-columns: ${'1fr '.repeat(lCols).trim()};
    `};
  }
`;

