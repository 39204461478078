import React, { FC, useEffect, useCallback, Dispatch } from 'react';
import { useParams } from 'react-router-dom';

import { theme } from 'theme';
import { gatewayService } from 'services';
import { Action, Actions } from 'state';
import { Service } from 'types/Service'
import { Job } from 'types/Job'
import { LocalError } from 'types/Error';
import { Spinner } from 'components/atoms';
import { PageProps } from 'components/AppRouter';
import {
  isInIframe,
  getNetworkErrors
} from 'utils/general';
import { getQueryParam } from 'utils/url';

import { State as GlobalState } from 'state';

interface Props {
  state: GlobalState;
  dispatch: Dispatch<Action>;
  children: (
    opts: {
      errors: PageProps['errors'];
      page?: number;
      jobId?: string;
      serviceId?: string;
    }
  ) => any;
}

const BookingContainer: FC<Props> = (props) => {
  const {
    state: globalState,
    dispatch,
  } = props;

  const { clientId, serviceId, page } = useParams();

  const jobId: string | undefined = getQueryParam('job', window.location.search.split('?')[1]);

  const cacheService = useCallback((service: Service | any | undefined) => {
    dispatch({
      type: Actions.CACHE_SERVICE,
      payload: {
        data: service
      }
    });
  }, [dispatch]);

  const fetchClient = useCallback(() => {
    if (globalState.client.loading) {
      return;
    }

    dispatch({ type: Actions.LOADING_CLIENT });

    gatewayService.getClient(clientId!, !isInIframe(window))
      .then((clientResponse: any) => {
        dispatch({
          type: Actions.CACHE_CLIENT,
          payload: clientResponse.data
        });
      })
      .catch((e: any) => {
        dispatch({
          type: Actions.ERROR_CLIENT,
          payload: getNetworkErrors([e])[0]
        });
      });
  }, [
    globalState.client.loading,
    clientId,
    dispatch
  ]);

  const fetchServices = useCallback((cid: string) => {
    if (globalState.services.loading) {
      return;
    }

    dispatch({ type: Actions.LOADING_SERVICES });

    gatewayService.getServices(cid, !isInIframe(window))
      .then((servicesResponse: any) => {
        dispatch({
          type: Actions.CACHE_SERVICES,
          payload: servicesResponse.data
        });
      })
      .catch((err: any) => {
        dispatch({
          type: Actions.ERROR_SERVICES,
          payload: getNetworkErrors([err])[0]
        });
      });
  }, [
    dispatch,
    globalState.services.loading,
  ]);

  const fetchService = useCallback((cid: string, sid: string) => {
    if (globalState.service.loading) {
      return;
    }

    dispatch({ type: Actions.LOADING_SERVICE });

    gatewayService.getService(cid, sid, true)
      .then((serviceResponse: any) => cacheService(serviceResponse.data))
      .catch((err: any) => {
        dispatch({
          type: Actions.ERROR_SERVICE,
          payload: getNetworkErrors([err])[0]
        });
      });
  }, [
    dispatch,
    globalState.service.loading,
    cacheService
  ]);

  const getJob = useCallback(() => {
    if (globalState.job.loading || !clientId) {
      return;
    }

    dispatch({ type: Actions.LOADING_JOB });

    gatewayService
      .getJob(clientId, jobId as string, true)
      .then((jobResponse: any) => {
        const job: Job = jobResponse.data;

        dispatch({
          type: Actions.SAVE_JOB,
          payload: jobResponse.data
        });

        if (job.serviceId === 'none') {
          const service: any = {
            _id: 'none',
            steps: job.steps
          };

          cacheService(service);
        };
      })
      .catch((err: any) => {
        dispatch({
          type: Actions.ERROR_JOB,
          payload: getNetworkErrors([err])[0]
        });
      });
  }, [
    globalState.job.loading,
    jobId,
    clientId,
    cacheService,
    dispatch
  ]);

  useEffect(() => {
    // not needed since they need to be decoupled and operate separately
    // if (!globalState.client.data
    //   && !globalState.client.error
    //   && !globalState.services.data
    //   && !globalState.services.error
    // ) {
      const clientIdValid: boolean = !!clientId && clientId.length === 14;

      if (!clientIdValid) {
        return dispatch({
          type: Actions.ERROR_JOB,
          payload: getNetworkErrors([
            new LocalError(
              'Invalid URL',
              undefined,
              { errorCode: '1401' }
            )
          ])[0]
        });
      }

      if (!globalState.client.data && !globalState.client.error) {
        fetchClient();
      }

      if (serviceId !== 'none' && serviceId !== 'new') {
        // At client page, load all services to list
        if (serviceId === undefined) {
          if (!globalState.services.data && !globalState.services.error) {
            fetchServices(clientId!)
          }
        }
        else if (serviceId && serviceId.length === 14) {
          // Load specific service
          fetchService(clientId!, serviceId)
        } else {
          dispatch({
            type: Actions.ERROR_JOB,
            payload: getNetworkErrors([
              new LocalError(
                'Invalid URL',
                undefined,
                { errorCode: '1401' }
              )
            ])[0]
          });
        }
      }
    // }
  }, [
    clientId,
    serviceId,
    globalState.client.data,
    globalState.client.error,
    globalState.services.data,
    globalState.services.error,
    fetchClient,
    fetchServices,
    fetchService,
    dispatch
  ]);

  useEffect(() => {
    if (jobId && !globalState.job.data && !globalState.job.error) {
      getJob();
    }
  }, [
    jobId,
    globalState.job.data,
    globalState.job.error,
    getJob
  ]);

  if (globalState.client.loading
    || globalState.services.loading
    || globalState.job.loading
  ) {
    return (
      <Spinner
        color={theme.textColor}
        size={'M'}
      />
    );
  }

  if (serviceId && page) {
    if (globalState.client.error
      || globalState.services.error
      || globalState.service.error
      || globalState.job.error
    ) {
      return (
        props.children({
          errors: getNetworkErrors([
            globalState.client.error
              || globalState.services.error
              || globalState.service.error
              || globalState.job.error
          ])
        })
      );
    }
  }

  if (!globalState.client.data || (serviceId && !globalState.service.data)) {
    return null;
  }

  // Validate incoming URL
  if (page && Number(page) <= 0) {
    return (
      props.children({
        errors: [
          new LocalError(
            'Invalid URL',
            undefined,
            { errorCode: '1401' }
          )
        ]
      })
    );
  }

  return (
    props.children({
      errors: null,
      page: serviceId && page ? Number(page) : 0,
      jobId,
      serviceId
    })
  );
};

export default BookingContainer;

