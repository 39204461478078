import styled from 'styled-components';
import { device } from 'theme/media';

export * from 'theme/mixins';

export const Wrapper = styled.div<{
  left: number | null;
  top: number | null;
  right: number | null;
  bottom: number | null;
  maxHeight?: number;
  maxWidth?: boolean;
  noPadding?: boolean;
}>`
  position: fixed;
  left: inherit;
  top: inherit;
  right: inherit;
  bottom: inherit;
  border: 1px solid ${({ theme }) => theme.colors.neutralPrimary};
  box-shadow: 0.2rem 1.5rem 2.9rem rgb(0 0 0 / 20%);
  padding: 1rem;
  background: ${({ theme }) => theme.colors.neutralSecondary};
  outline: none;
  opacity: 0;
  transform: scale(0.8);
  transition: all ${({ theme }) => theme.sideNav.animSpeed} ease-in-out;
  border-radius: .8rem;
  overflow: auto;

  ${({ noPadding }) => noPadding && `
    padding: 0;
  `};

  ${({ left }) => left && `
    right: unset;
    left: ${left}px;
  `};

  ${({ top }) => top && `
    bottom: unset;
    top: ${top}px;
  `};

  ${({ right }) => right && `
    left: unset;
    right: ${right}px;
  `};

  ${({ bottom }) => bottom && `
    top: unset;
    bottom: ${bottom}px;
  `};

  @media ${device.tablet} {
    ${({ maxHeight }) => maxHeight && maxHeight > 0 && `
      max-height: ${maxHeight}px;
    `};

    ${({ maxWidth }) => maxWidth && `
      max-width: min-content;
    `};
  }
`;

export const OuterWrapper = styled.div<{
  isLayered: boolean;
  isConvertable: boolean;
  hasFocus: boolean;
}>`
  display: flex;
  position: fixed;
  top: -9999px;
  width: 100%;
  z-index: ${({ theme }) => theme.layers.highest};

  ${({ hasFocus }) => hasFocus && `
    top: 0;

    ${Wrapper} {
      opacity: 1;
      transform: scale(1);
    }
  `}

  ${({ isLayered }) => isLayered && `
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);

    ${Wrapper} {
      position: unset;
    }
  `}

  ${({ isConvertable }) => isConvertable && `
    @media ${device.onlyMobile} {
      height: 100%;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.5);

      ${Wrapper} {
        position: fixed;
        top: unset;
        bottom: 0;
        left: 0;
        width: 100%;
        border-radius: unset;
        max-height: 100%;
      }
    }
  `}
`;

export const StyledArrow = styled.i``;
