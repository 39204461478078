import React, { FC, memo } from 'react';

import {
  Background,
  Footer
} from 'components/molecules';
import { PageProps } from 'components/AppRouter';
import { ApplicationError } from 'types/Error';

import {
  Wrapper,
  StyledCard,
  FormLine,
  StyledHeader,
  HR,
  StyledFormWrapper,
  ErrorDetails
} from './Error.styles';

const ErrorPage: FC<Pick<PageProps, 'errors'>> = props => {
  const {
    errors
  } = props;

  return (
    <>
      <Background />
      <Wrapper>
        <StyledCard boxShadow>
          <StyledFormWrapper>
            <FormLine
              center
              marginBottom
            >
              <StyledHeader>Ooops!</StyledHeader>
            </FormLine>

            <FormLine marginBottom>
              <HR />
            </FormLine>
            <FormLine marginBottom />

            <FormLine marginBottom>
              <p>Something went wrong. Please contact an administrator.</p>
            </FormLine>

            {errors && (
              <FormLine
                marginBottom
                column
              >
                {errors.map((error: ApplicationError, index: number) => (
                  <FormLine
                    key={index}
                    marginBottom
                    column
                  >
                    <ErrorDetails>Error code: {error.data.errorCode}</ErrorDetails>
                    <ErrorDetails>Message: {error.message}</ErrorDetails>
                  </FormLine>
                ))}
              </FormLine>
            )}
          </StyledFormWrapper>
        </StyledCard>
        <FormLine
          center
          marginBottom
        >
          <Footer />
        </FormLine>
      </Wrapper>
    </>
  );
};

export default memo(ErrorPage);
