import React, { FC, memo, useState, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';

import { theme } from 'theme';
import { getQueryParam } from 'utils/url';
import { gatewayService, authService } from 'services';
import { NetworkError } from 'types/Error';
import { getNetworkErrors } from 'utils/general';
import {
  Spinner,
  Text
} from 'components/atoms';
import {
  Background,
  Footer
} from 'components/molecules';

import {
  Wrapper,
  StyledCard,
  FormLine,
  StyledHeader,
  StyledFormWrapper,
  HR
} from './Verify.styles';

interface State {
  verify: {
    loading: boolean;
    data: any | null;
    error: NetworkError | null;
  };
}

const initialState: State = {
  verify: {
    loading: false,
    data: null,
    error: null
  },
};

const Verify: FC<any> = props => {
  const navigate = useNavigate();
  const location = useLocation();

  const id: string | undefined = getQueryParam('id', location.search);
  const token: string | undefined = getQueryParam('token', location.search);

  const [state, setState] = useState<State>(initialState);

  // handle redirect
  // TODO: show countdown before redirect to login page
  const postVerify = useCallback((verifyResponse: any) => {
    setTimeout(() => {
      navigate({ pathname: '/login' });
    }, 5000);
  }, [navigate]);

  const verify = useCallback(() => {
    if (state.verify.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      verify: {
        ...prevState.verify,
        loading: true
      }
    }));

    authService.default.invalidate();

    gatewayService.verify(id!, token!)
      .then((verifyResponse: any) => {
        setState(prevState => ({
          ...prevState,
          verify: {
            loading: false,
            data: verifyResponse.data,
            error: null
          }
        }));

        postVerify(verifyResponse.data);
      })
      .catch((err: any) => {
        setState(prevState => ({
          ...prevState,
          verify: {
            ...prevState.verify,
            loading: false,
            error: getNetworkErrors([err])[0]
          }
        }));
      });
  }, [
    id,
    token,
    state.verify,
    postVerify
  ]);

  useEffect(() => {
    if (!state.verify.data && !state.verify.error) {
      verify();
    }
  }, [state.verify, verify]);

  let submitError = null;

  if (state.verify.error) {
    submitError = (
      <Text
        isError
        value={state.verify.error.message}
      ></Text>
    );
  }

  return (
    <>
      <Background />
      <Wrapper>
        <StyledCard boxShadow>
          <StyledFormWrapper>
            <FormLine
              center
              marginBottom
            >
              <StyledHeader>Verifying your account</StyledHeader>
            </FormLine>

            <FormLine marginBottom>
              <HR />
            </FormLine>
            <FormLine marginBottom />

            <FormLine center>
              {state.verify.loading ? (
                <Spinner
                  color={theme.textColor}
                  size={'M'}
                />
              ) : !submitError && (
                <p>Account verified!</p>
              )}
            </FormLine>

            {submitError && (
              <FormLine
                center
                marginBottom
              >
                {submitError}
              </FormLine>
            )}
          </StyledFormWrapper>
        </StyledCard>
        <FormLine
          center
          marginBottom
        >
          <Footer />
        </FormLine>
      </Wrapper>
    </>
  );
};

export default memo(Verify);
