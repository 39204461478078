import styled from 'styled-components';

import { SpinnerProps } from './Spinner';

export const Wrapper = styled.div<{ inline?: boolean }>`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  ${({ inline }) => inline && `
    width: auto;
  `}
`

export const Inner = styled.div<{ size?: SpinnerProps['size']; color?: string; }>`
  display: inline-block;
  border-radius: 100%;
  border-style: solid;
  border-top-color: inherit;
  border-right-color: inherit;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-width: .1rem;
  animation: 600ms linear 0s infinite normal none running spinner-animation;

  ${({ size }) => size === 'S' && `
    width: 1.5rem;
    height: 1.5rem;
  `}

  ${({ size }) => size === 'M' && `
    width: 3.5rem;
    height: 3.5rem;
  `}

  ${({ size }) => size === 'L' && `
    width: 5.5rem;
    height: 5.5rem;
  `}

  ${({ color }) => color && `
    border-top-color: ${color};
    border-right-color: ${color};
  `}
`

