import styled from 'styled-components';

import { device } from 'theme/media';

export const Wrapper = styled.div`
  display: flex;
  width: inherit;
  flex-direction: column;
  align-items: center;
`;

export const Text = styled.p`
  font-size: 1.6rem;
`;

export const ContentContainer = styled.section`
  display: block;

  h2 {
    margin-bottom: 2rem;
    font-size: 2.8rem;
  }

  @media ${device.mobile} {
    width: 100%;
    margin-top: 2rem;
  }

  @media ${device.mobileL} {
    max-width: 96rem;
  }

  @media ${device.tablet} {
    max-width: 96rem;
    margin-top: 7rem;
  }
`;

export const Block = styled.div<{ altTheme?: boolean }>`
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: column;

  @media ${device.mobile} {
    padding: 10rem 1rem;
  }

  @media ${device.mobileL} {
    padding: 10rem 2rem;
  }

  @media ${device.tablet} {
    padding: 10rem 4rem;
  }
`;

