import styled from 'styled-components';

import { elemMixin } from '../form/mixins.styles';

export const StyledTextarea = styled.textarea<{ errored?: boolean; textRight?: boolean; }>`
  ${({ errored, textRight }) => elemMixin(errored, textRight)}

  min-height: 9rem;
`

