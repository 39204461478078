import styled from 'styled-components';
import { device } from 'theme/media';

export const Wrapper = styled.thead`
  position: sticky;
  top: 0;
  z-index: ${({ theme }) => theme.layers.low};
  background-color: ${({ theme }) => theme.colors.neutralSecondary};

  @media ${device.onlyMobile} {
    display: block;
  }
`;

export const Row = styled.tr`
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${({ theme }) => theme.table.borderColor};

  @media ${device.onlyMobile} {
    display: block;
    position: absolute;
    margin: 0 0 1rem 0;
    top: -9999px;
    left: -9999px;
  }
`;

export const TableHeader = styled.th<{ borderLeft?: boolean; stickyHeader?: boolean; }>`
  padding: ${({ theme }) => theme.table.padding};

  ${({ theme, borderLeft }) => borderLeft && `
    border-left-style: solid;
    border-left-color: ${theme.table.borderColor};
    border-left-width: 3px;
  `};

  @media ${device.onlyMobile} {
    display: block;
    padding: ${({ theme }) => theme.table.padding};
  }
`;

export const ColumnLabel = styled.span<{ isSortable: boolean }>`
  ${({ isSortable }) => isSortable && `
    cursor: pointer;
  `};
`;

export const FilterLabel = styled.u`
  float: right;
  font-weight: normal;
`;

