import styled from 'styled-components';

import {
  BaseButtonStyles,
  BaseContentWrapperStyles
} from './mixins.styles';

export const StyledPrimaryButton = styled.button<{ $width?: string; $loading?: boolean, iconOnly?: boolean; noPadding?: boolean; }>`
  ${({ $width, $loading, iconOnly, noPadding }) => BaseButtonStyles($width, $loading, iconOnly, noPadding)}

  color: ${({ theme }) => theme.primaryButton.color};
  background-color: ${({ theme }) => theme.primaryButton.backgroundColor};

  :not(:disabled):hover {
    background-color: ${({ theme }) => theme.primaryButton.hover.backgroundColor};
    border-color: ${({ theme }) => theme.primaryButton.hover.borderColor};
    box-shadow: rgb(60 66 87 / 8%) 0px 3px 9px 0px;
  }

  :not(:disabled):active {
    background-color: ${({ theme }) => theme.primaryButton.active.backgroundColor};
    border-color: ${({ theme }) => theme.primaryButton.active.borderColor};
  }

  :disabled {
    color: ${({ theme }) => theme.primaryButton.disabled.color};
    border-color: ${({ theme }) => theme.primaryButton.disabled.borderColor};

    svg {
      fill: ${({ theme }) => theme.button.disabled.color};
    }
  }

  ${({ theme, iconOnly }) => iconOnly && `
    svg {
      fill: ${theme.colors.corePrimaryDarker}
    }
  `}
`

export const ContentWrapper = styled.div`
  ${BaseContentWrapperStyles()}
`

