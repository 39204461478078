import styled from 'styled-components';
import { device } from 'theme/media';

import {
  MenuDots,
  Payments,
  Edit,
  Cross,
  Bin,
  Calendar,
  Profile,
  Groups
} from 'components/atoms';
import {
  ModalWrapper,
  AdminFormLine,
  ModalContentWrapper
} from 'theme/mixins';
export * from 'theme/mixins';

export const eventLabelStyles = (colour: string | null) => {
  const base = `
    display: inline-block;
    width: 1rem;
    height: 1rem;
    margin-right: .5rem;
    border-radius: 50%;
    flex-shrink: 0;
  `;

  if (colour) {
    return `
      ${base}
      background-color: ${colour};
      ${colour === '#FFFFFF' ?
        'border: .1rem solid;' :
        ''
      };
    `;
  }

  return `
    ${base}
    border: .1rem solid;
  `;
};

export const Wrapper = styled.div`
  display: flex;
  width: inherit;
  height: 100%;
  flex-direction: column;
`;

export const StatusIndicator = styled.i<{ isEnabled: boolean; }>`
  display: flex;
  position: relative;
  justify-content: center;
  padding-top: 2rem;
  font-style: normal;

  ${({ isEnabled }) => `
    :after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background-color: ${isEnabled ? 'green' : 'red'}
    }
  `}
`;

export const ContentWrapper = styled.div<{ column?: boolean; center?: boolean; }>`
  display: flex;
  justify-content: flex-start;
  width: 10rem;

  ${({ column }) => column && `
    flex-direction: column;
    align-items: center;
  `}

  ${({ center }) => center && `
    justify-content: center;
  `}

  :first-child {
    width: 3.5rem;
    height: 3.5rem;
    justify-content: flex-start;
  }

  :last-child {
    width: 3.5rem;
    height: 3.5rem;
    justify-content: flex-end;
  }
`;

export const JobStatus = styled.div<{ state: number; }>`
  display: flex;
  border: .1rem solid transparent;
  padding: 1rem;
  border-radius: 1.25rem;
  font-weight: 700;

  ${({ theme, state }) => state === 0 && `
    background-color: ${theme.colors.neutralPrimary};
    border: .2rem solid ${theme.colors.neutralPrimaryDark1};
  `}

  ${({ theme, state }) => state === 1 && `
    background-color: ${theme.colors.neutralPrimary};
    border: .2rem solid ${theme.colors.accentSecondaryLight};
  `}

  ${({ theme, state }) => state === 2 && `
    background-color: ${theme.colors.accentSecondaryLight};
  `}

  ${({ theme, state }) => state === 3 && `
    background-color: ${theme.colors.neutralPrimary};
    border: .2rem solid ${theme.colors.accentTertiary};
  `}
`;

export const StyledMenuDots = styled(MenuDots)`
  fill: ${({ theme }) => theme.colors.corePrimaryDarker};
  transform: rotateZ(90deg);
`;

export const StyledPayments = styled(Payments)<{ state: boolean; }>`
  transform: rotateZ(45deg);

  ${({ theme, state }) => !state && `
    fill: ${theme.colors.neutralPrimaryDark1};
  `}
`;

export const JobPreview = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const JobDetails = styled.div<{ isExpanded: boolean; }>`
  display: flex;
  max-height: 0;
  flex-direction: column;
  text-align: left;
  transition: max-height ${({ theme }) => theme.animSpeed} ease-in-out;
  cursor: default;
  overflow: hidden;

  ${({ isExpanded }) => isExpanded && `
    max-height: 25rem;
  `};
`;

export const JobDetailsOuter = styled.div`
  display: flex;
  width: 100%;
  max-height: inherit;
  flex-direction: column;
  overflow-x: auto;
  margin-top: 3rem;
  padding-top: 2rem;
  border-top: .1rem solid ${({ theme }) => theme.colors.neutralPrimaryDark};
`;

export const JobDetailsInner = styled.div<{ groupCount: number }>`
  display: flex;
  flex-direction: row;
  width: calc(29.999rem * ${({ groupCount }) => groupCount});
  overflow-y: auto;
  // margin-top: 3rem;
`;

export const GroupDetailsHeader = styled.h3`
  position: sticky;
  top: 0;
  margin-bottom: 0;
  padding-bottom: 1rem;
  background-color: ${({ theme }) => theme.colors.neutralSecondary};
`;

export const GroupDetails = styled.div`
  display: flex;
  flex-direction: column;
  // flex: 1;
  width: 29.999rem;
  padding: 0 1rem;
`;

export const GroupFields = styled.p`
  display: flex;
  flex-direction: row;
  margin-bottom: .5rem;
`;

export const FieldLabel = styled.span`
  flex: 1;
  text-align: left;
  color: ${({ theme }) => theme.colors.neutralPrimaryDarker};
  word-break: break-word;
`;

export const FieldValue = styled.span`
  flex: 1;
  text-align: left;
  word-break: break-word;
`;

export const JobDetailsFooter = styled.p`
  text-align: right;
`;

export const StyledEditIcon = styled(Edit)`
  width: 3.2rem;
  height: 3.2rem;
  padding-right: .20rem;
  cursor: pointer;
`;

export const StyledCrossIcon = styled(Cross)`
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
`;

export const StyledBinIcon = styled(Bin)`
  width: 2.2rem;
  height: 2.2rem;
  padding: 0 .20rem;
  cursor: pointer;
`;

export const StyledCalendar = styled(Calendar)`
  width: 3.2rem;
  height: 3.2rem;
`;

export const StyledProfile = styled(Profile)`
  width: 2.2rem;
  height: 2.2rem;
`;

export const StyledGroups = styled(Groups)`
  width: 2.2rem;
  height: 2.2rem;
  flex-shrink: 0;
`;

export const EventColour = styled.div<{ colour: string | null; }>`
  ${({ colour }) => eventLabelStyles(colour)}
  margin-left: 0;
  margin-right: 0;
`;

export const EventsModalWrapper = styled(ModalWrapper)`
  @media ${device.tablet} {
    max-width: 75rem;
  }
`;

export const ViewEventRow = styled(AdminFormLine)`
  margin-bottom: .5rem;
`;

export const ViewEventIconWrapper = styled(AdminFormLine)`
  width: 3.2rem;
  height: 3.2rem;
  margin-right: 1rem;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

export const NewEditModalContentWrapper = styled(ModalContentWrapper)`
  @media ${device.tablet} {
    min-width: 40.4rem;
  }
`;

