import styled from 'styled-components';

import { device } from 'theme/media';
import { AdminFormWrapper, AdminFormLine  } from 'theme/mixins';
export * from 'theme/mixins';

export const Wrapper = styled.div`
  display: flex;
  width: inherit;
  height: 100%;
  flex-direction: column;
`;

export const FormWrapper = styled(AdminFormWrapper)`
  padding: 0;
  text-align: left;
`;

export const Sections = styled(AdminFormLine)`
  > div {
    flex: 1;
  }

  @media ${device.mobile} {
    flex-direction: column;
  }

  @media ${device.mobileL} {
    flex-direction: row;
  }
`;

