import React from 'react';

export enum CellType {
  Text = 'text',
  String = 'string',
  Number = 'number',
  Currency = 'currency',
  Password = 'password',
  Dropdown = 'dropdown',
  Boolean = 'boolean',
  Array = 'array',
  ArrayMap = 'array-map',
  Action = 'action',
  ActionMenu = 'action-menu',
  Icon = 'icon'
}

export enum RowAction {
  Key = 'actions',
  Add = 'add',
  Edit = 'edit',
  Delete = 'delete',
  Cancel = 'cancel'
}

export interface ITableHeader {
  key: string;
  type: CellType;
  hide?: (editMode?: boolean) => boolean;
  label?: string;
  width?: string;
  isOptional?: boolean;
  dropdownOptions?: Array<{ label: string; value: string; }> | (() => Array<{ label: string; value: string; }>);
  editable?: boolean;
  sortable?: boolean;
  newRowPrefix?: string;
  customWrapper?: (children: React.ReactNode) => React.ReactNode;
  format?: (
    value: any,
    row: any,
    index: number,
    editRow: any
  ) => any;
  subColumns?: ITableHeader[];
  mergeWithParent?: boolean;
  isInternal?: boolean;
  renderIcon?: (row?: any) => React.ReactNode;
  renderMenu?: (
    opts: {
      row: any;
      activeMenu?: any | null;
    },
    onItemClick: (row: any, action: RowAction) => void,
    onClose?: () => void,
  ) => React.ReactNode;
  onIconClick?: (row: any, onIconClick: (row: any, action: RowAction) => void) => void;
  dependant?: {
    on: string;
    getDropdownOptions?: (dependantValue: any, currentDropdownOptions: any) => Array<{ label: string; value: string; }>;
    onChangeType?: (dependantValue: any) => CellType;
  }
}

