import React, { FC, memo, useCallback } from 'react';
import { format } from 'date-fns';

import {
  Text,
  Logo
} from 'components/atoms';
import { Status, Change, ChangeType } from 'types/Job';
import {
  Client,
  AccountingPlatform
} from 'types/Client';
import {
  getStatusState,
  formatJobStatus,
  stripZoneFromISOString,
  formatCurrency
} from 'utils/general';

import {
  Wrapper,
  Outer,
  Inner,
  Info,
  JobStatus,
  ChangesWrapper
} from './StatusHistory.styles';

interface StatusHistoryProps {
  statuses: Status[];
  clientSettings: Client['settings'];
}

const getInvoiceIcon = (platform: AccountingPlatform): JSX.Element => {
  let icon = null;

  switch (platform) {
    case AccountingPlatform.None:
      icon = (
        <Logo shortened />
      );
      break;
    case AccountingPlatform.QuickBooks:
      icon = (
        <img
          width="100%"
          height="100%"
          alt="QuickBooks logo"
          src="/images/quickbooks-logo.png"
        />
      );
      break;
    case AccountingPlatform.Xero:
      icon = (
        <img
          width="100%"
          height="100%"
          alt="Xero logo"
          src="/images/xero-logo.png"
        />
      );
      break;
  };

  return (
    <div style={{
      display: 'inline-block',
      position: 'relative',
      top: '.5rem',
      width: '2rem',
      height: 'auto',
      marginRight: '.75rem'
    }}>{icon}</div>
  );
};

const getChangeDescription = (
  change: Change,
  clientSettings: Client['settings']
): JSX.Element => {
  let descriptionElem;
  const previousValue = change.previousValue || '-';
  const currentValue = change.currentValue || '-';

  switch (change.type) {
    case ChangeType.JOB_FIELD:
      descriptionElem = (
        <span>Job field changed&nbsp;</span>
      );
      break;
    case ChangeType.FULFILLER:
      descriptionElem = (
        <span>Fulfiller changed from <b>{previousValue}</b> to <b>{currentValue}</b>&nbsp;</span>
      );
      break;
    case ChangeType.FULFILLER_PAY:
      descriptionElem = (
        <span>Fulfiller pay changed from <b>{formatCurrency(previousValue)}</b> to <b>{formatCurrency(currentValue)}</b>&nbsp;</span>
      );
      break;
    case ChangeType.JOB_DATE:
      descriptionElem = (
        <span>Date changed from <b>{format(previousValue === 'none' ? new Date() : new Date(stripZoneFromISOString(previousValue)), 'dd/MM/yyyy, HH:mm')}</b> to <b>{format(currentValue === 'none' ? new Date() : new Date(stripZoneFromISOString(currentValue)), 'dd/MM/yyyy, HH:mm')}</b>&nbsp;</span>
      );
      break;
    case ChangeType.GB_INVOICE_GENERATED:
      descriptionElem = (
        <>
          {getInvoiceIcon(currentValue)}
          <span>invoice generated&nbsp;</span>
        </>
      );
      break;
    case ChangeType.GB_INVOICE_SENT:
      descriptionElem = (
        <>
          {getInvoiceIcon(currentValue)}
          <span>invoice sent to customer&nbsp;</span>
        </>
      );
      break;
    case ChangeType.THIRD_PARTY_INVOICE_GENERATED:
      descriptionElem = (
        <>
          {getInvoiceIcon(currentValue)}
          <span>invoice generated/synched&nbsp;</span>
        </>
      );
      break;
    case ChangeType.THIRD_PARTY_INVOICE_SENT:
      descriptionElem = (
        <>
          {getInvoiceIcon(currentValue)}
          <span>invoice sent to customer&nbsp;</span>
        </>
      );
      break;
    case ChangeType.JOB_PRICE:
      descriptionElem = (
        <span>Price changed from <b>{formatCurrency(previousValue)}</b> to <b>{formatCurrency(currentValue)}</b>&nbsp;</span>
      );
      break;
  }

  return (
    <>
      {descriptionElem}
      <span>by <b>{change.author.name}</b>&nbsp;</span>
      <span>on <b>{format(new Date(change.date), 'dd/MM/yyyy, HH:mm')}</b></span>
    </>
  );
};

const StatusHistory: FC<StatusHistoryProps> = props => {
  const {
    statuses,
    clientSettings
  } = props;

  const renderStatus = useCallback(() => {
    return statuses.map((status: Status, index: number) => {
      const isFirst: boolean = index === 0;
      const text: string = formatJobStatus(status.type);
      const textElem = (
        <Text
          noWidth
          noMinWidth
          value={text}
        />
      );

      return (
        <React.Fragment key={`${status.type}-${status.date}`}>
          <Outer isFirst={isFirst}>
            <Inner>
              <JobStatus
                state={getStatusState(status.type)}
              >
                {textElem}
              </JobStatus>
            </Inner>
            <Info>
              <div>
                <span><i>{status.description}</i>&nbsp;</span>
                <span>by <b>{status.author.name}</b>&nbsp;</span>
                <span>on <b>{format(new Date(status.date), 'dd/MM/yyyy, HH:mm')}</b></span>
              </div>
            </Info>
          </Outer>
          <ChangesWrapper>
            {status.changes?.map((change) => {
              return (
                <Outer
                  key={`${change.type}-${change.date}`}
                >
                  <Inner></Inner>
                  <Info isComment>
                    <div>{getChangeDescription(change, clientSettings)}</div>
                  </Info>
                </Outer>
              );
            })}
          </ChangesWrapper>
        </React.Fragment>
      );
    });
  }, [
    statuses,
    clientSettings
  ]);

  return (
    <Wrapper>
      {renderStatus()}
    </Wrapper>
  );
};


export default memo(StatusHistory);

