import React, { FC, useReducer, useEffect, useCallback } from 'react';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { useLocation } from "react-router-dom";

import {
  isInIframe,
} from 'utils/general';
import {
  useToast,
  DragPreviewLayer
} from 'components/molecules';

import AppRouter from './AppRouter';
import {
  initialState,
  reducer,
  loadDeviceData,
  saveFormData
} from '../state';

const onBeforeUnload = (e: any) => {
  delete e['returnValue'];
};

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const rootElem: HTMLElement | null = document.querySelector('#root');

    if (rootElem) {
      rootElem.scrollTo({
        top: 0,
        left: 0,
        // @ts-ignore
        behavior: "instant", // Optional if you want to skip the scrolling animation
      });
    }
  }, [pathname]);

  return null;
}


const App: FC = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    render: renderToast,
    add
  } = useToast();

  const reducerProps = {
    state,
    dispatch
  };
  const toastProps = {
    addToast: add
  };

  loadDeviceData(state, dispatch);
  saveFormData(state);

  const onMessage = useCallback((event: any) => {
    if (event.origin === window.location.origin) {
      let data = null;

      try {
        data = JSON.parse(event.data);
      } catch (e) {};

      if (!data) {
        return;
      }

      if (isInIframe(window) && data.to === 'child') {
        // console.log('--------Child', event);

        dispatch({
          type: data.action,
          payload: data.payload
        });
      }
    }
  }, []);

  const removeMessageListener = useCallback(() => {
    window.removeEventListener('message', onMessage);
  }, [onMessage]);

  const addMessageListener = useCallback(() => {
    window.addEventListener('message', onMessage);
  }, [onMessage]);

  useEffect(() => {
    window.addEventListener('beforeunload', (e) => onBeforeUnload(e));
    addMessageListener();

    return () => {
      window.removeEventListener('beforeunload', (e) => onBeforeUnload(e));
      removeMessageListener();
    }
  }, [
    state,
    addMessageListener,
    removeMessageListener
  ]);

  return (
    <DndProvider
      backend={TouchBackend}
      options={{enableMouseEvents: true}}
    >
      <AppRouter
        {...reducerProps}
        {...toastProps}
      />
      {renderToast()}
      <DragPreviewLayer />
      <ScrollToTop />
    </DndProvider>
  )
}

export default App;

