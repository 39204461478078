import React, { FC, memo, useState, useCallback, useMemo, useEffect, useRef } from 'react';
import {
  Route,
  Routes,
  useParams,
  useLocation
} from "react-router";

import { theme } from 'theme';
import { sizes } from 'theme/media';
import { gatewayService } from 'services';
import navConfig, { Link } from 'config/nav';
import { getNavItems } from 'config/privileges';
import { Client } from 'types/Client';
import { AppBanner as AppBannerType } from 'types/Generic';
import { NetworkError } from 'types/Error';
import {
  isMinWidth,
  updatePinnables
} from 'utils/general';
import ErrorPage from '../../pages/error';

import {
  User,
  Background,
  AppBanner
} from 'components/molecules';

import {
  Logo,
  Spinner
} from 'components/atoms';

import { Actions } from 'state';
import { PageProps } from 'components/AppRouter';
import { AdminPageProps } from 'components/AppRouter';
import { RootDashboard }from './pages/dashboard';
import { Profile }from './pages/profile';
import { Billing }from './pages/billing';
import { Help }from './pages/help';
import { Services, Service }from './pages/services';
import { PricingRules }from './pages/pricing-rules';
import { Jobs, Job as JobPage } from './pages/jobs';
import { Workforce, UserPage }from './pages/workforce';
import { Calendar }from './pages/calendar';
import Settings from './pages/settings';
import {
  useMediaSizes,
  UseMediaSizesState
} from './hooks';
import {
  isPlatformAdmin,
  isPlatformReviewer,
  isValidUserAndClient
} from 'config/privileges';
import { getNetworkErrors } from 'utils/general';

import {
  Wrapper,
  InnerWrapper,
  Sidebar,
  Main,
  MainInner,
  LogoWrapper,
  LogoOuterWrapper,
  Nav,
  NavItem,
  IconOuterWrapper,
  NavIconWrapper,
  StyledNavLink,
  SidebarSplitter,
  MenuIcon,
  SidebarCloser,
  SiteHeader,
  StyledChevron,
  StyledLink,
  ClientContext
} from './Root.styles';

export enum Fields {
  propertyType = 'Property Type',
  propertyAge = 'Property Age',
  bathroomCount = 'Bathroom Count',
  roomCount = 'Room Count',
  circuitCount = 'Circuit Count'
}

interface State {
  currentClient: {
    loading: boolean;
    data: Client | null;
    error: NetworkError[] | null;
  };
  appBanners: {
    loading: boolean;
    data: AppBannerType[] | null;
    error: NetworkError[] | null;
  };
}

const initialState: State = {
  currentClient: {
    loading: false,
    data: null,
    error: null
  },
  appBanners: {
    loading: false,
    data: null,
    error: null
  },
};

const Root: FC<PageProps> = props => {
  const userData = props.state.userData;

  const {
    addToast,
    dispatch
  } = props;

  const [state, setState] = useState<State>(initialState);
  const [sideNavOpen, setSideNavOpen] = useState<boolean>(isMinWidth(sizes.laptop));
  const [sideNavHidden, setSideNavHidden] = useState<boolean>(!isMinWidth(sizes.laptop));
  const mainInnerElemRef = useRef<HTMLDivElement | null>(null);

  const { clientId, id } = useParams();
  const location = useLocation();

  const onMediaResize = useCallback(({ isMobile: isM, isLaptop }: UseMediaSizesState): void => {
    setSideNavOpen((prevState) => {
      const newState: boolean = isLaptop;

      if (prevState !== newState) {
        return newState;
      }

      return prevState;
    });
    setSideNavHidden((prevState) => {
      const newState: boolean = !isLaptop;

      if (prevState !== newState) {
        return newState;
      }

      return prevState;
    });
  }, []);

  const { isMobile } = useMediaSizes({ onMediaResize });

  const noClientSelected = useMemo(() => clientId === 'all', [clientId]);

  const navConfigLocal = useMemo(() => {
    if (!userData.user) {
      return [];
    }

    return getNavItems(
      navConfig(clientId!),
      userData.user,
      clientId!
    );
  }, [
    userData,
    clientId
  ]);

  const validateToken = useCallback((opts?: any) => {
    if (userData.loading) {
      return;
    }

    dispatch({
      type: Actions.UPDATE_USER_CACHE,
      payload: {
        loading: true,
        user: null,
        fields: {},
        errors: null
      }
    });

    gatewayService.getUserByToken(clientId!)
      .then((userResponse: any) => {
        dispatch({
          type: Actions.UPDATE_USER_CACHE,
          payload: {
            loading: false,
            user: userResponse.data,
            fields: userResponse.fields,
            errors: null
          }
        });
      })
      .catch((err) => {
        dispatch({
          type: Actions.UPDATE_USER_CACHE,
          payload: {
            loading: false,
            user: null,
            fields: {},
            errors: getNetworkErrors([err])
          }
        });
      });
  }, [
    userData,
    clientId,
    dispatch
  ]);

  const fetchClient = useCallback(() => {
    if (state.currentClient.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      currentClient: {
        ...prevState.currentClient,
        loading: true
      }
    }));

    gatewayService.getClient(clientId!)
      .then((clientResponse: any) => {
        setState(prevState => ({
          ...prevState,
          currentClient: {
            ...prevState.currentClient,
            loading: false,
            data: clientResponse.data,
            error: null
          }
        }));
      })
      .catch((err) => {
        setState(prevState => ({
          ...prevState,
          currentClient: {
            ...prevState.currentClient,
            loading: false,
            error: getNetworkErrors([err])
          }
        }));

        addToast({
          type: 'error',
          content: 'Error loading client.'
        });
      });
  }, [
    state.currentClient,
    clientId,
    addToast
  ]);

  const fetchAppBanners = useCallback(() => {
    if (state.appBanners.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      appBanners: {
        ...prevState.appBanners,
        loading: true
      }
    }));

    gatewayService.getAppBanners(clientId!)
      .then((appBannersResponse: any) => {
        setState(prevState => ({
          ...prevState,
          appBanners: {
            ...prevState.appBanners,
            loading: false,
            data: appBannersResponse.data,
            error: null
          }
        }));
      })
      .catch((err) => {
        setState(prevState => ({
          ...prevState,
          appBanners: {
            ...prevState.appBanners,
            loading: false,
            error: getNetworkErrors([err])
          }
        }));
      });
  }, [
    state.appBanners,
    clientId
  ]);

  const addScrollEventListener = useCallback(() => {
    if (mainInnerElemRef.current) {
      mainInnerElemRef.current.addEventListener('scroll', updatePinnables, false);
    }
  // eslint-disable-next-line
  }, [
    userData.user,
    updatePinnables
  ]);

  const removeScrollEventListener = useCallback(() => {
    if (mainInnerElemRef.current) {
      mainInnerElemRef.current.removeEventListener('scroll', updatePinnables, false);
    }
  }, []);

  const adminPageProps: AdminPageProps = {
    ...props,
    userData,
    sideNavOpen
  };

  useEffect(() => {
    validateToken();
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchAppBanners();
  // eslint-disable-next-line
  }, [location.key]);

  useEffect(() => {
    if (userData.user && (isPlatformAdmin(userData.user!) || isPlatformReviewer(userData.user!))) {
      if (
        clientId !== 'all'
          &&
          ((!state.currentClient.data && !state.currentClient.error)
            ||
          (state.currentClient.data && state.currentClient.data._id !== clientId))
      ) {
        fetchClient();
      }
    }
  }, [
    userData.user,
    state.currentClient.data,
    state.currentClient.error,
    clientId,
    fetchClient
  ]);

  // Observe section headers being pinned
  useEffect(() => {
    addScrollEventListener();

    return () => {
      removeScrollEventListener();
    };
  }, [
    addScrollEventListener,
    removeScrollEventListener,
  ]);

  if (userData.loading) {
    return (
      <Spinner
        color={theme.textColor}
        size={'M'}
      />
    );
  }

  if (userData.errors?.length && userData.errors[0].status !== 401) {
    return (
      <ErrorPage
        errors={userData.errors}
      />
    );
  }

  if (!userData.user) {
    return (
      <Spinner
        color={theme.textColor}
        size={'M'}
      />
    );
  }

  const isInServiceBuilder: boolean = !!location.pathname.match(/services\/ser_[A-Za-z0-9_-]{10}\/edit|services\/create/);
  const isInPricingBuilder: boolean = !!location.pathname.match(/pricing-rules/) && !!location.search.match(/\?service=ser_[A-Za-z0-9_-]{10}/);
  const isInBuilder: boolean = isInServiceBuilder || isInPricingBuilder;
  const showClientContext: boolean = !noClientSelected && (isPlatformAdmin(userData.user!) || isPlatformReviewer(userData.user!));

  return (
    <Wrapper>
      <Sidebar
        navOpen={sideNavOpen}
        navHidden={sideNavHidden}
      >
        <LogoOuterWrapper navOpen={sideNavOpen}>
          <StyledNavLink to={{ pathname: `/${clientId}/back-office/dashboard` }}>
            <LogoWrapper background>
              <Logo />
            </LogoWrapper>
          </StyledNavLink>
        </LogoOuterWrapper>
        <Nav>
          {navConfigLocal.map((link: Link) => (
            <NavItem key={link.text} active={!!(link.active || location.pathname.match(link.url))}>
              <StyledNavLink
                to={{ pathname: link.url }}
                onClick={() => {
                  if (isMobile) {
                    setSideNavHidden(true);
                  }
                }}
              >
                <IconOuterWrapper>
                  <NavIconWrapper>
                    {link.icon}
                  </NavIconWrapper>
                </IconOuterWrapper>
                <span>{link.text}</span>
              </StyledNavLink>
              {link.children && (
                <Nav isSub>
                  {link.children.map((subLink: Link) => (
                    <NavItem key={subLink.text}>
                      <StyledNavLink to={{ pathname: subLink.url }}>
                        <span>{subLink.text}</span>
                      </StyledNavLink>
                    </NavItem>
                  ))}
                </Nav>
              )}
            </NavItem>
          ))}
        </Nav>
      </Sidebar>
      <SidebarCloser
        navOpen={sideNavOpen}
        navHidden={sideNavHidden}
        hide={isInBuilder}
        onClick={() => setSideNavHidden(!sideNavHidden)}
      >
        <MenuIcon
          active={!sideNavHidden}
          color={theme.colors.corePrimary}
          onTap={() => {}}
        />
      </SidebarCloser>
      <SidebarSplitter
        navOpen={sideNavOpen}
        navHidden={sideNavHidden}
        onClick={() => setSideNavOpen(!sideNavOpen)}
      ><StyledChevron /></SidebarSplitter>
      <Main
        navOpen={sideNavOpen}
        onClick={() => {
          setSideNavHidden(prevState => {
            if (isMobile) {
              if (!prevState) {
                return true;
              }
            }

            return prevState;
          });
        }}
      >
        <Background />
        <MainInner
          ref={mainInnerElemRef}
          hideHeader={isInBuilder}
        >
          <SiteHeader hide={isInBuilder}>
            <User
              clientId={clientId!}
              userData={userData}
              sideNavOpen={sideNavOpen}
            />
          </SiteHeader>
          <InnerWrapper
            navOpen={sideNavOpen}
            removeBottomPadding={isInBuilder}
          >
            {/* TODO: show error page similar to customer facing when below is false */}
            {isValidUserAndClient(userData.user, clientId!, state.currentClient.data) && (
              <>
                {showClientContext && (
                  <ClientContext
                    marginBottom
                    isInBuilder={isInBuilder}
                  >
                    {state.currentClient.loading ? (
                      <Spinner
                        inline
                        color={theme.textColor}
                        size={'S'}
                        style={{ marginRight: '1rem' }}
                      />
                    ) : state.currentClient.data ? (
                      <span><b>{state.currentClient.data.businessName}&nbsp;-&nbsp;</b></span>
                    ) : null}
                    <StyledLink
                      to={{ pathname: `/all/back-office/dashboard` }}
                      onClick={(e: any) => e.stopPropagation() }
                    >
                      <b>Switch client</b>
                    </StyledLink>
                  </ClientContext>
                )}

                {(state.appBanners.data || []).map((banner) => (
                  <AppBanner
                    key={banner.content}
                    banner={banner}
                  />
                ))}

                <Routes>
                  <Route
                    path="/dashboard"
                    element={<RootDashboard {...adminPageProps} {...props} noClientSelected={noClientSelected} />}
                  />
                  <Route
                    path="/services"
                    element={<Services {...adminPageProps} {...props} />}
                  />
                  <Route
                    path="/services/create"
                    element={<Service {...adminPageProps} {...props} />}
                  />
                  {id !== 'create' && (
                    <Route
                      path="/services/:id"
                      element={<Service {...adminPageProps} {...props} />}
                    />
                  )}
                  <Route
                    path="/services/:id/edit"
                    element={<Service {...adminPageProps} {...props} />}
                  />
                  <Route
                    path="/jobs"
                    element={<Jobs {...adminPageProps} {...props} />}
                  />
                  <Route
                    path="/jobs/create"
                    element={<JobPage {...adminPageProps} {...props} />}
                  />
                  {id !== 'create' && (
                    <Route
                      path="/jobs/:id"
                      element={<JobPage {...adminPageProps} {...props} />}
                    />
                  )}
                  <Route
                    path="/jobs/:id/edit"
                    element={<JobPage {...adminPageProps} {...props} />}
                  />
                  <Route
                    path="/pricing-rules"
                    element={<PricingRules {...adminPageProps} {...props} />}
                  />
                  <Route
                    path="/workforce"
                    element={<Workforce {...adminPageProps} {...props} />}
                  />
                  <Route
                    path="/workforce/:id"
                    element={<UserPage {...adminPageProps} {...props} />}
                  />
                  <Route
                    path="/workforce/:id/edit"
                    element={<UserPage {...adminPageProps} {...props} />}
                  />
                  <Route
                    path="/settings"
                    element={<Settings {...adminPageProps} {...props} />}
                  />
                  <Route
                    path="/profile"
                    element={<Profile {...adminPageProps} {...props} />}
                  />
                  <Route
                    path="/calendar"
                    element={<Calendar {...adminPageProps} {...props} />}
                  >
                  </Route>
                  <Route
                    path="/billing"
                    element={<Billing {...adminPageProps} {...props} />}
                  />
                  <Route
                    path="/help"
                    element={<Help {...adminPageProps} {...props} />}
                  />
                </Routes>
              </>
            )}
          </InnerWrapper>
        </MainInner>
      </Main>
    </Wrapper>
  );
};

export default memo(Root);

