import React, { FC, useCallback, useState, useEffect, useRef, useMemo, memo } from 'react';
import ReactDOM from 'react-dom';
import { useParams, useNavigate, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  object,
  string,
  number,
  array,
  mixed,
  AnyObject
} from 'yup';
import {
  format,
  isSameDay,
  addMinutes,
  formatDuration,
  setHours,
  isSameMonth,
  startOfMonth,
  intervalToDuration,
  differenceInMinutes,
  addHours,
  addDays,
  setMinutes,
  setSeconds,
  setDate,
  setMonth,
  setYear,
  setMilliseconds
} from 'date-fns';

import { gatewayService } from 'services';
import { Service, Step, Field, FieldType } from 'types/Service';
import {
  Job as JobType,
  Status,
  StatusType,
  AdditionalCharge,
  DateEntryMethod
} from 'types/Job';
import { Fulfiller } from 'types/Fulfiller';
import { Client } from 'types/Client';
import { Customer } from 'types/Customer';
import {
  BrowserWindow,
  DropdownOption,
  GroupedOption
} from 'types/UI';
import { ITableHeader, CellType } from 'types/Header';
import { Payment, Source } from 'types/Payment';
import {
  useDebouncedCallback,
  useValidation,
  useAutoFocus
} from 'components/hooks';
import { Actions } from 'state';
import {
  createDropdownOption,
  formatCurrency,
  getRawCurrencyValue,
  animateValue,
  getPaymentLink,
  copyText,
  formatJobStatus,
  getPaymentService,
  objectIsEmpty,
  getNetworkErrors,
  getFlattenFormFields,
  filterOutJobPageSteps,
  filterOutJobPageStepsForValidation,
  isMinWidth,
  getFieldName,
  isDeepEqual,
  findLastStatusType,
  getCustomerName,
  accountingPlatformSelectedAndNotAuthorized,
  invoiceNotGenerated,
  isCustomerInvoiceReady,
  isJobOverdue,
  stripZoneFromISOString,
  getJobToCopy,
  extractFirstLastName,
  doubleSingleDigit,
  getJobDuration,
  extractNumericInput,
  addCountryCodeToNumber,
  stripCountryCodeFromNumber,
  stripLeadingZeroFromNumber,
  getFulfillerPayPercentage
} from 'utils/general';
import { theme } from 'theme';
import { sizes } from 'theme/media';
import { getQueryParam } from 'utils/url';
import {
  useSortFilter,
  useMediaSizes,
  UseMediaSizesState
} from '../../hooks';
import { FrameActions } from 'state';
import {
  FormSection,
  FormField,
  TextInput,
  Dropdown,
  AsyncCreatableDropdown,
  NumericInput,
  TextArea,
  Button,
  Spinner,
  PrimaryButton,
  Switch,
  Edit,
  Text,
  Phone,
  Payments,
  Payment as PaymentIcon,
  Notes,
  Tick,
  Warning,
  NotificationsOff,
  Checkbox,
  Clock,
  QuickReference,
  Worker,
  FileCopy,
  Settings,
  CreatableDropdown
} from 'components/atoms';
import {
  Popup,
  DateTimeFields,
  StatusHistory,
  PostcodeLookup
} from 'components/molecules';
// TODO: maybe move some of these to settings
import {
  canCreateJob,
  canUpdateJob,
  isFulfiller,
  concealJobPrice,
  canUpdateJobStatus,
  canRefund,
  canReadPayments,
  canUpdateCustomer,
  canHandlePayments,
  filterBookingChanges
} from 'config/privileges';
import { PageHeader, Table } from '../../components';
import { AdminPageProps } from 'components/AppRouter';
import {
  NetworkError
} from 'types/Error';
import {
  StyledRefund,
  IconButton,
  MenuWrapper,
  MenuItem,
  StyledMenuDots,
  StyledFrame,
  StatNumber,
  StyledClock,
  HR
} from 'theme/mixins';
import {
  ISO_FORMAT,
  VAT_RATE,
  FULFILLER_TYPE_DROPDOWN_OPTIONS
} from '../../../../../constants';

import { BuilderStep, defaultGenericStep } from '../services/BuilderStep';
import {
  StepNameModal
} from '../services/modals';

import {
  Wrapper,
  CreateFormWrapper,
  AdminFormLine,
  Card,
  PaymentLink,
  StatusWrapper,
  ModalWrapper,
  ModalItem,
  ModalBorder,
  StyledTextInput,
  ModalContentWrapper,
  StyledLinkExternal,
  ImportantPriceText,
  GeneratedPrice,
  StyledCopyIcon,
  BuilderWrapper,
  FulfillerInfo,
  FulfillerDropdown,
  FulfillerPay,
  PriceWrapper,
  StyledPlus,
  WarningCard,
  WarningText,
  WarningAction,
  VatWrapper,
  SettingDateAndTime,
  SettingDuration,
  DateKey,
  DateSplitter,
  TimeSplitter
} from './Job.styles';

import {
  StyledHR,
  Grid,
  PreviewPane,
  Device,
  FrameSpinner,
  MobileSettingsWrapper,
  IFrameSectionWrapper,
  StyledCross,
  ConfigWrapper,
  ConfigCard,
  ConfigCardColumn,
  ConfigDetails,
  ConfigDetailsOuter,
  MobileSettingIconWrapper,
  StyledMobileEditIcon
} from '../services/Service.styles';

interface State {
  customers: {
    loading: boolean;
    data: Customer[] | null;
    total: number | null;
    fields: any;
    offset: number;
    error: NetworkError | null;
  };
  // TODO: rename to something more descriptive of the creatable dropdown and modal
  customerModal: {
    loading: boolean;
    data: Customer | null;
    error: NetworkError | null;
    show: boolean;
    form: any;
    mode: 'create' | 'update' | null;
  };
  fulfillerSettingsModal: {
    show: boolean;
  };
  unavailableDates: {
    loading: boolean;
    data: string[] | undefined;
    error: NetworkError | null;
  };
  timeSlots: {
    loading: boolean;
    data: any | null;
    error: NetworkError | null;
    selectedTimeSlotIndex: number;
  };
  availability: {
    loading: boolean;
    data: {
      isAvailable: boolean;
      availableFulfillerIds: string[];
    } | null;
    error: NetworkError | null;
  };
  dateEntrySettingsModal: {
    show: boolean;
  };
  job: {
    loading: boolean;
    data: JobType | null;
    error: NetworkError | null;
  };
  jobFields: {
    loading: boolean;
    data: any | null;
    error: NetworkError | null;
  };
  jobCreate: {
    loading: boolean;
    data: any | null;
    error: NetworkError | null;
  };
  jobUpdate: {
    loading: boolean;
    data: any | null;
    error: NetworkError | null;
  };
  jobSync: {
    loading: boolean;
    error: NetworkError | null;
  };
  services: {
    loading: boolean;
    data: Service[] | null;
    total: number | null;
    fields: any;
    offset: number;
    error: NetworkError | null;
  };
  fulfillers: {
    loading: boolean;
    data: Fulfiller[] | null;
    total: number | null;
    fields: any;
    offset: number;
    error: NetworkError | null;
  };
  payments: {
    loading: boolean;
    data: Payment[] | null;
    total: number | null;
    fields: any;
    offset: number;
    error: NetworkError | null;
  };
  client: {
    loading: boolean;
    fields: any | null;
    data: Client | null;
    error: NetworkError | null;
  };
  defaultServiceSteps: {
    loading: boolean;
    data: Step[] | null;
    error: NetworkError | null;
  };
  serviceFields: {
    loading: boolean;
    data: any | null;
    error: NetworkError | null;
  };
  delayModal: {
    show: boolean;
    form: any;
  };
  chargesModal: {
    show: boolean;
    form: AdditionalCharge[];
  };
  paymentModal: {
    show: boolean;
  };
  refundModal: {
    show: boolean;
    loading: boolean;
    error: NetworkError | null;
    form: string[];
  };
  selectedDate: string[];
  form: any;
  ui: {
    addedFulfillerTypes: DropdownOption[];
  },
  // TODO: move to customerModal (or successor name)
  temp: any;
  warnings: any;
  errors: any;
  builder: {
    show: boolean;
    frameLoaded: boolean;
    selectedStepIndex: number;
    selectedFieldIndex: number;
    field: Field | null;
  };
  showMobileFieldOptions: boolean;
  steps: {
    showActionMenu: boolean;
    menuIndex: number;
    createModal: {
      show: boolean;
    };
    renameModal: {
      show: boolean;
      name: string;
      index: number;
    };
    expandedSteps: {
      [id: string]: boolean;
    };
    fieldsAsText: boolean;
  };
  statusComment: string | null;
  paymentAmount: number;
  addAnotherPayment: boolean;
  showActionsMenu: boolean;
  modal: {
    show: boolean;
    header: string;
    content: string;
    buttons: any[];
  };
}

const initialState: State = {
  customers: {
    loading: false,
    total: null,
    data: null,
    fields: {},
    offset: 0,
    error: null
  },
  customerModal: {
    loading: false,
    data: null,
    error: null,
    show: false,
    form: {},
    mode: null
  },
  fulfillerSettingsModal: {
    show: false
  },
  unavailableDates: {
    loading: false,
    data: undefined,
    error: null
  },
  timeSlots: {
    loading: false,
    data: null,
    error: null,
    selectedTimeSlotIndex: 0
  },
  availability: {
    loading: false,
    data: null,
    error: null
  },
  dateEntrySettingsModal: {
    show: false,
  },
  job: {
    loading: false,
    data: null,
    error: null
  },
  jobFields: {
    loading: false,
    data: null,
    error: null
  },
  jobCreate: {
    loading: false,
    data: null,
    error: null
  },
  jobUpdate: {
    loading: false,
    data: null,
    error: null
  },
  jobSync: {
    loading: false,
    error: null
  },
  services: {
    loading: false,
    total: null,
    data: null,
    fields: {},
    offset: 0,
    error: null
  },
  fulfillers: {
    loading: false,
    total: null,
    data: null,
    fields: {},
    offset: 0,
    error: null
  },
  payments: {
    loading: false,
    total: null,
    data: null,
    fields: {},
    offset: 0,
    error: null
  },
  client: {
    loading: false,
    fields: null,
    data: null,
    error: null
  },
  defaultServiceSteps: {
    loading: false,
    data: null,
    error: null
  },
  serviceFields: {
    loading: false,
    data: null,
    error: null
  },
  delayModal: {
    show: false,
    form: {},
  },
  chargesModal: {
    show: false,
    form: [{
      summary: '',
      price: 0
    }]
  },
  paymentModal: {
    show: false
  },
  refundModal: {
    show: false,
    loading: false,
    error: null,
    form: []
  },
  selectedDate: [],
  form: {
    calculatedPrice: 0,
    priceOverridden: false,

    fields: {
      date: {
        entryMethod: DateEntryMethod.Duration,
        schedule: {}
      }
    }
  },
  ui: {
    addedFulfillerTypes: [],
  },
  temp: {},
  warnings: {},
  errors: {},
  builder: {
    show: false,
    frameLoaded: false,
    selectedStepIndex: -1,
    selectedFieldIndex: -1,
    field: null
  },
  showMobileFieldOptions: false,
  steps: {
    showActionMenu: false,
    menuIndex: -1,
    createModal: {
      show: false
    },
    renameModal: {
      show: false,
      name: '',
      index: -1
    },
    expandedSteps: {},
    fieldsAsText: false
  },
  statusComment: null,
  paymentAmount: 0,
  addAnotherPayment: false,
  showActionsMenu: false,
  modal: {
    show: false,
    header: '',
    content: '',
    buttons: []
  }
};

const customerSchema = object({
  // TODO: add validation for either or
  firstName: string()
    .nullable()
    .label('First name')
    .optional(),
  lastName: string()
    .nullable()
    .label('Last name')
    .optional(),
  businessName: string()
    .nullable()
    .label('Business name')
    .optional(),
  email: string()
    .email()
    .trim()
    .optional(),

  addressLine1: string()
    .label('Address line 1')
    .required(),
  addressLine2: string()
    .nullable()
    .label('Address line 2')
    .optional(),
  addressLine3: string()
    .nullable()
    .label('Address line 3')
    .optional(),
  addressCity: string()
    .label('Address city')
    .required(),
  addressPostCode: string()
    .label('Address postcode')
    .required()
});

// Defined without Yup's wrapper object type so that it can be added to the dynamic schema in the useMemo further below
const jobSchema = {
  customerId: string()
    .label('Customer')
    .required(),
  serviceId: string()
    .label('Service')
    .required(),
  price: number()
    .min(500, 'Price must be greater than or equal to £5.00')
    .required(),
  fulfillerPay: number()
    .label('Fulfiller pay')
    .moreThan(0)
    .required(),
  staffNotes: string()
    .label('Staff notes')
    .optional()
    .nullable()
};

let accoutingPlatformWarningShown: boolean = false;

const Job: FC<AdminPageProps> = props => {
  const {
    userData,
    addToast,
    state: globalState,
    dispatch
  } = props;

  const jobToCopy = globalState.jobToCopy.form;

  const navigate = useNavigate();
  const location = useLocation();
  const { clientId, id } = useParams();
  const { t } = useTranslation();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const customerNameAutoFocus = useAutoFocus();

  const urlAction: string | undefined = location.pathname.split('/').pop();
  const updateToken: string | undefined = getQueryParam('ut', window.location.search.split('?')[1]);
  const isCreateMode: boolean = urlAction === 'create';
  const isEditMode: boolean = urlAction === 'edit';
  const [state, setState] = useState<State>({
    ...initialState,
    form: {
      ...initialState.form,
      clientId
    }
  });

  const generatedLabelRef = useRef<HTMLDivElement>(null);
  const [prevServiceId, setPrevServiceId] = useState<string | null>(null);
  const [prevMaxDuration, setPrevMaxDuration] = useState<string | null>(null);

  const { sortState: paymentsSortState, setSortState: paymentsSetSortState } = useSortFilter({
    listItemId: 'job-payments',
    total: null,
    offset: null,
    onSort: (opts) => fetchPayments(opts)
  });

  const {
    errors: customerErrors,
    validate: validateCustomerForm,
    reset: resetCustomerForm
  } = useValidation(customerSchema);
  const {
    errors,
    validate,
    setSchema,
    reset
  } = useValidation(jobSchema);

  const getPrice = (
    price: number,
    vatEnabled: boolean,
    additiveOnly?: boolean
  ): number => {
    const vat = price * (VAT_RATE / 100);

    return vatEnabled ? price + vat : additiveOnly ? price : price / ((VAT_RATE / 100) + 1);
  };

  const getFulfillerPay = (
    price: number,
    fulfillerPayPercentage: number,
    vatEnabled: boolean,
    additionalCharges: AdditionalCharge[]
  ): number => {
    const charges = (additionalCharges || []).reduce((acc, charge) => {
      acc += charge.price;

      return acc;
    }, 0);
    const priceWithoutVAT = price / ((VAT_RATE / 100) + 1);
    const finalPrice = vatEnabled ? priceWithoutVAT : price;
    const totalPrice = finalPrice + charges;

    return (totalPrice / 100) * (fulfillerPayPercentage ?? 0 / 100);
  };

  const allStepsCollapsed = useMemo(() => {
    return Object.keys(state.steps.expandedSteps).every((key: string) => {
      return state.steps.expandedSteps[key] === false;
    });
  }, [state.steps.expandedSteps]);

  const selectedService = useMemo(() => {
    return (state.services.data && state.services.data.find((service: Service) => (
      service._id === state.form.serviceId
    ))) || null;
  }, [
    state.services.data,
    state.form.serviceId
  ]);

  const refundButtonDisabled = useMemo((): boolean => {
    if (!state.payments.data) {
      return false;
    }

    return state.payments.data.every(payment => payment.refunded);
  }, [
    state.payments.data
  ]);

  const priceEditable = (status?: Status[] | StatusType): boolean => {
    if (!Array.isArray(status)) {
      return true;
    }

    const statusTypeList: StatusType[] = (status || []).map((s: Status) => s.type);

    return !statusTypeList.includes(StatusType.PAID)
      && !statusTypeList.includes(StatusType.CHARGES_PENDING)
      && !statusTypeList.includes(StatusType.CHARGES_PAID);
  };

  const steps = useMemo((): Step[] => {
    let stepsToReturn: Step[] = [];

    if (isCreateMode) {
      if (state.form.serviceId === 'none') {
        stepsToReturn = state.form.steps || stepsToReturn;
      } else {
        if (!selectedService) {
          return stepsToReturn;
        }

        stepsToReturn = selectedService.steps;
      }
    }
    else if (state.job.data) {
      if (state.form.serviceId === 'none') {
        stepsToReturn = state.form.steps;
      } else {
        stepsToReturn = state.form.service.steps;
      }
    }

    return stepsToReturn;
  }, [
    isCreateMode,
    selectedService,
    state.form,
    state.job.data
  ]);

  const isComplete = useMemo((): boolean => {
    return state.form.status?.slice(-1)[0].type === StatusType.COMPLETE || state.job.data?.status.slice(-1)[0].type === StatusType.COMPLETE;
  }, [
    state.form.status,
    state.job.data
  ]);

  const canAddAdditionalCharges = useMemo((): boolean => {
    const statusList: StatusType[] = state.job.data?.status.map(s => s.type) || [];

    return !isCreateMode
      && (statusList.includes(StatusType.PAID)
        || statusList.includes(StatusType.COMPLETE)
        || statusList.includes(StatusType.CHARGES_PAID)
        || statusList.includes(StatusType.CHARGES_PENDING)
      )
  }, [
    isCreateMode,
    state.job.data
  ]);

  const paymentModalStatusType = useMemo((): StatusType | null => {
    if (!state.job.data) {
      return null;
    }

    if (findLastStatusType(state.job.data.status, StatusType.CHARGES_PENDING)) {
      return StatusType.CHARGES_PAID;
    }

    return StatusType.PAID;
  }, [state.job.data]);

  const isSaveButtonDisabled = useMemo((): boolean => {
    if (isCreateMode) {
      return false;
    }

    if (!state.job.data) {
      return false;
    }

    const {
      calculatedPrice,
      priceOverridden,
      fulfiller,
      service,
      ...form
    } = state.form;

    const {
      fulfiller: dbFulfiller,
      service: dbService,
      ...dbValue
    } = state.job.data;

    if (state.addAnotherPayment) {
      return false;
    }

    // If one word form.status value is different to dbValue.status array,
    // no need to continue, return false instant difference.
    if (!Array.isArray(form.status) && form.status !== dbValue.status.slice(-1)[0].type) {
      return false;
    }
    // If one word form.status value is the same as the last dbValue.status type
    // then switch out form.status word for dbValue.status array
    else if (!Array.isArray(form.status) && form.status === dbValue.status.slice(-1)[0].type) {
      form.status = dbValue.status;
    }

    return isDeepEqual(form, dbValue);
  }, [
    isCreateMode,
    state.form,
    state.job.data,
    state.addAnotherPayment
  ]);

  const jobDurationInDays: number = useMemo(() => {
    if (!state.form.fields?.date?.iso?.length) {
      return 0;
    }

    const jobStart = new Date(stripZoneFromISOString(state.form.fields.date.iso[0]));
    const jobEnd = new Date(stripZoneFromISOString(state.form.fields.date.iso[1]));

    const superImposedStart: Date = setDate(jobStart, jobEnd.getDate()); // check time with end date to compare and ensure entime is greater than start time to make sure time range wording is applicable
    const endTimeIsGreaterThanStartTime: boolean = jobEnd.getTime() > superImposedStart.getTime();

    if (endTimeIsGreaterThanStartTime) {
      let days: number = 1;
      let newDate = addDays(jobStart, 0);
      let stop = false;

      // Check start and end time can fit in each day. If so increment counter
      while (!stop) {
        const superImposedDate = setMinutes(setHours(newDate, jobEnd.getHours()), jobEnd.getMinutes());

        if (superImposedDate.getTime() < jobEnd.getTime()) {
          newDate = addDays(newDate, 1);
          days++;
        } else if (superImposedDate.getTime() >= jobEnd.getTime()) {
          stop = true;
        }
      }

      return days;
    } else {
      let days: number = 0;
      let newDate = addDays(jobStart, 0);
      let stop = false;

      // Check the amount of days (including the first and last) that the job will cover
      while (!stop) {
        if (newDate.getFullYear() <= jobEnd.getFullYear()
          && newDate.getMonth() <= jobEnd.getMonth()
          && newDate.getDate() <= jobEnd.getDate()
        ) {
          newDate = addDays(newDate, 1);
          days++;
        } else {
          stop = true;
        }
      }

      return days;
    }
  }, [state.form.fields]);

  // Set validation schema
  useMemo(() => {
    if (!(isCreateMode || isEditMode)) {
      return;
    }

    let schema: AnyObject = {
      ...jobSchema,
      ...(isCreateMode
        && state.form.serviceId === 'none'
        && state.form.fields?.date?.entryMethod === DateEntryMethod.Duration
        && {
        maxDuration: string()
          .label('Max. duration')
          .required()
      })
    };

    filterOutJobPageStepsForValidation(steps).forEach((step: Step) => {
      schema = {
        ...schema,
        fields: {
          ...schema.fields,
          [step.name]: {}
        }
      };

      step.fields.forEach((field: Field, i: number) => {
        switch (field.type) {
          case FieldType.ShortText:
          case FieldType.LongText:
          case FieldType.Telephone:
            schema = {
              ...schema,
              fields: {
                ...schema.fields,
                [step.name]: {
                  ...schema.fields[step.name],
                  [field.name]: string().label(field.label)
                }
              }
            };
            break;
          case FieldType.Dropdown:
            schema = {
              ...schema,
              fields: {
                ...schema.fields,
                [step.name]: {
                  ...schema.fields[step.name],
                  [field.name]: mixed().label(field.label)
                }
              }
            };
            break;
          case FieldType.Email:
            schema = {
              ...schema,
              fields: {
                ...schema.fields,
                [step.name]: {
                  ...schema.fields[step.name],
                  [field.name]: string().email().label(field.label)
                }
              }
            };
            break;
          case FieldType.Number:
            schema = {
              ...schema,
              fields: {
                ...schema.fields,
                [step.name]: {
                  ...schema.fields[step.name],
                  [field.name]: number().label(field.label)
                }
              }
            };
            break;
          case FieldType.DateTime:
            schema = {
              ...schema,
              fields: {
                ...schema.fields,
                [step.name]: {
                  ...schema.fields[step.name],
                  [field.name]: array().label(field.label).min(2)
                    .test('timeslot-chosen', 'Please choose a timeslot', (value?: string[]) => {
                      if (!value) {
                        return false;
                      }

                      return value[0] !== value[1];
                    })
                }
              }
            };
            break;
          default:
            schema = {
              ...schema,
              fields: {
                ...schema.fields,
                [step.name]: {
                  ...schema.fields[step.name],
                  [field.name]: mixed().label(field.label)
                }
              }
            };
        };

        if (field.optional) {
          schema.fields[step.name][field.name] = schema.fields[step.name][field.name].optional();
        } else {
          schema.fields[step.name][field.name] = schema.fields[step.name][field.name].required();
        }
      });
    });

    // Format object using Yup's primitive types
    filterOutJobPageStepsForValidation(steps).forEach((step: Step) => {
      schema = {
        ...schema,
        fields: {
          ...schema.fields,
          [step.name]: object(schema.fields[step.name])
        }
      };
    });
    schema.fields = object(schema.fields);

    reset();
    setSchema(object(schema));
  }, [
    steps,
    isCreateMode,
    isEditMode,
    state.form.serviceId,
    state.form.fields,
    reset,
    setSchema
  ]);

  const onMediaResize = useCallback(({ isMobile: isM }: UseMediaSizesState): void => {
    const frameData = {
      action: FrameActions.HIDE_BACKGROUND_AND_FOOTER,
      to: 'child',
      payload: {
        service: {
          builder: {
            isEditable: isM,
            hideBackground: isM,
            hideFooter: isM
          }
        }
      }
    };

    throttleIframeMessage(frameData);
  // eslint-disable-next-line
  }, []);

  const {
    isMobile,
    isMobileXL
  } = useMediaSizes({ onMediaResize });

  const getPaymentsHeaderConfig = useCallback((): ITableHeader[] => {
    return [
      {
        key: 'custom',
        label: 'No.',
        type: CellType.Text,
        sortable: true,
        format: (value, row, index: number) => index + 1
      },
      {
        key: 'amount',
        label: 'Amount',
        type: CellType.Currency,
        sortable: true,
        format: (value: number) => formatCurrency(value)
      },
      {
        key: 'paymentId',
        label: `${getPaymentService(state.client.data && state.client.data.customerPaymentService && state.client.data.customerPaymentService.type)} ID`,
        type: CellType.Text,
        format: (value: string, row) => {
          if (row.source === Source.External) {
            return row._id;
          }

          if (!state.client.data!.customerPaymentService) {
            return <i>Add customer payment service</i>
          }

          return (
            <StyledLinkExternal
              inline
              href={`${state.client.data!.customerPaymentService.paymentsDashboardLink}/${value}`}
              target={'_blank'}
            >{value}</StyledLinkExternal>
          );
        }
      },
      {
        key: 'date',
        label: 'Date',
        type: CellType.Text,
        format: (value: string) => format(new Date(value), 'HH:mm dd/MM/yyyy')
      },
      {
        key: 'refunded',
        type: CellType.Icon,
        renderIcon: (row) => {
          // TODO: if on mobile open modal to show information
          if (row.refunded) {
            return (
              <div
                data-title={row.source !== Source.External ? `Refund initiated. It may take a few days to clear. Check the status with your payment service.` : 'Refunded'}
                style={{ display: 'inline-block' }}
              >
                <StyledRefund />
              </div>
            );
          }

          return '';
        }
      }
    ];
  }, [
    state.client
  ]);

  const getChargesHeaderConfig = useCallback((): ITableHeader[] => {
    return [
      {
        key: 'summary',
        label: 'Summary',
        type: CellType.Text
      },
      {
        key: 'price',
        label: 'Price',
        type: CellType.Currency,
        sortable: true,
        format: (value: number) => formatCurrency(value)
      }
    ];
  }, []);

  const fetchCustomers = useCallback((query?: string, cb?: (customers: Customer[]) => void) => {
    if (state.customers.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      customers: {
        ...prevState.customers,
        loading: true
      }
    }));

    gatewayService.getCustomers(clientId!, query)
      .then((customersResponse: any) => {
        setState(prevState => ({
          ...prevState,
          customers: {
            loading: false,
            total: customersResponse.total,
            data: [
              ...JSON.parse(JSON.stringify(customersResponse.data))
            ],
            fields: customersResponse.fields,
            offset: customersResponse.offset,
            error: null
          }
        }));

        if (cb) {
          cb(customersResponse.data);
        }
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          customers: {
            ...prevState.customers,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.customers,
    clientId,
    addToast
  ]);

  const [debounceFetchCustomers] = useDebouncedCallback((query: string, cb: (customers: Customer[]) => void) => {
    fetchCustomers(query, cb);
  }, 500);

  const createCustomer = useCallback((payload: any, callback?: (e: NetworkError | null) => void) => {
    if (state.customerModal.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      customerModal: {
        ...prevState.customerModal,
        loading: true
      }
    }));

    gatewayService.createCustomer(clientId!, payload)
      .then((createCustomerResponse: any) => {
        const createdCustomer: Customer = createCustomerResponse.data;

        setState(prevState => ({
          ...prevState,
          customerModal: {
            ...prevState.customerModal,
            loading: false,
            data: createdCustomer,
            error: null
          },
          form: {
            ...prevState.form,
            customerId: createdCustomer._id
          },
          temp: {
            ...prevState.temp,
            customerIdOption: createDropdownOption(getCustomerName(createdCustomer), createdCustomer._id),
            customer: createdCustomer
          }
        }));

        addToast({
          type: 'success',
          content: 'Customer created'
        });

        if (callback) {
          callback(null);
        }
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          customerModal: {
            ...prevState.customerModal,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.customerModal,
    clientId,
    addToast
  ]);

  const updateCustomer = useCallback((customerId: string, payload: any, callback?: () => void) => {
    if (state.customerModal.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      customerModal: {
        ...prevState.customerModal,
        loading: true
      }
    }));

    const {
      _id,
      clientId: payloadClientId,
      created,
      updated,
      verified,
      jobId,
      permissions,
      creator,
      creatorId,
      role,
      signedIn,
      revoke,
      isDeleted,
      colour,
      value,
      label,
      jobsFilter,
      ...rest
    } = payload;

    gatewayService.updateCustomer(clientId!, customerId, rest)
      .then((customerUpdateResponse: any) => {
        const updatedCustomer: Customer = customerUpdateResponse.data;

        setState(prevState => {
          if (!prevState.customers.data) {
            return prevState;
          }

          const index: number = prevState.customers.data.findIndex((c: Customer) => c._id === customerId);

          const newState = {
            ...prevState,
            customers: {
              ...prevState.customers,
              data: [
                ...prevState.customers.data!.slice(0, index),
                {
                  ...updatedCustomer
                },
                ...prevState.customers.data!.slice(index + 1, prevState.customers.total!),
              ]
            },
            customerModal: {
              ...initialState.customerModal,
            },
            temp: {
              ...prevState.temp,
              customerIdOption: createDropdownOption(getCustomerName(updatedCustomer), updatedCustomer._id),
              customer: updatedCustomer
            }
          };

          return newState;
        });

        addToast({
          type: 'success',
          content: 'Customer updated'
        });

        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          customerModal: {
            ...prevState.customerModal,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.customerModal,
    clientId,
    addToast
  ]);

  const fetchUnavailableDates = useCallback((isoDate: string) => {
    if (state.unavailableDates.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      unavailableDates: {
        ...prevState.unavailableDates,
        loading: true
      }
    }));

    gatewayService.getUnavailableDates(
      clientId!,
      isoDate,
      (selectedService && selectedService._id) || state.form.serviceId,
      false,
      isCreateMode && state.form.serviceId === 'none' ? state.form.maxDuration : null
    )
      .then((datesResponse: any) => {
        setState(prevState => ({
          ...prevState,
          unavailableDates: {
            loading: false,
            data: [
              ...datesResponse.data
            ],
            error: null
          }
        }));
      })
      .catch((err: any) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          unavailableDates: {
            ...prevState.unavailableDates,
            loading: false,
            data: undefined,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    isCreateMode,
    state.form.serviceId,
    state.form.maxDuration,
    state.unavailableDates.loading,
    clientId,
    selectedService,
    addToast
  ]);

  const fetchTimeSlots = useCallback((isoDate: string, maxDuration?: string) => {
    if (state.timeSlots.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      timeSlots: {
        ...prevState.timeSlots,
        loading: true
      }
    }));

    gatewayService.getTimeSlots(
      clientId!,
      isoDate,
      (selectedService && selectedService._id) || state.form.serviceId,
      false,
      state.form.serviceId === 'none' ? maxDuration || state.form.maxDuration : null
    )
      .then((timeSlotsResponse: any) => {
        setState(prevState => ({
          ...prevState,
          timeSlots: {
            ...prevState.timeSlots,
            loading: false,
            data: timeSlotsResponse.data,
            error: null
          }
        }));
      })
      .catch((err: any) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          timeSlots: {
            ...prevState.timeSlots,
            loading: false,
            data: null,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.form.serviceId,
    state.form.maxDuration,
    state.timeSlots.loading,
    clientId,
    selectedService,
    addToast
  ]);

  const fetchAvailability = useCallback((dates: string[]) => {
    if (state.availability.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      availability: {
        ...prevState.availability,
        loading: true
      }
    }));

    gatewayService.getAvailability(clientId!, dates)
      .then((availabilityResponse: any) => {
        setState(prevState => ({
          ...prevState,
          availability: {
            loading: false,
            data: availabilityResponse.data,
            error: null
          }
        }));
      })
      .catch((err: any) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          availability: {
            ...prevState.availability,
            loading: false,
            data: null,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.availability.loading,
    clientId,
    addToast
  ]);

  const fetchJobFields = useCallback(() => {
    if (state.jobFields.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      jobFields: {
        ...prevState.jobFields,
        loading: true
      }
    }));

    gatewayService.getJobFields(clientId!)
      .then((jobFieldsResponse: any) => {
        setState(prevState => ({
          ...prevState,
          jobFields: {
            loading: false,
            data: jobFieldsResponse.data,
            error: null
          }
        }));
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          jobFields: {
            ...prevState.jobFields,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.jobFields,
    clientId,
    addToast
  ]);

  const fetchJob = useCallback(() => {
    if (state.job.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      job: {
        ...prevState.job,
        loading: true
      }
    }));

    gatewayService.getJob(clientId!, id!)
      .then((jobResponse: any) => {
        setState(prevState => {
          const newState = {
            ...prevState,
            job: {
              loading: false,
              data: jobResponse.data,
              error: null
            },
            ...(!isCreateMode && {
              form: {
                ...prevState.form,
                ...jobResponse.data
              }
            })
          };

          if (!isCreateMode) {
            // Set maxDuration
            const start = newState.form.fields.date.iso[0];
            const end = newState.form.fields.date.iso[1];
            const duration = formatDuration(intervalToDuration({
              start: new Date(stripZoneFromISOString(start)),
              end: new Date(stripZoneFromISOString(end))
            })).replace(' ', '-');
            const strSplit = duration.split('-');
            const amount = strSplit[0];
            let unit = strSplit[1];

            unit = unit.charAt(0).toUpperCase() + unit.slice(1);

            if (unit === 'Minute' || unit === 'Hour' || unit === 'Day') {
              unit = `${unit}s`;
            }

            newState.form.maxDuration = `${amount}-${unit}`;

            // Set start and date
            if (newState.form.fields?.date?.entryMethod === DateEntryMethod.StartEnd) {
              newState.form.start = new Date(stripZoneFromISOString(newState.form.fields.date.iso[0]));
              newState.form.end = new Date(stripZoneFromISOString(newState.form.fields.date.iso[1]));
            }
          }

          return newState;
        });
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          job: {
            ...prevState.job,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    isCreateMode,
    state.job,
    id,
    clientId,
    addToast
  ]);

  const createJob = useCallback((cb: (id: string) => void) => {
    if (state.jobCreate.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      jobCreate: {
        ...prevState.jobCreate,
        loading: true
      }
    }));

    const {
      reference,
      customerId,
      noCustomerCommunications,
      price,
      serviceId,
      fields,
      fulfillerId,
      fulfillerLabel,
      fulfillerPay,
      fulfillerPayPercentage,
      vat,
      staffNotes
    } = state.form;

    gatewayService.createJob(
      clientId!,
      format(new Date(), ISO_FORMAT),
      {
        reference,
        customerId,
        noCustomerCommunications,
        price,
        serviceId,
        fields,
        fulfillerLabel,
        fulfillerPay,
        fulfillerPayPercentage,
        vat,
        staffNotes,
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
        ...(fulfillerId && { fulfillerId }),
        ...(serviceId === 'none' && steps.length && { steps })
      }
    )
      .then((createJobResponse: any) => {
        setState(prevState => ({
          ...prevState,
          form: initialState.form,
          jobCreate: {
            loading: false,
            data: createJobResponse.data,
            error: null
          }
        }));

        addToast({
          type: 'success',
          content: 'Job created'
        });

        cb(createJobResponse.data._id);
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          jobCreate: {
            ...prevState.jobCreate,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    steps,
    state.form,
    state.jobCreate,
    clientId,
    addToast
  ]);

  const updateJob = useCallback((jobId: string, payload: any, callback: () => void) => {
    if (state.jobUpdate.loading) {
      return;
    }

    // new status change
    if (payload.status && !Array.isArray(payload.status)) {
      payload = {
        ...payload,
        ...(state.paymentAmount && {
          paymentAmount: state.paymentAmount
        }),
        status: [
          ...state.job.data!.status,
          {
            type: payload.status,
            ...(state.paymentModal.show && paymentModalStatusType && {
              type: paymentModalStatusType
            }),
            date: format(new Date(), ISO_FORMAT),
            author: {
              _id: userData.user!._id,
              name: `${userData.user!.firstName} ${userData.user!.lastName}`
            },
            ...(state.statusComment && {
              description: state.statusComment.trim()
            })
          }
        ]
      };
    } else {
      payload = {
        ...payload,
        ...(state.paymentAmount && {
          paymentAmount: state.paymentAmount
        }),
        ...((state.paymentAmount || state.paymentModal.show) && paymentModalStatusType && {
          status: [
            ...state.job.data!.status,
            {
              type: paymentModalStatusType,
              date: format(new Date(), ISO_FORMAT),
              author: {
                _id: userData.user!._id,
                name: `${userData.user!.firstName} ${userData.user!.lastName}`
              },
              ...(state.statusComment && {
                description: state.statusComment.trim()
              })
            }
          ]
        })
      };
    }

    setState(prevState => ({
      ...prevState,
      jobUpdate: {
        ...prevState.jobUpdate,
        id: jobId,
        loading: true
      }
    }));

    gatewayService.updateJob(clientId!, jobId, payload)
      .then((updateJobResponse: any) => {
        setState(prevState => ({
          ...prevState,
          jobUpdate: {
            ...prevState.jobUpdate,
            loading: false,
            data: updateJobResponse.data,
            error: null
          },
          job: {
            ...prevState.job,
            data: {
              ...updateJobResponse.data
            }
          },
          form: {
            ...prevState.form,
            ...updateJobResponse.data,
            // status: updateJobResponse.data.status.slice(-1)[0].type,
          },
          paymentAmount: 0,
          statusComment: null
        }));

        addToast({
          type: 'success',
          content: 'Job updated'
        });

        callback()
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          jobUpdate: {
            ...prevState.jobUpdate,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.jobUpdate,
    state.job.data,
    state.statusComment,
    state.paymentAmount,
    state.paymentModal.show,
    paymentModalStatusType,
    userData,
    clientId,
    addToast
  ]);

  const fetchServices = useCallback(() => {
    if (state.services.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      services: {
        ...prevState.services,
        loading: true
      }
    }));

    gatewayService.getServices(clientId!)
      .then((servicesResponse: any) => {
        setState(prevState => ({
          ...prevState,
          services: {
            ...prevState.services,
            loading: false,
            total: servicesResponse.total,
            data: [
              ...JSON.parse(JSON.stringify(servicesResponse.data))
            ],
            fields: servicesResponse.fields,
            offset: servicesResponse.offset,
            error: null
          }
        }));
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          services: {
            ...prevState.services,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.services,
    clientId,
    addToast
  ]);

  const fetchFulfillers = useCallback(() => {
    if (state.fulfillers.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      fulfillers: {
        ...prevState.fulfillers,
        loading: true
      }
    }));

    gatewayService.getFulfillers(clientId!, {
      ...(selectedService?.fulfillerGroups.length && {
        filters: {
          options: selectedService!.fulfillerGroups.map((fulfillerGroupId: string) => {
            return { 'groups#dropdown': fulfillerGroupId };
          })
        }
      })
    })
      .then((fulfillersResponse: any) => {
        setState(prevState => ({
          ...prevState,
          fulfillers: {
            ...prevState.fulfillers,
            loading: false,
            total: fulfillersResponse.total,
            data: [
              ...JSON.parse(JSON.stringify(fulfillersResponse.data))
            ],
            fields: fulfillersResponse.fields,
            offset: fulfillersResponse.offset,
            error: null
          }
        }));
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          fulfillers: {
            ...prevState.fulfillers,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    selectedService,
    state.fulfillers,
    clientId,
    addToast
  ]);

  const fetchPayments = useCallback((opts?: any) => {
    if (!canReadPayments(clientId!, userData.user!)) {
      return;
    }

    if (state.payments.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      payments: {
        ...prevState.payments,
        loading: true
      }
    }));

    gatewayService.getPayments(clientId!, id!, opts)
      .then((paymentsResponse: any) => {
        setState(prevState => ({
          ...prevState,
          payments: {
            ...prevState.payments,
            loading: false,
            total: paymentsResponse.total,
            data: [
              ...JSON.parse(JSON.stringify(paymentsResponse.data))
            ],
            fields: paymentsResponse.fields,
            offset: paymentsResponse.offset,
            error: null
          }
        }));
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          payments: {
            ...prevState.payments,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.payments,
    clientId,
    id,
    userData.user,
    addToast
  ]);

  const fetchClient = useCallback(() => {
    if (state.client.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      client: {
        ...prevState.client,
        loading: true
      }
    }));

    gatewayService.getClient(clientId!)
      .then((clientResponse: any) => {
        setState(prevState => ({
          ...prevState,
          client: {
            loading: false,
            fields: clientResponse.fields,
            data: clientResponse.data,
            error: null
          }
        }));
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          client: {
            ...prevState.client,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.client,
    clientId,
    addToast
  ]);

  const fetchDefaultServiceSteps = useCallback(() => {
    if (state.defaultServiceSteps.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      defaultServiceSteps: {
        ...prevState.defaultServiceSteps,
        loading: true
      }
    }));

    gatewayService.getDefaultServiceSteps(clientId!)
      .then((defaultServiceStepsResponse: any) => {
        setState(prevState => ({
          ...prevState,
          defaultServiceSteps: {
            loading: false,
            data: defaultServiceStepsResponse.data,
            error: null
          },
          form: {
            ...prevState.form,
            steps: [
              ...prevState.form.steps,
              ...defaultServiceStepsResponse.data
            ]
          }
        }));
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          defaultServiceSteps: {
            ...prevState.defaultServiceSteps,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.defaultServiceSteps,
    clientId,
    addToast
  ]);

  const fetchServiceFields = useCallback(() => {
    if (state.serviceFields.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      serviceFields: {
        ...prevState.serviceFields,
        loading: true
      }
    }));

    gatewayService.getServiceFields(clientId!)
      .then((serviceFieldsResponse: any) => {
        setState(prevState => ({
          ...prevState,
          serviceFields: {
            loading: false,
            data: serviceFieldsResponse.data,
            error: null
          }
        }));
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          serviceFields: {
            ...prevState.serviceFields,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.serviceFields,
    clientId,
    addToast
  ]);

  const refund = useCallback((cb: (e?: NetworkError) => void) => {
    if (state.refundModal.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      refundModal: {
        ...prevState.refundModal,
        loading: true
      }
    }));

    gatewayService.refund(clientId!, state.refundModal.form)
      .then(() => {
        setState(prevState => ({
          ...prevState,
          refundModal: {
            ...prevState.refundModal,
            loading: false,
            error: null
          },
        }));

        addToast({
          type: 'success',
          content: 'Selected payments refunded'
        });

        cb();
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          refundModal: {
            ...prevState.refundModal,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });

        cb(error);
      });
  }, [
    state.refundModal,
    clientId,
    addToast
  ]);

  const syncJob = useCallback(() => {
    setState(prevState => {
      if (prevState.jobSync.loading) {
        return prevState;
      }

      const newState = {
        ...prevState,
        jobSync: {
          ...prevState.jobSync,
          loading: true
        }
      };

      return newState;
    });

    gatewayService.syncJob(clientId!, id!)
      .then((syncJobResponse) => {
        setState(prevState => ({
          ...prevState,
          jobSync: {
            ...prevState.jobSync,
            loading: false
          },
          job: {
            ...prevState.job,
            data: syncJobResponse.data
          }
        }));

        addToast({
          type: 'success',
          content: 'Invoice successfully synched'
        });
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          jobSync: {
            ...prevState.jobSync,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    clientId,
    id,
    addToast
  ]);

  const addNewField = useCallback((stepIndex: number, field: Field) => {
    setState(prevState => {
      const step = prevState.form.steps[stepIndex];

      const newState = {
        ...prevState,
        form: {
          ...prevState.form,
          steps: [
            ...(prevState.form.steps as any).slice(0, stepIndex),
            {
              ...(prevState.form.steps as any)[stepIndex],
              fields: [
                ...(prevState.form.steps as any)[stepIndex].fields,
                { ...field }
              ]
            },
            ...(prevState.form.steps as any).slice(stepIndex + 1)
          ]
        },
        steps: {
          ...prevState.steps,
          expandedSteps: {
            ...prevState.steps.expandedSteps,
            [step.name]: true
          }
        },
      };

      const newFieldIndex: number = newState.form.steps[stepIndex].fields.findIndex((f: Field) => f.id === field.id);

      newState.builder = {
        ...prevState.builder,
        selectedStepIndex: stepIndex,
        selectedFieldIndex: newFieldIndex,
        field
      };

      return newState;
    });
  }, []);

  const [editNewField] = useDebouncedCallback((stepIndex: number, fieldIndex: number, field: Field | Partial<Field>) => {
    setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        steps: [
          ...prevState.form.steps.slice(0, stepIndex),
          {
            ...prevState.form.steps[stepIndex],
            fields: [
              ...prevState.form.steps[stepIndex].fields.slice(0, fieldIndex),
              {
                ...field
              },
              ...prevState.form.steps[stepIndex].fields.slice(fieldIndex + 1)
            ]
          },
          ...prevState.form.steps.slice(stepIndex + 1)
        ]
      }
    }));
  }, 100);

  const selectStep = useCallback((index: number) => {
    setState(prevState => ({
      ...prevState,
      builder: {
        ...prevState.builder,
        selectedStepIndex: index,
        selectedFieldIndex: -1,
        field: null
      }
    }));
  }, []);

  const deleteStep = useCallback((index: number) => {
    setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        steps: [
          ...prevState.form.steps.slice(0, index),
          ...prevState.form.steps.slice(index + 1)
        ]
      }
    }));
  }, []);

  const removeField = useCallback((stepIndex: number, fieldIndex: number) => {
    setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        steps: [
          ...prevState.form.steps.slice(0, stepIndex),
          {
            ...prevState.form.steps[stepIndex],
            fields: [
              ...prevState.form.steps[stepIndex].fields.slice(0, fieldIndex),
              ...prevState.form.steps[stepIndex].fields.slice(fieldIndex + 1)
            ]
          },
          ...prevState.form.steps.slice(stepIndex + 1)
        ]
      },
      ...(prevState.builder.selectedFieldIndex === fieldIndex && {
        builder: {
          ...prevState.builder,
          selectedFieldIndex: -1,
          field: null
        }
      })
    }));
  }, []);

  const selectField = useCallback((
    stepIndex: number,
    fieldIndex: number,
    field: Field | null
  ) => {
    if (!field) {
      setState(prevState => ({
        ...prevState,
        builder: {
          ...prevState.builder,
          mode: 'edit',
          selectedStepIndex: -1,
          selectedFieldIndex: -1,
          field: null
        }
      }));

      return;
    }

    const { value, ...fieldRest } = field;

    setState(prevState => {
      const step = prevState.form.steps[stepIndex];

      return {
        ...prevState,
        builder: {
          ...prevState.builder,
          mode: 'edit',
          selectedStepIndex: stepIndex,
          selectedFieldIndex: fieldIndex,
          field: {
            ...fieldRest
          }
        },
        steps: {
          ...prevState.steps,
          expandedSteps: {
            ...prevState.steps.expandedSteps,
            [step.name]: true
          }
        }
      };
    });
  }, []);

  const editField = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      showMobileFieldOptions: true
    }));
  }, []);

  const closeNameModal = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      steps: {
        ...prevState.steps,
        createModal: {
          show: false
        },
        renameModal: {
          ...initialState.steps.renameModal
        }
      }
    }));
  }, []);

  const upsertStep = useCallback((form: any, index?: number) => {
    const newStepName: string = getFieldName(form.name);

    if (index! >= 0) {
      setState(prevState => {
        const updatedSteps = [
          ...prevState.form.steps.slice(0, index),
          {
            ...prevState.form.steps[index!],
            name: newStepName,
            label: form.name
          },
          ...prevState.form.steps.slice(index! + 1)
        ];

        return {
          ...prevState,
          form: {
            ...prevState.form,
            steps: updatedSteps
          },
          // TODO: finish, set selectedStep to the one being created/edited
          builder: {
            ...prevState.builder,
            selectedStepIndex: index!
          }
        };
      });
    } else {
      setState(prevState => {
        const firstFixedStepIndex: number = prevState.form.steps.findIndex((s: Step) => s.isFixed);

        const newState = { ...prevState };

        newState.form.steps.splice(firstFixedStepIndex, 0, {
          name: newStepName,
          label: form.name,
          fields: []
        });

        const newStepIndex: number = newState.form.steps.findIndex((s: Step) => s.name === newStepName);

        newState.builder = {
          ...prevState.builder,
          selectedStepIndex: newStepIndex,
          selectedFieldIndex: -1,
          field: null
        };

        return newState;
      });
    }
  }, []);

  const reOrderSteps = useCallback((
    dragIndex: number,
    hoverIndex: number
  ) => {
    setState(prevState => {
      const modifiedSteps = [ ...prevState.form.steps ];
      const dragStep = modifiedSteps[dragIndex];
      const hoverStep = modifiedSteps[hoverIndex];

      if (dragStep.isFixed || hoverStep.isFixed) {
        return prevState;
      }

      modifiedSteps.splice(dragIndex, 1);
      modifiedSteps.splice(hoverIndex, 0, dragStep);

      return {
        ...prevState,
        form: {
          ...prevState.form,
          steps: [ ...modifiedSteps ]
        },
        builder: {
          ...prevState.builder,
          selectedStepIndex: hoverIndex
        }
      };
    });
  }, []);

  const reOrderFields = useCallback((
    stepIndex: number,
    dragIndex: number,
    hoverIndex: number
  ) => {
    setState(prevState => {
      const step = prevState.form.steps[stepIndex];
      const fields = [ ...step.fields ];
      const dragField = fields[dragIndex];

      fields.splice(dragIndex, 1);
      fields.splice(hoverIndex, 0, dragField);

      return {
        ...prevState,
        form: {
          ...prevState.form,
          steps: [
            ...prevState.form.steps.slice(0, stepIndex),
            {
              ...step,
              fields
            },
            ...prevState.form.steps.slice(stepIndex + 1)
          ]
        }
      };
    });
  }, []);

  const changeFieldToNewStep = useCallback((
    sourceStepIndex: number,
    targetStepIndex: number,
    fieldIndex: number
  ) => {
    setState(prevState => {
      const newSteps = [ ...prevState.form.steps ];
      const sourceStep = newSteps[sourceStepIndex];
      const targetStep = newSteps[targetStepIndex];
      const dragField = sourceStep.fields[fieldIndex];
      const dragDirection: number = targetStepIndex > sourceStepIndex ? 1 : -1;

      sourceStep.fields.splice(fieldIndex, 1);

      if (dragDirection === 1) {
        targetStep.fields.unshift(dragField);
      } else {
        targetStep.fields.push(dragField);
      }

      newSteps[sourceStepIndex] = { ...sourceStep };
      newSteps[targetStepIndex] = { ...targetStep };

      const newFieldIndex: number = dragDirection === 1 ? 0 : targetStep.fields.length - 1;

      return {
        ...prevState,
        form: {
          ...prevState.form,
          steps: newSteps
        },
        steps: {
          ...prevState.steps,
          expandedSteps: {
            ...prevState.steps.expandedSteps,
            [targetStep.name]: true
          }
        },
        builder: {
          ...prevState.builder,
          selectedStepIndex: targetStepIndex,
          selectedFieldIndex: newFieldIndex,
          field: dragField
        }
      };
    });
  }, []);

  const onChangeFormField = useCallback((e: any, key: string, value?: any) => {
    const localValue = value || value === 0 ? value : e.target.value;

    const newState = {
      ...state,
      form: {
        ...state.form,
        [key]: localValue
      }
    };

    setState(newState);
  }, [state]);

  const onChangeFormFieldBoolean = useCallback((newBoolState: boolean, key: string) => {
    const newState = {
      ...state,
      form: {
        ...state.form,
        [key]: newBoolState
      }
    };

    setState(newState);
  }, [state]);

  const onChangeSelect = useCallback((key: string, option: any) => {
    setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        // [key]: (option && option.value) || ''
        [key]: (option && option.value) || null
      }
    }));
  }, []);

  const [onCalculateJob] = useDebouncedCallback((newState: any) => {
    gatewayService
      .calculate(
        clientId!,
        {
          serviceId: newState.form.serviceId,
          fields: getFlattenFormFields(newState.form.fields)
        }
      )
      .then((priceResponse: any) => {
        // TODO; check that the component is still mounted otherwise exit

        setState(prevState => {
          const calculatedPrice = priceResponse.data.price !== -1
            ? getPrice(priceResponse.data.price, prevState.form.vat, true)
            : 0;

          const ns = {
            ...newState,
            ...prevState,
            form: {
              ...newState.form,
              calculatedPrice,
              ...(!newState.form.priceOverridden && {
                price: calculatedPrice
              }),
              ...(newState.form.serviceId !== 'none' && !newState.form.priceOverridden && {
                fulfillerPay: getFulfillerPay(
                  calculatedPrice,
                  selectedService?.fulfillerPayPercentage ?? 0,
                  newState.form.vat,
                  newState.form.additionalCharges
                  // false
                )
              }),
              // To fix race condition
              vat: prevState.form.vat
            }
          };

          return ns;
        });

        // updatePrice(priceResponse.data.price);
      })
      .catch((res: any) => {
        console.log('--error calculating job');
      });
  }, 500);

  const toggleCustomizeFields = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      builder: {
        ...prevState.builder,
        show: !prevState.builder.show
      }
    }));
  }, []);

  const toggleExpandAllSteps = useCallback(() => {
    setState(prevState => {
      const expandedMap = steps.reduce((acc: any, curr: Step) => {
        if (!acc[curr.name]) {
          acc[curr.name] = allStepsCollapsed;
        }

        return acc;
      }, {});

      return {
        ...prevState,
        steps: {
          ...prevState.steps,
          expandedSteps: {
            ...expandedMap
          }
        }
      };
    });
  }, [
    steps,
    allStepsCollapsed
  ]);

  const toggleStepExpansion = useCallback((stepId: string) => {
    setState(prevState => ({
      ...prevState,
      steps: {
        ...prevState.steps,
        expandedSteps: {
          ...prevState.steps.expandedSteps,
          [stepId]: !prevState.steps.expandedSteps[stepId]
        }
      }
    }));
  }, []);

  const renderBuilderSteps = useCallback(() => {
    if (!(isCreateMode || isEditMode)) {
      return null; 
    }

    const builderSteps = (
      <ConfigCard
        boxShadow
        centerV
        column
      >
        <AdminFormLine>
          <ConfigCardColumn>Steps</ConfigCardColumn>
          <ConfigCardColumn
            style={{
              marginLeft: 'auto'
            }}
          >
            <IconButton
              hoverEffect
              type="button"
              onClick={(e: any) => {
                setState(prevState => ({
                  ...prevState,
                  steps: {
                    ...prevState.steps,
                    showActionMenu: true
                  }
                }));
              }}
            >
              <StyledMenuDots />
              {state.steps.showActionMenu && (
                <Popup
                  id="builder-steps-menu"
                  left
                  bottom
                  convertable
                  onClose={() => {
                    setState(prevState => ({
                      ...prevState,
                      steps: {
                        ...prevState.steps,
                        showActionMenu: false
                      }
                    }));
                  }}
                >
                  {({ closePopup }) => (
                    <MenuWrapper>
                      <MenuItem onClick={() => {
                        closePopup();

                        setState(prevState => ({
                          ...prevState,
                          steps: {
                            ...prevState.steps,
                            createModal: {
                              ...prevState.steps.createModal,
                              show: true
                            }
                          }
                        }));
                      }}>Add step</MenuItem>
                      <MenuItem onClick={() => {
                        closePopup();

                        toggleExpandAllSteps();
                      }}>{`${allStepsCollapsed ? 'Expand' : 'Collapse'} all steps`}</MenuItem>
                      <MenuItem onClick={() => {
                        closePopup();

                        setState(prevState => ({
                          ...prevState,
                          steps: {
                            ...prevState.steps,
                            fieldsAsText: !prevState.steps.fieldsAsText
                          }
                        }));
                      }}>{state.steps.fieldsAsText ? 'Show fields as user input' : 'Show fields as text'}</MenuItem>
                    </MenuWrapper>
                  )}
                </Popup>
              )}
            </IconButton>
          </ConfigCardColumn>
        </AdminFormLine>
        <ConfigDetails isExpanded={true}>
          <ConfigDetailsOuter>
            {steps.map((step: Step, index: number) => (
              <AdminFormLine
                marginBottom
                key={step.name}
              >
                <BuilderStep
                  step={step}
                  index={index}
                  isSelected={state.builder.selectedStepIndex === index}
                  isExpanded={state.steps.expandedSteps[step.name]}
                  canUpdateService={state.form.serviceId === 'none'}
                  isLast={index === steps.length - 1}
                  fields={state.serviceFields.data}
                  selectedField={{
                    stepIndex: state.builder.selectedStepIndex,
                    fieldIndex: state.builder.selectedFieldIndex,
                    field: state.builder.field
                  }}
                  showFieldsAsText={state.form.serviceId !== 'none' || state.steps.fieldsAsText}
                  onSelect={selectStep}
                  onExpand={toggleStepExpansion}
                  onRename={(stepToRename: Step) => {
                    setState(prevState => ({
                      ...prevState,
                      steps: {
                        ...prevState.steps,
                        renameModal: {
                          show: true,
                          name: stepToRename.label,
                          index
                        }
                      }
                    }));
                  }}
                  onDelete={(stepIndexToDelete: number) => {
                    deleteStep(stepIndexToDelete);
                  }}
                  onSelectField={selectField}
                  onAddField={addNewField}
                  onEditField={editNewField}
                  onDeleteField={removeField}
                  onReOrder={reOrderSteps}
                  onReOrderField={reOrderFields}
                  onMoveFieldBetweenSteps={changeFieldToNewStep}
                  canDrag={
                    !step.isFixed
                      && !state.steps.expandedSteps[step.name]
                      && state.form.serviceId === 'none'
                      && state.builder.selectedStepIndex === index
                  }
                />
              </AdminFormLine>
            ))}
          </ConfigDetailsOuter>
        </ConfigDetails>
      </ConfigCard>
    );

    return isMobile ? (
      ReactDOM.createPortal(
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1
          }}
          onClick={() => {}}
        >
          {builderSteps}
          {state.showMobileFieldOptions && (
            <IconButton
              medium
              hoverEffectAlwaysOn
              style={{
                position: 'fixed',
                top: '50%',
                marginTop: '-100px',
                right: 0,
                backgroundColor: 'white'
              }}
              onClick={() => {
                setState(prevState => ({
                  ...prevState,
                  showMobileFieldOptions: false
                }));
              }}
            >
              <StyledCross />
            </IconButton>
          )}
        </div>,
        document.body
      )
    ) : (builderSteps);
  }, [
    isCreateMode,
    isEditMode,
    isMobile,
    steps,
    state.serviceFields.data,
    state.steps.expandedSteps,
    state.steps.showActionMenu,
    state.steps.fieldsAsText,
    state.builder.selectedStepIndex,
    state.builder.selectedFieldIndex,
    state.builder.field,
    state.form.serviceId,
    state.showMobileFieldOptions,
    allStepsCollapsed,
    reOrderSteps,
    reOrderFields,
    selectStep,
    deleteStep,
    addNewField,
    editNewField,
    selectField,
    removeField,
    toggleStepExpansion,
    changeFieldToNewStep,
    toggleExpandAllSteps
  ]);

  const renderField = useCallback((field: Field, step: Step) => {
    let elem: React.ReactNode;

    const value = (state.form.fields[step.name] && state.form.fields[step.name][field.name]) || '';
    const databaseValue = (state.job.data && state.job.data.fields[step.name] && state.job.data.fields[step.name][field.name]) || '';
    const calculate: boolean | undefined = field.calculateAfterChange;
    const hasChanged: boolean = isEditMode && value !== databaseValue;
    const isWritable: boolean = (isCreateMode || (isEditMode && !isComplete));
    const schemaFieldPath: string = `fields.${step.name}.${field.name}`;

    if (field.name.includes('postcode')
      && (isCreateMode || isEditMode)
      && isWritable
    ) {
      return (
        <AdminFormLine
          key={field.id}
          marginBottom
          centerV
        >
          <div
            style={{
              width: '100%',
              marginLeft: '1rem'
            }}
          >
            <PostcodeLookup
              name={'billing-address-postcode'}
              width={'100%'}
              value={value}
              error={errors[schemaFieldPath]}
              label={field.label}
              onChange={(e) => {
                setState(prevState => ({
                  ...prevState,
                  form: {
                    ...prevState.form,
                    fields: {
                      ...prevState.form.fields,
                      address: {
                        ...prevState.form.fields.address,
                        postcode: e.target.value
                      }
                    }
                  }
                }));
              }}
              onSelected={(address: any) => {
                setState(prevState => ({
                  ...prevState,
                  form: {
                    ...prevState.form,
                    fields: {
                      ...prevState.form.fields,
                      address: {
                        ...prevState.form.fields.address,
                        'address-line-1': address.line_1,
                        'address-line-2': address.line_2,
                        'address-line-3': address.line_3.length > 0 ? address.line_3 : address.line_4.length === 0 ? address.locality : address.line_4,
                        city: address.town_or_city
                      }
                    }
                  }
                }));
              }}
              onBlur={() => validate({
                form: state.form,
                field: schemaFieldPath
              })}
            />
          </div>
        </AdminFormLine>
      );
    }

    switch (field.type) {
      case FieldType.ShortText:
      case FieldType.Email:
      case FieldType.Telephone:
        if (isWritable) {
          elem = (
            <TextInput
              hasChanged={hasChanged}
              widthM={'100%'}
              label={field.label}
              value={value}
              error={errors[schemaFieldPath]}
              onChange={(e: any) => {
                setState(prevState => {
                  const newState = {
                    ...prevState,
                    form: {
                      ...prevState.form,
                      fields: {
                        ...prevState.form.fields,
                        [step.name]: {
                          ...prevState.form.fields[step.name],
                          [field.name]: e.target.value
                        }
                      }
                    }
                  };

                  if (calculate && value !== e.target.value) {
                    onCalculateJob(newState);
                  }

                  return newState;
                });
              }}
              onBlur={() => validate({
                form: state.form,
                field: schemaFieldPath
              })}
            />
          );
        } else {
          elem = (
            <Text
              label={field.label}
              value={value}
            />
          );
        }
        break;
      case FieldType.LongText:
        if (isWritable) {
          elem = (
            <TextArea
              hasChanged={hasChanged}
              widthM={'100%'}
              label={field.label}
              value={value}
              error={errors[schemaFieldPath]}
              onChange={(e: any) => {
                setState(prevState => {
                  const newState = {
                    ...prevState,
                    form: {
                      ...prevState.form,
                      fields: {
                        ...prevState.form.fields,
                        [step.name]: {
                          ...prevState.form.fields[step.name],
                          [field.name]: e.target.value
                        }
                      }
                    }
                  };

                  if (calculate && value !== e.target.value) {
                    onCalculateJob(newState);
                  }

                  return newState;
                });
              }}
              onBlur={() => validate({
                form: state.form,
                field: schemaFieldPath
              })}
            />
          );
        } else {
          elem = (
            <Text
              label={field.label}
              value={value}
            />
          );
        }
        break;
      case FieldType.Number:
        if (isWritable) {
          elem = (
            <NumericInput
              hasChanged={hasChanged}
              widthM={'100%'}
              label={field.label}
              min={field.min}
              max={field.max}
              value={value}
              error={errors[schemaFieldPath]}
              onUpdate={(e: any, val) => {
                setState(prevState => {
                  const newState = {
                    ...prevState,
                    form: {
                      ...prevState.form,
                      fields: {
                        ...prevState.form.fields,
                        [step.name]: {
                          ...prevState.form.fields[step.name],
                          [field.name]: val
                        }
                      }
                    }
                  };

                  if (calculate && value !== val) {
                    onCalculateJob(newState);
                  }

                  return newState;
                });
              }}
              onBlur={() => validate({
                form: state.form,
                field: schemaFieldPath
              })}
            />
          );
        } else {
          elem = (
            <Text
              label={field.label}
              value={value}
            />
          );
        }
        break;
      case FieldType.Currency:
        if (isWritable) {
          elem = (
            <TextInput
              hasChanged={hasChanged}
              widthM={'100%'}
              type={FieldType.Currency}
              label={field.label}
              value={value}
              error={errors[schemaFieldPath]}
              onChange={(e: any) => {
                setState(prevState => {
                  const newState = {
                    ...prevState,
                    form: {
                      ...prevState.form,
                      fields: {
                        ...prevState.form.fields,
                        [step.name]: {
                          ...prevState.form.fields[step.name],
                          [field.name]: e.target.value
                        }
                      }
                    }
                  };

                  if (calculate && value !== e.target.value) {
                    onCalculateJob(newState);
                  }

                  return newState;
                });
              }}
              onBlur={() => validate({
                form: state.form,
                field: schemaFieldPath
              })}
            />
          );
        } else {
          elem = (
            <Text
              label={field.label}
              value={formatCurrency(value)}
            />
          );
        }
        break;
      case FieldType.Dropdown:
        if (isWritable) {
          elem = (
            <Dropdown
              hasChanged={hasChanged}
              widthM={'100%'}
              isClearable={true}
              label={field.label}
              value={value}
              error={errors[schemaFieldPath]}
              options={field.dropdownItems!}
              onChange={(option: any) => {
                setState(prevState => {
                  const newState = {
                    ...prevState,
                    form: {
                      ...prevState.form,
                      fields: {
                        ...prevState.form.fields,
                        [step.name]: {
                          ...prevState.form.fields[step.name],
                          [field.name]: (option && option.value) || ''
                        }
                      }
                    }
                  };

                  if (calculate) {
                    onCalculateJob(newState);
                  }

                  return newState;
                });
              }}
              onBlur={() => validate({
                form: state.form,
                field: schemaFieldPath
              })}
            />
          );
        } else {
          elem = (
            <Text
              label={field.label}
              value={value}
            />
          );
        }
        break;
    }

    return (
      <AdminFormLine
        key={field.label}
        row
        marginBottom
        centerV
      >
        <div
          style={{
            width: '100%',
            marginLeft: '1rem'
          }}
        >
          {elem}
        </div>
      </AdminFormLine>
    );
  }, [
    isCreateMode,
    isEditMode,
    isComplete,
    state.form,
    state.job.data,
    errors,
    onCalculateJob,
    validate
  ]);

  const renderSteps = useCallback(() => {
    const stepsToRender = state.builder.show ? steps : filterOutJobPageSteps(steps);

    return stepsToRender.map((step: Step) => {
      return (
        <Card
          key={step.name}
          column
          noPadding
          boxShadow
          style={{
            minHeight: '22.5rem',
            maxHeight: '45rem',
            padding: '0 2rem 2rem'
          }}
        >
          <h4
            className="pinnable"
            style={{
              position: 'sticky',
              top: 0,
              background: theme.colors.neutralSecondary,
              paddingTop: '2rem',
              paddingBottom: '1rem',
              marginBottom: 0,
              marginRight: '2rem',
              zIndex: theme.layers.high
            }}
          >{step.label}</h4>
          <div
            style={{
              height: '100%',
              overflow: 'auto',
              paddingRight: '1rem'
            }}
          >
            <div style={{marginTop: '1rem'}}>
              {step.name === 'address' && state.form.customerId && isCreateMode && (
                <AdminFormLine marginBottom>
                  <Button
                    type="button"
                    onClick={() => {
                      setState(prevState => ({
                        ...prevState,
                        form: {
                          ...prevState.form,
                          fields: {
                            ...prevState.form.fields,
                            address: {
                              ...prevState.form.fields.address,
                              'address-line-1': prevState.temp.customer.addressLine1 || '',
                              'address-line-2': prevState.temp.customer.addressLine2 || '',
                              'address-line-3': prevState.temp.customer.addressLine3 || '',
                              city: prevState.temp.customer.addressCity || '',
                              postcode: prevState.temp.customer.addressPostCode || ''
                            }
                          }
                        }
                      }));
                    }}
                  >Use customer address</Button>
                </AdminFormLine>
              )}
              {step.fields.map((f: Field) => renderField(f, step))}
            </div>
          </div>
        </Card>
      );
    });
  }, [
    steps,
    isCreateMode,
    state.builder.show,
    state.form.customerId,
    renderField
  ]);

  const renderWorkSchedule = useCallback((schedule: any, isReadMode?: boolean) => {
    return (
      <AdminFormLine
        column
        marginTop={isReadMode ? true : false}
        marginBottom={isReadMode ? false : true}
      >
        <u style={{ marginBottom: '.5rem'}}>Work schedule</u>
        {Object
          .entries(schedule)
          .map((entry: any[]) => {
            const startEndSplit: string[] = entry[1].split('-');
            const startTime: string = startEndSplit[0];
            const startTimeSplit: string[] = startTime.split(':');
            const startHour: string = doubleSingleDigit(startTimeSplit[0]);
            const startMinute: string = doubleSingleDigit(startTimeSplit[1]);
            const endTime: string = startEndSplit[1];
            const endTimeSplit: string[] = endTime.split(':');
            const endHour: string = doubleSingleDigit(endTimeSplit[0]);
            const endMinute: string = doubleSingleDigit(endTimeSplit[1]);

            return (
              <AdminFormLine
                row
                centerV
                key={entry[0]}
              >
                <DateKey>{format(entry[0], 'do MMM')}</DateKey>
                <DateSplitter>-</DateSplitter>
                <span>{startHour}:{startMinute}</span>
                <TimeSplitter>-</TimeSplitter>
                <span>{endHour}:{endMinute}</span>
              </AdminFormLine>
            );
          })
        }
      </AdminFormLine>
    );
  }, []);

  const onIframeMessage = useCallback((event: any) => {
    if (event.origin === window.location.origin) {
      let data = null;

      try {
        data = JSON.parse(event.data);
      } catch (e) {};

      if (!data) {
        return;
      }

      if (data.to === 'parent') {
        // console.log('--------Parent', data);

        switch (data.action) {
          case FrameActions.SELECT_STEP: {
            const stepIndex = data.payload.stepIndex;

            selectStep(stepIndex);
            break;
          }
          case FrameActions.SELECT_FIELD:
          case FrameActions.EDIT_FIELD: {
            const stepIndex = data.payload.selectedStepIndex;
            const fieldIndex = data.payload.selectedFieldIndex;
            const field = data.payload.field;

            selectField(stepIndex, fieldIndex, field);

            if (data.action === FrameActions.EDIT_FIELD) {
              editField();
            }

            break;
          }
          case FrameActions.DELETE_FIELD: {
            const stepIndex = data.payload.selectedStepIndex;
            const fieldIndex = data.payload.selectedFieldIndex;

            removeField(stepIndex, fieldIndex);
            break;
          }
          case FrameActions.REORDER_FIELDS: {
            const stepIndex = data.payload.stepIndex;
            const dragIndex = data.payload.dragIndex;
            const hoverIndex = data.payload.hoverIndex;

            reOrderFields(stepIndex, dragIndex, hoverIndex);
            break;
          }
          case FrameActions.REMOVE_STEP: {
            const stepIndex = data.payload.stepIndex;

            deleteStep(stepIndex);
            break;
          }
        }
      }
    }
  }, [
    selectStep,
    selectField,
    editField,
    removeField,
    reOrderFields,
    deleteStep
  ]);

  const sendInitialDataToFrame = useCallback(() => {
    const frameData = {
      action: FrameActions.UPDATE_CHILD,
      to: 'child',
      payload: {
        service: {
          data: {
            ...selectedService!,
            steps,
            ...(isCreateMode && {
              _id: 'newService',
              clientId
            })
          },
          // TODO: use initialState from state.ts
          builder: {
            context: 'service',
            selectedStepIndex: state.builder.selectedStepIndex,
            selectedFieldIndex: state.builder.selectedFieldIndex,
            selectedFields: [],
            isSelectable: true,
            isEditable: true,
            isSortable: true,
            isDeletable: true,
            hideBackground: !isMinWidth(sizes.tablet),
            hideFooter: !isMinWidth(sizes.tablet)
          }
        }
      }
    };

    // console.log('---------frameData', frameData);

    if (iframeRef.current) {
      (iframeRef.current!.contentWindow! as BrowserWindow).isChild = true;
      (iframeRef.current!.contentWindow! as BrowserWindow).isModifiable = (isCreateMode || isEditMode) && state.form.serviceId === 'none';
      iframeRef.current!.contentWindow!.postMessage(JSON.stringify(frameData), window.location.origin);
    }
  }, [
    steps,
    isCreateMode,
    isEditMode,
    clientId,
    state.form.serviceId,
    state.builder.selectedStepIndex,
    state.builder.selectedFieldIndex,
    selectedService
  ]);

  const addMessageListener = useCallback(() => {
    if (iframeRef.current) {
      iframeRef.current.contentWindow!.addEventListener('message', onIframeMessage);
    }
  }, [onIframeMessage]);

  const onFrameLoad = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      builder: {
        ...prevState.builder,
        frameLoaded: true
      }
    }));

    addMessageListener();
    sendInitialDataToFrame();
  }, [
    addMessageListener,
    sendInitialDataToFrame
  ]);

  const renderIFrameSection = useCallback(() => {
    return (
      <PreviewPane key={`iframe${isCreateMode || isEditMode ? '-editable' : ''}`}>
        <Device>
          <StyledFrame
            inline
            ref={iframeRef}
            title={'Booking preview'}
            onLoad={onFrameLoad}
            src={`${window.location.origin}/${clientId}/booking/new/1`}
          />
          {!state.builder.frameLoaded && (
            <FrameSpinner>
              <Spinner
                color={theme.textColor}
                size={'L'}
              />
            </FrameSpinner>
          )}
        </Device>
      </PreviewPane>
    );
  }, [
    isCreateMode,
    isEditMode,
    clientId,
    state.builder.frameLoaded,
    onFrameLoad
  ]);

  const renderStepFields = useCallback(() => {
    const stepsToRender = state.builder.show ? steps : filterOutJobPageSteps(steps);
    const stepCount: number = stepsToRender.length;
    const cols: number = Math.min(Math.max(2, +stepCount), 3);
    const sectionTitle: string = state.form.serviceId === 'none' ? 'Fields' : 'Service fields';

    if (isCreateMode && !state.form.serviceId) {
      return null;
    }

    let builderButtonText: string = '';
    let builderButtonIcon = <Edit />;

    if (state.builder.show) {
      if (isCreateMode) {
        if (state.form.serviceId === 'none') {
          builderButtonText = 'Enter details';
        } else {
          builderButtonText = 'Hide preview';
          builderButtonIcon = <Phone />;
        }
      }
    } else {
      if (isCreateMode) {
        if (state.form.serviceId === 'none') {
          builderButtonText = 'Customize';
        } else {
          builderButtonText = 'Show preview';
          builderButtonIcon = <Phone />;
        }
      }
    }

    return (
      <AdminFormLine column marginBottom>
        <AdminFormLine
          row
          centerV
          spaceBetween
          marginBottom
        >
          <h3 style={{marginBottom: 0}}>{sectionTitle}</h3>
          {isCreateMode && state.form.serviceId && (
            <Button
              type="button"
              style={{marginBottom: 0}}
              icon={builderButtonIcon}
              onClick={toggleCustomizeFields}
            >{builderButtonText}</Button>
          )}
        </AdminFormLine>
        {state.builder.show ? (
          <BuilderWrapper>
            <Grid>
              <MobileSettingsWrapper show={state.showMobileFieldOptions}>
                <ConfigWrapper>
                  {isMobile ? state.showMobileFieldOptions ? renderBuilderSteps() : null : renderBuilderSteps()}
                </ConfigWrapper>
              </MobileSettingsWrapper>
              <AdminFormLine className={'mobile-settings-seperator'}>
                <StyledHR $alt />
              </AdminFormLine>
              <IFrameSectionWrapper blur={state.showMobileFieldOptions}>
                {renderIFrameSection()}
                {(isCreateMode || isEditMode) && (
                  <MobileSettingIconWrapper
                    noRight
                    mobileSettingsOn={state.showMobileFieldOptions}
                  >
                    <IconButton
                      medium
                      hoverEffectAlwaysOn
                      type="button"
                      style={{
                        backgroundColor: 'white'
                      }}
                      onClick={() => {
                        setState(prevState => ({
                          ...prevState,
                          showMobileFieldOptions: true
                        }));
                      }}
                    >
                      <StyledMobileEditIcon />
                    </IconButton>
                  </MobileSettingIconWrapper>
                )}
              </IFrameSectionWrapper>
            </Grid>
          </BuilderWrapper>
        ) : (
          <FormSection
            noBorder
            noPadding
            cols={cols}
          >
            {renderSteps()}
          </FormSection>
        )}
      </AdminFormLine>
    )
  }, [
    steps,
    isCreateMode,
    isEditMode,
    isMobile,
    state.form,
    state.builder.show,
    state.showMobileFieldOptions,
    renderSteps,
    renderBuilderSteps,
    toggleCustomizeFields,
    renderIFrameSection
  ]);

  const renderAdditionalCharges = useCallback(() => {
    const charges: AdditionalCharge[] | null = !state.job.data ? null : state.job.data.additionalCharges;

    if (isCreateMode || !charges || (charges && charges.length === 0)) {
      return null;
    }

    return (
      <AdminFormLine column marginBottom>
        <Card
          column
          boxShadow
          style={{
            marginBottom: '2rem',
            paddingTop: '0'
          }}
        >
          <AdminFormLine
            row
            centerV
            spaceBetween
            className="pinnable"
            style={{
              position: 'sticky',
              top: 0,
              background: theme.colors.neutralSecondary,
              zIndex: theme.layers.high,
              paddingTop: '2rem',
              paddingBottom: '1.75rem'
            }}
          >
            <h3 style={{ marginBottom: 0, marginRight: '2rem' }}>Additional charges (ac)</h3>
          </AdminFormLine>
          <Table
            namespaceKey={'additional-charges'}
            headerConfig={getChargesHeaderConfig()}
            rows={state.job.data!.additionalCharges! || []}
            total={(state.job.data!.additionalCharges! && state.job.data!.additionalCharges!.length) || 0}
          />
        </Card>
      </AdminFormLine>
    )
  }, [
    isCreateMode,
    state.job.data,
    getChargesHeaderConfig
  ]);

  const renderPayments = useCallback(() => {
    if (concealJobPrice(userData.user!) || isCreateMode) {
      return null;
    }

    const payments = state.payments.data;

    if (!payments || !state.client.data || (payments && payments.length === 0)) {
      return null;
    }

    return (
      <AdminFormLine column marginBottom>
        <Card
          column
          boxShadow
          style={{
            marginBottom: '2rem',
            paddingTop: '0'
          }}
        >
          <AdminFormLine
            row
            centerV
            spaceBetween
            className="pinnable"
            style={{
              position: 'sticky',
              top: 0,
              background: theme.colors.neutralSecondary,
              zIndex: theme.layers.high,
              paddingTop: '2rem',
              paddingBottom: '1.75rem'
            }}
          >
            <h3 style={{ marginBottom: 0, marginRight: '2rem' }}>Payments</h3>
          </AdminFormLine>
          <Table
            namespaceKey={'payments'}
            loading={state.payments.loading}
            headerConfig={getPaymentsHeaderConfig()}
            rows={state.payments.data! || []}
            total={state.payments.total!}
            offset={state.payments.offset}
            sortState={paymentsSortState}
            onSort={paymentsSetSortState}
          />
        </Card>
      </AdminFormLine>
    )
  }, [
    userData,
    isCreateMode,
    state.payments,
    state.client.data,
    getPaymentsHeaderConfig,
    paymentsSortState,
    paymentsSetSortState
  ]);

  const onCancel = useCallback(() => {
    if (isCreateMode) {
      navigate({
        pathname: `/${clientId}/back-office/jobs`
      });
    }
    else if (isEditMode && id) {
      if (state.job.data) {
        setState(prevState => ({
          ...prevState,
          form: {
            ...state.job.data
          }
        }));
      }

      navigate({
        pathname: `/${clientId}/back-office/jobs/${id}`
      });
    }
  }, [
    isCreateMode,
    isEditMode,
    id,
    clientId,
    state.job.data,
    navigate
  ]);

  const onAddCharges = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      chargesModal: {
        ...prevState.chargesModal,
        show: true
      }
    }));
  }, []);

  const onMakePayment = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      paymentModal: {
        ...prevState.paymentModal,
        show: true
      }
    }));
  }, []);

  const onFormSubmit = useCallback((e: any) => {
    e.preventDefault();

    validate({
      form: state.form,
      all: true,
      propagateRejection: true
    })
      .then(() => {
        if (Object.keys(state.errors).length) {
          return;
        }

        if (isCreateMode) {
          // if return to jobs button is clicked
          if (state.jobCreate.data && state.jobCreate.data._id) {
            return navigate({
              pathname: `/${clientId}/back-office/jobs`
            });
          }

          createJob((newJobId: string) => {
            navigate({
              pathname: `/${clientId}/back-office/jobs/${newJobId}`
            });
          });
        }
        else if (isEditMode) {
          const {
            _id,
            reference,
            created,
            updated,
            calculatedPrice,
            priceOverridden,
            fulfiller,
            fulfillerLabel,
            service,
            serviceId,
            clientId: cID,
            byAdmin,
            amountDue,
            qboInvoiceId,
            paymentReminders,
            hourReminders,
            pendingReminders,
            unAssignmentReminderSent,
            customerId,
            noCustomerCommunications,
            customer,
            tz,
            maxDuration,
            start,
            end,
            ...rest
          } = state.form;

          updateJob(id!, rest, () => {
            navigate({
              pathname: `/${clientId}/back-office/jobs/${id}`
            });
          });
        }
      })
      .catch(() => {
        addToast({
          type: 'error',
          content: 'Please ensure all required fields are filled in correctly',
          autoRemove: true
        });
      });
  }, [
    navigate,
    id,
    clientId,
    isCreateMode,
    isEditMode,
    state.form,
    state.errors,
    state.jobCreate.data,
    addToast,
    validate,
    createJob,
    updateJob
  ]);

  const getPrimaryButtonText = useCallback(() => {
    let text = 'job';

    if (isCreateMode) {
      text = `Create ${text}`;

      if (state.jobCreate.data && state.jobCreate.data._id) {
        text = 'Return to jobs';
      }
    }
    else if (isEditMode) {
      text = `Update ${text}`;
    }

    return text;
    // {`${isCreateMode ? 'Create' : isEditMode ? 'Update' : ''} job`}
  }, [
    isCreateMode,
    isEditMode,
    state.jobCreate.data
  ]);

  const renderPaymentLink = useCallback(() => {
    if (!state.job.data || !canHandlePayments(
      state.client.data,
      userData.user
    )) {
      return null;
    }

    const paymentLink = getPaymentLink(
      clientId!,
      state.form.serviceId,
      steps.length,
      state.job.data._id
    );

    return (
      <AdminFormLine>
        <FormField
          label={'Payment link'}
        >
          <>
            <PaymentLink onClick={() => {
              copyText(paymentLink)
                .then(() => {
                  if (!state.client.data!.isOnboarded) {
                    return addToast({
                      type: 'error',
                      content: 'Please complete all the steps on the homepage to fully get setup'
                    });
                  }

                  addToast({
                    type: 'success',
                    content: 'Payment link copied'
                  });
                })
                .catch(() => {
                  addToast({
                    type: 'error',
                    content: 'Error copying payment link. Please copy manually.'
                  });
                });
            }}>
              <span>{state.client.data!.isOnboarded ? paymentLink : 'Please complete all the steps on the homepage to fully get setup'}</span>
              <span><StyledCopyIcon /></span>
            </PaymentLink>
          </>
        </FormField>
      </AdminFormLine>
    );
  }, [
    clientId,
    userData.user,
    state.client.data,
    state.job.data,
    state.form.serviceId,
    steps.length,
    addToast
  ]);

  const statusOptions = useCallback((currentStatus: Status): DropdownOption[] => {
    const options: DropdownOption[] = [];

    // Some states can be switched to via a different CTA.
    // This allows the option to be displayed in the dropdown to switch back to.
    if (currentStatus.type === StatusType.SUBMITTED) {
      options.push(
        createDropdownOption(formatJobStatus(StatusType.SUBMITTED), StatusType.SUBMITTED)
      );
    }
    if (currentStatus.type === StatusType.DEFERRED) {
      options.push(
        createDropdownOption(formatJobStatus(StatusType.DEFERRED), StatusType.DEFERRED)
      );
    }
    if (currentStatus.type === StatusType.DELAYED) {
      options.push(
        createDropdownOption(formatJobStatus(StatusType.DELAYED), StatusType.DELAYED)
      );
    }
    if (currentStatus.type === StatusType.REFUNDED) {
      options.push(
        createDropdownOption(formatJobStatus(StatusType.REFUNDED), StatusType.REFUNDED)
      );
    }
    if (currentStatus.type === StatusType.CHARGES_PENDING) {
      options.push(
        createDropdownOption(formatJobStatus(StatusType.CHARGES_PENDING), StatusType.CHARGES_PENDING)
      );
    }

    // Only job to be updated to PENDING if it's the day of the job
    const jobDate = new Date(stripZoneFromISOString(state.job.data?.fields?.date?.iso[0]));
    if (isSameDay(new Date(), jobDate)) {
      options.push(
        createDropdownOption(formatJobStatus(StatusType.PENDING), StatusType.PENDING)
      );
    }

    // Only add CHARGES_PAID if CHARGES_PENDING is most recent status
    if (findLastStatusType(state.job.data?.status || [], StatusType.CHARGES_PENDING)) {
      options.push(
        createDropdownOption(formatJobStatus(StatusType.CHARGES_PAID), StatusType.CHARGES_PAID),
      );
    }

    options.push(
      createDropdownOption(formatJobStatus(StatusType.PAID), StatusType.PAID),
      createDropdownOption(formatJobStatus(StatusType.CANCELLED), StatusType.CANCELLED),
      createDropdownOption(formatJobStatus(StatusType.COMPLETE), StatusType.COMPLETE),
      createDropdownOption(formatJobStatus(StatusType.DISPUTE), StatusType.DISPUTE)
    );

    return options;
  }, [state.job.data]);

  const closeDelayModal = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      delayModal: {
        ...initialState.delayModal
      }
    }));
  }, []);

  const closeChargesModal = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      chargesModal: {
        ...initialState.chargesModal
      }
    }));
  }, []);

  const closeRefundModal = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      refundModal: {
        ...initialState.refundModal
      }
    }));
  }, []);

  const closePaymentModal = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      paymentModal: {
        ...initialState.paymentModal
      }
    }));
  }, []);

  const closeFulfillerSettingsModal = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      fulfillerSettingsModal: {
        ...initialState.fulfillerSettingsModal
      }
    }));
  }, []);

  const closeDateEntrySettingsModal = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      dateEntrySettingsModal: {
        ...initialState.dateEntrySettingsModal
      }
    }));
  }, []);

  const onEdit = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        calculatedPrice: initialState.form.calculatedPrice
      }
    }));

    navigate({ pathname: `/${clientId}/back-office/jobs/${id}/edit` });
  }, [
    navigate,
    clientId,
    id
  ]);

  const onRefund = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      refundModal: {
        ...prevState.refundModal,
        show: true
      }
    }));
  }, []);

  const onChargesSubmit = useCallback((cb: () => void) => {
    const jobId: string = state.job.data!._id;

    updateJob(jobId, {
      additionalCharges: [
        ...state.job.data?.additionalCharges || [],
        ...state.chargesModal.form
      ]
    }, () => {
      cb();
    });
  }, [
    state.chargesModal.form,
    state.job.data,
    updateJob
  ]);

  const loadCustomerOptions = useCallback(async (value: string) => {
    const options: Array<{ label: string; value: string }> = await new Promise((resolve) => {
      debounceFetchCustomers(value, (customers) => {
        resolve(
          (customers ?? [])
            .map((customer: Customer) => ({
              ...customer,
              ...createDropdownOption(getCustomerName(customer), customer._id)
            }))
        );
      });
    });

    return options;
  }, [debounceFetchCustomers]);

  const openCustomerModal = useCallback((newOption?: DropdownOption) => {
    resetCustomerForm();

    const {
      firstName,
      lastName
    } = extractFirstLastName(newOption?.label);

    setState(prevState => ({
      ...prevState,
      customerModal: {
        ...prevState.customerModal,
        show: true,
        mode: !!newOption ? 'create' : 'update',
        form: {
          ...prevState.customerModal.form,
          ...(firstName && !prevState.temp.customer && {
            firstName
          }),
          ...(lastName && !prevState.temp.customer && {
            lastName
          }),
          ...(!newOption && prevState.temp.customer && {
            ...prevState.temp.customer
          })
        }
      }
    }));
  }, [resetCustomerForm]);

  const closeCustomerModal = useCallback((isUpdate: boolean) => {
    resetCustomerForm();

    setState(prevState => {
      const customerCreated: boolean = !!prevState.customerModal.data;

      const newState = {
        ...prevState,
        customerModal: {
          ...initialState.customerModal
        },
        ...(!customerCreated && !isUpdate && {
          form: {
            ...prevState.form,
            customerId: ''
          },
          temp: {
            ...prevState.temp,
            customerIdOption: null
          }
        })
      };

      return newState;
    });
  }, [resetCustomerForm]);

  const onCustomerSubmit = useCallback((isCreate: boolean, cb: () => void) => {
    validateCustomerForm({
      form: state.customerModal.form,
      all: true
    })
      .then(() => {
        if (isCreate) {
          createCustomer(state.customerModal.form, cb);
        } else {
          updateCustomer(state.customerModal.form._id as string, state.customerModal.form, cb);
        }
      });
  }, [
    state.customerModal.form,
    createCustomer,
    updateCustomer,
    validateCustomerForm
  ]);

  const [validateCustomerEmailRemotely] = useDebouncedCallback((e: any) => {
    const emailAddress: string = (e.target.value || '').trim();

    gatewayService
      .checkForDuplicateCustomer(clientId!, emailAddress)
      .then((fulfillerResponse: any) => {
        // TODO; check that the component is still mounted otherwise exit

        setState(prevState => ({
          ...prevState,
          errors: {}
        }));
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        let errorMessage: string = error.message;

        if (error.status === 400) {
          errorMessage = 'This email has already been taken';

          if (!errorMessage.includes('already exists')) {
            errorMessage = 'Must be a valid email';
          }
        }

        setState(prevState => ({
          ...prevState,
          errors: {
            email: errorMessage
          }
        }));
      });
  }, 1000);

  // TODO: find way of chaining modals. Maybe have a modal manager where these configs are passed in as an array and when one has finished being shown, it loads the next.
  const onMarkJobComplete = useCallback(() => {
    return setState(prevState => ({
      ...prevState,
      modal: {
        show: true,
        header: `Mark job complete`,
        content: 'Are you sure you\'d like to mark this job as complete? The customer will receive a notification',
        buttons: [
          {
            type: 'standard',
            text: 'No'
          },
          {
            type: 'primary',
            text: 'Yes',
            loading: 'state.jobUpdate.loading',
            onClick: (cb: () => void) => {
              updateJob(
                prevState.job.data!._id,
                {
                  status: [
                    ...prevState.job.data!.status,
                    {
                      type: StatusType.COMPLETE,
                      date: format(new Date(), ISO_FORMAT),
                      author: {
                        _id: userData.user!._id,
                        name: `${userData.user!.firstName} ${userData.user!.lastName}`
                      }
                    }
                  ]
                },
                (e?: NetworkError) => {
                  if (!e) {
                    fetchJob();

                    cb();
                  }
                }
              );
            }
          }
        ]
      }
    }));
  }, [
    userData.user,
    updateJob,
    fetchJob
  ]);

  const closeModal = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      modal: {
        ...initialState.modal
      }
    }));
  }, []);

  const onCopyToNew = useCallback(() => {
    dispatch({
      type: Actions.JOB_TO_COPY,
      payload: getJobToCopy(state.job.data!)
    });

    setState({ ...initialState });

    navigate({
      pathname: `/${clientId}/back-office/jobs/create`
    });
  }, [
    clientId,
    state.job,
    dispatch,
    navigate
  ]);

  const openFulfillerSettingsModal = useCallback((newOption?: DropdownOption) => {
    setState(prevState => ({
      ...prevState,
      fulfillerSettingsModal: {
        ...prevState.fulfillerSettingsModal,
        show: true
      }
    }));
  }, []);

  const openDateEntrySettingsModal = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      dateEntrySettingsModal: {
        ...prevState.dateEntrySettingsModal,
        show: true
      }
    }));
  }, []);

  // TODO: abstract
  const renderModal = useCallback(() => {
    if (!state.modal.show) {
      return null;
    }

    return (
      <Popup
        id={'event-modal'}
        layered
        convertable
        noPadding
        onClose={closeModal}
      >
        {({ closePopup }) => {
          return (
            <ModalWrapper>
              <ModalItem>
                <AdminFormLine marginTop>
                  <h3 style={{marginBottom: 0}}>{state.modal.header}</h3>
                </AdminFormLine>
              </ModalItem>

              <AdminFormLine marginBottom />
              <ModalBorder />
              <AdminFormLine marginBottom />

              <ModalItem>
                <AdminFormLine marginBottom>
                  {state.modal.content}
                </AdminFormLine>
              </ModalItem>

              <AdminFormLine marginBottom />
              <ModalBorder />
              <AdminFormLine marginBottom />

              <ModalItem>
                <AdminFormLine right>
                  {state.modal.buttons.map((button: any, index: number) => {
                    switch(button.type) {
                      case 'standard':
                        return (
                          <Button
                            key={index}
                            type={'button'}
                            onClick={() => {
                              closePopup();

                              if (button.onClick) {
                                button.onClick();
                              }
                            }}
                            style={{ marginRight: '1rem' }}
                          >{button.text}</Button>
                        );
                      case 'primary':
                        return (
                          <PrimaryButton
                            key={index}
                            type={'submit'}
                            loading={
                              // eslint-disable-next-line
                              eval(button.loading)
                            }
                            spinnerColor={theme.colors.coreSecondary}
                            onClick={() => {
                              if (button.onClick) {
                                button.onClick(closePopup);
                              }
                            }}
                          >{button.text}</PrimaryButton>
                        );
                    };

                    return null;
                  })}
                </AdminFormLine>
              </ModalItem>
            </ModalWrapper>
          );
        }}
      </Popup>
    );
  }, [
    state,
    closeModal
  ]);

  const renderCustomerModal = useCallback(() => {
    if (!state.customerModal.show) {
      return null;
    }

    const isCreate: boolean = state.customerModal.mode === 'create';
    const isUpdate: boolean = state.customerModal.mode === 'update';
    const customer = state.temp.customer;
    const firstNameHasChanged: boolean = isUpdate && !!customer && state.customerModal.form.firstName !== customer.firstName;
    const lastNameHasChanged: boolean = isUpdate && !!customer && state.customerModal.form.lastName !== customer.lastName;
    const businessNameHasChanged: boolean = isUpdate && !!customer && state.customerModal.form.businessName !== customer.businessName;
    const emailHasChanged: boolean = isUpdate && !!customer && state.customerModal.form.email !== customer.email;
    const phoneHasChanged: boolean = isUpdate && !!customer && state.customerModal.form.phone !== customer.phone;
    const addressLine1HasChanged: boolean = isUpdate && !!customer && state.customerModal.form.addressLine1 !== customer.addressLine1;
    const addressLine2HasChanged: boolean = isUpdate && !!customer && state.customerModal.form.addressLine2 !== customer.addressLine2;
    const addressLine3HasChanged: boolean = isUpdate && !!customer && state.customerModal.form.addressLine3 !== customer.addressLine3;
    const addressCityHasChanged: boolean = isUpdate && !!customer && state.customerModal.form.addressCity !== customer.addressCity
    // const addressPostCodeHasChanged: boolean = isUpdate && !!customer && state.customerModal.form.addressPostCode !== customer.addressPostCode;

    const headerTextPrefix = isCreate ? 'Create' : 'Update';
    const submitBtnPrefix = isCreate ? 'Submit' : 'Update';

    return (
      <Popup
        key={state.customerModal.mode}
        id={'customer-modal'}
        layered
        convertable
        noPadding
        onClose={() => closeCustomerModal(isUpdate)}
      >
        {({ closePopup }) => {
          return (
            <ModalWrapper>
              <ModalItem>
                <AdminFormLine marginTop>
                  <h3 style={{marginBottom: 0, marginRight: '2rem' }}>{`${headerTextPrefix}`} customer</h3>
                </AdminFormLine>
              </ModalItem>

              <AdminFormLine marginBottom />
              <ModalBorder />
              <AdminFormLine marginBottom />

              <ModalContentWrapper>
                <ModalItem>
                  <AdminFormLine marginBottom>
                    <TextInput
                      ref={customerNameAutoFocus}
                      hasChanged={firstNameHasChanged}
                      width={'35rem'}
                      value={state.customerModal.form.firstName as string || ''}
                      error={customerErrors.firstName}
                      label={'First name'}
                      onChange={(e) => {
                        setState(prevState => ({
                          ...prevState,
                          customerModal: {
                            ...prevState.customerModal,
                            form: {
                              ...prevState.customerModal.form,
                              firstName: e.target.value
                            }
                          }
                        }));
                      }}
                      onBlur={() => validateCustomerForm({
                        form: state.customerModal.form,
                        field: 'firstName'
                      })}
                    />
                  </AdminFormLine>
                </ModalItem>

                <ModalItem>
                  <AdminFormLine marginBottom>
                    <TextInput
                      hasChanged={lastNameHasChanged}
                      width={'35rem'}
                      value={state.customerModal.form.lastName as string || ''}
                      error={customerErrors.lastName}
                      label={'Last name'}
                      onChange={(e) => {
                        setState(prevState => ({
                          ...prevState,
                          customerModal: {
                            ...prevState.customerModal,
                            form: {
                              ...prevState.customerModal.form,
                              lastName: e.target.value
                            }
                          }
                        }));
                      }}
                      onKeyUp={(e) => {
                        if (e.key === 'Enter') {
                          onCustomerSubmit(isCreate, () => {
                            if (isEditMode || (!isEditMode && !isCreateMode)) {
                              fetchJob();
                            }

                            closePopup();
                          });
                        }
                      }}
                      onBlur={() => validateCustomerForm({
                        form: state.customerModal.form,
                        field: 'lastName'
                      })}
                    />
                  </AdminFormLine>
                </ModalItem>

                <ModalItem>
                  <AdminFormLine marginBottom>
                    <TextInput
                      hasChanged={businessNameHasChanged}
                      width={'35rem'}
                      value={state.customerModal.form.businessName as string || ''}
                      error={customerErrors.businessName}
                      label={'Business name'}
                      onChange={(e) => {
                        setState(prevState => ({
                          ...prevState,
                          customerModal: {
                            ...prevState.customerModal,
                            form: {
                              ...prevState.customerModal.form,
                              businessName: e.target.value
                            }
                          }
                        }));
                      }}
                      onKeyUp={(e) => {
                        if (e.key === 'Enter') {
                          onCustomerSubmit(isCreate, () => {
                            if (isEditMode || (!isEditMode && !isCreateMode)) {
                              fetchJob();
                            }

                            closePopup();
                          });
                        }
                      }}
                      onBlur={() => validateCustomerForm({
                        form: state.customerModal.form,
                        field: 'businessName'
                      })}
                    />
                  </AdminFormLine>
                </ModalItem>

                <ModalItem>
                  <AdminFormLine marginBottom>
                    <TextInput
                      hasChanged={emailHasChanged}
                      width={'35rem'}
                      value={state.customerModal.form.email as string || ''}
                      error={customerErrors.email || state.errors.email}
                      label={'Email'}
                      onChange={(e) => {
                        setState(prevState => ({
                          ...prevState,
                          customerModal: {
                            ...prevState.customerModal,
                            form: {
                              ...prevState.customerModal.form,
                              email: e.target.value
                            }
                          }
                        }));

                        try {
                          const emailIsValid = customerSchema.validateSyncAt('email', { email: e.target.value });
                          if (emailIsValid) {
                            validateCustomerEmailRemotely(e);
                          }
                        } catch(e) {}
                      }}
                      onKeyUp={(e) => {
                        if (e.key === 'Enter') {
                          onCustomerSubmit(isCreate, () => {
                            if (isEditMode || (!isEditMode && !isCreateMode)) {
                              fetchJob();
                            }

                            closePopup();
                          });
                        }
                      }}
                      onBlur={() => validateCustomerForm({
                        form: state.customerModal.form,
                        field: 'email'
                      })}
                    />
                  </AdminFormLine>
                </ModalItem>

                <ModalItem>
                  <AdminFormLine marginBottom>
                    <TextInput
                      hasChanged={phoneHasChanged}
                      width={'35rem'}
                      value={stripCountryCodeFromNumber(state.customerModal.form.phone as string || '')}
                      error={customerErrors.phone}
                      label={'Contact number'}
                      inputMode="numeric"
                      maxLength={10}
                      inputStartAdornment={
                        <div>+44</div>
                      }
                      onInput={(e: any) => {
                        extractNumericInput(e);

                        setTimeout(() => {
                          e.target.value = stripLeadingZeroFromNumber(e.target.value);
                        }, 1000);
                      }}
                      onChange={(e) => {
                        setState(prevState => ({
                          ...prevState,
                          customerModal: {
                            ...prevState.customerModal,
                            form: {
                              ...prevState.customerModal.form,
                              phone: addCountryCodeToNumber(e.target.value)
                            }
                          }
                        }));
                      }}
                      onKeyUp={(e) => {
                        if (e.key === 'Enter') {
                          onCustomerSubmit(isCreate, () => {
                            if (isEditMode || (!isEditMode && !isCreateMode)) {
                              fetchJob();
                            }

                            closePopup();
                          });
                        }
                      }}
                      onBlur={() => validateCustomerForm({
                        form: state.customerModal.form,
                        field: 'phone'
                      })}
                    />
                  </AdminFormLine>
                </ModalItem>

                <AdminFormLine marginBottom />
                <AdminFormLine marginBottom>
                  <HR $alt />
                </AdminFormLine>
                <AdminFormLine marginBottom />

                <ModalItem>
                  <AdminFormLine marginBottom>
                    <TextInput
                      hasChanged={addressLine1HasChanged}
                      width={'35rem'}
                      value={state.customerModal.form.addressLine1 as string || ''}
                      error={customerErrors.addressLine1}
                      label={'Address line 1'}
                      onChange={(e) => {
                        setState(prevState => ({
                          ...prevState,
                          customerModal: {
                            ...prevState.customerModal,
                            form: {
                              ...prevState.customerModal.form,
                              addressLine1: e.target.value
                            }
                          }
                        }));
                      }}
                      onKeyUp={(e) => {
                        if (e.key === 'Enter') {
                          onCustomerSubmit(isCreate, () => {
                            if (isEditMode || (!isEditMode && !isCreateMode)) {
                              fetchJob();
                            }

                            closePopup();
                          });
                        }
                      }}
                      onBlur={() => validateCustomerForm({
                        form: state.customerModal.form,
                        field: 'addressLine1'
                      })}
                    />
                  </AdminFormLine>
                </ModalItem>

                <ModalItem>
                  <AdminFormLine marginBottom>
                    <TextInput
                      hasChanged={addressLine2HasChanged}
                      width={'35rem'}
                      value={state.customerModal.form.addressLine2 as string || ''}
                      error={customerErrors.addressLine2}
                      label={'Address line 2'}
                      onChange={(e) => {
                        setState(prevState => ({
                          ...prevState,
                          customerModal: {
                            ...prevState.customerModal,
                            form: {
                              ...prevState.customerModal.form,
                              addressLine2: e.target.value
                            }
                          }
                        }));
                      }}
                      onKeyUp={(e) => {
                        if (e.key === 'Enter') {
                          onCustomerSubmit(isCreate, () => {
                            if (isEditMode || (!isEditMode && !isCreateMode)) {
                              fetchJob();
                            }

                            closePopup();
                          });
                        }
                      }}
                      onBlur={() => validateCustomerForm({
                        form: state.customerModal.form,
                        field: 'addressLine2'
                      })}
                    />
                  </AdminFormLine>
                </ModalItem>

                <ModalItem>
                  <AdminFormLine marginBottom>
                    <TextInput
                      hasChanged={addressLine3HasChanged}
                      width={'35rem'}
                      value={state.customerModal.form.addressLine3 as string || ''}
                      error={customerErrors.addressLine3}
                      label={'Address line 3'}
                      onChange={(e) => {
                        setState(prevState => ({
                          ...prevState,
                          customerModal: {
                            ...prevState.customerModal,
                            form: {
                              ...prevState.customerModal.form,
                              addressLine3: e.target.value
                            }
                          }
                        }));
                      }}
                      onKeyUp={(e) => {
                        if (e.key === 'Enter') {
                          onCustomerSubmit(isCreate, () => {
                            if (isEditMode || (!isEditMode && !isCreateMode)) {
                              fetchJob();
                            }

                            closePopup();
                          });
                        }
                      }}
                      onBlur={() => validateCustomerForm({
                        form: state.customerModal.form,
                        field: 'addressLine3'
                      })}
                    />
                  </AdminFormLine>
                </ModalItem>

                <ModalItem>
                  <AdminFormLine marginBottom>
                    <TextInput
                      hasChanged={addressCityHasChanged}
                      width={'35rem'}
                      value={state.customerModal.form.addressCity as string || ''}
                      error={customerErrors.addressCity}
                      label={'Address city'}
                      onChange={(e) => {
                        setState(prevState => ({
                          ...prevState,
                          customerModal: {
                            ...prevState.customerModal,
                            form: {
                              ...prevState.customerModal.form,
                              addressCity: e.target.value
                            }
                          }
                        }));
                      }}
                      onKeyUp={(e) => {
                        if (e.key === 'Enter') {
                          onCustomerSubmit(isCreate, () => {
                            if (isEditMode || (!isEditMode && !isCreateMode)) {
                              fetchJob();
                            }

                            closePopup();
                          });
                        }
                      }}
                      onBlur={() => validateCustomerForm({
                        form: state.customerModal.form,
                        field: 'addressCity'
                      })}
                    />
                  </AdminFormLine>
                </ModalItem>

                <ModalItem>
                  <AdminFormLine marginBottom>
                    <PostcodeLookup
                      // hasChanged={addressPostCodeHasChanged}
                      name={'customer-address-postcode'}
                      width={'35rem'}
                      value={state.customerModal.form.addressPostCode as string || ''}
                      error={customerErrors.addressPostCode}
                      label={'Address postcode'}
                      onChange={(e) => {
                        setState(prevState => ({
                          ...prevState,
                          customerModal: {
                            ...prevState.customerModal,
                            form: {
                              ...prevState.customerModal.form,
                              addressPostCode: e.target.value
                            }
                          }
                        }));
                      }}
                      onSelected={(address: any) => {
                        setState(prevState => ({
                          ...prevState,
                          customerModal: {
                            ...prevState.customerModal,
                            form: {
                              ...prevState.customerModal.form,
                              addressLine1: address.line_1,
                              addressLine2: address.line_2,
                              addressLine3: address.line_3.length > 0 ? address.line_3 : address.line_4.length === 0 ? address.locality : address.line_4,
                              addressCity: address.town_or_city
                            }
                          }
                        }));
                      }}
                      onBlur={() => validateCustomerForm({
                        form: state.customerModal.form,
                        field: 'addressPostCode'
                      })}
                    />
                  </AdminFormLine>
                </ModalItem>
              </ModalContentWrapper>

              <AdminFormLine marginBottom />
              <ModalBorder />
              <AdminFormLine marginBottom />

              <ModalItem>
                <AdminFormLine right>
                  <Button
                    type={'button'}
                    onClick={() => {
                      closePopup();
                    }}
                    style={{ marginRight: '1rem' }}
                  >Cancel</Button>
                  <PrimaryButton
                    type={'submit'}
                    loading={state.customerModal.loading}
                    onClick={() => {
                      onCustomerSubmit(isCreate, () => {
                        if (isEditMode || (!isEditMode && !isCreateMode)) {
                          fetchJob();
                        }

                        closePopup();
                      });
                    }}
                  >{`${submitBtnPrefix}`}</PrimaryButton>
                </AdminFormLine>
              </ModalItem>
            </ModalWrapper>
          );
        }}
      </Popup>
    );
  }, [
    // t,
    isCreateMode,
    isEditMode,
    state.customerModal,
    state.errors,
    state.temp.customer,
    customerErrors,
    customerNameAutoFocus,
    closeCustomerModal,
    onCustomerSubmit,
    validateCustomerForm,
    validateCustomerEmailRemotely,
    fetchJob
  ]);

  // TODO: abstract
  const renderDelayModal = useCallback(() => {
    if (!state.delayModal.show) {
      return null;
    }

    const dropdownOptions = [...new Array(24)].map((_, index: number) => {
      const val: number = (index + 1) * 5;

      return createDropdownOption(val.toString(), val);
    });

    return (
      <Popup
        id={'delay-modal'}
        layered
        convertable
        noPadding
        onClose={closeDelayModal}
      >
        {({ closePopup }) => {
          return (
            <ModalWrapper>
              <ModalItem>
                <AdminFormLine marginTop>
                  <h3 style={{marginBottom: 0}}>{t('delayModalHeader')}</h3>
                </AdminFormLine>
              </ModalItem>

              <AdminFormLine marginBottom />
              <ModalBorder />
              <AdminFormLine marginBottom />

              <ModalContentWrapper>
                <AdminFormLine marginBottom>
                  <Dropdown
                    width={'35rem'}
                    label={t('delayModalHeaderDropdownLabel')}
                    value={state.delayModal.form.delayedBy}
                    options={dropdownOptions}
                    onChange={(option: any) => {
                      const time: number = (option && option.value) || '';

                      const newStartTime = format(addMinutes(new Date(stripZoneFromISOString(state.job.data!.fields.date.iso[0])), time), ISO_FORMAT);
                      const newEndTime = format(addMinutes(new Date(stripZoneFromISOString(state.job.data!.fields.date.iso[1])), time), ISO_FORMAT);

                      setState(prevState => ({
                        ...prevState,
                        delayModal: {
                          ...prevState.delayModal,
                          form: {
                            ...prevState.delayModal.form,
                            delayedBy: time
                          }
                        },
                        form: {
                          ...prevState.form,
                          fields: {
                            ...prevState.form.fields,
                            date: {
                              ...prevState.form.fields.date,
                              iso: [ newStartTime, newEndTime ]
                            }
                          }
                        }
                      }));
                    }}
                  />
                </AdminFormLine>
              </ModalContentWrapper>

              <AdminFormLine marginBottom />
              <ModalBorder />
              <AdminFormLine marginBottom />

              <ModalItem>
                <AdminFormLine right>
                  <Button
                    type={'button'}
                    onClick={() => {
                      closePopup();

                      setState(prevState => ({
                        ...prevState,
                        delayModal: {
                          ...initialState.delayModal,
                        },
                        form: {
                          ...prevState.form,
                          fields: {
                            ...prevState.form.fields,
                            date: {
                              ...prevState.form.fields.date,
                              iso: state.job.data!.fields.date.iso
                            }
                          }
                        }
                      }));
                    }}
                    style={{ marginRight: '1rem' }}
                  >Cancel</Button>
                  <PrimaryButton
                    type={'submit'}
                    disabled={!!!state.delayModal.form.delayedBy}
                    onClick={() => {
                      closePopup();
                    }}
                  >Continue</PrimaryButton>
                </AdminFormLine>
              </ModalItem>
            </ModalWrapper>
          );
        }}
      </Popup>
    );
  }, [
    t,
    state,
    closeDelayModal
  ]);

  const renderChargesModal = useCallback(() => {
    if (!state.chargesModal.show) {
      return null;
    }

    return (
      <Popup
        id={'charges-modal'}
        layered
        convertable
        noPadding
        onClose={closeChargesModal}
      >
        {({ closePopup }) => {
          return (
            <ModalWrapper>
              <ModalItem>
                <AdminFormLine marginTop>
                  <h3 style={{marginBottom: 0}}>{t('chargesModalHeader')}</h3>
                </AdminFormLine>
              </ModalItem>

              <AdminFormLine marginBottom />
              <ModalBorder />
              <AdminFormLine marginBottom />

              <ModalContentWrapper>
                {state.chargesModal.form.map((charge: AdditionalCharge, index: number) => (
                  <AdminFormLine
                    key={index}
                    row
                    marginBottom
                  >
                    <StyledTextInput
                      width={'35rem'}
                      value={charge.summary}
                      label={t('chargesModalSummary')}
                      onChange={(e: any) => {
                        setState(prevState => ({
                          ...prevState,
                          chargesModal: {
                            ...prevState.chargesModal,
                            form: [
                              ...prevState.chargesModal.form.slice(0, index),
                              {
                                ...prevState.chargesModal.form[index],
                                summary: e.target.value
                              },
                              ...prevState.chargesModal.form.slice(index + 1, prevState.chargesModal.form.length),
                            ]
                          }
                        }));
                      }}
                      onKeyUp={(e: any) => {
                        if (e.key === 'Enter') {
                          onChargesSubmit(closePopup);
                        }
                      }}
                    />
                    <TextInput
                      type={FieldType.Currency}
                      value={formatCurrency(charge.price)}
                      label={t('chargesModalPrice')}
                      onChange={(e: any) => {
                        setState(prevState => ({
                          ...prevState,
                          chargesModal: {
                            ...prevState.chargesModal,
                            form: [
                              ...prevState.chargesModal.form.slice(0, index),
                              {
                                ...prevState.chargesModal.form[index],
                                price: Number(getRawCurrencyValue(e.target.value))
                              },
                              ...prevState.chargesModal.form.slice(index + 1, prevState.chargesModal.form.length),
                            ]
                          }
                        }));
                      }}
                      onKeyUp={(e) => {
                        if (e.key === 'Enter') {
                          onChargesSubmit(closePopup);
                        }
                      }}
                    />
                  </AdminFormLine>
                ))}
              </ModalContentWrapper>

              <AdminFormLine marginBottom />
              <ModalBorder />
              <AdminFormLine marginBottom />

              <ModalItem>
                <AdminFormLine spaceBetween>
                  <Button
                    type={'button'}
                    onClick={() => {
                      closePopup();
                    }}
                    style={{ marginRight: '1rem' }}
                  >Cancel</Button>
                  <AdminFormLine>
                    <Button
                      type={'button'}
                      disabled={!(state.chargesModal.form.slice(-1)[0].summary.length > 0 && state.chargesModal.form.slice(-1)[0].price > 0)}
                      icon={
                        isMobile ? (
                          <StyledPlus style={{
                            width: '2rem',
                            height: 'auto'
                          }} />
                        ) : undefined
                      }
                      style={{ marginRight: '1rem' }}
                      onClick={() => {
                        setState(prevState => ({
                          ...prevState,
                          chargesModal: {
                            ...prevState.chargesModal,
                            form: [
                              ...prevState.chargesModal.form,
                              {
                                ...initialState.chargesModal.form[0]
                              }
                            ]
                          }
                        }));
                      }}
                    >{isMobile ? '' : 'Add charge'}</Button>
                    <PrimaryButton
                      type={'submit'}
                      loading={state.jobUpdate.loading}
                      spinnerColor={theme.colors.coreSecondary}
                      icon={
                        isMobile ? (
                          <Tick style={{
                            width: '2rem',
                            height: 'auto',
                            fill: theme.colors.coreSecondary
                          }} />
                        ) : undefined
                      }
                      onClick={() => onChargesSubmit(closePopup)}
                    >{isMobile ? '' :`Submit (${state.chargesModal.form.length})`}</PrimaryButton>
                  </AdminFormLine>
                </AdminFormLine>
              </ModalItem>
            </ModalWrapper>
          );
        }}
      </Popup>
    );
  }, [
    t,
    state,
    isMobile,
    closeChargesModal,
    onChargesSubmit
  ]);

  const getNonRefundedPayments = (payments: Payment[]): Payment[] => {
    return payments.filter(payment => !payment.refunded);
  };

  const renderRefundModal = useCallback(() => {
    if (!state.refundModal.show || !state.payments.data) {
      return null;
    }

    return (
      <Popup
        id={'refund-modal'}
        layered
        convertable
        noPadding
        onClose={closeRefundModal}
      >
        {({ closePopup }) => {
          return (
            <ModalWrapper>
              <ModalItem>
                <AdminFormLine marginTop>
                  <h3 style={{marginBottom: 0}}>{t('refundModalHeader')}</h3>
                </AdminFormLine>
              </ModalItem>

              <AdminFormLine marginBottom />
              <ModalBorder />
              <AdminFormLine marginBottom />

              <ModalContentWrapper>
                <Table
                  checkable
                  namespaceKey={'payments-to-refund'}
                  headerConfig={[
                    {
                      key: 'custom',
                      label: 'No.',
                      type: CellType.Text,
                      format: (value, row, index: number) => index + 1
                    },
                    {
                      key: 'amount',
                      label: 'Amount',
                      type: CellType.Currency,
                      sortable: true,
                      format: (value: number) => formatCurrency(value)
                    },
                    {
                      key: 'paymentId',
                      label: `${getPaymentService(state.client.data && state.client.data.customerPaymentService && state.client.data.customerPaymentService.type)} ID`,
                      type: CellType.Text,
                      format: (value: string, row) => {
                        if (row.source === Source.External) {
                          return row._id;
                        }

                        if (!state.client.data!.customerPaymentService) {
                          return <i>Add customer payment service</i>
                        }

                        return (
                          <StyledLinkExternal
                            inline
                            href={`${state.client.data!.customerPaymentService.paymentsDashboardLink}/${value}`}
                            target={'_blank'}
                          >{value}</StyledLinkExternal>
                        );
                      }
                    },
                    {
                      key: 'date',
                      label: 'Date',
                      type: CellType.Text,
                      format: (value: string) => format(new Date(value), 'HH:mm dd/MM/yyyy')
                    }
                  ]}
                  loading={state.payments.loading}
                  rows={getNonRefundedPayments(state.payments.data! || [])}
                  total={state.payments.total || 0}
                  offset={state.payments.offset || 0}
                  onChecked={(paymentIds: string[]) => {
                    setState(prevState => ({
                      ...prevState,
                      refundModal: {
                        ...prevState.refundModal,
                        form: paymentIds
                      }
                    }));
                  }}
                />
              </ModalContentWrapper>

              <AdminFormLine marginBottom />
              <ModalBorder />
              <AdminFormLine marginBottom />

              <ModalItem>
                <AdminFormLine spaceBetween>
                  <Button
                    type={'button'}
                    onClick={() => {
                      closePopup();
                    }}
                    style={{ marginRight: '1rem' }}
                  >Cancel</Button>
                  <AdminFormLine>
                    <PrimaryButton
                      type={'submit'}
                      loading={state.refundModal.loading}
                      disabled={getNonRefundedPayments(state.payments.data! || []).length === 0 || state.refundModal.form.length === 0}
                      spinnerColor={theme.colors.coreSecondary}
                      onClick={() => {
                        refund((e) => {
                          if (!e) {
                            closePopup();
                          }

                          fetchPayments();
                          fetchJob();
                        });
                      }}
                    >Refund selected</PrimaryButton>
                  </AdminFormLine>
                </AdminFormLine>
              </ModalItem>
            </ModalWrapper>
          );
        }}
      </Popup>
    );
  }, [
    t,
    state,
    closeRefundModal,
    refund,
    fetchPayments,
    fetchJob
  ]);

  const renderPaymentModal = useCallback(() => {
    if (!state.paymentModal.show) {
      return null;
    }

    return (
      <Popup
        id={'make-payment-modal'}
        layered
        convertable
        noPadding
        onClose={closePaymentModal}
      >
        {({ closePopup }) => {
          return (
            <ModalWrapper>
              <ModalItem>
                <AdminFormLine marginTop>
                  <h3 style={{marginBottom: 0}}>{t('makePaymentModalHeader')}</h3>
                </AdminFormLine>
              </ModalItem>

              <AdminFormLine marginBottom />
              <ModalBorder />
              <AdminFormLine marginBottom />

              <ModalContentWrapper>
                <AdminFormLine
                  column
                  marginBottom
                >
                  <AdminFormLine marginBottom>
                    <Text value="Copy the payment link below and send it to your customer to settle any outstanding amounts:" />
                  </AdminFormLine>
                  {renderPaymentLink()}
                </AdminFormLine>

                <AdminFormLine marginBottom />
                <ModalBorder />
                <AdminFormLine marginBottom />

                <AdminFormLine
                  column
                  marginBottom
                >
                  <AdminFormLine marginBottom>
                    <Text value="Alternatively update the job as paid if invoice/payment was settled outside of the system:" />
                  </AdminFormLine>

                  <TextInput
                    type={FieldType.Currency}
                    widthM={'100%'}
                    widthT={'35rem'}
                    label={t('amount')}
                    value={formatCurrency(state.paymentAmount)}
                    error={state.errors.paymentAmount}
                    onChange={(e: any) => {
                      setState(prevState => ({
                        ...prevState,
                        paymentAmount: Number(getRawCurrencyValue(e.target.value)),
                      }));
                    }}
                  />
                </AdminFormLine>

                <AdminFormLine marginBottom>
                  <TextArea
                    widthM={'100%'}
                    widthT={'35rem'}
                    label={t('comment')}
                    value={state.statusComment as string}
                    placeholder="Add a note detailing the reason for this status change"
                    onChange={(e) => {
                      setState(prevState => ({
                        ...prevState,
                        statusComment: e.target.value
                      }));
                    }}
                  />
                </AdminFormLine>
              </ModalContentWrapper>

              <AdminFormLine marginBottom />
              <ModalBorder />
              <AdminFormLine marginBottom />

              <ModalItem>
                <AdminFormLine right>
                  <Button
                    type={'button'}
                    onClick={() => {
                      closePopup();

                      setState(prevState => ({
                        ...prevState,
                        delayModal: {
                          ...initialState.delayModal,
                        },
                        form: {
                          ...prevState.form,
                          fields: {
                            ...prevState.form.fields,
                            date: {
                              ...prevState.form.fields.date,
                              iso: state.job.data!.fields.date.iso
                            }
                          }
                        }
                      }));
                    }}
                    style={{ marginRight: '1rem' }}
                  >Cancel</Button>
                  <PrimaryButton
                    type={'submit'}
                    disabled={state.paymentAmount === 0}
                    loading={state.jobUpdate.loading}
                    onClick={() => {
                      const {
                        _id,
                        reference,
                        created,
                        updated,
                        calculatedPrice,
                        priceOverridden,
                        fulfiller,
                        fulfillerLabel,
                        service,
                        serviceId,
                        clientId: cID,
                        byAdmin,
                        amountDue,
                        qboInvoiceId,
                        paymentReminders,
                        hourReminders,
                        pendingReminders,
                        unAssignmentReminderSent,
                        customerId,
                        customer,
                        noCustomerCommunications,
                        tz,
                        maxDuration,
                        start,
                        end,
                        ...rest
                      } = state.form;

                      updateJob(id!, rest, () => {
                        fetchPayments();
                        closePopup();
                      });
                    }}
                  >Pay</PrimaryButton>
                </AdminFormLine>
              </ModalItem>
            </ModalWrapper>
          );
        }}
      </Popup>
    );
  }, [
    t,
    id,
    state,
    updateJob,
    fetchPayments,
    closePaymentModal,
    renderPaymentLink
  ]);

  const renderFulfillerSettingsModal = useCallback(() => {
    if (!state.fulfillerSettingsModal.show) {
      return null;
    }

    const fulfillerLabelOptions = [
      ...FULFILLER_TYPE_DROPDOWN_OPTIONS,
      ...state.ui.addedFulfillerTypes
    ];

    if (state.client.data) {
      const globalSelectedOption: string = state.client.data?.settings.jobs.defaultFulfillerLabel;
      const optionIndex: number = fulfillerLabelOptions.findIndex(o => o.label === globalSelectedOption);

      if (optionIndex === -1) {
        fulfillerLabelOptions.push(createDropdownOption(globalSelectedOption));
      }
    }

    fulfillerLabelOptions.sort((a, b) => a.label.localeCompare(b.label));

    return (
      <Popup
        id={'fulfiller-settings-modal'}
        layered
        convertable
        noPadding
        onClose={closeFulfillerSettingsModal}
      >
        {({ closePopup }) => {
          return (
            <ModalWrapper>
              <ModalItem>
                <AdminFormLine marginTop>
                  <h3 style={{marginBottom: 0}}>{t('fulfillerSettingsModalHeader')}</h3>
                </AdminFormLine>
              </ModalItem>

              <AdminFormLine marginBottom />
              <ModalBorder />
              <AdminFormLine marginBottom />

              <ModalContentWrapper>
                <AdminFormLine
                  column
                  marginBottom
                >
                  <CreatableDropdown
                    widthM={'100%'}
                    widthT={'35rem'}
                    label={t('fulfillerLabel')}
                    value={state.form.fulfillerLabel}
                    options={fulfillerLabelOptions}
                    onChange={(option: any, actionMeta) => {
                      const value = (option && option.value) || '';

                      if (!value.length) {
                        return;
                      }

                      setState(prevState => {
                        const newState = {
                          ...prevState,
                          form: {
                            ...prevState.form,
                            fulfillerLabel: value
                          }
                        };

                        if (actionMeta.action === 'create-option') {
                          const newOptionExists: boolean = newState.ui.addedFulfillerTypes.findIndex(ft => ft.label === value) !== -1;

                          if (!newOptionExists) {
                            newState.ui.addedFulfillerTypes.push(actionMeta.option as DropdownOption);
                          }
                        }

                        return newState;
                      });
                    }}
                  />
                </AdminFormLine>
              </ModalContentWrapper>

              <AdminFormLine marginBottom />
              <ModalBorder />
              <AdminFormLine marginBottom />

              <ModalItem>
                <AdminFormLine right>
                  <Button
                    type={'button'}
                    onClick={() => {
                      closePopup();
                    }}
                  >Close</Button>
                </AdminFormLine>
              </ModalItem>
            </ModalWrapper>
          );
        }}
      </Popup>
    );
  }, [
    t,
    state.fulfillerSettingsModal.show,
    state.form.fulfillerLabel,
    state.ui.addedFulfillerTypes,
    state.client.data,
    closeFulfillerSettingsModal
  ]);

  const renderDateEntrySettingsModal = useCallback(() => {
    if (!state.dateEntrySettingsModal.show) {
      return null;
    }

    const jobStart: Date = new Date(stripZoneFromISOString(state.form.fields.date.iso[0]));
    const jobEnd: Date = new Date(stripZoneFromISOString(state.form.fields.date.iso[1]));
    const startDateAndTime: string = format(jobStart, 'h:mmaaa do MMM');
    const endDateAndTime: string = format(jobEnd, 'h:mmaaa do MMM');
    const endTime: string = format(jobEnd, 'h:mmaaa');
    const startTime: string = format(jobStart, 'h:mmaaa');
    const superImposedStart: Date = setDate(jobStart, jobEnd.getDate()); // check time with end date to compare and ensure entime is greater than start time to make sure time range wording is applicable
    const endTimeIsGreaterThanStartTime: boolean = jobEnd.getTime() > superImposedStart.getTime();
    const outOfHoursRange: string = endTimeIsGreaterThanStartTime ? `${endTime} - ${startTime} or ` : '';

    const workingDays: any[] = [...Array(jobDurationInDays)];
    const defaultWorkingHours = workingDays.reduce((acc, _, index: number) => {
      const date: Date = addDays(jobStart, index);
      const dateKey = format(date, ISO_FORMAT).split('T')[0];

      let timeString = endTimeIsGreaterThanStartTime ? `${jobStart.getHours()}:${jobStart.getMinutes()}-${jobEnd.getHours()}:${jobEnd.getMinutes()}` : '08:00-16:00';

      if (!endTimeIsGreaterThanStartTime) {
        const isFirstDay: boolean = index === 0;
        const isLastDay: boolean = index === workingDays.length - 1;
        const startHours: string = isFirstDay ? `${jobStart.getHours()}` : isLastDay ? `${jobEnd.getHours()}` : '08';
        const startMinutes: string = isFirstDay ? `${jobStart.getMinutes()}` : isLastDay ? `${jobEnd.getMinutes()}` : '00';
        const endHours: string = isFirstDay ? `${jobStart.getHours()}` : isLastDay ? `${jobEnd.getHours() - 1}` : '16';
        const endMinutes: string = isFirstDay ? `${jobStart.getMinutes()}` : isLastDay ? `${jobEnd.getMinutes()}` : '00';

        timeString = `${startHours}:${startMinutes}-${endHours}:${endMinutes}`;
      }

      acc[dateKey] = timeString;

      return acc;
    }, {});
    const defaultWorkingHoursEntries = Object.entries(defaultWorkingHours);
    const blockOutOfHours = Object.keys(state.form.fields.date.schedule).length === 0;

    return (
      <Popup
        id={'date-entry-settings-modal'}
        layered
        convertable
        noPadding
        onClose={closeDateEntrySettingsModal}
      >
        {({ closePopup }) => {
          return (
            <ModalWrapper>
              <ModalItem>
                <AdminFormLine marginTop>
                  <h3 style={{marginBottom: 0}}>{t('dateEntrySettingsModalHeader')}</h3>
                </AdminFormLine>
              </ModalItem>

              <AdminFormLine marginBottom />
              <ModalBorder />
              <AdminFormLine marginBottom />

              <ModalContentWrapper>
                <AdminFormLine marginBottom>
                  <AdminFormLine column>
                    <Text value={(
                      <SettingDateAndTime marginBottom>
                        <span>Start: <b>{startDateAndTime}&nbsp;&nbsp;</b></span>
                      </SettingDateAndTime>
                    )}/>
                    <Text value={(
                      <SettingDateAndTime>
                        <span>End: <b>{endDateAndTime}&nbsp;</b></span>
                      </SettingDateAndTime>
                    )}/>
                  </AdminFormLine>
                  <AdminFormLine
                    center
                    column
                  >
                    <SettingDuration><b>{`${jobDurationInDays} days`}</b></SettingDuration>
                  </AdminFormLine>
                </AdminFormLine>
                <AdminFormLine
                  column
                  marginBottom
                >
                  <Checkbox
                    smallText
                    widthM={'100%'}
                    widthT={'35rem'}
                    label={`Block out of hours in the calendar (${outOfHoursRange}overnight every day)`}
                    // tooltip={state.jobFields.data.noCustomerCommunications.tooltip}
                    info="Check this option if you want every hour taken up by this job until the end date. Uncheck if you'd like to free the time or choose different workng hours"
                    checked={blockOutOfHours}
                    onChange={(e) => {
                      setState(prevState => ({
                        ...prevState,
                        form: {
                          ...prevState.form,
                          fields: {
                            ...prevState.form.fields,
                            date: {
                              ...prevState.form.fields.date,
                              schedule: e.target.checked ? {} : defaultWorkingHours
                            }
                          }
                        }
                      }));
                    }}
                  />
                </AdminFormLine>
                {!blockOutOfHours && (
                  <>
                    <AdminFormLine>
                      <h4>Work schedule</h4>
                    </AdminFormLine>
                    {defaultWorkingHoursEntries
                      .map((_, index: number) => {
                        const parsedEntryDateString: string = Object.entries(state.form.fields.date.schedule)[index][0];
                        const parsedEntryDateSplit: string[] = parsedEntryDateString.split('-');
                        const parsedEntryDate: string = parsedEntryDateSplit[2];
                        const parsedEntryMonth: string = parsedEntryDateSplit[1];
                        const parsedEntryYear: string = parsedEntryDateSplit[0];

                        const date: Date = setDate(setMonth(setYear(jobStart, Number(parsedEntryYear)), Number(parsedEntryMonth) - 1), Number(parsedEntryDate));
                        const formattedDate = format(date, 'do MMM');
                        const dateKey = format(date, ISO_FORMAT).split('T')[0];
                        const parsedEntryHours: string[] = (state.form.fields.date.schedule[dateKey] || '').split('-');

                        const parsedEntryStartTime: string = parsedEntryHours.length === 2 ? parsedEntryHours[0] : '09:00';
                        const parsedEntryStartHours: number = Number(parsedEntryStartTime.split(':')[0]) || 0;
                        const parsedEntryStartMinutes: number = Number(parsedEntryStartTime.split(':')[1]) || 0;

                        const parsedEntryEndTime: string = parsedEntryHours.length === 2 ? parsedEntryHours[1] : '19:00';
                        const parsedEntryEndHours: number = Number(parsedEntryEndTime.split(':')[0]) || 0;
                        const parsedEntryEndMinutes: number = Number(parsedEntryEndTime.split(':')[1]) || 0;

                        const isFirstDay: boolean = index === 0;
                        const isLastDay: boolean = index === defaultWorkingHoursEntries.length - 1;
                        const firstDayMinEndHours: number = isFirstDay ? parsedEntryStartHours : 0;
                        const lastDayMaxStartHours: number = isLastDay ? parsedEntryEndHours - 1 : 23;

                        return (
                          <AdminFormLine
                            row
                            centerV
                            key={dateKey}
                            style={{ marginBottom: '.75rem' }}
                          >
                            <DateKey>{formattedDate}</DateKey>
                            <DateSplitter>-</DateSplitter>
                            <NumericInput
                              noMinWidth
                              min="0"
                              max={lastDayMaxStartHours}
                              width="4rem"
                              disabled={isFirstDay}
                              value={parsedEntryStartHours}
                              onChange={(e) => {
                                setState(prevState => {
                                  const startHours: string = e.target.value;

                                  return {
                                    ...prevState,
                                    form: {
                                      ...prevState.form,
                                      fields: {
                                        ...prevState.form.fields,
                                        date: {
                                          ...prevState.form.fields.date,
                                          schedule: {
                                            ...prevState.form.fields.date.schedule,
                                            [dateKey]: `${startHours}:${parsedEntryStartMinutes}-${parsedEntryEndTime}`
                                          }
                                        }
                                      }
                                    }
                                  };
                                });
                              }}
                            />
                            <TimeSplitter>:</TimeSplitter>
                            <NumericInput
                              noMinWidth
                              min="0"
                              max="59"
                              width="4rem"
                              disabled={isFirstDay}
                              value={parsedEntryStartMinutes}
                              onChange={(e) => {
                                setState(prevState => {
                                  const startMinutes: string = e.target.value;

                                  return {
                                    ...prevState,
                                    form: {
                                      ...prevState.form,
                                      fields: {
                                        ...prevState.form.fields,
                                        date: {
                                          ...prevState.form.fields.date,
                                          schedule: {
                                            ...prevState.form.fields.date.schedule,
                                            [dateKey]: `${parsedEntryStartHours}:${startMinutes}-${parsedEntryEndTime}`
                                          }
                                        }
                                      }
                                    }
                                  };
                                });
                              }}
                            />
                            <DateSplitter>-</DateSplitter>
                            <NumericInput
                              noMinWidth
                              min={firstDayMinEndHours}
                              max="23"
                              width="4rem"
                              disabled={isLastDay}
                              value={parsedEntryEndHours}
                              onChange={(e) => {
                                setState(prevState => {
                                  const endHours: string = e.target.value;

                                  return {
                                    ...prevState,
                                    form: {
                                      ...prevState.form,
                                      fields: {
                                        ...prevState.form.fields,
                                        date: {
                                          ...prevState.form.fields.date,
                                          schedule: {
                                            ...prevState.form.fields.date.schedule,
                                            [dateKey]: `${parsedEntryStartHours}:${parsedEntryStartMinutes}-${endHours}:${parsedEntryEndMinutes}`
                                          }
                                        }
                                      }
                                    }
                                  };
                                });
                              }}
                            />
                            <TimeSplitter>:</TimeSplitter>
                            <NumericInput
                              noMinWidth
                              min="0"
                              max="59"
                              width="4rem"
                              disabled={isLastDay}
                              value={parsedEntryEndMinutes}
                              onChange={(e) => {
                                setState(prevState => {
                                  const endMinutes: string = e.target.value;

                                  return {
                                    ...prevState,
                                    form: {
                                      ...prevState.form,
                                      fields: {
                                        ...prevState.form.fields,
                                        date: {
                                          ...prevState.form.fields.date,
                                          schedule: {
                                            ...prevState.form.fields.date.schedule,
                                            [dateKey]: `${parsedEntryStartHours}:${parsedEntryStartMinutes}-${parsedEntryEndHours}:${endMinutes}`
                                          }
                                        }
                                      }
                                    }
                                  };
                                });
                              }}
                            />
                          </AdminFormLine>
                        );
                      })
                    }
                  </>
                )}
              </ModalContentWrapper>

              <AdminFormLine marginBottom />
              <ModalBorder />
              <AdminFormLine marginBottom />

              <ModalItem>
                <AdminFormLine right>
                  <Button
                    type={'button'}
                    onClick={() => {
                      closePopup();
                    }}
                  >Close</Button>
                </AdminFormLine>
              </ModalItem>
            </ModalWrapper>
          );
        }}
      </Popup>
    );
  }, [
    t,
    state.form.fields,
    state.dateEntrySettingsModal,
    jobDurationInDays,
    closeDateEntrySettingsModal
  ]);

  const renderMaxDuration = useCallback(() => {
    if ((isCreateMode || isEditMode)
      && !isComplete
      && state.form.serviceId === 'none'
    ) {
      const val: string = state.form.maxDuration || '';
      const valSplit: string[] = val.split('-');
      const numericVal: number | undefined = Number(valSplit[0]) || undefined;
      const dropdownVal: string = valSplit[1] || '';

      let max: number = 60;
      if (dropdownVal.length > 0) {
        switch (dropdownVal) {
          case 'Minutes':
            max = 60;
            break;
          case 'Hours':
            max = 24;
            break;
        };
      }

      return (
        <AdminFormLine
          column
          marginBottom
        >
          <h5
            style={{
              fontSize: '1.2rem',
              marginBottom: '.5rem'
            }}
          >{t('maxDuration')}</h5>
          <AdminFormLine>
            <NumericInput
              value={numericVal}
              label={'Amount'}
              placeholder={'2'}
              min={0}
              max={max}
              error={errors.maxDuration}
              onUpdate={(e, newVal) => {
                setState(prevState => {
                  const currDropdownVal: string = prevState.form.maxDuration ? prevState.form.maxDuration.split('-')[1] || '' : 'Hours';

                  return {
                    ...prevState,
                    form: {
                      ...prevState.form,
                      maxDuration: `${newVal}-${currDropdownVal}`
                    }
                  };
                });
              }}
              onBlur={() => {
                fetchTimeSlots(
                  state.selectedDate.length ?
                    state.selectedDate[0] :
                    isEditMode && state.form.fields.date?.iso?.length ?
                      format(state.form.fields.date.iso[0], ISO_FORMAT) :
                      format(new Date(), ISO_FORMAT)
                );
                validate({
                  form: state.form,
                  field: 'maxDuration'
                });
              }}
            />
            <Dropdown
              label={'Unit of time'}
              options={['Minutes', 'Hours'].map(unit => createDropdownOption(unit))}
              placeholder={'Hours'}
              value={dropdownVal}
              error={errors.maxDuration ? ' ' : undefined}
              onChange={(option: any) => {
                setState(prevState => {
                  const currNumericVal: number = Number((prevState.form.maxDuration || '').split('-')[0]) || 0;

                  const newState = {
                    ...prevState,
                    form: {
                      ...prevState.form,
                      maxDuration: `${currNumericVal}-${(option && option.value) || ''}`
                    }
                  };

                  fetchTimeSlots(
                    newState.selectedDate.length ?
                      newState.selectedDate[0] :
                      isEditMode ?
                        format(state.form.fields.date.iso[0], ISO_FORMAT) :
                        format(new Date(), ISO_FORMAT),
                    newState.form.maxDuration
                  );

                  return newState;
                });
              }}
              onBlur={() => {
                validate({
                  form: state.form,
                  field: 'maxDuration'
                });
              }}
              style={{
                marginLeft: '1rem'
              }}
            />
          </AdminFormLine>
        </AdminFormLine>
      );
    }

    return null;
  }, [
    t,
    isComplete,
    isCreateMode,
    isEditMode,
    state.selectedDate,
    state.form,
    errors.maxDuration,
    fetchTimeSlots,
    validate
  ]);

  const renderDatePicker = useCallback(() => {
    if (!state.form.serviceId || !state.client.data) {
      return null;
    }

    const root = (isCreateMode || isEditMode ? state.form['fields'] : (state.job.data as any)['fields']) || '';

    // console.log('-----------------------------------------root', root);

    let timeSlotOptions: string[] = (state.timeSlots.data?.slots ?? []).map((slotItems: any) => slotItems.slot);
    // let timeSlotUnselected: boolean = false;

    // // Check if selected time slot has changed
    // if (root.date && root.date.iso && !isCreateMode) {
    //   const selectedTimeSlot = root.date.iso.join(' - ');

    //   if (selectedTimeSlot && !timeSlotOptions.includes(selectedTimeSlot)) {
    //     // console.log('-----------------timeSlotUnselected', selectedTimeSlot);
    //     timeSlotUnselected = true;
    //   }
    // }

    // Re-add job time slot to time slot options (if selected slot is out of range of slots etc)
    if (state.job.data?.fields?.date?.iso?.length
      && state.job.data?.fields?.date?.entryMethod === DateEntryMethod.Duration
      && !isCreateMode
    ) {
      const selectedTimeSlot = state.job.data.fields.date.iso.join(' - ');

      if (selectedTimeSlot && !timeSlotOptions.includes(selectedTimeSlot)) {
        // console.log('-----------------adding job slot to selection', selectedTimeSlot);
        timeSlotOptions = [
          selectedTimeSlot,
          ...timeSlotOptions
        ];
      }
    }

    // If Delayed, add new slot to dropdown options
    if (isEditMode) {
      if (root.date?.iso?.length) {
        const delayedSlotTime = root.date.iso.join(' - ');

        if (delayedSlotTime && !timeSlotOptions.includes(delayedSlotTime)) {
          // console.log('-----------------adding delayed slot time', delayedSlotTime);
          timeSlotOptions = [
            delayedSlotTime,
            ...timeSlotOptions
          ];
        }
      }
    }

    // console.log('-----------------------------------------root.date.iso', root.date && root.date.iso);
    // console.log('-----------------------------------------timeSlotOptions', timeSlotOptions, state.timeSlots.data);

    const jobDate: string = state.job.data ? state.job.data.fields.date.iso[0] : format(new Date(), ISO_FORMAT);
    const newJobDate: string = (state.form.fields && state.form.fields.date && state.form.fields.date.iso && state.form.fields.date.iso[0]) || '';
    const isJobDay: boolean = format(new Date(), ISO_FORMAT).split('T')[0] === jobDate.split('T')[0];
    const jobIsSameDay: boolean = jobDate.split('T')[0] === newJobDate.split('T')[0];
    const delayTimeInMinutes = jobIsSameDay
      && newJobDate.length !== 0
      && jobDate ? differenceInMinutes(
        new Date(stripZoneFromISOString(newJobDate)),
        new Date(stripZoneFromISOString(jobDate))
      ) : 0;
    const delayTimeString: string = delayTimeInMinutes > 0 ? formatDuration(
      intervalToDuration({
        start: new Date(stripZoneFromISOString(jobDate)),
        end: new Date(stripZoneFromISOString(newJobDate))
      })
    ) : '';
    const tooltipStatus: string = jobIsSameDay ? `Delayed (by ${delayTimeString})` : 'Deferred';

    const jobDateChanged: boolean = !!(state.form.fields.date && state.job.data!) && !!newJobDate.length && newJobDate !== jobDate;
    const showDateTooltip: boolean = jobDateChanged && isJobDay && !jobIsSameDay;
    // const showTimeTooltip: boolean = jobDateChanged && isJobDay && jobIsSameDay && !timeSlotUnselected;
    const showTimeTooltip: boolean = jobDateChanged && isJobDay && jobIsSameDay && delayTimeInMinutes > 0;
    const tooltipText: string = jobIsSameDay && delayTimeInMinutes < 0 ? `Changing this will bring the job forward by ${delayTimeString}. The customer will be notified` : `Changing this will update the booking to status ${tooltipStatus}. The customer will be notified`;

    const value = (!objectIsEmpty(root) && !objectIsEmpty(root.date) ? root.date.iso : undefined);

    // console.log('---------value', value);

    // console.log('-----tooltips', jobDateChanged, showDateTooltip, showTimeTooltip, jobDate, newJobDate);

    // not working well with DateTimeFields
    // const minInDays: number = selectedService ? selectedService.earliestBookingInDays : 0;
    // const openToDate: string = minInDays ? moment().add(minInDays, 'days').toISOString() : moment().hour(12).add(1, 'day').toISOString();

    // console.log('------openToDate', root.date.iso, openToDate);
    const startValue = state.form.start || '';
    const endValue = state.form.end || '';
    const isGenericJob = state.form.serviceId === 'none';
    const hasWorkSchedule: boolean = !!Object.keys(state.form.fields?.date?.schedule ?? {}).length;


    // TODO: do changes (hasChanged) for StartEnd entry method
    // TODO: ensure validation for both entryMethods make sense in all states of the form

    return (
      <>
        {(isCreateMode || isEditMode) && !isComplete && (
          isGenericJob && (
            <AdminFormLine marginBottom>
              <Dropdown
                widthM={'100%'}
                widthT={'35rem'}
                isClearable={true}
                label={'Date entry method'}
                value={state.form.fields?.date?.entryMethod}
                placeholder="Enter job date by"
                // error={errors[key]}
                options={[
                  createDropdownOption('Duration', DateEntryMethod.Duration),
                  createDropdownOption('Start and end date', DateEntryMethod.StartEnd)
                ]}
                endAdornment={state.form.fields?.date?.entryMethod === DateEntryMethod.StartEnd ? (
                  <IconButton
                    type="button"
                    marginLeft
                    hoverEffect
                    disabled={jobDurationInDays < 2}
                    onClick={() => {
                      openDateEntrySettingsModal();
                    }}
                  >
                    <Settings style={{ width: '2.1rem' }} />
                  </IconButton>
                ) : undefined}
                // tooltip={fieldItem.tooltip}
                onChange={(option: any) => {
                  let defaultDateStart = setMinutes(new Date(), 0);
                  defaultDateStart = setSeconds(defaultDateStart, 0);
                  defaultDateStart = setMilliseconds(defaultDateStart, 0);
                  let defaultDateEnd = addHours(defaultDateStart, 2);

                  if (state.job.data?.fields?.date?.iso?.length === 2) {
                    defaultDateStart = new Date(stripZoneFromISOString(state.job.data!.fields.date.iso[0]));
                    defaultDateEnd = new Date(stripZoneFromISOString(state.job.data!.fields.date.iso[1]));
                  }

                  setState(prevState => ({
                    ...prevState,
                    form: {
                      ...prevState.form,
                      fields: {
                        ...prevState.form.fields,
                        date: {
                          ...prevState.form.fields.date,
                          entryMethod: (option && option.value) || null,
                          ...(option?.value === DateEntryMethod.StartEnd && {
                            iso: [
                              format(defaultDateStart, ISO_FORMAT),
                              format(defaultDateEnd, ISO_FORMAT)
                            ]
                          }),
                          ...(option?.value === DateEntryMethod.Duration && {
                            iso: null,
                            schedule: {}
                          })
                        }
                      },
                      ...(option?.value === DateEntryMethod.StartEnd && {
                        start: defaultDateStart,
                        end: defaultDateEnd
                      }),
                      ...(option?.value === DateEntryMethod.Duration && {
                        maxDuration: null,
                        start: null,
                        end: null
                      })
                    }
                  }));
                }}
                // onBlur={() => validate({
                //   form: state.form,
                //   field: key
                // })}
              />
            </AdminFormLine>
          )
        )}
        {state.form.fields?.date?.entryMethod === DateEntryMethod.Duration && renderMaxDuration()}
        <AdminFormLine marginBottom>
          {(isCreateMode || isEditMode) && !isComplete ? (
            !isGenericJob || (state.form.fields?.date?.entryMethod === DateEntryMethod.Duration && state.form.maxDuration) ? (
              <DateTimeFields
                hasDateChanged={isEditMode && jobDateChanged && !jobIsSameDay}
                hasTimeChanged={isEditMode && jobDateChanged}
                loading={state.unavailableDates.loading}
                value={value}
                widthM={'100%'}
                widthT={'35rem'}
                slots={{
                  options: timeSlotOptions,
                  onFetchTimeSlots: fetchTimeSlots
                }}
                unavailableDates={state.unavailableDates.data}
                withMinMaxDate={{
                  minInDays: (selectedService && selectedService.earliestBookingInDays) || undefined,
                  maxInMonths: (selectedService && selectedService.latestBookingInMonths) || undefined
                }}
                dateTooltip={jobDateChanged && showDateTooltip ? tooltipText : undefined}
                timeTooltip={jobDateChanged && showTimeTooltip ? tooltipText : undefined}
                error={errors['fields.date.iso']}
                onCalendarOpen={() => {
                  const dateToFetch = value ? value[0] : format(new Date(), ISO_FORMAT);

                  fetchUnavailableDates(dateToFetch)
                }}
                onDateFocus={() => {
                  if (!state.client.data!.isOnboarded) {
                    addToast({
                      type: 'info',
                      content: (
                        <p>Date and time slot availability is based on fulfillers that are available in your workforce.<br/><br/>If no dates and time slots are available then ensure that you've:<br/><br/>1. Added Fulfillers to your workforce if there are none.<br/><br/>2. Checked that at least one Fulfiller is not revoked and<br/><br/>3. Double checked that their calendars are not all booked up at the same time.</p>
                      )
                    });
                  }
                }}
                onChange={(datetime: Date | string[], meta) => {
                  // console.log('---------onChange', datetime);

                  setState(prevState => ({
                    ...prevState,
                    timeSlots: {
                      ...prevState.timeSlots,
                      ...(meta && meta.timeSlotIndex! >= 0 && {
                        selectedTimeSlotIndex: meta.timeSlotIndex!
                      })
                    },
                    form: {
                      ...prevState.form,
                      fields: {
                        ...prevState.form.fields,
                        date: {
                          ...prevState.form.fields['date'],
                          schedule: initialState.form.fields.date.schedule,
                          'iso': datetime
                        }
                      }
                    },
                    selectedDate: datetime as string[]
                  }));
                }}
                onMonthChange={(date: Date) => {
                  const now = setHours(new Date(), 12);
                  const mDate = setHours(date, 12);

                  if (isSameMonth(mDate, now)) {
                    fetchUnavailableDates(format(now, ISO_FORMAT));
                  } else {
                    fetchUnavailableDates(format(setHours(startOfMonth(mDate), 12), ISO_FORMAT));
                  }
                }}
                onBlur={() => validate({
                  form: state.form,
                  field: 'fields.date.iso'
                })}
              />
            ) : isGenericJob && state.form.fields?.date?.entryMethod === DateEntryMethod.StartEnd ? (
              <AdminFormLine
                column
                widthM={'100%'}
                widthT={'35rem'}
              >
                <>
                  <h4>
                    <span>Start</span>
                    {/*hasChanged && (
                      <ModifiedCircle leftMargin />
                    )*/}
                  </h4>
                  <DateTimeFields
                    width={'100%'}
                    value={startValue || undefined}
                    error={state.errors.start || ''}
                    dateOnly={state.form.isAllDay}
                    timeDisabled={state.form.timeDisabled}
                    onChange={(datetime: Date | string[]) => {
                      setState(prevState => {
                        const start = datetime as Date;
                        const end = datetime as Date;

                        const newState = {
                          ...prevState,
                          form: {
                            ...prevState.form,
                            start,
                            end: addHours(end, 2),
                            fields: {
                              ...prevState.form.fields,
                              date: {
                                ...prevState.form.fields.date,
                                schedule: initialState.form.fields.date.schedule,
                                iso: [
                                  format(start, ISO_FORMAT),
                                  format(addHours(end, 2), ISO_FORMAT)
                                ]
                              }
                            }
                          }
                        }

                        fetchAvailability(newState.form.fields.date.iso);

                        return newState;
                      });
                    }}
                  />
                </>
                <>
                  <h4>
                    <span>End</span>
                    {/*hasChanged && (
                      <ModifiedCircle leftMargin />
                    )*/}
                  </h4>
                  <DateTimeFields
                    width={'100%'}
                    value={endValue || undefined}
                    error={state.errors.end || ''}
                    dateOnly={state.form.isAllDay}
                    timeDisabled={state.form.timeDisabled}
                    onChange={(datetime: Date | string[]) => {
                      setState(prevState => {
                        const end = datetime as Date;
                        const start = prevState.form.fields.date.iso[0];

                        const newState = {
                          ...prevState,
                          form: {
                            ...prevState.form,
                            end,
                            fields: {
                              ...prevState.form.fields,
                              date: {
                                ...prevState.form.fields.date,
                                schedule: initialState.form.fields.date.schedule,
                                iso: [
                                  start,
                                  format(end, ISO_FORMAT)
                                ]
                              }
                            }
                          }
                        };

                        fetchAvailability(newState.form.fields.date.iso);

                        return newState;
                      });
                    }}
                  />
                </>
                {hasWorkSchedule && renderWorkSchedule(state.form.fields.date.schedule)}
              </AdminFormLine>
            ) : null
          ) : (
            <AdminFormLine column>
              <Text
                value={
                  <>
                    <span
                      style={{
                        ...(isJobOverdue(state.job.data!) && {
                          textDecoration: `.2rem underline ${theme.colors.accentTertiary}`
                        })
                      }}
                    >
                      {format(new Date(stripZoneFromISOString(state.job.data!.fields.date.iso[0])), 'h:mmaaa dd/MM/yy')}
                    </span>
                    <span>&nbsp;|&nbsp;</span>
                    <span>{getJobDuration(state.job.data)}</span>
                  </>
                }
                tooltip={isJobOverdue(state.job.data!) ? 'This job is overdue. You may want to reschedule or refund the customer.' : undefined}
                startAdornment={(
                  <Clock style={{
                    width: '1.75rem',
                    height: 'auto',
                    marginRight: '.5rem',
                    flexShrink: 0
                  }} />
                )}
              />
              {hasWorkSchedule && renderWorkSchedule(state.job.data!.fields.date.schedule, true)}
            </AdminFormLine>
          )}
        </AdminFormLine>
      </>
    );
  }, [
    isCreateMode,
    isEditMode,
    isComplete,
    state.form,
    state.errors.start,
    state.errors.end,
    state.job.data,
    state.client.data,
    state.timeSlots.data,
    state.unavailableDates.loading,
    state.unavailableDates.data,
    errors,
    selectedService,
    jobDurationInDays,
    fetchTimeSlots,
    fetchUnavailableDates,
    fetchAvailability,
    renderMaxDuration,
    addToast,
    validate,
    openDateEntrySettingsModal,
    renderWorkSchedule
  ]);

  const renderStaffNotes = useCallback(() => {
    if (isCreateMode && !state.form.fields.date?.iso?.length) {
      return null;
    }

    const value = (isCreateMode || isEditMode ? state.form['staffNotes'] : (state.job.data as any)['staffNotes']) || '';

    if (!isCreateMode && !isEditMode && !value) {
      return null;
    }

    if ((isCreateMode || isEditMode) && !isComplete) {
      return (
        <TextArea
          widthM={'100%'}
          widthT={'35rem'}
          value={value}
          label={t('staffNotes')}
          error={errors['staffNotes']}
          info={state.jobFields.data['staffNotes'].info}
          placeholder={state.jobFields.data['staffNotes'].hint}
          tooltip={state.jobFields.data['staffNotes'].tooltip}
          onChange={(e) => onChangeFormField(e, 'staffNotes')}
          onBlur={() => validate({
            form: state.form,
            field: 'staffNotes'
          })}
        />
      );
    }

    return (
      <Text
        label={t('staffNotes')}
        value={value}
      />
    );
  }, [
    t,
    errors,
    isCreateMode,
    isEditMode,
    isComplete,
    state.jobFields,
    state.form,
    state.job,
    validate,
    onChangeFormField
  ]);

  const renderNonEditablePrice = useCallback(() => {
    let price: string | JSX.Element = formatCurrency(state.job.data!.price);
    const additionalCharges = state.job.data!.additionalCharges;
    let charges: number = 0;

    if (additionalCharges) {
      charges = additionalCharges.reduce((acc, charge) => {
        acc += charge.price;

        return acc;
      }, 0);

      price = (
        <PriceWrapper>
          <span>{formatCurrency(state.job.data!.price + charges)}</span>
          <Text
            noWidth
            value={<b>&nbsp;{`(incl. ${formatCurrency(charges)}ac)`}</b>}
            tooltip={'Additional charges have been appliied'}
            style={{ fontSize: '1.4rem' }}
          />
        </PriceWrapper>
      );
    }

    const amountDue = state.job.data!.amountDue;

    return (
      <AdminFormLine row width="100%">
        <ImportantPriceText
          label={'Price'}
          value={(
            <>
              <div>{price}</div>
              {state.job.data?.vat ? (
                <div style={{
                  fontSize: '1.4rem',
                  marginTop: '.5rem'
                }}>VAT - 20% ({formatCurrency(getPrice(state.job.data.price + charges, false))} excl. VAT)</div>
              ) : null}
            </>
          )}
        />
        <ImportantPriceText
          label={'Amount due'}
          value={formatCurrency(amountDue || 0)}
        />
      </AdminFormLine>
    );
  }, [state.job.data]);

  const renderFulfillerPayInfo = useCallback(() => {
    let payPercentageElem = null;
    let payElem = null;

    if (state.form.serviceId === 'none' && !concealJobPrice(userData.user!)) {
      payPercentageElem = (
        <NumericInput
          min={0}
          max={100}
          step={0.01}
          widthM={'50%'}
          widthT={'8.3rem'}
          value={state.form.fulfillerPayPercentage || 0}
          label={'Ful\' pay %'}
          style={{
            flexShrink: 0,
            marginRight: '1rem'
          }}
          disabled={state.form.status === StatusType.COMPLETE}
          hasChanged={isEditMode && state.form.fulfillerPayPercentage !== state.job.data?.fulfillerPayPercentage}
          onChange={(e: any) => {
            setState(prevState => {
              const price = prevState.form.vat ? getPrice(prevState.form.price, false) : prevState.form.price;
              const newPercentage = Number(Number(e.target.value).toFixed(2));
              const fulfillerPay = getFulfillerPay(
                price,
                newPercentage,
                // prevState.form.vat
                false,
                prevState.form.additionalCharges
              );

              const newState = {
                ...prevState,
                form: {
                  ...prevState.form,
                  fulfillerPayPercentage: newPercentage,
                  fulfillerPay
                }
              };

              if (fulfillerPay > prevState.form.price) {
                newState.errors.fulfillerPay = 'Fulfiller pay cannot exceed job price';
              } else {
                delete newState.errors.fulfillerPay;
              }

              return newState;
            });
          }}
          onBlur={() => validate({
            form: state.form,
            field: 'fulfillerPay'
          })}
        />
      );
    }

    if (!isFulfiller(userData.user!)) {
      payElem = (
        <FulfillerPay
          widthM={'50%'}
          widthT={'8.3rem'}
          type={FieldType.Currency}
          value={formatCurrency(state.form.fulfillerPay || 0)}
          label={`Fulfiller pay ${selectedService ? '(' + selectedService.fulfillerPayPercentage + '%)' : ''}`}
          warning={state.warnings.fulfillerPay}
          error={errors.fulfillerPay || state.errors.fulfillerPay}
          disabled={state.form.status === StatusType.COMPLETE}
          hasChanged={isEditMode && state.form.fulfillerPay !== state.job.data?.fulfillerPay}
          onChange={(e: any) => {
            setState(prevState => {
              const newFulfillerPay = Number(getRawCurrencyValue(e.target.value));
              const price = prevState.form.vat ? getPrice(prevState.form.price, false) : prevState.form.price;
              const fulfillerPayPercentage = getFulfillerPayPercentage(newFulfillerPay, price);

              const newState = {
                ...prevState,
                form: {
                  ...prevState.form,
                  fulfillerPay: newFulfillerPay,
                  ...(prevState.form.serviceId === 'none' && {
                    fulfillerPayPercentage
                  })
                }
              };

              if (newFulfillerPay > Math.round(price)) {
                if (newState.form.vat) {
                  newState.errors.fulfillerPay = 'Fulfiller pay is cutting into VAT allocation for job';
                } else {
                  newState.errors.fulfillerPay = 'Fulfiller pay cannot exceed job price';
                }
              } else {
                delete newState.errors.fulfillerPay;
              }

              return newState;
            });
          }}
          onBlur={() => validate({
            form: state.form,
            field: 'fulfillerPay'
          })}
        />
      );
    }

    if (!payPercentageElem && !payElem) {
      return null;
    }

    return (
      <AdminFormLine row>
        {payPercentageElem}
        {payElem}
      </AdminFormLine>
    );
  }, [
    isEditMode,
    userData.user,
    state.form,
    errors.fulfillerPay,
    state.errors.fulfillerPay,
    state.warnings.fulfillerPay,
    state.job.data,
    selectedService,
    validate
  ]);

  const renderTopRightCard = useCallback(() => {
    let availableFulfillers: Fulfiller[] = [];
    let unAvailableFulfillers: Fulfiller[] = [ ...(state.fulfillers.data || []) ];
    let groupedOptions: Array<GroupedOption<DropdownOption>> = [];

    if (state.form.fields.date?.iso?.length === 2) {
      const timeSlotItem = state.timeSlots.data && state.timeSlots.data.slots[state.timeSlots.selectedTimeSlotIndex];
      const startAndEndDateEntryMethod: boolean = state.form.fields?.date?.entryMethod === DateEntryMethod.StartEnd;
      const fulfillerIdRepo: string[] = !!timeSlotItem ? timeSlotItem.fulfillerIds : startAndEndDateEntryMethod ? (state.availability.data?.availableFulfillerIds || []) : [];
      const timeSlotFulfillers: Fulfiller[] = state.timeSlots.data?.fulfillers ?? [];

      if (timeSlotItem) {
        availableFulfillers = timeSlotItem.fulfillerIds
          .map((fulfillerId: string) => {
            return timeSlotFulfillers.find(f => f._id === fulfillerId);
          })
          .filter((f: Fulfiller | undefined): f is Fulfiller => !!f);
      } else if (startAndEndDateEntryMethod) {
        availableFulfillers = (state.availability.data?.availableFulfillerIds || [])
          .map((fulfillerId: string) => {
            return (state.fulfillers.data || []).find(f => f._id === fulfillerId);
          })
          .filter((f: Fulfiller | undefined): f is Fulfiller => !!f);
      }

      if (isEditMode && state.job.data?.fulfiller) {
        const alreadyAdded: boolean = !!availableFulfillers.find(f => f._id === state.job.data!.fulfiller!._id);

        if (!alreadyAdded) {
          availableFulfillers.unshift(state.job.data.fulfiller);
        }
      }

      unAvailableFulfillers = unAvailableFulfillers.filter((fulfiller: Fulfiller) => {
        return !fulfillerIdRepo.includes(fulfiller._id);
      });

      groupedOptions = [
        {
          label: 'Available',
          options: availableFulfillers.map((fulfiller: Fulfiller) => {
            return createDropdownOption(`${fulfiller.firstName} ${fulfiller.lastName}`, fulfiller._id);
          })
        },
        {
          label: 'Unavailable',
          options: unAvailableFulfillers.map((fulfiller: Fulfiller) => {
            return createDropdownOption(`${fulfiller.firstName} ${fulfiller.lastName}`, fulfiller._id);
          })
        }
      ];
    }

    const isMatched: boolean = !!state.job.data?.fulfillerId?.length;
    const fulfillerExists: boolean = !!(state.job.data && state.job.data.fulfiller);
    const showReadOnlyVersion = !isCreateMode && (!isEditMode || (isEditMode && (selectedService || state.form.serviceId === 'none')));

    const readOnlyVersion = showReadOnlyVersion ? (
      <AdminFormLine column>
        {!concealJobPrice(userData.user!) && (
          <AdminFormLine marginBottom>
            {renderNonEditablePrice()}
          </AdminFormLine>
        )}
        <FulfillerInfo marginBottom>
          <Text
            startAdornment={(
              <Worker style={{
                width: '1.75rem',
                height: 'auto',
                marginRight: '.5rem',
                flexShrink: 0
              }} />
            )}
            value={state.job.data!.fulfiller ? `${state.job.data!.fulfiller.firstName} ${state.job.data!.fulfiller.lastName}` : isMatched && !fulfillerExists ? 'User not found' : 'Unmatched'}
          />
          <AdminFormLine row>
            <Text
              label="Fulfiller pay"
              value={
                <StatNumber
                  small
                  caption
                >
                  <span>{formatCurrency(state.form.fulfillerPay || 0)}</span>
                  {!concealJobPrice(userData.user!) && (
                    <span>{state.form.fulfillerPayPercentage || 0}%</span>
                  )}
                </StatNumber>
              }
            />
          </AdminFormLine>
        </FulfillerInfo>
        {renderPaymentLink()}
      </AdminFormLine>
    ) : null;

    if (isComplete) {
      return readOnlyVersion;
    }

    return (isCreateMode || isEditMode) ? (
      <>
        {(selectedService || state.form.serviceId === 'none') && (
          <Card
            sticky
            noMediaQ
            boxShadow
            style={{ padding: '0 2rem 0' }}
          >
            <AdminFormLine
              right
              topPadding
              spaceBetween
            >
              {state.form.serviceId && state.form.serviceId !== 'none' && priceEditable(state.form.status) && (
                <FormField
                  label={'Generated price'}
                  tooltip="Click to use this price"
                >
                  <GeneratedPrice
                    ref={generatedLabelRef}
                    onClick={() => {
                      setState(prevState => ({
                        ...prevState,
                        form: {
                          ...prevState.form,
                          price: prevState.form.calculatedPrice
                        }
                      }));
                    }}
                  >{formatCurrency(0)}</GeneratedPrice>
                </FormField>
              )}
              {!concealJobPrice(userData.user!) && (
                priceEditable(state.form.status) ? (
                  <>
                    <TextInput
                      type={FieldType.Currency}
                      value={formatCurrency(state.form.price || '')}
                      label={'Price'}
                      info={state.form.vat && state.form.price ? `${formatCurrency(getPrice(state.form.price, false))} excl. VAT` : undefined}
                      error={errors.price}
                      hasChanged={isEditMode && state.form.price !== state.job.data!.price}
                      onChange={(e: any) => {
                        const newPrice = Number(getRawCurrencyValue(e.target.value));

                        setState(prevState => {
                          const fulfillerPayPercentage = prevState.form.serviceId === 'none' ? prevState.form.fulfillerPayPercentage : selectedService?.fulfillerPayPercentage ?? 0;

                          return {
                            ...prevState,
                            form: {
                              ...prevState.form,
                              price: Number(getRawCurrencyValue(e.target.value)),
                              priceOverridden: true,
                              fulfillerPay: getFulfillerPay(
                                prevState.form.vat ? getPrice(newPrice, false) : newPrice,
                                fulfillerPayPercentage,
                                // prevState.form.vat
                                false,
                                prevState.form.additionalCharges
                              )
                            }
                          };
                        });
                      }}
                      onBlur={() => validate({
                        form: state.form,
                        field: 'price'
                      })}
                    />
                    <VatWrapper>
                      <Switch
                        value={state.form.vat}
                        label={t('vat') + ` (${VAT_RATE}%)`}
                        // tooltip={state.jobFields.data.vat.tooltip}
                        onChange={(updatedState: boolean) => {
                          setState(prevState => {
                            const fulfillerPayPercentage = prevState.form.serviceId === 'none' ? prevState.form.fulfillerPayPercentage : selectedService?.fulfillerPayPercentage ?? 0;

                            const price = getPrice(
                              prevState.form.price,
                              updatedState
                            );
                            const fulfillerPay = getFulfillerPay(
                              price,
                              fulfillerPayPercentage,
                              updatedState,
                              prevState.form.additionalCharges
                              // false
                            );

                            const newState = {
                              ...prevState,
                              form: {
                                ...prevState.form,
                                vat: updatedState,
                                price,
                                fulfillerPay,
                                ...(selectedService && !prevState.form.priceOverridden && {
                                  calculatedPrice: price
                                })
                              }
                            };

                            return newState;
                          });
                        }}
                      />
                    </VatWrapper>
                  </>
                ) : (
                  renderNonEditablePrice()
                )
              )}
            </AdminFormLine>
            {isCreateMode && state.form.serviceId && (
              <FulfillerInfo marginTop>
                <FulfillerDropdown
                  label={state.form.fulfillerLabel}
                  value={state.form.fulfillerId}
                  tooltip={'Leave blank for auto assignment'}
                  placeholder={'Assign fulfiller'}
                  warning={unAvailableFulfillers.map(f => f._id).includes(state.form.fulfillerId) ? 'The completion of this job may be affected as this fulfiller is not free' : undefined}
                  options={groupedOptions}
                  formatGroupLabel={(data) => (
                    <div>
                      <span>{data.label}</span>
                      <span style={{ marginLeft: '.5rem' }}>({data.options.length})</span>
                    </div>
                  )}
                  endAdornment={(
                    <IconButton
                      type="button"
                      marginLeft
                      hoverEffect
                      onClick={() => {
                        openFulfillerSettingsModal();
                      }}
                    >
                      <Settings style={{ width: '2.1rem' }} />
                    </IconButton>
                  )}
                  onChange={(option: any) => onChangeSelect('fulfillerId', option)}
                  onMenuOpen={fetchFulfillers}
                />
                {renderFulfillerPayInfo()}
              </FulfillerInfo>
            )}
            {isEditMode && (
              <FulfillerInfo marginTop>
                <FulfillerDropdown
                  hasChanged={state.form.fulfillerId !== state.job.data!.fulfillerId}
                  label={state.form.fulfillerLabel}
                  value={state.form.fulfillerId}
                  isDisabled={state.form.serviceId === 'none' && !isComplete ? false : isFulfiller(userData.user!)}
                  tooltip={state.form.serviceId !== 'none' && state.form.fulfillerId !== state.job.data!.fulfillerId ? 'Changing this will affect the auto job assignment process and will prevent future auto assignments to this job' : undefined}
                  placeholder={'Assign fulfiller'}
                  options={groupedOptions}
                  formatGroupLabel={(data) => (
                    <div>
                      <span>{data.label}</span>
                      <span style={{ marginLeft: '.5rem' }}>({data.options.length})</span>
                    </div>
                  )}
                  onChange={(option: any) => onChangeSelect('fulfillerId', option)}
                  onMenuOpen={fetchFulfillers}
                />
                {renderFulfillerPayInfo()}
              </FulfillerInfo>
            )}
            <AdminFormLine topPadding />
            {renderPaymentLink()}
            <AdminFormLine topPadding />
          </Card>
        )}
      </>
    ) : (
      readOnlyVersion
    );
  }, [
    t,
    isCreateMode,
    isEditMode,
    userData.user,
    state.job.data,
    isComplete,
    errors.price,
    state.form,
    state.timeSlots.data,
    state.timeSlots.selectedTimeSlotIndex,
    state.fulfillers.data,
    state.availability.data,
    selectedService,
    onChangeSelect,
    validate,
    renderPaymentLink,
    renderFulfillerPayInfo,
    renderNonEditablePrice,
    fetchFulfillers,
    openFulfillerSettingsModal
  ]);

  const renderWarnings = useCallback(() => {
    const client = state.client.data;
    const job = state.job.data;
    const jobNewlyCreated: boolean = !!state.jobCreate.data;
    const hasCustomer: boolean = !!job?.customer;
    const noCustomerCommunications: boolean = !!job?.noCustomerCommunications;

    const warnings = [];

    if (
      !isCreateMode
        && !isEditMode
        && job
        && client
        && invoiceNotGenerated(client, job)
        && !jobNewlyCreated
        && hasCustomer
        && !noCustomerCommunications
        && isCustomerInvoiceReady(job.customer)
    ) {
      warnings.push(
        <AdminFormLine key="invoice-synch" marginBottom>
          <WarningCard>
            <WarningText>
              <span><Warning /></span>
              <span>The invoice associated with this booking has not been successfully synched with {client.settings.accounting.platform}.</span>
            </WarningText>
            <WarningAction>
              <Button
                type={'button'}
                loading={state.jobSync.loading}
                onClick={() => {
                  syncJob();
                }}
              >Retry</Button>
            </WarningAction>
          </WarningCard>
        </AdminFormLine>
      );
    }

    if (
      !isCreateMode
        && !isEditMode
        && job
        && hasCustomer
        && !noCustomerCommunications
        && !isCustomerInvoiceReady(job.customer)
    ) {
      warnings.push(
        <AdminFormLine key="customer-incomplete" marginBottom>
          <WarningCard>
            <WarningText>
              <span><Warning /></span>
              <span>Customer information is incomplete. As such no invoices or emails will be sent.</span>
            </WarningText>
            <WarningAction>
              <Button
                type={'button'}
                onClick={() => {
                  setState(prevState => ({
                    ...prevState,
                    temp: {
                      ...prevState.temp,
                      customer: job.customer
                    }
                  }));

                  openCustomerModal();
                }}
              >Update customer</Button>
            </WarningAction>
          </WarningCard>
        </AdminFormLine>
      );
    }

    return warnings;
  }, [
    isCreateMode,
    isEditMode,
    state.client.data,
    state.job.data,
    state.jobSync.loading,
    state.jobCreate.data,
    syncJob,
    openCustomerModal
  ]);

  const renderBasicInfo = useCallback((formKeys: string[]) => {
    return (
      <AdminFormLine column marginBottom>
        <AdminFormLine
          row
          centerV
          marginBottom
        >
          <h3 style={{marginBottom: 0}}>Basic info</h3>
        </AdminFormLine>
        <FormSection
          noBorder
          cols={2}
        >
          <fieldset>
            {formKeys
              .filter(k => k !== 'fulfillerId'
                && k !== 'clientId'
                && k !== 'qboInvoiceId'
                && k !== 'vat'
                && k !== 'noCustomerCommunications'
                && k !== 'tz'
                && k !== 'staffNotes'
                && k !== 'fulfillerLabel'
              )
              .map((key: string, index: number) => {
                const fieldItem = state.jobFields.data[key];
                const value = (isCreateMode || isEditMode ? state.form[key] : (state.job.data as any)[key]) || '';

                const disableReferenceField: boolean = isEditMode && key === 'reference';

                let elem: React.ReactNode;

                switch (fieldItem.type) {
                  case 'boolean':
                    if (!isCreateMode && !isEditMode) {
                      elem = null;
                    } else {
                      elem = (
                        <Switch
                          falseText="Disabled"
                          trueText="Enabled"
                          value={value}
                          label={key}
                          tooltip={fieldItem.tooltip}
                          onChange={(newState: boolean) => onChangeFormFieldBoolean(newState, key)}
                        />
                      );
                    }
                    break;
                  case 'string':
                    if ((!isCreateMode && !isEditMode) || isEditMode || disableReferenceField || isComplete) {
                      if (key === 'reference') {
                        elem = (
                          <Text
                            startAdornment={(
                              <QuickReference style={{
                                width: '1.75rem',
                                height: 'auto',
                                marginRight: '.5rem',
                                flexShrink: 0
                              }} />
                            )}
                            value={state.job.data?.reference}
                          />
                        );
                      }
                      else if (key === 'serviceId') {
                        const jobService: Service | undefined = state.job.data?.service;

                        elem = (
                          <Text
                            label={t('service')}
                            value={state.job.data?.serviceId === 'none' ? 'Generic' : jobService?.isDeleted ? <s>{jobService.name}</s> : jobService?.name || ''}
                          />
                        );
                      }
                      else if (key === 'customerId') {
                        const formattedCustomer = getCustomerName(state.job.data?.customer);
                        const noCustomerCommunications = state.job.data?.noCustomerCommunications;

                        elem = (
                          <Text
                            label="Customer"
                            value={formattedCustomer}
                            tooltip={noCustomerCommunications ? 'This customer will not receive an invoice or any email updates relating to this job from GoBook\'em except if a payment is made via the payment link.' : undefined}
                            endAdornment={
                              <>
                                {noCustomerCommunications ? (
                                  <NotificationsOff
                                    style={{
                                      width: '1.6rem',
                                      height: '1.6rem',
                                      marginLeft: '1rem'
                                    }}
                                  />
                                ) : undefined}
                                {isEditMode && canUpdateCustomer(clientId!, userData.user!) && (
                                  <IconButton
                                    type="button"
                                    marginLeft
                                    inheritHeight
                                    hoverEffect
                                    style={{
                                      padding: '.2rem',
                                      position: 'absolute',
                                      right: '-4rem'
                                    }}
                                    onClick={() => {
                                      setState(prevState => ({
                                        ...prevState,
                                        temp: {
                                          ...prevState.temp,
                                          customer: prevState.job.data?.customer
                                        }
                                      }));

                                      openCustomerModal();
                                    }}
                                  >
                                    <Edit />
                                  </IconButton>
                                )}
                              </>
                            }
                          />
                        );
                      } else {
                        elem = (
                          <Text
                            label={t(key)}
                            value={value}
                          />
                        );
                      }
                    } else {
                      if (key === 'customerId') {
                        // tslint:disable-next-line
                        elem = (
                          <AdminFormLine
                            column
                            widthM={'100%'}
                            widthT={'35rem'}
                          >
                            <AsyncCreatableDropdown
                              key={state.customerModal.data?._id ?? 'customer-async-creatable'}
                              widthM={'100%'}
                              widthT={'35rem'}
                              isClearable={true}
                              label={'Customer'}
                              value={state.temp.customerIdOption}
                              error={errors[key]}
                              loadOptions={loadCustomerOptions}
                              tooltip={fieldItem.tooltip}
                              endAdornment={!!state.form.customerId ? (
                                <IconButton
                                  type="button"
                                  marginLeft
                                  inheritHeight
                                  hoverEffect
                                  style={{ padding: '.2rem' }}
                                  onClick={() => {
                                    openCustomerModal();
                                  }}
                                >
                                  <Edit />
                                </IconButton>
                              ) : undefined}
                              onChange={(option: any, actionMeta) => {
                                setState(prevState => ({
                                  ...prevState,
                                  form: {
                                    ...prevState.form,
                                    customerId: option?.value || null,
                                  },
                                  temp: {
                                    ...prevState.temp,
                                    customerIdOption: option,
                                    ...((actionMeta.action === 'create-option' || actionMeta.action === 'clear') && {
                                      customer: null
                                    }),
                                    ...(actionMeta.action === 'select-option' && {
                                      customer: option
                                    })
                                  }
                                }));

                                if (actionMeta.action === 'create-option') {
                                  if (document.activeElement) {
                                    (document.activeElement as any).blur();
                                  }

                                  openCustomerModal(option);
                                }
                              }}
                              onBlur={() => validate({
                                form: state.form,
                                field: key
                              })}
                            />
                            <Checkbox
                              smallText
                              label={t('noCustomerCommunications')}
                              tooltip={state.jobFields.data.noCustomerCommunications.tooltip}
                              info={state.form.noCustomerCommunications ? state.jobFields.data.noCustomerCommunications.info : ''}
                              checked={state.form.noCustomerCommunications || false}
                              style={{ marginTop: '.25rem' }}
                              onChange={(e) => onChangeFormFieldBoolean(e.target.checked, 'noCustomerCommunications')}
                            />
                          </AdminFormLine>
                        );
                      }
                      else if (key === 'serviceId') {
                        elem = (
                          <Dropdown
                            widthM={'100%'}
                            widthT={'35rem'}
                            isClearable={true}
                            label={'Service'}
                            value={state.form[key]}
                            error={errors[key]}
                            options={[
                              createDropdownOption('None', 'none'),
                              ...((state.services.data ?? [])
                                .filter(service => service.isEnabled)
                                .map((service: Service) => {
                                  return createDropdownOption(service.name, service._id);
                                })
                              )
                            ]}
                            tooltip={fieldItem.tooltip}
                            onChange={(option: any) => onChangeSelect(key, option)}
                            onBlur={() => validate({
                              form: state.form,
                              field: key
                            })}
                          />
                        );
                      } else {
                        elem = (
                          <TextInput
                            hasChanged={isEditMode && state.form[key] !== (state.job.data! as any)[key]}
                            widthM={'100%'}
                            widthT={'35rem'}
                            value={value}
                            label={t(key)}
                            error={errors[key]}
                            tooltip={fieldItem.tooltip}
                            onChange={(e) => onChangeFormField(e, key)}
                            onBlur={() => validate({
                              form: state.form,
                              field: key
                            })}
                          />
                        );
                      }
                    }
                    break;
                  case 'long-string':
                    elem = (
                      <TextArea
                        widthM={'100%'}
                        widthT={'35rem'}
                        value={value}
                        label={t(key)}
                        error={errors[key]}
                        info={fieldItem.info}
                        placeholder={fieldItem.hint}
                        tooltip={fieldItem.tooltip}
                        onChange={(e) => onChangeFormField(e, key)}
                        onBlur={() => validate({
                          form: state.form,
                          field: key
                        })}
                      />
                    );
                    break;
                }

                if (!elem) {
                  return null;
                }

                return (
                  <AdminFormLine key={index} marginBottom>
                    {elem}
                  </AdminFormLine>
                );
              })
            }
            {renderDatePicker()}
            {renderStaffNotes()}
          </fieldset>
          <fieldset>
            {renderTopRightCard()}
          </fieldset>
        </FormSection>
      </AdminFormLine>
    );
  }, [
    t,
    isCreateMode,
    isEditMode,
    isComplete,
    clientId,
    errors,
    state.form,
    state.temp,
    state.job.data,
    state.jobFields.data,
    state.services.data,
    state.customerModal.data,
    userData.user,
    loadCustomerOptions,
    onChangeSelect,
    onChangeFormField,
    onChangeFormFieldBoolean,
    openCustomerModal,
    validate,
    renderDatePicker,
    renderStaffNotes,
    renderTopRightCard
  ]);

  const renderForm = useCallback(() => {
    if (state.job.loading) {
      return (
        <Spinner
          color={theme.textColor}
          size={'M'}
        />
      );
    }

    if (!state.jobFields.data) {
      return null;
    }

    if (!state.services.data) {
      return null;
    }

    if (!state.client.data) {
      return null;
    }

    if (!isCreateMode && objectIsEmpty(state.job.data)) {
      return null;
    }

    if (updateToken) {
      return 'Update token present';
    }

    const formKeys: string[] = Object.keys(state.jobFields.data);

    const statusValue = Array.isArray(state.form.status) ? state.form.status.slice(-1)[0].type : state.form.status || '';
    const statusDatabaseValue = state.job.data?.status.slice(-1)[0].type || '';
    const hasChanged: boolean = isEditMode && statusValue !== statusDatabaseValue;
    const statusHasChanged: boolean = isEditMode && (statusValue !== statusDatabaseValue || state.addAnotherPayment)
    const isPaymentStatus: boolean = [StatusType.PAID, StatusType.CHARGES_PAID].includes(statusValue);
    const showAddPaymentButton: boolean = isPaymentStatus && !statusHasChanged;

    return (
      <>
        {state.job.data && (
          <>
            <StatusWrapper>
              <StatusHistory
                statuses={filterBookingChanges(state.job.data.status, userData.user)}
                clientSettings={state.client.data.settings}
              />
            </StatusWrapper>
            {canUpdateJobStatus(state.job.data, userData.user, isEditMode) && (
              <AdminFormLine
                marginTop
                column
              >
                <AdminFormLine>
                  <Dropdown
                    hasChanged={statusHasChanged}
                    widthM={'100%'}
                    widthT={'35rem'}
                    label={'Status'}
                    value={statusValue}
                    endAdornment={showAddPaymentButton ? (
                      <IconButton
                        type="button"
                        marginLeft
                        inheritHeight
                        onClick={() => {
                          setState(prevState => ({
                            ...prevState,
                            addAnotherPayment: true
                          }));
                        }}
                      >
                        <StyledPlus />
                      </IconButton>
                    ) : statusHasChanged ? (
                      <AdminFormLine>
                        {isPaymentStatus && (
                          <TextInput
                            type={FieldType.Currency}
                            value={formatCurrency(state.paymentAmount)}
                            error={state.errors.paymentAmount}
                            formFieldStyles={{ marginLeft: '1rem' }}
                            onChange={(e: any) => {
                              setState(prevState => ({
                                ...prevState,
                                paymentAmount: Number(getRawCurrencyValue(e.target.value)),
                              }));
                            }}
                          />
                        )}
                        <IconButton
                          type="button"
                          marginLeft
                          inheritHeight
                          onClick={() => {
                            setState(prevState => ({
                              ...prevState,
                              statusComment: prevState.statusComment?.length! >= 0 ? null : ''
                            }));
                          }}
                        >
                          <Notes />
                        </IconButton>
                      </AdminFormLine>
                    ) : undefined}
                    options={statusOptions(state.job.data.status.slice(-1)[0])}
                    onChange={(option: any) => onChangeSelect('status', option)}
                  />
                </AdminFormLine>
                {(hasChanged || statusHasChanged) && state.statusComment?.length! >= 0 && (
                  <AdminFormLine marginTop>
                    <TextArea
                      widthM={'100%'}
                      widthT={'35rem'}
                      value={state.statusComment as string}
                      placeholder="Add a note detailing the reason for this status change"
                      onChange={(e) => {
                        setState(prevState => ({
                          ...prevState,
                          statusComment: e.target.value
                        }));
                      }}
                    />
                  </AdminFormLine>
                )}
              </AdminFormLine>
            )}
          </>
        )}
        <CreateFormWrapper
          noOverflow
          onSubmit={onFormSubmit}
        >
          <FormSection
            cols={1}
            noBorder={!isCreateMode && !isEditMode}
          >
            <div>
              {renderWarnings()}
              {renderBasicInfo(formKeys)}
              {renderStepFields()}
              {renderAdditionalCharges()}
              {renderPayments()}
            </div>
          </FormSection>
          {(isCreateMode || isEditMode) && (
            <AdminFormLine
              topPadding
              spaceBetween
            >
              <Button
                type={'button'}
                onClick={onCancel}
              >Cancel</Button>
              <AdminFormLine>
                {(canCreateJob(clientId!, userData.user!) || canUpdateJob(clientId!, userData.user!, state.job.data!)) && (
                  <PrimaryButton
                    type={'submit'}
                    loading={state.jobCreate.loading || state.jobUpdate.loading}
                    disabled={isSaveButtonDisabled}
                    spinnerColor={theme.colors.coreSecondary}
                  >{getPrimaryButtonText()}</PrimaryButton>
                )}
              </AdminFormLine>
            </AdminFormLine>
          )}
        </CreateFormWrapper>
      </>
    );
  }, [
    state,
    isCreateMode,
    isEditMode,
    userData,
    clientId,
    isSaveButtonDisabled,
    updateToken,
    onChangeSelect,
    onFormSubmit,
    onCancel,
    renderBasicInfo,
    renderWarnings,
    renderStepFields,
    renderAdditionalCharges,
    renderPayments,
    getPrimaryButtonText,
    statusOptions
  ]);

  const removeMessageListener = useCallback(() => {
    if (iframeRef.current) {
      iframeRef.current!.contentWindow!.removeEventListener('message', onIframeMessage);
    }
  }, [onIframeMessage]);

  const [throttleIframeMessage] = useDebouncedCallback((frameData: any) => {
    if (iframeRef.current) {
      iframeRef.current!.contentWindow!.postMessage(JSON.stringify(frameData), window.location.origin);
    }
  }, 50);

  useEffect(() => {
    if (generatedLabelRef.current) {
      const currentPrice = Number(getRawCurrencyValue(generatedLabelRef.current.innerHTML || '')) || 0;

      animateValue(
        currentPrice,
        state.form.calculatedPrice,
        1250,
        (value: number) => {
          if (generatedLabelRef.current) {
            generatedLabelRef.current!.innerHTML = formatCurrency(value);
          }
        },
      );
    }
  }, [state.form.calculatedPrice]);

  useEffect(() => {
    if (!state.jobFields.data && !state.jobFields.error) {
      fetchJobFields();
    }

    if (!isCreateMode) {
      if (!state.job.data && !state.job.error) {
        fetchJob();
      }
    }

    if (!state.services.data && !state.services.error) {
      fetchServices();
    }

    if (!state.client.data && !state.client.error) {
      fetchClient();
    }

    if (!selectedService && state.form.serviceId !== prevServiceId) {
      if (!jobToCopy) {
        if (state.form.serviceId === 'none'
          && !state.defaultServiceSteps.data
          && !state.defaultServiceSteps.error
        ) {
          if (isCreateMode) {
            fetchDefaultServiceSteps();
          }
        }
      }

      if (!state.serviceFields.data && !state.serviceFields.error) {
        fetchServiceFields();
      }
    }
  }, [
    userData,
    isCreateMode,
    isEditMode,
    state.jobFields.data,
    state.jobFields.error,
    state.customers.data,
    state.customers.error,
    state.job.data,
    state.job.error,
    state.services.data,
    state.services.error,
    state.client.data,
    state.client.error,
    state.defaultServiceSteps.data,
    state.defaultServiceSteps.error,
    state.unavailableDates.data,
    state.unavailableDates.error,
    state.serviceFields.data,
    state.serviceFields.error,
    selectedService,
    prevServiceId,
    prevMaxDuration,
    state.form.serviceId,
    state.form.maxDuration,
    jobToCopy,
    fetchServiceFields,
    fetchUnavailableDates,
    fetchJobFields,
    fetchJob,
    fetchServices,
    fetchClient,
    fetchDefaultServiceSteps
  ]);

  useEffect(() => {
    fetchPayments();
  // eslint-disable-next-line
  }, [location.key]);

  useEffect(() => {
    if (isEditMode) {
      if (selectedService && !state.fulfillers.data && !state.fulfillers.error && state.job.data
        && (state.job.data.status.slice(-1)[0].type === StatusType.PAID || state.job.data.status.slice(-1)[0].type === StatusType.PENDING)) {
          fetchFulfillers();
      }
    }
  }, [
    isEditMode,
    selectedService,
    state.fulfillers.data,
    state.fulfillers.error,
    state.job.data,
    fetchFulfillers
  ]);

  // Set prevServiceId
  useEffect(() => {
    if (prevServiceId !== state.form.serviceId) {
      setPrevServiceId(state.form.serviceId);
    }
  }, [
    prevServiceId,
    state.form.serviceId
  ]);

  // Reset price, date and fulfiller fields if a different service is selected in create mode
  useEffect(() => {
    if (!jobToCopy && isCreateMode && prevServiceId !== state.form.serviceId) {
      setState(prevState => {
        const newState = {
          ...prevState,
          form: {
            ...prevState.form,
            price: 0,
            calculatedPrice: 0,
            priceOverridden: false,
            fields: {
              date: { ...initialState.form.fields.date }
            },
            fulfillerId: null,
            maxDuration: null,
            ...(state.form.serviceId === 'none' && {
              steps: [
                {
                  ...defaultGenericStep
                },
                ...(prevState.defaultServiceSteps.data || [])
              ],
              fulfillerPay: 0,
              fulfillerPayPercentage: 0
            })
          },
          builder: initialState.builder,
          steps: initialState.steps
        };

        if (selectedService) {
          newState.form.fulfillerLabel = selectedService.fulfillerLabel;

          const serviceSelectedOption: string = selectedService.fulfillerLabel;
          const optionIndex: number = prevState.ui.addedFulfillerTypes.findIndex(o => o.label === serviceSelectedOption);

          if (optionIndex === -1) {
            prevState.ui.addedFulfillerTypes.push(createDropdownOption(serviceSelectedOption));
          }
        } else if (prevState.client.data) {
          newState.form.fulfillerLabel = prevState.client.data.settings.jobs.defaultFulfillerLabel;
        }

        if (!!state.form.serviceId && state.form.serviceId !== 'none') {
          onCalculateJob(newState);
        }

        if (state.form.serviceId && state.form.serviceId !== 'none') {
          fetchTimeSlots(format(new Date(), ISO_FORMAT));
        }

        return newState;
      });
    }
    else if (isEditMode) {
      if (state.form.fields?.date?.iso?.length
        && !state.timeSlots.loading
        && !state.timeSlots.data
        && !state.timeSlots.error
      ) {
        if (state.form.fields?.date?.entryMethod === DateEntryMethod.Duration) {
          const jobDate: Date = new Date(stripZoneFromISOString(state.form.fields?.date?.iso[0]));
          fetchTimeSlots(format(jobDate, ISO_FORMAT));
        }
      }

      if (!!state.form.serviceId
        && state.form.serviceId !== 'none'
        && state.form.calculatedPrice === 0
        && priceEditable(state.form.status)
      ) {
        onCalculateJob(state);
      }
    }
  }, [
    isCreateMode,
    isEditMode,
    jobToCopy,
    selectedService,
    state,
    prevServiceId,
    fetchTimeSlots,
    onCalculateJob
  ]);

  // Set VAT according to Service or Client settings
  useEffect(() => {
    if (!jobToCopy && isCreateMode && prevServiceId !== state.form.serviceId) {
      setState(prevState => {
        let vat = prevState.client.data?.settings.accounting.defaultVat ?? false;

        if (selectedService) {
          vat = selectedService?.vat ?? false;
        }

        const newState = {
          ...prevState,
          form: {
            ...prevState.form,
            vat
          }
        };

        return newState;
      });
    }
  }, [
    isCreateMode,
    prevServiceId,
    selectedService,
    state.form.serviceId,
    jobToCopy
  ]);

  // Reset date and fulfiller fields if duration changes in create mode
  useEffect(() => {
    if (isCreateMode && prevMaxDuration !== state.form.maxDuration) {
      setPrevMaxDuration(state.form.maxDuration);
      setState(prevState => ({
        ...prevState,
        form: {
          ...prevState.form,
          fields: {
            ...prevState.form.fields,
            date: { ...initialState.form.fields.date }
          },
          fulfillerId: null
        }
      }));
    }
  }, [
    isCreateMode,
    state.form.maxDuration,
    prevMaxDuration
  ]);

  // TODO: to reset the form pass in Actions.RESET_FORM
  useEffect(() => {
    const frameData = {
      action: FrameActions.UPDATE_CHILD,
      to: 'child',
      payload: {
        service: {
          data: {
            ...selectedService!,
            steps,
            ...(isCreateMode && {
              _id: 'newService',
              clientId
            })
          },
          // groupedFields,
          // TODO: use initialState from state.ts
          builder: {
            context: 'service',
            selectedStepIndex: state.builder.selectedStepIndex,
            selectedFieldIndex: state.builder.selectedFieldIndex,
            selectedFields: [],
            isSelectable: true,
            isEditable: isMobile,
            isSortable: true,
            isDeletable: true,
            hideBackground: isMobile,
            hideFooter: isMobile
          }
        }
      }
    };

    if (iframeRef.current) {
      iframeRef.current!.contentWindow!.postMessage(JSON.stringify(frameData), window.location.origin);
    }
    // console.log('--------in update');
  }, [
    steps,
    selectedService,
    isCreateMode,
    isMobile,
    state,
    clientId
  ]);

  // Copy to new
  useEffect(() => {
    if (jobToCopy) {
      setState(prevState => ({
        ...prevState,
        form: {
          ...prevState.form,
          ...jobToCopy
        },
        ...(jobToCopy.customer && {
          temp: {
            ...prevState.temp,
            customerIdOption: createDropdownOption(getCustomerName(jobToCopy.customer), jobToCopy.customer._id),
            customer: jobToCopy.customer
          }
        })
      }));
    }

    return () => {
      if (jobToCopy) {
        dispatch({ type: Actions.RESET_JOB_TO_COPY });
      }
    };
  }, [
    isCreateMode,
    jobToCopy,
    dispatch
  ]);

  // Reset global job to copy state if service ID changes in jobToCopy mode
  useEffect(() => {
    if (jobToCopy && isCreateMode && prevServiceId !== state.form.serviceId && state.form.serviceId === jobToCopy.serviceId) {
      dispatch({ type: Actions.RESET_JOB_TO_COPY });
    }
  }, [
    isCreateMode,
    prevServiceId,
    state.form.serviceId,
    jobToCopy,
    dispatch
  ]);

  // Show warning toast that 3rd party accounting platform isn't integrated properly
  useEffect(() => {
    if (!(state.client.data && isCreateMode)) {
      return;
    }

    const [status, platform] = accountingPlatformSelectedAndNotAuthorized(state.client.data);

    if (status && !accoutingPlatformWarningShown) {
      addToast({
        type: 'warning',
        content: `GoBook'em has not properly connected your account to ${platform}. As such invoices raised from bookings will not be synched with ${platform}. Please try to connect or report an issue.`
      });

      accoutingPlatformWarningShown = true;
    }
  }, [
    isCreateMode,
    state.client.data,
    addToast
  ]);

  useEffect(() => {
    return () => {
      removeMessageListener();
    };
  // eslint-disable-next-line
  }, []);

  const showDelayButton: boolean = !isCreateMode
    && isSameDay(new Date(), new Date(stripZoneFromISOString(state.job.data?.fields?.date?.iso[0])))
    && state.job.data?.status.slice(-1)[0].type !== StatusType.COMPLETE;

  // console.log('-----------state', state);

  return (
    <Wrapper>
      <PageHeader
        title={
          <>
            <span>{isCreateMode ? 'Create job' : isEditMode ? 'Edit job' : 'Job'}</span>
            {!isCreateMode && !isEditMode && (
              <IconButton
                hoverEffect
                onClick={() => {
                  copyText(window.location.href)
                    .then(() => {
                      addToast({
                        type: 'success',
                        content: 'Job link copied'
                      });
                    })
                    .catch(() => {
                      addToast({
                        type: 'error',
                        content: 'Error copying job link. Please copy manually.'
                      });
                    });
                }}
                style={{ marginLeft: '1rem' }}
              >
                <StyledCopyIcon />
              </IconButton>
            )}
          </>
        }
        rightContent={(isMobile && !isMobileXL) && !isCreateMode ? (
          <IconButton
            hoverEffect
            onClick={(e: any) => {
              setState(prevState => ({
                ...prevState,
                showActionsMenu: !prevState.showActionsMenu
              }));
            }}
          >
            <StyledMenuDots />
            {state.showActionsMenu && (
              <Popup
                id="job-actions-menu"
                left
                bottom
                convertable
                onClose={() => {
                  setState(prevState => ({
                    ...prevState,
                    showActionsMenu: false
                  }));
                }}
              >
                {({ closePopup }) => (
                  <MenuWrapper>
                    {!isCreateMode && (
                      <MenuItem
                        onClick={() => {
                          closePopup();

                          onCopyToNew();
                        }}
                      >Copy to new</MenuItem>
                    )}
                    {showDelayButton && (
                      <MenuItem
                        onClick={() => {
                          closePopup();

                          setState(prevState => ({
                            ...prevState,
                            delayModal: {
                              ...prevState.delayModal,
                              show: true
                            }
                          }));
                        }}
                      >Delay</MenuItem>
                    )}
                    {canRefund(
                      state.job.data,
                      isCreateMode
                    ) && canHandlePayments(
                      state.client.data,
                      userData.user
                    ) && (
                      <MenuItem
                        disabled={refundButtonDisabled}
                        onClick={() => {
                          closePopup();

                          onRefund();
                        }}
                      >Refund</MenuItem>
                    )}
                    {canAddAdditionalCharges && (
                      <MenuItem
                        data-title="Add additional charges"
                        onClick={() => {
                          closePopup();

                          onAddCharges();
                        }}
                      >Add charges</MenuItem>
                    )}
                    {!isCreateMode && state.job.data && canHandlePayments(
                      state.client.data,
                      userData.user
                    ) && (
                      <MenuItem
                        onClick={() => {
                          closePopup();

                          onMakePayment();
                        }}
                      >Make payment</MenuItem>
                    )}
                    {!isCreateMode && state.job.data && state.job.data.status.slice(-1)[0].type !== StatusType.COMPLETE && (
                      <MenuItem
                        onClick={() => {
                          closePopup();

                          onMarkJobComplete();
                        }}
                      >Mark job complete</MenuItem>
                    )}
                    {!isCreateMode && state.job.data && (
                      <MenuItem
                        disabled={isEditMode}
                        onClick={() => {
                          if (isEditMode) {
                            return;
                          }

                          closePopup();

                          onEdit();
                        }}
                      >Edit</MenuItem>
                    )}
                  </MenuWrapper>
                )}
              </Popup>
            )}
          </IconButton>
        ) : (
          <>
            {!isCreateMode && (
              <Button
                style={{marginBottom: 0}}
                icon={
                  <FileCopy style={{
                    ...(isMobile ? {
                      width: '1.5rem',
                      height: 'auto'
                    } : {
                      width: '1.95rem',
                      height: 'auto'
                    })
                  }} />
                }
                onClick={onCopyToNew}
              >{isMobile ? '' : 'Copy to new'}</Button>
            )}
            {showDelayButton && (
              <Button
                style={{marginBottom: 0}}
                icon={
                  <StyledClock style={{
                    ...(isMobile && {
                      width: '2rem',
                      height: 'auto'
                    })
                  }} />
                }
                onClick={() => {
                  setState(prevState => ({
                    ...prevState,
                    delayModal: {
                      ...prevState.delayModal,
                      show: true
                    }
                  }));
                }}
              >{isMobile ? '' : 'Delay'}</Button>
            )}
            {canRefund(
              state.job.data,
              isCreateMode
            ) && canHandlePayments(
              state.client.data,
              userData.user
            ) && (
              <Button
                style={{marginBottom: 0}}
                icon={
                  <StyledRefund style={{
                    ...(isMobile && {
                      width: '1.5rem',
                      height: 'auto'
                    })
                  }} />
                }
                disabled={refundButtonDisabled}
                onClick={onRefund}
              >{isMobile ? '' : 'Refund'}</Button>
            )}
            {canAddAdditionalCharges && (
              <Button
                type={'button'}
                icon={
                  <Payments style={{
                    ...(isMobile ? {
                      width: '1.75rem',
                      height: 'auto'
                    } : {
                      width: '1.95rem',
                      height: 'auto'
                    })
                  }} />
                }
                style={{marginBottom: 0}}
                data-title="Add additional charges"
                onClick={onAddCharges}
              >{isMobile ? '' : 'Add charges'}</Button>
            )}
            {!isCreateMode && state.job.data && canHandlePayments(
              state.client.data,
              userData.user
            ) && (
              <Button
                style={{marginBottom: 0}}
                icon={
                  <PaymentIcon style={{
                    ...(isMobile && {
                      width: '2rem',
                      height: 'auto'
                    })
                  }} />
                }
                onClick={onMakePayment}
              >{isMobile ? '' : 'Make payment'}</Button>
            )}
            {!isCreateMode && state.job.data && state.job.data.status.slice(-1)[0].type !== StatusType.COMPLETE && (
              <Button
                style={{marginBottom: 0}}
                icon={
                  <Tick style={{
                    width: '1.75rem',
                    height: 'auto'
                  }} />
                }
                onClick={onMarkJobComplete}
              >{isMobile ? '' : 'Complete'}</Button>
            )}
            {!isCreateMode && state.job.data && (
              <Button
                style={{marginBottom: 0}}
                icon={
                  <Edit style={{
                    ...(isMobile && {
                      width: '2rem',
                      height: 'auto'
                    })
                  }} />
                }
                disabled={isEditMode}
                onClick={onEdit}
              >{isMobile ? '' : 'Edit'}</Button>
            )}
          </>
        )}
      />
      {isCreateMode && (
        <AdminFormLine marginBottom>Create a new job based on a service (Service Job). Or create a quick Generic Job. Please note Generic Jobs will not have fulfillers automatically assigned or have any of the other service and pricing rule features.</AdminFormLine>
      )}
      {renderForm()}
      {renderCustomerModal()}
      {renderDelayModal()}
      {renderChargesModal()}
      {renderRefundModal()}
      {renderPaymentModal()}
      {renderFulfillerSettingsModal()}
      {renderDateEntrySettingsModal()}
      {renderModal()}
      <StepNameModal
        key={`${state.steps.createModal.show.toString()}-${state.steps.renameModal.show.toString()}`}
        show={state.steps.createModal.show || state.steps.renameModal.show}
        stepNames={(state.form.steps || []).map((step: Step) => step.label)}
        name={state.steps.renameModal.name || undefined}
        index={state.steps.renameModal.index >= 0 ? state.steps.renameModal.index : -1}
        onClose={closeNameModal}
        onSubmit={upsertStep}
      />
    </Wrapper>
  );
};

export default memo(Job);

