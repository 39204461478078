export enum Period {
  NextYear = 'next-year',
  NextQuarter = 'next-quarter',
  NextMonth = 'next-month',
  NextWeek = 'next-week',
  Tomorrow = 'tomorrow',
  Today = 'today',
  Yesterday = 'yesterday',
  ThisWeek = 'this-week',
  LastWeek = 'last-week',
  ThisMonth = 'this-month',
  LastMonth = 'last-month',
  ThisQuarter = 'this-quarter',
  LastQuarter = 'last-quarter',
  ThisYear = 'this-year',
  LastYear = 'last-year'
}

export interface PlatformInsights {
  totalJobsPC: {
    previous: number;
    current: number;
    value: string;
  };
  totalPaymentsPC: {
    previous: number;
    current: number;
    value: string;
  };
  totalGrossEarningsPC: {
    previous: number;
    current: number;
    value: string;
  };
  refundedJobsPC: {
    previous: number;
    current: number;
    value: string;
  };
  totalClientsPC: {
    previous: number;
    current: number;
    value: string;
  };
  genericServiceShare: {
    generic: number;
    genericFormatted: string;
    service: number;
    serviceFormatted: string;
  };
}

export interface PlatformOverview {
  totalClients: number;
  totalOnboardedClients: number;
  totalJobs: number;
  totalServices: number;
  totalEnabledServices: number;
  totalUsers: number;
  totalRevokedUsers: number;
  totalFulfillers: number;
  totalRevokedFulfillers: number;
  totalPricingRules: number;
}

export interface AuthorMeta {
  by: string;
  at: string;
}

export interface BaseEntity {
  _id: string;
  created: AuthorMeta;
  updated: AuthorMeta;
}

export enum AppBannerType {
  Info = 'info',
  Critical = 'critical'
}

export interface AppBanner {
  type: AppBannerType;
  content: string;
}

