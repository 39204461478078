import styled from 'styled-components';

import {
  BaseButtonStyles,
  BaseContentWrapperStyles
} from './mixins.styles';

export const StyledButton = styled.button<{ $width?: string; $loading?: boolean, iconOnly?: boolean; noPadding?: boolean; }>`
  ${({ $width, $loading, iconOnly, noPadding }) => BaseButtonStyles($width, $loading, iconOnly, noPadding)}
`

export const ContentWrapper = styled.div`
  ${BaseContentWrapperStyles()}
`

