import styled from 'styled-components';

import { CircleWrapperProps } from './Avatar';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  user-select: none;
`;

export const CircleWrapper = styled.div<CircleWrapperProps>`
  display: flex;
  width: 4rem;
  height: 4rem;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: .1rem solid ${({ theme }) => theme.textColor};
  background-color: ${({ theme }) => theme.colors.neutralSecondary};
  flex-shrink: 0;

  ${({ colour }) => colour.font && `
    color: ${colour.font};
  `}
  ${({ colour }) => colour.background && `
    background-color: ${colour.background};
  `}
`;

export const NameWrapper = styled.div`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: .75rem;
  line-height: 4rem;
`;

