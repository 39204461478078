import React, { FC, useCallback, useState, useEffect, memo } from 'react';
import { useParams, useNavigate } from "react-router";

import { Service, Step } from 'types/Service';
import { gatewayService } from 'services';
import { PrimaryButton, Button, Spinner } from 'components/atoms';
import { Popup } from 'components/molecules';
import { theme } from 'theme';
import {
  getServiceLink,
  copyText,
  getNetworkErrors,
  isListEmpty
} from 'utils/general';
import { PageHeader } from '../../components';
import { AdminPageProps } from 'components/AppRouter';
import {
  canCreateService,
  canUpdateService,
  canDeleteService
} from 'config/privileges';
import { FulfillerGroup } from 'types/FulfillerGroup';
import { NetworkError } from 'types/Error';
import { useMediaSizes } from '../../hooks';

import {
  MenuWrapper,
  MenuItem,
  IconButton,
  NoDataCaption
} from 'theme/mixins';
import {
  Wrapper,
  ServiceCard,
  StyledPlus,
  StatusIndicator,
  ServiceCardColumn,
  ServiceCardOuter,
  StyledMenuDots,
  StyledPayments,
  AdminFormLine,
  ModalContentWrapper,
  ModalWrapper,
  ModalItem,
  ModalBorder,
  PricingRulesCaption,
  StyledBuilding
} from './Services.styles';

interface State {
  services: {
    loading: boolean;
    initialLoad: boolean;
    data: Service[] | null;
    total: number | null;
    offset: number;
    error: NetworkError | null;
  };
  serviceUpdate: {
    id: string;
    loading: boolean;
    data: Service | null;
    error: NetworkError | null;
  };
  serviceDelete: {
    loading: boolean;
    error: NetworkError | null;
  };
  fulfillerGroups: {
    loading: boolean;
    data: FulfillerGroup[] | null;
    total: number | null;
    error: NetworkError | null;
  };
  modal: {
    show: boolean;
    header: string;
    content: string;
    buttons: any[];
  };
}

const initialState: State = {
  services: {
    loading: false,
    initialLoad: true,
    total: null,
    data: null,
    offset: 0,
    error: null
  },
  serviceUpdate: {
    id: '',
    loading: false,
    data: null,
    error: null
  },
  serviceDelete: {
    loading: false,
    error: null
  },
  fulfillerGroups: {
    loading: false,
    data: null,
    total: null,
    error: null
  },
  modal: {
    show: false,
    header: '',
    content: '',
    buttons: []
  }
};

const Services: FC<AdminPageProps> = props => {
  const {
    userData,
    addToast
  } = props;

  const [state, setState] = useState<State>(initialState);
  const [activeMenuId, setActiveMenuId] = useState<string | null>(null);

  const navigate = useNavigate();
  const { clientId } = useParams();
  const { isMobile } = useMediaSizes();

  const onNew = useCallback(() => {
    navigate({ pathname: `/${clientId}/back-office/services/create` });
  }, [clientId, navigate]);

  const fetchServices = useCallback(() => {
    if (state.services.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      services: {
        ...prevState.services,
        loading: true
      }
    }));

    gatewayService.getServices(clientId!)
      .then((services: any) => {
        setState(prevState => ({
          ...prevState,
          services: {
            loading: false,
            initialLoad: false,
            total: services.total,
            data: [
              ...JSON.parse(JSON.stringify(services.data))
            ],
            fields: services.fields,
            offset: services.offset,
            error: null
          }
        }));
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          services: {
            ...prevState.services,
            loading: false,
            initialLoad: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.services,
    clientId,
    addToast
  ]);

  const updateService = useCallback((id: string, payload: Partial<Service>) => {
    if (state.serviceUpdate.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      serviceUpdate: {
        ...prevState.serviceUpdate,
        id,
        loading: true
      }
    }));

    gatewayService.updateService(clientId!, id, payload)
      .then((service: any) => {
        const index: number = state.services.data!.findIndex((s: Service) => s._id === id);

        setState(prevState => ({
          ...prevState,
          services: {
            ...prevState.services,
            data: [
              ...prevState.services.data!.slice(0, index),
              {
                ...service.data
              },
              ...prevState.services.data!.slice(index + 1, prevState.services.total!),
            ]
          },
          serviceUpdate: {
            ...prevState.serviceUpdate,
            loading: false,
            data: service.data,
            error: null
          }
        }));

        addToast({
          type: 'success',
          content: 'Service updated'
        });
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          serviceUpdate: {
            ...prevState.serviceUpdate,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    addToast,
    state.services.data,
    state.serviceUpdate,
    clientId
  ]);

  const removeService = useCallback((id: string, callback: (e?: NetworkError) => void) => {
    if (state.serviceDelete.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      serviceDelete: {
        ...prevState.serviceDelete,
        loading: true
      }
    }));

    gatewayService.deleteService(clientId!, id)
      .then(() => {
        setState(prevState => ({
          ...prevState,
          serviceDelete: {
            ...prevState.serviceDelete,
            loading: false,
            error: null
          }
        }));

        addToast({
          type: 'success',
          content: 'Service deleted'
        });

        callback();
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          serviceDelete: {
            ...prevState.serviceDelete,
            loading: false,
            error
          }
        }));

        callback(err);

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    addToast,
    state.serviceDelete,
    clientId
  ]);

  const fetchFulfillerGroups = useCallback(() => {
    if (state.fulfillerGroups.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      fulfillerGroups: {
        ...prevState.fulfillerGroups,
        loading: true
      }
    }));

    gatewayService.getFulfillerGroups(clientId!)
      .then((fulfillerGroupsResponse: any) => {
        setState(prevState => ({
          ...prevState,
          fulfillerGroups: {
            ...prevState.fulfillerGroups,
            loading: false,
            total: fulfillerGroupsResponse.total,
            data: fulfillerGroupsResponse.data,
            error: null
          }
        }));
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          fulfillerGroups: {
            ...prevState.fulfillerGroups,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.fulfillerGroups,
    clientId,
    addToast
  ]);

  const onMenuClick = useCallback((e: any, id: string) => {
    e.stopPropagation();

    setActiveMenuId(id);
  }, []);

  const closePopupMenu = useCallback(() => {
    // TODO: component still mounted

    setActiveMenuId(null);
  }, []);

  const toggleService = useCallback((id: string, isEnabled: boolean) => {
    updateService(id, {    
      isEnabled: !isEnabled
    });
  }, [updateService]);

  const managePricing = useCallback((id: string) => {
    navigate({
      pathname: `/${clientId}/back-office/pricing-rules`,
      search: `?service=${id}`
    });
  }, [
    navigate,
    clientId
  ]);

  const cloneService = useCallback((serviceId: string) => {
    gatewayService.cloneService(clientId!, serviceId)
      .then(() => {
        fetchServices();
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    clientId,
    fetchServices,
    addToast
  ]);

  // TODO: find way of chaining modals. Maybe have a modal manager where these configs are passed in as an array and when one has finished being shown, it loads the next.
  const deleteService = useCallback((service: Service) => {
    if (service.isEnabled) {
      return setState(prevState => ({
        ...prevState,
        modal: {
          show: true,
          header: 'Delete service',
          content: 'New jobs will not be bookable under this service. Furthermore the link to this service will no longer work. All existing jobs under this service will be unaffected.',
          buttons: [
            {
              type: 'standard',
              text: 'Cancel'
            },
            {
              type: 'primary',
              text: 'Delete anyway',
              loading: 'state.serviceDelete.loading',
              onClick: (cb: () => void) => {
                removeService(service._id, (e?: NetworkError) => {
                  if (!e) {
                    fetchServices();
                    cb();
                  }
                });
              }
            }
          ]
        }
      }));
    }

    if (service.pricingRules.length > 0) {
      return setState(prevState => ({
        ...prevState,
        modal: {
          show: true,
          header: 'Delete service',
          content: 'This service has associated pricing rules which will also be deleted.',
          buttons: [
            {
              type: 'standard',
              text: 'Cancel'
            },
            {
              type: 'primary',
              text: 'Delete anyway',
              loading: 'state.serviceDelete.loading',
              onClick: (cb: () => void) => {
                removeService(service._id, (e?: NetworkError) => {
                  if (!e) {
                    fetchServices();
                    cb();
                  }
                });
              }
            }
          ]
        }
      }));
    }

    removeService(service._id, (e?: NetworkError) => {
      if (!e) {
        fetchServices();
      }
    });
  }, [
    removeService,
    fetchServices
  ]);

  const gotoService = useCallback((e: any, id: string) => {
    navigate({ pathname: `/${clientId}/back-office/services/${id}` });
  }, [navigate, clientId]);

  const gotoServiceEdit = useCallback((e: any, serviceId: string) => {
    navigate({ pathname: `/${clientId}/back-office/services/${serviceId}/edit` });
  }, [navigate, clientId]);

  const closeModal = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      modal: {
        ...initialState.modal
      }
    }));
  }, []);

  // TODO: abstract
  const renderModal = useCallback(() => {
    if (!state.modal.show) {
      return null;
    }

    return (
      <Popup
        id={'services-modal'}
        layered
        convertable
        noPadding
        onClose={closeModal}
      >
        {({ closePopup }) => {
          return (
            <ModalWrapper>
              <ModalItem>
                <AdminFormLine marginTop>
                  <h3 style={{marginBottom: 0}}>{state.modal.header}</h3>
                </AdminFormLine>
              </ModalItem>

              <AdminFormLine marginBottom />
              <ModalBorder />
              <AdminFormLine marginBottom />

              <ModalContentWrapper maxWidth>
                <ModalItem>
                  <AdminFormLine marginBottom>
                    {state.modal.content}
                  </AdminFormLine>
                </ModalItem>
              </ModalContentWrapper>

              <AdminFormLine marginBottom />
              <ModalBorder />
              <AdminFormLine marginBottom />

              <ModalItem>
                <AdminFormLine right>
                  {state.modal.buttons.map((button: any, index: number) => {
                    switch(button.type) {
                      case 'standard':
                        return (
                          <Button
                            key={index}
                            type={'button'}
                            onClick={() => {
                              closePopup();

                              if (button.onClick) {
                                button.onClick();
                              }
                            }}
                            style={{ marginRight: '1rem' }}
                          >{button.text}</Button>
                        );
                      case 'primary':
                        return (
                          <PrimaryButton
                            key={index}
                            type={'submit'}
                            loading={
                              // eslint-disable-next-line
                              eval(button.loading)
                            }
                            spinnerColor={theme.colors.coreSecondary}
                            onClick={() => {
                              if (button.onClick) {
                                button.onClick(closePopup);
                              }
                            }}
                          >{button.text}</PrimaryButton>
                        );
                    };

                    return null;
                  })}
                </AdminFormLine>
              </ModalItem>
            </ModalWrapper>
          );
        }}
      </Popup>
    );
  }, [
    state,
    closeModal
  ]);

  const renderServices = useCallback(() => {
    if (state.services.initialLoad || state.services.loading || state.fulfillerGroups.loading) {
      return (
        <Spinner
          color={theme.textColor}
          size={'M'}
        />
      );
    }

    if (state.services.error) {
      return null;
    }

    if (isListEmpty(state.services.data) || !state.fulfillerGroups.data) {
      return (
        <AdminFormLine
          column
          centerH
          style={{
            paddingTop: '5rem'
          }}
        >
          <AdminFormLine marginBottom>
            <StyledBuilding />
          </AdminFormLine>
          <AdminFormLine marginBottom>
            <NoDataCaption style={{ marginLeft: '3rem' }}>Get started building services that you want to offer to your customers</NoDataCaption>
          </AdminFormLine>
          <PrimaryButton
            onClick={onNew}
          >Get started!</PrimaryButton>
        </AdminFormLine>
      );
    }

    return state.services.data!.map((service: Service) => {
      const fieldCount: number = service.steps.reduce((acc: any, curr: Step) => curr.fields.length, 0);
      const stepCount: number = service.steps.length;
      const pricingRuleCount: number = (service.pricingRules && service.pricingRules.length) || 0;

      return (
        <ServiceCard
          key={service._id}
          column
          boxShadow
          centerV
          onClick={(e: any) => gotoService(e, service._id)}
        >
          <ServiceCardOuter>
            <ServiceCardColumn>
              <StatusIndicator isEnabled={service.isEnabled} />
            </ServiceCardColumn>
            <ServiceCardColumn grow row spaceAround>
              <ServiceCardColumn maxWidth alignLeft>
                <p><b>{service.name}</b></p>
                <p>
                  <span>
                    {`${fieldCount} field${fieldCount > 1 ? 's' : ''}`}
                  </span>
                  <span style={{ margin: '0 .5rem' }}>&#8226;</span>
                  <span>
                    {`${stepCount} step${stepCount > 1 ? 's' : ''}`}
                  </span>
                </p>
                <p>
                  <i>
                    {service.fulfillerGroups
                    .map((groupId: string) => {
                      const fulfillerGroup: FulfillerGroup | undefined = state.fulfillerGroups.data!.find(g => g._id === groupId);

                      if (fulfillerGroup) {
                        return fulfillerGroup.name;
                      }

                      return 'Unknown fulfiller group';
                    })
                    .join(', ')}
                  </i>
                </p>
              </ServiceCardColumn>
              <ServiceCardColumn>
                <IconButton style={{ marginBottom: '.75rem' }}>
                  <StyledPayments $state={pricingRuleCount !== 0} />
                </IconButton>
                <PricingRulesCaption>{`${pricingRuleCount} pricing rule${pricingRuleCount > 1 || pricingRuleCount === 0 ? 's' : ''}`}</PricingRulesCaption>
              </ServiceCardColumn>
            </ServiceCardColumn>
            <ServiceCardColumn>
              <IconButton hoverEffect onClick={(e: any) => onMenuClick(e, service._id)}>
                <StyledMenuDots />
                {activeMenuId === service._id && (
                  <Popup
                    id={service._id}
                    left
                    bottom
                    convertable
                    onClose={closePopupMenu}
                  >
                    {({ closePopup }) => (
                      <MenuWrapper>
                        {canUpdateService(clientId!, userData.user!) && (
                          <MenuItem
                            disabled={!service.pricingRules.length}
                            onClick={() => {
                              if (service.pricingRules.length) {
                                closePopup();
                                toggleService(service._id, service.isEnabled);
                              }
                            }}
                          >{service.isEnabled ? 'Disable' : 'Enable'}</MenuItem>
                        )}
                        {canUpdateService(clientId!, userData.user!) && (
                          <MenuItem onClick={(e: any) => {
                            closePopup();
                            gotoServiceEdit(e, service._id);
                          }}>Edit</MenuItem>
                        )}
                        <MenuItem onClick={() => {
                          closePopup();
                          managePricing(service._id);
                        }}>Manage pricing</MenuItem>
                        <MenuItem onClick={() => {
                          closePopup();
                          copyText(getServiceLink(clientId!, service._id))
                            .then(() => {
                              addToast({
                                type: 'success',
                                content: 'Service link copied'
                              });
                            })
                            .catch(() => {
                              addToast({
                                type: 'error',
                                content: 'Error copying service link. Please copy manually.'
                              });
                            });
                        }}>Copy link</MenuItem>
                        {canCreateService(clientId!, userData.user!) && (
                          <MenuItem onClick={() => {
                            closePopup();
                            cloneService(service._id);
                          }}>Clone</MenuItem>
                        )}
                        {canDeleteService(clientId!, userData.user!) && (
                          <MenuItem onClick={() => {
                            closePopup();
                            deleteService(service);
                          }}>Delete</MenuItem>
                        )}
                      </MenuWrapper>
                    )}
                  </Popup>
                )}
              </IconButton>
            </ServiceCardColumn>
          </ServiceCardOuter>
        </ServiceCard>
      );
    });
  }, [
    userData.user,
    state.services,
    state.fulfillerGroups,
    clientId,
    activeMenuId,
    toggleService,
    managePricing,
    cloneService,
    deleteService,
    closePopupMenu,
    onMenuClick,
    gotoService,
    gotoServiceEdit,
    addToast,
    onNew
  ]);

  useEffect(() => {
    if (!state.services.data && !state.services.error) {
      fetchServices();
    }
  }, [state.services.data, state.services.error, fetchServices]);

  useEffect(() => {
    if (!state.fulfillerGroups.data && !state.fulfillerGroups.error) {
      fetchFulfillerGroups();
    }
  }, [
    state.fulfillerGroups.data,
    state.fulfillerGroups.error,
    fetchFulfillerGroups
  ]);

  // TODO: show middle div explaining what a service is and providing a button to create one

  return (
    <Wrapper>
      <PageHeader
        title={'Services'}
        rightContent={
          <>
            {canCreateService(clientId!, userData.user!) && (
              <Button
                style={{marginBottom: 0}}
                icon={<StyledPlus />}
                onClick={onNew}
              >{isMobile ? '' : 'New'}</Button>
            )}
          </>
        }
      />
      {renderServices()}
      {renderModal()}
    </Wrapper>
  );
};

export default memo(Services);

