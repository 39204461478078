import { Branding } from 'gobookem-shared';

import GlobalStyle from './globals'

const theme = Branding.theme;

// import theme, { Theme as ThemeImport } from './theme'

export type Theme = typeof theme;

export {
  theme,
  GlobalStyle
}

