import Browser, { Window } from './browser';

interface Auth {
  browser: Window;
  token(): string | null;
  isAuthenticated(): boolean;
  setToken(token: string): void;
  invalidate(): void;
  shouldRefreshOnInvalid: boolean;
  redirectToLogin(): void;
  redirectAfterLogin(redirectUrl: string): void;
}

const auth: Auth = {
  browser: new Browser(),
  token: () => auth.browser.window.getItem('token'),
  isAuthenticated: () => {
    return (
      !!auth.browser.window.getItem('token')
    );
  },
  setToken: (token: string) => {
    auth.browser.window.setItem('token', token);
  },
  invalidate: () => {
    auth.browser.window.removeItem('userd');
    auth.browser.window.removeItem('token');
  },
  shouldRefreshOnInvalid: true,
  redirectToLogin: () => {
    auth.browser.window.redirect(`/login?redirect=${encodeURIComponent(window.location.pathname + window.location.search)}`, true);
  },
  redirectAfterLogin: (redirectUrl: string) => {
    auth.browser.window.redirect(redirectUrl, true);
  }
};

export default auth;
