import React, { FC, memo } from 'react';

import {
  Background,
  Footer
} from 'components/molecules';
import { PageProps } from 'components/AppRouter';
import {
  FormLine,
  HR
} from 'theme/mixins';

import {
  Wrapper,
  StyledHeader,
  StyledCard,
  StyledTick
} from './BookingComplete.styles';

const BookingComplete: FC<PageProps> = props => {
  return (
    <>
      <Background />
      <Wrapper>
        <StyledCard boxShadow>
          <FormLine
            center
            marginBottom
          >
            <StyledHeader>Booking complete</StyledHeader>
          </FormLine>

          <FormLine marginBottom>
            <HR />
          </FormLine>
          <FormLine marginBottom />

          <FormLine center marginBottom>
            <StyledTick />
          </FormLine>

          <FormLine center marginBottom>
            <p>Thank you for making your booking with us!</p>
          </FormLine>

          <br/>

          <FormLine center marginBottom>
            <p>An email confirmation has been sent</p>
          </FormLine>
        </StyledCard>
        <FormLine
          center
          marginBottom
        >
          <Footer />
        </FormLine>
      </Wrapper>
    </>
  );
};

export default memo(BookingComplete);
