import styled from 'styled-components';
import { device } from 'theme/media';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Table = styled.table<{ isFixed?: boolean; }>`
  width: inherit;
  text-align: left;
  border-collapse: collapse;
  background: ${({ theme }) => theme.table.backgroundColor};

  ${({ isFixed }) => isFixed && `
    table-layout: fixed;
  `}

  @media ${device.onlyMobile} {
    display: block;
  }
`;

export const TableBody = styled.tbody`
  @media ${device.onlyMobile} {
    display: block;
  }
`;

export const TableFooter = styled.div`
  display: flex;
  margin-top: 3rem;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`;

