import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { device } from 'theme/media';

import {
  Chevron,
  Menu
} from 'components/atoms';
import { Tabs } from 'components/molecules';

import {
  Card,
  AdminFormLine
} from 'theme/mixins';
export * from 'theme/mixins';

export const Wrapper = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-rows: auto;
  grid-template-columns: ${({ theme }) => theme.sideNav.openWidth}rem 1fr;

  @media ${device.mobile} {
    grid-template-columns: 1fr;
  }

  @media ${device.laptop} {
    grid-template-columns: ${({ theme }) => theme.sideNav.openWidth}rem 1fr;
  }

  .pinnable {
    transition: padding-left ${({ theme }) => theme.animSpeed} ease-in-out;

    &.pinned {
      padding-left: 3rem;
    }
  }
`;

export const Main = styled.div<{ navOpen: boolean; }>`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: auto;
  flex: 1;
  width: 100%;
  height: inherit;
  transition: all ${({ theme }) => theme.animSpeed} ease-in-out;
  overflow-y: auto;

  @media ${device.laptop} {
    transform: translateX(0);

    ${({ theme, navOpen }) => !navOpen && `
      width: calc(100% + ${theme.sideNav.openWidth - theme.sideNav.closeWidth}rem);
      transform: translateX(-${theme.sideNav.openWidth - theme.sideNav.closeWidth}rem);
    `};
  }
`;

export const MainInner = styled.div<{ hideHeader: boolean; }>`
  display: grid;
  grid-template-columns: auto;
  flex: 1;
  width: 100%;
  height: inherit;
  overflow-y: auto;
  transform: translateX(0);

  @media ${device.mobile} {
    ${({ hideHeader }) => hideHeader && `
      grid-template-rows: 1fr;
    `};

    ${({ hideHeader }) => !hideHeader && `
      grid-template-rows: 6rem 1fr;
    `};
  }

  @media ${device.tablet} {
    ${({ hideHeader }) => hideHeader && `
      grid-template-rows: 6rem 1fr;
    `};
  }
`;

export const InnerWrapper = styled.div<{ navOpen: boolean; removeBottomPadding: boolean; }>`
  display: flex;
  height: 100%;
  margin: 0 auto;
  padding: 5rem 0;
  flex-direction: column;
  transition: all ${({ theme }) => theme.animSpeed} ease-in-out;
  // overflow-x: hidden;

  // TODO: change mobile design of service list

  ${({ removeBottomPadding }) => removeBottomPadding && `
    padding-bottom: 0;
  `}

  @media ${device.mobile} {
    width: inherit;
  }

  @media ${device.laptop} {
    width: 75rem;

    ${({ navOpen }) => !navOpen && `
      width: 91.5rem;
    `};
  }

  @media ${device.laptopL} {
    width: 96rem;

    ${({ navOpen }) => !navOpen && `
      width: 112.5rem;
    `};
  }
`;

export const Sidebar = styled.div<{ navOpen: boolean; navHidden: boolean; }>`
  display: flex;
  width: ${({ theme }) => theme.sideNav.closeWidth}rem;
  height: inherit;
  background-color: ${({ theme }) => theme.sideNav.backgroundColor};
  border-right: .1rem solid ${({ theme }) => theme.sideNav.borderColor};
  transition: all ${({ theme }) => theme.animSpeed} ease-in-out;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;

  @media ${device.mobile} {
    position: fixed;
    left: 0;
    top: 0;
    z-index: ${({ theme }) => theme.layers.highest};

    ${({ theme, navOpen }) => navOpen && `
      width: ${theme.sideNav.openWidth}rem;
    `}

    ${({ theme, navHidden, navOpen }) => navHidden && `
      left: -${navOpen ? theme.sideNav.openWidth : theme.sideNav.closeWidth}rem;
    `}
  }

  @media ${device.laptop} {
    position: relative;
    left: 0;
    z-index: unset;
  }
`;

export const StyledChevron = styled(Chevron)`
  fill: ${({ theme }) => theme.colors.corePrimaryDarker};
  width: 3rem;
  height: 3rem;
  transition: transform ${({ theme }) => theme.animSpeed} ease-in-out;
  transform: rotateZ(90deg);
`;

export const MenuIcon = styled(Menu)`
  top: 0;
  left: 0;
  padding: 0;
  transition: all ${({ theme }) => theme.animDelay} ease-in-out;
  transform: scale(.4);
`;

export const SidebarSplitter = styled.i<{ navOpen: boolean; navHidden: boolean; }>`
  display: flex;
  position: absolute;
  left: 0;
  bottom: 20%;
  width: 3rem;
  height: 3rem;
  background-color: ${({ theme }) => theme.sideNav.backgroundColor};
  border: .1rem solid ${({ theme }) => theme.sideNav.borderColor};
  border-radius: 1.5rem;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.corePrimary};
  align-items: center;
  justify-content: center;
  font-size: 1.9rem;
  z-index: 10;
  transition: transform ${({ theme }) => theme.animSpeed} ease-in-out;
  transform: translate(${({ theme }) => theme.sideNav.openWidth - 1.5}rem, 0);

  ${({ theme, navOpen }) => !navOpen && `
    transform: translate(${theme.sideNav.closeWidth - 1.5}rem, 0);

    ${StyledChevron} {
      transform: rotateZ(-90deg);
    }
  `};

  ${({ theme, navHidden }) => navHidden && `
    transform: translate(-${theme.sideNav.closeWidth - 1.5}rem, 0);
  `}
`;

export const SidebarCloser = styled.i<{ navOpen: boolean; navHidden: boolean; hide: boolean; }>`
  position: absolute;
  left: 0;
  width: 3rem;
  height: 3rem;
  border-radius: 1.5rem;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.corePrimary};
  align-items: center;
  justify-content: center;
  font-size: 1.9rem;
  z-index: 10;
  transition: transform ${({ theme }) => theme.animSpeed} ease-in-out, top ${({ theme }) => theme.animSpeed} ease-in-out;
  transform: translate(${({ theme }) => theme.sideNav.openWidth - 1.5}rem, 0);

  ${({ theme, navOpen }) => !navOpen && `
    transform: translate(${theme.sideNav.closeWidth - 1.5}rem, 0);
  `};

  ${({ theme, navHidden }) => navHidden && `
    transform: translate(0rem, 0);
  `}

  @media ${device.mobile} {
    display: flex;
    top: 5.5rem;

    ${({ navHidden }) => navHidden && `
      top: 1.6rem;
      background-color: transparent;
      border: none;

      ${MenuIcon} {
        // top: -4rem;
        left: 2.5rem;
        transform: scale(1);
      }
    `};

    ${({ theme, navHidden }) => !navHidden && `
      background-color: ${theme.sideNav.backgroundColor};
      border: .1rem solid ${theme.sideNav.borderColor};
    `};

    ${({ hide }) => hide && `
      display: none;
    `};
  }

  @media ${device.tablet} {
    ${({ hide }) => hide && `
      display: flex;
    `};
  }

  @media ${device.laptop} {
    display: none;
  }
`;

export const SiteHeader = styled.div<{ hide: boolean; }>`
  display: flex;
  position: sticky;
  top: 0;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  z-index: ${({ theme }) => theme.layers.highest};

  @media ${device.mobile} {
    ${({ hide }) => hide && `
      display: none;
    `};
  }

  @media ${device.tablet} {
    ${({ hide }) => hide && `
      display: flex;
    `};
  }
`;

export const Nav = styled.ul<{ isSub?: boolean; }>`
  // height: inherit;
  margin-top: 3rem;
  // overflow: hidden;

  ${({ isSub }) => isSub && `
    margin-top: 0;
    margin-left: 6rem;
  `};
`;

export const IconOuterWrapper = styled.div`
  display: flex;
  width: 6rem;
  height: auto;
  justify-content: center;
  flex-shrink: 0;
`;

export const NavIconWrapper = styled.div`
  display: flex;
  width: 4rem;
  height: 4rem;
  padding: 0;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
`;

export const NavItem = styled.li<{ active?: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 1rem;

  span {
    flex-shrink: 0;
    color: ${({ theme }) => theme.textColor};
  }

  svg {
    width: 2.5rem;
    fill: ${({ theme }) => theme.sideNav.iconColor};
  }

  ${({ theme, active }) => !active && `
    :hover {
      background-color: ${theme.sideNav.hover.itemBackground};

      span {
        color: ${theme.sideNav.hover.itemColor};
      }

      svg {
        fill: ${theme.sideNav.hover.itemColor};
      }
    }
  `};

  ${({ theme, active }) => active && `
    background-color: ${theme.sideNav.hover.itemBackground};

    span {
      color: ${theme.sideNav.hover.itemColor};
    }

    svg {
      fill: ${theme.colors.neutralSecondary};
    }

    ${NavIconWrapper} {
      background-color: ${theme.colors.corePrimary};
    }
  `};
`;

export const Header = styled.h2`
  display: flex;
  margin-bottom: 1.5rem;
`;

export const StyledTabs = styled(Tabs)`
  height: 100%;
  margin-top: 2rem;
`;

export const StyledCard = styled(Card)`
  padding: 0 2rem 0;
`;

export const GeneratedPrice = styled.div`
  font-size: 1.8rem;
  font-weight: 700;
  cursor: pointer;
`;

export const PaymentLink = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
  cursor: pointer;

  svg {
    width: 1.6rem;
    height: 1.6rem;
    margin-left: 1rem;
  }
`;

export const StyledNavLink = styled(Link)`
  display: flex;
  width: inherit;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
`;

export const ClientContext = styled(AdminFormLine)<{ isInBuilder: boolean }>`
  ${({ isInBuilder }) => isInBuilder && `
    @media ${device.onlyMobile} {
      display: none;
    }
  `};
`;
