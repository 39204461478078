import React, { FC, useCallback, useState, useEffect, memo, useMemo, useRef } from 'react';
import { useParams, useNavigate } from "react-router";
import { useDrag, useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import {
  string,
  object,
  array
} from 'yup';
import { format } from 'date-fns';

import { Fulfiller } from 'types/Fulfiller';
import { FulfillerGroup } from 'types/FulfillerGroup';
import { User, RoleType } from 'types/User';
import { Client } from 'types/Client';
import { FieldType } from 'types/Service';
import { Period } from 'types/Generic';
import { gatewayService } from 'services';
import {
  DropdownOption,
  DragItem
} from 'types/UI';
import {
  Spinner,
  Text,
  TextInput,
  Button,
  PrimaryButton,
  Dropdown,
  UnfoldMore,
  UnfoldLess,
  ColourPicker,
  Avatar
} from 'components/atoms';
import {
  Popup
} from 'components/molecules';
import { theme } from 'theme';
import { PageHeader, Table } from '../../components';
import { ITableHeader, CellType, RowAction } from 'types/Header';
import {
  useSortFilter,
  useMediaSizes
} from '../../hooks';
import {
  copyText,
  createDropdownOption,
  getNetworkErrors,
  getInactiveUserIds,
  isListEmpty,
  extractNumericInput,
  addCountryCodeToNumber,
  stripCountryCodeFromNumber,
  formatPhoneNumber,
  stripLeadingZeroFromNumber
} from 'utils/general';
import { AdminPageProps } from 'components/AppRouter';
import {
  isFulfiller,
  canCreateUser,
  canUpdateUser,
  canDeleteUser,
  canCreateFulfillerGroup,
  canUpdateFulfillerGroup,
  canDeleteFulfillerGroup,
  getRoleOptions
} from 'config/privileges';
import {
  DATE_DROPDOWN_OPTIONS,
  ISO_FORMAT
} from '../../../../../constants';
import { Form } from 'types/UI';
import {
  LocalError,
  NetworkError
} from 'types/Error';
import {
  useDebouncedCallback,
  useValidation,
  useAutoFocus
} from 'components/hooks';

import {
  MenuWrapper,
  MenuItem,
  NoDataIcon,
  NoDataCaption
} from 'theme/mixins';
import {
  Wrapper,
  Card,
  IconButton,
  StyledMenuDots,
  AdminFormLine,
  StyledCopy,
  ModalWrapper,
  ModalItem,
  ModalBorder,
  StyledPlus,
  StyledTabs,
  StyledFormSection,
  StyledHR,
  ContentWrapper,
  CardInner,
  Details,
  StyledChevron,
  DetailsOuter,
  DetailsInner,
  ModalContentWrapper,
  StyledNoDataIcon
  // GroupListItem
} from './Workforce.styles';

interface State {
  client: {
    loading: boolean;
    data: Client | null;
    error: NetworkError | null;
  };
  fulfillers: {
    loading: boolean;
    data: Fulfiller[] | null;
    total: number | null;
    fields: any;
    offset: number;
    error: NetworkError | null;
  };
  fulfillerCreate: {
    id: string;
    loading: boolean;
    data: Fulfiller | null;
    error: NetworkError | null;
    modalShown: boolean;
  };
  fulfillerUpdate: {
    id: string;
    loading: boolean;
    error: NetworkError | null;
    modalShown: boolean;
    fulfiller: Fulfiller | null;
  };
  adminCreate: {
    id: string;
    loading: boolean;
    data: Fulfiller | null;
    error: NetworkError | null;
    modalShown: boolean;
  };
  adminUpdate: {
    id: string;
    loading: boolean;
    error: NetworkError | null;
    modalShown: boolean;
    admin: User | null;
  };
  fulfillerDelete: {
    loading: boolean;
    error: NetworkError | null;
  };
  fulfillerGroups: {
    loading: boolean;
    data: FulfillerGroup[] | null;
    total: number | null;
    expanded: {
      [id: string]: boolean;
    };
    error: NetworkError | null;
    activeMenuId: string | null;
  };
  fulfillerGroupCreate: {
    id: string;
    loading: boolean;
    data: FulfillerGroup | null;
    error: NetworkError | null;
    modalShown: boolean;
  };
  fulfillerGroupUpdate: {
    id: string;
    loading: boolean;
    error: NetworkError | null;
    modalShown: boolean;
    fulfillerGroup: FulfillerGroup | null;
  };
  fulfillerGroupDelete: {
    loading: boolean;
    error: NetworkError | null;
  };
  admins: {
    loading: boolean;
    data: User[] | null;
    total: number | null;
    fields: any;
    offset: number;
    error: NetworkError | null;
  };
  // adminCreate: {
  //   id: string;
  //   loading: boolean;
  //   data: User | null;
  //   error: NetworkError | null;
  // };
  // adminUpdate: {
  //   id: string;
  //   loading: boolean;
  //   error: NetworkError | null;
  // };
  adminDelete: {
    loading: boolean;
    error: NetworkError | null;
  };
  defaultPassword: {
    editMode: boolean;
    form: any;
  };
  form: Form;
  errors: {
    [key: string]: any;
  };
  activeFulfillersTab: string;
}

const initialState: State = {
  client: {
    loading: false,
    data: null,
    error: null
  },
  fulfillers: {
    loading: false,
    total: null,
    data: null,
    fields: {},
    offset: 0,
    error: null
  },
  fulfillerCreate: {
    id: '',
    loading: false,
    data: null,
    error: null,
    modalShown: false,
  },
  fulfillerUpdate: {
    id: '',
    loading: false,
    error: null,
    modalShown: false,
    fulfiller: null
  },
  fulfillerDelete: {
    loading: false,
    error: null
  },
  adminCreate: {
    id: '',
    loading: false,
    data: null,
    error: null,
    modalShown: false
  },
  adminUpdate: {
    id: '',
    loading: false,
    error: null,
    modalShown: false,
    admin: null
  },
  fulfillerGroups: {
    loading: false,
    data: null,
    total: null,
    expanded: {},
    error: null,
    activeMenuId: null
  },
  fulfillerGroupCreate: {
    id: '',
    loading: false,
    data: null,
    error: null,
    modalShown: false
  },
  fulfillerGroupUpdate: {
    id: '',
    loading: false,
    error: null,
    modalShown: false,
    fulfillerGroup: null
  },
  fulfillerGroupDelete: {
    loading: false,
    error: null
  },
  admins: {
    loading: false,
    total: null,
    data: null,
    fields: {},
    offset: 0,
    error: null
  },
  adminDelete: {
    loading: false,
    error: null
  },
  defaultPassword: {
    editMode: false,
    form: {}
  },
  form: {},
  errors: {},
  activeFulfillersTab: '',
};

interface DroppableFulfillerGroupProps {
  clientId: string;
  userData: AdminPageProps['userData'];
  fulfillerGroup: FulfillerGroup;
  expanded: State['fulfillerGroups']['expanded'];
  activeMenuId: State['fulfillerGroups']['activeMenuId'];
  sideNavOpen: AdminPageProps['sideNavOpen'];
  fetchFulfillerGroups: () => void;
  updateFulfiller: (id: string, payload: Partial<Fulfiller>, callback?: () => void) => void;
  onChevronClick: (e: any, id: string) => void;
  onFulfillerGroupClick: (e: any, id: string) => void;
  openFulfillerGroupModal: (id?: string) => void;
  closeFulfillerGroupPopupMenu: () => void;
  removeFulfillerGroup: (id: string, callback?: () => void) => void;
}

const DroppableGroup: FC<DroppableFulfillerGroupProps> = (props) => {
  const {
    clientId,
    userData,
    fulfillerGroup,
    expanded,
    activeMenuId,
    fetchFulfillerGroups,
    updateFulfiller,
    onChevronClick,
    onFulfillerGroupClick,
    openFulfillerGroupModal,
    closeFulfillerGroupPopupMenu,
    removeFulfillerGroup
  } = props;

  const { isMobile } = useMediaSizes();
  const [activeAvatarMenuId, setActiveAvatarMenuId] = useState<string | null>(null);

  const openDraggableAvatarPopup = useCallback((fulfiller: User | Fulfiller) => {
    setActiveAvatarMenuId(fulfiller._id);
  }, []);

  const closeDraggableAvatarPopup = useCallback(() => {
    setActiveAvatarMenuId(null);
  }, []);

  const renderDraggableAvatarPopup = useCallback((fulfiller: Fulfiller) => {
    if (activeAvatarMenuId !== fulfiller._id) {
      return null;
    }

    return (
      <Popup
        id={fulfiller._id}
        right
        bottom
        convertable
        onClose={closeDraggableAvatarPopup}
      >
        {({ closePopup }) => (
          <MenuWrapper>
            {canUpdateUser(clientId, userData.user!, fulfiller) && (
              <MenuItem
                onClick={() => {
                  closePopup();

                  const foundFulfiller = fulfillerGroup.fulfillers.find(f => f._id === fulfiller._id);

                  if (!foundFulfiller) {
                    // TODO: log error
                    return;
                  }

                  foundFulfiller.fulfillerGroups = foundFulfiller.fulfillerGroups.filter(g => g !== fulfillerGroup._id);

                  updateFulfiller(foundFulfiller._id, foundFulfiller, () => {
                    fetchFulfillerGroups();
                  });
                }}
              >Delete</MenuItem>
            )}
          </MenuWrapper>
        )}
      </Popup>
    );
  }, [
    clientId,
    activeAvatarMenuId,
    userData,
    fulfillerGroup,
    updateFulfiller,
    fetchFulfillerGroups,
    closeDraggableAvatarPopup
  ]);

  const [{ isOver, canDrop }, drop] = useDrop<
    any,
    void,
    {
      isOver: boolean,
      canDrop: boolean
    }
  >({
    accept: DragItem.Avatar,
    collect(monitor) {
      return {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
      }
    },
    canDrop(item, monitor) {
      return item.fulfiller.fulfillerGroups && !item.fulfiller.fulfillerGroups.includes(fulfillerGroup._id);
    },
    drop(item: any, monitor) {
      if (monitor.didDrop()) {
        return;
      }

      if (item.fulfiller.fulfillerGroups && !item.fulfiller.fulfillerGroups.includes(fulfillerGroup._id)) {
        updateFulfiller(
          item.fulfiller._id,
          {
            ...item.fulfiller,
            fulfillerGroups: [
              ...item.fulfiller.fulfillerGroups,
              fulfillerGroup._id
            ]
          },
          () => fetchFulfillerGroups()
        );
      }
    }
  });

  const isItemOver: boolean = isOver && canDrop;
  const members: number = fulfillerGroup.fulfillers?.length ?? 0;

  return (
    <Card
      ref={drop}
      key={fulfillerGroup._id}
      column
      boxShadow
      centerV
      isOver={isItemOver}
      style={{
        position: 'relative',
        marginBottom: '2rem',
        cursor: 'pointer',
        padding: '1rem',
        userSelect: 'none'
      }}
    >
      <CardInner>
        <ContentWrapper className={'mobile-group-expand-wrapper'}>
          <IconButton
            hoverEffect
            onClick={(e: any) => onChevronClick(e, fulfillerGroup._id)}
          >
            <StyledChevron $down={expanded[fulfillerGroup._id]}/>
          </IconButton>
        </ContentWrapper>
        <ContentWrapper
          wordBreak
          style={{
            ...(isMobile && {
              marginLeft: 0,
              paddingBottom: '1rem'
            })
          }}
        >
          <p>{fulfillerGroup.name} {members ? <b>{`(${members})`}</b> : ''}</p>
        </ContentWrapper>
        <ContentWrapper className={'mobile-group-menu-wrapper'}>
          <IconButton hoverEffect onClick={(e: any) => onFulfillerGroupClick(e, fulfillerGroup._id)}>
            <StyledMenuDots />
            {activeMenuId === fulfillerGroup._id && (
              <Popup
                id={fulfillerGroup._id}
                left
                bottom
                convertable
                onClose={closeFulfillerGroupPopupMenu}
              >
                {({ closePopup }) => (
                  <MenuWrapper>
                    {canUpdateFulfillerGroup(clientId, userData.user!, fulfillerGroup) && (
                      <MenuItem onClick={() => {
                        closePopup();
                        openFulfillerGroupModal(fulfillerGroup._id);
                      }}>Edit</MenuItem>
                    )}
                    {canDeleteFulfillerGroup(clientId, userData.user!, fulfillerGroup) && (
                      <MenuItem onClick={() => {
                        closePopup();
                        removeFulfillerGroup(fulfillerGroup._id, () => {
                          fetchFulfillerGroups(); 
                        });
                      }}>Delete</MenuItem>
                    )}
                  </MenuWrapper>
                )}
              </Popup>
            )}
          </IconButton>
        </ContentWrapper>
      </CardInner>
      <Details
        isExpanded={expanded[fulfillerGroup._id]}
        onClick={(e: any) => e.stopPropagation()}
      >
        <DetailsOuter>
          <DetailsInner groupCount={1}>
            {isListEmpty(fulfillerGroup.fulfillers) ? (
              <AdminFormLine
                column
                centerH
              >
                <StyledNoDataIcon />
                <AdminFormLine marginBottom>
                  <NoDataCaption>Drag in your first fulfiller!</NoDataCaption>
                </AdminFormLine>
              </AdminFormLine>
            ) : (
              <>
                {fulfillerGroup.fulfillers
                  .sort((a, b) => a.firstName.localeCompare(b.firstName))
                  .map((fulfiller) => (
                    <AdminFormLine
                      column
                      key={`${fulfillerGroup._id}-${fulfiller._id}`}
                      style={{ marginBottom: '.75rem' }}
                    >
                      <DraggableAvatar
                        fulfiller={fulfiller}
                        onClick={() => openDraggableAvatarPopup(fulfiller)}
                      />
                      {renderDraggableAvatarPopup(fulfiller)}
                    </AdminFormLine>
                  ))
                }
              </>
            )}
          </DetailsInner>
        </DetailsOuter>
      </Details>
    </Card>
  );
};

export interface DraggableAvatarProps {
  isPreview?: boolean;
  item?: any;
  fulfiller?: User | Fulfiller | null;
  onClick?: (user: User | Fulfiller) => void;
}

export const DraggableAvatar: FC<DraggableAvatarProps> = (props) => {
  const {
    isPreview,
    item,
    fulfiller,
    onClick
  } = props;

  const ref = useRef<HTMLTableRowElement>(null);

  const [{ isDragging }, drag] = useDrag({
    type: DragItem.Avatar,
    canDrag: true,
    item: () => ({
      fulfiller,
      width: ref.current!.getBoundingClientRect().width
    }),
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    })
  });

  drag(ref);

  let elem = (
    <Avatar
      showName
      ref={ref}
      user={fulfiller}
      style={{ opacity: isDragging ? 0.5 : 1 }}
      onClick={(e) => {
        if (fulfiller && onClick) {
          onClick(fulfiller);
        }
      }}
    />
  );

  if (isPreview) {
    elem = (
      <Avatar
        showName
        user={item.fulfiller}
      />
    );
  }

  return elem;
};

const fulfillerGroupSchema = object({
  name: string()
    .required()
});

const fulfillerSchema = object({
  firstName: string()
    .label('First name')
    .required(),
  lastName: string()
    .label('Last name')
    .required(),
  email: string()
    .email()
    .trim()
    .required(),
  fulfillerGroups: array()
    .min(1, 'Fulfiller groups field must have at least one item')
    .label('Groups')
    .required(),
  phone: string()
    .nullable()
    .label('Contact number')
    .optional()
});

const adminSchema = object({
  firstName: string()
    .label('First name')
    .required(),
  lastName: string()
    .label('Last name')
    .required(),
  email: string()
    .email()
    .trim()
    .required(),
  role: string()
    .nullable()
    .required()
});

const Workforce: FC<AdminPageProps> = props => {
  const {
    userData,
    sideNavOpen,
    addToast
  } = props;

  const [state, setState] = useState<State>(initialState);
  const {
    errors: fulfillerGroupErrors,
    validate: validateFulfillerGroupForm,
    reset: resetFulfillerGroupForm
  } = useValidation(fulfillerGroupSchema);
  const {
    errors: fulfillerErrors,
    validate: validateFulfillerForm,
    reset: resetFulfillerForm
  } = useValidation(fulfillerSchema);
  const {
    errors: adminErrors,
    validate: validateAdminForm,
    reset: resetAdminForm
  } = useValidation(adminSchema);
  const expandAll = useMemo(() => {
    return Object.keys(state.fulfillerGroups.expanded).every((key: string) => {
      return state.fulfillerGroups.expanded[key] === false;
    });
  }, [state.fulfillerGroups.expanded]);

  const navigate = useNavigate();
  const { clientId } = useParams();
  const groupNameAutoFocus = useAutoFocus();
  const fulfillerNameAutoFocus = useAutoFocus();
  const adminNameAutoFocus = useAutoFocus();

  const { t } = useTranslation();
  const { isMobile } = useMediaSizes();

  const {
    renderFilterControl: fulfillerFilterControlMarkup,
    filterApplied: fulfillerFilterApplied
  } = useSortFilter({
    listItemId: 'fulfillers',
    total: state.fulfillers.total,
    offset: state.fulfillers.offset,
    onFilter: (opts) => fetchFulfillers(opts),
    filterOptions: {
      fields: [
        {
          label: 'Joined',
          name: 'created.at',
          type: FieldType.DateTime,
          options: [
            createDropdownOption('Today', Period.Today),
            createDropdownOption('Yesterday', Period.Yesterday),
            createDropdownOption('This week', Period.ThisWeek),
            createDropdownOption('Last week', Period.LastWeek),
            createDropdownOption('This month', Period.ThisMonth),
            createDropdownOption('Last month', Period.LastMonth),
            createDropdownOption('This quarter', Period.ThisQuarter),
            createDropdownOption('Last quarter', Period.LastQuarter),
            createDropdownOption('This year', Period.ThisYear),
            createDropdownOption('Last year', Period.LastYear),
            ...DATE_DROPDOWN_OPTIONS
          ],
          default: {
            'created.at#datetime': `today=${format(new Date(), ISO_FORMAT)}`
          }
        },
        {
          label: 'Groups',
          name: 'fulfillerGroups',
          type: FieldType.Dropdown,
          queryType: 'or',
          items: [
            // {
            //   ...createDropdownOption('Unmatched', 'null'),
            //   changeKeyTo: 'fulfillerId'
            // },
            ...((state.fulfillerGroups.data && state.fulfillerGroups.data.map((fulfillerGroup: FulfillerGroup) => {
              return createDropdownOption(fulfillerGroup.name, fulfillerGroup._id);
            })) || [])
          ]
        }
      ]
    }
  });

  const {
    renderPaginationAndSort: fulfillerPaginationMarkup,
    sortState: fulfillerSortState,
    setSortState: fulfillerSetSortState
  } = useSortFilter({
    listItemId: 'fulfiller',
    total: state.fulfillers.total,
    offset: state.fulfillers.offset,
    onPrev: (opts) => fetchFulfillers(opts),
    onNext: (opts) => fetchFulfillers(opts),
    onFilter: (opts) => fetchFulfillers(opts),
    onSort: (opts) => fetchFulfillers(opts)
  });
  const {
    renderPaginationAndSort: adminPaginationMarkup,
    sortState: adminSortState,
    setSortState: adminSetSortState,
  } = useSortFilter({
    listItemId: 'administrator',
    total: state.admins.total,
    offset: state.admins.offset,
    onPrev: (opts) => fetchAdmins(opts),
    onNext: (opts) => fetchAdmins(opts),
    onFilter: (opts) => fetchAdmins(opts),
    onSort: (opts) => fetchAdmins(opts)
  });

  const groupDropdownItems = useMemo(() => {
    if (!state.fulfillerGroups.data) {
      return [];
    }

    return state.fulfillerGroups.data.map(g => createDropdownOption(g.name, g._id));
  }, [
    state.fulfillerGroups.data 
  ]);

  const valueGroupItems = useMemo(() => {
    if (!state.form.fulfillerGroups || !state.fulfillerGroups.data) {
      return [];
    }

    return (state.form.fulfillerGroups as Array<string | DropdownOption>).map((item: string | DropdownOption) => {
      if (typeof item === 'string') {
        const fulfillerGroup: FulfillerGroup | undefined = state.fulfillerGroups.data!.find(g => g._id === item);

        if (fulfillerGroup) {
          return createDropdownOption(fulfillerGroup.name, fulfillerGroup._id);
        }

        return createDropdownOption('Unknown fulfiller group', '');
      }

      return item;

    });
  }, [
    state.form.fulfillerGroups,
    state.fulfillerGroups.data 
  ]);

  const updateFulfiller = useCallback((id: string, payload: any, callback?: () => void) => {
    if (state.fulfillerUpdate.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      fulfillerUpdate: {
        ...prevState.fulfillerUpdate,
        id,
        loading: true
      }
    }));

    const {
      _id,
      clientId: payloadClientId,
      created,
      updated,
      verified,
      jobId,
      priority,
      permissions,
      creator,
      creatorId,
      role,
      signedIn,
      jobsFilter,
      email,
      ...rest
    } = payload;

    payload = {
      ...rest,
      ...(rest.fulfillerGroups && {
        fulfillerGroups: rest.fulfillerGroups.map((g: DropdownOption) => {
          if (g.value) {
            return g.value;
          }

          return g;
        })
      })
    };

    gatewayService.updateFulfiller(clientId!, id, payload)
      .then((fulfillerUpdateResponse: any) => {
        const index: number = state.fulfillers.data!.findIndex((f: Fulfiller) => f._id === id);

        setState(prevState => ({
          ...prevState,
          fulfillers: {
            ...prevState.fulfillers,
            data: [
              ...prevState.fulfillers.data!.slice(0, index),
              {
                ...fulfillerUpdateResponse.data
              },
              ...prevState.fulfillers.data!.slice(index + 1, prevState.fulfillers.total!),
            ]
          },
          fulfillerUpdate: {
            ...initialState.fulfillerUpdate
          }
        }));

        addToast({
          type: 'success',
          content: 'Fulfiller updated'
        });

        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          fulfillerUpdate: {
            ...prevState.fulfillerUpdate,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.fulfillers.data,
    state.fulfillerUpdate,
    clientId,
    addToast
  ]);

  const updateAdmin = useCallback((id: string, payload: Partial<User>, callback?: () => void) => {
    if (state.adminUpdate.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      adminUpdate: {
        ...prevState.adminUpdate,
        id,
        loading: true,
        payload
      }
    }));

    const {
      _id,
      clientId: payloadClientId,
      created,
      updated,
      verified,
      isTrial,
      permissions,
      creator,
      creatorId,
      email,
      signedIn,
      jobsFilter,
      ...rest
    } = payload;

    gatewayService.updateUser(clientId!, id, rest)
      .then((updateUserResponse: any) => {
        const index: number = state.admins.data!.findIndex((u: User) => u._id === id);

        setState(prevState => ({
          ...prevState,
          admins: {
            ...prevState.admins,
            data: [
              ...prevState.admins.data!.slice(0, index),
              {
                ...updateUserResponse.data
              },
              ...prevState.admins.data!.slice(index + 1, prevState.admins.total!),
            ]
          },
          adminUpdate: {
            ...initialState.adminUpdate,
          }
        }));

        addToast({
          type: 'success',
          content: 'Admin updated'
        });

        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          adminUpdate: {
            ...prevState.adminUpdate,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.admins.data,
    state.adminUpdate,
    clientId,
    addToast
  ]);

  const openUpdateFulfillerModal = useCallback((fulfiller: Fulfiller) => {
    setState(prevState => ({
      ...prevState,
      fulfillerUpdate: {
        ...initialState.fulfillerUpdate,
        modalShown: true,
        fulfiller
      },
      form: {
        ...fulfiller as any
      }
    }));
  }, []);

  const openUpdateAdminModal = useCallback((admin: User) => {
    setState(prevState => ({
      ...prevState,
      adminUpdate: {
        ...initialState.adminUpdate,
        modalShown: true,
        admin
      },
      form: {
        ...admin as any
      }
    }));
  }, []);

  const onRowClick = useCallback((row: User, type: 'fulfiller' | 'user') => {
    if (row._id === userData.user!._id) {
      return navigate({
        pathname: `/${clientId}/back-office/profile`
      });
    }

    navigate({
      pathname: `/${clientId}/back-office/workforce/${row._id}`,
      search: `?type=${type}`
    });
  }, [
    navigate,
    userData,
    clientId
  ]);

  const getFulfillerHeaderConfig = useCallback((): ITableHeader[] => {
    return [
      {
        key: 'name',
        label: 'Name',
        width: '30%',
        type: CellType.Text,
        sortable: true,
        editable: true,
        format: (_, row) => `${row.firstName} ${row.lastName}`
      },
      {
        key: 'email',
        label: 'Email',
        width: '40%',
        type: CellType.Text,
        sortable: true
      },
      {
        key: 'phone',
        label: 'Phone',
        width: '30%',
        type: CellType.Text,
        editable: true,
        isOptional: true,
        format: (phone) => formatPhoneNumber(phone)
      },
      {
        key: RowAction.Key,
        type: CellType.ActionMenu,
        isInternal: true,
        renderIcon: () => (
          <IconButton hoverEffect>
            <StyledMenuDots />
          </IconButton>
        ),
        renderMenu: (
          { row, activeMenu },
          onItemClick: (row: Fulfiller, action: RowAction) => void,
          onClose?: () => void
        ) => {
          if (activeMenu !== row._id) {
            return null;
          }

          return (
            <Popup
              id={row._id}
              left
              bottom
              convertable
              onClose={onClose}
            >
              {({ closePopup }) => (
                <MenuWrapper>
                  {canUpdateUser(clientId!, userData.user!, row) && (
                    <>
                      <MenuItem
                        forMobile
                        onClick={() => {
                          onRowClick(row, 'fulfiller');
                          closePopup();
                        }}
                      >View</MenuItem>
                      <MenuItem
                        onClick={() => {
                          openUpdateFulfillerModal(row);
                          closePopup();
                        }}
                      >Edit</MenuItem>
                      {!row.deactivation && (
                        <MenuItem
                          onClick={() => {
                            onItemClick(row, RowAction.Key)
                            updateFulfiller(row._id, {
                              ...row,
                              revoke: !row.revoke
                            });
                          }}
                        >{row.revoke ? 'Allow' : 'Revoke'} access</MenuItem>
                      )}
                    </>
                  )}
                  {canDeleteUser(clientId!, userData.user!, row) && (
                    !row.deactivation && (
                      <MenuItem
                        onClick={() => {
                          onItemClick(row, RowAction.Delete)
                          closePopup();
                        }}
                      >Delete</MenuItem>
                    )
                  )}
                </MenuWrapper>
              )}
            </Popup>
          );
        }
      }
    ];
  }, [
    clientId,
    userData.user,
    updateFulfiller,
    onRowClick,
    openUpdateFulfillerModal
  ]);

  const getAdministratorHeaderConfig = useCallback((): ITableHeader[] => {
    return [
      {
        key: 'name',
        label: 'Name',
        width: '33.33%',
        type: CellType.Text,
        sortable: true,
        editable: true,
        format: (_, row) => `${row.firstName} ${row.lastName}`
      },
      {
        key: 'email',
        label: 'Email',
        width: '33.33%',
        type: CellType.Text,
        sortable: true,
      },
      {
        key: 'role',
        label: 'Role',
        width: '30%',
        type: CellType.Dropdown,
        dropdownOptions: getRoleOptions(state.admins.fields.role && state.admins.fields.role.enum, userData.user!, true),
        editable: true
      },
      {
        key: RowAction.Key,
        type: CellType.ActionMenu,
        isInternal: true,
        renderIcon: () => (
          <IconButton hoverEffect>
            <StyledMenuDots />
          </IconButton>
        ),
        renderMenu: (
          { row, activeMenu },
          onItemClick: (row: User, action: RowAction) => void,
          onClose?: () => void
        ) => {
          if (activeMenu !== row._id) {
            return null;
          }

          return (
            <Popup
              id={row._id}
              left
              bottom
              convertable
              onClose={onClose}
            >
              {({ closePopup }) => (
                <MenuWrapper>
                  <MenuItem
                    forMobile
                    onClick={() => {
                      closePopup();
                      onRowClick(row, 'user');
                    }}
                  >View</MenuItem>
                  {canUpdateUser(clientId!, userData.user!, row) && (
                    <>
                      <MenuItem
                        onClick={() => {
                          openUpdateAdminModal(row);
                          closePopup();
                        }}
                      >Edit</MenuItem>
                      {!row.deactivation && (
                        <MenuItem
                          onClick={() => {
                            closePopup();
                            onItemClick(row, RowAction.Key)
                            updateAdmin(row._id, {
                              ...row,
                              revoke: !row.revoke
                            });
                          }}
                        >{row.revoke ? 'Allow' : 'Revoke'} access</MenuItem>
                      )}
                    </>
                  )}
                  {canDeleteUser(clientId!, userData.user!, row) && (
                    !row.deactivation && (
                      <MenuItem
                        onClick={() => {
                          onItemClick(row, RowAction.Delete)
                          closePopup();
                        }}
                      >Delete</MenuItem>
                    )
                  )}
                </MenuWrapper>
              )}
            </Popup>
          );
        }
      }
    ];
  }, [
    state.admins,
    clientId,
    userData.user,
    onRowClick,
    updateAdmin,
    openUpdateAdminModal
  ]);

  const createFulfiller = useCallback((payload: any, callback?: (e?: NetworkError) => void) => {
    if (state.fulfillerCreate.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      fulfillerCreate: {
        ...prevState.fulfillerCreate,
        loading: true
      }
    }));

    if (!state.client.data) {
      throw new LocalError('Client data not fetched to get default password');
    }

    const defaultPassword = state.client.data.defaultPassword;

    payload = {
      ...payload,
      password: defaultPassword,
      fulfillerGroups: payload.fulfillerGroups ? payload.fulfillerGroups.map((g: DropdownOption) => g.value) : []
    };

    if (!payload.colour) {
      payload.colour = '#e8a80a';
    }

    gatewayService.createFulfiller(clientId!, payload)
      .then((createFulfillerResponse: any) => {
        setState(prevState => ({
          ...prevState,
          fulfillerCreate: {
            ...prevState.fulfillerCreate,
            id: createFulfillerResponse.data.id,
            loading: false,
            data: createFulfillerResponse.data,
            error: null
          }
        }));

        addToast({
          type: 'success',
          content: 'Fulfiller created'
        });

        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          fulfillerCreate: {
            ...prevState.fulfillerCreate,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.client.data,
    state.fulfillerCreate,
    clientId,
    addToast
  ]);

  const fetchFulfillers = useCallback((opts?: any) => {
    if (state.fulfillers.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      fulfillers: {
        ...prevState.fulfillers,
        loading: true
      }
    }));

    gatewayService.getFulfillers(clientId!, opts)
      .then((fulfillersResponse: any) => {
        setState(prevState => ({
          ...prevState,
          fulfillers: {
            loading: false,
            total: fulfillersResponse.total,
            data: [
              ...JSON.parse(JSON.stringify(fulfillersResponse.data))
            ],
            fields: fulfillersResponse.fields,
            offset: fulfillersResponse.offset,
            error: null
          }
        }));
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          fulfillers: {
            ...prevState.fulfillers,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.fulfillers,
    clientId,
    addToast
  ]);

  const removeFulfiller = useCallback((id: string, callback?: (e?: NetworkError) => void) => {
    if (state.fulfillerDelete.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      fulfillerDelete: {
        ...prevState.fulfillerDelete,
        loading: true
      }
    }));

    gatewayService.deleteFulfiller(clientId!, id)
      .then(() => {
        setState(prevState => ({
          ...prevState,
          fulfillerDelete: {
            ...prevState.fulfillerDelete,
            loading: false,
            error: null
          }
        }));

        addToast({
          type: 'success',
          content: 'Fulfiller deleted'
        });

        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          fulfillerDelete: {
            ...prevState.fulfillerDelete,
            loading: false,
            error
          }
        }));

        if (callback) {
          callback(err);
        }

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.fulfillerDelete,
    clientId,
    addToast
  ]);

  const createAdmin = useCallback((payload: any, callback?: (e?: NetworkError) => void) => {
    if (state.adminCreate.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      adminCreate: {
        ...prevState.adminCreate,
        loading: true
      }
    }));

    if (!state.client.data) {
      throw new LocalError('Client data not fetched to get default password found');
    }

    const defaultPassword = state.client.data.defaultPassword;

    payload = {
      ...payload,
      password: defaultPassword
    };

    gatewayService.createUser(clientId!, payload)
      .then((createUserResponse: any) => {
        setState(prevState => ({
          ...prevState,
          adminCreate: {
            ...prevState.adminCreate,
            id: createUserResponse.data.id,
            loading: false,
            data: createUserResponse.data,
            error: null
          }
        }));

        addToast({
          type: 'success',
          content: 'Admin created'
        });

        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          adminCreate: {
            ...prevState.adminCreate,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.adminCreate,
    state.client.data,
    clientId,
    addToast
  ]);

  const fetchClient = useCallback((opts?: any) => {
    if (state.client.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      client: {
        ...prevState.client,
        loading: true
      }
    }));

    gatewayService.getClient(clientId!)
      .then((clientResponse: any) => {
        setState(prevState => ({
          ...prevState,
          client: {
            loading: false,
            data: clientResponse.data,
            error: null
          }
        }));
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          client: {
            ...prevState.client,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.client,
    clientId,
    addToast
  ]);

  const updateClient = useCallback((payload: Partial<Client>, cb?: () => void) => {
    if (state.client.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      client: {
        ...prevState.client,
        loading: true
      }
    }));

    const {
      primaryAdministrator,
      ...rest
    } = payload;

    gatewayService.updateClient(clientId!, rest)
      .then((updateClientResponse: any) => {
        setState(prevState => ({
          ...prevState,
          client: {
            ...prevState.client,
            loading: false,
            data: updateClientResponse.data
          },
          defaultPassword: {
            ...initialState.defaultPassword
          }
        }));

        if (cb) {
          cb();
        }
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          client: {
            ...prevState.client,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.client,
    clientId,
    addToast
  ]);

  const fetchAdmins = useCallback((opts?: any) => {
    if (state.admins.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      admins: {
        ...prevState.admins,
        loading: true
      }
    }));

    gatewayService.getUsers(clientId!, opts)
      .then((usersResponse: any) => {
        setState(prevState => ({
          ...prevState,
          admins: {
            loading: false,
            total: usersResponse.total,
            data: [
              ...JSON.parse(JSON.stringify(usersResponse.data))
            ],
            fields: usersResponse.fields,
            offset: usersResponse.offset,
            error: null
          }
        }));
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          admins: {
            ...prevState.admins,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.admins,
    clientId,
    addToast
  ]);

  const removeAdmin = useCallback((id: string, callback?: (e?: NetworkError) => void) => {
    if (state.adminDelete.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      adminDelete: {
        ...prevState.adminDelete,
        loading: true
      }
    }));

    gatewayService.deleteUser(clientId!, id)
      .then(() => {
        setState(prevState => ({
          ...prevState,
          adminDelete: {
            ...prevState.adminDelete,
            loading: false,
            error: null
          }
        }));

        addToast({
          type: 'success',
          content: 'Admin deleted'
        });

        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          adminDelete: {
            ...prevState.adminDelete,
            loading: false,
            error
          }
        }));

        if (callback) {
          callback(err);
        }

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.adminDelete,
    clientId,
    addToast
  ]);

  const createFulfillerGroup = useCallback((payload: any, callback?: () => void) => {
    if (state.fulfillerGroupCreate.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      fulfillerGroupCreate: {
        ...prevState.fulfillerGroupCreate,
        loading: true
      }
    }));

    gatewayService.createFulfillerGroup(clientId!, payload)
      .then((createFulfillerGroupResponse: any) => {
        setState(prevState => ({
          ...prevState,
          fulfillerGroupCreate: {
            ...prevState.fulfillerGroupCreate,
            id: createFulfillerGroupResponse.data.id,
            loading: false,
            data: createFulfillerGroupResponse.data,
            error: null
          }
        }));

        addToast({
          type: 'success',
          content: 'Fulfiller group created'
        });

        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          fulfillerGroupCreate: {
            ...prevState.fulfillerGroupCreate,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.fulfillerGroupCreate,
    clientId,
    addToast
  ]);

  const fetchFulfillerGroups = useCallback(() => {
    if (state.fulfillerGroups.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      fulfillerGroups: {
        ...prevState.fulfillerGroups,
        loading: true
      }
    }));

    gatewayService.getFulfillerGroups(clientId!)
      .then((getFulfillerGroupsResponse: any) => {
        setState(prevState => ({
          ...prevState,
          fulfillerGroups: {
            ...prevState.fulfillerGroups,
            loading: false,
            total: getFulfillerGroupsResponse.total,
            data: getFulfillerGroupsResponse.data,
            error: null
          }
        }));
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          fulfillerGroups: {
            ...prevState.fulfillerGroups,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.fulfillerGroups,
    clientId,
    addToast
  ]);

  const updateFulfillerGroup = useCallback((id: string, payload: Partial<FulfillerGroup>, callback?: () => void) => {
    if (state.fulfillerGroupUpdate.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      fulfillerGroupUpdate: {
        ...prevState.fulfillerGroupUpdate,
        id,
        loading: true
      }
    }));

    const {
      _id,
      clientId: payloadClientId,
      created,
      updated,
      fulfillers,
      ...rest
    } = payload;

    gatewayService.updateFulfillerGroup(clientId!, id, rest)
      .then((fulfillerGroupUpdateResponse: any) => {
        const index: number = state.fulfillerGroups.data!.findIndex((g: FulfillerGroup) => g._id === id);

        setState(prevState => ({
          ...prevState,
          fulfillerGroups: {
            ...prevState.fulfillerGroups,
            data: [
              ...prevState.fulfillerGroups.data!.slice(0, index),
              {
                ...fulfillerGroupUpdateResponse.data
              },
              ...prevState.fulfillerGroups.data!.slice(index + 1, prevState.fulfillerGroups.total!),
            ]
          },
          fulfillerGroupUpdate: {
            ...initialState.fulfillerGroupUpdate
          },
          form: {
            ...initialState.form
          }
        }));

        addToast({
          type: 'success',
          content: 'Group updated'
        });

        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          fulfiilerGroupUpdate: {
            ...prevState.fulfillerGroupUpdate,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.fulfillerGroups.data,
    state.fulfillerGroupUpdate,
    clientId,
    addToast
  ]);

  const removeFulfillerGroup = useCallback((id: string, callback?: (e?: NetworkError) => void) => {
    if (state.fulfillerGroupDelete.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      fulfillerGroupDelete: {
        ...prevState.fulfillerGroupDelete,
        loading: true
      }
    }));

    gatewayService.deleteFulfillerGroup(clientId!, id)
      .then(() => {
        setState(prevState => ({
          ...prevState,
          fulfillerGroupDelete: {
            ...initialState.fulfillerGroupDelete,
          }
        }));

        addToast({
          type: 'success',
          content: 'Fulfiller group deleted'
        });

        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          fulfillerGroupDelete: {
            ...prevState.fulfillerGroupDelete,
            loading: false,
            error
          }
        }));

        if (callback) {
          callback(err);
        }

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.fulfillerGroupDelete,
    clientId,
    addToast
  ]);

  const onFulfillerGroupClick = useCallback((e: any, id: string) => {
    setState(prevState => ({
      ...prevState,
      fulfillerGroups: {
        ...prevState.fulfillerGroups,
        activeMenuId: id
      }
    }));
  }, []);

  const closeFulfillerGroupPopupMenu = useCallback(() => {
    // TODO: component still mounted
    setState(prevState => ({
      ...prevState,
      fulfillerGroups: {
        ...prevState.fulfillerGroups,
        activeMenuId: null
      }
    }));
  }, []);

  const addAdmin = useCallback((payload: any) => {
    createAdmin(payload, (e?: NetworkError) => {
      if (!e) {
        fetchAdmins();
      }
    });
  }, [createAdmin, fetchAdmins]);

  const deleteFulfiller = useCallback((id: string) => {
    removeFulfiller(id, (e?: NetworkError) => {
      if (!e) {
        fetchFulfillers();
        fetchFulfillerGroups();
      }
    });
  }, [
    removeFulfiller,
    fetchFulfillers,
    fetchFulfillerGroups
  ]);

  const deleteAdmin = useCallback((id: string) => {
    removeAdmin(id, (e?: NetworkError) => {
      if (!e) {
        fetchAdmins();
      }
    });
  }, [removeAdmin, fetchAdmins]);

  const changePassword = useCallback(() => {
    setState(prevState => {
      if (!prevState.client.data) {
        return prevState;
      }

      const newState = {
        ...prevState,
        defaultPassword: {
          ...prevState.defaultPassword,
          editMode: !prevState.defaultPassword.editMode,
          form: {
            defaultPassword: prevState.client.data.defaultPassword
          }
        }
      };

      return newState;
    });
  }, []);

  const cancelChangePassword = useCallback(() => {
    setState(prevState => {
      const newState = {
        ...prevState,
        defaultPassword: {
          ...initialState.defaultPassword,
        }
      };

      delete newState.errors.defaultPassword;

      return newState;
    });
  }, []);

  const onEditCell = useCallback((row: User | Fulfiller, header: ITableHeader, isInactive: boolean) => {
    // Remove menu
    if (row._id === userData.user!._id && header.key === RowAction.Key) {
      return <span />;
    }

    // Set user context
    if (row._id === userData.user!._id && header.key === 'name') {
      return (
        <Text
          value={(
            <div
              style={{
                ...(isInactive && {
                  color: theme.baseInput.disabled.color
                })
              }}
            >
              <span>{`${row.firstName} ${row.lastName} (You)`}</span>
            </div>
          )}
        />
      );
    }

    // Indicate unverified user
    if (!row.verified.status && header.key === 'name') {
      return (
        <Text
          value={(
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                ...(isInactive && {
                  color: theme.baseInput.disabled.color
                })
              }}
            >
              <span>{`${row.firstName} ${row.lastName}`}</span>
              <span style={{ color: theme.colors.accentTertiary }}>&nbsp;(unverified)</span>
            </div>
          )}
        />
      );
    }

    return null;
  }, [ userData ]);

  const openCreateFulfillerModal = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      fulfillerCreate: {
        ...initialState.fulfillerCreate,
        modalShown: true
      }
    }));
  }, []);

  const openCreateAdminModal = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      adminCreate: {
        ...initialState.adminCreate,
        modalShown: true
      }
    }));
  }, []);

  const closeFulfillerModal = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      fulfillerCreate: {
        ...initialState.fulfillerCreate
      },
      fulfillerUpdate: {
        ...initialState.fulfillerUpdate,
        fulfiller: null
      },
      form: initialState.form
    }));

    resetFulfillerForm();
  }, [resetFulfillerForm]);

  const closeAdminModal = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      adminCreate: {
        ...initialState.adminCreate
      },
      adminUpdate: {
        ...initialState.adminUpdate,
        fulfiller: null
      },
      form: initialState.form
    }));

    resetAdminForm();
  }, [resetAdminForm]);

  const openFulfillerGroupModal = useCallback((id?: string) => {
    const fulfillerGroup = id && state.fulfillerGroups.data && state.fulfillerGroups.data.find(g => g._id === id);

    setState(prevState => ({
      ...prevState,
      ...(!id && {
        fulfillerGroupCreate: {
          ...initialState.fulfillerGroupCreate,
          modalShown: true
        }
      }),
      ...(id && fulfillerGroup && {
        fulfillerGroupUpdate: {
          ...initialState.fulfillerGroupUpdate,
          modalShown: true,
          fulfillerGroup
        },
        form: {
          ...fulfillerGroup as any
        }
      })
    }));
  }, [state.fulfillerGroups.data]);

  const closeFulfillerGroupModal = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      fulfillerGroupCreate: {
        ...initialState.fulfillerGroupCreate
      },
      fulfillerGroupUpdate: {
        ...initialState.fulfillerGroupUpdate
      },
      form: initialState.form
    }));

    resetFulfillerGroupForm();
  }, [resetFulfillerGroupForm]);

  const onChevronClick = useCallback((e: any, id: string) => {
    e.stopPropagation();

    setState(prevState => ({
      ...prevState,
      fulfillerGroups: {
        ...prevState.fulfillerGroups,
        expanded: {
          ...prevState.fulfillerGroups.expanded,
          [id]: !prevState.fulfillerGroups.expanded[id]
        }
      }
    }));
  }, []);

  const onFulfillerGroupSubmit = useCallback((cb: () => void) => {
    validateFulfillerGroupForm({
      form: state.form,
      all: true
    })
      .then(() => {
        if (state.fulfillerGroupCreate.modalShown) {
          createFulfillerGroup(state.form, () => {
            cb();
            fetchFulfillerGroups();
          });
        } else {
          updateFulfillerGroup(state.form._id as string, state.form, () => {
            cb();
          });
        }
      });
  }, [
    state.form,
    createFulfillerGroup,
    updateFulfillerGroup,
    fetchFulfillerGroups,
    state.fulfillerGroupCreate.modalShown,
    validateFulfillerGroupForm
  ]);

  const onFulfillerSubmit = useCallback((isCreate: boolean, cb: () => void) => {
    validateFulfillerForm({
      form: state.form,
      all: true
    })
      .then(() => {
        if (isCreate) {
          createFulfiller(state.form, () => {
            cb();
            fetchFulfillers();
            fetchFulfillerGroups();
          });
        } else {
          updateFulfiller(state.form._id as string, state.form, () => {
            cb();
            fetchFulfillers();
            fetchFulfillerGroups();
          });
        }
      });
  }, [
    state.form,
    fetchFulfillers,
    fetchFulfillerGroups,
    createFulfiller,
    updateFulfiller,
    validateFulfillerForm
  ]);

  const onAdminSubmit = useCallback((isCreate: boolean, cb: () => void) => {
    validateAdminForm({
      form: state.form,
      all: true
    })
      .then(() => {
        if (isCreate) {
          createAdmin(state.form, () => {
            cb();
            fetchAdmins();
          });
        } else {
          updateAdmin(state.form._id as string, state.form, () => {
            cb();
            fetchAdmins();
          });
        }
      });
  }, [
    state.form,
    fetchAdmins,
    createAdmin,
    updateAdmin,
    validateAdminForm
  ]);

  const renderDefaultPassword = useCallback(() => {
    if (state.client.loading) {
      return (
        <Spinner
          color={theme.textColor}
          size={'M'}
        />
      );
    }

    if (!state.client.data) {
      return null;
    }

    const defaultPassword = state.defaultPassword.editMode ? state.defaultPassword.form.defaultPassword : state.client.data.defaultPassword || '';

    return (
      <AdminFormLine marginBottom>
        <Card>
          <AdminFormLine
            column
            centerV
            spaceBetween
            marginBottom
            style={{paddingBottom: '.5rem'}}
          >
            <h3 style={{ marginBottom: 0, marginRight: '2rem' }}>Default password</h3>
            <AdminFormLine marginTop>This is the default password that all team members will be assigned when they are added to your workforce. Upon their first login they will be prompted to set a new password. This is also the password that will be assigned when a users account has had its password reset.</AdminFormLine>
          </AdminFormLine>
          <AdminFormLine
            row
            centerV
          >
            {state.defaultPassword.editMode ? (
              <TextInput
                value={defaultPassword}
                error={state.errors.defaultPassword}
                endAdornment={(
                  <AdminFormLine
                    row
                    centerV
                  >
                    <Button
                      style={{
                        marginLeft: '1rem',
                        marginBottom: 0
                      }}
                      type="button"
                      onClick={cancelChangePassword}
                    >Cancel</Button>
                    <PrimaryButton
                      style={{
                        marginLeft: '1rem',
                        marginBottom: 0
                      }}
                      disabled={
                        !!state.errors.defaultPassword || state.defaultPassword.form.defaultPassword === state.client.data?.defaultPassword
                      }
                      type="submit"
                      onClick={() => {
                        updateClient(state.defaultPassword.form, () => {
                          addToast({
                            type: 'success',
                            content: 'Default password updated'
                          });
                        });
                      }}
                    >Submit</PrimaryButton>
                  </AdminFormLine>
                )}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    updateClient(state.defaultPassword.form, () => {
                      addToast({
                        type: 'success',
                        content: 'Default password updated'
                      });
                    });
                  }
                }}
                onChange={(e) => {
                  setState(prevState => {
                    const newPw = e.target.value;
                    const newState = {
                      ...prevState,
                      defaultPassword: {
                        ...prevState.defaultPassword,
                        form: {
                          defaultPassword: newPw
                        }
                      }
                    };

                    if (!newPw.length || newPw.length < 4) {
                      newState.errors.defaultPassword = 'Password must be at least 4 characters';
                    } else {
                      delete newState.errors.defaultPassword;
                    }

                    return newState;
                  });
                }}
              />
            ) : (
              <Text
                value={(
                  <p style={{
                    marginRight: '1rem'
                  }}>{defaultPassword}</p>
                )}
                endAdornment={(
                  <>
                    <IconButton
                      hoverEffect
                      onClick={() => {
                        copyText(defaultPassword)
                          .then(() => {
                            addToast({
                              type: 'success',
                              content: 'Default password copied'
                            });
                          })
                          .catch(() => {
                            addToast({
                              type: 'error',
                              content: 'Error copying default password. Please copy manually.'
                            });
                          });
                      }}
                    >
                      <StyledCopy />
                    </IconButton>
                    <Button
                      style={{
                        marginLeft: '1rem',
                        marginBottom: 0
                      }}
                      type="button"
                      onClick={() => {
                        changePassword();
                      }}
                    >Change</Button>
                  </>
                )}
              />
            )}
          </AdminFormLine>
        </Card>
      </AdminFormLine>
    );
  }, [
    state.client,
    state.defaultPassword,
    state.errors.defaultPassword,
    changePassword,
    updateClient,
    cancelChangePassword,
    addToast
  ]);

  const toggleExpandAll = useCallback(() => {
    setState(prevState => {
      const expandedMap = state.fulfillerGroups.data!.reduce((acc: any, curr: FulfillerGroup) => {
        if (!acc[curr._id]) {
          acc[curr._id] = expandAll;
        }

        return acc;
      }, {});

      return {
        ...prevState,
        fulfillerGroups: {
          ...prevState.fulfillerGroups,
          expanded: {
            ...expandedMap
          }
        }
      };
    });
  }, [
    state.fulfillerGroups.data,
    expandAll
  ]);

  const renderFulfillerGroups = useCallback(() => {
    if (isListEmpty(state.fulfillerGroups.data)) {
      return (
        <AdminFormLine
          column
          centerH
        >
          <StyledNoDataIcon />
          <AdminFormLine marginBottom>
            <NoDataCaption>Create groups to organise your fulfillers</NoDataCaption>
          </AdminFormLine>
          <Button
            icon={
              <StyledPlus />
            }
            onClick={() => openFulfillerGroupModal()}
          >Add a group</Button>
        </AdminFormLine>
      );
    }

    return (
      state.fulfillerGroups.data!.map((fulfillerGroup: FulfillerGroup) => (
        <DroppableGroup
          key={fulfillerGroup._id}
          clientId={clientId!}
          userData={userData}
          fulfillerGroup={fulfillerGroup}
          expanded={state.fulfillerGroups.expanded}
          activeMenuId={state.fulfillerGroups.activeMenuId}
          sideNavOpen={sideNavOpen}
          fetchFulfillerGroups={fetchFulfillerGroups}
          updateFulfiller={updateFulfiller}
          onChevronClick={onChevronClick}
          onFulfillerGroupClick={onFulfillerGroupClick}
          openFulfillerGroupModal={openFulfillerGroupModal}
          closeFulfillerGroupPopupMenu={closeFulfillerGroupPopupMenu}
          removeFulfillerGroup={removeFulfillerGroup}
        />
      ))
    );
  }, [
    userData,
    sideNavOpen,
    clientId,
    state.fulfillerGroups.data,
    state.fulfillerGroups.expanded,
    state.fulfillerGroups.activeMenuId,
    fetchFulfillerGroups,
    updateFulfiller,
    onChevronClick,
    onFulfillerGroupClick,
    openFulfillerGroupModal,
    closeFulfillerGroupPopupMenu,
    removeFulfillerGroup
  ]);

  const renderDraggableList = useCallback(() => {
    if (isListEmpty(state.fulfillers.data)) {
      return (
        <AdminFormLine
          column
          centerH
        >
          <StyledNoDataIcon />
          <AdminFormLine marginBottom>
            <NoDataCaption>Get started building your team and add your first fulfiller</NoDataCaption>
          </AdminFormLine>
          <Button
            icon={<StyledPlus />}
            disabled={isListEmpty(state.fulfillerGroups.data)}
            onClick={openCreateFulfillerModal}
          >Add a fulfiller</Button>
        </AdminFormLine>
      );
    }

    // return (
    //   state.fulfillers.data.map((fulfiller: Fulfiller) => (
    //     <div
    //       key={fulfiller._id}
    //       style={{ borderBottom: '.1rem solid #e3e8ee' }}
    //     >
    //       <div>
    //         <span>{fulfiller.name}</span>
    //         <span>{fulfiller.email}</span>
    //       </div>
    //     </div>
    //   ))
    // );

    return (
      <Card
        column
        boxShadow
        centerV
        style={{
          marginBottom: '2rem',
          cursor: 'pointer',
          padding: '1rem'
        }}
      >
        {(state.fulfillers.data || []).map((fulfiller) => (
          <AdminFormLine
            column
            key={fulfiller._id}
            style={{ marginBottom: '.75rem' }}
          >
            <DraggableAvatar fulfiller={fulfiller} />
          </AdminFormLine>
        ))}
      </Card>
    );
  }, [
    state.fulfillers.data,
    state.fulfillerGroups.data,
    openCreateFulfillerModal
  ]);

  const renderFulfillers = useCallback(() => {
    if (state.fulfillers.error || state.fulfillerGroups.error) {
      return null;
    }

    return (
      <Card id={'fulfillers'}>
        <AdminFormLine
          row
          centerV
          spaceBetween
          marginBottom
          style={{paddingBottom: '.5rem'}}
        >
          <h3 style={{ marginBottom: 0, marginRight: '2rem' }}>Fulfillers</h3>
        </AdminFormLine>
        <StyledTabs
          activeTab={state.activeFulfillersTab}
          tabHasChanged={(activeFulfillersTab: string, index: number) => {
            setState(prevState => ({
              ...prevState,
              activeFulfillersTab
            }));
          }}
        >
          <div title={'Groups'}>
            <AdminFormLine marginBottom marginTop>Manage your fulfillers by dragging and dropping them into groups. These groups can later be added to a service so that only specialized groups of fulfillers will be considered for job fulfilment.</AdminFormLine>
            <AdminFormLine
              column
              marginTop
            >
              <AdminFormLine
                row
                centerV
                right
                marginBottom
                style={{paddingBottom: '.5rem'}}
              >
                {!isListEmpty(state.fulfillerGroups.data) && (
                  <Button
                    style={{marginBottom: 0, marginRight: '1rem' }}
                    icon={
                      !isMobile ? undefined : expandAll ? (
                        <UnfoldMore style={{ width: '1.75rem', height: 'auto' }} />
                      ) : (
                        <UnfoldLess style={{ width: '1.75rem', height: 'auto' }} />
                      )
                    }
                    onClick={toggleExpandAll}
                    disabled={!state.fulfillerGroups.data || (state.fulfillerGroups.data && state.fulfillerGroups.data.length === 0)}
                  >{isMobile ? '' : ((expandAll ? 'Expand' : 'Collapse').concat(' all'))}</Button>
                )}
                {!isListEmpty(state.fulfillerGroups.data) && canCreateFulfillerGroup(clientId!, userData.user!) && (
                  <Button
                    style={{marginBottom: 0}}
                    icon={<StyledPlus />}
                    onClick={() => openFulfillerGroupModal()}
                  >{isMobile ? '' : 'New group'}</Button>
                )}
              </AdminFormLine>
              {state.fulfillers.loading || state.fulfillerGroups.loading ? (
                <Spinner
                  color={theme.textColor}
                  size={'M'}
                />
              ) : (
                <StyledFormSection
                  cols={3}
                  noBorder={true}
                >
                  <div style={{
                    maxHeight: '50rem',
                    overflow: 'auto'
                  }}>
                    {renderFulfillerGroups()}
                  </div>
                  <AdminFormLine>
                    <StyledHR $alt />
                  </AdminFormLine>
                  <div style={{
                    maxHeight: '50rem',
                    overflow: 'auto'
                  }}>
                    {renderDraggableList()}
                  </div>
                </StyledFormSection>
              )}
            </AdminFormLine>
          </div>
          <div title={'List'}>
            <AdminFormLine
              column
              marginTop
              marginBottom
            >
              <AdminFormLine
                row
                centerV
                right
                marginBottom
                style={{paddingBottom: '.5rem'}}
              >
                {(!isListEmpty(state.fulfillers.data) || (fulfillerFilterApplied && isListEmpty(state.fulfillers.data))) && fulfillerFilterControlMarkup(sideNavOpen, true)}
                {(!isListEmpty(state.fulfillers.data) || (fulfillerFilterApplied && isListEmpty(state.fulfillers.data))) && canCreateUser(clientId!, userData.user!, RoleType.Fulfiller) && (
                  <Button
                    style={{marginBottom: 0, marginLeft: '1rem' }}
                    icon={<StyledPlus />}
                    onClick={openCreateFulfillerModal}
                  >{isMobile ? '' : 'New fulfiller'}</Button>
                )}
              </AdminFormLine>
              {state.fulfillers.loading ? (
                <Spinner
                  color={theme.textColor}
                  size={'M'}
                />
              ) : isListEmpty(state.fulfillers.data) ? (
                <>
                  {fulfillerFilterApplied ? (
                    <AdminFormLine
                      column
                      centerH
                    >
                      <NoDataIcon />
                      <AdminFormLine marginBottom>
                        <NoDataCaption>No fulfillers found. Try a different filter.</NoDataCaption>
                      </AdminFormLine>
                    </AdminFormLine>
                  ) : (
                    <AdminFormLine
                      column
                      centerH
                    >
                      <NoDataIcon />
                      <AdminFormLine marginBottom>
                        <NoDataCaption>Get started building your team and add your first fulfiller</NoDataCaption>
                      </AdminFormLine>
                      <Button
                        icon={<StyledPlus />}
                        disabled={isListEmpty(state.fulfillerGroups.data)}
                        onClick={openCreateFulfillerModal}
                      >Add a fulfiller</Button>
                    </AdminFormLine>
                  )}
                </>
              ) : (
                <Table
                  namespaceKey={'fulfillers'}
                  loading={state.fulfillers.loading}
                  headerConfig={getFulfillerHeaderConfig()}
                  rows={state.fulfillers.data || []}
                  total={state.fulfillers.total!}
                  offset={state.fulfillers.offset}
                  footerContent={fulfillerPaginationMarkup()}
                  rowUpdateState={state.fulfillerUpdate}
                  inactiveRows={getInactiveUserIds(state.fulfillers.data! || [])}
                  sortState={fulfillerSortState}
                  onUpdate={(id: string, data: any) => updateFulfiller(id, data)}
                  onDelete={deleteFulfiller}
                  onRowClick={(row: Fulfiller) => {
                    onRowClick(row, 'fulfiller');
                  }}
                  onEditCell={onEditCell}
                  onSort={fulfillerSetSortState}
                />
              )}
            </AdminFormLine>
          </div>
        </StyledTabs>
      </Card>
    );
  }, [
    sideNavOpen,
    isMobile,
    state.fulfillers,
    state.fulfillerUpdate,
    clientId,
    userData.user,
    state.fulfillerGroups.data,
    state.fulfillerGroups.loading,
    state.fulfillerGroups.error,
    expandAll,
    state.activeFulfillersTab,
    getFulfillerHeaderConfig,
    fulfillerPaginationMarkup,
    fulfillerFilterApplied,
    updateFulfiller,
    deleteFulfiller,
    fulfillerFilterControlMarkup,
    onRowClick,
    onEditCell,
    openCreateFulfillerModal,
    openFulfillerGroupModal,
    renderDraggableList,
    renderFulfillerGroups,
    toggleExpandAll,
    fulfillerSortState,
    fulfillerSetSortState
  ]);

  const onChangeFormField = useCallback((e: any, key: string, value?: any) => {
    const localValue = value || value === 0 ? value : e.target.value;

    setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        [key]: localValue
      }
    }));
  }, []);

  const onChangeFormSelectField = useCallback((key: string, option: any) => {
    const localValue = (option && option.value) || '';

    setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        [key]: localValue
      }
    }));
  }, []);

  const [validateEmailRemotely] = useDebouncedCallback((e: any) => {
    const email: string = e.target.value.trim();

    gatewayService
      .getFulfillers(clientId!)
      .then((fulfillerResponse: any) => {
        // TODO; check that the component is still mounted otherwise exit

        const fulfillerEmails: string[] = fulfillerResponse.data
          .map((f: Fulfiller) => f.email);

        setState(prevState => {
          const emailTaken: boolean = fulfillerEmails.includes(email);

          const newState = {
            ...prevState,
            errors: {
              ...prevState.errors,
              ...(emailTaken && {
                email: 'This email has already been taken'
              })
            }
          };

          if (!emailTaken) {
            delete newState.errors.email;
          }

          return newState;
        });
      })
      .catch(() => {});
  }, 1000);

  const renderFulfillerModal = useCallback(() => {
    if (!state.fulfillerCreate.modalShown && !state.fulfillerUpdate.modalShown) {
      return null;
    }

    if (!state.fulfillerGroups.data) {
      return null;
    }

    const isCreate: boolean = state.fulfillerCreate.modalShown;
    const isUpdate: boolean = state.fulfillerUpdate.modalShown;
    const fulfiller = state.fulfillerUpdate.fulfiller;
    const firstNameHasChanged: boolean = isUpdate && !!fulfiller && state.form.firstName !== fulfiller.firstName;
    const lastNameHasChanged: boolean = isUpdate && !!fulfiller && state.form.lastName !== fulfiller.lastName;
    const emailHasChanged: boolean = isUpdate && !!fulfiller && state.form.email !== fulfiller.email;
    const formGroups: string[] = (valueGroupItems as DropdownOption[]).map(g => g.value);
    const groupsHasChanged: boolean = isUpdate && !!fulfiller && (!formGroups.every((id: string) => fulfiller.fulfillerGroups.includes(id)) || formGroups.length !== fulfiller.fulfillerGroups.length);
    const phoneHasChanged: boolean = isUpdate && !!fulfiller && state.form.phone !== fulfiller.phone;
    const colourHasChanged: boolean = isUpdate && !!fulfiller && state.form.colour !== fulfiller.colour;

    return (
      <Popup
        id={'fulfiller-modal'}
        layered
        convertable
        noPadding
        onClose={closeFulfillerModal}
      >
        {({ closePopup }) => {
          return (
            <ModalWrapper>
              <ModalItem>
                <AdminFormLine marginTop>
                  <h3 style={{marginBottom: 0, marginRight: '2rem' }}>{`${isCreate ? 'Create' : 'Update'}`} fulfiller</h3>
                </AdminFormLine>
              </ModalItem>

              <AdminFormLine marginBottom />
              <ModalBorder />
              <AdminFormLine marginBottom />

              <ModalContentWrapper>
                <ModalItem>
                  <AdminFormLine marginBottom>
                    <TextInput
                      ref={fulfillerNameAutoFocus}
                      hasChanged={firstNameHasChanged}
                      width={'35rem'}
                      value={state.form.firstName as string}
                      error={fulfillerErrors.firstName}
                      label={'First name'}
                      onChange={(e) => onChangeFormField(e, 'firstName')}
                      onKeyUp={(e) => {
                        if (e.key === 'Enter') {
                          onFulfillerSubmit(isCreate, closePopup);
                        }
                      }}
                      onBlur={() => validateFulfillerForm({
                        form: state.form,
                        field: 'firstName'
                      })}
                    />
                  </AdminFormLine>
                </ModalItem>

                <ModalItem>
                  <AdminFormLine marginBottom>
                    <TextInput
                      hasChanged={lastNameHasChanged}
                      width={'35rem'}
                      value={state.form.lastName as string}
                      error={fulfillerErrors.lastName}
                      label={'Last name'}
                      onChange={(e) => onChangeFormField(e, 'lastName')}
                      onKeyUp={(e) => {
                        if (e.key === 'Enter') {
                          onFulfillerSubmit(isCreate, closePopup);
                        }
                      }}
                      onBlur={() => validateFulfillerForm({
                        form: state.form,
                        field: 'lastName'
                      })}
                    />
                  </AdminFormLine>
                </ModalItem>

                <ModalItem>
                  <AdminFormLine marginBottom>
                    {isCreate ? (
                      <TextInput
                        hasChanged={emailHasChanged}
                        width={'35rem'}
                        value={state.form.email as string}
                        error={fulfillerErrors.email || state.errors.email}
                        label={'Email'}
                        onChange={(e) => {
                          onChangeFormField(e, 'email');
                          validateEmailRemotely(e);
                        }}
                        onKeyUp={(e) => {
                          if (e.key === 'Enter') {
                            onFulfillerSubmit(isCreate, closePopup);
                          }
                        }}
                        onBlur={() => validateFulfillerForm({
                          form: state.form,
                          field: 'email'
                        })}
                      />
                    ) : (
                      <Text
                        label={'Email'}
                        value={state.form.email}
                      />
                    )}
                  </AdminFormLine>
                </ModalItem>

                <ModalItem>
                  <AdminFormLine marginBottom>
                    <Dropdown
                      isMulti
                      hasChanged={groupsHasChanged}
                      width={'35rem'}
                      isClearable={true}
                      label={'Groups'}
                      value={valueGroupItems}
                      error={fulfillerErrors.fulfillerGroups}
                      options={groupDropdownItems}
                      onChange={(items: any) => {
                        setState(prevState => ({
                          ...prevState,
                          form: {
                            ...prevState.form,
                            fulfillerGroups: items
                          }
                        }));
                      }}
                      onBlur={() => validateFulfillerForm({
                        form: state.form,
                        field: 'fulfillerGroups'
                      })}
                    />
                  </AdminFormLine>
                </ModalItem>

                <ModalItem>
                  <AdminFormLine marginBottom>
                    <TextInput
                      hasChanged={phoneHasChanged}
                      width={'35rem'}
                      value={stripCountryCodeFromNumber(state.form.phone as string)}
                      error={fulfillerErrors.phone}
                      label={'Contact number'}
                      inputMode="numeric"
                      maxLength={10}
                      inputStartAdornment={
                        <div>+44</div>
                      }
                      onInput={(e: any) => {
                        extractNumericInput(e);

                        setTimeout(() => {
                          e.target.value = stripLeadingZeroFromNumber(e.target.value);
                        }, 1000);
                      }}
                      onChange={(e) => {
                        const localValue = e.target.value;

                        setState(prevState => ({
                          ...prevState,
                          form: {
                            ...prevState.form,
                            phone: addCountryCodeToNumber(localValue)
                          }
                        }));
                      }}
                      onKeyUp={(e) => {
                        if (e.key === 'Enter') {
                          onFulfillerSubmit(isCreate, closePopup);
                        }
                      }}
                      onBlur={() => validateFulfillerForm({
                        form: state.form,
                        field: 'phone'
                      })}
                    />
                  </AdminFormLine>
                </ModalItem>

                <ModalItem>
                  <AdminFormLine marginBottom>
                    <ColourPicker
                      hasChanged={colourHasChanged}
                      width={'35rem'}
                      value={state.form.colour as string || '#E8A80A'}
                      label={t('calendarColour')}
                      onChange={(colour: any) => {
                        setState(prevState => ({
                          ...prevState,
                          form: {
                            ...prevState.form,
                            colour: colour.hex
                          }
                        }));
                      }}
                    />
                  </AdminFormLine>
                </ModalItem>
              </ModalContentWrapper>

              <AdminFormLine marginBottom />
              <ModalBorder />
              <AdminFormLine marginBottom />

              <ModalItem>
                <AdminFormLine right>
                  <Button
                    type={'button'}
                    onClick={() => {
                      closePopup();
                    }}
                    style={{ marginRight: '1rem' }}
                  >Cancel</Button>
                  <PrimaryButton
                    type={'submit'}
                    loading={state.fulfillerCreate.loading || state.fulfillerUpdate.loading}
                    onClick={() => onFulfillerSubmit(isCreate, closePopup)}
                  >{`${isCreate ? 'Submit' : 'Update'}`}</PrimaryButton>
                </AdminFormLine>
              </ModalItem>
            </ModalWrapper>
          );
        }}
      </Popup>
    );
  }, [
    t,
    state.form,
    state.fulfillerGroups.data,
    state.fulfillerCreate,
    state.fulfillerUpdate,
    state.errors,
    fulfillerErrors,
    fulfillerNameAutoFocus,
    groupDropdownItems,
    valueGroupItems,
    onChangeFormField,
    closeFulfillerModal,
    onFulfillerSubmit,
    validateFulfillerForm,
    validateEmailRemotely
  ]);

  const renderFulfillerGroupModal = useCallback(() => {
    if (!state.fulfillerGroupCreate.modalShown && !state.fulfillerGroupUpdate.modalShown) {
      return null;
    }

    const isUpdate: boolean = !!state.fulfillerGroupUpdate.fulfillerGroup;
    const fulfillerGroup = state.fulfillerGroupUpdate.fulfillerGroup;
    const nameHasChanged: boolean = isUpdate && !!fulfillerGroup && state.form.name !== fulfillerGroup.name;

    return (
      <Popup
        id={'group-modal'}
        layered
        convertable
        noPadding
        onClose={closeFulfillerGroupModal}
      >
        {({ closePopup }) => {
          return (
            <ModalWrapper>
              <ModalItem>
                <AdminFormLine marginTop>
                  <h3 style={{marginBottom: 0, marginRight: '2rem' }}>{state.fulfillerGroupCreate.modalShown ? 'Create' : 'Edit'} fulfiller group</h3>
                </AdminFormLine>
              </ModalItem>

              <AdminFormLine marginBottom />
              <ModalBorder />
              <AdminFormLine marginBottom />

              <ModalItem>
                <AdminFormLine marginBottom>
                  <TextInput
                    ref={groupNameAutoFocus}
                    width={'35rem'}
                    hasChanged={nameHasChanged}
                    value={state.form.name as string}
                    error={fulfillerGroupErrors.name}
                    label={'Name'}
                    onChange={(e) => {
                      onChangeFormField(e, 'name');
                    }}
                    onKeyUp={(e) => {
                      if (e.key === 'Enter') {
                        onFulfillerGroupSubmit(closePopup);
                      }
                    }}
                    onBlur={() => validateFulfillerGroupForm({
                      form: state.form,
                      field: 'name'
                    })}
                  />
                </AdminFormLine>
              </ModalItem>

              <AdminFormLine marginBottom />
              <ModalBorder />
              <AdminFormLine marginBottom />

              <ModalItem>
                <AdminFormLine right>
                  <Button
                    type={'button'}
                    onClick={() => {
                      closePopup();
                    }}
                    style={{ marginRight: '1rem' }}
                  >Cancel</Button>
                  <PrimaryButton
                    type={'submit'}
                    loading={state.fulfillerGroupCreate.loading || state.fulfillerGroupUpdate.loading}
                    onClick={() => {
                      onFulfillerGroupSubmit(() => closePopup());
                    }}
                  >{state.fulfillerGroupCreate.modalShown ? 'Submit' : 'Update'}</PrimaryButton>
                </AdminFormLine>
              </ModalItem>
            </ModalWrapper>
          );
        }}
      </Popup>
    );
  }, [
    state.form,
    state.fulfillerGroupCreate,
    state.fulfillerGroupUpdate,
    fulfillerGroupErrors.name,
    onChangeFormField,
    groupNameAutoFocus,
    closeFulfillerGroupModal,
    onFulfillerGroupSubmit,
    validateFulfillerGroupForm
  ]);

  const renderAdminModal = useCallback(() => {
    if (!state.adminCreate.modalShown && !state.adminUpdate.modalShown) {
      return null;
    }

    const isCreate: boolean = state.adminCreate.modalShown;
    const isUpdate: boolean = state.adminUpdate.modalShown;
    const admin = state.adminUpdate.admin;
    const firstNameHasChanged: boolean = isUpdate && !!admin && state.form.firstName !== admin.firstName;
    const lastNameHasChanged: boolean = isUpdate && !!admin && state.form.lastName !== admin.lastName;
    const emailHasChanged: boolean = isUpdate && !!admin && state.form.email !== admin.email;
    const roleHasChanged: boolean = isUpdate && !!admin && state.form.role !== admin.role;

    return (
      <Popup
        id={'admin-modal'}
        layered
        convertable
        noPadding
        onClose={closeAdminModal}
      >
        {({ closePopup }) => {
          return (
            <ModalWrapper>
              <ModalItem>
                <AdminFormLine marginTop>
                  <h3 style={{marginBottom: 0, marginRight: '2rem' }}>{`${isCreate ? 'Create' : 'Update'}`} administrator</h3>
                </AdminFormLine>
              </ModalItem>

              <AdminFormLine marginBottom />
              <ModalBorder />
              <AdminFormLine marginBottom />

              <ModalContentWrapper>
                <ModalItem>
                  <AdminFormLine marginBottom>
                    <TextInput
                      ref={adminNameAutoFocus}
                      hasChanged={firstNameHasChanged}
                      width={'35rem'}
                      value={state.form.firstName as string}
                      error={adminErrors.firstName}
                      label={'First name'}
                      onChange={(e) => onChangeFormField(e, 'firstName')}
                      onKeyUp={(e) => {
                        if (e.key === 'Enter') {
                          onAdminSubmit(isCreate, closePopup);
                        }
                      }}
                      onBlur={() => validateAdminForm({
                        form: state.form,
                        field: 'firstName'
                      })}
                    />
                  </AdminFormLine>
                </ModalItem>

                <ModalItem>
                  <AdminFormLine marginBottom>
                    <TextInput
                      hasChanged={lastNameHasChanged}
                      width={'35rem'}
                      value={state.form.lastName as string}
                      error={adminErrors.lastName}
                      label={'Last name'}
                      onChange={(e) => onChangeFormField(e, 'lastName')}
                      onKeyUp={(e) => {
                        if (e.key === 'Enter') {
                          onAdminSubmit(isCreate, closePopup);
                        }
                      }}
                      onBlur={() => validateAdminForm({
                        form: state.form,
                        field: 'lastName'
                      })}
                    />
                  </AdminFormLine>
                </ModalItem>

                <ModalItem>
                  <AdminFormLine marginBottom>
                    {isCreate ? (
                      <TextInput
                        hasChanged={emailHasChanged}
                        width={'35rem'}
                        value={state.form.email as string}
                        error={adminErrors.email || state.errors.email}
                        label={'Email'}
                        onChange={(e) => {
                          onChangeFormField(e, 'email');
                          validateEmailRemotely(e);
                        }}
                        onKeyUp={(e) => {
                          if (e.key === 'Enter') {
                            onAdminSubmit(isCreate, closePopup);
                          }
                        }}
                        onBlur={() => validateAdminForm({
                          form: state.form,
                          field: 'email'
                        })}
                      />
                    ) : (
                      <Text
                        label={'Email'}
                        value={state.form.email}
                      />
                    )}
                  </AdminFormLine>
                </ModalItem>

                <ModalItem>
                  <AdminFormLine marginBottom>
                    <Dropdown
                      hasChanged={roleHasChanged}
                      width={'35rem'}
                      isClearable={true}
                      label={'Role'}
                      value={state.form.role}
                      error={adminErrors.role}
                      options={getRoleOptions(state.admins.fields.role && state.admins.fields.role.enum, userData.user!, true)}
                      onChange={(option: any) => onChangeFormSelectField('role', option)}
                      onBlur={() => validateAdminForm({
                        form: state.form,
                        field: 'role'
                      })}
                    />
                  </AdminFormLine>
                </ModalItem>
              </ModalContentWrapper>

              <AdminFormLine marginBottom />
              <ModalBorder />
              <AdminFormLine marginBottom />

              <ModalItem>
                <AdminFormLine right>
                  <Button
                    type={'button'}
                    onClick={() => {
                      closePopup();
                    }}
                    style={{ marginRight: '1rem' }}
                  >Cancel</Button>
                  <PrimaryButton
                    type={'submit'}
                    loading={state.adminCreate.loading || state.adminUpdate.loading}
                    onClick={() => onAdminSubmit(isCreate, closePopup)}
                  >{`${isCreate ? 'Submit' : 'Update'}`}</PrimaryButton>
                </AdminFormLine>
              </ModalItem>
            </ModalWrapper>
          );
        }}
      </Popup>
    );
  }, [
    state.form,
    state.adminCreate,
    state.adminUpdate,
    state.admins.fields,
    state.errors,
    userData.user,
    adminNameAutoFocus,
    adminErrors,
    onChangeFormField,
    onChangeFormSelectField,
    closeAdminModal,
    validateAdminForm,
    validateEmailRemotely,
    onAdminSubmit
  ]);

  const renderAdministrators = useCallback(() => {
    if (state.admins.loading) {
      return (
        <Spinner
          color={theme.textColor}
          size={'M'}
        />
      );
    }

    // TODO: show error

    if (!state.admins.data) {
      return null;
    }

    return (
      <AdminFormLine>
        <Card>
          <Table
            namespaceKey={'administrators'}
            loading={state.admins.loading}
            headerConfig={getAdministratorHeaderConfig()}
            rows={state.admins.data! || []}
            total={state.admins.total!}
            offset={state.admins.offset}
            headerContent={(headerActions?: JSX.Element) => (
              <AdminFormLine
                row
                centerV
                spaceBetween
                marginBottom
                style={{paddingBottom: '.5rem'}}
              >
                <h3 style={{
                  marginBottom: 0,
                  marginRight: '2rem'
                }}>Administrators</h3>
                {canCreateUser(clientId!, userData.user!) && (
                  <Button
                    style={{marginBottom: 0, marginLeft: '1rem' }}
                    icon={<StyledPlus />}
                    onClick={openCreateAdminModal}
                  >{isMobile ? '' : 'New'}</Button>
                )}
              </AdminFormLine>
            )}
            footerContent={adminPaginationMarkup()}
            inactiveRows={getInactiveUserIds(state.admins.data! || [])}
            sortState={adminSortState}
            onAdd={addAdmin}
            onDelete={deleteAdmin}
            onRowClick={(row: User) => {
              onRowClick(row, 'user');
            }}
            onEditCell={onEditCell}
            onSort={adminSetSortState}
          />
        </Card>
      </AdminFormLine>
    );
  }, [
    state.admins,
    clientId,
    userData.user,
    adminSortState,
    isMobile,
    getAdministratorHeaderConfig,
    adminPaginationMarkup,
    addAdmin,
    deleteAdmin,
    onRowClick,
    onEditCell,
    adminSetSortState,
    openCreateAdminModal
  ]);

  useEffect(() => {
    if (!state.client.data && !state.client.error) {
      fetchClient();
    }
  }, [
    state.client.data,
    state.client.error,
    fetchClient
  ]);

  useEffect(() => {
    if (!state.fulfillers.data && !state.fulfillers.error) {
      fetchFulfillers();
    }
  }, [
    state.fulfillers.data,
    state.fulfillers.error,
    fetchFulfillers
  ]);

  useEffect(() => {
    if (!state.admins.data && !state.admins.error) {
      fetchAdmins();
    }
  }, [
    state.admins.data,
    state.admins.error,
    fetchAdmins
  ]);

  useEffect(() => {
    if (!state.fulfillerGroups.data && !state.fulfillerGroups.error) {
      fetchFulfillerGroups();
    }
  }, [
    state.fulfillerGroups.data,
    state.fulfillerGroups.error,
    fetchFulfillerGroups
  ]);

  // TODO: show error/forbidden page
  if (isFulfiller(userData.user!)) {
    return null;
  }

  return (
    <Wrapper>
      <PageHeader title={'Workforce'} />
      {renderFulfillers()}
      <AdminFormLine marginBottom />
      {renderAdministrators()}
      <AdminFormLine marginBottom />
      {renderDefaultPassword()}
      {renderFulfillerModal()}
      {renderFulfillerGroupModal()}
      {renderAdminModal()}
    </Wrapper>
  );
};

export default memo(Workforce);
