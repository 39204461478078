import React from 'react';
import { IconProps } from './types';

type Props = React.DetailedHTMLProps<React.DOMAttributes<SVGSVGElement>, SVGSVGElement> &
  IconProps;

const Edit = React.forwardRef<SVGSVGElement, Props>((props, ref) => {
  const { className, ...rest } = props;

  return (
    <svg
      {...rest}
      ref={ref}
      className={className}
      width="2.4rem"
      height="2.4rem"
      viewBox="0 0 24 24"
    >
      <path
        fillRule="evenodd"
        d="M18.648 7.62L17.37 6.34c-.456-.455-1.249-.455-1.703 0l-8.302 8.31-.072.071c-.27.27-.462.61-.555.981l-.73 2.923a.302.302 0 0 0 .365.365l2.92-.73c.37-.093.71-.285.98-.556l.071-.071.001-.001 7.237-7.244 1.065-1.065c.47-.47.47-1.234 0-1.704zm-8.515 8.22h-.976v-.976a.301.301 0 0 0-.301-.302h-.55l6.51-6.516 2.129 2.13-6.511 6.517v-.551a.301.301 0 0 0-.301-.301zm-.985 1.835l-1.195.3a1.81 1.81 0 0 0-.375-.555 1.808 1.808 0 0 0-.553-.375l.298-1.196c.065-.258.197-.493.382-.684h.85v.977c0 .166.134.301.3.301h.977v.85c-.19.185-.426.318-.684.382zm9.074-8.777l-.851.852-2.13-2.13.852-.853a.598.598 0 0 1 .852 0l1.277 1.279a.604.604 0 0 1 0 .852z"
      />
    </svg>
  );
});

export default Edit;
