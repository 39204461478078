import styled from 'styled-components';
import { device } from 'theme/media';

import { EventWithSpan } from './Calendar';
import { eventLabelStyles } from '../../pages/calendar/Calendar.styles';

export * from 'theme/mixins';

const padding = .266;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const DayRow = styled.div`
  display: flex;
  position: relative;
  font-size: 1.5rem;
  // min-height: min-content;
  justify-content: center;
  padding: ${padding}rem 0 ${padding}rem 0;
`;

export const EntriesContainer = styled.div`
  display: flex;
  width: inherit;
  overflow: hidden;
  margin-top: 1rem;
`;

export const Entries = styled.div`
  display: flex;
  width: inherit;
  flex-direction: column;
  min-height: min-content;
`;

export const EntryRow = styled.div<{ span: EventWithSpan['span']; colour?: string | null; isEmpty?: boolean; }>`
  display: flex;
  width: inherit;
  font-size: 1rem;
  font-weight: normal;
  text-align: left;
  flex-shrink: 0;
  background-color: transparent;
  align-items: center;
  padding: 0 ${padding}rem 0 ${padding}rem;
  border: .1rem solid transparent;
  margin: .1rem 0 .1rem 0;
  overflow: hidden;

  ${({ isEmpty }) => isEmpty && `
    opacity: 0;
    color: transparent;
  `};

  ${({ colour }) => colour && `
    :before {
      content: '';
      ${eventLabelStyles(colour)};

      @media ${device.onlyMobile} {
        margin-right: .25rem;
      }
    }
  `};

  ${({ span }) => span && ``};

  ${({ theme, span }) => span && span.isStart && `
    background-color: ${theme.colors.corePrimaryLighter1};
    border-left: .3rem solid black;
    border-top: .1rem solid black;
    border-bottom: .1rem solid black;
  `};

  ${({ theme, span }) => span && span.isMiddle && `
    background-color: ${theme.colors.corePrimaryLighter1};
    border-top: .1rem solid black;
    border-bottom: .1rem solid black;
  `};

  ${({ theme, span }) => span && span.isEnd && `
    background-color: ${theme.colors.corePrimaryLighter1};
    border-right: .3rem solid black;
    border-top: .1rem solid black;
    border-bottom: .1rem solid black;
  `};

  :hover {
    background-color: ${({ theme }) => theme.colors.neutralPrimary1};
  }
`;

export const EntryItems = styled.span`
  position: absolute;
  right: ${padding}rem;
  margin-left: auto;
  font-size: 1.1rem;
`;

export const Time = styled.span`
  margin-right: .5rem;
  flex-shrink: 0;
`;

export const Summary = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ModalSummary = styled.span`
  margin-left: .5rem;
`;

export const ModalTimeSplit = styled.span<{ colour?: string; }>`
  display: flex;
  height: 1.6rem;
  align-items: center;
  justify-contents: center;

  :before {
    content: '';
    width: 1rem;
    height: 1rem;
    border-radius: 50%;

    ${({ colour }) => colour && `
      background-color: ${colour};
    `}

    ${({ colour }) => (colour === '#FFFFFF' || !colour) && `
      border: .1rem solid;
    `}
  }
`;

