import { FC, useEffect, useCallback, useState } from 'react';
import { useNavigate } from 'react-router';

import { gatewayService } from 'services';
import { getQueryParam } from 'utils/url';
import { PageProps } from 'components/AppRouter';
import { Spinner } from 'components/atoms';
import { theme } from 'theme';
import {
  getNetworkErrors
} from 'utils/general';
import {
  NetworkError,
  LocalError
} from 'types/Error';
import ErrorPage from '../../pages/error';

interface State {
  request: {
    loading: boolean;
    success: boolean;
    requested: boolean;
    error: NetworkError | null;
  };
}

const initialState: State = {
  request: {
    loading: false,
    success: false,
    requested: false,
    error: null
  }
};

const AccountingAuthResult: FC<PageProps> = props => {
  const [state, setState] = useState<State>(initialState);

  const navigate = useNavigate();

  let oauthState: string | undefined = getQueryParam('state', window.location.search.split('?')[1]);

  if (oauthState) {
    oauthState = decodeURIComponent(oauthState);
  }

  const clientId: string | undefined = oauthState ? oauthState.split(':').pop() : undefined;
  const code: string | undefined = getQueryParam('code', window.location.search.split('?')[1]);
  const realmId: string | undefined = getQueryParam('realmId', window.location.search.split('?')[1]);

  const postAccountingOaCompletion = useCallback(() => {
    if (
      state.request.loading
      || !clientId
      || !code
      || !realmId
    ) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      request: {
        ...prevState.request,
        loading: true
      }
    }));

    const payload = {
      code,
      realmId,
      state: oauthState
    };

    gatewayService.postAccountingOaCompletion(
      clientId,
      payload
    )
      .then(() => {
        setState(prevState => ({
          ...prevState,
          request: {
            loading: false,
            success: true,
            requested: true,
            error: null
          },
        }));

        navigate({
          pathname: `/${clientId}/back-office/settings`
        });
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          request: {
            ...prevState.request,
            loading: false,
            success: false,
            error
          }
        }));
      });
  }, [
    code,
    realmId,
    oauthState,
    clientId,
    state.request.loading,
    navigate
  ]);

  useEffect(() => {
    postAccountingOaCompletion();
  // eslint-disable-next-line
  }, []);

  if (state.request.loading) {
    return (
      <Spinner
        color={theme.textColor}
        size={'M'}
      />
    );
  }

  if (state.request.error) {
    return (
      <ErrorPage
        errors={[state.request.error]}
      />
    );
  }

  if (!clientId) {
    return (
      <ErrorPage
        errors={[
          new LocalError(
            'Unable to connect accounting platform',
            401,
            { errorCode: '1500' }
          )
        ]}
      />
    );
  }

  if (!code || !realmId) {
    return (
      <ErrorPage
        errors={[
          new LocalError(
            'Unable to connect accounting platform',
            500,
            { errorCode: '1500' }
          )
        ]}
      />
    );
  }

  return null;
};

export default AccountingAuthResult;

