import { Service } from './Service';
import { Job } from './Job';
import { BaseEntity } from './Generic';

export enum Source {
  Stripe = 'stripe',
  External = 'external'
}

export interface Payment extends BaseEntity {
  _id: string;
  date: Date;
  amount: number;
  refunded: boolean;
  paymentId: string;
  jobId: string;
  serviceId: string;
  source: Source;
  clientId: string;
}

export interface PaymentExpanded extends Payment {
  job: Job;
  service: Service;
}

