import React, { FC, memo, useCallback, useState } from 'react';
import { useNavigate } from "react-router";
import {
  object,
  string
} from 'yup';

import { theme } from 'theme';
import { gatewayService } from 'services';
import {
  TextInput,
  PrimaryButton,
  Text,
  Checkbox
} from 'components/atoms';
import {
  PostcodeLookup,
  Background,
  RecaptchaNotice,
  Footer
} from 'components/molecules';
import { NetworkError } from 'types/Error';
import { getNetworkErrors } from 'utils/general';
import { Form } from 'types/UI';
import {
  useValidation,
  useDebouncedCallback
} from 'components/hooks';

import {
  Wrapper,
  StyledCard,
  StyledFormWrapper,
  FormLine,
  StyledHeader,
  HR,
  StyledLink
} from './Signup.styles';

interface State {
  signup: {
    loading: boolean;
    error: NetworkError | null;
  };
  form: Form;
  errors: {
    [key: string]: any;
  };
  termsAndPrivacyChecked: boolean;
}

const initialState: State = {
  signup: {
    loading: false,
    error: null
  },
  form: {},
  errors: {},
  termsAndPrivacyChecked: false
};

const signUpSchema = object({
  firstName: string()
    .required(),
  lastName: string()
    .required(),
  email: string()
    .email()
    .trim()
    .required(),
  password: string()
    .trim()
    .required(),
  businessName: string()
    .label('Business Name')
    .required(),
  businessEmail: string()
    .label('Business Email')
    .email()
    .trim()
    .required(),
  businessAddressLine1: string()
    .label('Business Address Line 1')
    .required(),
  businessAddressLine2: string()
    .label('Business Address Line 2')
    .optional(),
  businessAddressCity: string()
    .label('Business Address City')
    .required(),
  businessAddressPostCode: string()
    .label('Business Address Postcode')
    .required()
});

const Signup: FC<any> = props => {
  const [state, setState] = useState<State>(initialState);

  const navigate = useNavigate();
  const { errors, validate } = useValidation(signUpSchema);

  // handle redirect
  const postSignup = useCallback(() => {
    navigate({
      pathname: '/post-signup'
    });
  }, [navigate]);

  const createClient = useCallback((payload: any) => {
    if (state.signup.loading) {
      return;
    }

    gatewayService.createClient(payload)
      .then((createClientResponse: any) => {
        setState(prevState => ({
          ...prevState,
          login: {
            loading: false,
            error: null
          },
        }));

        postSignup();
      })
      .catch((err) => {
        setState(prevState => ({
          ...prevState,
          signup: {
            ...prevState.signup,
            loading: false,
            error: getNetworkErrors([err])[0]
          }
        }));
      });
  }, [
    state.signup.loading,
    postSignup
  ]);

  const [validateEmailRemotely] = useDebouncedCallback((e: any) => {
    const email: string = e.target.value.trim();

    gatewayService
      .checkEmail(email)
      .then((checkEmailResponse: any) => {
        // TODO; check that the component is still mounted otherwise exit

        setState(prevState => {
          const newState = { ...prevState };

          delete newState.errors.email;

          return newState;
        });
      })
      .catch((err) => {
        setState(prevState => {
          if (err && err.response && err.response.data) {
            const errorCode: number = err.response.data.errorCode;

            switch (errorCode) {
              case 8004:
                const newState = {
                  ...prevState,
                  errors: {
                    ...prevState.errors,
                    email: err.response.data.message
                  }
                };

                return newState;
            };
          }

          return prevState;
        });
      });
  }, 1000);

  const validateTermsAndPrivacy = useCallback((): Promise<void> => {
    return new Promise((resolve) => {
      setState(prevState => {
        const newState = { ...prevState };

        if (!prevState.termsAndPrivacyChecked) {
          newState.errors.termsAndPrivacyChecked = 'Please confirm you\'ve read and reviewed our Terms of use and Privacy policy';
        } else {
          delete newState.errors.termsAndPrivacyChecked;
        }

        if (prevState.termsAndPrivacyChecked) {
          resolve();
        }

        return newState;
      });
    });
  }, []);

  const handleOnChange = useCallback((e: any, key: string) => {
    const value = e.target.value;

    setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        [key]: value
      }
    }));
  }, []);
  
  const handleSubmit = useCallback((e: any) => {
    e.preventDefault();

    Promise.all([
      validateTermsAndPrivacy(),
      validate({
        form: state.form,
        all: true
      })
    ])
      .then(() => {
        setState(prevState => ({
          ...prevState,
          signup: {
            ...prevState.signup,
            loading: true
          }
        }));

        const { firstName, lastName, email, password, ...rest } = state.form;
        const payload = {
          user: {
            firstName,
            lastName,
            email,
            password
          },
          ...rest
        };

        if (process.env.NODE_ENV === 'production') {
          // @ts-ignore
          window['grecaptcha'].enterprise.ready(async () => {
            const action = 'signup';
            // @ts-ignore
            const token = await window['grecaptcha'].enterprise.execute(process.env.REACT_APP_RC_KEY_ID, { action });
            gatewayService.verifyRecaptcha(token, action)
              .then(() => {
                createClient(payload);
              })
              .catch((err) => {
                const error: NetworkError = getNetworkErrors([err])[0];

                setState(prevState => ({
                  ...prevState,
                  signup: {
                    ...prevState.signup,
                    loading: false,
                    error
                  }
                }));
              });
          });

          return;
        }

        createClient(payload);
      });
  }, [
    state,
    createClient,
    validate,
    validateTermsAndPrivacy
  ]);

  let submitError = null;

  if (state.signup.error) {
    const errorMessage: string = state.signup.error.message;

    submitError = (
      <Text
        isError
        value={errorMessage}
      ></Text>
    );
  }

  return (
    <>
      <Background />
      <Wrapper>
        <StyledCard boxShadow>
          <StyledFormWrapper onSubmit={handleSubmit}>
            <FormLine
              center
              marginBottom
            >
              <StyledHeader>Create your account</StyledHeader>
            </FormLine>

            <FormLine marginBottom>
              <HR />
            </FormLine>
            <FormLine marginBottom />

            <FormLine marginBottom>
              <TextInput
                name="first-name"
                width={'100%'}
                label="First name"
                value={state.form.firstName as string}
                error={errors.firstName}
                onChange={(e) => handleOnChange(e, 'firstName')}
                onBlur={() => validate({
                  form: state.form,
                  field: 'firstName'
                })}
              />
            </FormLine>
            <FormLine marginBottom>
              <TextInput
                name="last-name"
                width={'100%'}
                label="Last name"
                value={state.form.lastName as string}
                error={errors.lastName}
                onChange={(e) => handleOnChange(e, 'lastName')}
                onBlur={() => validate({
                  form: state.form,
                  field: 'lastName'
                })}
              />
            </FormLine>
            <FormLine marginBottom>
              <TextInput
                name="email"
                width={'100%'}
                label="Email"
                type="email"
                value={state.form.email as string}
                error={errors.email || state.errors.email}
                onChange={(e) => {
                  handleOnChange(e, 'email');

                  try {
                    const emailIsValid = signUpSchema.validateSyncAt('email', { email: e.target.value });
                    if (emailIsValid) {
                      validateEmailRemotely(e);
                    }
                  } catch(e) {}
                }}
                onBlur={(e) => {
                  validate({
                    form: state.form,
                    field: 'email'
                  });

                  // Sync business email with user email if valid
                  try {
                    const newEmailValue = e.target.value;
                    const emailIsValid = signUpSchema.validateSyncAt('email', { email: newEmailValue });

                    if (emailIsValid) {
                      if (!state.form.businessEmail || ((state.form.businessEmail as string)?.trim().length === 0)) {
                        setState(prevState => ({
                          ...prevState,
                          form: {
                            ...prevState.form,
                            businessEmail: newEmailValue
                          }
                        }));
                      }
                    }
                  } catch (e) {}
                }}
              />
            </FormLine>
            <FormLine marginBottom>
              <TextInput
                name="password"
                width={'100%'}
                label="Password"
                type="password"
                value={state.form.password as string}
                error={errors.password}
                onChange={(e) => handleOnChange(e, 'password')}
                onBlur={() => validate({
                  form: state.form,
                  field: 'password'
                })}
              />
            </FormLine>

            <FormLine marginBottom />
            <FormLine marginBottom>
              <HR />
            </FormLine>
            <FormLine marginBottom />

            <FormLine marginBottom>
              <TextInput
                name="business-name"
                width={'100%'}
                label="Business Name"
                value={state.form.businessName as string}
                error={errors.businessName}
                onChange={(e) => handleOnChange(e, 'businessName')}
                onBlur={() => validate({
                  form: state.form,
                  field: 'businessName'
                })}
              />
            </FormLine>
            <FormLine marginBottom>
              <TextInput
                name="business-email"
                width={'100%'}
                label="Business Email"
                value={state.form.businessEmail as string}
                error={errors.businessEmail}
                onChange={(e) => handleOnChange(e, 'businessEmail')}
                onBlur={() => validate({
                  form: state.form,
                  field: 'businessEmail'
                })}
              />
            </FormLine>
            <FormLine marginBottom>
              <TextInput
                name="business-address-line-1"
                width={'100%'}
                label="Business Address Line 1"
                value={state.form.businessAddressLine1 as string}
                error={errors.businessAddressLine1}
                onChange={(e) => handleOnChange(e, 'businessAddressLine1')}
                onBlur={() => validate({
                  form: state.form,
                  field: 'businessAddressLine1'
                })}
              />
            </FormLine>
            <FormLine marginBottom>
              <TextInput
                name="business-address-line-2"
                width={'100%'}
                label="Business Address Line 2"
                value={state.form.businessAddressLine2 as string}
                error={errors.businessAddressLine2}
                onChange={(e) => handleOnChange(e, 'businessAddressLine2')}
                onBlur={() => validate({
                  form: state.form,
                  field: 'businessAddressLine2'
                })}
              />
            </FormLine>
            <FormLine marginBottom>
              <TextInput
                name="business-address-city"
                width={'100%'}
                label="Business Address City"
                value={state.form.businessAddressCity as string}
                error={errors.businessAddressCity}
                onChange={(e) => handleOnChange(e, 'businessAddressCity')}
                onBlur={() => validate({
                  form: state.form,
                  field: 'businessAddressCity'
                })}
              />
            </FormLine>
            <FormLine marginBottom>
              <PostcodeLookup
                name="business-address-postcode"
                width={'100%'}
                value={state.form.businessAddressPostCode as string}
                error={errors.businessAddressPostCode}
                label={'Business Address Postcode'}
                onChange={(e) => handleOnChange(e, 'businessAddressPostCode')}
                onSelected={(address: any) => {
                  console.log('---address', address);
                  setState(prevState => ({
                    ...prevState,
                    form: {
                      ...prevState.form,
                      businessAddressLine1: address.line_1,
                      businessAddressLine2: address.line_2,
                      businessAddressCity: address.town_or_city
                    }
                  }));
                }}
                onBlur={() => validate({
                  form: state.form,
                  field: 'businessAddressPostCode'
                })}
              />
            </FormLine>

            <FormLine marginBottom />

            <FormLine
              row
              centerV
            >
              <Checkbox
                noIcons
                noErrorText
                error={state.errors.termsAndPrivacyChecked}
                checked={state.termsAndPrivacyChecked}
                onChange={(e) => {
                  setState(prevState => ({
                    ...prevState,
                    termsAndPrivacyChecked: e.target.checked
                  }));

                  validateTermsAndPrivacy();
                }}
              />
              <div>
                <span>I agree with GoBook'em&nbsp;</span>
                <StyledLink to={'/terms'}>Terms of use</StyledLink>
                <span>&nbsp;and&nbsp;</span>
                <StyledLink to={'/privacy'}>Privacy policy</StyledLink>
                <span>.</span>
              </div>
            </FormLine>
            {state.errors.termsAndPrivacyChecked && (
              <FormLine
                marginTop
                marginBottom
              >
                <Text
                  isError
                  value={state.errors.termsAndPrivacyChecked}
                />
              </FormLine>
            )}

            <FormLine marginBottom />

            <FormLine marginBottom>
              <PrimaryButton
                width={'100%'}
                loading={state.signup.loading}
                spinnerColor={theme.colors.coreSecondary}
                disabled={state.signup.loading}
                type="submit"
              >
                Create account
              </PrimaryButton>
            </FormLine>
            {submitError && (
              <FormLine
                center
                marginBottom
              >
                {submitError}
              </FormLine>
            )}

            <FormLine
              center
              marginBottom
            >
              <span>Have an account?&nbsp;</span>
              <StyledLink to={'/login'}>Login</StyledLink>
            </FormLine>

            <RecaptchaNotice />
          </StyledFormWrapper>
        </StyledCard>
        <FormLine
          center
          marginBottom
        >
          <Footer />
        </FormLine>
      </Wrapper>
    </>
  );
};

export default memo(Signup);
