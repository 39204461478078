import React, { FC, useState, useCallback } from 'react';

import { theme } from 'theme';
import { TextInput } from 'components/atoms';
import { gatewayService } from 'services';
import {
  getNetworkErrors
} from 'utils/general';
import { NetworkError } from 'types/Error';

import {
  Wrapper,
  StyledButton,
  StyledDropdown,
  Message
} from './PostcodeLookup.styles';

export interface PostcodeLookupProps {
  name: string;
  width: string;
  value: string;
  label: string;
  error?: string;
  isDisabled?: boolean;
  onChange: (value: any) => void;
  onSelected: (address: any) => void;
  onBlur?: (event: any) => void;
}

interface State {
  postcodeLookup: {
    loading: boolean;
    data: any[] | null;
    error: NetworkError | null;
  };
};

const initialState: State = {
  postcodeLookup: {
    loading: false,
    data: null,
    error: null
  }
};

const PostcodeLookupField: FC<PostcodeLookupProps> = props => {
  const {
    name,
    width,
    value,
    label,
    error,
    isDisabled,
    onChange,
    onSelected,
    onBlur
  } = props;
  const [state, setState] = useState<State>(initialState);

  const onSearch = useCallback((e: any) => {
    e.preventDefault();

    setState(prevState => ({
      ...prevState,
      postcodeLookup: {
        ...prevState.postcodeLookup,
        loading: true
      }
    }));

    gatewayService.postcodeLookup(value)
      .then((res: any) => {
        // console.log('----------address res', res);

        setState({
          ...state,
          postcodeLookup: {
            ...state.postcodeLookup,
            loading: false,
            data: [
              ...res.addresses
                .map((address: any) => {
                  const add = address.formatted_address
                    .map((addressSegment: string) => addressSegment.trim())
                    .filter((addressSegment: string) => addressSegment.length > 0)
                    .join(', ');

                  return {
                    label: add,
                    value: add,
                    raw: address
                  };
                })
            ],
            error: null
          }
        });
      })
      .catch((err: any) => {
        // console.log('----------address err', err);

        setState(prevState => ({
          ...prevState,
          postcodeLookup: {
            ...prevState.postcodeLookup,
            loading: false,
            data: null,
            error: getNetworkErrors([err])[0]
          }
        }));

        // TODO: show error
      });
  }, [value, state]);

  return (
    <Wrapper
      width={width}
      hasResults={!!state.postcodeLookup.data?.length}
    >
      <div>
        <TextInput
          name={name}
          width={'100%'}
          value={value}
          label={label}
          error={error}
          disabled={isDisabled}
          onChange={onChange}
          onBlur={onBlur}
          placeholder="Enter postcode"
          endAdornment={(
            <StyledButton
              loading={state.postcodeLookup.loading}
              disabled={(value && value.length ? value.length <= 3 : true) || isDisabled}
              spinnerColor={theme.colors.corePrimary}
              onClick={onSearch}
            >Search</StyledButton>
          )}
        />
      </div>
      <div>
        {!!state.postcodeLookup.data?.length && (
          <StyledDropdown
            width={'100%'}
            label={'Select address'}
            options={state.postcodeLookup.data}
            onChange={(option: any) => {
              onSelected(option.raw);

              setState({ ...initialState });
            }}
          />
        )}
        {state.postcodeLookup.data?.length === 0 && (
          <Message>No addresses found. Check the postcode or enter the address manually.</Message>
        )}
        {(state.postcodeLookup.error?.message ?? '').toLowerCase().includes('invalid postcode') && (
          <Message error>Please enter a valid postcode.</Message>
        )}
      </div>
    </Wrapper>
  );
};

export default PostcodeLookupField;

