import styled from 'styled-components';

export * from 'theme/mixins';

export const Wrapper = styled.div`
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  // opacity: 0.2;
  opacity: 1;
  overflow: hidden;
  // animation: 20s ease-in-out 0s infinite alternate glow;
  background-color: ${({ theme }) => theme.backgroundColor};
  z-index: -1;
`;

export const RectWrapper = styled.div<{ left?: boolean; right?: boolean }>`
  position: absolute;
  top: 0;
  width: 50vw;

  ${({ left }) => left && `
    left: 0;
    height: 100vh;
  `}

  ${({ right }) => right && `
    right: 0;
  `}
`;

