import styled from 'styled-components';

import { AppBannerType } from 'types/Generic';

export * from 'theme/mixins';

export const Wrapper = styled.div<{ type: AppBannerType }>`
  display: flex;
  width: 100%;
  height: auto;
  padding: 1rem;
  margin-bottom: 2rem;

  ${({ theme, type }) => type === AppBannerType.Info && `
    background-color: #2472A6;
    color: ${theme.colors.neutralSecondary};
  `}

  ${({ theme, type }) => type === AppBannerType.Critical && `
    background-color: #BE3B3B;
    color: ${theme.colors.neutralSecondary};
  `}
`;

