import React, { useState, useMemo, useRef } from 'react';
// tslint:disable-next-line
import CreatableSelect from 'react-select/creatable';
import { Props as SelectProps } from 'react-select';

import { FormField } from '../form';
import { BaseProps } from '../form/types';
import { FormFieldProps } from '../form/FormField';

import { selectStyles } from './CreatableDropdown.styles';
import { getTheme } from './Dropdown.styles';

export interface CreatableDropdownProps extends SelectProps {
  options: Array<{ label: string; value: string }>;
  isClearable?: boolean;
}

export type Props = CreatableDropdownProps & BaseProps & FormFieldProps;

const CreatableDropdown = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    className,
    label,
    value,
    error,
    hasChanged,
    width,
    widthM,
    widthT,
    widthL,
    isClearable,
    options,
    noWidth,
    noMinWidth,
    onFocus,
    onBlur,
    onChange,
    onMenuOpen,
    ...rest
  } = props;

  const [isFocused, setIsFocused] = useState<boolean>(false);
  const onMenuCloseTs = useRef<number>(0);

  const optionIndex = useMemo(() => {
    if (!options) {
      return -1;
    }

    return options.findIndex((opt: any) => JSON.stringify(opt.value) === JSON.stringify(value));
  }, [options, value]);

  return (
    <FormField
      id={rest.id}
      error={error}
      label={label}
      className={className}
      style={rest.styles}
      hasChanged={hasChanged}
      value={value}
      width={width}
      widthM={widthM}
      widthT={widthT}
      widthL={widthL}
      noWidth={noWidth}
      noMinWidth={noMinWidth}
      isFocused={isFocused}
      info={rest.info}
      tooltip={rest.tooltip}
      builder={rest.builder}
      warning={rest.warning}
      endAdornment={rest.endAdornment}
      formFieldStyles={rest.formFieldStyles}
    >
      <CreatableSelect
        {...props}
        className={'select'}
        classNamePrefix={'select'}
        components={{DropdownIndicator: null}}
        value={optionIndex >= 0 ? options[optionIndex] : null}
        styles={selectStyles(isFocused, !!error)}
        theme={(theme) => getTheme(theme)}
        menuPortalTarget={document.body as HTMLElement}
        menuPosition={'fixed'}
        menuShouldScrollIntoView={false}
        // closeMenuOnScroll={e => {
        //   if (e.target && (e.target as HTMLElement).className.includes('MenuList')) {
        //     return false;
        //   }

        //   return true;
        // }}
        options={options}
        onChange={(newValue, actionMeta) => {
          if (onChange) {
            onChange(newValue, actionMeta);
          }
        }}
        onMenuOpen={() => {
          setTimeout(()=>{
            const selectedEl = document.getElementsByClassName("select__option--is-selected")[0];

            if(selectedEl){
              selectedEl.scrollIntoView({ inline: 'start' });
            }
          }, 0);

          if (onMenuOpen) {
            onMenuOpen();
          }
        }}
        onMenuClose={() => {
          onMenuCloseTs.current = Date.now();
        }}
        onFocus={e => {
          setIsFocused(true);

          if (onFocus) {
            onFocus(e as any);
          }
        }}
        onBlur={e => {
          setIsFocused(false);

          if (onBlur) {
            onBlur(e as any);
          }
        }}
      />
    </FormField>
  );
});

export default CreatableDropdown;

