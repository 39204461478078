import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TabHeader = styled.ol`
  display: flex;
  position: relative;
  width: inherit;
  border-bottom: .1rem solid ${({ theme }) => theme.colors.neutralPrimaryDark1}77;
  padding-left: 0;
  overflow-x: auto;
  overflow-y: hidden;
  max-width: ${({ theme }) => `calc(100vw - ${theme.appPadding.value * 4.1}${theme.appPadding.unit})`};
  white-space: nowrap;
  flex-shrink: 0;
`;

export const TabBody = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 1.5rem;

  > div {
    width: inherit;
  }
`;

export const Underline = styled.div<{ left: number; width: number; }>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: .5rem;
  text-align: center;
  transform: translate(0, 0);
  transition: transform ${({ theme }) => theme.animSpeed} ease-in-out, width ${({ theme }) => theme.animSpeed} ease-in-out;
  background-color: ${({ theme }) => theme.colors.corePrimaryDarker};

  ${({ left, width }) => `
    width: ${width}px;
    transform: translate(${left}px, 0);
  `};
`;
