import styled from 'styled-components';

import {
  Tick,
  InfoIcon,
  Warning,
  ErrorIcon,
  Cross
} from 'components/atoms';

export const Wrapper = styled.div`
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  // width: 32rem;    // interfares with minimized pricing rules modal action buttons (bottom right)
  padding: .4rem 1rem .4rem 1rem;
  box-sizing: border-box;
  z-index: ${({ theme }) => theme.layers.highest + 1};
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const ComponentWrapper = styled.div`
  display: flex;
  position: relative;
  max-width: 32rem;
  min-height: 6.4rem;
  max-height: 80rem;
  box-sizing: border-box;
  padding: .8rem;
  margin-bottom: 1rem;
  border-radius: .4rem;
  box-shadow: 0 .1rem 1rem 0 rgb(0 0 0 / 10%), 0 .2rem 1.5rem 0 rgb(0 0 0 / 5%);
  background: ${({ theme }) => theme.colors.neutralSecondary};
  cursor: pointer;
  transform: translateX(340px);
  animation: ${({ theme }) => theme.animDelay} ease-in-out 0s 1 normal forwards toast-in;
`;

export const ComponentWrapperInner = styled.div`
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  padding: .7rem;
  align-items: center;
`;

export const StyledTick = styled(Tick)`
  flex-shrink: 0;
  fill: ${({ theme }) => theme.toast.successColor};
  margin-right: 1rem;
`;

export const StyledInfo = styled(InfoIcon)`
  flex-shrink: 0;
  fill: ${({ theme }) => theme.toast.infoColor};
  margin-right: 1rem;
`;

export const StyledWarning = styled(Warning)`
  flex-shrink: 0;
  fill: ${({ theme }) => theme.toast.warningColor};
  margin-right: 1rem;
`;

export const StyledError = styled(ErrorIcon)`
  flex-shrink: 0;
  fill: ${({ theme }) => theme.toast.errorColor};
  margin-right: 1rem;
`;

export const StyledCross = styled(Cross)`
  width: 1.5rem;
  flex-shrink: 0;
`;

export const RemoveAllWrapper = styled.div`
  display: flex;
  position: fixed;
  right: 35rem;
  width: 5rem;
  height: 5rem;
  bottom: 2.25rem;
  border-radius: 50%;
  transition: box-shadow ${({ theme }) => theme.animSpeed};
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: rgb(60 66 87 / 8%) 0px 3px 9px 0px;
  border: 0.1rem solid ${({ theme }) => theme.card.borderColor};
`;

export const RemoveAllCross = styled(Cross)`
  width: 2.5rem;
`;

export const ContentWrapper = styled.div`
  word-break: break-word;
`;
