import React, { FC } from 'react';
import { usePreview } from 'react-dnd-preview';

import { theme } from 'theme';
import { DragItem } from 'types/UI';
import { DraggableFormField } from '../../pages/booking/Booking';
import { DraggableRow } from '../../pages/adminDashboard/components/Table/TableRow';
import { BuilderStep } from '../../pages/adminDashboard/pages/services/BuilderStep';
import { DraggableAvatar } from '../../pages/adminDashboard/pages/workforce/Workforce';

const DragPreviewLayer: FC = () => {
  const {
    display,
    itemType,
    item,
    style
  } = usePreview();

  if (!display) {
    return null;
  }

  let elem: JSX.Element | null = null;

  switch (itemType) {
    case DragItem.Field:
      elem = (
        <DraggableFormField
          isPreview
          item={item}
        />
      );
      break;
    case DragItem.TableRowItem:
      elem = (
        <DraggableRow
          isPreview
          item={item}
        />
      );
      break;
    case DragItem.Step:
      elem = (
        <BuilderStep
          {...item}
          isPreview
        />
      );
      break;
    case DragItem.Avatar:
      elem = (
        <DraggableAvatar
          isPreview
          item={item}
        />
      );
      break;
  }

  return (
    <div
      style={{
        ...style,
        width: `${item.width}px`,
        zIndex: theme.layers.highest + 1
      }}
    >
      {elem}
    </div>
  );
};

export default DragPreviewLayer;

