import styled from 'styled-components';

const Indicator = styled.div`
  border-radius: 1rem;
  width: 1rem;
  height: 1rem;
  background-color: ${({ theme }) => theme.switch.indicatorColor};
  flex-shrink: 0;
`

const SwitchButton = styled.button<{ disabled?: boolean; toggleState?: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  grid-row: 1;
  align-items: center;
  padding-right: 0.6rem;
  padding-left: ${({ toggleState }) => (toggleState ? '2.4rem' : '0.6rem')};
  border-radius: 1.2rem;
  width: 4.3rem;
  height: 2.4rem;
  margin: 0.1rem 0;
  border: 2px solid transparent;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  background-color: ${({ toggleState, theme }) =>
    toggleState ? theme.switch.trueBackgroundColor : theme.switch.falseBackgroundColor};
  transition: ${({ theme }) => theme.animSpeed} ease-in-out;

  :focus {
    border-color: ${({ theme }) => theme.switch.focus.borderColor};
  }

  :active {
    border-color: ${({ theme }) => theme.switch.active.borderColor};
  }

  :hover {
    background-color: ${({ toggleState, theme }) =>
      toggleState
        ? theme.switch.hover.trueBackgroundColor
        : theme.switch.hover.falseBackgroundColor};
  }

  :disabled {
    background-color: ${({ theme }) => theme.switch.disabled.backgroundColor};

    & > ${Indicator} {
      background-color: ${({ theme }) => theme.switch.disabled.indicatorColor};
      pointer-events: none;
    }
  }
`

const SwitchText = styled.div<{ normalFontSize?: boolean; }>`
  margin: 0 0.4rem;
  font-weight: 600;
  font-size: 1.1rem;
  grid-row: 1;

  ${({ normalFontSize }) => normalFontSize && `
    font-size: unset;
  `}
`

const Container = styled.div<{ textPosition: 'left' | 'right' }>`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;

  & ${SwitchText} {
    grid-column: ${({ textPosition }) => (textPosition === 'left' ? '1' : '2')};
  }

  & ${SwitchButton} {
    grid-column: ${({ textPosition }) => (textPosition === 'left' ? '2' : '1')};
  }
`

export { Container, SwitchButton, Indicator, SwitchText }
