import React from 'react'
export interface CalendarProps {
  className?: string
}

const Calendar = (props: CalendarProps) => (
  <svg className={props.className} width="2.4rem" height="2.4rem" viewBox="0 0 24 24">
    <path
      strokeWidth=".3"
      d="M16.333 7h-1V6h-.667v1H9.333V6h-.666v1h-1C6.747 7 6 7.748 6 8.667v7.666C6 17.253 6.748 18 7.667 18h8.666c.92 0 1.667-.748 1.667-1.667V8.667A1.67 1.67 0 0 0 16.333 7zm1 9.333a1 1 0 0 1-1 1H7.667c-.552 0-1-.45-1-1v-6h10.666v6zm0-6.666H6.667v-1c0-.552.448-1 1-1h1v.666h.666v-.666h5.333v.666h.667v-.666h1a1 1 0 0 1 1 1v1z"
    />
  </svg>
)

export default Calendar
