import React, { FC, memo, useCallback, useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router";
import { format } from 'date-fns';

import {
  FormSection,
  Spinner,
  Text,
  Dropdown
} from 'components/atoms';
import {
  Popup
} from 'components/molecules';
import { User } from 'types/User';
import { Fulfiller } from 'types/Fulfiller';
import {
  NetworkError
} from 'types/Error';
import { ITableHeader, CellType, RowAction } from 'types/Header';
import { AdminPageProps } from 'components/AppRouter';
import { Clients } from './';
import { Table } from '../../../components';
import { useSortFilter } from '../../../hooks';
import {
  getNetworkErrors,
  getInactiveUserIds,
  createDropdownOption,
  formatLabel,
  animateValue,
  getRawCurrencyValue,
  formatCurrency
} from 'utils/general';
import {
  PlatformInsights,
  PlatformOverview,
} from 'types/Generic';
import { Period } from 'types/Generic';
import { gatewayService } from 'services';
import { theme } from 'theme';
import {
  canCreateUser,
  canUpdateUser,
  canDeleteUser,
  getRoleOptions
} from 'config/privileges';
import { RadialChart } from 'components/pages/adminDashboard/components';
import { ISO_FORMAT } from '../../../../../../constants';

import {
  MenuWrapper,
  MenuItem,
  StatNumber
} from 'theme/mixins';
import {
  Wrapper,
  AdminFormLine,
  Card,
  IconButton,
  StyledMenuDots,
  HR,
  SectionSeparator
} from './PlatformDashboard.styles';
import {
  StatSection,
  ChartWrapper,
  RadialChartWrapper
} from '../RootDashboard.styles';

interface Props extends AdminPageProps {
  noClientSelected: boolean;
}

interface State {
  insights: {
    loading: boolean;
    data: PlatformInsights | null;
    error: NetworkError | null;
  };
  overview: {
    loading: boolean;
    data: PlatformOverview | null;
    error: NetworkError | null;
  };
  admins: {
    loading: boolean;
    data: User[] | null;
    total: number | null;
    fields: any;
    offset: number;
    error: NetworkError | null;
  };
  adminCreate: {
    id: string;
    loading: boolean;
    data: User | null;
    error: NetworkError | null;
  };
  adminUpdate: {
    id: string;
    loading: boolean;
    error: NetworkError | null;
  };
  adminDelete: {
    loading: boolean;
    error: NetworkError | null;
  };
  insightPeriod: string;
}

const initialState: State = {
  insights: {
    loading: false,
    data: null,
    error:null
  },
  overview: {
    loading: false,
    data: null,
    error: null
  },
  admins: {
    loading: false,
    total: null,
    data: null,
    fields: {},
    offset: 0,
    error: null
  },
  adminCreate: {
    id: '',
    loading: false,
    data: null,
    error: null,
  },
  adminUpdate: {
    id: '',
    loading: false,
    error: null,
  },
  adminDelete: {
    loading: false,
    error: null
  },
  insightPeriod: 'today'
};

const PlatformDashboard: FC<Props> = props => {
  const {
    userData,
    noClientSelected,
    addToast
  } = props;

  const navigate = useNavigate();
  const [state, setState] = useState<State>(initialState);
  const totalJobsRef = useRef<HTMLDivElement>(null);
  const totalPaymentsRef = useRef<HTMLDivElement>(null);
  const totalEarningsRef = useRef<HTMLDivElement>(null);
  const refundedJobsRef = useRef<HTMLDivElement>(null);
  const totalClientsRef = useRef<HTMLDivElement>(null);
  const [prevInsightPeriod, setPrevInsightPeriod] = useState<string>(state.insightPeriod);

  const { renderPaginationAndSort: adminPaginationMarkup } = useSortFilter({
    listItemId: 'administrator',
    total: state.admins.total,
    offset: state.admins.offset,
    onPrev: (opts) => fetchAdmins(opts),
    onNext: (opts) => fetchAdmins(opts),
    onFilter: (opts) => fetchAdmins(opts)
  });

  const createAdmin = useCallback((payload: any, callback?: (e?: NetworkError) => void) => {
    if (state.adminCreate.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      adminCreate: {
        ...prevState.adminCreate,
        loading: true
      }
    }));

    gatewayService.createUser('all', payload)
      .then((createUserResponse: any) => {
        setState(prevState => ({
          ...prevState,
          adminCreate: {
            id: createUserResponse.data.id,
            loading: false,
            data: createUserResponse.data,
            error: null
          }
        }));

        addToast({
          type: 'success',
          content: 'Admin created'
        });

        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        setState(prevState => ({
          ...prevState,
          adminCreate: {
            ...prevState.adminCreate,
            loading: false,
            error: getNetworkErrors([err])[0]
          }
        }));

        addToast({
          type: 'error',
          content: 'Something went wrong creating an admin account'
        });
      });
  }, [
    state.adminCreate,
    addToast
  ]);

  const fetchAdmins = useCallback((opts?: any) => {
    if (state.admins.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      admins: {
        ...prevState.admins,
        loading: true
      }
    }));

    gatewayService.getUsers('all', opts)
      .then((usersResponse: any) => {
        setState(prevState => ({
          ...prevState,
          admins: {
            loading: false,
            total: usersResponse.total,
            data: [
              ...JSON.parse(JSON.stringify(usersResponse.data))
            ],
            fields: usersResponse.fields,
            offset: usersResponse.offset,
            error: null
          }
        }));
      })
      .catch((err) => {
        setState(prevState => ({
          ...prevState,
          admins: {
            ...prevState.admins,
            loading: false,
            error: getNetworkErrors([err])[0]
          }
        }));

        addToast({
          type: 'error',
          content: 'Something went wrong loading admin accounts'
        });
      });
  }, [
    state.admins,
    addToast
  ]);

  const updateAdmin = useCallback((id: string, payload: Partial<User>) => {
    if (state.adminUpdate.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      adminUpdate: {
        ...prevState.adminUpdate,
        id,
        loading: true,
        payload
      }
    }));

    const {
      _id,
      clientId,
      created,
      updated,
      verified,
      isTrial,
      permissions,
      ...rest
    } = payload;

    gatewayService.updateUser('all', id, rest)
      .then((updateUserResponse: any) => {
        const index: number = state.admins.data!.findIndex((u: User) => u._id === id);

        setState(prevState => ({
          ...prevState,
          admins: {
            ...prevState.admins,
            data: [
              ...prevState.admins.data!.slice(0, index),
              {
                ...updateUserResponse.data
              },
              ...prevState.admins.data!.slice(index + 1, prevState.admins.total!),
            ]
          },
          adminUpdate: {
            ...initialState.adminUpdate,
          }
        }));

        addToast({
          type: 'success',
          content: 'Admin updated'
        });
      })
      .catch((err) => {
        setState(prevState => ({
          ...prevState,
          adminUpdate: {
            ...prevState.adminUpdate,
            loading: false,
            error: getNetworkErrors([err])[0]
          }
        }));

        addToast({
          type: 'error',
          content: 'Something went wrong updating admin account'
        });
      });
  }, [
    state.admins.data,
    state.adminUpdate,
    addToast
  ]);

  const removeAdmin = useCallback((id: string, callback?: (e?: NetworkError) => void) => {
    if (state.adminDelete.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      adminDelete: {
        ...prevState.adminDelete,
        loading: true
      }
    }));

    gatewayService.deleteUser('all', id)
      .then(() => {
        setState(prevState => ({
          ...prevState,
          adminDelete: {
            ...prevState.adminDelete,
            loading: false,
            error: null
          }
        }));

        addToast({
          type: 'success',
          content: 'Admin deleted'
        });

        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        setState(prevState => ({
          ...prevState,
          adminDelete: {
            ...prevState.adminDelete,
            loading: false,
            error: getNetworkErrors([err])[0]
          }
        }));

        if (callback) {
          callback(err);
        }

        addToast({
          type: 'error',
          content: 'Something went wrong removing admin account'
        });
      });
  }, [
    state.adminDelete,
    addToast
  ]);

  const fetchInsights = useCallback(() => {
    if (state.insights.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      insights: {
        ...prevState.insights,
        loading: true
      }
    }));

    gatewayService.getPlatformInsights(
      format(new Date(), ISO_FORMAT),
      state.insightPeriod
    )
      .then((insightResponse: any) => {
        setState(prevState => ({
          ...prevState,
          insights: {
            loading: false,
            data: insightResponse.data,
            error: null
          }
        }));
      })
      .catch((err) => {
        setState(prevState => ({
          ...prevState,
          insights: {
            ...prevState.insights,
            loading: false,
            error: getNetworkErrors([err])[0]
          }
        }));

        addToast({
          type: 'error',
          content: 'Something went wrong loading Insights'
        });
      });
  }, [
    state.insights,
    state.insightPeriod,
    addToast
  ]);

  const fetchOverview = useCallback(() => {
    if (state.overview.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      overview: {
        ...prevState.overview,
        loading: true
      }
    }));

    gatewayService.getPlatformOverview()
      .then((overviewResponse: any) => {
        setState(prevState => ({
          ...prevState,
          overview: {
            loading: false,
            data: overviewResponse.data,
            error: null
          }
        }));
      })
      .catch((err) => {
        setState(prevState => ({
          ...prevState,
          overview: {
            ...prevState.overview,
            loading: false,
            error: getNetworkErrors([err])[0]
          }
        }));

        addToast({
          type: 'error',
          content: 'Something went wrong loading Overview'
        });
      });
  }, [
    state.overview,
    addToast
  ]);

  const getAdministratorHeaderConfig = useCallback((): ITableHeader[] => {
    return [
      {
        key: 'name',
        label: 'Name',
        width: '25%',
        type: CellType.Text,
        sortable: true,
        editable: true
      },
      {
        key: 'email',
        label: 'Email',
        width: '25%',
        type: CellType.Text,
        sortable: true,
      },
      {
        key: 'password',
        label: 'Password',
        width: '25%',
        hide: (editMode?: boolean) => !editMode,
        type: CellType.Password,
        format: (value: string, row: User, index: number, editRow) => {
          if (!Object.keys(row).includes('_id')) {
            return value;
          }

          if (row._id === editRow.id) {
            return value;
          }

          return '••••';
        }
      },
      {
        key: 'role',
        label: 'Role',
        width: '25%',
        type: CellType.Dropdown,
        dropdownOptions: getRoleOptions(state.admins.fields.role && state.admins.fields.role.enum, userData.user!, !noClientSelected),
        editable: true
      },
      {
        key: RowAction.Key,
        type: CellType.ActionMenu,
        isInternal: true,
        renderIcon: () => (
          <IconButton hoverEffect>
            <StyledMenuDots />
          </IconButton>
        ),
        renderMenu: (
          { row, activeMenu },
          onItemClick: (row: User, action: RowAction) => void,
          onClose?: () => void
        ) => {
          if (activeMenu !== row._id) {
            return null;
          }

          return (
            <Popup
              id={row._id}
              left
              bottom
              convertable
              onClose={onClose}
            >
              {({ closePopup }) => (
                <MenuWrapper>
                  {canUpdateUser('all', userData.user!, row) && (
                    <>
                      <MenuItem
                        onClick={() => {
                          onItemClick(row, RowAction.Edit)
                          closePopup();
                        }}
                      >Quick edit</MenuItem>
                      {!row.deactivation && (
                        <MenuItem
                          onClick={() => {
                            onItemClick(row, RowAction.Key)
                            updateAdmin(row._id, {
                              ...row,
                              revoke: !row.revoke
                            });
                          }}
                        >{row.revoke ? 'Allow' : 'Revoke'} access</MenuItem>
                      )}
                    </>
                  )}
                  {canDeleteUser('all', userData.user!, row) && (
                    !row.deactivation && (
                      <MenuItem
                        onClick={() => {
                          onItemClick(row, RowAction.Delete)
                          closePopup();
                        }}
                      >Delete</MenuItem>
                    )
                  )}
                </MenuWrapper>
              )}
            </Popup>
          );
        }
      }
    ];
  }, [
    state.admins.fields,
    userData.user,
    noClientSelected,
    updateAdmin
  ]);

  const addAdmin = useCallback((payload: any) => {
    createAdmin(payload, (e?: NetworkError) => {
      if (!e) {
        fetchAdmins();
      }
    });
  }, [createAdmin, fetchAdmins]);

  const deleteAdmin = useCallback((id: string) => {
    removeAdmin(id, (e?: NetworkError) => {
      if (!e) {
        fetchAdmins();
      }
    });
  }, [removeAdmin, fetchAdmins]);

  const onRowClick = useCallback((row: User, type: 'fulfiller' | 'user') => {
    if (row._id === userData.user!._id) {
      return navigate({
        pathname: `/all/back-office/profile`
      });
    }

    navigate({
      pathname: `/all/back-office/workforce/${row._id}`,
      search: `?type=${type}`
    });
  }, [
    userData,
    navigate
  ]);

  const onEditCell = useCallback((row: User | Fulfiller, header: ITableHeader) => {
    // remove menu
    if (row._id === userData.user!._id && header.key === RowAction.Key ) {
      return <span />;
    }

    // set user context
    if (row._id === userData.user!._id && header.key === 'name' ) {
      return (
        <Text value={`${row.firstName} ${row.lastName} (You)`} />
      );
    }

    return null;
  }, [ userData ]);

  const renderPlatformInsights = useCallback(() => {
    if (state.insights.loading) {
      return (
        <Spinner
          color={theme.textColor}
          size={'M'}
        />
      );
    }

    if (!state.insights.data) {
      return null;
    }

    const totalJobsPCDirection: number = state.insights.data.totalJobsPC.value.startsWith('+') ? 1 : state.insights.data.totalJobsPC.value.startsWith('-') ? -1 : 0;
    const totalPaymentsPCDirection: number = state.insights.data.totalPaymentsPC.value.startsWith('+') ? 1 : state.insights.data.totalPaymentsPC.value.startsWith('-') ? -1 : 0;
    const totalGrossEarningsPCDirection: number = state.insights.data.totalGrossEarningsPC.value.startsWith('+') ? 1 : state.insights.data.totalGrossEarningsPC.value.startsWith('-') ? -1 : 0;
    const refundedJobsPCDirection: number = state.insights.data.refundedJobsPC.value.startsWith('+') ? 1 : state.insights.data.refundedJobsPC.value.startsWith('-') ? -1 : 0;
    const totalClientsPCDirection: number = state.insights.data.totalClientsPC.value.startsWith('+') ? 1 : state.insights.data.totalClientsPC.value.startsWith('-') ? -1 : 0;

    return (
      <AdminFormLine marginBottom>
        <Card>
          <AdminFormLine
            row
            centerV
            spaceBetween
            marginBottom
            style={{paddingBottom: '.5rem'}}
          >
            <h3 style={{ marginBottom: 0, marginRight: '2rem' }}>Insights</h3>
            <Dropdown
              width={'14rem'}
              value={state.insightPeriod}
              options={[
                ...Object
                  .values(Period)
                  .filter(val => !val.includes('last')
                    && !val.includes('yesterday')
                    && !val.includes('next')
                    && !val.includes('tomorrow')
                  )
                  .map(val => createDropdownOption(formatLabel(val), val))
              ]}
              onChange={(option: any) => {
                setState(prevState => ({
                  ...prevState,
                  insightPeriod: (option && option.value) || ''
                }));
              }}
            />
          </AdminFormLine>

          <AdminFormLine
            row
            marginBottom
            inheritWidth
          >
            <StatSection
              mCols={1}
              tCols={2}
            >
              <AdminFormLine column>
                <AdminFormLine>Total jobs</AdminFormLine>
                <StatNumber
                  ref={totalJobsRef}
                  pdDirection={totalJobsPCDirection}
                >
                  <span>{0}</span>
                  <span>{state.insights.data.totalJobsPC.value}</span>
                </StatNumber>
              </AdminFormLine>
              <AdminFormLine column>
                <AdminFormLine>Total payments</AdminFormLine>
                <StatNumber
                  ref={totalPaymentsRef}
                  pdDirection={totalPaymentsPCDirection}
                >
                  <span>{0}</span>
                  <span>{state.insights.data.totalPaymentsPC.value}</span>
                </StatNumber>
              </AdminFormLine>
              <AdminFormLine column>
                <AdminFormLine>Total earnings</AdminFormLine>
                <StatNumber
                  ref={totalEarningsRef}
                  pdDirection={totalGrossEarningsPCDirection}
                >
                  <span>{formatCurrency(0)}</span>
                  <span>{state.insights.data.totalGrossEarningsPC.value}</span>
                </StatNumber>
              </AdminFormLine>
              <AdminFormLine column>
                <AdminFormLine>Refunded jobs</AdminFormLine>
                <StatNumber
                  ref={refundedJobsRef}
                  pdDirection={refundedJobsPCDirection}
                >
                  <span>{0}</span>
                  <span>{state.insights.data.refundedJobsPC.value}</span>
                </StatNumber>
              </AdminFormLine>
            </StatSection>
          </AdminFormLine>

          <AdminFormLine
            row
            marginBottom
          >
            <FormSection
              cols={2}
              noBorder
            >
            </FormSection>
          </AdminFormLine>

          <AdminFormLine marginBottom />
          <AdminFormLine marginBottom />

          <AdminFormLine
            row
            marginBottom
            inheritWidth
          >
            <StatSection mCols={1} lCols={2}>
              <AdminFormLine column>
                <AdminFormLine>Total clients</AdminFormLine>
                <StatNumber
                  ref={totalClientsRef}
                  pdDirection={totalClientsPCDirection}
                >
                  <span>{0}</span>
                  <span>{state.insights.data.totalClientsPC.value}</span>
                </StatNumber>
              </AdminFormLine>
              {state.insights.data.totalJobsPC.current !== 0 && (
                <AdminFormLine column>
                  <AdminFormLine>Percentage of generic and service jobs</AdminFormLine>
                  <ChartWrapper>
                    <RadialChartWrapper>
                      <RadialChart
                        autoFit
                        data={[
                          {
                            label: `Generic ${state.insights.data.genericServiceShare.genericFormatted}`,
                            value: state.insights.data.genericServiceShare.generic,
                            trackWidth: 10,
                            colour: theme.colors.corePrimary
                          },
                          {
                            label: `Service ${state.insights.data.genericServiceShare.serviceFormatted}`,
                            value: state.insights.data.genericServiceShare.service,
                            trackWidth: 10,
                            colour: theme.colors.accentSecondary
                          }
                        ]}
                        gap={5}
                      />
                    </RadialChartWrapper>
                  </ChartWrapper>
                </AdminFormLine>
              )}
            </StatSection>
          </AdminFormLine>
        </Card>
      </AdminFormLine>
    );
  }, [
    state.insightPeriod,
    state.insights.loading,
    state.insights.data
  ]);

  const renderPlatformOverview = useCallback(() => {
    if (state.overview.loading) {
      return (
        <Spinner
          color={theme.textColor}
          size={'M'}
        />
      );
    }

    if (!state.overview.data) {
      return null;
    }

    return (
      <AdminFormLine marginBottom>
        <Card>
          <AdminFormLine
            row
            centerV
            spaceBetween
            marginBottom
            style={{paddingBottom: '.5rem'}}
          >
            <h3 style={{ marginBottom: 0, marginRight: '2rem' }}>Overview</h3>
          </AdminFormLine>

          <AdminFormLine
            row
            marginBottom
            inheritWidth
          >
            <StatSection
              mCols={2}
              lCols={4}
            >
              <AdminFormLine column>
                <AdminFormLine>Total clients</AdminFormLine>
                <StatNumber>{state.overview.data.totalClients}</StatNumber>
              </AdminFormLine>
              <AdminFormLine column>
                <AdminFormLine>Total onboarded clients</AdminFormLine>
                <StatNumber>{state.overview.data.totalOnboardedClients}</StatNumber>
              </AdminFormLine>
              <AdminFormLine column>
                <AdminFormLine>Total jobs</AdminFormLine>
                <StatNumber>{state.overview.data.totalJobs}</StatNumber>
              </AdminFormLine>
              <AdminFormLine column>
                <AdminFormLine>Total pricing rules</AdminFormLine>
                <StatNumber>{state.overview.data.totalPricingRules}</StatNumber>
              </AdminFormLine>
            </StatSection>
          </AdminFormLine>
          <AdminFormLine
            row
            marginBottom
            inheritWidth
          >
            <StatSection
              mCols={2}
              lCols={6}
            >
              <AdminFormLine column>
                <AdminFormLine>Total services</AdminFormLine>
                <StatNumber>{state.overview.data.totalServices}</StatNumber>
              </AdminFormLine>
              <AdminFormLine column>
                <AdminFormLine>Total enabled services</AdminFormLine>
                <StatNumber>{state.overview.data.totalEnabledServices}</StatNumber>
              </AdminFormLine>
              <AdminFormLine column>
                <AdminFormLine>Total users</AdminFormLine>
                <StatNumber>{state.overview.data.totalUsers}</StatNumber>
              </AdminFormLine>
              <AdminFormLine column>
                <AdminFormLine>Total revoked users</AdminFormLine>
                <StatNumber>{state.overview.data.totalRevokedUsers}</StatNumber>
              </AdminFormLine>
              <AdminFormLine column>
                <AdminFormLine>Total fulfillers</AdminFormLine>
                <StatNumber>{state.overview.data.totalFulfillers}</StatNumber>
              </AdminFormLine>
              <AdminFormLine column>
                <AdminFormLine>Total revoked fulfillers</AdminFormLine>
                <StatNumber>{state.overview.data.totalRevokedFulfillers}</StatNumber>
              </AdminFormLine>
            </StatSection>
          </AdminFormLine>
        </Card>
      </AdminFormLine>
    );
  }, [
    state.overview.loading,
    state.overview.data
  ]);


  const renderAdministrators = useCallback(() => {
    if (state.admins.loading) {
      return (
        <Spinner
          color={theme.textColor}
          size={'M'}
        />
      );
    }

    if (!state.admins.data) {
      return null;
    }

    return (
      <AdminFormLine marginBottom>
        <Card>
          <Table
            addable={canCreateUser('all', userData.user!)}
            namespaceKey={'administrators'}
            loading={state.admins.loading}
            headerConfig={getAdministratorHeaderConfig()}
            rows={state.admins.data! || []}
            total={state.admins.total!}
            offset={state.admins.offset}
            headerContent={(headerActions?: JSX.Element) => (
              <AdminFormLine
                row
                centerV
                spaceBetween
                marginBottom
                style={{paddingBottom: '.5rem'}}
              >
                <h3 style={{ marginBottom: 0, marginRight: '2rem' }}>Administrators</h3>
                {headerActions}
              </AdminFormLine>
            )}
            footerContent={adminPaginationMarkup()}
            rowUpdateState={state.adminUpdate}
            inactiveRows={getInactiveUserIds(state.admins.data! || [])}
            onAdd={addAdmin}
            onUpdate={updateAdmin}
            onDelete={deleteAdmin}
            onRowClick={(row: User) => {
              onRowClick(row, 'user');
            }}
            onEditCell={onEditCell}
          />
        </Card>
      </AdminFormLine>
    );
  }, [
    state.admins,
    state.adminUpdate,
    userData.user,
    adminPaginationMarkup,
    addAdmin,
    updateAdmin,
    deleteAdmin,
    onRowClick,
    onEditCell,
    getAdministratorHeaderConfig
  ]);

  useEffect(() => {
    if (!state.admins.data && !state.admins.error) {
      fetchAdmins();
    }
  }, [
    state.admins.data,
    state.admins.error,
    fetchAdmins,
  ]);

  useEffect(() => {
    if (!state.insights.data) {
      return;
    }

    const currentTotalJobs = Number((totalJobsRef.current && (totalJobsRef.current.childNodes[0] as HTMLElement).innerHTML) || '');

    animateValue(
      currentTotalJobs,
      state.insights.data.totalJobsPC.current,
      1250,
      (value: number) => {
        if (totalJobsRef.current) {
          (totalJobsRef.current.childNodes[0] as HTMLElement).innerHTML = `${Math.round(value)}`;
        }
      }
    );
  }, [
    state.insights.data
  ]);

  useEffect(() => {
    if (!state.insights.data) {
      return;
    }

    const currentTotalPayments = Number((totalPaymentsRef.current && (totalPaymentsRef.current.childNodes[0] as HTMLElement).innerHTML) || '');

    animateValue(
      currentTotalPayments,
      state.insights.data.totalPaymentsPC.current,
      1250,
      (value: number) => {
        if (totalPaymentsRef.current) {
          (totalPaymentsRef.current.childNodes[0] as HTMLElement).innerHTML = `${Math.round(value)}`;
        }
      }
    );
  }, [
    state.insights.data
  ]);

  useEffect(() => {
    if (!state.insights.data) {
      return;
    }

    const currentTotalEarnings = Number(getRawCurrencyValue((totalEarningsRef.current && (totalEarningsRef.current.childNodes[0] as HTMLElement).innerHTML) || ''));

    animateValue(
      currentTotalEarnings,
      state.insights.data.totalGrossEarningsPC.current,
      1250,
      (value: number) => {
        if (totalEarningsRef.current) {
          (totalEarningsRef.current.childNodes[0] as HTMLElement).innerHTML = formatCurrency(value);
        }
      }
    );
  }, [
    state.insights.data
  ]);

  useEffect(() => {
    if (!state.insights.data) {
      return;
    }

    const currentRefundedJobs = Number((refundedJobsRef.current && (refundedJobsRef.current.childNodes[0] as HTMLElement).innerHTML) || '');

    animateValue(
      currentRefundedJobs,
      state.insights.data.refundedJobsPC.current,
      1250,
      (value: number) => {
        if (refundedJobsRef.current) {
          (refundedJobsRef.current.childNodes[0] as HTMLElement).innerHTML = `${Math.round(value)}`;
        }
      }
    );
  }, [
    state.insights.data
  ]);

  useEffect(() => {
    if (!state.insights.data) {
      return;
    }

    const currentTotalClients = Number((totalClientsRef.current && (totalClientsRef.current.childNodes[0] as HTMLElement).innerHTML) || '');

    animateValue(
      currentTotalClients,
      state.insights.data.totalClientsPC.current,
      1250,
      (value: number) => {
        if (totalClientsRef.current) {
          (totalClientsRef.current.childNodes[0] as HTMLElement).innerHTML = `${Math.round(value)}`;
        }
      }
    );
  }, [
    state.insights.data
  ]);

  useEffect(() => {
    if ((!state.insights.data && !state.insights.error) || (state.insightPeriod && state.insightPeriod !== prevInsightPeriod)) {
      fetchInsights();
    }
  }, [
    state.insights.data,
    state.insights.error,
    state.insightPeriod,
    prevInsightPeriod,
    fetchInsights
  ]);

  useEffect(() => {
    if (!state.overview.data && !state.overview.error) {
      fetchOverview();
    }
  }, [
    state.overview.data,
    state.overview.error,
    fetchOverview
  ]);

  useEffect(() => {
    if (prevInsightPeriod !== state.insightPeriod) {
      setPrevInsightPeriod(state.insightPeriod);
    }
  }, [
    state.insightPeriod,
    prevInsightPeriod
  ]);

  if (noClientSelected) {
    return (
      <Wrapper>
        {renderPlatformInsights()}
        {renderPlatformOverview()}
        <SectionSeparator>
          <HR $alt />
        </SectionSeparator>
        <Clients {...props} />
        <SectionSeparator>
          <HR $alt />
        </SectionSeparator>
        {renderAdministrators()}
      </Wrapper>
    );
  }

  return null;
};

export default memo(PlatformDashboard);
