import styled from 'styled-components';
import { device } from 'theme/media';

export * from 'theme/mixins';

export const Wrapper = styled.button`
  display: flex;
  outline: none;
  border: none;

  @media ${device.laptop} {
    margin-right: 5rem;
  }
`;

export const ProfileName = styled.div`
  font-size: 1.9rem;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: default;
`;

export const ProfileRole = styled.div`
  cursor: default;
`;

