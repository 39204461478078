import React, { FC, useCallback, useRef, useState } from 'react';

import {
  Wrapper,
  SectionsWrapper,
  SectionWrapper,
  CtaWrapper,
  Indicator,
  Indicators,
  IndicatorPoint,
  StyledPrimaryButton
} from './Carousel.styles';

export enum Dock {
  Top,
  Right,
  Bottom,
  Left
}

export interface CTA {
  label: string;
  dock?: Dock;
}

export interface CarouselProps {
  children: React.ReactNode[];
  ctas: Array<string | CTA>;
  start?: number;
  onSectionComplete?: (index: number, showNext: (isValid: boolean) => void) => void;
}

const Carousel: FC<CarouselProps> = props => {
  const { children, ctas, start, onSectionComplete } = props;

  const sectionsWrapperRef = useRef<HTMLDivElement>(null);
  const [sectionIndex, setSectionIndex] = useState<number>(start || 0);

  const handleCtaClick = useCallback(
    (index: number) => {
      if (onSectionComplete) {
        onSectionComplete(index, (isValid: boolean) => {
          if (isValid) {
            // TODO: show next section
            console.log('--setting section', index + 1);
            setSectionIndex(index + 1);
          }
        });
      }
    },
    [onSectionComplete]
  );

  const renderCTA = useCallback(
    (index: number) => {
      const cta: string | CTA = ctas[index];

      if ((cta as CTA).label) {
        return (
          <CtaWrapper dock={(cta as CTA).dock}>
            <StyledPrimaryButton type="submit" onClick={() => handleCtaClick(index)}>
              {(cta as CTA).label}
            </StyledPrimaryButton>
          </CtaWrapper>
        );
      } else {
        return (
          <CtaWrapper>
            <StyledPrimaryButton type="submit" onClick={() => handleCtaClick(index)}>
              {cta as string}
            </StyledPrimaryButton>
          </CtaWrapper>
        );
      }
    },
    [ctas, handleCtaClick]
  );

  const renderSections = useCallback((sections: React.ReactNode[]) => {
    return sections.map((section: React.ReactNode, index: number) => (
      <SectionWrapper key={index}>
        {section}
        {renderCTA(index)}
      </SectionWrapper>
    ));
  }, [renderCTA]);

  return (
    <Wrapper>
      <SectionsWrapper ref={sectionsWrapperRef} sectionIndex={sectionIndex}>
        {renderSections(children)}
      </SectionsWrapper>
      <Indicator>
        <Indicators index={sectionIndex}>
          {children.map((item, index) => (
            <IndicatorPoint key={index} />
          ))}
        </Indicators>
      </Indicator>
    </Wrapper>
  );
};

export default Carousel;
