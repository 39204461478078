import styled from 'styled-components';

import { device } from 'theme/media';

import {
  Card,
  Header2
} from 'theme/mixins';

export const Wrapper = styled.div`
  display: flex;
  width: inherit;
  flex-direction: column;
  align-items: center;
`;

export const StyledCard = styled(Card)`
  margin-top: 5rem;
  margin-bottom: 5rem;

  @media ${device.mobile} {
    width: 100%;
  }

  @media ${device.mobileL} {
    width: 40rem;
  }

  @media ${device.tablet} {
    width: 50rem;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  background: ${({ theme }) => theme.colors.neutralSecondary};
  z-index: ${({ theme }) => theme.layers.highest};
`;

export const StyledHeader = styled(Header2)`
  margin-top: 0;
`;

