import React, { FC, useState, useCallback, useMemo } from 'react';
import { gatewayService } from 'services';
import {
  Elements
} from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';

import { Client, CustomerPaymentServiceType } from 'types/Client';
import Stripe from './stripe/Stripe';
import { Source } from 'types/Payment';
import { State as BookingState } from '../../Booking';

export interface CommonProviderProps {
  mock: boolean;
  form: any;
  paymentIntent: any;
  processing: boolean;
  isFull: boolean;
  setIsFull: (val: boolean) => void;
  isFocused: boolean;
  setIsFocused: (val: boolean) => void;
  disabled: boolean;
  setDisabled: (val: boolean) => void;
  error: string | null;
  setError: (val: string) => void;
  beforePay: () => Promise<void>;
  onPostPayment: (payload: any, source: Source) => void;
  onBack: () => void;
}

interface Props {
  mock: boolean;
  upsertJobId: string | null;
  paymentIntent: BookingState['paymentIntent'];
  client: Client;
  form: any;
  beforePay: () => Promise<any>;
  afterPay: (error?: any) => void;
  onBack: () => void;
}

const Payment: FC<Props> = props => {
  const {
    mock,
    upsertJobId,
    paymentIntent,
    client,
    form,
    beforePay,
    afterPay,
    onBack
  } = props;

  // TODO: refactor these into 'state' below
  const [isFull, setIsFull] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [processing, setProcessing] = useState<boolean>(false);
  const [succeeded, setSucceeded] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const commonProps = useMemo(() => ({
    mock,
    form,
    paymentIntent,
    processing,
    isFull,
    setIsFull,
    isFocused,
    setIsFocused,
    disabled,
    setDisabled,
    error,
    setError,
    setProcessing,
    succeeded,
    setSucceeded,
    onBack
  }), [
    mock,
    form,
    paymentIntent,
    processing,
    isFull,
    disabled,
    error,
    isFocused,
    succeeded,
    onBack
  ]);

  const stripePromise = useMemo(() => {
    if (!client.customerPaymentService?.key) {
      throw new Error('No customer payment service key');
    }

    return loadStripe(client.customerPaymentService.key);
  }, [client.customerPaymentService])

  const postPaymentOutcome = useCallback((payload: any) => {
    return gatewayService
      .postPaymentOutcome(client._id, payload, true)
      .catch((err: any) => {
        console.log('---error creating payment intent', err);
        //  TODO: log inability to send error
      });
  }, [client._id]);

  const onBeforePayment = useCallback(async () => {
    setProcessing(true);

    try {
      await beforePay();
    } catch(e) {
      setProcessing(false);

      throw e;
    }
  }, [beforePay]);

  const onPostPayment = useCallback(async (payload: any, source: Source) => {
    setProcessing(false);

    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);

      // TODO: maybe add payment id
      await postPaymentOutcome({
        jobId: upsertJobId,
        code: payload.error.code,
        declineCode: payload.error.decline_code
      });

      afterPay(payload.error);
    } else {
      setError(null);
      setSucceeded(true);

      // paymentIntent.id is id for link back to dashboard
      // console.log('----------------------paymentIntent', payload.paymentIntent);
      // TODO: ensure this happens first before cb
      if (payload.paymentIntent) {
        postPaymentOutcome({
          jobId: upsertJobId,
          amount: payload.paymentIntent.amount,
          paymentId: payload.paymentIntent.id,
          status: payload.paymentIntent.status,
          source
        });
      }

      afterPay();
    }
  }, [
    upsertJobId,
    afterPay,
    postPaymentOutcome
  ]);

  const render = useCallback(() => {
    let elem: any;

    switch (client.customerPaymentService?.type) {
      case CustomerPaymentServiceType.Stripe:
        elem = (
          <Elements stripe={stripePromise}>
            <Stripe
              {...commonProps}             
              beforePay={onBeforePayment}
              onPostPayment={onPostPayment}
            />
          </Elements>
        )
        break;
    }

    return elem;
  }, [
    client.customerPaymentService,
    stripePromise,
    commonProps,
    onBeforePayment,
    onPostPayment
  ]);

  return (
    render()
  );
};

export default Payment;

