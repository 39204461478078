import styled from 'styled-components';

import { device } from 'theme/media';

const Container = styled.div<{ noBorder?: boolean; noPadding?: boolean; }>`
  display: flex;
  width: 100%;
  flex-flow: column nowrap;
  padding-bottom: 1.1rem;

  ${({ theme, noBorder }) => !noBorder && `
    border-style: solid;
    border-color: ${theme.colors.neutralPrimaryDark1};
    border-width: 0 0 2px 0;
  `}

  ${({ noPadding }) => noPadding && `
    padding: 0;
  `}
`

const SectionHeader = styled.span`
  display: flex;
  color: ${({ theme }) => theme.textColor};
  font-size: 1.4rem;
  font-weight: 600;
  padding-bottom: 2rem;
`

const SectionBody = styled.div<{ cols?: number }>`
  display: grid;
  grid-template-rows: auto;
  grid-column-gap: 0.8rem;
  grid-row-gap: 1rem;

  fieldset {
    border: none;
  }

  @media ${device.mobile} {
    grid-template-columns: 1fr;
  }

  @media ${device.tablet} {
    ${({ cols }) => cols && cols > 0 && `
      grid-template-columns: ${'1fr '.repeat(cols).trim()};
    `};
  }
`

export { Container, SectionHeader, SectionBody }
