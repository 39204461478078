import React from 'react'

import { IconProps } from './types'

type Props = React.DetailedHTMLProps<React.DOMAttributes<SVGSVGElement>, SVGSVGElement> &
  IconProps;

const Chevron = React.forwardRef<SVGSVGElement, Props>((props, ref) => {
  const { className, ...rest } = props;

  return (
    <svg
      {...rest}
      ref={ref}
      className={className}
      width="2.4rem"
      height="2.4rem"
      viewBox="0 0 24 24"
    >
      <path
        d="M14.639431,14.058574 C14.8606371,14.272142 15.219283,14.272142 15.4404891,14.058574 C15.6616951,13.8450061 15.6616951,13.498744 15.4404891,13.285176 L12.2037258,10.160176 C11.9825198,9.946608 11.6238739,9.946608 11.4026678,10.160176 L8.16590456,13.285176 C7.94469848,13.498744 7.94469848,13.8450061 8.16590456,14.058574 C8.38711062,14.272142 8.74575652,14.272142 8.96696258,14.058574 L11.804746,11.3187774 L14.639431,14.058574 Z"
        transform="translate(11.803197, 12.109375) scale(1, -1) translate(-11.803197, -12.109375) "
      />
    </svg>
  );
});

export default Chevron;

