import React, { FC, memo, useCallback, useState, useMemo, useEffect, useRef } from 'react';

import { theme } from 'theme';
import { ITableHeader, CellType, RowAction } from 'types/Header';
import {
  Spinner,
  Button
} from 'components/atoms';
import { StyledPlus } from 'theme/mixins';
import { ApplicationError } from 'types/Error';

import TableHeader from './TableHeader';
import TableRow from './TableRow';

import { Row, Cell } from './TableRow.styles';
import {
  Wrapper,
  Table,
  TableBody,
  TableFooter
} from './Table.styles';

export interface EditRowState {
  id: string;
  loading: boolean;
  error: ApplicationError | null;
}

export enum SortDirection {
  Ascending = 1,
  Descending = -1
}

export interface SortState {
  key: string;
  label: string;
  direction: SortDirection;
}

export interface TableProps {
  namespaceKey: string;
  headerConfig: ITableHeader[];
  rows: any[];
  total: number;
  loading?: boolean;
  hidePagination?: boolean;
  offset?: number;
  addable?: boolean;
  addButtonElem?: JSX.Element;
  highlightRows?: { [key: string]: string; };
  headerContent?: (headerActions?: JSX.Element) => JSX.Element;
  footerContent?: JSX.Element;
  rowUpdateState?: EditRowState;
  isDraggable?: boolean;
  checkable?: boolean;
  inactiveRows?: string[];
  sortState?: SortState | null;
  onAdd?: (payload: any, newRowIndex: number) => void;
  onUpdate?: (id: string, payload: any, isNew: boolean, index?: number, header?: ITableHeader) => void;
  onDelete?: (id: string) => void;
  onSort?: (sortState: SortState, header: ITableHeader, namespaceKey: string) => void;
  onRowRender?: (row: any, index: number) => React.ReactNode;
  onRowClick?: (row: any) => void;
  onPrev?: (offset: number) => void;
  onNext?: (offset: number) => void;
  onEditCell?: (row: any, header: ITableHeader, isInactive: boolean) => JSX.Element | null;
  onChecked?: (rows: string[]) => void;
}

const initialEditRowState: EditRowState = {
  id: '',
  loading: false,
  error: null
};

const TableComponent: FC<TableProps> = props => {
  const {
    namespaceKey,
    loading,
    headerConfig,
    rows,
    total,
    addable,
    addButtonElem,
    highlightRows,
    headerContent,
    footerContent,
    rowUpdateState,
    sortState,
    onUpdate,
    onDelete,
    onRowRender,
    onRowClick,
    onEditCell,
    onChecked,
    onSort,
    onAdd,
    ...rest
  } = props;

  const [bufferState, setBufferState] = useState<any>({});
  const [newRow, setNewRow] = useState<any | null>(null);
  const [, setRowsBefore] = useState<number>(0);
  const [editRow, setEditRow] = useState<EditRowState>(rowUpdateState ? rowUpdateState : initialEditRowState);
  const [activeMenu, setActiveMenu] = useState<any | null>(null);

  const [subRowsBefore, setSubRowsBefore] = useState<number>(0);
  const tableRef = useRef<HTMLTableElement | null>(null);
  const parentIndex = useRef<number>(-1);
  const parentSubRowsKey = useRef<string>('');
  const addCallbackAdded = useRef<boolean>(false);

  if (!addCallbackAdded.current && addButtonElem) {
    (addButtonElem as any).onButtonClicked = () => {
      onSubmitNewRow();
    };
  }

  const isTableFixed = useMemo((): boolean => headerConfig.every(h => !!h.width), [headerConfig]);

  const columnCount = useMemo(() => {
    let totalCount = headerConfig.length;

    const nestedHeaderIndex: number = headerConfig.findIndex(hc => hc.type === CellType.ArrayMap);

    if (nestedHeaderIndex !== -1) {
      let columns = [ ...headerConfig ];
      columns.splice(nestedHeaderIndex, 1);
      columns = columns.concat(headerConfig[nestedHeaderIndex].subColumns!);
      totalCount = columns.length;
    }

    return totalCount;
  }, [headerConfig]);

  const allRows = useMemo(() => {
    const alteredRows = rows.map((row: any) => {
      if (bufferState[row._id]) {
        return {
          ...row,
          ...bufferState[row._id]
        };
      }

      return row;
    });

    if (newRow && !newRow.isNewSub) {
      return [
        {
          ...newRow
        },
        ...alteredRows
      ];
    }

    return alteredRows;
  }, [
    bufferState,
    rows,
    newRow
  ]);

  const onSubmitNewRow = useCallback(() => {
    const row: any = {
      isNew: true,
      // _id: `${namespaceKey}-${(total + 1)}`
    };

    headerConfig.forEach((h: ITableHeader) => {
      if (h.isInternal) {
        return;
      }

      if (h.newRowPrefix) {
        row[h.key] = `${h.newRowPrefix} ${total + 1}`;
      } else {
        if (h.type === CellType.ArrayMap) {
          row[h.key] = [{}];

          h.subColumns!.forEach((subH: ITableHeader) => {
            if (subH.isInternal) {
              return;
            }

            row[h.key][0][subH.key] = '';
          });
        } else {
          row[h.key] = row[h.key] || '';
        }
      }
    });

    // console.log('--new row', row);

    setNewRow(row);
    setRowsBefore(total);
  }, [
    headerConfig,
    total,
    // namespaceKey
  ]);

  const onRowAction = useCallback((row: any, action: RowAction, header: ITableHeader, state: any) => {
    // console.log('---onRowAction', row, action, header, state);

    const nestedHeader: ITableHeader | undefined = headerConfig.find(hc => hc.type === CellType.ArrayMap);

    switch (action) {
      case RowAction.Add:
        if (nestedHeader) {
          const outerObj: any = {};
          const nestedObj: any = {};

          headerConfig.forEach((h: ITableHeader) => {
            outerObj[h.key] = row[h.key];

            if (h.subColumns) {
              h.subColumns.forEach((subHeader: ITableHeader) => {
                if (subHeader.isInternal) {
                  return;
                }

                nestedObj[subHeader.key] = '';
              });
            }
          });

          const newSubRow = {
            ...outerObj,
            _id: row._id,
            isNewSub: true,
            [nestedHeader.key]: [
              ...row[nestedHeader.key],
              {
                ...nestedObj
              }
            ]
          };

          // console.log('--newSubRow', newSubRow);

          parentSubRowsKey.current = nestedHeader.key;
          setNewRow(newSubRow);
          setSubRowsBefore(row[nestedHeader.key].length);
        }
        break;
      case RowAction.Edit:
        setEditRow({
          ...editRow,
          id: row._id
        });
        break;
      case RowAction.Delete:
        if (nestedHeader) {
          if (newRow) {
            return setNewRow(null);
          }

          if (row.subIndex === 0) {
            if (onDelete) {
              onDelete(row._id);
            }

            return;
          }

          const newArray = [
            ...state[nestedHeader.key]
          ];
          newArray.splice(row.subIndex, 1);

          const newState = {
            ...state,
            [nestedHeader.key]: newArray
          };

          if (onUpdate) {
            return onUpdate(row._id, newState, false);
          }
        }

        if (onDelete) {
          return onDelete(row._id);
        }
        break;
      case RowAction.Cancel:
        if (rowUpdateState) {
          setEditRow({
            ...initialEditRowState
          });
        }

        if (newRow) {
          return setNewRow(null);
        }
        break;
    };
  }, [
    headerConfig,
    editRow,
    newRow,
    rowUpdateState,
    onUpdate,
    onDelete
  ]);

  const onUpdateRow = useCallback((id: string, payload: any, isNew: boolean, index?: number, header?: ITableHeader) => {
    if (onUpdate) {

      onUpdate(id, payload, isNew, index, header);
    }
  }, [onUpdate]);

  const getHeaderContent = useCallback(() => {
    if (!headerContent) {
      return null;
    }

    if (addable) {
      return headerContent(
        <Button
          style={{marginBottom: 0}}
          disabled={newRow}
          icon={<StyledPlus style={{width: '1.3rem', height: '1.3rem' }}/>}
          onClick={onSubmitNewRow}
        >New</Button>
      );
    }

    return headerContent();
  }, [
    addable,
    newRow,
    headerContent,
    onSubmitNewRow
  ]);
  // console.log('--allRows', allRows);

  const setRowHeightsEvenly = useCallback(() => {
    if (onEditCell && tableRef.current) {
      const heights: number[] = [];

      tableRef.current.querySelectorAll('tr')
        .forEach((tr: HTMLTableRowElement) => {
          heights.push(tr.offsetHeight);
        });

      const maxHeight = Math.max(...heights);
      const minHeight = Math.min(...heights);

      if (maxHeight !== minHeight) {
        tableRef.current.querySelectorAll('tr')
          .forEach((tr: HTMLTableRowElement) => {
            tr.style.height = `${maxHeight}px`;
          });
      }
    }
  }, [
    onEditCell
  ]);

  const onCheckRow = useCallback((checked: boolean, row: any, index: number) => {
    setBufferState((prevBufferState: any) => {
      const newBufferState = {
        ...prevBufferState,
        [row._id]: {
          ...(prevBufferState[row._id] && {
            ...prevBufferState[row._id]
          }),
          checked
        }
      };

      if (onChecked) {
        onChecked(Object.entries(newBufferState)
          .filter((entry: [string, any]) => entry[1].checked)
          .map((entry: [string, any]) => entry[0])
        );
      }

      return newBufferState;
    });
  }, [onChecked]);

  const onCheckAllRows = useCallback((checked: boolean) => {
    setBufferState((prevBufferState: any) => {
      const newBufferState = {
        ...prevBufferState,
        ...rows.reduce((acc, row: any) => {
          if (!acc[row._id]) {
            acc[row._id] = {
              checked
            };
          } else {
            acc[row._id] = {
              ...acc[row._id],
              checked
            };
          }

          return acc;
        }, { ...prevBufferState })
      };

      if (onChecked) {
        onChecked(Object.entries(newBufferState)
          .filter((entry: [string, any]) => entry[1].checked)
          .map((entry: [string, any]) => entry[0])
        );
      }

      return newBufferState;
    });
  }, [
    rows,
    onChecked
  ]);

  // reconcile updated row
  useEffect(() => {
    if (rowUpdateState) {
      // external request initiated or exteranl error (update)
      if ((!editRow.loading && rowUpdateState.loading) || (!editRow.error && rowUpdateState.error)) {
        setEditRow(prevState => ({
          ...prevState,
          loading: rowUpdateState.loading,
          error: rowUpdateState.error
        }));
      }
      // success reset
      else if (editRow.loading && !rowUpdateState.loading) {
        setEditRow(prevState => ({
          ...rowUpdateState,
        }));
      }
    }
  }, [
    allRows,
    editRow,
    rowUpdateState
  ]);

  useEffect(() => {
    // console.log('-----reconciliation 0', parentIndex);
    if (parentIndex.current > -1) {
      if (!rows[parentIndex.current]) {
        return;
      }

      const currentSubRowLength: number = rows[parentIndex.current][parentSubRowsKey.current].length;
      const diff: number = currentSubRowLength - subRowsBefore;

      // console.log('-----reconciliation 2', currentSubRowLength, subRowsBefore);
      if (newRow && currentSubRowLength > subRowsBefore && diff === 1) {
        // console.log('-----removing 2');
        parentIndex.current = -1;
        parentSubRowsKey.current = '';
        setNewRow(null);
        setSubRowsBefore(0);
      }
    }
  }, [rows, newRow, subRowsBefore]);

  useEffect(() => {
    // not really needed anymore
    // setRowHeightsEvenly();
  }, [
    setRowHeightsEvenly
  ]);

  if (loading) {
    return (
      <Wrapper>
        <Spinner
          color={theme.textColor}
          size={'M'}
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper key={namespaceKey}>
      {getHeaderContent()}
      <Table
        ref={tableRef}
        isFixed={isTableFixed}
        {...rest}
      >
        <TableHeader
          {...props}
          isEditMode={newRow || editRow.id.length > 0}
          onCheckAllRows={onCheckAllRows}
          sortState={sortState}
        />
        <TableBody>
          {allRows && allRows.length > 0 && allRows
            .map((row: any, index: number) => {
              if (onRowRender) {
                return onRowRender(row, index);
              } else {
                const key: string = row._id || `${index}`;

                return (
                  <TableRow
                    {...props}
                    key={key}
                    index={index}
                    row={row}
                    editRow={editRow}
                    isTableFixed={isTableFixed}
                    highlight={(highlightRows && highlightRows[row._id]) || undefined}
                    isEditMode={row.isNew || editRow.id === row._id}
                    isEditModeGlobal={newRow || editRow.id === row._id}
                    onUpdateRow={onUpdateRow}
                    headerConfig={headerConfig}
                    activeMenu={activeMenu}
                    onRowClick={onRowClick}
                    onRowAction={onRowAction}
                    onCheckRow={onCheckRow}
                    onShowMenu={(id) => setActiveMenu(id)}
                  />
                );
              }
            })}
          {allRows && allRows.length === 0 && (
            <Row>
              <Cell
                centre
                empty
                colSpan={columnCount}
              >Nothing to show</Cell>
            </Row>
          )}
        </TableBody>
      </Table>
      <TableFooter>
        {footerContent}
      </TableFooter>
    </Wrapper>
  );
};

export default memo(TableComponent);
