import styled from 'styled-components';
import { device } from 'theme/media';

import {
  Building
} from 'components/atoms';
import { Card } from 'theme/mixins';
export * from 'theme/mixins';

export const Wrapper = styled.div`
  display: flex;
  width: inherit;
  height: 100%;
  flex-direction: column;
`;

export const ServiceCard = styled(Card)`
  margin-bottom: 2rem;
  cursor: pointer;

  @media ${device.mobile} {
    padding: 2rem;
  }

  @media ${device.mobileXL} {
    padding: 2rem 3rem;
  }
`;

export const StatusIndicator = styled.i<{ isEnabled: boolean; }>`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ isEnabled }) => `
    :after {
      content: '';
      display: block;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background-color: ${isEnabled ? 'green' : 'red'}
    }
  `}
`;

// eslint-disable-next-line
export const ServiceCardColumn = styled.div<{
  grow?: boolean;
  row?: boolean;
  maxWidth?: boolean;
  spaceAround?: boolean;
  alignLeft?: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  word-break: break-word;

  ${({ grow }) => grow && `
    flex: 1;
  `}

  ${({ row }) => row && `
    flex-direction: row;
  `}

  ${({ maxWidth }) => maxWidth && `
    max-width: 15rem;
  `}

  ${({ spaceAround }) => spaceAround && `
    justify-content: space-around;
  `}

  ${({ alignLeft }) => alignLeft && `
    align-items: start;
  `}

  :not(:last-child) {
    margin-right: 2rem;
  }
`;

export const ServiceCardOuter = styled.div`
  display: flex;
`;

export const PricingRulesCaption = styled.p`
  text-align: center;
`;

export const StyledBuilding = styled(Building)`
  width: 20rem;
  height: auto;
`;

