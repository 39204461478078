import React, { FC, useRef, useState, useCallback, useEffect, useMemo } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import type { XYCoord, Identifier } from 'dnd-core'
import { nanoid } from 'nanoid';

import {
  TextInput,
  TextArea,
  NumericInput,
  Dropdown,
  MultiCreatableDropdown,
  Button
} from 'components/atoms';
import {
  Popup,
  DateTimeFields
} from 'components/molecules';
import {
  IconButton,
  StyledMenuDots,
  MenuWrapper,
  MenuItem,
  AdminFormLine
} from 'theme/mixins';
import {
  hasValue,
  createDropdownOption,
  formatLabel,
  getFieldName
} from 'utils/general';
import { DragItem } from 'types/UI';
import {
  Step,
  Field,
  FieldType
} from 'types/Service';
import { theme } from 'theme';
import { DraggableFormField, DropItemProps } from '../../../booking/Booking';

import {
  Wrapper,
  StepWrapper,
  StepFieldsInner,
  StyledUnfoldLess,
  StyledUnfoldMore,
  StepFields,
  StepFieldsOuter,
  StyledField,
  FieldEditorWrapper
} from './BuilderStep.styles';

interface BuilderStepProps {
  step: Step;
  index: number;
  isSelected: boolean;
  isExpanded: boolean;
  canUpdateService: boolean;
  isLast: boolean;
  fields: any;
  selectedField: {
    stepIndex: number;
    fieldIndex: number;
    field: Field | null;
  };
  showFieldsAsText: boolean;
  onSelect: (stepIndex: number) => void;
  onExpand: (id: string) => void;
  onRename: (step: Step) => void;
  onDelete: (index: number) => void;
  onSelectField: (stepIndex: number, fieldIndex: number, field: Field | null) => void;
  onAddField: (stepIndex: number, field: Field) => void;
  onEditField: (stepIndex: number, fieldIndex: number, field: Field | Partial<Field>) => void;
  onDeleteField: (stepIndex: number, fieldIndex: number) => void;
  isPreview?: boolean;
  canDrag?: boolean;
  onReOrder?: (dragIndex: number, hoverIndex: number) => void;
  onReOrderField?: (stepIndex: number, dragIndex: number, hoverIndex: number) => void;
  onMoveFieldBetweenSteps?: (sourceStepIndex: number, targetStepIndex: number, fieldIndex: number) => void;
}

interface State {
  popupOpen: boolean;
  fieldEditor: {
    field: Field | Partial<Field> | null;
    selectedStepIndex: number;
    selectedFieldIndex: number;
  };
  selectedField: Field | null;
}

const initialState: State = {
  popupOpen: false,
  fieldEditor: {
    field: null,
    selectedStepIndex: -1,
    selectedFieldIndex: -1
  },
  selectedField: null
};

export const defaultGenericStep = {
  name: 'details',
  label: 'Details',
  fields: [
    {
      id: nanoid(5),
      name: 'description-field',
      label: 'Description',
      type: FieldType.LongText,
      hint: 'Enter details needed to complete this job'
    }
  ]
};

export const BuilderStep: FC<BuilderStepProps> = (props) => {
  const {
    step,
    index,
    isSelected,
    isExpanded,
    canUpdateService,
    isLast,
    fields,
    selectedField,
    showFieldsAsText,
    onSelect,
    onExpand,
    onRename,
    onDelete,
    onSelectField,
    onAddField,
    onEditField,
    onDeleteField,
    isPreview,
    canDrag,
    onReOrder,
    onReOrderField,
    onMoveFieldBetweenSteps
  } = props;

  const [state, setState] = useState<State>(initialState);
  const ref = useRef<HTMLDivElement>(null);
  const lastDragField = useRef<Field | null>(null);

  const supportedFieldTypeDropdownOptions = useMemo(() => {
    return fields.steps.type.enum.filter((item: string) => {
      return [
        'short-text',
        'long-text',
        'number',
        'dropdown'
      ].includes(item);
    });
  }, [fields]);

  const onChangeNewField = useCallback((e: any, key: string, fieldIndex: number) => {
    const localValue = e.target.value;

    setState(prevState => {
      const newState = {
        ...prevState,
        fieldEditor: {
          ...prevState.fieldEditor,
          field: {
            ...prevState.fieldEditor.field,
            [key]: localValue,
            ...(key === 'label' && {
              name: getFieldName(localValue)
            })
          }
        }
      };

      onEditField(index, newState.fieldEditor.selectedFieldIndex, newState.fieldEditor.field);

      return newState;
    });
  }, [index, onEditField]);

  const onChangeNewFieldSelect = useCallback((key: string, option: any, fieldIndex: number) => {
    setState(prevState => {
      const newState = {
        ...prevState,
        fieldEditor: {
          ...prevState.fieldEditor,
          field: {
            ...prevState.fieldEditor.field,
            [key]: (option && option.value) || '',
            calculateAfterChange: option.value === FieldType.Number || option.value === FieldType.Dropdown
          }
        }
      };

      onEditField(index, newState.fieldEditor.selectedFieldIndex, newState.fieldEditor.field);

      return newState;
    });
  }, [index, onEditField]);

  const onCancelFieldEdit = useCallback(() => {
    setState(prevState => {
      const newState = {
        ...prevState,
        fieldEditor: {
          ...prevState.fieldEditor,
          field: null,
          selectedStepIndex: -1,
          selectedFieldIndex: -1
        }
      };

      onSelectField(-1, -1, null);

      return newState;
    });
  }, [onSelectField]);

  const onStepSelect = useCallback((e: any) => {
    const parentClassName: string = (e.target?.parentNode?.className ?? '');

    if (!!state.fieldEditor.field || (parentClassName.includes && parentClassName.includes('step-field'))) {
      return;
    }

    onSelect(index);
  }, [
    index,
    state.fieldEditor.field,
    onSelect
  ]);

  const renderFieldEditor = useCallback((fieldIndex: number, field: Field) => {
    if (step.isFixed || !state.fieldEditor.field) {
      return null;
    }

    if (state.fieldEditor.field.id !== field.id) {
      return null;
    }

    return (
      <>
        <FieldEditorWrapper
          onClick={(e) => e.stopPropagation()}
        >
          <AdminFormLine column marginBottom>
            <AdminFormLine marginBottom />
            <AdminFormLine marginBottom>
              <TextInput
                widthM={'100%'}
                widthT={'35rem'}
                value={state.fieldEditor.field.label}
                label={'Question'}
                onChange={(e) => onChangeNewField(e, 'label', fieldIndex)}
                onKeyPress={(e: any) => {
                  if (e.key === 'Enter') {
                    onCancelFieldEdit();
                  }
                }}
              />
            </AdminFormLine>
            <AdminFormLine marginBottom>
              <Dropdown
                widthM={'100%'}
                widthT={'35rem'}
                label={'Question type'}
                value={state.fieldEditor.field.type}
                options={supportedFieldTypeDropdownOptions.map((k: string) => (
                  createDropdownOption(formatLabel(k), k)
                ))}
                onChange={(option: any) => {
                  if (!option) {
                    return;
                  }

                  onChangeNewFieldSelect('type', option, fieldIndex);
                }}
              />
            </AdminFormLine>
            {state.fieldEditor.field.type === FieldType.Number && (
              <>
                <AdminFormLine marginBottom>
                  <NumericInput
                    value={state.fieldEditor.field.min}
                    min={0}
                    max={1000000}
                    label={'Number minimum'}
                    onUpdate={(e, val) => {
                      setState(prevState => {
                        const newState: any = {
                          ...state,
                          fieldEditor: {
                            ...state.fieldEditor,
                            field: {
                              ...state.fieldEditor.field,
                              min: val
                            }
                          }
                        };

                        onEditField(index, newState.fieldEditor.selectedFieldIndex, newState.fieldEditor.field);

                        return newState;
                      });
                    }}
                    onKeyPress={(e: any) => {
                      if (e.key === 'Enter') {
                        onCancelFieldEdit();
                      }
                    }}
                  />
                </AdminFormLine>
                <AdminFormLine marginBottom>
                  <NumericInput
                    value={state.fieldEditor.field.max}
                    min={1}
                    max={5000000}
                    label={'Number maximum'}
                    onUpdate={(e, val) => {
                      setState(prevState => {
                        const newState: any = {
                          ...state,
                          fieldEditor: {
                            ...state.fieldEditor,
                            field: {
                              ...state.fieldEditor.field,
                              max: val
                            }
                          }
                        };

                        onEditField(index, newState.fieldEditor.selectedFieldIndex, newState.fieldEditor.field);

                        return newState;
                      });
                    }}
                    onKeyPress={(e: any) => {
                      if (e.key === 'Enter') {
                        onCancelFieldEdit();
                      }
                    }}
                  />
                </AdminFormLine>
              </>
            )}
            {state.fieldEditor.field.type === FieldType.Dropdown && (
              <AdminFormLine marginBottom>
                <MultiCreatableDropdown
                  enterKeyHint="go"
                  widthM={'100%'}
                  widthT={'35rem'}
                  isClearable={true}
                  label={'Dropdown items'}
                  value={state.fieldEditor.field.dropdownItems}
                  onChange={(items: any) => {
                    setState(prevState => {
                      const newState: any = {
                        ...prevState,
                        fieldEditor: {
                          ...prevState.fieldEditor,
                          field: {
                            ...state.fieldEditor.field,
                            dropdownItems: items
                          }
                        }
                      };

                      onEditField(index, newState.fieldEditor.selectedFieldIndex, newState.fieldEditor.field);

                      return newState;
                    });
                  }}
                  onKeyDown={(e: any) => {
                    if (e.key === 'Enter') {
                      onCancelFieldEdit();
                    }
                  }}
                />
              </AdminFormLine>
            )}
            <AdminFormLine marginBottom>
              <TextArea
                widthM={'100%'}
                widthT={'35rem'}
                value={state.fieldEditor.field.hint as any || null}
                label={'Question hint'}
                tooltip={'This is the hint for the form field which will appear inside the field. It should give the user a clue about the information they need to enter. Try typing something to see the effect'}
                onChange={(e) => onChangeNewField(e, 'hint', fieldIndex)}
              />
            </AdminFormLine>
            <AdminFormLine marginBottom>
              <TextArea
                widthM={'100%'}
                widthT={'35rem'}
                value={state.fieldEditor.field.info as any || null}
                label={'Question info'}
                tooltip={'This is extra text which will appear below the form field. This can serve as a detailed explanation for requiring certain infromation. Try typing something to see the effect'}
                onChange={(e) => onChangeNewField(e, 'info', fieldIndex)}
              />
            </AdminFormLine>
            <AdminFormLine
              right
              marginBottom
            >
              <Button
                type={'button'}
                onClick={onCancelFieldEdit}
              >Done</Button>
            </AdminFormLine>
          </AdminFormLine>
        </FieldEditorWrapper>
      </>
    );
  }, [
    state,
    index,
    step.isFixed,
    supportedFieldTypeDropdownOptions,
    onEditField,
    onChangeNewField,
    onChangeNewFieldSelect,
    onCancelFieldEdit
  ]);

  const renderField = useCallback((field: Field, fieldIndex: number) => {
    let elem = null;

    const isEditorOpen: boolean = !!state.fieldEditor.field;
    const isSelectable: boolean = !isEditorOpen || field.id === state.fieldEditor?.field?.id;
    const isSelectedField: boolean = field.id === state.selectedField?.id;
    const isDraggable: boolean = !isEditorOpen;

    const commonProps = {
      className: 'step-field',
      width: '100%',
      label: field.label,
      placeholder: field.hint,
      info: field.info,
      autocomplete: '!off',
      ...(!step.isFixed && isSelectable && {
        builder: {
          selected: isSelectedField,
          isSelectable,
          isSortable: isSelectedField && isDraggable,
          isEditable: isSelectedField,
          isDeletable: isSelectedField,
          onEdit: (e: any) => {
            e.stopPropagation();

            setState(prevState => ({
              ...prevState,
              fieldEditor: {
                ...prevState.fieldEditor,
                field
              }
            }));
          },
          onDelete: (e: any) => {
            e.stopPropagation();

            onDeleteField(index, fieldIndex)
          },
          onEditWrapperClick: (e: any) => {
            e.stopPropagation();

            // if (!(lastDragField.current && lastDragField.current.name === field.name)) {
              onSelectField(index, fieldIndex, field)
            // }
          }
        },
        onFocus: (e: any) => {
          e.stopPropagation();

          onSelectField(index, fieldIndex, field);
        }
      }),
      onChange: () => {}
    };

    switch (field.type) {
      case FieldType.ShortText:
        elem = (
          <TextInput
            {...commonProps}
          />
        );
        break;
      case FieldType.LongText:
        elem = (
          <TextArea
            {...commonProps}
          />
        );
        break;
      case FieldType.Number:
        elem = (
          <NumericInput
            {...commonProps}
            min={field.min}
            max={field.max}
          />
        );
        break;
      case FieldType.Dropdown:
        elem = (
          <Dropdown
            {...commonProps}
            isClearable={false}
            options={field.dropdownItems!}
          />
        );
        break;
      case FieldType.DateTime:
        elem = (
          <DateTimeFields
            {...commonProps}
            slots={{
              options: [],
              onFetchTimeSlots: () => {}
            }}
          />
        );
        break;
    };

    return (
      <StepFieldsInner
        key={`${step.name}-${field.id}`}
        column
      >
        {showFieldsAsText ? (
          <StyledField>
            <span>{field.label}</span>
          </StyledField>
        ) : (
          <DraggableFormField
            key={field.id}
            canDrag={commonProps.builder?.isSortable || false}
            content={elem}
            fieldItem={field}
            index={fieldIndex}
            stepIndex={index}
            onReOrder={(dragIndex: number, hoverIndex: number) => {
              if (onReOrderField) {
                onReOrderField(index, dragIndex, hoverIndex);
              }
            }}
            onDrag={(dragField: Field) => {
              lastDragField.current = dragField;
            }}
            onEnd={(dragField: Field, stepIndex: number) => {
              // Abort if field no longer exists in list (dropped on another step)
              const fieldFound: boolean = !!step.fields.find(f => f.id === dragField.id);

              if (!fieldFound) {
                return;
              }

              onSelectField(index, fieldIndex, dragField)
            }}
          />
        )}

        {renderFieldEditor(fieldIndex, field)}
      </StepFieldsInner>
    );
  }, [
    index,
    step.name,
    step.fields,
    step.isFixed,
    state.fieldEditor,
    state.selectedField,
    showFieldsAsText,
    renderFieldEditor,
    onSelectField,
    onDeleteField,
    onReOrderField
  ]);

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      fieldEditor: {
        ...prevState.fieldEditor,
        selectedStepIndex: selectedField.stepIndex,
        selectedFieldIndex: selectedField.fieldIndex,
        ...(prevState.fieldEditor.field && selectedField.field?.id !== prevState.fieldEditor.field.id && {
          field: null
        })
      },
      selectedField: selectedField.field
    }));
  }, [
    index,
    step.fields,
    selectedField,
    state.fieldEditor.selectedStepIndex,
    state.fieldEditor.selectedFieldIndex
  ]);

  const [{ isDragging }, drag] = useDrag({
    type: DragItem.Step,
    canDrag,
    item: () => ({
      step,
      index,
      fields,
      isSelected,
      selectedField,
      canUpdateService,
      onSelect,
      onRename,
      onDelete,
      width: ref.current!.getBoundingClientRect().width
    }),
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    })
  });

  const [{ handlerId, dragItemType, canDrop, dragItem }, drop] = useDrop<
    BuilderStepProps,
    void,
    {
      handlerId: Identifier | null;
      dragItemType: any | null;
      canDrop: boolean;
      dragItem: BuilderStepProps | DropItemProps;
    }
  >({
    accept: [DragItem.Step, DragItem.Field],
    // accept: DragItem.Step,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        dragItemType: monitor.getItemType(),
        canDrop: monitor.canDrop(),
        dragItem: monitor.getItem()
      }
    },
    hover(hoverItem: BuilderStepProps | DropItemProps, monitor) {
      if (!ref.current || dragItemType === null) {
        return
      }

      const dragIndex = hoverItem.index
      const hoverIndex: number = hasValue(index) ? index as number : -1;
      const isTypeStep: boolean = dragItemType === DragItem.Step;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex! && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex! && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      if (!isPreview && isTypeStep && onReOrder) {
        onReOrder(dragIndex, hoverIndex!);

        // mutation for performance
        hoverItem.index = hoverIndex
      }
    },
    canDrop: (dropItem: BuilderStepProps | DropItemProps): boolean => {
      switch (dragItemType) {
        case DragItem.Field:
          const sourceStepIndex: number = (dropItem as DropItemProps).stepIndex;
          const targetStepIndex: number = index;

          if (step.isFixed) {
            return false;
          }

          return Math.abs(sourceStepIndex - targetStepIndex) === 1;
        case DragItem.Step:
          return true;
        default:
          return false;
      };
    },
    drop: (droppedItem: BuilderStepProps | DropItemProps) => {
      switch (dragItemType) {
        case DragItem.Field:
          if ((droppedItem as DropItemProps).stepIndex !== index) {
            if (onMoveFieldBetweenSteps) {
              onMoveFieldBetweenSteps(
                (droppedItem as DropItemProps).stepIndex,
                index,
                droppedItem.index
              );
            }
          }
          break;
      };
    }
  });

  drag(drop(ref));

  const isFieldOverForeignStep: boolean = canDrop && dragItemType === DragItem.Field && (dragItem as DropItemProps).stepIndex !== index;

  return (
    <Wrapper
      ref={ref}
      column
      centerH
      centerV
      pointer
      noPadding
      isLast={isLast}
      isPreview={!!isPreview}
      isOver={isFieldOverForeignStep}
      data-handler-id={handlerId}
      style={{
        ...(isSelected && {
          border: `.2rem solid ${theme.colors.corePrimary}`
        }),
        opacity: isDragging ? 0 : 1
      }}
      onClick={onStepSelect}
    >
      <StepWrapper
        row
        centerV
      >
        <IconButton
          hoverEffect
          type="button"
          style={{ marginRight: '1.5rem' }}
          onClick={() => onExpand(step.name)}
        >
          {isExpanded ? (
            <StyledUnfoldLess />
          ) : (
            <StyledUnfoldMore />
          )}
        </IconButton>
        <span>{step.label}</span>
        {!step.isFixed && canUpdateService && (
          <IconButton
            hoverEffect
            type="button"
            style={{ marginLeft: 'auto' }}
            onClick={() => {
              setState(prevState => ({
                ...prevState,
                popupOpen: true
              }));
            }}
          >
            <StyledMenuDots />
            {state.popupOpen && (
              <Popup
                id={step.name}
                left
                bottom
                convertable
                onClose={() => {
                  setState(prevState => ({
                    ...prevState,
                    popupOpen: false
                  }));
                }}
              >
                {({ closePopup }) => (
                  <MenuWrapper>
                    <MenuItem onClick={(e) => {
                      e.stopPropagation();

                      closePopup();
                      onRename(step);
                    }}>Rename</MenuItem>
                    <MenuItem onClick={(e) => {
                      e.stopPropagation();

                      closePopup();

                      onAddField(index, {
                        id: nanoid(5),
                        name: 'new-field',
                        label: 'New field',
                        type: FieldType.ShortText
                      });
                    }}>Add field</MenuItem>
                    <MenuItem onClick={(e) => {
                      e.stopPropagation();

                      closePopup();
                      onDelete(index);
                    }}>Delete</MenuItem>
                  </MenuWrapper>
                )}
              </Popup>
            )}
          </IconButton>
        )}
      </StepWrapper>
      <StepFields isExpanded={isExpanded}>
        <StepFieldsOuter>
          {step.fields.map((field: Field, fieldIndex: number) => renderField(field, fieldIndex))}
        </StepFieldsOuter>
      </StepFields>
    </Wrapper>
  );
};

