import React, { useState } from 'react';

import { FormField } from '../form';
import { StyledBaseInputProps } from './BaseInput';
import { BaseProps } from '../form/types';
import { FormFieldProps } from '../form/FormField';

import { StyledInput } from './OTPInput.styles';

export interface OTPInputProps extends Omit<StyledBaseInputProps, 'errored'> {
  digits: number;
}

export type Props = OTPInputProps & BaseProps & FormFieldProps;

const OTPInput = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    digits,
    type,
    className,
    label,
    error,
    hasChanged,
    width,
    widthM,
    widthT,
    widthL,
    noWidth,
    noMinWidth,
    onFocus,
    onBlur,
    ...rest
  } = props;

  const [isFocused, setIsFocused] = useState<boolean>(false);

  return (
    <FormField
      id={rest.id}
      error={error}
      label={label}
      className={className}
      hasChanged={hasChanged}
      value={rest.value}
      width={width}
      widthM={widthM}
      widthT={widthT}
      widthL={widthL}
      noWidth={noWidth}
      noMinWidth={noMinWidth}
      isFocused={isFocused}
      isFull={String(rest.value as string).length > 0}
      info={rest.info}
      tooltip={rest.tooltip}
      builder={rest.builder}
      startAdornment={rest.startAdornment}
      endAdornment={rest.endAdornment}
      formFieldStyles={rest.formFieldStyles}
    >
      <StyledInput
        {...rest}
        ref={ref}
        type={type || 'text'}
        autoComplete="one-time-code"
        inputMode="numeric"
        digits={digits}
        maxLength={digits}
        pattern={`\\d{${digits}}`}
        isFocused={isFocused}
        onInput={(e: any) => {
          let val: string = (e.target.value as string);

          // strip dots and spaces
          val = val
            .replaceAll('.', '')
            .replaceAll(' ', '');

          e.target.value = val;

          // check the full string (copy & paste scenarios)
          // and remove earliest non numberic char to end
          if (isNaN(Number(val))) {
            const firstAlphaChar = val.search(/[^0-9]/);

            if (firstAlphaChar >= 0) {
              const validChars = val.substring(0, firstAlphaChar);

              e.target.value = validChars;
              e.target.style.setProperty('--_otp-digit', e.target.selectionStart)
            }

            return;
          }

          e.target.style.setProperty('--_otp-digit', e.target.selectionStart)
        }}
        onFocus={e => {
          if (onFocus) {
            onFocus(e);
          }

          setIsFocused(true);
        }}
        onBlur={e => {
          if (onBlur) {
            onBlur(e);
          }

          setIsFocused(false);
        }}
      />
    </FormField>
  );
});

export default OTPInput;

