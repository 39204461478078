import { setup } from './gateway';
import Browser from './browser';
import { authService } from './';
import { gatewayService } from './';

function* generateId() {
  let i = 0;
  while (true) {
    yield i++;
  }
}

export const createApplicationServices = (url: string) => {
  return {
    comms: setup(url),
    generateId: generateId(),
    browser: new Browser(),
    authService,
    gatewayService
  };
};
