import React from 'react';

import { FormField } from '../form';
import { BaseProps } from '../form/types';

import { FormFieldProps } from '../form/FormField';

import {
  Wrapper
} from './Text.styles';

interface CustomTextProps extends BaseProps {
  id?: string;
  isError?: boolean;
}

export type TextProps = CustomTextProps & FormFieldProps & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const Text = React.forwardRef<HTMLDivElement, TextProps>((props, ref) => {
  const {
    value,
    label,
    className,
    error,
    highlightChanged,
    width,
    noWidth,
    noMinWidth,
    isError,
    ...rest
  } = props;

  return (
    <FormField
      id={rest.id}
      className={className}
      error={error}
      label={label}
      value={value || ''}
      width={width}
      noWidth={noWidth}
      noMinWidth={noMinWidth}
      tooltip={rest.tooltip}
      tooltipInfoIcon={rest.tooltipInfoIcon}
      info={rest.info}
      style={rest.style}
      wordBreak={rest.wordBreak}
      startAdornment={rest.startAdornment}
      endAdornment={rest.endAdornment}
    >
      <Wrapper
        {...rest}
        ref={ref}
        isError={isError}
      >
        <span>{value as React.ReactNode}</span>
      </Wrapper>
    </FormField>
  );
});

export default Text;
