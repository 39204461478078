import React, { FC, useCallback, useState, useRef, useEffect, createRef } from 'react';

import Tab from './Tab';
import {
  Wrapper,
  TabHeader,
  TabBody,
  Underline
} from './Tabs.styles';

interface TabsProps {
  activeTab?: string;
  children: React.ReactElement[];
  tabHasChanged?: (activeTab: string, index: number) => void;
}

interface TabsState {
  activeTab: string;
  activeTabIndex: number;
  tabLeft: number;
  tabWidth: number;
}

const initialState = (props: TabsProps): TabsState => {
  return {
    activeTab: props.activeTab || props.children[0].props.title,
    activeTabIndex: 0,
    tabLeft: 0,
    tabWidth: 0
  };
};

const Tabs: FC<TabsProps> = props => {
  const {
    children,
    tabHasChanged
  } = props;

  const firstLoad = useRef<boolean>(true);
  const tabsRef = useRef<Array<React.RefObject<HTMLLIElement>>>([]);
  const [state, setState] = useState<TabsState>(initialState(props));

  const onTabClick = useCallback((tab: string, index: number) => {
    setState({
      ...state,
      activeTab: tab,
      activeTabIndex: index
    });

    if (tabHasChanged) {
      tabHasChanged(tab, index);
    }
  }, [state, tabHasChanged]);

  const getTabDimensions = useCallback(() => {
    if (!tabsRef.current[state.activeTabIndex]?.current) {
      return 0;
    }

    setState(prevState => ({
      ...prevState,
      tabLeft: tabsRef.current[state.activeTabIndex].current!.offsetLeft,
      tabWidth: tabsRef.current[state.activeTabIndex].current!.offsetWidth
    }));
  // eslint-disable-next-line
  }, [
    tabsRef.current,
    state.activeTabIndex
  ]);

  useEffect(() => {
    getTabDimensions();
  }, [getTabDimensions]);

  useEffect(() => {
    if (firstLoad.current) {
      tabsRef.current = [...Array(children.length)].map(() => createRef<HTMLLIElement>());

      if (tabHasChanged) {
        tabHasChanged(state.activeTab, 0);
      }

      firstLoad.current = false;
    }
  }, [
    tabHasChanged,
    state.activeTab,
    children.length
  ]);

  return (
    <Wrapper {...props}>
      <TabHeader>
        {children.map((child, index: number) => {
          const { title } = child.props;

          return (
            <React.Fragment key={index}>
              <Tab
                ref={tabsRef.current[index]}
                activeTab={state.activeTab}
                key={title}
                label={title}
                onTabSelect={(label: string) => onTabClick(label, index)}
              />
              {index === 0 && (
                <Underline
                  left={state.tabLeft}
                  width={state.tabWidth}
                />
              )}
            </React.Fragment>
          );
        })}
      </TabHeader>
      <TabBody>
        {children.map((child, index: number) => {
          if (child.props.title !== state.activeTab) {
            return undefined;
          }

          return child;
        })}
      </TabBody>
    </Wrapper>
  );
};

export default Tabs;

