// TODO: temp. Extend from shared type
import {
  Service,
  Step
} from './Service';
import { Fulfiller } from './Fulfiller';
import { Customer } from './Customer';
import { BaseEntity } from './Generic';

export enum StatusType {
  SUBMITTED = 'SUBMITTED',

  PAID = 'PAID',
  // REFUND_REQUESTED = 'REFUND_REQUESTED',
  REFUNDED = 'REFUNDED',

  // MATCHED = 'MATCHED',
  // ACCEPTED = 'ACCEPTED',
  // REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  DEFERRED = 'DEFERRED',
  DELAYED = 'DELAYED',

  CANCELLED = 'CANCELLED',
  COMPLETE = 'COMPLETE',
  CHARGES_PENDING = 'CHARGES_PENDING',
  CHARGES_PAID = 'CHARGES_PAID',
  DISPUTE = 'DISPUTE'
}

export enum ChangeType {
  JOB_FIELD,
  FULFILLER,
  FULFILLER_PAY,
  JOB_DATE,
  GB_INVOICE_GENERATED,
  GB_INVOICE_SENT,
  THIRD_PARTY_INVOICE_GENERATED,
  THIRD_PARTY_INVOICE_SENT,
  JOB_PRICE
}

export interface Change {
  type: ChangeType,
  previousValue: any;
  currentValue: any;
  date: string;
  author: {
    _id: string;
    name: string;
  }
}

export interface Status {
  type: StatusType;
  date: string;
  author: {
    _id: string;
    name: string;
  };
  description?: string;
  changes?: Change[];
}

export interface Fields {
  [key: string]: string | number | Date | any;

  // TODO: use below instead
  // [key: string]: {
  //   [key: string]: string | number | Date | any;
  // }
}

export interface AdditionalCharge {
  summary: string;
  price: number;
}

export enum DateEntryMethod {
  Duration = 'duration',
  StartEnd = 'start-end'
}

export interface Job extends BaseEntity {
  _id: string;
  reference: string;
  status: Status[];
  price: number;
  additionalCharges: AdditionalCharge[] | null;
  fields: Fields;
  customerId: string;
  noCustomerCommunications: boolean;
  customerInvoiceCreated: boolean;
  byAdmin: boolean |null;
  fulfillerId: string | null;
  serviceId: string;
  clientId: string;
  fulfillerPay: number;
  fulfillerPayPercentage?: number;
  fulfillerLabel?: number;
  qboInvoiceId: string;
  vat: boolean;
  tz: string;
  staffNotes: string | null;
  steps?: Step[];
  amountDue?: number;

  // expanded fields
  customer?: Customer;
  service?: Service;
  fulfiller?: Fulfiller;
}

