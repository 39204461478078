import {
  createDropdownOption
} from 'utils/general';
import { DropdownOption } from 'types/UI';

export const LOCAL_STORAGE_KEY = 'd';

export const DEFAULT_SERVICE = 'My first service';
export const DEFAULT_GROUP = {
  name: 'my-first-group',
  label: 'My first group'
};

export const DEFAULT_FIELD = {
  name: 'my-first-field',
  label: 'My first field'
};

export const CURRENCY_DROPDOWN_OPTIONS: DropdownOption[] = [
  createDropdownOption('Is less than', 'less-than'),
  createDropdownOption('Is between', 'between'),
  createDropdownOption('Is greater than', 'greater-than'),
  createDropdownOption('Is equal to', 'equal')
];

export const DATE_DROPDOWN_OPTIONS: DropdownOption[] = [
  createDropdownOption('Is before', 'before'),
  createDropdownOption('Is between', 'between'),
  createDropdownOption('Is after', 'after'),
  createDropdownOption('Is on', 'equal')
];

export const DEFAULT_PRICING_RULE_NAME_PREFIX = 'Rule';

export const DAY_IN_MILLISECONDS = 86400000;

export const HOUR_DROPDOWN_OPTIONS: DropdownOption[] = [...new Array(24)].map((h: number, i: number) => {
  let hour: string = `${i}`;

  hour = hour.length === 1 ? `0${hour}` : hour;

  return createDropdownOption(hour);
})

export const MINUTE_DROPDOWN_OPTIONS: DropdownOption[] = [...new Array(60)].map((m: number, i: number) => {
  let min: string = `${i}`;

  min = min.length === 1 ? `0${min}` : min;

  return createDropdownOption(min);
})

export const UI_INTERNAL_EVENT = '***UI_INTERNAL_EVENT***';

export const BASE_PRICE_NAME = 'Base';

export const ISO_FORMAT = `yyyy-MM-dd'T'HH:mm:ss.SSS'Z'`;

export const VAT_RATE = 20;

export const FULFILLER_TYPE_DROPDOWN_OPTIONS: DropdownOption[] = [
  createDropdownOption('Barber'),
  createDropdownOption('Beautician'),
  createDropdownOption('Builder'),
  createDropdownOption('Chauffeur'),
  createDropdownOption('Consultant'),
  createDropdownOption('Driver'),
  createDropdownOption('Electrical engineer'),
  createDropdownOption('Engineer'),
  createDropdownOption('Fulfiller (default)'),
  createDropdownOption('Gas engineer'),
  createDropdownOption('Massage therapist'),
  createDropdownOption('Masseur'),
  createDropdownOption('Masseuse'),
  createDropdownOption('Painter'),
  createDropdownOption('Security guard'),
  createDropdownOption('Specialist'),
  createDropdownOption('Teacher')
];

export const MAX_PRICE = 10000000;

