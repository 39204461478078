import styled from 'styled-components';
import { device } from 'theme/media';

import { DayRow } from './Day.styles';

export * from 'theme/mixins';

export const Wrapper = styled.div<{ dayWidth: number }>`
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 50rem;

  > div > div {
    width: 100%;
    height: 100%;
  }

  .react-datepicker {
    display: flex;

    &__header {
      font-size: 1.5rem;
      background-color: transparent;
    }

    &__month-container {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
    }

    &__month {
      display: grid;
      grid-auto-rows: 1fr;
      height: 100%;
      align-items: center;
      flex-direction: column;
      margin: 0;

      @media ${device.onlyMobile} {
        grid-auto-rows: unset;
        display: block;
      }
    }

    &__week {
      display: flex;
      width: 100%;
      height: 100%;

      @media ${device.onlyMobile} {
        min-height: 5rem;
        max-height: 10rem;
      }

      @media ${device.tablet} {
        min-height: ${({ dayWidth }) => dayWidth}px;
        max-height: ${({ dayWidth }) => dayWidth}px;
      }
    }

    &__day {
      display: flex;
      flex: 1;
      border-right: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      margin: 0;

      :last-child {
        border-right: none;
      }

      :hover {
        background-color: transparent;
      }

      &--outside-month {
        color: #ccc;
      }

      &--today {
        background: transparent;

        ${DayRow} {
          color: ${({ theme }) => theme.colors.accentPrimary};
        }
      }

      &--selected, &--selected:hover {
        background-color: ${({ theme }) => theme.colors.corePrimaryLighter2}; 
      }
    }

    &__day-names {
      display: flex;
      padding: 1rem 0;
    }

    &__day-name {
      display: flex;
      flex: 1;
      font-size: 1.5rem;
      justify-content: center;
      margin: 0;
    }

    &__current-month {
      font-size: 1.5rem;
    }
  }
`;

export const StyledHeader = styled.div`
  margin: 0 2rem .25rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    margin: 0;
  }
`;
