import React from 'react'
import { IconProps } from './types'

type Props = React.DetailedHTMLProps<React.DOMAttributes<SVGSVGElement>, SVGSVGElement> &
  IconProps;

const Plus = React.forwardRef<SVGSVGElement, Props>((props, ref) => {
  const { className, ...rest } = props;

  return (
    <svg
      {...rest}
      ref={ref}
      className={className}
      height="2.4rem"
      width="2.4rem"
      viewBox="0 0 16 16"
    >
      <path d="M9 7h6a1 1 0 0 1 0 2H9v6a1 1 0 0 1-2 0V9H1a1 1 0 1 1 0-2h6V1a1 1 0 1 1 2 0z" fillRule="evenodd"></path>
    </svg>
  );
});

export default Plus
