import React, { FC, useCallback, useState, useMemo, memo } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  object,
  string,
  ref
} from 'yup';
import { format } from 'date-fns';

import { gatewayService, authService } from 'services';
// import { Actions } from 'components/App';
import { Fulfiller } from 'types/Fulfiller';
import { User, RoleType } from 'types/User';
import { CellType } from 'types/Header';
import { theme } from 'theme';
// import { DEFAULT_GROUP } from '../../../../../constants';
import {
  FormSection,
  TextInput,
  NumericInput,
  Button,
  PrimaryButton,
  Switch,
  Edit,
  Key,
  Text,
  Bin,
  ColourPicker
} from 'components/atoms';
import { Popup } from 'components/molecules';
import {
  canDeleteUser,
  restrictedProfileEdit
} from 'config/privileges';
import {
  hasValue,
  getNetworkErrors,
  extractNumericInput,
  addCountryCodeToNumber,
  stripCountryCodeFromNumber,
  formatPhoneNumber,
  stripLeadingZeroFromNumber
} from 'utils/general';
import { Form } from 'types/UI';
import { AdminPageProps } from 'components/AppRouter';
import { PageHeader } from '../../components';
import { Actions } from 'state';
import {
  NetworkError
} from 'types/Error';
import {
  canUpdateUser,
  isFulfiller
} from 'config/privileges';
import { useValidation } from 'components/hooks';
import {
  useMediaSizes
} from '../../hooks';

import {
  ModalWrapper,
  ModalItem,
  ModalBorder
} from 'theme/mixins';
import {
  Wrapper,
  FormWrapper,
  AdminFormLine,
  Card,
  Sections
  // StyledStatusIndicator
} from './Profile.styles';

interface State {
  user: {
    loading: boolean;
    data: User | Fulfiller | null;
    fields: any;
    error: NetworkError | null;
  };
  userUpdate: {
    active: boolean;
    loading: boolean;
    error: NetworkError | null;
  };
  userDeactivate: {
    loading: boolean;
    showModal: boolean;
    error: NetworkError | null;
  };
  securityUpdate: {
    active: boolean;
    action: 'password' | '2FA' | '';
    loading: boolean;
    error: NetworkError | null;
  };
  form: Form;
}

const initialState: State = {
  user: {
    loading: false,
    data: null,
    fields: {},
    error: null
  },
  userUpdate: {
    active: false,
    loading: false,
    error: null
  },
  userDeactivate: {
    loading: false,
    showModal: false,
    error: null
  },
  securityUpdate: {
    active: false,
    action: '',
    loading: false,
    error: null
  },
  form: {}
};

let errorMessage: string = '';

const profileSchema = object({
  firstName: string()
    .label('First name')
    .required(),
  lastName: string()
    .label('Last name')
    .required(),
  email: string()
    .email()
    .required()
});

const changePasswordSchema = object({
  oldPassword: string()
    .label('Current password')
    .required(),
  newPassword: string()
    .label('New password')
    .notOneOf([ref('oldPassword')], 'New password must be different')
    .required()
});

const Profile: FC<AdminPageProps> = props => {
  const {
    addToast,
    userData,
    dispatch
  } = props;

  const params: any = useParams();
  const { t } = useTranslation();

  const [state, setState] = useState<State>({
    ...initialState,
    user: {
      ...initialState.user,
      data: userData.user,
      fields: userData.fields
    },
    form: {
      ...initialState.form,
      clientId: params.clientId
    }
  });
  const {
    errors: profileFormErrors,
    validate: validateProfileForm,
    reset: resetProfileForm
  } = useValidation(profileSchema);
  const {
    errors: changePasswordFormErrors,
    validate: validateChangePasswordForm,
    reset: resetChangePasswordForm
  } = useValidation(changePasswordSchema);

  const { isMobile } = useMediaSizes();

  const basicInfoSubmitButtonDisabled = useMemo(() => {
    if (!state.user.data) {
      return true;
    }

    return JSON.stringify(state.form) === JSON.stringify(state.user.data);
  }, [
    state.form,
    state.user.data
  ]);

  const passwordSubmitButtonDisabled = useMemo(() => {
    if (!state.user.data) {
      return true;
    }

    if (!hasValue(state.form) || !hasValue(state.form.oldPassword) || !hasValue(state.form.newPassword)) {
      return true;
    }
  }, [
    state.form,
    state.user.data
  ]);

  const updateUser = useCallback((payload: Partial<User | Fulfiller>, cb?: (e: NetworkError | null, updateUserResponse?: any) => void) => {
    const {
      _id,
      clientId,
      created,
      updated,
      verified,
      jobId,
      urls,
      permissions,
      creatorId,
      email,
      signedIn,
      jobsFilter,
      ...rest
    } = payload;

    delete (rest as User).isTrial;

    if (state.user.data!.role === RoleType.Fulfiller) {
      delete rest.role;
    }

    const apiMethod = state.user.data!.role === RoleType.Fulfiller ? gatewayService.updateFulfiller : gatewayService.updateUser;

    apiMethod(params.clientId, _id!, rest)
      .then((updateUserResponse: any) => {
        if (cb) {
          cb(null, updateUserResponse);
        }

        dispatch({
          type: Actions.UPDATE_USER_CACHE,
          payload: {
            loading: false,
            fields: state.user.fields,
            user: updateUserResponse.data,
            errors: null
          }
        });
      })
      .catch((e) => {
        errorMessage = e.response.data.message || 'Something went wrong, please try again';

        addToast({
          type: 'error',
          content: errorMessage
        });

        if (cb) {
          cb(e);
        }
      });
  }, [
    state.user.data,
    state.user.fields,
    params.clientId,
    dispatch,
    addToast
  ]);

  const updateBasicInfo = useCallback((payload: Partial<User | Fulfiller>, cb?: () => void) => {
    if (state.userUpdate.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      userUpdate: {
        ...prevState.userUpdate,
        loading: true,
      }
    }));

    updateUser(payload, (e: NetworkError | null, updateUserResponse?: any) => {
      if (e) {
        return setState(prevState => ({
          ...prevState,
          userUpdate: {
            ...prevState.userUpdate,
            loading: false,
            error: e
          }
        }));
      }

      setState(prevState => ({
        ...prevState,
        user: {
          ...prevState.user,
          data: {
            ...updateUserResponse.data
          }
        },
        userUpdate: {
          ...initialState.userUpdate
        },
        form: initialState.form
      }));

      addToast({
        type: 'success',
        content: 'Profile updated'
      });

      if (cb) {
        cb();
      }
    });
  }, [
    state.userUpdate,
    addToast,
    updateUser
  ]);

  const updateSecurity = useCallback((payload: Partial<User | Fulfiller>, cb?: () => void) => {
    if (state.securityUpdate.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      securityUpdate: {
        ...prevState.securityUpdate,
        loading: true,
      }
    }));

    updateUser(payload, (e: NetworkError | null, updateUserResponse?: any) => {
      if (e) {
        return setState(prevState => ({
          ...prevState,
          securityUpdate: {
            ...prevState.securityUpdate,
            loading: false,
            error: e
          }
        }));
      }

      setState(prevState => ({
        ...prevState,
        user: {
          ...prevState.user,
          data: {
            ...updateUserResponse.data
          }
        },
        securityUpdate: initialState.securityUpdate,
        form: initialState.form
      }));

      addToast({
        type: 'success',
        content: 'Password changed'
      });

      if (cb) {
        cb();
      }
    });
  }, [
    state.securityUpdate,
    addToast,
    updateUser
  ]);

  const deactivateAccount = useCallback(() => {
    if (state.userDeactivate.loading || !state.user.data) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      userDeactivate: {
        ...prevState.userDeactivate,
        loading: true,
      }
    }));

    gatewayService.deleteUser(params.clientId, state.user.data._id!)
      .then(() => {
        setState(prevState => ({
          ...prevState,
          userDeactivate: {
            ...prevState.userDeactivate,
            loading: false
          }
        }));

        authService.default.invalidate();
        window.location.href = '/';
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          userDeactivate: {
            ...prevState.userDeactivate,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.user.data,
    state.userDeactivate,
    params.clientId,
    addToast
  ]);

  const onChangeFormField = useCallback((e: any, key: string, value?: any) => {
    const localValue = value || value === 0 ? value : e.target.value;

    setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        [key]: localValue
      }
    }));
  }, []);

  const onChangeFormFieldBoolean = useCallback((newBoolState: boolean, key: string) => {
    setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        [key]: newBoolState
      }
    }));
  }, []);

  const onBasicInfoSubmit = useCallback((e: any) => {
    e.preventDefault();

    validateProfileForm({
      form: state.form,
      all: true,
      propagateRejection: true
    })
      .then(() => {
        if (state.userUpdate.active) {
          updateBasicInfo(state.form);
        }
      })
      .catch(() => {
        addToast({
          type: 'error',
          content: 'Please ensure all required fields are filled in correctly',
          autoRemove: true
        });
      });
  }, [
    state.form,
    state.userUpdate.active,
    addToast,
    updateBasicInfo,
    validateProfileForm
  ]);

  const onSecuritySubmit = useCallback(() => {
    validateChangePasswordForm({
      form: state.form,
      all: true,
      propagateRejection: true
    })
      .then(() => {
        if (state.securityUpdate.active && state.securityUpdate.action === 'password') {
          updateSecurity(state.form);
        }
      })
      .catch(() => {
        addToast({
          type: 'error',
          content: 'Please ensure all required fields are filled in correctly',
          autoRemove: true
        });
      });
  }, [
    state.form,
    state.securityUpdate.active,
    state.securityUpdate.action,
    addToast,
    updateSecurity,
    validateChangePasswordForm
  ]);

  const onCancelSecurityUpdateEdit = useCallback(() => {
    resetChangePasswordForm();

    setState(prevState => ({
      ...prevState,
      securityUpdate: {
        ...initialState.securityUpdate,
      },
      form: {
        ...initialState.form
      }
    }));
  }, [resetChangePasswordForm]);

  const onBasicInfoEdit = useCallback(() => {
    onCancelSecurityUpdateEdit();

    setState(prevState => ({
      ...prevState,
      userUpdate: {
        ...prevState.userUpdate,
        active: true
      },
      form: {
        ...prevState.user.data
      }
    }));
  }, [onCancelSecurityUpdateEdit]);

  const onCancelBasicInfoEdit = useCallback(() => {
    resetProfileForm();

    setState(prevState => ({
      ...prevState,
      userUpdate: {
        ...initialState.userUpdate,
      },
      form: {
        ...initialState.form
      }
    }));
  }, [resetProfileForm]);

  const onChangePassword = useCallback(() => {
    onCancelBasicInfoEdit();

    setState(prevState => ({
      ...prevState,
      securityUpdate: {
        ...prevState.securityUpdate,
        active: true,
        action: 'password'
      },
      form: {
        ...prevState.user.data
      }
    }));
  }, [onCancelBasicInfoEdit]);

  const renderConfirmAccountDeleteModal = useCallback(() => {
    if (!state.userDeactivate.showModal) {
      return null;
    }

    return (
      <Popup
        id={'account-delete-confirmation-modal'}
        layered
        convertable
        noPadding
        onClose={() => {
          setState(prevState => ({
            ...prevState,
            userDeactivate: {
              ...prevState.userDeactivate,
              showModal: false
            }
          }));
        }}
      >
        {({ closePopup }) => {
          return (
            <ModalWrapper>
              <ModalItem>
                <AdminFormLine marginTop>
                  <h3 style={{marginBottom: 0, marginRight: '2rem' }}>Are you sure you want to delete your account?</h3>
                </AdminFormLine>
              </ModalItem>

              <AdminFormLine marginBottom />
              <ModalBorder />
              <AdminFormLine marginBottom />

              <ModalItem>
                <AdminFormLine marginBottom>
                  <Text
                    value="After deletion all payments and billing will be rejected and discontinued. Workforce members and admin users will no longer have access to the platform. Your account will remain for 7 days afterwhich it will be deleted along with all associated data."
                  />
                </AdminFormLine>
              </ModalItem>

              <AdminFormLine marginBottom />
              <ModalBorder />
              <AdminFormLine marginBottom />

              <ModalItem>
                <AdminFormLine right>
                  <Button
                    type={'button'}
                    style={{ marginRight: '1rem' }}
                    onClick={() => {
                      closePopup();
                    }}
                  >Keep account</Button>
                  <PrimaryButton
                    style={{ backgroundColor: theme.colors.accentTertiary }}
                    type={'button'}
                    disabled={state.userDeactivate.loading}
                    loading={state.userDeactivate.loading}
                    spinnerColor={theme.colors.coreSecondary}
                    onClick={() => deactivateAccount()}
                  >Continue deletion</PrimaryButton>
                </AdminFormLine>
              </ModalItem>
            </ModalWrapper>
          );
        }}
      </Popup>
    );
  }, [
    state.userDeactivate,
    deactivateAccount
  ]);

  const renderChangePasswordModal = useCallback(() => {
    if (state.securityUpdate.action !== 'password') {
      return null;
    }

    return (
      <Popup
        id={'change-password-modal'}
        layered
        convertable
        noPadding
        onClose={onCancelSecurityUpdateEdit}
      >
        {({ closePopup }) => {
          return (
            <ModalWrapper>
              <ModalItem>
                <AdminFormLine marginTop>
                  <h3 style={{marginBottom: 0, marginRight: '2rem' }}>Change password</h3>
                </AdminFormLine>
              </ModalItem>

              <AdminFormLine marginBottom />
              <ModalBorder />
              <AdminFormLine marginBottom />

              <ModalItem>
                <AdminFormLine marginBottom>
                  <TextInput
                    widthM={'100%'}
                    widthT={'35rem'}
                    value={state.form.oldPassword as string}
                    error={changePasswordFormErrors.oldPassword}
                    label={t('currentPassword')}
                    type={'password'}
                    onChange={(e) => onChangeFormField(e, 'oldPassword')}
                    onBlur={() => validateChangePasswordForm({
                      form: state.form,
                      field: 'oldPassword'
                    })}
                  />
                </AdminFormLine>
              </ModalItem>

              <ModalItem>
                <AdminFormLine marginBottom>
                  <TextInput
                    widthM={'100%'}
                    widthT={'35rem'}
                    value={state.form.newPassword as string}
                    error={changePasswordFormErrors.newPassword}
                    label={t('newPassword')}
                    type={'password'}
                    onChange={(e) => onChangeFormField(e, 'newPassword')}
                    onBlur={() => validateChangePasswordForm({
                      form: state.form,
                      field: 'newPassword'
                    })}
                  />
                </AdminFormLine>
              </ModalItem>

              <AdminFormLine marginBottom />
              <ModalBorder />
              <AdminFormLine marginBottom />

              <ModalItem>
                <AdminFormLine right>
                  <Button
                    type={'button'}
                    style={{ marginRight: '1rem' }}
                    onClick={() => {
                      closePopup();
                    }}
                  >Cancel</Button>
                  <PrimaryButton
                    type={'submit'}
                    disabled={passwordSubmitButtonDisabled}
                    loading={state.securityUpdate.loading}
                    spinnerColor={theme.colors.coreSecondary}
                    onClick={() => onSecuritySubmit()}
                  >Submit</PrimaryButton>
                </AdminFormLine>
              </ModalItem>
            </ModalWrapper>
          );
        }}
      </Popup>
    );
  }, [
    t,
    state.form,
    state.securityUpdate.action,
    state.securityUpdate.loading,
    changePasswordFormErrors,
    onChangeFormField,
    onSecuritySubmit,
    validateChangePasswordForm,
    onCancelSecurityUpdateEdit,
    passwordSubmitButtonDisabled
  ]);

  const renderBasicInfo = useCallback(() => {
    if (!state.user.fields || !state.user.data) {
      return (
        <div>
          <p>{errorMessage}</p>
        </div>
      );
    }

    const formKeys = Object
      .keys(state.user.fields)
      .filter((key) => {
        const fields: boolean = key !== 'priority'
          && key !== 'creatorId'
          && key !== 'revoke'
          && key !== 'isTrial'
          && key !== 'jobsFilter';

        if (state.userUpdate.active) {
          return fields && key !== 'email'
        }

        return fields;
      });

    return (
      <Card>
        <AdminFormLine
          row
          centerV
          spaceBetween
          marginBottom
          style={{paddingBottom: '.5rem'}}
        >
          <h3 style={{ marginBottom: 0 }}>Basic Info</h3>
          <AdminFormLine>
            {canDeleteUser(params.clientId, state.user.data) && (
              <Button
                style={{
                  marginBottom: 0,
                  marginRight: '1rem',
                  // color: theme.colors.neutralSecondary,
                  backgroundColor: theme.colors.accentTertiary
                }}
                icon={
                  <Bin style={{ width: '1.75rem', height: 'auto' }} />
                }
                type={'button'}
                onClick={() => {
                  setState(prevState => ({
                    ...prevState,
                    userDeactivate: {
                      ...prevState.userDeactivate,
                      showModal: true
                    }
                  }));
                }}
              >{isMobile ? '' : 'Delete account'}</Button>
            )}
            {canUpdateUser(params.clientId, userData.user!) && (
              <Button
                style={{marginBottom: 0}}
                icon={<Edit style={{ width: '2rem', height: 'auto' }} />}
                onClick={onBasicInfoEdit}
                disabled={state.userUpdate.active}
              >{isMobile ? '' : 'Edit'}</Button>
            )}
          </AdminFormLine>
        </AdminFormLine>
        <FormWrapper
          noOverflow
          onSubmit={onBasicInfoSubmit}
        >
          <FormSection
            cols={1}
            noBorder={!state.userUpdate.active}
            noPadding
          >
            <fieldset>
              {formKeys
                .map((key: string, index: number) => {
                  const fieldItem = state.user.fields[key];
                  const value = (state.userUpdate.active ? state.form[key] : (state.user.data as any)[key]) || '';
                  const databaseValue = (state.user.data && (state.user.data as any)[key]) || '';
                  const hasChanged: boolean = state.userUpdate.active && value !== databaseValue;

                  let elem: React.ReactNode;

                  switch (fieldItem.type) {
                    case CellType.Number:
                      if (!state.userUpdate.active) {
                        elem = (
                          <Text
                            label={t(key)}
                            value={value}
                          />
                        );
                      } else {
                        elem = (
                          <NumericInput
                            hasChanged={hasChanged}
                            disabled={restrictedProfileEdit(state.user.data!, key)}
                            value={value === '' ? false : value}
                            error={profileFormErrors[key]}
                            min={1}
                            max={500}
                            label={t(key)}
                            onUpdate={(e, val: number) => onChangeFormField(e, key, val)}
                            onBlur={() => validateProfileForm({
                              form: state.form,
                              field: key
                            })}
                          />
                        );
                      }
                      break;
                    case CellType.Boolean:
                      if (!state.userUpdate.active) {
                        elem = (
                          <Text
                            label={t(key)}
                            value={value === true ? 'Yes' : 'No'}
                          />
                        );
                      } else {
                        elem = (
                          <Switch
                            hasChanged={hasChanged}
                            disabled={restrictedProfileEdit(state.user.data!, key)}
                            value={value === '' ? false : value}
                            label={t(key)}
                            onChange={(newState: boolean) => onChangeFormFieldBoolean(newState, key)}
                          />
                        );
                      }
                      break;
                    case CellType.String:
                      if (key === 'colour') {
                        if (!isFulfiller(userData.user!)) {
                          elem = (
                            <ColourPicker
                              disableAutoScroll
                              readOnly={!state.userUpdate.active}
                              hasChanged={hasChanged}
                              value={value || '#E8A80A'}
                              label={t('calendarColour')}
                              onChange={(colour: any) => {
                                setState(prevState => ({
                                  ...prevState,
                                  form: {
                                    ...prevState.form,
                                    [key]: colour.hex
                                  }
                                }));
                              }}
                            />
                          );
                        }
                      } else {
                        if (!state.userUpdate.active) {
                          if (key === 'phone') {
                            elem = (
                              <Text
                                label={t(key)}
                                value={formatPhoneNumber(value)}
                              />
                            );
                          } else {
                            elem = (
                              <Text
                                label={t(key)}
                                value={value}
                              />
                            );
                          }
                        } else {
                          if (key === 'phone') {
                            elem = (
                              <TextInput
                                hasChanged={hasChanged}
                                disabled={restrictedProfileEdit(state.user.data!, key)}
                                widthM={'100%'}
                                widthT={'35rem'}
                                value={stripCountryCodeFromNumber(value)}
                                error={profileFormErrors[key]}
                                label={t(key)}
                                inputMode="numeric"
                                maxLength={10}
                                inputStartAdornment={
                                  <div>+44</div>
                                }
                                onInput={(e: any) => {
                                  extractNumericInput(e);

                                  setTimeout(() => {
                                    e.target.value = stripLeadingZeroFromNumber(e.target.value);
                                  }, 1000);
                                }}
                                onChange={(e) => {
                                  const localValue = e.target.value;

                                  setState(prevState => ({
                                    ...prevState,
                                    form: {
                                      ...prevState.form,
                                      phone: addCountryCodeToNumber(localValue)
                                    }
                                  }));
                                }}
                                onBlur={() => validateProfileForm({
                                  form: state.form,
                                  field: key
                                })}
                              />
                            );
                          } else {
                            elem = (
                              <TextInput
                                hasChanged={hasChanged}
                                disabled={restrictedProfileEdit(state.user.data!, key)}
                                widthM={'100%'}
                                widthT={'35rem'}
                                value={value}
                                error={profileFormErrors[key]}
                                label={t(key)}
                                onChange={(e) => onChangeFormField(e, key)}
                                onBlur={() => validateProfileForm({
                                  form: state.form,
                                  field: key
                                })}
                              />
                            );
                          }
                        }
                      }
                      break;
                  }

                  if (!elem) {
                    return null;
                  }

                  return (
                    <AdminFormLine key={index} marginBottom>
                      {elem}
                    </AdminFormLine>
                  );
                })
              }
            </fieldset>
          </FormSection>
          {(state.userUpdate.active) && (
            <AdminFormLine
              topPadding
              spaceBetween
            >
              <Button
                type={'button'}
                onClick={onCancelBasicInfoEdit}
              >Cancel</Button>
              <PrimaryButton
                type={'submit'}
                style={{ marginLeft: '1rem' }}
                disabled={basicInfoSubmitButtonDisabled}
                loading={state.userUpdate.loading}
                spinnerColor={theme.colors.coreSecondary}
              >Update</PrimaryButton>
            </AdminFormLine>
          )}
        </FormWrapper>
      </Card>
    );
  }, [
    t,
    state,
    params.clientId,
    isMobile,
    userData.user,
    profileFormErrors,
    basicInfoSubmitButtonDisabled,
    // onCancel,
    onChangeFormField,
    onChangeFormFieldBoolean,
    onBasicInfoSubmit,
    onBasicInfoEdit,
    onCancelBasicInfoEdit,
    validateProfileForm
  ]);

  const renderSecurity = useCallback(() => {
    if (!state.user.fields || !state.user.data) {
      return (
        <div>
          <p>{errorMessage}</p>
        </div>
      );
    }

    return (
      <Card>
        <AdminFormLine
          row
          centerV
          spaceBetween
          marginBottom
          style={{paddingBottom: '.5rem'}}
        >
          <h3 style={{ marginBottom: 0 }}>Security</h3>
          <AdminFormLine>
            {canUpdateUser(params.clientId, userData.user!) && (
              <Button
                style={{marginBottom: 0}}
                icon={<Key style={{ width: '1.75rem', height: 'auto' }} />}
                onClick={onChangePassword}
                disabled={state.securityUpdate.active}
              >{isMobile ? '' : 'Change password'}</Button>
            )}
          </AdminFormLine>
        </AdminFormLine>

        <Sections fullWidth>
          <AdminFormLine column marginBottom>
            <AdminFormLine marginBottom>
              <h4>Last sign in</h4>
            </AdminFormLine>
            <AdminFormLine
              column
              marginBottom
            >
              <Text
                label="Time"
                value={userData.user ? format(new Date(userData.user.signedIn.on), 'dd/MM/yyyy, HH:mm') : ''}
              />
            </AdminFormLine>
            <AdminFormLine
              column
              marginBottom
            >
              <Text
                label="Device"
                value={
                  <>
                    <span>{userData.user?.signedIn.ua.browser.name}</span>
                    <span>,&nbsp;{userData.user?.signedIn.ua.device.vendor}</span>
                    <span>&nbsp;{userData.user?.signedIn.ua.device.model}</span>
                  </>
                }
              />
            </AdminFormLine>
            <AdminFormLine
              column
              marginBottom
            >
              <Text
                label="Location"
                value={userData.user?.signedIn.ip}
              />
            </AdminFormLine>
          </AdminFormLine>
          <AdminFormLine column>
            <AdminFormLine marginBottom>
              <h4>2FA</h4>
            </AdminFormLine>
            <AdminFormLine
              column
              marginBottom
            >
              <Text
                label="Method"
                value="Email"
                tooltip="This is the code that is sent to your inbox to verify your identity on login"
              />
            </AdminFormLine>
          </AdminFormLine>
        </Sections>
      </Card>
    );
  }, [
    state,
    params.clientId,
    userData.user,
    isMobile,
    onChangePassword,
  ]);

  return (
    <Wrapper>
      <PageHeader title={'User Profile'} />
      {renderBasicInfo()}
      <AdminFormLine marginBottom />
      {renderSecurity()}
      {renderConfirmAccountDeleteModal()}
      {renderChangePasswordModal()}
    </Wrapper>
  );
};

export default memo(Profile);

