import React, { FC, memo, useCallback, useEffect, useRef, useState } from 'react';

import {
  Background,
  HeaderLanding,
  Footer
} from 'components/molecules';

import TxtType, { items } from './TxtType';
import { PageProps } from 'components/AppRouter';
import { useMediaSizes } from '../adminDashboard/hooks';

import {
  Wrapper,
  TopContainer,
  ContentContainer,
  TypeWriter,
  FormLine,
  Block,
  ScrollBtn,
  StyledArrow,
  StyledPercentage,
  PricingOuter,
  PricingTextBlock,
  StyledOptions,
  WhatOuter,
  WhatTextBlock,
  Text,
  WhatFeatures,
  StyledTick,
  HowOuter,
  HowList,
  HowListItem,
  StyledProfessor,
  SecurityOuter,
  StyledSecurity,
  SecurityTextBlock,
  SecurityList,
  StyledLock,
  StyledShield,
  StyledBilling
} from './Home.styles';

const Home: FC<PageProps> = props => {
  const txtTypeRef = useRef<TxtType | null>(null);
  const typeWriterElemRef = useRef<HTMLDivElement>(null);
  const topConRef = useRef<HTMLDivElement>(null);
  const contentConRef = useRef<HTMLDivElement>(null);
  const whatRef = useRef<HTMLDivElement>(null);
  const howRef = useRef<HTMLDivElement>(null);
  const secureRef = useRef<HTMLDivElement>(null);
  const yScrollPosRef = useRef<{ curr: number; prev: number }>({ curr: 0, prev: 0 });

  const { isMobile } = useMediaSizes();

  const [state, setState] = useState({
    floatHeader: false,
    hideHeader: false
  });

  const scroll = useCallback((direction: number) => {
    switch (direction) {
      case -1:
        contentConRef.current!.scrollIntoView({ behavior: 'smooth' });
        break;
      case 1:
        topConRef.current!.scrollIntoView({ behavior: 'smooth' });
        break;
    }
  }, []);

  useEffect(() => {
    if (!txtTypeRef.current) {
      txtTypeRef.current = new TxtType(typeWriterElemRef.current, items, topConRef.current);
    }
  }, []);

  const onScroll = useCallback((e: any) => {
    const scrollTop: number = e.target.scrollTop;
    const headerHeight: number = 69;
    const bodyHeight: number = document.body.offsetHeight;

    yScrollPosRef.current.curr = scrollTop;
    const deltaScroll: number = yScrollPosRef.current.curr - yScrollPosRef.current.prev;
    const whatRefBoundingRect = whatRef.current?.getBoundingClientRect();

    if (whatRef.current) {
      let zoneHeight: number = 0;
      const pointElems = whatRef.current.querySelectorAll('.wp');
      pointElems.forEach(curr => zoneHeight += (curr as HTMLDivElement).offsetHeight, 0);
      const heightRatio = parseFloat(Number(zoneHeight / bodyHeight).toFixed(1));

      const offsetY = isMobile ? 50 : heightRatio < 0.2 ? -300 : 0;
      zoneHeight *= .3;

      const sectionY1: number = (whatRefBoundingRect?.top ?? 0) + offsetY + scrollTop;

      const opacityRange: [number, number] = [0, 1];
      const translateRange: [number, number] = [-30, 0];
      const opacityStep: number = opacityRange[1] / zoneHeight;
      const translateStep: number = Math.abs(translateRange[0]) / zoneHeight;
      const scrollProgress: number = Math.abs((whatRefBoundingRect?.top ?? 0) + offsetY - headerHeight);

      // point 1
      const point1Opacity = scrollTop < sectionY1 - headerHeight ? opacityRange[0] : scrollTop > sectionY1  - headerHeight + zoneHeight * 1 ? opacityRange[1] : scrollProgress * opacityStep;
      const point1Translate = scrollTop < sectionY1 - headerHeight ? translateRange[0] : scrollTop > sectionY1 - headerHeight + zoneHeight * 1 ? translateRange[1] : scrollProgress * translateStep - 30;
      (pointElems[0] as HTMLDivElement).style.opacity = `${point1Opacity}`;
      (pointElems[0] as HTMLDivElement).style.transform = `translateY(${point1Translate}px)`;
      // point 2
      const point2Opacity = scrollTop < sectionY1 - headerHeight + zoneHeight * 1 ? opacityRange[0] : scrollTop > sectionY1 - headerHeight + zoneHeight * 2 ? opacityRange[1] : (scrollProgress - zoneHeight * 1) * opacityStep;
      const point2Translate = scrollTop < sectionY1 - headerHeight + zoneHeight * 1 ? translateRange[0] : scrollTop > sectionY1 - headerHeight + zoneHeight * 2 ? translateRange[1] : (scrollProgress - zoneHeight * 1) * translateStep - 30;
      (pointElems[1] as HTMLDivElement).style.opacity = `${point2Opacity}`;
      (pointElems[1] as HTMLDivElement).style.transform = `translateY(${point2Translate}px)`;
      // point 3
      const point3Opacity = scrollTop < sectionY1 - headerHeight + zoneHeight * 2 ? opacityRange[0] : scrollTop > sectionY1 - headerHeight + zoneHeight * 3 ? opacityRange[1] : (scrollProgress - zoneHeight * 2) * opacityStep;
      const point3Translate = scrollTop < sectionY1 - headerHeight + zoneHeight * 2 ? translateRange[0] : scrollTop > sectionY1 - headerHeight + zoneHeight * 3 ? translateRange[1] : (scrollProgress - zoneHeight * 2) * translateStep - 30;
      (pointElems[2] as HTMLDivElement).style.opacity = `${point3Opacity}`;
      (pointElems[2] as HTMLDivElement).style.transform = `translateY(${point3Translate}px)`;
      // point 4
      const point4Opacity = scrollTop < sectionY1 - headerHeight + zoneHeight * 3 ? opacityRange[0] : scrollTop > sectionY1 - headerHeight + zoneHeight * 4 ? opacityRange[1] : (scrollProgress - zoneHeight * 3) * opacityStep;
      const point4Translate = scrollTop < sectionY1 - headerHeight + zoneHeight * 3 ? translateRange[0] : scrollTop > sectionY1 - headerHeight + zoneHeight * 4 ? translateRange[1] : (scrollProgress - zoneHeight * 3) * translateStep - 30;
      (pointElems[3] as HTMLDivElement).style.opacity = `${point4Opacity}`;
      (pointElems[3] as HTMLDivElement).style.transform = `translateY(${point4Translate}px)`;
      // point 5
      const point5Opacity = scrollTop < sectionY1 - headerHeight + zoneHeight * 4 ? opacityRange[0] : scrollTop > sectionY1 - headerHeight + zoneHeight * 5 ? opacityRange[1] : (scrollProgress - zoneHeight * 4) * opacityStep;
      const point5Translate = scrollTop < sectionY1 - headerHeight + zoneHeight * 4 ? translateRange[0] : scrollTop > sectionY1 - headerHeight + zoneHeight * 5 ? translateRange[1] : (scrollProgress - zoneHeight * 4) * translateStep - 30;
      (pointElems[4] as HTMLDivElement).style.opacity = `${point5Opacity}`;
      (pointElems[4] as HTMLDivElement).style.transform = `translateY(${point5Translate}px)`;
    }

    if (howRef.current) {
      let zoneHeight: number = 0;
      const pointElems = howRef.current.querySelectorAll('.hp');
      const whiteBoardPointElems = howRef.current.querySelectorAll('line');
      pointElems.forEach(curr => zoneHeight += (curr as HTMLDivElement).offsetHeight, 0);
      zoneHeight *= .3;

      const offsetY = -100;

      const howRefBoundingRect = howRef.current.getBoundingClientRect();
      const sectionY1: number = howRefBoundingRect.top + offsetY + scrollTop;

      const opacityRange: [number, number] = [0, 1];
      const translateRange: [number, number] = [-30, 0];
      const opacityStep: number = opacityRange[1] / zoneHeight;
      const translateStep: number = Math.abs(translateRange[0]) / zoneHeight;
      const scrollProgress: number = Math.abs(howRefBoundingRect.top + offsetY - headerHeight);

      // point 1
      const point1Opacity = scrollTop < sectionY1 - headerHeight ? opacityRange[0] : scrollTop > sectionY1 - headerHeight + zoneHeight * 1 ? opacityRange[1] : scrollProgress * opacityStep;
      const point1Translate = scrollTop < sectionY1 - headerHeight ? translateRange[0] : scrollTop > sectionY1 - headerHeight + zoneHeight * 1 ? translateRange[1] : scrollProgress * translateStep - 30;
      (pointElems[0] as HTMLDivElement).style.opacity = `${point1Opacity}`;
      (pointElems[0] as HTMLDivElement).style.transform = `translateY(${point1Translate}px)`;
      whiteBoardPointElems[0].style.opacity = `${point1Opacity}`;
      whiteBoardPointElems[0].style.transform = `translateY(${point1Translate}px)`;
      // point 2
      const point2Opacity = scrollTop < sectionY1 - headerHeight + zoneHeight * 1 ? opacityRange[0] : scrollTop > sectionY1 - headerHeight + zoneHeight * 2 ? opacityRange[1] : (scrollProgress - zoneHeight * 1) * opacityStep;
      const point2Translate = scrollTop < sectionY1 - headerHeight + zoneHeight * 1 ? translateRange[0] : scrollTop > sectionY1 - headerHeight + zoneHeight * 2 ? translateRange[1] : (scrollProgress - zoneHeight * 1) * translateStep - 30;
      (pointElems[1] as HTMLDivElement).style.opacity = `${point2Opacity}`;
      (pointElems[1] as HTMLDivElement).style.transform = `translateY(${point2Translate}px)`;
      whiteBoardPointElems[1].style.opacity = `${point2Opacity}`;
      whiteBoardPointElems[1].style.transform = `translateY(${point2Translate}px)`;
      // point 3
      const point3Opacity = scrollTop < sectionY1 - headerHeight + zoneHeight * 2 ? opacityRange[0] : scrollTop > sectionY1 - headerHeight + zoneHeight * 3 ? opacityRange[1] : (scrollProgress - zoneHeight * 2) * opacityStep;
      const point3Translate = scrollTop < sectionY1 - headerHeight + zoneHeight * 2 ? translateRange[0] : scrollTop > sectionY1 - headerHeight + zoneHeight * 3 ? translateRange[1] : (scrollProgress - zoneHeight * 2) * translateStep - 30;
      (pointElems[2] as HTMLDivElement).style.opacity = `${point3Opacity}`;
      (pointElems[2] as HTMLDivElement).style.transform = `translateY(${point3Translate}px)`;
      whiteBoardPointElems[2].style.opacity = `${point3Opacity}`;
      whiteBoardPointElems[2].style.transform = `translateY(${point3Translate}px)`;
      // point 4
      const point4Opacity = scrollTop < sectionY1 - headerHeight + zoneHeight * 3 ? opacityRange[0] : scrollTop > sectionY1 - headerHeight + zoneHeight * 4 ? opacityRange[1] : (scrollProgress - zoneHeight * 3) * opacityStep;
      const point4Translate = scrollTop < sectionY1 - headerHeight + zoneHeight * 3 ? translateRange[0] : scrollTop > sectionY1 - headerHeight + zoneHeight * 4 ? translateRange[1] : (scrollProgress - zoneHeight * 3) * translateStep - 30;
      (pointElems[3] as HTMLDivElement).style.opacity = `${point4Opacity}`;
      (pointElems[3] as HTMLDivElement).style.transform = `translateY(${point4Translate}px)`;
      whiteBoardPointElems[3].style.opacity = `${point4Opacity}`;
      whiteBoardPointElems[3].style.transform = `translateY(${point4Translate}px)`;
      // point 5
      const point5Opacity = scrollTop < sectionY1 - headerHeight + zoneHeight * 4 ? opacityRange[0] : scrollTop > sectionY1 - headerHeight + zoneHeight * 5 ? opacityRange[1] : (scrollProgress - zoneHeight * 4) * opacityStep;
      const point5Translate = scrollTop < sectionY1 - headerHeight + zoneHeight * 4 ? translateRange[0] : scrollTop > sectionY1 - headerHeight + zoneHeight * 5 ? translateRange[1] : (scrollProgress - zoneHeight * 4) * translateStep - 30;
      (pointElems[4] as HTMLDivElement).style.opacity = `${point5Opacity}`;
      (pointElems[4] as HTMLDivElement).style.transform = `translateY(${point5Translate}px)`;
      whiteBoardPointElems[4].style.opacity = `${point5Opacity}`;
      whiteBoardPointElems[4].style.transform = `translateY(${point5Translate}px)`;
      // point 6
      const point6Opacity = scrollTop < sectionY1 - headerHeight + zoneHeight * 5 ? opacityRange[0] : scrollTop > sectionY1 - headerHeight + zoneHeight * 6 ? opacityRange[1] : (scrollProgress - zoneHeight * 5) * opacityStep;
      const point6Translate = scrollTop < sectionY1 - headerHeight + zoneHeight * 5 ? translateRange[0] : scrollTop > sectionY1 - headerHeight + zoneHeight * 6 ? translateRange[1] : (scrollProgress - zoneHeight * 5) * translateStep - 30;
      (pointElems[5] as HTMLDivElement).style.opacity = `${point6Opacity}`;
      (pointElems[5] as HTMLDivElement).style.transform = `translateY(${point6Translate}px)`;
      whiteBoardPointElems[5].style.opacity = `${point6Opacity}`;
      whiteBoardPointElems[5].style.transform = `translateY(${point6Translate}px)`;
    }

    if (secureRef.current) {
      let zoneHeight: number = 0;
      const pointElems = secureRef.current.querySelectorAll('.sp');
      const screenPointElems = secureRef.current.querySelectorAll('g');
      pointElems.forEach(curr => zoneHeight += (curr as HTMLDivElement).offsetHeight, 0);
      const heightRatio = parseFloat(Number(zoneHeight / bodyHeight).toFixed(1));

      const offsetY = heightRatio < 0.2 ? -300 : 0;
      zoneHeight *= .5;

      const secureRefBoundingRect = secureRef.current.getBoundingClientRect();
      const sectionY1: number = secureRefBoundingRect.top + offsetY + scrollTop;

      const opacityRange: [number, number] = [0, 1];
      const translateRange: [number, number] = [-30, 0];
      const opacityStep: number = opacityRange[1] / zoneHeight;
      const translateStep: number = Math.abs(translateRange[0]) / zoneHeight;
      const scrollProgress: number = Math.abs(secureRefBoundingRect.top + offsetY - headerHeight);

      // point 1
      const point1Opacity = scrollTop < sectionY1 - headerHeight ? opacityRange[0] : scrollTop > sectionY1 - headerHeight + zoneHeight * 1 ? opacityRange[1] : scrollProgress * opacityStep;
      const point1Translate = scrollTop < sectionY1 - headerHeight ? translateRange[0] : scrollTop > sectionY1 - headerHeight + zoneHeight * 1 ? translateRange[1] : scrollProgress * translateStep - 30;
      (pointElems[0] as HTMLDivElement).style.opacity = `${point1Opacity}`;
      (pointElems[0] as HTMLDivElement).style.transform = `translateY(${point1Translate}px)`;
      screenPointElems[0].style.opacity = `${point1Opacity}`;
      screenPointElems[0].style.transform = `translateY(${point1Translate}px)`;
      // point 2
      const point2Opacity = scrollTop < sectionY1 - headerHeight + zoneHeight * 1 ? opacityRange[0] : scrollTop > sectionY1 - headerHeight + zoneHeight * 2 ? opacityRange[1] : (scrollProgress - zoneHeight * 1) * opacityStep;
      const point2Translate = scrollTop < sectionY1 - headerHeight + zoneHeight * 1 ? translateRange[0] : scrollTop > sectionY1 - headerHeight + zoneHeight * 2 ? translateRange[1] : (scrollProgress - zoneHeight * 1) * translateStep - 30;
      (pointElems[1] as HTMLDivElement).style.opacity = `${point2Opacity}`;
      (pointElems[1] as HTMLDivElement).style.transform = `translateY(${point2Translate}px)`;
      screenPointElems[2].style.opacity = `${point2Opacity}`;
      screenPointElems[2].style.transform = `translateY(${point2Translate}px)`;
      // point 3
      const point3Opacity = scrollTop < sectionY1 - headerHeight + zoneHeight * 2 ? opacityRange[0] : scrollTop > sectionY1 - headerHeight + zoneHeight * 3 ? opacityRange[1] : (scrollProgress - zoneHeight * 2) * opacityStep;
      const point3Translate = scrollTop < sectionY1 - headerHeight + zoneHeight * 2 ? translateRange[0] : scrollTop > sectionY1 - headerHeight + zoneHeight * 3 ? translateRange[1] : (scrollProgress - zoneHeight * 2) * translateStep - 30;
      (pointElems[2] as HTMLDivElement).style.opacity = `${point3Opacity}`;
      (pointElems[2] as HTMLDivElement).style.transform = `translateY(${point3Translate}px)`;
      screenPointElems[1].style.opacity = `${point3Opacity}`;
      screenPointElems[1].style.transform = `translateY(${point3Translate}px)`;
    }

    if (scrollTop === 0) {
      setState(prevState => ({
        ...prevState,
        floatHeader: false
      }));
    } else {
      setState(prevState => ({
        ...prevState,
        floatHeader: true
      }));
    }

    if (deltaScroll < 0) {
      setState(prevState => ({
        ...prevState,
        hideHeader: false
      }));
    } else {
      if ((whatRefBoundingRect?.top ?? 0) <= 0) {
        setState(prevState => ({
          ...prevState,
          hideHeader: true
        }));
      }
    }

    yScrollPosRef.current.prev = yScrollPosRef.current.curr;
  }, [isMobile]);

  const addScrollListener = useCallback(() => {
    const rootElem: HTMLElement | null = document.querySelector('#root');

    if (rootElem) {
      rootElem.addEventListener('scroll', onScroll, false);
    }
  }, [onScroll]);

  const removeScrollListener = useCallback(() => {
    const rootElem: HTMLElement | null = document.querySelector('#root');

    if (rootElem) {
      rootElem.removeEventListener('scroll', onScroll);
    }
  }, [onScroll]);

  useEffect(() => {
    return () => {
      removeScrollListener();
    };
  }, [removeScrollListener]);

  useEffect(() => {
    addScrollListener();
  }, [addScrollListener]);

  return (
    <>
      <Background />
      <Wrapper>
        <HeaderLanding
          float={state.floatHeader}
          hideHeader={state.hideHeader}
        />
        <TopContainer ref={topConRef}>
          <TypeWriter ref={typeWriterElemRef} />
          <ScrollBtn
            onClick={() => scroll(-1)}
          >
            <StyledArrow />
          </ScrollBtn>
        </TopContainer>
        <ContentContainer ref={contentConRef}>
          <Block
            altTheme
            id="what-is-it"
            className="pinnable"
            ref={whatRef}
          >
            <h2>What is it?</h2>
            <WhatOuter
              column
              spaceBetween
              centerV
            >
              <StyledOptions/>
              <WhatTextBlock column>
                <h3>A booking system that manages your workstream</h3>
                <WhatFeatures column>
                  <FormLine
                    row
                    centerV
                    marginBottom
                    className="wp"
                  >
                    <StyledTick/>
                    <Text>Easily collect customer requirements</Text>
                  </FormLine>
                  <FormLine
                    row
                    centerV
                    marginBottom
                    className="wp"
                  >
                    <StyledTick/>
                    <Text>Securely accept payments</Text>
                  </FormLine>
                  <FormLine
                    row
                    centerV
                    marginBottom
                    className="wp"
                  >
                    <StyledTick/>
                    <Text>Manage work allocation</Text>
                  </FormLine>
                  <FormLine
                    row
                    centerV
                    marginBottom
                    className="wp"
                  >
                    <StyledTick/>
                    <Text>View insights into your business</Text>
                  </FormLine>
                  <FormLine
                    row
                    centerV
                    marginBottom
                    className="wp"
                  >
                    <StyledTick/>
                    <Text>Invoice synching with QuickBooks and much more...</Text>
                  </FormLine>
                </WhatFeatures>
              </WhatTextBlock>
            </WhatOuter>
          </Block>
          <Block
            id="how-does-it-work"
            className="pinnable"
            ref={howRef}
          >
            <h2>How does it work?</h2>
            <HowOuter
              column
              spaceBetween
              centerV
            >
              <HowList>
                <HowListItem className="hp">
                  <Text>Create a service (what is the customer booking)</Text>
                </HowListItem>
                <HowListItem className="hp">
                  <Text>Add pricing rules to determine how your customer is charged based on their selection</Text>
                </HowListItem>
                <HowListItem className="hp">
                  <Text>Setup your secure customer payment method by linking your payment serivce provider account (Stripe)</Text>
                </HowListItem>
                <HowListItem className="hp">
                  <Text>Add a fulfiller to your workforce (the person who will carry out the service)</Text>
                </HowListItem>
                <HowListItem className="hp">
                  <Text>Add the link where your customer can book your services to your website. Or send them the link directly if you don't have a website</Text>
                </HowListItem>
                <HowListItem className="hp">
                  <Text>That's it! Get back to running your business</Text>
                </HowListItem>
              </HowList>
              <StyledProfessor />
            </HowOuter>
          </Block>
          <Block
            altTheme
            id="pricing"
            className="pinnable"
          >
            <h2>How much will it cost you?</h2>
            <PricingOuter
              column
              spaceBetween
              centerV
            >
              <PricingTextBlock column>
                <h3>Transparency is key</h3>
                <Text>£24.99 per month, no hidden fees</Text>
              </PricingTextBlock>
              <StyledPercentage />
            </PricingOuter>
          </Block>
          <Block
            id="secure"
            className="pinnable"
            ref={secureRef}
          >
            <h2>Secure</h2>
            <SecurityOuter
              column
              spaceBetween
              centerV
            >
              <StyledSecurity />
              <SecurityTextBlock column>
                <h3>We take security seriously</h3>
                <SecurityList column>
                  <FormLine
                    row
                    centerV
                    marginBottom
                    className="sp"
                  >
                    <StyledLock />
                    <Text>All data is securely transported between servers and devices</Text>
                  </FormLine>
                  <FormLine
                    row
                    centerV
                    marginBottom
                    className="sp"
                  >
                    <StyledShield />
                    <Text>Where possible we use encryption to transport sensitive information</Text>
                  </FormLine>
                  <FormLine
                    row
                    centerV
                    marginBottom
                    className="sp"
                  >
                    <StyledBilling />
                    <Text>We've partnered with the most trusted payment service providers for collecting payments</Text>
                  </FormLine>
                </SecurityList>
              </SecurityTextBlock>
            </SecurityOuter>
          </Block>
          <Block altTheme>
            <Footer />
            <ScrollBtn
              bottom
              onClick={() => scroll(1)}
            >
              <StyledArrow />
            </ScrollBtn>
          </Block>
        </ContentContainer>
      </Wrapper>
    </>
  );
};

export default memo(Home);

