import React, { FC, memo, useState, useCallback, useEffect } from 'react';

import {
  Background,
  HeaderLanding,
  Footer
} from 'components/molecules';

import { PageProps } from 'components/AppRouter';
import { FormLine } from 'theme/mixins';

import {
  Wrapper,
  ContentContainer,
  Block,
  Text
} from './Privacy.styles';

const Privacy: FC<PageProps> = props => {
  const [state, setState] = useState({ floatHeader: false });

  const onScroll = useCallback((e: any) => {
    const scrollTop: number = e.target.scrollTop;

    if (scrollTop === 0) {
      setState(prevState => ({
        ...prevState,
        floatHeader: false
      }));
    } else {
      setState(prevState => ({
        ...prevState,
        floatHeader: true
      }));
    }
  }, []);

  const addScrollListener = useCallback(() => {
    const rootElem: HTMLElement | null = document.querySelector('#root');

    if (rootElem) {
      rootElem.addEventListener('scroll', onScroll, false);
    }
  }, [onScroll]);

  const removeScrollListener = useCallback(() => {
    const rootElem: HTMLElement | null = document.querySelector('#root');

    if (rootElem) {
      rootElem.removeEventListener('scroll', onScroll);
    }
  }, [onScroll]);

  useEffect(() => {
    return () => {
      removeScrollListener();
    };
  }, [removeScrollListener]);

  useEffect(() => {
    addScrollListener();
  }, [addScrollListener]);

  return (
    <>
      <Background />
      <Wrapper>
        <HeaderLanding float={state.floatHeader} />
        <ContentContainer>
          <Block>
            <h2>Privacy Policy</h2>
            <Text>This Privacy Policy outlines how GoBook'em Limited ("GoBook'em," "we," "our," or "us") collects, uses, discloses, and protects your personal information. By using our services, you agree to the terms and practices described in this Privacy Policy.</Text>
            <br />
            <br />

            <h3>Information We Collect</h3>
            <Text>We may collect various types of information, including:</Text>
            <br />
            <Text>Personal Information: This includes information you provide when registering for our services, such as your name, contact details, and payment information.</Text>
            <br />
            <Text>Usage Information: We collect data on how you interact with our platform, including log files, device information, and usage patterns.</Text>
            <br />
            <Text>User Content: Any content you create, upload, or share on our platform, including bookings and reviews.</Text>
            <br />
            <br />

            <h3>How We Use Your Information</h3>
            <Text>We use your information for the following purposes:</Text>
            <br />
            <Text>Service Provision: To provide and maintain our services, process bookings, and improve user experience.</Text>
            <br />
            <Text>Communication: To communicate with you about your bookings, updates, and promotional offers.</Text>
            <br />
            <Text>Analytics: To analyze usage patterns and improve our services.</Text>
            <br />
            <Text>Legal Compliance: To comply with legal obligations and respond to legal requests.</Text>
            <br />
            <br />

            <h3>Information Sharing</h3>
            <Text>We may share your information in the following situations:</Text>
            <br />
            <Text>Service Providers: With third-party service providers who assist in delivering our services.</Text>
            <br />
            <Text>Legal Compliance: When required by law or to protect our rights and safety.</Text>
            <br />
            <Text>Consent: With your consent or as directed by you.</Text>
            <br />
            <br />

            <h3>Your Choices</h3>
            <Text>You have the following choices regarding your information:</Text>
            <br />
            <Text>Access and Update: You can access and update your personal information through your account settings.</Text>
            <br />
            <Text>Communication Preferences: You can choose your communication preferences, including opting out of promotional emails.</Text>
            <br />
            <Text>Deactivation: You can deactivate your account; however, some information may be retained as required by law or for legitimate business purposes.</Text>
            <br />
            <br />

            <h3>Security Measures</h3>
            <Text>We implement security measures to protect your information from unauthorized access and disclosure. However, no method of transmission over the internet or electronic storage is entirely secure, and we cannot guarantee absolute security.</Text>
            <br />
            <br />

            <h3>Children's Privacy</h3>
            <Text>Our services are not intended for individuals under the age of 13. We do not knowingly collect personal information from children. If you become aware that a child has provided us with personal information, please contact us, and we will take steps to delete such information.</Text>
            <br />
            <br />

            <h3>Changes to this Privacy Policy</h3>
            <Text>We may update this Privacy Policy to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any significant changes through our platform or other means.</Text>
            <br />
            <br />

            <h3>Contact Us</h3>
            <Text>If you have questions or concerns about this Privacy Policy or our privacy practices, you can contact us at:</Text>
            <br />
            <Text>GoBook'em Limited</Text>
            <Text>157 Worlds End Lane</Text>
            <Text>Quinton</Text>
            <Text>B32 1JX</Text>
            <Text>Email: privacy@gobookem.com</Text>
            <br />
            <Text>This Privacy Policy was last updated on 19/12/2023.</Text>
            <br />
          </Block>
        </ContentContainer>
        <FormLine
          center
          marginBottom
        >
          <Footer />
        </FormLine>
      </Wrapper>
    </>
  );
};

export default memo(Privacy);

