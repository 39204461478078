import styled from 'styled-components';

import { AdminFormLine  } from 'theme/mixins';
export * from 'theme/mixins';

export const RightContentWrapper = styled(AdminFormLine)`
  > * {
    margin-left: 1rem;
  }
`;
