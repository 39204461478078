import styled from 'styled-components';
import { device } from 'theme/media';

import {
  Card,
  AdminFormLine
} from 'theme/mixins';
export {
  StyledUnfoldLess,
  StyledUnfoldMore
} from './Service.styles';

export const Wrapper = styled(Card)<{ isLast: boolean; isPreview: boolean; isOver: boolean; }>`
  position: relative;
  border: .2rem solid ${({ theme }) => theme.card.borderColor};

  ${({ theme, isLast, isPreview }) => !isLast && !isPreview && `
    :after {
      content: '';
      position: absolute;
      bottom: -2.4rem;
      left: 50%;
      width .2rem;
      height: 2.2rem;
      background-color: ${theme.card.borderColor};
    }
  `}
`;

export const StepWrapper = styled(AdminFormLine)`
  padding: 3rem;

  @media ${device.onlyMobile} {
    padding: 2rem;
  }
`;

export const StepFields = styled.div<{ isExpanded: boolean; }>`
  display: flex;
  max-height: 0;
  flex-direction: column;
  text-align: left;
  transition: max-height ${({ theme }) => theme.animSpeed} ease-in-out;
  cursor: default;
  overflow: hidden;

  ${({ isExpanded }) => isExpanded && `
    max-height: 200rem;
  `};
`;

export const StepFieldsOuter = styled.div`
  display: flex;
  width: 100%;
  max-height: inherit;
  flex-direction: column;
  // overflow-x: auto;
  overflow-x: hidden;
  // margin-top: 3rem;
  padding-top: 4rem;
  border-top: .1rem solid ${({ theme }) => theme.colors.neutralPrimaryDark};

  @media ${device.onlyMobile} {
    padding-top: 3rem;
  }
`;

export const StepFieldsInner = styled(AdminFormLine)`
  padding-left: 3rem;
  padding-right: 3rem;

  @media ${device.onlyMobile} {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
`;

export const StyledField = styled.p`
  margin-bottom: 1.75rem;
`;

export const FieldEditorWrapper = styled.div`
  margin-top: 3rem;
  padding-top: 2rem;
  border-top: .1rem solid ${({ theme }) => theme.colors.neutralPrimaryDark};
`;

