import styled from 'styled-components';

export const CircleBackground = styled.circle<{ trackBackgroundColour: string }>`
  fill: transparent;
  stroke: ${({ trackBackgroundColour }) => trackBackgroundColour};
`;

export const Circle = styled.circle`
  fill: transparent;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
`;

export const OuterG = styled.g`
  visibility: hidden;
`;

export const StyledG = styled.g<{ pos: number }>`
  ${({ pos }) => pos && `
      transform: translate3d(${pos / 2}px, ${pos / 2}px, 0);
  `}
`;

export const StyledText = styled.text`
  text-anchor: middle;
`;

export const OuterSVG = styled.svg``;
export const InnerSVG = styled(OuterSVG)``;

