import styled from 'styled-components';
import {
  Dropdown,
  Button,
  Cross,
  ArrowRightAlt
} from 'components/atoms';

export * from 'theme/mixins';

export const ListFooter = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const ListHeaderFooterSection = styled.div<{
  column?: boolean;
  vCenter?: boolean;
  showResetButton?: boolean;
}>`
  display: flex;
  flex-direction: row;

  button {
    margin-bottom: 0;

    ${({ showResetButton }) => !showResetButton && `
      &:first-child {
        margin-right: 1rem;
      }
    `}
  }

  ${({ column }) => column && `
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
  `}

  ${({ vCenter }) => vCenter && `
    align-items: center;
  `}
`;

export const FilterHeader = styled.span`
  margin: 0 auto;
`;

export const MenuWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export const MenuItem = styled.li<{
  vCenter?: boolean;
  hCenter?: boolean;
  paddingBottom?: boolean;
  marginBottom?: boolean;
  borderBottom?: boolean;
  column?: boolean;
  row?: boolean;
}>`
  display: flex;
  padding: .5rem 1rem;

  ${({ column }) => column && `
    flex-direction: column;
  `};

  ${({ vCenter }) => vCenter && `
    align-items: center;
  `};

  ${({ hCenter }) => hCenter && `
    justify-content: center;
  `};

  ${({ paddingBottom }) => paddingBottom && `
    padding-bottom: 1rem;
  `};

  ${({ marginBottom }) => marginBottom && `
    margin-bottom: 1rem;
  `};

  ${({ theme, borderBottom }) => borderBottom && `
    border-bottom: .1rem solid ${theme.colors.neutralPrimaryDark};
  `};
`;

export const FilterOptionsElem = styled.div<{ isExpanded: boolean }>`
  display: flex;
  max-height: 0;
  background-color: ${({ theme }) => theme.backgroundColor};
  transition: max-height ${({ theme }) => theme.animSpeed} ease-in-out;

  ${({ isExpanded }) => isExpanded && `
    max-height: 25rem;
  `};
`;

export const FilterOptionsInner = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  text-align: left;
  margin: 1rem 0;
  overflow: auto;
  padding: 0 1rem;
`;

export const StyledDropdown = styled(Dropdown)`
  margin-bottom: 1rem;
`;

export const FilterButton = styled(Button)`
  position: relative;
`;

export const FilterCountBadge = styled.div`
  display: flex;
  position: absolute;
  top: -1rem;
  right: 0;
  width: 2rem;
  height: 2rem;
  background: black;
  color: white;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
`;

export const AppliedFilter = styled.div`
  display: inline-flex;
  align-items: center;
  padding: .75rem .75rem .75rem 1.25rem;
  border-radius: 2rem;
  border: .1rem solid;
  margin-right: 1rem;
  margin-bottom: 1rem;

  button {
    display: inline-flex;
    width: 2rem;
    height: 2rem;
    margin-left: 1rem;
  }
`;

export const StyledCross = styled(Cross)`
  width: 1.3rem;
  height: auto;
`;

export const AppliedFilterHeader = styled.h4`
  margin-bottom: 1rem;
`;

export const SortWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const SortArrowUp = styled(ArrowRightAlt)`
  width: 2rem;
  height: auto;
  transform: rotateZ(-90deg);
`;

export const SortArrowDown = styled(ArrowRightAlt)`
  width: 2rem;
  height: auto;
  transform: rotateZ(90deg);
`;

