import { FormFieldProps } from 'components/atoms/form/FormField';

export interface DropdownOption {
  label: string;
  value: any;
}

export interface BrowserWindow extends Window {
  isChild?: boolean;
  isModifiable?: boolean;
}

export enum DragItem {
  Field = 'field',
  Step = 'step',
  TableRowItem = 'table-row-item',
  Avatar = 'avatar'
}

export interface Form {
  [key: string]: FormFieldProps['value'];
}

export interface GroupedOption<T> {
  readonly label: string;
  readonly options: readonly T[] | readonly T[];
}

