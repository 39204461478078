import React, { FC } from 'react'
import { IconProps } from './types'

const Arrow: FC<IconProps> = ({ className }) => (
  <svg className={className} enableBackground="new 0 0 24 24" height="2.4rem" viewBox="0 0 24 24" width="2.4rem" fill="#000000">
    <g>
      <path d="M0,0h24v24H0V0z" fill="none"/>
    </g>
    <g>
      <polygon points="6.23,20.23 8,22 18,12 8,2 6.23,3.77 14.46,12"/>
    </g>
  </svg>
)

export default Arrow;
