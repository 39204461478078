import React, { FC, memo, useCallback, useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { getScrollParent } from '../../../utils/general';
import { useMediaSizes } from '../../pages/adminDashboard/hooks';

import {
  OuterWrapper,
  Wrapper
} from './Popup.styles';

const getPositionProps = ({
  wrapperElem,
  parentX,
  parentY,
  parentWidth,
  parentHeight,
  top,
  right,
  bottom,
  left,
  centre,
  layered
}: any) => {
  let l: number | null = (parentX || 0);
  let t: number | null = (parentY || 0);
  let r: number | null = (parentX || 0);
  let b: number | null = (parentY || 0);

  // Only set y when visible
  if (!wrapperElem) {
    t = 0;
    b = 0;
  }

  const ownWidth: number = wrapperElem ? wrapperElem.children[0].offsetWidth : 0;
  const ownHeight: number = wrapperElem ? wrapperElem.children[0].offsetHeight : 0;
  const bodyWidth: number = document.body.offsetWidth;
  const bodyHeight: number = document.body.offsetHeight;
  const contentHeight = wrapperElem?.children[0]?.children[0]?.offsetHeight ?? ownHeight;

  let maxHeight: number = 0;

  if (layered || centre) {
    if (!parentWidth || !parentHeight) {
      r = null;
      b = null;

      return {
        l,
        t,
        r,
        b,
        maxHeight
      };
    }

    l = null;
    t = null;
    r = null;
    b = null;
  }
  else {
    if (!parentX || !parentY) {
      r = null;
      b = null;

      return {
        l,
        t,
        r,
        b,
        maxHeight
      };
    }

    if (bottom && centre) {
      l = l! + parentWidth * .5 - ownWidth / 2;
      b += parentHeight;
      r = null;
      t = null;
    } else {
      // TODO: Calculate css bottom
      if (top) {
        t! -= parentHeight;
        b = null;
        // maxHeight = bodyHeight - t!;
      }
      if (right) {
        l = l!;
        r = null;
      }
      // Calculate css top
      if (bottom) {
        t += parentHeight;
        b = null;
      }
      // Calculate css right
      if (left) {
        const leftPos = r! - ownWidth + parentWidth;
        r = bodyWidth - leftPos - ownWidth;
        l = null;
      }
    }
  }

  let availableSpaceAbove: number = 0;
  let availableSpaceBelow: number = 0;
  let useMaxHeight: boolean = false;
  let mostSpace = 0;

  // Shift popup if no space available
  if (t && ownHeight > 0 && !layered) {
    availableSpaceAbove = t - parentHeight;
    availableSpaceBelow = bodyHeight - t;

    useMaxHeight = availableSpaceBelow < contentHeight || availableSpaceAbove < contentHeight;
    mostSpace = Math.max(availableSpaceAbove, availableSpaceBelow);

    if (useMaxHeight && availableSpaceBelow === mostSpace) {
      maxHeight = availableSpaceBelow;
    }

    // No space below
    if (availableSpaceBelow < ownHeight) {
      if (availableSpaceAbove === mostSpace) {
        b = availableSpaceBelow - ownHeight + parentHeight + ownHeight;
        t = null;

        if (useMaxHeight) {
          maxHeight = availableSpaceAbove;
        }
      }
    }
  }

  // console.log(
  //   '-----Popup debug',
  //   '-----ownWidth', ownWidth,
  //   '-----ownHeight', ownHeight,
  //   '-----parentWidth', parentWidth,
  //   '-----parentHeight', parentHeight,
  //   '-----contentHeight', contentHeight,
  //   '-----maxHeight', maxHeight,
  //   '-----availableSpaceAbove', availableSpaceAbove,
  //   '-----availableSpaceBelow', availableSpaceBelow
  // );

  return { l, t, r, b, maxHeight };
};

interface PopupProps {
  id: string | number;
  children: (opts: any) => React.ReactNode;

  // Where the popup should appear in relation the trigger element
  top?: boolean;
  right?: boolean;
  bottom?: boolean;
  left?: boolean;
  centre?: boolean;

  arrow?: boolean;
  noPadding?: boolean;
  layered?: boolean;
  convertable?: boolean;    // If true, popup will be full screen at the bottom on mobile
  parentElementBoundingRect?: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
  maxWidth?: boolean;
  onClose?: (opts?: any) => void;

  style?: any;
}

const Popup: FC<PopupProps> = props => {
  const {
    id,
    top,
    right,
    bottom,
    left,
    centre,
    arrow,
    layered,
    convertable,
    parentElementBoundingRect,
    maxWidth,
    onClose,
    children,
    ...rest
  } = props;

  const [state, setStateOriginal] = useState<any>({});
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const triggerElement = useRef<Element | null>(null);
  const stateRef = useRef(state);
  const mutationObserver = useRef<MutationObserver | null>(null);
  const { isMobile } = useMediaSizes();

  const setState = useCallback((arg: any) => {
    if (typeof arg === 'function') {
      setStateOriginal((prevValue: any) => {
        stateRef.current = arg(prevValue);

        return {
          ...prevValue,
          ...arg(prevValue)
        };
      });
    } else {
      stateRef.current = arg;
      setStateOriginal(arg);
    }
  }, []);

  const closePopup = useCallback((e: any, opts?: any) => {
    // Ignore all scroll events on mobile since we don't
    // want to close the Popup which is mostly covering the screen
    if (e?.type === 'scroll' && isMobile) {
      return;
    }

    const isOutsideClick: boolean = wrapperRef.current && e
      && wrapperRef.current.children[0] !== e.target
      && !wrapperRef.current.children[0].contains(e.target);
    const isCloseAction: boolean = !e;

    const targetDisconnected: boolean = e && !e.target.isConnected;

    // console.log(
    //   '----debug',
    //   '--isOutsideClick', isOutsideClick,
    //   '--isCloseAction', isCloseAction,
    //   '--e.type', e?.type,
    //   '--triggerElement.current', triggerElement.current,
    //   '--e.relatedTarget', e?.relatedTarget,
    //   '--e.target', e?.target,
    //   '--targetDisconnected', targetDisconnected,
    //   '--e', e
    // );

    // Any 3rd party or otherwise element that is no longer part of the DOM
    if (targetDisconnected) {
      // console.log('------ignoring 1');
      return;
    }

    // Ignore any top level (portal) element that contains target elem
    if (wrapperRef.current && e && e.target) {
      const nextSibling = wrapperRef.current.nextSibling;

      if (nextSibling && nextSibling.contains(e.target)) {
        // console.log('------ignoring 2');
        return;
      }
    }

    const isThisPopup: boolean = !!(wrapperRef.current && stateRef.current.activeElement === wrapperRef.current.children[0]);
    // console.log('------isThisPopup', isThisPopup);

    if (isOutsideClick || isCloseAction) {
      // console.log('-------closing 0');

      setState((prevState: any) => ({
        ...prevState,
        activeElement: null
      }));

      if (onClose && isThisPopup) {
        return setTimeout(() => onClose(opts), 250);
      }
    }
  }, [
    setState,
    isMobile,
    onClose
  ]);

  const addScrollEventListener = useCallback(() => {
    if (state.scrollParentElem) {
      // console.log('--------addScrollEventListener', scrollParentElem);
      state.scrollParentElem.addEventListener('scroll', closePopup, false);
    }
  }, [state.scrollParentElem, closePopup]);

  const removeScrollEventListener = useCallback(() => {
    if (state.scrollParentElem) {
      // console.log('--------removeScrollEventListener', scrollParentElem);
      state.scrollParentElem.removeEventListener('scroll', closePopup, false);
    }
  }, [state.scrollParentElem, closePopup]);

  const addPageClickEventListener = useCallback(() => {
    // console.log('--------addPageClickEventListener');
    document.documentElement.addEventListener('click', closePopup, false);
  }, [closePopup]);

  const removePageClickEventListener = useCallback(() => {
    // console.log('--------removePageClickEventListener');
    document.documentElement.removeEventListener('click', closePopup, false);
  }, [closePopup]);

  useEffect(() => {
    if (wrapperRef.current) {
      const parentElem: HTMLElement | null = wrapperRef.current!.parentNode as HTMLDivElement;

      if (parentElem && parentElem !== document.body) {
        const ownWidth = wrapperRef.current.offsetWidth;
        const ownHeight = wrapperRef.current.offsetHeight;
        const { x, y, width, height } = parentElem.getBoundingClientRect();
        const scrollParentElem = getScrollParent(wrapperRef.current);

        // console.log('-------------------', x, y, width, height, ownWidth, parentElem);

        setState((prevState: any) => ({
          ...prevState,
          id,
          ownWidth,
          ownHeight,
          parentX: x,
          parentY: y,
          parentWidth: width,
          parentHeight: height,
          parentElemCaptured: true,
          scrollParentElem
        }));

        // console.log('--------parent ready');
      }

      else if (state.parentElemCaptured && parentElem === document.body) {
        // console.log('--------parent body ready', wrapperRef.current.offsetWidth);

        triggerElement.current = document.activeElement;

        (wrapperRef.current.children[0] as HTMLDivElement).focus();
        // console.log('----focusing');

        setState((prevState: any) => ({
          ...prevState,
          activeElement: document.activeElement,
          mountedToBody: true
        }));
      }
    }
  }, [
    id,
    parentElementBoundingRect,
    setState,
    state.parentElemCaptured
  ]);

  useEffect(() => {
    addScrollEventListener();
    addPageClickEventListener();

    return () => {
      removeScrollEventListener();
      removePageClickEventListener();
    };
  }, [
    addScrollEventListener,
    addPageClickEventListener,
    removeScrollEventListener,
    removePageClickEventListener
  ]);

  // Observe node insertion to ensure only one popup is on screen at any time
  useEffect(() => {
    if (!mutationObserver.current) {
      mutationObserver.current = new MutationObserver((mutationList) => {
        for (const mutation of mutationList) {
          if (mutation.type === 'childList') {
            if (
              mutation.addedNodes.length
              && (mutation.addedNodes[0] as HTMLDivElement).getAttribute
              && (mutation.addedNodes[0] as HTMLDivElement).getAttribute('id')?.includes('popup')
            ) {
              // console.log(
              //   id,
              //   'A child node has been added or removed.',
              //   mutation.addedNodes,
              //   mutation.removedNodes
              // );

              const addedPopupId: string = ((mutation.addedNodes[0] as HTMLDivElement).getAttribute('id') || '').replace('popup-', '');

              if (id !== addedPopupId) {
                closePopup(null);
              }
            }
          }
        }
      });

      mutationObserver.current.observe(
        document.documentElement,
        { childList: true, subtree: true }
      );
    }

    return () => {
      if (mutationObserver.current) {
        mutationObserver.current.disconnect();
      }
    };
  // eslint-disable-next-line
  }, []);

  const pos = getPositionProps({
    wrapperElem: wrapperRef.current,
    parentX: parentElementBoundingRect ? parentElementBoundingRect.x : state.parentX,
    parentY: parentElementBoundingRect ? parentElementBoundingRect.y : state.parentY,
    parentWidth: parentElementBoundingRect ? parentElementBoundingRect.width : state.parentWidth,
    parentHeight: parentElementBoundingRect ? parentElementBoundingRect.height : state.parentHeight,
    top,
    right,
    bottom,
    left,
    centre,
    layered
  });

  // console.log('-----Popup state', state, state.activeElement, wrapperRef.current);
  // console.log('-----Popup state', id, wrapperRef.current, pos);

  const hasFocus: boolean = !!(state.parentElemCaptured && wrapperRef.current && state.activeElement === wrapperRef.current.children[0]);

  // console.log(`------hasFocus-${id}`, hasFocus);

  const core = (
    <OuterWrapper
      ref={wrapperRef}
      id={`popup-${id}`}
      isLayered={!!layered}
      isConvertable={!!convertable}
      hasFocus={hasFocus}
      onClick={(e) => closePopup(e)}
    >
      <Wrapper
        {...rest}
        tabIndex={-1}
        left={pos.l}
        top={pos.t}
        right={pos.r}
        bottom={pos.b}
        maxHeight={pos.maxHeight}
        maxWidth={maxWidth}
      >
        {children({
          closePopup
        })}
        {arrow && (
          <i />
        )}
      </Wrapper>
    </OuterWrapper>
  );

  if (state.parentElemCaptured) {
    return (
      ReactDOM.createPortal(
        <>
          {core}
        </>,
        document.body
      )
    );
  }

  return core;
};

export default memo(Popup);

