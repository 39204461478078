import React, { FC, memo } from 'react';

import { FormLine } from 'theme/mixins';

import {
  Wrapper
} from './RecaptchaNotice.styles';

const RecaptchaNotice: FC = props => {
  return (
    <Wrapper>
      <FormLine marginBottom />

      <span>This site is protected by reCAPTCHA and the Google </span>
      <span><a href="https://policies.google.com/privacy">Privacy Policy</a> and </span>
      <span><a href="https://policies.google.com/terms">Terms of Service</a> apply.</span>
    </Wrapper>
  );
};

export default memo(RecaptchaNotice);

