import { Fulfiller } from 'types/Fulfiller';
import { ApplicationError } from 'types/Error';
import { BaseEntity } from './Generic';

export enum RoleType {
  PlatformAdmin = 'Platform Admin',
  PlatformReviewer = 'Platform Reviewer',
  ClientAdmin = 'Client Admin',
  ClientReviewer = 'Client Reviewer',
  Fulfiller = 'Fulfiller',
  // Customer = 'Customer'
}

export type Access =
 | '*'
 | 'c'
 | 'r'
 | 'u'
 | 'd'
 | 'r-own'
 | 'u-own'
 | 'd-own';

export interface AccessMap {
  [key: string]: {
    access: {
      [key: string]: Access[];
    }
  }
}

export interface Verified {
  status: boolean;
}

export interface BaseUser extends BaseEntity {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  verified: Verified;
  clientId: string;
  creatorId: string;
  colour: string;
  jobsFilter: any;
  phone?: string;
  revoke?: boolean;

  // decorated
  role: string;
  permissions: {
    accessMap: AccessMap;
  };
  signedIn: {
    on: string;
    ua: any;
    ip: string;
  };
  creator?: BaseUser;
  resetUserPassword?: boolean;
  jobId?: string;
  urls?: { [key: string]: string; };
  deactivation?: {
    deactivate: boolean;
    attempts: number;
    on: string;
    requested: string;
  };
}

export interface User extends BaseUser {
  isTrial?: boolean;
}

export interface UserData {
  loading: boolean;
  fields: {
    [key: string]: any;
  };
  user: User | Fulfiller | null;
  errors: ApplicationError[] | null;
}

