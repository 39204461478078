import styled from 'styled-components';

export const StyledInput = styled.input<{
  digits: number;
  isFocused: boolean;
}>`
  --otp-digits: ${({ digits }) => digits};
  --otp-ls: 2ch;
  --otp-gap: 1.25;

  /* private consts */
  --_otp-bgsz: calc(var(--otp-ls) + 1ch);
  --_otp-digit: 0;

  all: unset;
  background: linear-gradient(
    90deg,
    var(--otp-bg, ${({ theme, isFocused }) => `${isFocused ? theme.colors.corePrimaryLighter : '#EEE'}`}) calc(var(--otp-gap) * var(--otp-ls)),
    transparent 0),
    linear-gradient(90deg,
    var(--otp-bg, #EEE) calc(var(--otp-gap) * var(--otp-ls)),
    transparent 0
  );
  background-repeat: no-repeat, repeat-x;
  background-size: var(--_otp-bgsz) 100%;
  caret-color: var(--otp-cc, #222);
  caret-shape: block;
  clip-path: inset(0% calc(var(--otp-ls) / 2) 0% 0%);
  font-size: var(--otp-fz, 2.5rem);
  inline-size: calc(var(--otp-digits) * var(--_otp-bgsz));
  letter-spacing: var(--otp-ls);
  padding-block: var(--otp-pb, 1ch);
  padding-inline-start: calc(((var(--otp-ls) - 1ch) / 1.65) * var(--otp-gap));

  ${({ isFocused }) => isFocused && `
    background-position: calc(var(--_otp-digit) * var(--_otp-bgsz)) 0, 0 0;
  `}
`;

