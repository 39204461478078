export const sizes: any = {
  // mobile: 494,
  // tablet: 1023,
  // desktop: 1823

  // mobileS: 320,
  mobile: 0,
  mobileS: 300,
  mobileM: 375,
  mobileL: 425,
  mobileXL: 596,
  tablet: 768,
  laptop: 1024,
  laptopM: 1232,
  laptopL: 1440,
  desktop: 2560
};

export const device = Object.keys(sizes).reduce((acc: any, cur: string) => {
  acc[cur] = `(min-width: ${sizes[cur]}px)`;

  return acc;
}, {
  onlyMobile: `(min-width: ${sizes.mobile}) and (max-width: ${sizes.tablet}px)`,
  onlyMobileAndTablet: `(min-width: ${sizes.mobile}) and (max-width: ${sizes.laptop}px)`
});
