import styled from 'styled-components';
import { device } from 'theme/media';

import {
  InfoIcon,
  Warning,
  ErrorIcon,
  Drag,
  Edit,
  Pound,
  Bin2
} from '../icon';

export const StyledIconWrapper = styled.div<{ left?: boolean; right?: boolean; centre?: boolean; }>`
  display: flex;
  position: absolute;
  top: -1.5rem;
  width: 3rem;
  height: 3rem;
  border-radius: 1.5rem;
  background-color: ${({ theme }) => theme.colors.corePrimary};
  opacity: 0;
  transition: opacity ${({ theme }) => theme.animSpeed};
  cursor: pointer;
  align-items: center;
  justify-content: center;

  ${({ left }) => left && `
    left: -1.5rem;
  `};

  ${({ centre }) => centre && `
    left: calc(50% - 1.5rem);
  `};

  ${({ right }) => right && `
    right: -1.5rem;
  `};
`;

export const EditWrapper = styled.div<{ isFocused: boolean; }>`
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: calc(100% + 2rem);
  height: calc(100% + 2rem);
  margin-left: -1rem;
  margin-top: -1rem;
  border: .2rem dashed ${({ theme }) => theme.colors.corePrimary};
  border-radius: .4rem;
  transition: opacity ${({ theme }) => theme.animSpeed};

  ${({ isFocused }) => isFocused && `
    opacity: 1;
  `}
`;

// eslint-disable-next-line
export const FormFieldWrapper = styled.div<{
  changed?: boolean;
  errored?: boolean;
  width?: string;
  widthM?: string;
  widthML?: string;
  widthT?: string;
  widthL?: string;
  height?: string;
  fluidWidth?: string;
  noWidth?: boolean;
  noMinWidth?: boolean;
  wordBreak?: boolean;
  isSelectable?: boolean;
}>`
  display: block;
  position: relative;
  min-width: 6rem;

  ${({ noMinWidth }) => noMinWidth && `
    min-width: 0;
  `}

  ${({ height }) =>
    height &&
    `
    height: ${height};
  `}

  ${({ isSelectable }) => isSelectable && `
    &:hover {
      ${EditWrapper} {
        opacity: 1;

        ${StyledIconWrapper} {
          opacity: 1;
        }
      }
    }
  `}

  ${({ width }) => width && `
    width: ${width};
  `}

  ${({ wordBreak }) => wordBreak && `
    word-break: break-word;
  `}

  @media ${device.mobile} {
    ${({ widthM }) => widthM && `
      width: ${widthM};
    `}
  }

  @media ${device.mobileL} {
    ${({ widthML }) => widthML && `
      width: ${widthML};
    `}
  }

  @media ${device.tablet} {
    ${({ widthT }) => widthT && `
      width: ${widthT};
    `}
  }

  @media ${device.laptop} {
    ${({ widthL }) => widthL && `
      width: ${widthL};
    `}
  }

  @media ${device.laptop} {
    ${({ width, widthM, widthT, widthL }) => !width && !widthM && !widthT && !widthL && `
      max-width: 35rem;
    `}
  }
`;

// eslint-disable-next-line
export const Label = styled.label<{
  isFocused?: boolean;
  isFull?: boolean;
  normalFontSize?: boolean;
}>`
  display: flex;
  margin: 0;
  font-size: 1.2rem;
	text-align: left;
	// margin-bottom: .25rem;
	margin-bottom: .5rem;
	word-break: break-word;

  span {
    color: inherit;
    transition: color ${({ theme }) => theme.animSpeed};
  }

  ${({ isFocused, theme }) =>
    isFocused &&
    `
      span {
        color: ${theme.colors.accentPrimary};
      }
  `}

  ${({ normalFontSize }) => normalFontSize && `
    font-size: unset;
  `}
`;

export const Indicators = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-left: 1rem;
`;

export const ErrorTriangle = styled.span`
  width: 0;
  height: 0;
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  border-bottom: 0.9rem solid ${({ theme }) => theme.colors.accentTertiary};
  margin-right: 1rem;
`;

export const ModifiedCircle = styled.span<{ leftMargin?: boolean }>`
  display: inline-block;
  height: 0.7rem;
  width: 0.7rem;
  border-radius: 0.7rem;
  background-color: ${({ theme }) => theme.colors.corePrimary};
  overflow: hidden;
  margin-right: 1rem;

  ${({ leftMargin }) => leftMargin && `
    margin-left: 1rem;
  `}
`;

export const StyledInfo = styled(InfoIcon)`
  display: none;

  @media ${device.onlyMobileAndTablet} {
    display: block;
    height: 1.4rem;
    width: 1.4rem;
    fill: ${({ theme }) => theme.colors.accentQuaternary};
  }
`;

export const StyledWarning = styled(Warning)`
  width: 1.4rem;
  height: auto;
  margin-right: .5rem;
  fill: ${({ theme }) => theme.colors.corePrimary};
`;

export const StyledError = styled(ErrorIcon)`
  height: 1.4rem;
  width: 1.4rem;
  fill: ${({ theme }) => theme.colors.accentTertiary};
`;

export const StyledDrag = styled(Drag)`
  width: 1.9rem;
  height: auto;
`;

export const StyledEdit = styled(Edit)`
  width: 2.2rem;
  height: auto;
`;

export const StyledPound = styled(Pound)`
  width: 1.5rem;
  height: 1.5rem;
`;

export const StyledBinIcon = styled(Bin2)`
  width: 1.5rem;
  height: auto;
`;

export const InfoWrapper = styled.div`
  margin-top: 1rem;
  font-size: 1.2rem;
`;

export const WarningWrapper = styled.div`
  margin-top: 1rem;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.corePrimary};
`;

export const ErrorWrapper = styled.div`
  margin-top: 1rem;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.accentTertiary};
`;

export const TooltipWrapper = styled.div`
  display: none;

  @media ${device.onlyMobileAndTablet} {
    display: block;
    margin-top: 1rem;
    font-size: 1.2rem;
  }
`;

export const ChildrenWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

