import styled from 'styled-components';
import { device } from 'theme/media';

import {
  ModalContentWrapper
} from 'theme/mixins';
export * from 'theme/mixins';


export const EventModalContentWrapper = styled(ModalContentWrapper)`
  @media ${device.tablet} {
    min-width: 40.4rem;
  }
`;

