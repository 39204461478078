import React, { FC } from 'react';

import {
  AdminHeader,
  AdminFormLine,
  RightContentWrapper
} from './PageHeader.styles';

export interface PageHeaderProps {
  title?: string | React.ReactNode;
  children?: React.ReactNode;
  rightContent?: React.ReactNode;
  tag?: string;
}

const PageHeader: FC<PageHeaderProps> = props => {
  const {
    title,
    rightContent,
    children,
    tag
  } = props;

  return (
    <AdminFormLine
      row
      centerV
      spaceBetween
      marginBottom
      style={{paddingBottom: '.5rem'}}
      className={tag}
    >
      {children ? (
        children
      ) : (
        <>
          <AdminHeader
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: 0
            }}
          >{title}</AdminHeader>
          {rightContent && (
            <RightContentWrapper
              row
            >
              {rightContent}
            </RightContentWrapper>
          )}
        </>
      )}
    </AdminFormLine>
  );
};

export default PageHeader;
