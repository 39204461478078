import React, { FC, useCallback, useEffect, Dispatch, useMemo } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import { theme } from 'theme';
import styled from 'styled-components';
import { device } from 'theme/media';
import {
  scrollToContent,
  isInBackofficePage
} from 'utils/general';
import { authService } from 'services';
import { UserData } from 'types/User';
import { Step } from 'types/Service';
import { State, Action } from 'state';
import { Toast } from 'components/molecules/Toast/ToastManager';
import { ApplicationError, LocalError } from 'types/Error';

import Home from './pages/home';
import AdminRoot from './pages/adminDashboard/Root';
import BookingLanding from './pages/bookingLanding';
import Booking, { BookingWrapper } from './pages/booking';
import BookingComplete from './pages/bookingComplete';
import Signup, { PostSignup } from './pages/signup';
import Login from './pages/login';
import Verify from './pages/verify';
import Unverified from './pages/unverified';
import NoAccess from './pages/noAccess';
import RequestPasswordReset from './pages/requestPasswordReset';
import ResetPassword from './pages/resetPassword';
import ChangeDefaultPassword from './pages/changeDefaultPassword';
import ErrorPage from './pages/error';
import Terms from './pages/terms';
import Privacy from './pages/privacy';
import AccountingAuthResult from './pages/accountingAuthResult';

const NavBarRouterStyle = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  color: ${theme.textColor};
  z-index: ${theme.layers.lowest};

  @media ${device.mobile} {
    padding: 0 ${theme.appPadding.value}${theme.appPadding.unit};
  }

  @media ${device.laptop} {
    padding: unset;
  }
`;

export interface Props {
  state: State;
  dispatch: Dispatch<Action>;
  addToast: (toast: Toast) => void;
}

export interface PageProps extends Props {
  userData: UserData;
  errors?: ApplicationError[] | null;
}

export interface AdminPageProps extends PageProps {
  sideNavOpen: boolean;
}

export interface BookingPageProps extends Props {
  step: Step;
  index: number;
  serviceId?: string;
  jobId?: string;
}

export const bookState = {
  booksLinkClicked: false
};

const AppRouter: FC<Props> = (props) => {
  const location = useLocation();

  const pageProps = useMemo((): PageProps => ({
    ...props,
    userData: props.state.userData
  }), [props]);

  const renderBookingRoute = useCallback((
    index?: number,
    serviceId?: string,
    jobId?: string
  ) => {
    if (!index || !serviceId || (!index && !serviceId)) {
      // TODO: render coming soon (B2C) or show restricted content page for now
      return (
        <BookingLanding {...pageProps} />
      );
    }

    const step = props.state.service.data!.steps[index - 1];

    if (!step) {
      return (
        <ErrorPage
          {...pageProps}
          errors={[
            new LocalError(
              'Invalid service URL',
              undefined,
              { errorCode: '1402' }
            )
          ]}
        />
      );
    }

    const bookingRouteProps: BookingPageProps = {
      ...props,
      step,
      index,
      jobId,
      serviceId
    };

    return (
      <Booking {...bookingRouteProps} />
    );
  }, [props, pageProps]);

  const bookingRoutes = useCallback(() => {
    return (
      <Route
        path={`/:clientId/booking/:serviceId?/:page?`}
        element={(
          <BookingWrapper {...props}>
            {({ errors, page, serviceId, jobId }) => {
              if (errors) {
                return (
                  <ErrorPage
                    {...pageProps}
                    errors={errors}
                  />
                );
              }

              return renderBookingRoute(page, serviceId, jobId)
            }}
          </BookingWrapper>
        )}
      />
    );
  }, [
    props,
    pageProps,
    renderBookingRoute
  ]);

  useEffect(() => {
    if (location.hash.length > 0) {
      const highlight: boolean = isInBackofficePage(window);

      setTimeout(() => {
        scrollToContent(location.hash, undefined, highlight);
      }, 0);
    } else if (location.pathname === '/') {
      scrollToContent('section:nth-of-type(1)');
    }

    // Auth
    if (location.pathname.match('back-office') && !authService.default.isAuthenticated()) {
      authService.default.redirectToLogin();
    }
  }, [location]);

  return (
    <>
      <NavBarRouterStyle>
        <Routes>
          <Route
            path="/signup"
            element={<Signup {...pageProps} {...props} />}
          />
          <Route
            path="/post-signup"
            element={<PostSignup {...pageProps} {...props} />}
          />
          <Route
            path="/login"
            element={<Login {...pageProps} {...props} />}
          />
          <Route
            path="/:clientId/verify"
            element={<Verify {...pageProps} {...props} />}
          />
          <Route
            path="/unverified"
            element={<Unverified {...pageProps} {...props} />}
          />
          <Route
            path="/no-access"
            element={<NoAccess {...pageProps} {...props} />}
          />
          <Route
            path="/request-password-reset"
            element={<RequestPasswordReset {...pageProps} {...props} />}
          />
          <Route
            path="/reset-password"
            element={<ResetPassword {...pageProps} {...props} />}
          />
          <Route
            path="/change-password"
            element={<ChangeDefaultPassword {...pageProps} {...props} />}
          />
          <Route
            path="/:clientId/back-office/*"
            element={<AdminRoot {...pageProps} {...props} />}
          />
          <Route
            path="/:clientId/booking/complete"
            element={<BookingComplete {...pageProps} />}
          />
          <Route
            path="/accounting/qbo-auth-result"
            element={<AccountingAuthResult {...pageProps} {...props} />}
          />
          {bookingRoutes()}
          <Route
            path={`/`}
            element={<Home {...pageProps} {...props} />}
          />
          <Route
            path={`/terms`}
            element={<Terms {...pageProps} {...props} />}
          />
          <Route
            path={`/privacy`}
            element={<Privacy {...pageProps} {...props} />}
          />
        </Routes>
      </NavBarRouterStyle>
    </>
  );
};

export default AppRouter;

