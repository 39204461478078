import React from 'react';
import styled from 'styled-components';

import {
  Home,
  Work,
  Worker,
  Calendar2,
  Settings as SettingsIcon,
  Help,
  Service as ServiceIcon,
  Payments,
  Billing
} from 'components/atoms';

export const StyledPayments = styled(Payments)`
  transform: rotateZ(45deg);
`;

export enum Page {
  Home = 'help',
  Services = 'services',
  Jobs = 'jobs',
  PricingRules = 'pricing-rules',
  Workforce = 'workforce',
  Calendar = 'calendar',
  Settings = 'settings',
  Billing = 'billing',
  Help = 'help'
}

export enum Tag {
  Fulfiller = 'fulfiller',
  Client = 'client'
}

export interface Link {
  id: Page;
  text: string;
  url: string;
  active?: boolean;
  icon?: JSX.Element;
  children?: Link[];
  tags?: Tag[];
}

const navConfig = (clientId: string): Link[] => ([
  {
    id: Page.Home,
    text: 'Home',
    url: `/${clientId}/back-office/dashboard`,
    icon: <Home />,
    tags: [
      Tag.Fulfiller
    ]
  },
  {
    id: Page.Workforce,
    text: 'Workforce',
    url: `/${clientId}/back-office/workforce`,
    icon: <Worker />,
    tags: [
      Tag.Client
    ]
  },
  {
    id: Page.Services,
    text: 'Services',
    url: `/${clientId}/back-office/services`,
    icon: <ServiceIcon />,
    tags: [
      Tag.Client
    ]
  },
  {
    id: Page.PricingRules,
    text: 'Pricing rules',
    url: `/${clientId}/back-office/pricing-rules`,
    icon: <StyledPayments />,
    tags: [
      Tag.Client
    ]
  },
  {
    id: Page.Billing,
    text: 'Billing',
    url: `/${clientId}/back-office/billing`,
    icon: <Billing />,
    tags: [
      Tag.Client
    ]
  },
  {
    id: Page.Jobs,
    text: 'Jobs',
    url: `/${clientId}/back-office/jobs`,
    icon: <Work />,
    tags: [
      Tag.Client,
      Tag.Fulfiller
    ]
  },
  {
    id: Page.Calendar,
    text: 'Calendar',
    url: `/${clientId}/back-office/calendar`,
    icon: <Calendar2 />,
    tags: [
      Tag.Client,
      Tag.Fulfiller
    ]
  },
  {
    id: Page.Settings,
    text: 'Settings',
    url: `/${clientId}/back-office/settings`,
    icon: <SettingsIcon />,
    tags: [
      Tag.Client
    ]
  },
  {
    id: Page.Help,
    text: 'Help',
    url: `/${clientId}/back-office/help`,
    icon: <Help />,
    tags: [
      Tag.Fulfiller
    ]
  }
]);

export default navConfig;

