import React, { FC, memo, useState, useCallback, useEffect } from 'react';

import {
  Background,
  HeaderLanding,
  Footer
} from 'components/molecules';

import { PageProps } from 'components/AppRouter';
import {
  FormLine,
  StyledLink
} from 'theme/mixins';

import {
  Wrapper,
  ContentContainer,
  Block,
  Text
} from './Terms.styles';

const Terms: FC<PageProps> = props => {
  const [state, setState] = useState({ floatHeader: false });

  const onScroll = useCallback((e: any) => {
    const scrollTop: number = e.target.scrollTop;

    if (scrollTop === 0) {
      setState(prevState => ({
        ...prevState,
        floatHeader: false
      }));
    } else {
      setState(prevState => ({
        ...prevState,
        floatHeader: true
      }));
    }
  }, []);

  const addScrollListener = useCallback(() => {
    const rootElem: HTMLElement | null = document.querySelector('#root');

    if (rootElem) {
      rootElem.addEventListener('scroll', onScroll, false);
    }
  }, [onScroll]);

  const removeScrollListener = useCallback(() => {
    const rootElem: HTMLElement | null = document.querySelector('#root');

    if (rootElem) {
      rootElem.removeEventListener('scroll', onScroll);
    }
  }, [onScroll]);

  useEffect(() => {
    return () => {
      removeScrollListener();
    };
  }, [removeScrollListener]);

  useEffect(() => {
    addScrollListener();
  }, [addScrollListener]);

  return (
    <>
      <Background />
      <Wrapper>
        <HeaderLanding float={state.floatHeader} />
        <ContentContainer>
          <Block>
            <h2>Terms of Service</h2>
            <Text>By registering for the Service, you agree to be bound by the Terms of Service set out here. GoBook'em reserves the right to update and change the Terms from time to time on 30 days’ notice. By continuing to access the Platform after this notice period expires, you agree to be bound by the updated or changed Terms. Any new features that augment or enhance the current Service, including the release of new tools and resources, shall be subject to the Terms. Please also read <StyledLink $bold to="/privacy">our privacy policy</StyledLink> (available on the Platform), because it will apply to all the information you provide to us and forms part of the Terms. By accepting the Terms, you also accept our privacy policy. If you do not agree to be bound by the Terms, you must not use any part of the Platform.</Text>
            <br />
            <br />

            <h3>Definitions</h3>
            <Text>"Confidential Information" – includes all information exchanged between you and GoBook'em, whether in writing, electronically, or orally, including the Service but does not include information which is, or becomes, publicly available other than through unauthorised disclosure.</Text>
            <Text>"Charges" means the charges payable by you in connection with the use of the Platform, as displayed on the Platform or otherwise agreed with you from time to time.</Text>
            <Text>"Data" – means any data in-putted by you or with your authority into the Platform.</Text>
            <Text>"Intellectual Property Right" – means any patent, trademark, service mark, copyright, moral right, right in a design, know-how, and any other intellectual property rights, anywhere in the world whether or not registered.</Text>
            <Text>"Platform" – means our website at www.gobookem.com or any other address used by us from time to time and the GoBook'em applications.</Text>
            <Text>"Service" – means the online booking management services made available (as may be changed or updated from time to time by GoBook'em) via the Platform.</Text>
            <Text>"Terms" – means these Terms of Service.</Text>
            <Text>"GoBook'em" – means GoBook'em Limited. "We", "us" or "our" have a corresponding meaning.</Text>
            <Text>"You" – means the person who registers to use the Service, and, where the context permits, includes any entity on whose behalf that person registers to use the Service, and any person or organization that uses the Service with the authorisation of that person or entity. "Your" has a corresponding meaning.</Text>
            <br />
            <br />

            <h3>Provision of Service</h3>
            <Text>GoBook'em will offer a free version of the Service for the purpose of evaluation and use. Following your evaluation, you have no obligation to continue to use the Service. We have the right to suspend or terminate the Service and refuse any and all current or future use of the Service for any reason at any time.</Text>
            <br />
            <br />

            <h3>Usage Limitations</h3>
            <Text>Use of the Service may be subject to limitations, including but not limited to monthly transaction volumes.</Text>
            <br />
            <br />

            <h3>Intellectual Property</h3>
            <h4>General</h4>
            <Text>Title to, and all Intellectual Property Rights in the Service, the Platform, and any documentation relating to the Service or the Platform remain the property of GoBook'em (or its licensor's).</Text>
            <br />

            <h4>Ownership of Data</h4>
            <Text>Title to, and all Intellectual Property Rights in, the Data remain your property. You grant GoBook'em a license to use, copy, transmit, store, and back up your information and Data for the purposes of enabling you to access and use the Service and for any other purpose related to the provision of services to you. If your account is terminated, we will provide an export of your data on request.</Text>
            <br />

            <h4>Third-party applications and your Data</h4>
            <Text>If you enable third-party applications for use in conjunction with the Service, you acknowledge that we may allow the providers of those third-party applications to access your Data as required for the interoperation of such third-party applications with the Service. We will not be responsible for any disclosure, modification, or deletion of your Data resulting from any such access by third-party application providers.</Text>
            <br />
            <br />

            <h3>Warranties and Acknowledgements</h3>
            <h4>Authority</h4>
            <Text>You warrant that where you have registered to use the Service on behalf of another person, you have the authority to agree to these Terms on behalf of that person and agree that by registering to use the Service you bind the person on whose behalf you act to the performance of any and all obligations that you become subject to by virtue of these Terms, without limiting your own personal obligations under these Terms. You will be responsible for all activities that occur on the Platform under your access credentials or your company’s account. Please take care in adding additional users and setting access permissions, and tell us straight away if you think there has been a security breach.</Text>
            <br />

            <h4>No warranties</h4>
            <Text>GoBook'em gives no warranty about the Service. Without limiting the foregoing, we do not warrant that the Service will meet your requirements, that it will be suitable for any particular purpose, or that it will be available on an uninterrupted, secure, or error-free basis. To avoid doubt, all implied conditions or warranties are excluded insofar as is permitted by law, including (without limitation) warranties of merchantability, fitness for purpose, title, and non-infringement.</Text>
            <br />

            <h4>Consumer guarantees</h4>
            <Text>You warrant and represent that you are acquiring the right to access and use the Service for the purposes of a business and that, to the maximum extent permitted by law, any statutory consumer guarantees or legislation intended to protect non-business consumers in any jurisdiction does not apply to the supply of the Service, the Platform or these Terms.</Text>
            <br />

            <h4>Viruses</h4>
            <Text>GoBook'em does not guarantee that the Platform will be secure or free from bugs or viruses. You should use your own virus protection software. We will not be liable for any loss or damage caused by a virus, distributed denial-of-service attack, or other technologically harmful material that may infect your computer equipment, computer programs, data or other proprietary material due to your use of the Platform. You must not misuse the Platform by knowingly introducing viruses, trojans, worms, logic bombs, or other material which is malicious or technologically harmful.</Text>
            <br />
          </Block>
        </ContentContainer>
        <FormLine
          center
          marginBottom
        >
          <Footer />
        </FormLine>
      </Wrapper>
    </>
  );
};

export default memo(Terms);

