import styled from 'styled-components';

import { elemMixin } from '../form/mixins.styles';

export const InputControl = styled.input<{ errored?: boolean; textRight?: boolean; }>`
  ${({ errored, textRight }) => elemMixin(errored, textRight)}
`;

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StartAdornmentWrapper = styled.div`
  margin-right: .5rem;
`;

