import styled from 'styled-components';
import { device } from 'theme/media';

import {
  Plus,
  FormSection,
  Button,
  Cross,
  Edit,
  Switch,
  Text,
  Settings,
  Tick,
  Chevron,
  Aircraft,
  TextInput
} from 'components/atoms';
import { elemMixin } from 'components/atoms/form/mixins.styles';
import {
  AdminFormWrapper,
  AdminFormLine,
  IconButton
} from 'theme/mixins';
export * from 'theme/mixins';

export const Wrapper = styled.div`
  width: inherit;
  height: 100%;
  flex-direction: column;

  .mobile-page-header, .mobile-settings-seperator, .mobile-actions-seperator, .mobile-page-actions, .mobile-instruction {
    @media ${device.mobile} {
      display: none;
    }

    @media ${device.tablet} {
      display: flex;
    }
  }

  .mobile-page-new-action {
    @media ${device.tablet} {
      display: none;
    }
  }

  @media ${device.mobile} {
    overflow: hidden;
  }

  @media ${device.tablet} {
    overflow: unset;
  }
`;

export const StyledPlus = styled(Plus)`
  fill: ${({ theme }) => theme.textColor};
`;

export const StatusIndicator = styled.i<{ isEnabled: boolean; }>`
  display: flex;
  position: relative;
  justify-content: center;
  padding-top: 2rem;
  font-style: normal;

  ${({ isEnabled }) => `
    :after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background-color: ${isEnabled ? 'green' : 'red'}
    }
  `}
`;

export const ContentWrapper = styled.div<{ column?: boolean; }>`
  display: flex;
  justify-content: flex-start;

  ${({ column }) => column && `
    flex-direction: column;
    align-items: center;
  `}

  :last-child {
    width: 9rem;
    justify-content: flex-end;
  }
`;

export const CreateServiceFormWrapper = styled(AdminFormWrapper)`
  height: inherit;
  text-align: left;

  @media ${device.mobile} {
    height: inherit;
  }

  @media ${device.tablet} {
    height: unset;
  }
`;

export const StyledFormSection = styled(FormSection)`
  padding: 0;

  > div:last-child {
    grid-template-columns: 1fr 0 1fr;
    grid-column-gap: revert;
  }
`;

export const Grid = styled.div`
  display: grid;
  position: relative;
  width: inherit;
  height: inherit;
  grid-column-gap: revert;

  @media ${device.mobile} {
    grid-template-columns: 1fr;
  }

  @media ${device.tablet} {
    grid-template-columns: 1fr 0 1fr;
  }
`;

export const MobileSettingsWrapper = styled.div<{
  show: boolean;
  minimize: boolean;
}>`
  position: relative;
  transition: transform ${({ theme }) => theme.animSpeed} ease-in-out;
  background: transparent;

  ${({ minimize }) => minimize && `
    transform: translateY(90%);

    > div:first-child {
      display: none;
    }

    @media ${device.tablet} {
      transform: unset;

      > div:first-child {
        display: unset;
      }
    }
  `};

  @media ${device.mobile} {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: auto;
    // max-height: calc(100vh + 6rem);
    padding-top: 7rem;
    z-index: ${({ theme }) => theme.layers.highest};

    ${({ show }) => show && `
      display: block;
    `}

  }

  @media ${device.tablet} {
    display: block;
    position: relative;
    width: unset;
    height: unset;
    overflow: unset;
    // max-height: unset;
    padding-top: unset;
    z-index: unset;
  }
`;

export const MobileSettingIconWrapper = styled.div<{ mobileSettingsOn: boolean; }>`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  margin-top: -100px;
  right: 2rem;
  z-index: ${({ theme }) => theme.layers.high};

  @media ${device.tablet} {
    display: none;
  }

  > div {
    margin-bottom: 1rem;
  }

  ${({ mobileSettingsOn }) => mobileSettingsOn && `
    z-index: 0;
  `}
`;

export const FormFieldControlWrapper = styled.div`
  @media ${device.tablet} {
    display: flex;
    margin-right: 2rem;
  }
`;

export const ClearButton = styled(Button)`
  margin-left: 2rem;
`;

export const IFrameSectionWrapper = styled.div<{ blur: boolean; }>`
  height: inherit;
  filter: blur(0);
  transition: filter ${({ theme }) => theme.animSpeed} ease-in-out;

  ${({ blur }) => blur && `
    filter: blur(1.2rem);
  `}
`;

export const Prices = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: start;
  margin-top: 2.1rem;
  // margin-left: 2.1rem;
`;

export const StyledCross = styled(Cross)`
  width: 1.3rem;
  height: auto;

  @media ${device.tablet} {
    display: none;
  }
`;

export const NameCrossButton = styled(IconButton)`
  position: relative;
  top: -.5rem;
  margin-right: 1rem;
  margin-left: -2rem;
`;

export const NameCrossIcon = styled(Cross)`
  width: 1.5rem;
  height: 1.5rem;
`;

export const NameTickButton = styled(IconButton)`
  position: relative;
  top: -.5rem;
  margin-left: 1rem;
`;

export const NameTickIcon = styled(Tick)`
  width: 1.5rem;
  height: 1.5rem;
`;

export const NameEditButton = styled(IconButton)`
  display: flex;
  position: relative;
  left: -1rem;
  top: -.5rem;
  margin-left: -1rem;
  visibility: hidden;
`;

export const NameEditIcon = styled(Edit)`
  display: flex;
  width: 2.1rem;
  height: 2.1rem;
`;

export const NameWrapper = styled(AdminFormLine)`
  margin-bottom: 1rem;

  :hover {
    ${NameEditButton} {
      visibility: visible;
    }
  }
`;

export const NameInput = styled(TextInput)`
  position: relative;
  top: -1rem;
`;

export const StyledEditButton = styled(IconButton)`
  display: none;
`;

export const BinButton = styled(IconButton)`
  display: none;
`;

export const ConditionBinButton = styled(IconButton)`
  display: none;
`;

export const StyledEditIcon = styled(Edit)`
  width: 2.1rem;
  height: 2.1rem;
`;

export const FieldRuleIcon = styled(Text)<{ isEnabled: boolean }>`
  display: none;
  color: ${({ theme }) => theme.colors.neutralPrimaryDark1};
  font-weight: 700;
  cursor: default;

  ${({ theme, isEnabled }) => isEnabled && `
    color: ${theme.textColor};
  `};
`;

export const PriceOuter = styled.div<{ selected?: boolean; }>`
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 2rem;
  cursor: pointer;

  ${({ selected }) => selected && `
    ${StyledEditButton} {
      display: flex;
      position: absolute;
      top: -2.5rem;
      left: -2rem;
    }

    ${BinButton} {
      display: flex;
      position: absolute;
      margin-left: 0;
      top: -2.5rem;
      right: -2rem;
    }
  `};

  ${FieldRuleIcon} {
    display: flex;
    position: absolute;
    bottom: -2rem;
    left: -1rem;
  }
`;

export const PriceRule = styled.div<{ testMode: boolean; isAppliedToField: boolean; }>`
  display: flex;
  width: 100%;
  margin-bottom: 4rem;
  padding-left: 2.1rem;
  flex-direction: column;
  align-items: start;

  ${({ testMode }) => testMode && `
    ${PriceOuter}, ${Condition}, ${ConditionButton} {
      cursor: default;
    }
  `};

  ${({ testMode }) => !testMode && `
    :hover {
      ${PriceOuter} {
        ${StyledEditButton} {
          display: flex;
          position: absolute;
          top: -2.5rem;
          left: -2rem;
        }

        ${BinButton} {
          display: flex;
          position: absolute;
          margin-left: 0;
          top: -2.5rem;
          right: -2rem;
        }
      }
    }
  `};
`;

export const PriceInput = styled.input<{
  hasConditions: boolean;
  conditionSelected: boolean;
  widthBasedOnPriceLength: number;
  selected?: boolean;
}>`
  ${elemMixin(false, false)}

  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  text-align: center;
  border: .2rem solid ${({ theme }) => theme.colors.coreSecondary};

  ${({ widthBasedOnPriceLength }) => widthBasedOnPriceLength && `
    width: ${widthBasedOnPriceLength}rem;
    height: ${widthBasedOnPriceLength}rem;
  `};

  ${({ theme, selected }) => selected && `
      border-color: ${theme.option.active.color};
  `};

  ${({ theme, hasConditions, conditionSelected }) => hasConditions && `
    + i {
      position: absolute;
      right: -1.6rem;
      top: calc(50% - 0.1rem);
      border: 0.1rem solid;
      width: 1rem;

      ${conditionSelected && 'border-color:' + theme.option.active.color + ';'};
    }
  `};
`;

export const RuleName = styled.p`
  margin-bottom: 2rem;
`;

export const ConditionButton = styled.span`
  border: 0.1rem solid transparent;
  padding: 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  white-space: nowrap;
  background-color: ${({ theme }) => theme.colors.neutralSecondary};
  box-shadow: rgb(60 66 87 / 8%) 0px 1px 2px 0px;
`;

const RING_BOTTOM_MARGIN = 20;
export const Condition = styled.div<{
  selected: boolean;
  highlightStem: boolean;
  zindex: number;
  ownHeight: number;
  testMode: boolean;
  paddingLeftBasedOnPriceLength: number;
  ringRadius: number;
}>`
  display: flex;
  position: relative;
  padding-left: 11rem;
  margin-bottom: 2rem;
  cursor: pointer;

  ${({ paddingLeftBasedOnPriceLength }) => paddingLeftBasedOnPriceLength && `
    padding-left: ${paddingLeftBasedOnPriceLength}rem;
  `};

  :before {
    content: '';
    position: absolute;
    left: ${({ paddingLeftBasedOnPriceLength }) => paddingLeftBasedOnPriceLength - 1.5}rem;
    bottom: 50%;
    height: 0;
    border: 0.1rem solid;
    transition: height .5s ease-in-out;

    ${({ ownHeight, ringRadius }) => ownHeight && ringRadius && `
      height: ${(ownHeight * .5) + ringRadius + RING_BOTTOM_MARGIN}px;
    `};

    ${({ zindex }) => zindex >= 0 && `
      z-index: ${zindex};
    `};

    ${({ theme, selected }) => selected && `
      border-color: ${theme.option.active.color};
    `};

    ${({ theme, highlightStem }) => highlightStem && `
      border-color: ${theme.option.active.color};
    `};
  }

  :after {
    content: '';
    position: absolute;
    left: ${({ paddingLeftBasedOnPriceLength }) => paddingLeftBasedOnPriceLength - 1.5}rem;
    border: 0.1rem solid;
    width: 1rem;
    bottom: calc(50% - 0.1rem);

    ${({ theme, selected }) => selected && `
      border-color: ${theme.option.active.color};
    `};
  }

  ${({ theme, selected }) => selected && `
    ${ConditionButton} {
      border-color: ${theme.textColor};
    }
  `}

  ${ConditionBinButton} {
    display: inline-block;
    position: absolute;
    left: 5rem;
    top: calc(50% - 3rem * .5);
    visibility: hidden;
  }

  ${({ testMode }) => !testMode && `
    :hover {
      ${ConditionBinButton} {
        visibility: visible;
      }
    }
  `};
`;

export const Price = styled.div<{
  hasConditions: boolean;
  conditionSelected: boolean;
  widthBasedOnPriceLength: number;
  selected?: boolean;
  highlightColor?: string
}>`
  display: flex;
  position: relative;
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  border: .2rem solid ${({ theme }) => theme.colors.coreSecondary};
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutralSecondary};
  box-shadow: rgb(60 66 87 / 8%) 0px 1px 2px 0px;

  ${({ widthBasedOnPriceLength }) => widthBasedOnPriceLength && `
    width: ${widthBasedOnPriceLength}rem;
    height: ${widthBasedOnPriceLength}rem;
  `};

  ${({ theme, selected }) => selected && `
      border-color: ${theme.option.active.color};
  `};

  ${({ theme, hasConditions, conditionSelected }) => hasConditions && `
    :after {
      content: '';
      position: absolute;
      right: -1.8rem;
      top: calc(50% - 0.1rem);
      border: 0.1rem solid;
      width: 1rem;
      opacity: 1;

      ${conditionSelected ? 'border-color:' + theme.option.active.color + ';' : ''};
    }
  `};

  ${({ theme, highlightColor }) => highlightColor && `
      border-color: ${theme.colors.accentSecondary};
      animation: .25s linear 0s infinite alternate glow-2;
  `};
`;

export const Conditions = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ConditionInner = styled.div`
  display: block;
  line-height: 2.5;
`;

export const CompareField = styled.i`
  text-align: left;
`;

export const CompareValue = styled.i`
  display: inline-flex;
  text-align: left;
`;

export const StyledSwitch = styled(Switch)`
  margin-left: auto;
  margin-right: 1rem;

  > div > div {
    margin: 0 1rem 0;
  }
`;

export const StyledMobileSettingsIcon = styled(Settings)`
  width: 2.75rem;
  height: auto;
`;

export const StyledMobileTickIcon = styled(Tick)<{ disabled: boolean; }>`
  width: 2rem;
  height: auto;

  ${({ theme, disabled }) => disabled && `
    fill: ${theme.baseInput.disabled.color};
  `}
`;

export const StyledMobileChevronIcon = styled(Chevron)`
  width: 4rem;
  height: auto;
  transform: rotateZ(90deg);
`;

export const StyledAircraft = styled(Aircraft)`
  width: 20rem;
  height: auto;
`;

export const RuleHighlight = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  max-width: 65%;
  border: .2rem solid ${({ theme }) => theme.colors.accentSecondary};
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.neutralSecondary};

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

