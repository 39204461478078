import React, { FC, memo, useCallback } from 'react';
import { useParams, useNavigate,  } from 'react-router';

import { theme } from 'theme';
import {
  Spinner,
} from 'components/atoms';
import {
  Background,
  Footer
} from 'components/molecules';

import { Service } from 'types/Service';
import { Actions } from 'state';
import { isListEmpty } from 'utils/general';
import { PageProps } from 'components/AppRouter';
import {
  FormLine,
  HR,
  Card,
  IconButton,
  StyledChevron
} from 'theme/mixins';

import {
  Wrapper,
  HeaderWrapper,
  StyledHeader,
  StyledCard
} from './BookingLanding.styles';

const BookingLanding: FC<PageProps> = (props) => {
  const {
    state,
    dispatch
  } = props;

  const navigate = useNavigate();
  const params: any = useParams();

  const onServiceClick = useCallback((service: Service) => {
    dispatch({
      type: Actions.CACHE_SERVICE,
      payload: {
        data: service
      }
    });

    navigate({
      pathname: `/${params.clientId}/booking/${service._id}/1`
    });
  }, [
    navigate,
    params.clientId,
    dispatch
  ]);

  const render = useCallback(() => {
    if (state.services.loading) {
      return (
        <Spinner
          color={theme.textColor}
          size={'M'}
        />
      );
    }

    // if (state.services.error) {
    //   return (
    //     <div>
    //       <p>{errorMessage}</p>
    //       <Button onClick={() => {
    //         if (retryMethod) {
    //           retryMethod();
    //         }
    //       }}>Retry</Button>
    //     </div>
    //   );
    // }

    if (isListEmpty(state.services.data)) {
      return 'No services found. Please contact the vendor if this is unexpected.';
    }

    return (state.services.data || []).map((service: Service) => (
      <FormLine
        marginBottom
        key={service._id}
      >
        <Card
          row
          centerH
          centerV
          pointer
          hSpaceBetween
          onClick={() => onServiceClick(service)}
        >
          <span>{service.name}</span>
          <IconButton hoverEffect>
            <StyledChevron />
          </IconButton>
        </Card>
      </FormLine>
    ));
  }, [
    state.services,
    onServiceClick
  ]);

  return (
    <>
      <Background />
      <Wrapper>
        <StyledCard boxShadow>
          <HeaderWrapper>
            <FormLine
              center
              marginBottom
            >
              <StyledHeader>{state.client.data!.businessName}</StyledHeader>
            </FormLine>

            <FormLine marginBottom>
              <HR />
            </FormLine>
            <FormLine marginBottom />
          </HeaderWrapper>

          {render()}
        </StyledCard>
        <FormLine
          center
          marginBottom
        >
          <Footer />
        </FormLine>
      </Wrapper>
    </>
  );
};

export default memo(BookingLanding);
