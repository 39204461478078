import React, { FC, useMemo, useState, useCallback } from 'react';

import {
  Button,
  PrimaryButton,
  TextInput,
} from 'components/atoms';
import { Popup } from 'components/molecules';
import { theme } from 'theme';
import {
  ModalWrapper,
  ModalContentWrapper,
  ModalItem,
  AdminFormLine,
  ModalBorder
} from 'theme/mixins';
import { hasValue } from 'utils/general';
import { useAutoFocus } from 'components/hooks';

interface State {
  form: {
    [key: string]: any;
  };
}

const initialState: State = {
  form: {}
};

export interface StepNameModalProps {
  show: boolean;
  stepNames: string[];
  name?: string;
  index?: number;
  onClose: () => void;
  onSubmit: (form: State['form'], index?: number) => void;
}

const StepNameModal: FC<StepNameModalProps> = props => {
  const {
    show,
    stepNames,
    name,
    index,
    onClose,
    onSubmit
  } = props;

  const isCreate: boolean = !name;
  const nameAutoFocus = useAutoFocus();

  const existingStepNames = useMemo((): string[] => {
    const names = stepNames.map(stepName => stepName.toLowerCase());

    if (!isCreate && name) {
      const currentNameIndex: number = stepNames.findIndex(stepName => stepName === name);

      names.splice(currentNameIndex, 1);
    }

    return names;
  }, [
    isCreate,
    stepNames,
    name
  ]);

  const [state, setState] = useState<State>({
    ...initialState,
    form: {
      ...initialState.form,
      name
    }
  });

  const onSubmitLocal = useCallback((cb: () => void) => {
    onSubmit({ ...state.form }, index);
  }, [
    state.form,
    index,
    onSubmit
  ]);

  if (!show) {
    return null;
  }

  const value = state.form.name || '';
  const databaseValue = name || '';
  const hasChanged: boolean = !isCreate && value !== databaseValue;

  return (
    <Popup
      id={'step-rename-modal'}
      layered
      convertable
      noPadding
      maxWidth
      onClose={() => onClose()}
    >
      {({ closePopup }) => {
        return (
          <ModalWrapper>
            <ModalItem>
              <AdminFormLine marginTop>
                <h3 style={{marginBottom: 0}}>{isCreate ? 'Create step' : 'Rename step'}</h3>
              </AdminFormLine>
            </ModalItem>

            <AdminFormLine marginBottom />
            <ModalBorder />
            <AdminFormLine marginBottom />

            <ModalContentWrapper>
              <AdminFormLine marginBottom>
                <TextInput
                  ref={nameAutoFocus}
                  width={'100%'}
                  value={value}
                  label={'Step name'}
                  error={(existingStepNames.includes(value.toLowerCase().trim()) && 'This step name is already taken') || undefined}
                  hasChanged={hasChanged}
                  onChange={(e) => {
                    const localValue = e.target.value;

                    setState(prevState => ({
                      ...prevState,
                      form: {
                        ...prevState.form,
                        name: localValue
                      }
                    }));
                  }}
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                      closePopup();
                      onSubmitLocal(closePopup);
                    }
                  }}
                />
              </AdminFormLine>
            </ModalContentWrapper>

            <AdminFormLine marginBottom />
            <ModalBorder />
            <AdminFormLine marginBottom />

            <ModalItem>
              <AdminFormLine right>
                <Button
                  type={'button'}
                  onClick={() => {
                    closePopup();
                  }}
                  style={{ marginRight: '1rem' }}
                >Cancel</Button>
                <PrimaryButton
                  type={'submit'}
                  spinnerColor={theme.colors.coreSecondary}
                  disabled={(!isCreate && !hasChanged) || !hasValue(state.form.name)}
                  onClick={() => {
                    closePopup();
                    onSubmitLocal(closePopup)
                  }}
                >{isCreate ? 'Create' : 'Update'}</PrimaryButton>
              </AdminFormLine>
            </ModalItem>
          </ModalWrapper>
        );
      }}
    </Popup>
  );
};

export default StepNameModal;
