import React from 'react'
import { IconProps } from './types'

type Props = React.DetailedHTMLProps<React.DOMAttributes<SVGSVGElement>, SVGSVGElement> &
  IconProps;

const Cross = React.forwardRef<SVGSVGElement, Props>((props, ref) => {
  const { className, ...rest } = props;

  return (
    <svg
      {...rest}
      ref={ref}
      className={className}
      width="24"
      height="24"
      viewBox="7 7 10 10"
    >
      <g fillRule="evenodd">
        <path
          d="M11.664 11.725L8.627 8.334a.5.5 0 0 1 .746-.668l2.962 3.31 2.962-3.31a.5.5 0 1 1 .745.668l-3.036 3.39 3.036 3.392a.5.5 0 1 1-.745.667l-2.962-3.309-2.962 3.31a.5.5 0 0 1-.746-.668l3.037-3.391z"
        />
      </g>
    </svg>
  );
});

export default Cross;
