import styled from 'styled-components';

const StyledDatePickerWrapper = styled.div<{ width?: string; fullWidth?: boolean; }>`
  display: block;
  width: ${({ fullWidth }) => fullWidth ? '100%' : 'initial'};

  ${({ width }) => width && `
    width: ${width};
  `};

  ${({ fullWidth }) => fullWidth && `
    width: ${fullWidth ? '100%' : 'initial'};
  `};

  .react-datepicker {
    font-family: Roboto;
  }

  .react-datepicker {
    > div {
      width: 100%;
      height: 100%;
    }

    &__day, &__day-name {
      margin: .5rem;
      font-size: 1rem;
    }
  }

  & .react-datepicker-wrapper {
    width: 100%;
  }

  & .react-datepicker-wrapper .react-datepicker__input-container {
    width: 100%;
  }

  & .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range {
    color: ${({ theme }) => theme && theme.textColor};
    background-color: ${({ theme }) => theme && theme.selectionColor};
  }
`;

const DatePickerFooter = styled.div`
  padding: 1rem;
  text-align: left;
`;

const DatePickerFooterList = styled.ul`
  list-style-type: none;
`;

const DatePickerFooterListItem = styled.li<{ colour?: string }>`
  text-indent: 1.25rem;
  color: ${({ colour }) => colour};
  margin-bottom: .4rem;

  :before {
    display: block;
    position: absolute;
    content: '';
    width: 1rem;
    height: 1rem;
    border-radius: 1rem;
    background-color: ${({ colour }) => colour};
  } 
`;

const LoadingLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000022;
`;

const StyledHeader = styled.div`
  margin: 0 1rem .25rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button svg {
    fill: ${({ theme }) => theme.textColor};
  }

  h3 {
    margin: 0;
  }
`;

export {
  StyledDatePickerWrapper,
  DatePickerFooter,
  DatePickerFooterList,
  DatePickerFooterListItem,
  LoadingLayer,
  StyledHeader
};
