import styled from 'styled-components';
import { device } from 'theme/media';

import { MenuDots, Payments } from 'components/atoms';
import { Card } from 'theme/mixins';
export * from 'theme/mixins';

export const Wrapper = styled.div`
  display: flex;
  width: inherit;
  height: 100%;
  flex-direction: column;
`;

export const StatusIndicator = styled.i<{ isEnabled: boolean; }>`
  display: flex;
  position: relative;
  justify-content: center;
  padding-top: 2rem;
  font-style: normal;

  ${({ isEnabled }) => `
    :after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background-color: ${isEnabled ? 'green' : 'red'}
    }
  `}
`;

export const StyledMenuDots = styled(MenuDots)`
  fill: ${({ theme }) => theme.colors.corePrimaryDarker};
  transform: rotateZ(90deg);
`;

export const StyledPayments = styled(Payments)<{ state: boolean; }>`
  transform: rotateZ(45deg);

  ${({ theme, state }) => !state && `
    fill: ${theme.colors.neutralPrimaryDark1};
  `}
`;

export const JobPreview = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const JobDetails = styled.div<{ isExpanded: boolean; }>`
  display: flex;
  max-height: 0;
  flex-direction: column;
  text-align: left;
  transition: max-height ${({ theme }) => theme.animSpeed} ease-in-out;
  cursor: default;
  overflow: hidden;

  ${({ isExpanded }) => isExpanded && `
    max-height: 25rem;
  `};
`;

// 11rem in max width is combination of total appPadding (4rem) and total card padding (6rem)
export const JobDetailsOuter = styled.div`
  display: flex;
  width: 100%;
  max-height: inherit;
  flex-direction: column;
  overflow-x: auto;
  margin-top: 3rem;
  padding-top: 2rem;
  border-top: .1rem solid ${({ theme }) => theme.colors.neutralPrimaryDark};

  @media ${device.tablet} {
    max-width: calc(100vw - 11rem);
  }
`;

export const JobDetailsInner = styled.div<{ groupCount: number }>`
  display: flex;
  flex-direction: row;
  overflow-y: auto;

  @media ${device.mobile} {
    flex-direction: column;
  }

  @media ${device.tablet} {
    width: max-content;
    flex-direction: unset;
  }
`;

export const GroupDetailsHeader = styled.h3`
  position: sticky;
  top: 0;
  margin-bottom: 0;
  padding-bottom: 1rem;
  background-color: ${({ theme }) => theme.colors.neutralSecondary};
`;

export const GroupDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;

  @media ${device.mobile} {
    width: unset;
  }

  @media ${device.tablet} {
    width: 29.999rem;
  }
`;

export const GroupFields = styled.p`
  display: flex;
  flex-direction: row;
  margin-bottom: .5rem;
`;

export const FieldLabel = styled.span`
  flex: 1;
  text-align: left;
  color: ${({ theme }) => theme.colors.neutralPrimaryDarker};
  word-break: break-word;
`;

export const FieldValue = styled.span`
  flex: 1;
  text-align: left;
  word-break: break-word;
`;

export const JobDetailsFooter = styled.div`
  text-align: right;
`;

export const JobCard = styled(Card)`
  margin-bottom: 2rem;
  cursor: pointer;

  @media ${device.mobile} {
    padding: 2rem 1.5rem 2rem 1.5rem;
  }

  @media ${device.tablet} {
    padding: 2rem 3rem;
  }
`;

// eslint-disable-next-line
export const JobCardColumn = styled.div<{
  grow?: boolean;
  row?: boolean;
  maxWidth?: boolean;
  spaceAround?: boolean;
  alignStart?: boolean;
  alignCenter?: boolean;
  stack?: boolean;
  noMarginRight?: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  word-break: break-word;
  flex-shrink: 0;

  ${({ grow }) => grow && `
    flex: 1;
  `}

  ${({ row }) => row && `
    flex-direction: row;
  `}

  ${({ maxWidth }) => maxWidth && `
    max-width: 15rem;
  `}

  ${({ spaceAround }) => spaceAround && `
    justify-content: space-around;
  `}

  ${({ alignStart }) => alignStart && `
    @media ${device.mobileXL} {
      align-items: start;
    }
  `}

  ${({ alignCenter }) => alignCenter && `
    @media ${device.mobileXL} {
      align-items: center;
    }
  `}

  ${({ stack, alignCenter }) => stack && `
    @media ${device.mobile} {
      flex-direction: column;
      align-items: start;

      > div {
        margin-bottom: .75rem;
      }
    }

    @media ${device.mobileXL} {
      flex-direction: unset;
      align-items: unset;

      > div {
        width: 13rem;
      }
    }

    @media ${device.tablet} {
      > div {
        width: 17rem;
      }
    }
  `}

  ${({ noMarginRight }) => !noMarginRight && `
    :not(:last-child) {
      @media ${device.mobile} {
        margin-right: 1rem;
      }

      @media ${device.mobileXL} {
        margin-right: 1.5rem;
      }

      @media ${device.tablet} {
        margin-right: 2rem;
      }
    }
  `}
`;

