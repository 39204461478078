import React, { FC, memo, useCallback, useState } from 'react';
import {
  object,
  string,
  ref
} from 'yup';

import { theme } from 'theme';
import { gatewayService } from 'services';
import {
  TextInput,
  Text,
  PrimaryButton
} from 'components/atoms';
import {
  Background,
  Footer
} from 'components/molecules';
import { getQueryParam } from 'utils/url';
import {
  getNetworkErrors
} from 'utils/general';
import { Form } from 'types/UI';
import {
  NetworkError
} from 'types/Error';
import { useValidation } from 'components/hooks';

import {
  Wrapper,
  StyledCard,
  StyledFormWrapper,
  FormLine,
  StyledHeader,
  StyledLink,
  HR,
} from './ResetPassword.styles';

interface State {
  request: {
    loading: boolean;
    success: boolean;
    error: NetworkError | null;
  };
  form: Form;
}

const initialState: State = {
  request: {
    loading: false,
    success: false,
    error: null
  },
  form: {}
};

const resetPasswordSchema = object({
  password: string()
    .label('New password')
    .required(),
  confirmPassword: string()
    .label('Confirm password')
    .oneOf([ref('password')], 'Passwords must match')
    .required()
});

const ResetPassword: FC<any> = props => {
  const [state, setState] = useState<State>(initialState);
  const { errors, validate } = useValidation(resetPasswordSchema);

  const id: string | undefined = getQueryParam('id', window.location.search.split('?')[1]);
  const token: string | undefined = getQueryParam('token', window.location.search.split('?')[1]);

  const request = useCallback((password: string) => {
    if (state.request.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      request: {
        ...prevState.request,
        loading: true
      }
    }));

    const payload = {
      password,
      token
    };

    gatewayService.resetPassword(id!, payload)
      .then(() => {
        setState(prevState => ({
          ...prevState,
          request: {
            loading: false,
            success: true,
            error: null
          },
        }));
      })
      .catch((err) => {
        setState(prevState => ({
          ...prevState,
          request: {
            ...prevState.request,
            loading: false,
            success: false,
            error: getNetworkErrors([err])[0]
          }
        }));
      });
  }, [
    token,
    id,
    state.request.loading
  ]);

  const handleOnChange = useCallback((e: any, key: string) => {
    const value = e.target.value;

    setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        [key]: value
      }
    }));
  }, []);

  const handleSubmit = useCallback((e: any) => {
    e.preventDefault();

    validate({
      form: state.form,
      all: true
    })
      .then(() => {
        request(state.form.password as string);
      });
  }, [
    state,
    request,
    validate
  ]);

  let submitError = null;

  if (state.request.error) {
    submitError = (
      <Text
        isError
        value={state.request.error.message}
      ></Text>
    );
  }

  return (
    <>
      <Background />
      <Wrapper>
        <StyledCard boxShadow>
          <StyledFormWrapper onSubmit={handleSubmit}>
            <FormLine
              center
              marginBottom
            >
              <StyledHeader>Reset password</StyledHeader>
            </FormLine>

            <FormLine marginBottom>
              <HR />
            </FormLine>
            <FormLine marginBottom />

            {!state.request.success && (
              <>
                <FormLine marginBottom>
                  <TextInput
                    name={'password'}
                    width={'100%'}
                    label="New password"
                    type="password"
                    value={state.form.password as string}
                    error={errors.password}
                    onChange={(e) => handleOnChange(e, 'password')}
                    onBlur={() => validate({
                      form: state.form,
                      field: 'password'
                    })}
                  />
                </FormLine>
                <FormLine marginBottom>
                  <TextInput
                    name={'confirm-password'}
                    width={'100%'}
                    label="Confirm password"
                    type="password"
                    value={state.form.confirmPassword as string}
                    error={errors.confirmPassword}
                    onChange={(e) => handleOnChange(e, 'confirmPassword')}
                    onBlur={() => validate({
                      form: state.form,
                      field: 'confirmPassword'
                    })}
                  />
                </FormLine>

                <FormLine marginBottom />

                <FormLine right>
                  <PrimaryButton
                    width={'100%'}
                    loading={state.request.loading}
                    spinnerColor={theme.colors.coreSecondary}
                    disabled={state.request.loading}
                    type="submit"
                  >
                    Reset password
                  </PrimaryButton>
                </FormLine>
              </>
            )}

            {submitError && (
              <FormLine
                center
                marginBottom
              >
                {submitError}
              </FormLine>
            )}
            {state.request.success && (
              <FormLine
                center
                marginBottom
              >
                <p>Password successfully updated. Please login <StyledLink to="/login">here</StyledLink></p>
              </FormLine>
            )}
          </StyledFormWrapper>
        </StyledCard>
        <FormLine
          center
          marginBottom
        >
          <Footer />
        </FormLine>
      </Wrapper>
    </>
  );
};

export default memo(ResetPassword);
