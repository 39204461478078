import styled from 'styled-components';

export const StyledButton = styled.button`
  display: flex;
  position: relative;
  border: none;
  outline: none;
  background: transparent;
  font: inherit;
  overflow: visible;
  margin: 0;
  padding: 1.5rem 0 1.5rem 1.5rem;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: 0.15s;
  transition-property: opacity, filter;
  text-transform: none;
  color: inherit;
  z-index: 2;
`;

export const StyledBox = styled.div`
  display: inline-block;
  position: relative;
  width: 4rem;
  height: 2.4rem;
`;

export const StyledInner = styled.div<{ color: string; isActive: boolean; useAltColor?: boolean; altColor?: string }>`
  position: absolute;
  width: 4rem;
  height: 0.4rem;
  top: auto;
  bottom: 0;
  transition: transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.13s,
    background-color 1s ease-in-out;
  border-radius: 4px;

  ${({ color }) =>
    color &&
    `
      background-color: ${color};
  `}

  ${({ useAltColor, altColor }) =>
    useAltColor &&
    altColor &&
    `
      background-color: ${altColor};
  `}

  ${({ isActive }) =>
    isActive &&
    `
      transition-delay: .22s;
      transition-timing-function: cubic-bezier(.215,.61,.355,1);
      transform: translate3d(0,-1rem,0) rotate(-45deg);
  `}

  :before {
    content: '';
    display: block;
    position: absolute;
    top: -1rem;
    width: 4rem;
    height: 0.4rem;
    border-radius: 0.4rem;
    transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s,
      transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 1s ease-in-out;

    ${({ color }) =>
      color &&
      `
        background-color: ${color};
    `}

    ${({ useAltColor, altColor }) =>
      useAltColor &&
      altColor &&
      `
        background-color: ${altColor};
    `}

    ${({ isActive }) =>
      isActive &&
      `
        top: 0;
        transition: top .1s cubic-bezier(.33333,0,.66667,.33333) .16s,transform .13s cubic-bezier(.215,.61,.355,1) .25s, background-color 1s ease-in-out;
        transform: rotate(-90deg);
    `}
  }

  :after {
    content: '';
    display: block;
    position: absolute;
    top: -2rem;
    bottom: -1rem;
    width: 4rem;
    height: 0.4rem;
    border-radius: 0.4rem;
    transition: top 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s, opacity 0.1s linear,
      background-color 1s ease-in-out;

    ${({ color }) =>
      color &&
      `
        background-color: ${color};
    `}

    ${({ useAltColor, altColor }) =>
      useAltColor &&
      altColor &&
      `
        background-color: ${altColor};
    `}

    ${({ isActive }) =>
      isActive &&
      `
        top: 0;
        transition: top .2s cubic-bezier(.33333,0,.66667,.33333),opacity .1s linear .22s, background-color 1s ease-in-out;
        opacity: 0;
    `}
  }
`;

