import React, { FC, useRef, useCallback, useEffect } from 'react';

import { theme } from 'theme';

import { ToastInternal } from './ToastManager';
import { StyledLinkExternal } from 'theme/mixins';

import {
  ComponentWrapper,
  ComponentWrapperInner,
  StyledTick,
  StyledInfo,
  StyledWarning,
  StyledError,
  StyledCross,
  ContentWrapper,
} from './ToastManager.styles';

interface Props extends ToastInternal {
  onLoad: (closeFunction: () => void) => void;
  onClose: (id: number, tag?: number) => void;
}

const CLOSE_TIMER = 10000;
const EXTERNAL_LINK_OPEN_TAG = '<external-link>';
const EXTERNAL_LINK_CLOSE_TAG = '</external-link>';

const ToastComponent: FC<Props> = props => {
  const {
    id,
    type,
    content,
    autoRemove,
    tag,
    onLoad,
    onClose
  } = props;

  const onLoadCalled = useRef<boolean>(false);
  const autoRemoveRef = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const toast = [];

  const onAnimationEnd = useCallback((event: React.AnimationEvent<HTMLDivElement>) => {
    const animationName = window.getComputedStyle(event.currentTarget).animationName;

    // console.log('--in animation end', animationName);

    switch (animationName) {
      case 'toast-out':
        onClose(id, tag);
        break;
    };
  }, [
    id,
    tag,
    onClose
  ]);

  const onCloseLocal = useCallback(() => {
    if (wrapperRef.current) {
      wrapperRef.current.style.animation = `${theme.animDelay} ease-in-out 0s 1 normal forwards toast-out`;
    }

    if (autoRemoveRef.current) {
      clearTimeout(autoRemoveRef.current);
    }
  }, []);

  const getParsedContent = (incomingContent: Props['content']): Props['content'] => {
    if (!(incomingContent as string).indexOf) {
      return incomingContent;
    }

    const externalLinkOpenIndex: number = (incomingContent as string).indexOf(EXTERNAL_LINK_OPEN_TAG);
    const externalLinkCloseIndex: number = (incomingContent as string).indexOf(EXTERNAL_LINK_CLOSE_TAG);

    let parsedContent: Props['content'] = incomingContent as string;

    if (externalLinkOpenIndex >= 0 && externalLinkCloseIndex >= 0) {
      const firstHalf: string = (incomingContent as string).slice(0, externalLinkOpenIndex);
      const secondHalf: string = (incomingContent as string).slice(externalLinkCloseIndex + EXTERNAL_LINK_CLOSE_TAG.length, (incomingContent as string).length);
      const externalLink: string = (incomingContent as string).substring(externalLinkOpenIndex + EXTERNAL_LINK_OPEN_TAG.length, externalLinkCloseIndex);

      parsedContent = (
        <>
          <span>{firstHalf}</span>
          <StyledLinkExternal
            noIcon
            target={'_blank'}
            href={externalLink}
          >{externalLink}</StyledLinkExternal>
          <span>{secondHalf}</span>
        </>
      );
    }

    return parsedContent;
  };

  useEffect(() => {
    if (onLoadCalled.current) {
      return;
    }

    onLoad(onCloseLocal);

    onLoadCalled.current = true;
  }, [
    onLoad,
    onCloseLocal
  ]);

  switch (type) {
    case 'success':
      toast.push(
        <StyledTick key={'toast-icon'} />
      );
      break;
    case 'info':
      toast.push(
        <StyledInfo key={'toast-icon'} />
      );
      break;
    case 'warning':
      toast.push(
        <StyledWarning key={'toast-icon'} />
      );
      break;
    case 'error':
      toast.push(
        <StyledError key={'toast-icon'} />
      );
      break;
  }

  toast.push(
    <ContentWrapper key={'toast-content'}>{getParsedContent(content)}</ContentWrapper>
  );

  // const isAutoRemove: boolean = autoRemove || type === 'success' || type === 'info';
  const isAutoRemove: boolean = autoRemove || type === 'success';

  if (isAutoRemove) {
    const timer: number = type === 'success' ? CLOSE_TIMER * .5 : CLOSE_TIMER;

    autoRemoveRef.current = setTimeout(onCloseLocal, timer);
  }

  return (
    <ComponentWrapper
      ref={wrapperRef}
      onAnimationEnd={onAnimationEnd}
    >
      <ComponentWrapperInner>
        {toast}
      </ComponentWrapperInner>
      {!isAutoRemove && (
        <StyledCross onClick={onCloseLocal} />
      )}
    </ComponentWrapper>
  );
};

export default ToastComponent;
