import React, { FC, memo, useState, useEffect, useCallback } from 'react'

import { FormField } from '../form'
import { Container, SwitchButton, Indicator, SwitchText } from './Switch.styles'
import { FormFieldProps } from '../form/FormField';

export interface SwitchProps {
  className?: string
  disabled?: boolean
  error?: string
  id?: string
  value?: boolean
  label?: string
  textPosition?: 'left' | 'right'
  trueText?: string
  falseText?: string
}

export interface SwitchActions {
  onChange?: (value: boolean) => void;
  onFocus?: (e: any) => void;
  onBlur?: (e: any) => void;
}

export type Props = SwitchProps & FormFieldProps & SwitchActions;

const Switch: FC<Props> = props => {
  const {
    className,
    disabled,
    error,
    id,
    label,
    textPosition,
    value,
    noMinWidth,
    trueText,
    falseText,
    hasChanged,
    onChange,
    onFocus,
    onBlur,
    ...rest
  } = props
  const [innerValue, setInnerValue] = useState(value || false)
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const handleClick = useCallback(() => {
    if (disabled) {
      return
    }

    if (value === undefined) {
      setInnerValue(!innerValue)
    }

    if (onChange) {
      onChange(!innerValue)
    }
  }, [innerValue, value, onChange, disabled])

  useEffect(() => setInnerValue(value || false), [value])

  return (
    <FormField
      {...rest}
      id={id}
      error={error}
      label={label}
      className={className}
      hasChanged={hasChanged}
      value={value}
      noMinWidth={noMinWidth}
      isFocused={isFocused}
      info={rest.info}
      tooltip={rest.tooltip}
      builder={rest.builder}
      startAdornment={rest.startAdornment}
      endAdornment={rest.endAdornment}
      formFieldStyles={rest.formFieldStyles}
    >
      <Container textPosition={textPosition || 'right'}>
        <SwitchButton
          id={id}
          type='button'
          disabled={disabled}
          toggleState={innerValue}
          onClick={handleClick}
          onFocus={e => {
            if (onFocus) {
              onFocus(e);
            }

            setIsFocused(true);
          }}
          onBlur={e => {
            if (onBlur) {
              onBlur(e);
            }

            setIsFocused(false);
          }}
        >
          <Indicator />
        </SwitchButton>
        <SwitchText normalFontSize={rest.normalFontSize}>{innerValue ? trueText : falseText}</SwitchText>
      </Container>
    </FormField>
  )
}

export default memo(Switch)
