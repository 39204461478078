import React, { FC, useCallback, useState, useEffect, useMemo, memo } from 'react';
import { useParams } from "react-router";
import { format } from 'date-fns';

import { Service, FieldType } from 'types/Service';
import { Bill, BillPreview } from 'types/Billing';
import {
  Payment,
  PaymentExpanded,
  Source
} from 'types/Payment';
import {
  Client,
  PaymentPlan
} from 'types/Client';
import { gatewayService } from 'services';
import { getQueryParam } from 'utils/url';
import {
  Spinner,
  Button,
  Dropdown,
  TextInput,
  Switch,
  PrimaryButton,
  Text,
} from 'components/atoms';
import { ITableHeader, CellType } from 'types/Header';
import { theme } from 'theme';
import {
  formatCurrency,
  createDropdownOption,
  getPaymentService,
  hasValue,
  getNetworkErrors,
  isListEmpty,
  stripZoneFromISOString
} from 'utils/general';
import { useSortFilter } from '../../hooks';
import { Table, PageHeader } from '../../components';
import {
  DATE_DROPDOWN_OPTIONS,
  CURRENCY_DROPDOWN_OPTIONS,
  ISO_FORMAT
} from '../../../../../constants';
import { AdminPageProps } from 'components/AppRouter';
import { canUpdateClient } from 'config/privileges';
import {
  NetworkError
} from 'types/Error';
import { Period } from 'types/Generic';
import {
  NoDataIcon,
  NoDataCaption
} from 'theme/mixins';

import {
  Wrapper,
  StyledCreditCards,
  StyledTransferMoney,
  Card,
  AdminFormLine,
  ImportantPriceText,
  StyledLink,
  StyledLinkExternal
} from './Billing.styles';

interface State {
  billPreview: {
    loading: boolean;
    data: BillPreview | null;
    error: NetworkError | null;
  };
  bills: {
    loading: boolean;
    data: Bill[] | null;
    total: number | null;
    fields: any;
    offset: number;
    error: NetworkError | null;
  };
  payments: {
    loading: boolean;
    data: Payment[] | null;
    total: number | null;
    fields: any;
    offset: number;
    error: NetworkError | null;
    showAll: boolean;
  };
  services: {
    loading: boolean;
    data: Service[] | null;
    total: number | null;
    fields: any;
    offset: number;
    error: NetworkError | null;
  };
  client: {
    loading: boolean;
    fields: any | null;
    data: Client | null;
    error: NetworkError | null;
    changePaymentMethod: boolean;
  };
  updateClient: {
    loading: boolean;
    data: Client | null;
    error: NetworkError | null;
    form: any
  };
  ddRequest: {
    loading: boolean;
    data: any | null;
    error: NetworkError | null;
  };
}

const initialState: State = {
  billPreview: {
    loading: false,
    data: null,
    error: null
  },
  bills: {
    loading: false,
    total: null,
    data: null,
    fields: {},
    offset: 0,
    error: null
  },
  payments: {
    loading: false,
    total: null,
    data: null,
    fields: {},
    offset: 0,
    error: null,
    showAll: false
  },
  services: {
    loading: false,
    total: null,
    data: null,
    fields: {},
    offset: 0,
    error: null
  },
  client: {
    loading: false,
    fields: null,
    data: null,
    error: null,
    changePaymentMethod: false
  },
  updateClient: {
    loading: false,
    data: null,
    error: null,
    form: {}
  },
  ddRequest: {
    loading: false,
    data: null,
    error: null
  },
};

const navigateToGocardless = (url: string) => {
  window.location.href = url;
};

// TODO: move to server as config
interface PaymentProdvider {
  label: string;
  value: number;
  logoUri: string;
}
const PAYMENT_PROVIDERS: { [key: number]: PaymentProdvider } = {
  0: {
    label: 'Stripe',
    value: 0,
    logoUri: 'https://cdn.brandfolder.io/KGT2DTA4/at/8vbr8k4mr5xjwk4hxq4t9vs/Stripe_wordmark_-_blurple.svg'
  }
};
// TODO end

const Billing: FC<AdminPageProps> = props => {
  const {
    sideNavOpen,
    userData,
    addToast
  } = props;

  const [state, setState] = useState<State>(initialState);
  const { clientId } = useParams();
  const result: string | undefined = getQueryParam('result', window.location.search.split('?')[1]);

  const paymentMethodButtonDisabled = useMemo(() => {
    if (!state.client.data) {
      return true;
    }

    if (!state.client.data.customerPaymentService) {
      return !state.updateClient.form.paymentProvider?.length && !state.updateClient.form.paymentProviderKey?.length;
    }

    if (state.updateClient.form.paymentProvider === state.client.data.customerPaymentService.type
      && state.updateClient.form.paymentProviderKey === state.client.data.customerPaymentService.key
      && state.updateClient.form.paymentProviderSecret === state.client.data.customerPaymentService.secret
    ) {
      return true;
    }

    return false;
  }, [
    state.client.data,
    state.updateClient.form
  ]);

  const paymentsTotal = (state.payments.showAll ? state.payments.total : state.billPreview.data?.items?.length) || 0;

  const {
    renderPaginationAndSort: paymentsPaginationMarkup,
    renderFilterControl: paymentsFilterControlMarkup,
    sortState: paymentsSortState,
    setSortState: paymentsSetSortState,
    filterApplied: paymentsFilterApplied
  } = useSortFilter({
    listItemId: 'payment',
    total: paymentsTotal,
    offset: state.payments.offset,
    onPrev: (opts) => fetchPayments(opts),
    onNext: (opts) => fetchPayments(opts),
    onFilter: (opts) => fetchPayments(opts),
    onSort: (opts) => fetchPayments(opts),
    filterOptions: {
      fields: [
        {
          label: 'Service',
          name: 'service.name',
          type: FieldType.Dropdown,
          items: (state.services.data && state.services.data.map((service: Service) => {
            return createDropdownOption(service.name);
          })) || []
        },
        {
          label: 'Date',
          name: 'date',
          type: FieldType.DateTime,
          options: [
            createDropdownOption('Today', Period.Today),
            createDropdownOption('Yesterday', Period.Yesterday),
            createDropdownOption('This week', Period.ThisWeek),
            createDropdownOption('Last week', Period.LastWeek),
            createDropdownOption('This month', Period.ThisMonth),
            createDropdownOption('Last month', Period.LastMonth),
            createDropdownOption('This quarter', Period.ThisQuarter),
            createDropdownOption('Last quarter', Period.LastQuarter),
            createDropdownOption('This year', Period.ThisYear),
            createDropdownOption('Last year', Period.LastYear),
            ...DATE_DROPDOWN_OPTIONS
          ],
          default: {
            'date#datetime': `today=${format(new Date(), ISO_FORMAT)}`
          }
        },
        {
          label: 'Amount',
          name: 'amount',
          type: FieldType.Currency,
          options: [ ...CURRENCY_DROPDOWN_OPTIONS ],
          default: {
            'amount#currency': 'less-than=0'
          }
        }
      ]
    }
  });

  const {
    renderPaginationAndSort: billingPaginationMarkup,
    renderFilterControl: billingFilterControlMarkup,
    sortState: billingSortState,
    setSortState: billingSetSortState,
    filterApplied: billingFilterApplied
  } = useSortFilter({
    listItemId: 'bill',
    total: state.bills.total,
    offset: state.bills.offset,
    onPrev: (opts) => fetchBills(opts),
    onNext: (opts) => fetchBills(opts),
    onFilter: (opts) => fetchBills(opts),
    onSort: (opts) => fetchBills(opts),
    filterOptions: {
      fields: [
        {
          label: 'Amount',
          name: 'amount',
          type: FieldType.Currency,
          options: [ ...CURRENCY_DROPDOWN_OPTIONS ],
          default: {
            'amount#currency': 'less-than=0'
          }
        }
      ]
    }
  });

  const getPaymentsHeaderConfig = useCallback((showAll: boolean): ITableHeader[] => {
    const headers: ITableHeader[] = [
      {
        key: 'service.name',
        label: 'Service',
        width: '15%',
        type: CellType.Text,
        format: (serviceName: string, row: PaymentExpanded) => {
          if (!serviceName && row.serviceId === 'none') {
            return 'Generic job';
          }

          if (!row.service) {
            return (
              <i>Deleted service</i>
            );
          }

          if (row.service.isDeleted) {
            return (
              <s>{row.service.name}</s>
            );
          }

          return (
            <StyledLink
              to={{ pathname: `/${clientId}/back-office/services/${row.serviceId}` }}
              onClick={(e: any) => e.stopPropagation() }
            >
              <span>{row.service.name}</span>
            </StyledLink>
          );
        }
      },
      {
        key: 'jobId',
        label: 'Job',
        width: '12%',
        type: CellType.Text,
        format: (value: string, row: PaymentExpanded) => (
          <StyledLink
            to={{ pathname: `/${clientId}/back-office/jobs/${row.jobId}` }}
            onClick={(e: any) => e.stopPropagation() }
          >
            <span>{row.jobId}</span>
          </StyledLink>
        )
      },
      {
        key: 'paymentId',
        label: `${getPaymentService(state.client.data && state.client.data.customerPaymentService && state.client.data.customerPaymentService.type)} ID`,
        width: '25%',
        type: CellType.Text,
        format: (value: string, row) => {
          if (row.source === Source.External) {
            return row._id;
          }

          if (!state.client.data!.customerPaymentService) {
            return <i>Add customer payment service</i>
          }

          return (
            <StyledLinkExternal
              inline
              href={`${state.client.data!.customerPaymentService.paymentsDashboardLink}/${value}`}
              target={'_blank'}
            >{value}</StyledLinkExternal>
          );
        }
      },
      {
        key: 'date',
        label: 'Date',
        width: '15%',
        type: CellType.Text,
        sortable: showAll,
        format: (value: string) => format(new Date(value), 'dd/MM/yyyy, HH:mm')
      },
      {
        key: 'amount',
        label: 'Amount',
        width: '10%',
        type: CellType.Currency,
        sortable: showAll,
        format: (value: number) => formatCurrency(value)
      }
    ];

    if (state.client.data?.paymentPlan === PaymentPlan.PerBooking) {
      headers.push({
        key: 'fee',
        label: 'Fee',
        width: '10%',
        type: CellType.Currency,
        sortable: showAll,
        format: (value: number) => formatCurrency(value)
      });
    }

    return headers;
  }, [
    clientId,
    state.client.data
  ]);

  const getBillsHeaderConfig = useCallback((): ITableHeader[] => {
    return [
      {
        key: 'period',
        label: 'Period',
        type: CellType.Text
      },
      {
        key: 'invoiceNumber',
        label: 'Invoice',
        type: CellType.Text
      },
      {
        key: 'status',
        label: 'Status',
        type: CellType.Text,
        format: (value: string) => value.toLowerCase().split('').map((letter: string, index: number) => index === 0 ? letter.toUpperCase() : letter).join('')
      },
      {
        key: 'amount',
        label: 'Amount',
        type: CellType.Currency,
        sortable: true,
        format: (value: number) => formatCurrency(value)
      }
    ];
  }, []);

  const fetchBillPreview = useCallback((opts?: any) => {
    if (state.billPreview.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      billPreview: {
        ...prevState.billPreview,
        loading: true
      }
    }));

    gatewayService.getBillPreview(clientId!)
      .then((billPreviewResponse: any) => {
        setState(prevState => ({
          ...prevState,
          billPreview: {
            ...prevState.billPreview,
            loading: false,
            data: billPreviewResponse.data,
            error: null
          }
        }));
      })
      .catch((err: any) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          billPreview: {
            ...prevState.billPreview,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.billPreview,
    clientId,
    addToast
  ]);

  const fetchBills = useCallback((opts?: any) => {
    if (state.bills.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      bills: {
        ...prevState.bills,
        loading: true
      }
    }));

    gatewayService.getBills(clientId!, opts)
      .then((jobsResponse: any) => {
        setState(prevState => ({
          ...prevState,
          bills: {
            ...prevState.bills,
            loading: false,
            total: jobsResponse.total,
            data: [
              ...JSON.parse(JSON.stringify(jobsResponse.data))
            ],
            fields: jobsResponse.fields,
            offset: jobsResponse.offset,
            error: null
          }
        }));
      })
      .catch((err: any) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          bills: {
            ...prevState.bills,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.bills,
    clientId,
    addToast
  ]);

  const fetchPayments = useCallback((opts?: any) => {
    if (state.payments.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      payments: {
        ...prevState.payments,
        loading: true
      }
    }));

    gatewayService.getPayments(
      clientId!,
      null,
      opts
    )
      .then((paymentsResponse: any) => {
        setState(prevState => ({
          ...prevState,
          payments: {
            ...prevState.payments,
            loading: false,
            total: paymentsResponse.total,
            data: [
              ...JSON.parse(JSON.stringify(paymentsResponse.data))
            ],
            fields: paymentsResponse.fields,
            offset: paymentsResponse.offset,
            error: null
          }
        }));
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          payments: {
            ...prevState.payments,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.payments,
    clientId,
    addToast
  ]);

  const fetchServices = useCallback(() => {
    if (state.services.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      services: {
        ...prevState.services,
        loading: true
      }
    }));

    gatewayService.getServices(clientId!)
      .then((servicesResponse: any) => {
        setState(prevState => ({
          ...prevState,
          services: {
            ...prevState.services,
            loading: false,
            total: servicesResponse.total,
            data: [
              ...JSON.parse(JSON.stringify(servicesResponse.data))
            ],
            fields: servicesResponse.fields,
            offset: servicesResponse.offset,
            error: null
          }
        }));
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          services: {
            ...prevState.services,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.services,
    clientId,
    addToast
  ]);

  const fetchClient = useCallback(() => {
    if (state.client.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      client: {
        ...prevState.client,
        loading: true
      }
    }));

    gatewayService.getClient(clientId!)
      .then((clientResponse: any) => {
        setState(prevState => ({
          ...prevState,
          client: {
            ...prevState.client,
            loading: false,
            fields: clientResponse.fields,
            data: clientResponse.data,
            error: null
          },
          updateClient: {
            ...prevState.updateClient,
            ...(clientResponse.data.customerPaymentService && {
              form: {
                paymentProvider: clientResponse.data.customerPaymentService.type,
                paymentProviderKey: clientResponse.data.customerPaymentService.key,
                paymentProviderSecret: clientResponse.data.customerPaymentService.secret
              }
            })
          }
        }));
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          client: {
            ...prevState.client,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.client,
    clientId,
    addToast
  ]);

  const updateClient = useCallback((payload: any, cb?: () => void) => {
    if (state.updateClient.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      updateClient: {
        ...prevState.updateClient,
        loading: true
      }
    }));

    const {
      primaryAdministrator,
      ...rest
    } = payload;

    gatewayService.updateClient(clientId!, rest)
      .then((updateClientResponse: any) => {
        setState(prevState => ({
          ...prevState,
          updateClient: {
            ...prevState.updateClient,
            loading: false,
            data: updateClientResponse.data,
            error: null,
            form: {
              paymentProvider: updateClientResponse.data.customerPaymentService.type,
              paymentProviderKey: updateClientResponse.data.customerPaymentService.key,
              paymentProviderSecret: updateClientResponse.data.customerPaymentService.secret
            }
          },
          client: {
            ...prevState.client,
            data: updateClientResponse.data
          }
        }));

        if (cb) {
          cb();
        }
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          updateClient: {
            ...prevState.updateClient,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.updateClient,
    clientId,
    addToast
  ]);

  const setupDD = useCallback(() => {
    if (state.ddRequest.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      ddRequest: {
        ...prevState.ddRequest,
        loading: true
      }
    }));

    gatewayService.createDirectDebit(clientId!)
      .then((directDebitResponse: any) => {
        setState(prevState => ({
          ...prevState,
          ddRequest: {
            loading: false,
            data: directDebitResponse.data,
            error: null
          },
        }));

        navigateToGocardless(directDebitResponse.data.authorisationUrl);
      })
      .catch((err) => {
        const error: NetworkError = getNetworkErrors([err])[0];

        setState(prevState => ({
          ...prevState,
          ddRequest: {
            ...prevState.ddRequest,
            loading: false,
            error
          }
        }));

        addToast({
          type: 'error',
          content: error.message
        });
      });
  }, [
    state.ddRequest,
    clientId,
    addToast
  ]);

  const onChangePaymentMethod = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      client: {
        ...prevState.client,
        changePaymentMethod: true
      }
    }));
  }, []);

  const onCancelChangePaymentMethod = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      client: {
        ...prevState.client,
        changePaymentMethod: false
      }
    }));
  }, []);

  const onUpdateCustomerPaymentMethod = useCallback((e: any) => {
    updateClient({
      customerPaymentService: {
        type: state.updateClient.form.paymentProvider,
        key: state.updateClient.form.paymentProviderKey,
        secret: state.updateClient.form.paymentProviderSecret
      }
    }, () => {
      addToast({
        type: 'success',
        content: 'Customer payment method updated'
      });

      setState(prevState => ({
        ...prevState,
        client: {
          ...prevState.client,
          changePaymentMethod: false
        }
      }));
    });
  }, [
    state.updateClient.form,
    updateClient,
    addToast
  ]);

  const renderBillPreview = useCallback(() => {
    if (state.billPreview.loading) {
      return (
        <Spinner
          color={theme.textColor}
          size={'M'}
        />
      );
    }

    if (state.billPreview.error) {
      return null;
    }

    if (!state.billPreview.data) {
      return null;
    }

    const formattedPeriod: string = `${format(new Date(stripZoneFromISOString(state.billPreview.data.dates[0])), 'do MMM')} - ${format(new Date(stripZoneFromISOString(state.billPreview.data.dates[1])), 'do MMM')}`;

    return (
      <AdminFormLine marginBottom>
        <Card>
          <AdminFormLine
            row
            centerV
            spaceBetween
            marginBottom
            style={{paddingBottom: '.5rem'}}
          >
            <h3 style={{ marginBottom: 0, marginRight: '2rem' }}>Estimated costs for this billing period - ({formattedPeriod})</h3>
          </AdminFormLine>
          <AdminFormLine
            row
            centerV
          >
            <ImportantPriceText value={formatCurrency(state.billPreview.data.amount)} />
          </AdminFormLine>
          {!state.payments.showAll && (
            <AdminFormLine row marginTop>
              <p>See below for a breakdown</p>
            </AdminFormLine>
          )}
        </Card>
      </AdminFormLine>
    );
  }, [
    state.billPreview,
    state.payments.showAll
  ]);

  const renderBillsHeader = useCallback(() => {
    const noData: boolean = isListEmpty(state.bills.data);

    return (
      <AdminFormLine
        row
        centerV
        spaceBetween
        marginBottom
        style={{paddingBottom: '.5rem'}}
      >
        <h3 style={{ marginBottom: 0, marginRight: '2rem' }}>Billing history</h3>
        {(!noData || (billingFilterApplied && noData)) && billingFilterControlMarkup(sideNavOpen, true)}
      </AdminFormLine>
    );
  }, [
    sideNavOpen,
    state.bills.data,
    billingFilterApplied,
    billingFilterControlMarkup
  ]);

  const renderBillsContent = useCallback(() => {
    if (isListEmpty(state.bills.data)) {
      if (billingFilterApplied) {
        return (
          <AdminFormLine
            column
            centerH
          >
            <NoDataIcon />
            <NoDataCaption>No bills found. Try a different filter.</NoDataCaption>
          </AdminFormLine>
        );
      } else {
        return (
          <AdminFormLine
            column
            centerH
          >
            <NoDataIcon />
            <NoDataCaption>No bills just yet</NoDataCaption>
          </AdminFormLine>
        );
      }
    }

    return (
      <AdminFormLine
        row
        centerV
      >
        <Table
          namespaceKey={'bill'}
          headerConfig={getBillsHeaderConfig()}
          loading={state.bills.loading}
          rows={state.bills.data! || []}
          total={state.bills.total || 0}
          offset={state.bills.offset || 0}
          footerContent={billingPaginationMarkup()}
          sortState={billingSortState}
          onSort={billingSetSortState}
        />
      </AdminFormLine>
    );
  }, [
    state.bills,
    getBillsHeaderConfig,
    billingFilterApplied,
    billingPaginationMarkup,
    billingSortState,
    billingSetSortState
  ]);

  const renderBills = useCallback(() => {
    if (state.bills.error) {
      return null;
    }

    return (
      <AdminFormLine marginBottom>
        <Card>
          {renderBillsHeader()}
          {renderBillsContent()}
        </Card>
      </AdminFormLine>
    );
  }, [
    state.bills.error,
    renderBillsHeader,
    renderBillsContent
  ]);

  const renderCustomerPaymentMethod = useCallback(() => {
    if (state.client.loading) {
      return (
        <Spinner
          color={theme.textColor}
          size={'M'}
        />
      );
    }

    if (state.client.error) {
      return null;
    }

    if (!state.client.data) {
      return null;
    }

    const customerPaymentMethodSelected: boolean = !!state.client.data.customerPaymentService;
    const noCustomerPaymentMethod: boolean = !state.client.data.customerPaymentService && !state.client.changePaymentMethod;
    const paymentProviderSelected: boolean = !!PAYMENT_PROVIDERS[state.updateClient.form.paymentProvider];
    const dbCustomerPaymentService = state.client.data!.customerPaymentService?.type ?? '';
    const dbAPIKey = state.client.data!.customerPaymentService?.key ?? '';
    const dbAPISecret = state.client.data!.customerPaymentService?.secret ?? '';
    const hasCustomerPaymentServiceChanged: boolean = customerPaymentMethodSelected && paymentProviderSelected && state.updateClient.form.paymentProvider !== dbCustomerPaymentService;
    const hasAPIKeyChanged: boolean = customerPaymentMethodSelected && paymentProviderSelected && state.updateClient.form.paymentProviderKey !== dbAPIKey;
    const hasAPISecretChanged: boolean = customerPaymentMethodSelected && paymentProviderSelected && state.updateClient.form.paymentProviderSecret !== dbAPISecret;

    return (
      <AdminFormLine marginBottom>
        <Card id={'customer-payment'}>
          <AdminFormLine
            row
            centerV
            spaceBetween
            marginBottom
            style={{paddingBottom: '.5rem'}}
          >
            <h3 style={{ marginBottom: 0, marginRight: '2rem' }}>Customer payment method</h3>
            <AdminFormLine>
              {!noCustomerPaymentMethod && canUpdateClient(clientId!, userData.user!) && (
                <Button
                  style={{marginBottom: 0}}
                  disabled={state.client.changePaymentMethod}
                  onClick={onChangePaymentMethod}
                >Change</Button>
              )}
            </AdminFormLine>
          </AdminFormLine>
          {noCustomerPaymentMethod ? (
            <AdminFormLine
              centerH
              column
            >
              <AdminFormLine marginBottom>
                <StyledTransferMoney />
              </AdminFormLine>
              <AdminFormLine marginBottom>
                <NoDataCaption>Setup your customer payment method to accept payments from your customers</NoDataCaption>
              </AdminFormLine>
              <AdminFormLine marginBottom>
                <Button
                  onClick={onChangePaymentMethod}
                >Add a payment method</Button>
              </AdminFormLine>
            </AdminFormLine>
          ) : (
            <>
              <AdminFormLine marginBottom>
                {state.client.changePaymentMethod ? (
                  <Dropdown
                    widthM={'100%'}
                    widthT={'35rem'}
                    label={'Payment provider'}
                    hasChanged={hasCustomerPaymentServiceChanged}
                    value={state.updateClient.form.paymentProvider}
                    options={Object.values(PAYMENT_PROVIDERS)}
                    onChange={(option: any) => {
                      setState(prevState => ({
                        ...prevState,
                          updateClient: {
                            ...prevState.updateClient,
                            form: {
                              ...state.updateClient.form,
                              paymentProvider: option && hasValue(option.value) ? option.value : ''
                            }
                        }
                      }));
                    }}
                  />
                ) : (
                  paymentProviderSelected && (
                    <Text
                      label={'Payment provider'}
                      value={
                        <img
                          alt={`${PAYMENT_PROVIDERS[state.updateClient.form.paymentProvider].label}`}
                          src={`${PAYMENT_PROVIDERS[state.updateClient.form.paymentProvider].logoUri}`}
                        />
                      }
                    />
                  )
                )}
              </AdminFormLine>
              <AdminFormLine marginBottom>
                {state.client.changePaymentMethod ? (
                  <TextInput
                    widthM={'100%'}
                    widthT={'35rem'}
                    label={'API key'}
                    hasChanged={hasAPIKeyChanged}
                    value={state.updateClient.form.paymentProviderKey}
                    type={'password'}
                    onChange={(e) => {
                      setState(prevState => ({
                        ...prevState,
                        updateClient: {
                          ...prevState.updateClient,
                          form: {
                            ...prevState.updateClient.form,
                            paymentProviderKey: e.target.value
                          }
                        }
                      }));
                    }}
                    onKeyUp={(e) => {
                      if (e.key === 'Enter') {
                        onUpdateCustomerPaymentMethod(window.event);
                      }
                    }}
                  />
                ) : (
                  <Text
                    label={'API key'}
                    value={'••••••••••••••••••••••••••••••••••••••••••••••'}
                  />
                )}
              </AdminFormLine>
              <AdminFormLine marginBottom>
                {state.client.changePaymentMethod ? (
                  <TextInput
                    widthM={'100%'}
                    widthT={'35rem'}
                    label={'API secret'}
                    hasChanged={hasAPISecretChanged}
                    value={state.updateClient.form.paymentProviderSecret}
                    type={'password'}
                    onChange={(e) => {
                      setState(prevState => ({
                        ...prevState,
                        updateClient: {
                          ...prevState.updateClient,
                          form: {
                            ...prevState.updateClient.form,
                            paymentProviderSecret: e.target.value
                          }
                        }
                      }));
                    }}
                    onKeyUp={(e) => {
                      if (e.key === 'Enter') {
                        onUpdateCustomerPaymentMethod(window.event);
                      }
                    }}
                  />
                ) : (
                  <Text
                    label={'API secret'}
                    value={'••••••••••••••••••••••••••••••••••••••••••••••'}
                  />
                )}
              </AdminFormLine>
            </>
          )}
          {state.client.changePaymentMethod && canUpdateClient(clientId!, userData.user!) && (
            <AdminFormLine
              topPadding
              right
            >
              <AdminFormLine>
                <Button
                  type={'button'}
                  onClick={onCancelChangePaymentMethod}
                >Cancel</Button>
                <PrimaryButton
                  type={'button'}
                  style={{
                    marginLeft: '1rem',
                    marginBottom: 0
                  }}
                  disabled={paymentMethodButtonDisabled}
                  loading={state.updateClient.loading}
                  spinnerColor={theme.colors.coreSecondary}
                  onClick={onUpdateCustomerPaymentMethod}
                >Update</PrimaryButton>
              </AdminFormLine>
            </AdminFormLine>
          )}
        </Card>
      </AdminFormLine>
    );
  }, [
    state.client,
    state.updateClient,
    userData.user,
    clientId,
    paymentMethodButtonDisabled,
    onChangePaymentMethod,
    onCancelChangePaymentMethod,
    onUpdateCustomerPaymentMethod
  ]);

  const renderClientPaymentMethod = useCallback(() => {
    if (state.client.loading) {
      return (
        <Spinner
          color={theme.textColor}
          size={'M'}
        />
      );
    }

    if (state.client.error) {
      return null;
    }

    if (!state.client.data) {
      return null;
    }

    let elem = null;
    const isActive: boolean = !!(result === '1' || state.client.data.goCardlessMandateId);

    if (isActive) {
      //  TODO: show green tick
      elem = (
        <AdminFormLine marginBottom>Direct debit active</AdminFormLine>
      );
    }
    else if (state.client.data.goCardlessCustomerId) {
      elem = (
        <AdminFormLine marginBottom>Setup in progress, please complete the direct debit form</AdminFormLine>
      );
    }
    else if (result === '0') {
      elem = (
        <p>Direct debit setup failed, please try again</p>
      );
    }

    return (
      <AdminFormLine marginBottom>
        <Card id={'direct-debit'}>
          <AdminFormLine
            row
            centerV
            spaceBetween
            marginBottom
            style={{paddingBottom: '.5rem'}}
          >
            <h3 style={{ marginBottom: 0, marginRight: '2rem' }}>Payment method</h3>
          </AdminFormLine>
          <AdminFormLine
            column
            centerH
          >
            <AdminFormLine
              centerH
              column
            >
              <AdminFormLine marginBottom>
                <StyledCreditCards />
              </AdminFormLine>
              {!isActive && (
                <AdminFormLine marginBottom>
                  <NoDataCaption>Setup the method we use to bill you</NoDataCaption>
                </AdminFormLine>
              )}
            </AdminFormLine>
            {elem}
            {!isActive && (
              <AdminFormLine row>
                <Button
                  style={{marginBottom: 0}}
                  onClick={setupDD}
                  loading={state.ddRequest.loading}
                >Setup direct debit</Button>
              </AdminFormLine>
            )}
          </AdminFormLine>
        </Card>
      </AdminFormLine>
    );
  }, [
    result,
    setupDD,
    state.client,
    state.ddRequest
  ]);

  const renderPaymentsHeader = useCallback(() => {
    const rows = (state.payments.showAll ? state.payments.data : state.billPreview.data ? state.billPreview.data.items : null) || [];
    const noData: boolean = isListEmpty(rows);
    const isBelowLimit: boolean = (state.payments.total || 0) <= 10;

    return (
      <AdminFormLine
        row
        centerV
        spaceBetween
        marginBottom
        style={{paddingBottom: '.5rem'}}
      >
        <h3 style={{ marginBottom: 0, marginRight: '2rem' }}>Customer payment history</h3>
        {(!noData || (paymentsFilterApplied && noData)) && (
          <AdminFormLine
            row
            centerV
          >
            <Switch
              value={isBelowLimit ? true : state.payments.showAll}
              falseText={'All'}
              trueText={'All'}
              disabled={isBelowLimit}
              textPosition={'left'}
              normalFontSize={true}
              onChange={(value: boolean) => {
                setState(prevState => ({
                  ...prevState,
                  payments: {
                    ...prevState.payments,
                    showAll: value
                  }
                }));
              }}
              style={{marginRight: '2rem'}}
            />
            {paymentsFilterControlMarkup(sideNavOpen, true, isBelowLimit || !state.payments.showAll)}
          </AdminFormLine>
        )}
      </AdminFormLine>
    );
  }, [
    state.payments.showAll,
    state.payments.data,
    state.payments.total,
    state.billPreview.data,
    paymentsFilterApplied,
    paymentsFilterControlMarkup,
    sideNavOpen
  ]);

  const renderPaymentsContent = useCallback(() => {
    const rows = (state.payments.showAll ? state.payments.data : state.billPreview.data?.items) || [];
    const total = (state.payments.showAll ? state.payments.total : state.billPreview.data?.items?.length) || 0;

    if (isListEmpty(rows)) {
      if (paymentsFilterApplied) {
        return (
          <AdminFormLine
            column
            centerH
          >
            <NoDataIcon />
            <NoDataCaption>No payments found. Try a different filter.</NoDataCaption>
          </AdminFormLine>
        );
      } else {
        return (
          <AdminFormLine
            column
            centerH
          >
            <NoDataIcon />
            <NoDataCaption>No customer payments just yet</NoDataCaption>
          </AdminFormLine>
        );
      }
    }

    return (
      <AdminFormLine
        row
        centerV
      >
        <Table
          namespaceKey={'payments'}
          headerConfig={getPaymentsHeaderConfig(state.payments.showAll)}
          loading={state.payments.loading || state.billPreview.loading || state.client.loading}
          rows={rows}
          total={total}
          offset={state.payments.offset || 0}
          footerContent={paymentsPaginationMarkup()}
          sortState={paymentsSortState}
          onSort={paymentsSetSortState}
        />
      </AdminFormLine>
    );
  }, [
    state.payments,
    state.billPreview,
    state.client,
    getPaymentsHeaderConfig,
    paymentsFilterApplied,
    paymentsPaginationMarkup,
    paymentsSortState,
    paymentsSetSortState
  ]);

  const renderPayments = useCallback(() => {
    if (state.payments.error) {
      return null;
    }

    return (
      <AdminFormLine marginBottom>
        <Card>
          {renderPaymentsHeader()}
          {renderPaymentsContent()}
        </Card>
      </AdminFormLine>
    );
  }, [
    state.payments.error,
    renderPaymentsHeader,
    renderPaymentsContent
  ]);

  useEffect(() => {
    if (!state.billPreview.data && !state.billPreview.error) {
      fetchBillPreview();
    }

    if (!state.bills.data && !state.bills.error) {
      fetchBills();
    }

    if (!state.payments.data && !state.payments.error) {
      fetchPayments();
    }

    if (!state.services.data && !state.services.error) {
      fetchServices();
    }

    if (!state.client.data && !state.client.error) {
      fetchClient();
    }
  }, [
    state.billPreview.data,
    state.billPreview.error,
    state.bills.data,
    state.bills.error,
    state.payments.data,
    state.payments.error,
    state.services.data,
    state.services.error,
    state.client.data,
    state.client.error,
    fetchBillPreview,
    fetchBills,
    fetchPayments,
    fetchServices,
    fetchClient
  ]);

  return (
    <Wrapper>
      <PageHeader title={'Billing'} />
      {renderBillPreview()}
      {renderPayments()}
      {renderBills()}
      {renderCustomerPaymentMethod()}
      {renderClientPaymentMethod()}
    </Wrapper>
  );
};

export default memo(Billing);
