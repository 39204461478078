import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Props as SelectProps } from 'react-select';
// tslint:disable-next-line
import CreatableSelect from 'react-select/creatable';

import { FormField } from '../form';
import { BaseProps } from '../form/types';
import { createDropdownOption } from 'utils/general';
import { FormFieldProps } from '../form/FormField';

import { selectStyles } from './CreatableDropdown.styles';
import { getTheme } from './Dropdown.styles';

export interface MultiCreatableDropdownProps extends SelectProps {
  isClearable?: boolean;
  showDropdown?: boolean;
  enterKeyHint?: string;
}

export type Props = MultiCreatableDropdownProps & BaseProps & FormFieldProps;

const MultiCreatableDropdown = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    className,
    label,
    error,
    hasChanged,
    width,
    widthM,
    widthT,
    widthL,
    isClearable,
    showDropdown,
    options,
    noWidth,
    noMinWidth,
    onFocus,
    onBlur,
    onChange,
    onKeyDown,
    enterKeyHint,
    ...rest
  } = props;

  const [innerValue, setInnerValue] = useState<any>(rest.value || []);
  const [innerInputValue, setInnerInputValue] = useState<any>('');
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const elemRef = useRef<HTMLDivElement>(null);

  const onChangeInternal = useCallback((value: any, actionMeta: any) => {
    setInnerValue(value);

    if (onChange) {
      onChange(value, actionMeta);
    }
  }, [onChange]);

  const onChangeInputInternal = useCallback((value: string) => {
    setInnerInputValue(value);
  }, []);

  const onKeyDownInternal = useCallback((event: any) => {
    if (!innerInputValue) {
      if (onKeyDown) {
        onKeyDown(event);
      }

      return;
    }

    switch (event.key) {
      case 'Enter':
      case 'Tab':
        event.preventDefault();

        // console.group('Value Added');
        // console.log(innerValue);
        // console.groupEnd();

        setInnerInputValue('');
        setInnerValue((prevState: any) => {
          const state: any = [];

          if (prevState) {
            state.push(...prevState);
          }

          const newOption = createDropdownOption(innerInputValue);
          state.push(newOption);

          if (onChange) {
            onChange(state, {
              action: 'create-option',
              option: newOption
            });
          }

          return state;
        });
        break;
    };
  }, [
    // innerValue,
    innerInputValue,
    onChange,
    onKeyDown
  ]);

  useEffect(() => {
    if (JSON.stringify(rest.value) !== JSON.stringify(innerValue)) {
      setInnerValue(rest.value);
    }
  }, [
    innerValue,
    rest.value
  ]);

  // Set enterkeyhint attribute
  useEffect(() => {
    if (enterKeyHint && elemRef.current) {
      const inputElem = elemRef.current.querySelector('input');

      if (inputElem) {
        inputElem.setAttribute('enterkeyhint', enterKeyHint);
      }
    }
  }, [
    label,
    enterKeyHint
  ]);

  return (
    <FormField
      ref={elemRef}
      id={rest.id}
      error={error}
      label={label}
      className={className}
      style={rest.style}
      hasChanged={hasChanged}
      value={innerValue}
      width={width}
      widthM={widthM}
      widthT={widthT}
      widthL={widthL}
      noWidth={noWidth}
      noMinWidth={noMinWidth}
      isFocused={isFocused}
      isFull={innerValue && innerValue.length > 0}
      info={rest.info}
      tooltip={rest.tooltip}
      builder={rest.builder}
      endAdornment={rest.endAdornment}
      formFieldStyles={rest.formFieldStyles}
    >
      <CreatableSelect
        {...(!showDropdown && {
          menuIsOpen: false,
          components: {DropdownIndicator: null}
        })}
        isClearable
        isMulti
        menuIsOpen={false}
        value={innerValue}
        options={options}
        inputValue={innerInputValue}
        styles={selectStyles(isFocused, !!error)}
        theme={(theme) => getTheme(theme)}
        menuPortalTarget={document.body as HTMLElement}
        menuPosition={'fixed'}
        menuShouldScrollIntoView={false}
        closeMenuOnScroll={e => {
          if (e.target && (e.target as HTMLElement).className.includes('MenuList')) {
            return false;
          }

          return true;
        }}
        onChange={onChangeInternal}
        onInputChange={onChangeInputInternal}
        onKeyDown={onKeyDownInternal}
        onFocus={e => {
          if (onFocus) {
            onFocus(e as any);
          }

          setIsFocused(true);
        }}
        onBlur={e => {
          if (onBlur) {
            onBlur(e as any);
          }

          setIsFocused(false);
        }}
      />
    </FormField>
  );
});

export default MultiCreatableDropdown;
